import { FC, useEffect } from 'react';
import { ChangeEvent, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {Invitation, MembershipSimple} from 'group-service-api';
import { ErrorType, IError } from '../../model/common/error';
import { DocumentsHelper } from '../../utils/documents-helper';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { useAppSelector } from '../../store/hooks';

interface IAddMemberEmailCardProps {
    onClick: (invitation: Invitation) => void;
}

const AddMemberEmailCard: FC<IAddMemberEmailCardProps> = (props) => {
    const { onClick } = props;
    const { t } = useTranslation();
    const { selectedGroup, isLoadingAction } = useAppSelector((state) => state.groups);
    const [email, setEmail] = useState<string>('');
    const [validationError, setValidationError] = useState<IError>({
        error: false,
        errorMessage: '',
        errorType: ErrorType.VALIDATION_ERROR,
    });

    useEffect(() => {
        setEmail('');
    }, [selectedGroup?.memberships]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setValidationError({ error: false, errorMessage: '', errorType: ErrorType.VALIDATION_ERROR });
        setEmail(event.target.value);
    };

    const handleAddClick = () => {
        const validEmail = DocumentsHelper.isEmailFormat(email);

        const alreadyExists = selectedGroup?.memberships.findIndex((m: MembershipSimple) => m.email === email);

        if (!validEmail) {
            setValidationError({
                error: true,
                errorMessage: t('dashboard.sidebar.userContacts.newContact.email.modal.emailAddress.validationError') as string,
                errorType: ErrorType.VALIDATION_ERROR,
            });
        } else if (alreadyExists !== undefined && alreadyExists > -1) {
            setValidationError({
                error: true,
                errorMessage: t('dashboard.sidebar.userContacts.newContact.email.modal.emailAddress.existingEmail') as string,
                errorType: ErrorType.VALIDATION_ERROR
            });
        } else {
            onClick({ email: email });
            setEmail('');
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            handleAddClick();
        }
    };

    return (
        <Box sx={{ maxWidth: '400px' }}>
            <TextField
                sx={{ mb: 3 }}
                error={Boolean(validationError.errorMessage)}
                helperText={Boolean(validationError.errorMessage) && validationError.errorMessage}
                autoFocus
                fullWidth
                label={t('dashboard.sidebar.userContacts.newContact.email.modal.emailAddress')}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={t('dashboard.sidebar.userContacts.newContact.email.modal.emailAddress.placeholder')}
                rows={1}
                value={email}
                variant="outlined"
            />
            <CommonButton btnType={ButtonTypeEnum.ADD} loading={isLoadingAction} onClick={() => handleAddClick()}>
                {t('groups.memberships.add')}
            </CommonButton>
        </Box>
    );
};

export default AddMemberEmailCard;
