import type { FC } from 'react';
import { Box, Divider, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UnprotectedRoutes } from '../../../routes';

const ShopLegalDocs: FC = () => {
    const { t } = useTranslation();

    return (
        <Box sx={ { p: 2, textAlign: 'center' } }>
            <Divider />
            <Link
                target={ '_blank' }
                sx={ { m: 2 } } color='textSecondary'
                component={ RouterLink }
                to={ UnprotectedRoutes.terms_sale_route.path }
                variant='subtitle2'>
                { t('dashboard.sidebar.terms.sale') }
            </Link>
            <Link
                target={ '_blank' }
                sx={ { m: 2 } } color='textSecondary'
                component={ RouterLink }
                to={ UnprotectedRoutes.terms_protection_route.path }
                variant='subtitle2'>
                { t('dashboard.sidebar.terms.dataProtection') }
            </Link>
            <Link
                target={ '_blank' }
                sx={ { m: 2 } } color='textSecondary'
                component={ RouterLink }
                to={ UnprotectedRoutes.terms_data_usage_route.path }
                variant='subtitle2'>
                { t('dashboard.sidebar.terms.dataUsage') }
            </Link>
            <Link
                target={ '_blank' }
                sx={ { m: 2 } } color='textSecondary'
                component={ RouterLink }
                to={ UnprotectedRoutes.terms_conversion_route.path }
                variant='subtitle2'>
                { t('dashboard.sidebar.terms.conversion') }
            </Link>
        </Box>
    );
};

export default ShopLegalDocs;
