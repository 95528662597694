import type { FC } from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { IOrganizationDocument } from '../../model/common/documents/IOrganizationDocument';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getUserDocuments } from '../../slices/organizations';
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { format } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import EmptyState from '../empty-states/EmptyState';
import { DocumentSimpleOut } from 'organizations-service-api';

interface IOrganizationUserDocumentsProps {
    onSelectDocument: (document: IOrganizationDocument) => void;
}

const SelectOrganizationUserDocuments: FC<IOrganizationUserDocumentsProps> = ({ onSelectDocument }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [selectedDocumentUuids, setSelectedDocumentUuids] = useState<string[]>([]);
    const { isLoadingFiles, selectedOrganization, userDocuments } = useAppSelector(state => state.organizations);

    useEffect(() => {
        if (!userDocuments && selectedOrganization?.currentUser?.uuid) {
            dispatch(getUserDocuments(selectedOrganization?.currentUser?.uuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrganization?.currentUser?.uuid]);

    const handleSelectDocument = (_event: ChangeEvent<HTMLInputElement>, document: DocumentSimpleOut): void => {
        onSelectDocument(
            {
                createdAt: document.createdAt,
                documentName: document.documentName,
                fileName: document.fileName,
                uuid: document.uuid
            } as IOrganizationDocument
        );

        setSelectedDocumentUuids((prevSelectedDocumentUuids: string[]) => {
            if (prevSelectedDocumentUuids.includes(document.uuid)) {
                return prevSelectedDocumentUuids.filter((uuid: string) => uuid !== document.uuid);
            }
            return [...prevSelectedDocumentUuids, document.uuid];
        });
    };

    return (
        <Card sx={ { border: 'none', boxShadow: 'none', mb: 5, mt: 5 } }>
            <Typography sx={ { m: 4, textAlign: 'center' } } color='textPrimary' variant='subtitle2'>
                <Trans
                    i18nKey='organizationDocuments.title'
                    values={ { organizationName: selectedOrganization?.name?.toUpperCase() } }
                    components={ { italic: <i />, bold: <strong /> } } />
            </Typography>
            <CardContent>
                { isLoadingFiles ?
                    <Box sx={ { mt: 5, textAlign: 'center' } }>
                        <CircularProgress size="3rem" />
                    </Box> :
                    (!userDocuments || userDocuments.length === 0) ?
                        <EmptyState message={ t('organizationDocuments.emptyList') } /> :
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={ { textAlign: 'center' } }></TableCell>
                                    <TableCell>{ t('organizationDocuments.documentName') }</TableCell>
                                    <TableCell>{ t('organizationDocuments.fileName') }</TableCell>
                                    <TableCell>{ t('organizationDocuments.documentCreatedAt') }</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { userDocuments && userDocuments.map((doc: DocumentSimpleOut, index: number) => {
                                    return (
                                        <TableRow key={ index }>
                                            <TableCell sx={ { textAlign: 'center' } }>
                                                <Checkbox
                                                    checked={ selectedDocumentUuids.includes(doc.uuid) }
                                                    color='primary'
                                                    onChange={ (event) => handleSelectDocument(event, doc) }
                                                    value={ selectedDocumentUuids.includes(doc.uuid) }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    { doc.documentName }
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    { doc.fileName }
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    { format(new Date(doc.createdAt), 'dd.MM.yyyy. HH:mm') }
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) }
                            </TableBody>
                        </Table>
                }
            </CardContent>
            {/*</Box>*/ }
        </Card>
    );
};

export default SelectOrganizationUserDocuments;
