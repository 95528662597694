import type { FC } from 'react';
import { Card, CardContent, CircularProgress, Grid, IconButton, Stack, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { GroupAddRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';

interface INewGroupCardProps {
    isLoading: boolean;
    onNewGroupClick: () => void;
}

const NewGroupCard: FC<INewGroupCardProps> = ({ isLoading, onNewGroupClick }) => {
    const { t } = useTranslation();

    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    return smUp ? (
        <Card
            sx={ {
                alignItems: 'center',
                backgroundColor: 'unset',
                border: 'none',
                boxShadow: 'none',
                display: 'flex',
                m: 6
            } }>
            <CardContent sx={ { mx: 'auto' } }>
                { isLoading ? (
                    <CircularProgress size='3rem' sx={ { opacity: '0.3' } } />
                ) : (
                    <Tooltip title={ t('groups.pending.summary.create') as string } open>
                        <IconButton onClick={ onNewGroupClick }>
                            <GroupAddRounded
                                sx={ {
                                    cursor: 'pointer',
                                    opacity: '0.3',
                                    transform: 'scale(3)'
                                } }
                                fontSize='large'
                            />
                        </IconButton>
                    </Tooltip>
                ) }
            </CardContent>
        </Card>
    ) : (
        <Card sx={ { p: 1, pl: 2 } }>
            <Stack direction='row' sx={ { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } }>
                <Typography color='textPrimary' variant='body1'>
                    { t('groups.pending.summary.create') }
                </Typography>
                <Grid item>
                    <CommonButton btnType={ ButtonTypeEnum.ADD } onClick={ onNewGroupClick } loading={ isLoading }>
                        { t('groups.pending.summary.create') }
                    </CommonButton>
                </Grid>
            </Stack>
        </Card>
    );
};

export default NewGroupCard;
