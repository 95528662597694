import type { FC } from 'react';
import { useState } from 'react';
import { Alert, Box, Container, Theme, useMediaQuery } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import signLogo from '../../assets/img/illustrations/Identyum-SIGN-logo.svg';
import signLogoLight from '../../assets/img/illustrations/Identyum-SIGN-logo-light.svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';
import Tutorial from 'components/tutorial';
import LanguagePopover from '../../components/dashboard/LanguagePopover';
import IdentifyFooter from './IdentifyFooter';
import ThemeSwitch from '../../components/ThemeSwitch';
import createDark from '../../assets/img/login/new_login_dark.svg';
import loginLight from '../../assets/img/login/new_login_light.svg';
import useSettings from '../../hooks/useSettings';
import { THEMES } from '../../common/constants';

enum PageState {
    LOGIN,
    TUTORIAL,
}

const Login: FC = () => {
    const { settings } = useSettings();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isPasswordResetMessageVisible } = useAppSelector((state) => state.pinManager);
    const [ pageState, setPageState ] = useState(PageState.LOGIN);

    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const isDarkMode = settings?.theme === THEMES.DARK;

    const handleLogin = () => {
        navigate('/refresh');
    };

    const handleWatchTutorial = () => {
        setPageState(PageState.TUTORIAL);
    };

    return (
        <>
            <Box
                sx={ {
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh'
                } }>
                <Box sx={ { alignItems: 'center', display: 'flex', justifyContent: 'flex-end', ml: 2, mr: 2 } }>
                    <LanguagePopover />
                    <ThemeSwitch />
                </Box>
                <Container maxWidth='sm' sx={ { py: '80px' } }>
                    { pageState === PageState.LOGIN && (
                        <>
                            <Box
                                sx={ {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mb: smUp ? 8 : 0,
                                    pl: smUp ? 0 : 8,
                                    pr: smUp ? 0 : 8
                                } }>
                                <RouterLink to='/'>
                                    <Box
                                        sx={ {
                                            '& > img': {
                                                maxHeight: '100%',
                                                width: '100%'
                                            },
                                            height: smUp ? 200 : 'auto'
                                        } }>
                                        <img src={ isDarkMode ? signLogoLight : signLogo } alt='Identyum SIGN' />
                                    </Box>
                                </RouterLink>
                            </Box>
                            <Box
                                sx={ {
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    p: 8
                                } }>
                                <Box className='light-btn-login' onClick={ handleLogin }>
                                    <div style={ { color: '#5c5c8e', fontSize: 22, marginRight: 5 } }>{ t('login.btn.title') }</div>
                                    <img style={ { marginTop: 8 } } src={ loginLight } width={ 110 } height={ 55 } alt='Identyum' />
                                </Box>
                                <Box className='dark-btn-login' onClick={ handleWatchTutorial }>
                                    <div style={ { color: '#9494d3', fontSize: 22, marginRight: 5 } }>{ t('create.btn.title') }</div>
                                    <img style={ { marginTop: 8 } } src={ createDark } width={ 110 } height={ 55 } alt='Identyum' />
                                </Box>

                                { isPasswordResetMessageVisible && (
                                    <Box sx={ { alignItems: 'center' } }>
                                        <Alert severity='error' sx={ { mt: 5 } }>
                                            { t('dashboard.sidebar.documents.account.login.resetPassword') }
                                        </Alert>
                                    </Box>
                                ) }
                            </Box>
                        </>
                    ) }
                    { pageState === PageState.TUTORIAL && <Tutorial /> }
                </Container>
                <IdentifyFooter />
            </Box>
        </>
    );
};

export default Login;
