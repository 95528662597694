import type { FC } from 'react';
import { useEffect, useState } from 'react';
import GroupCard from '../../components/groups/GroupCard';
import { createGroup, getMemberships } from '../../slices/groups';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { CreateGroupRequest, Membership } from 'group-service-api';
import { Avatar, Grid, Typography } from '@mui/material';
import Page from '../../components/shared/Page';
import { useTranslation } from 'react-i18next';
import AddGroupDialog from '../../components/groups/AddGroupDialog';
import { useNavigate } from 'react-router-dom';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import EmptyState from '../../components/empty-states/EmptyState';
import { Groups } from '@mui/icons-material';
import { GroupRoutes } from '../../routes';
import GroupSwitchOverlay from '../../components/groups/GroupSwitchOverlay';
import { resetDocuments } from '../../slices/documents';
import { GroupedMemberships, groupMembershipsByOrganization } from '../../utils/organizations-helper';
import { getAllOrganizations } from '../../slices/organizations';
import { DocumentsHelper } from '../../utils/documents-helper';
import SkeletonGridCard from '../../components/skeletons/SkeletonGridCard';

const GroupsPage: FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [addNewGroupDialogOpened, setAddNewGroupDialogOpened] = useState<boolean>(false);
    const [showSwitchOverlay, setShowSwitchOverlay] = useState<boolean>(false);
    const [selectedGroupName, setSelectedGroupName] = useState<string | undefined>(undefined);

    const { confirmedMemberships, creatingGroupOrganizationUuid, isCreatingPrivateGroup, isLoadingAction } = useAppSelector((state) => state.groups);
    const { allOrganizations } = useAppSelector((state) => state.organizations);

    const groupedMemberships: GroupedMemberships | undefined = groupMembershipsByOrganization(allOrganizations, confirmedMemberships);
    const url = window.location.href;

    useEffect(() => {
        dispatch(getMemberships());
        dispatch(getAllOrganizations());
        if (url && url.includes('new')) {
            setAddNewGroupDialogOpened(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddButtonClick = () => {
        setAddNewGroupDialogOpened(true);
    };

    const handleAddNewGroupDialogConfirmed = (groupName: string, organizationUuid: string) => {
        setAddNewGroupDialogOpened(false);
        if (groupName) {
            dispatch(createGroup({ name: groupName, organizationUuid: organizationUuid } as CreateGroupRequest));
        }
    };

    const handleCloseAddNewGroupDialog = () => {
        setAddNewGroupDialogOpened(false);
    };

    const handleSelectGroupClick = (groupUuid: string, groupName: string) => {
        dispatch(resetDocuments());
        setShowSwitchOverlay(true);
        setSelectedGroupName(groupName);
        setTimeout(() => {
            setShowSwitchOverlay(false);
            navigate(GroupRoutes.groups_sent_documents_route.path.replace(':groupUuid', groupUuid));
        }, 3000);
    };

    const renderMemberships = (memberships: Membership[] | undefined): JSX.Element => {
        return memberships && memberships.length > 0 ? (
            <Grid container direction='row' spacing={2}>
                {memberships?.map((m: Membership, index: number) => (
                    <>
                        <Grid item md={4} xs={12} key={m.group.uuid}>
                            <GroupCard membership={m} onGroupSelect={(groupUuid: string) => handleSelectGroupClick(groupUuid, m.group.name)} />
                        </Grid>
                        {index === memberships.length - 1 && isCreatingPrivateGroup && !m.group.organizationUuid && <SkeletonGridCard />}
                        {index === memberships.length - 1 && !isCreatingPrivateGroup && m.group.organizationUuid === creatingGroupOrganizationUuid && (
                            <SkeletonGridCard />
                        )}
                    </>
                ))}
            </Grid>
        ) : (
            <Grid container direction='row' justifyContent={isCreatingPrivateGroup || creatingGroupOrganizationUuid ? 'left' : 'center'} alignItems='center'>
                {isCreatingPrivateGroup || creatingGroupOrganizationUuid ? (
                    <SkeletonGridCard />
                ) : (
                    <EmptyState icon={<Groups />} message={t('groups.pending.summary.empty')} />
                )}
            </Grid>
        );
    };

    const renderGroupedMemberships = () => {
        if (!groupedMemberships) {
            return renderMemberships(confirmedMemberships);
        }
        return (Object.keys(groupedMemberships) as string[]).map((organizationName) => (
            <Grid container justifyContent='space-between' key={organizationName} sx={{ mb: 10 }}>
                <Grid item sx={{ alignItems: 'center', display: 'flex', overflow: 'hidden' }}>
                    <Avatar sx={{ height: 64, mb: 2, mr: 2, width: 64 }}>
                        { DocumentsHelper.getInitials(organizationName?.toUpperCase() ?? '') }
                    </Avatar>
                    <Typography color='textSecondary' variant='h6'>
                        { organizationName === 'private_groups' ? t('groups.pending.summary.privateGroups').toUpperCase() : organizationName.toUpperCase() }
                        {` (${groupedMemberships[organizationName].length}) `}
                    </Typography>
                </Grid>
                { renderMemberships(groupedMemberships[organizationName]) }
            </Grid>
        ));
    };

    return (
        <>
            <Page
                pageTitle={t('groups.pending.summary.review')}
                pageDescription={t('groups.pending.summary.reviewAndChoose')}
                actionButton={
                    <CommonButton btnType={ButtonTypeEnum.ADD} onClick={handleAddButtonClick} loading={isLoadingAction}>
                        {t('groups.pending.summary.create')}
                    </CommonButton>
                }>
                {renderGroupedMemberships()}

                <AddGroupDialog
                    open={addNewGroupDialogOpened}
                    onCloseConfirmed={handleAddNewGroupDialogConfirmed}
                    onCloseCanceled={handleCloseAddNewGroupDialog}
                />
            </Page>
            <GroupSwitchOverlay groupName={selectedGroupName} open={showSwitchOverlay} />
        </>
    );
};

export default GroupsPage;
