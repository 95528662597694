import type { FC } from 'react';
import { useEffect } from 'react';
import Page from '../../components/shared/Page';
import { Box, Card, CardActions, CardContent, Grid, LinearProgress, Typography } from '@mui/material';
import { getMySurveys } from '../../slices/survey';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes } from '../../routes';
import SkeletonBlock from '../../components/skeletons/SkeletonBlock';
import { SurveyUser } from 'survey-service-api';
import EmptyState from '../../components/empty-states/EmptyState';
import { ShareSharp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

const MySurveysPage: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { sharedSurveys, isLoading } = useAppSelector((state) => state.survey);

    useEffect(() => {
        dispatch(getMySurveys());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigateToForm = (uuid: string) => {
        navigate(PrivateRoutes.my_survey_route.path.replace(':surveyUuid', uuid ?? ''));
    };

    const calculatePercentage = (survey: SurveyUser): string => {
        const totalAnswers = survey.answers.filter(value => !value.question.idyWalletQuestion).length;
        const totalQuestions = survey.survey.sections
            .map((value) => value.questions.length)
            .reduce((accumulator, value) => (accumulator ?? 0) + (value ?? 0), 0);

        const percentage = (totalAnswers / totalQuestions) * 100;

        return `${totalAnswers}/${totalQuestions} (${percentage > 0 ? percentage.toFixed(0) : 0}%)`;
    };

    const progressValue = (survey: SurveyUser): number => {
        const totalAnswers = survey.answers.length;
        const totalQuestions = survey.survey.sections
            .map((value) => value.questions.length)
            .reduce((accumulator, value) => (accumulator ?? 0) + (value ?? 0), 0);

        return (totalAnswers / totalQuestions) * 100;
    };

    return (
        <Page pageTitle={t('survey.MySurveysPage.title')} pageDescription={t('survey.MySurveysPage.description')}>
            {isLoading ? (
                <SkeletonBlock />
            ) : sharedSurveys && sharedSurveys.length > 0 ? (
                <Grid container spacing={3} direction="row">
                    {sharedSurveys &&
                        sharedSurveys.map((s: SurveyUser) => (
                            <Grid item key={s.uuid} lg={3} md={4} xl={3} xs={12} sm={12}>
                                <Card>
                                    <CardContent sx={{ minHeight: '250px' }}>
                                        {s.survey && (
                                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '250px' }}>
                                                <Box>
                                                    <Typography variant="h6">
                                                        {s.survey.name.substring(0, 100)}
                                                        {s.survey.name.length > 99 ? '...' : ''}
                                                    </Typography>
                                                    <Typography variant="caption">
                                                        {s.survey.description.substring(0, 220)}
                                                        {s.survey.description.length > 219 ? '...' : ''}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography>{calculatePercentage(s)}</Typography>
                                                    <Box>
                                                        <LinearProgress
                                                            value={progressValue(s)}
                                                            variant="determinate"
                                                            color={s.submitted ? 'success' : 'primary'}
                                                        />
                                                        {s.submitted ? (
                                                            <Typography variant="caption" color="green">
                                                                {t('survey.MySurveysPage.cards.confirmedDesc')}
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant="caption" color="primary">
                                                                {t('survey.MySurveysPage.cards.unconfirmedDesc')}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                    </CardContent>
                                    <CardActions>
                                        <CommonButton btnType={ButtonTypeEnum.EDIT} onClick={() => navigateToForm(s.uuid)} sx={{ mr: 2 }}>
                                            {t('survey.MySurveysPage.cardActions.button.edit')}
                                        </CommonButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                </Grid>
            ) : (
                <EmptyState message={t('survey.MySurveysPage.EmptyState.message')} icon={<ShareSharp />} />
            )}
        </Page>
    );
};

export default MySurveysPage;
