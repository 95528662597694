import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import auth from '../../auth/auth';
import { getAllOrganizations, getOrganizationDetails, resetActiveOrganization } from '../../slices/organizations';
import { PrivateRoutes } from '../../routes';
import { OrganizationOut } from 'organizations-service-api';
import { resetOrganizationGroups } from '../../slices/groups';
import { clearCertStatuses } from '../../slices/fina-lcp';

const OrganizationChooser: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { allOrganizations, selectedOrganization } = useAppSelector((state) => state.organizations);
    const [ organizationUuid, setOrganizationUuid ] = useState<string>(selectedOrganization?.uuid ?? '');

    useEffect(() => {
        if (selectedOrganization && !organizationUuid) {
            setOrganizationUuid(selectedOrganization.uuid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!allOrganizations) {
            dispatch(getAllOrganizations());
        }

        if (selectedOrganization && !organizationUuid) {
            setOrganizationUuid(selectedOrganization.uuid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ allOrganizations, selectedOrganization ]);

    const handleOrganizationChange = (event: SelectChangeEvent): void => {
        dispatch(resetActiveOrganization());
        dispatch(resetOrganizationGroups());

        const orgUuid = event.target.value as string;
        setOrganizationUuid(orgUuid);
        auth.setOrganizationUuid(orgUuid);
        dispatch(getOrganizationDetails(orgUuid));
        dispatch(clearCertStatuses());

        window.history.replaceState(
            {},
            '',
            PrivateRoutes
                .private_organizations_details_route
                .path
                .replace(
                    ':organizationUuid',
                    orgUuid
                )
        );
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="idOrganizationSelect">
                { t('select.company') }
            </InputLabel>
            <Select
                id="selectCompanyId"
                fullWidth
                // disabled={!isLoading}
                value={ organizationUuid }
                onChange={ handleOrganizationChange }
                label={ t('select.company') }>
                { allOrganizations?.map((o: OrganizationOut) => {
                    return (
                        <MenuItem key={ o.uuid } value={ o.uuid }>
                            { `${ o.name } - ${ o.oib }` }
                        </MenuItem>
                    );
                }) }
            </Select>
        </FormControl>
    );
};

export default OrganizationChooser;
