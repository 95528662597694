import idyAxios from './idyAxios';
import { IdyCertifierControllerApi, IdyCertifierControllerV2Api } from 'idy-certifier-api';
import { UserConfigControllerApi } from 'consent-manager-api';
import { ContactsServiceControllerApi } from 'contacts-service-api';
import { DataControllerApi } from 'document-scanner-api';
import { FinaLcpCertifierV2Api } from 'fina-lcp-certifier-api';
import { UserDocumentSignerControllerApi, UserDocumentSimpleSignerControllerApi } from 'user-document-signer-api';
import {
    BundlesControllerApi,
    WsPayControllerApi,
    PayablesControllerApi,
    ProvidedPayableItemsControllerApi,
    TransactionsControllerApi
} from 'payment-service-api';
import { GroupsControllerApi, MembershipsControllerApi, OrganizationsControllerApi as GroupsOrganizationsControllerApi } from 'group-service-api';
import { PinManagerControllerV2Api } from 'pin-manager-api';
import { DataControllerApi as PhoneVerifierDataControllerApi, PhonesControllerApi, PhonesConfigControllerApi } from 'phone-verifier-api';
import { OrganizationsControllerApi, PersonsControllerApi } from 'organizations-service-api';
import {
    AnswersControllerApi,
    OptionsControllerApi,
    QuestionsControllerApi,
    SectionsControllerApi,
    SurveysControllerApi,
    SurveyUsersControllerApi,
    SurveyUsersWithoutAuthControllerApi
} from 'survey-service-api';
import { EmailsControllerApi } from 'email-verifier-api';
import { IdentificationDataControllerApi } from 'profile-manager-api';

let idyCertifierApi: IdyCertifierControllerApi;
let simpleSignerApi: IdyCertifierControllerV2Api;
let consentManagerApi: UserConfigControllerApi;
let contactsApi: ContactsServiceControllerApi;
let documentScannerApi: DataControllerApi;
let emailVerifierApi: EmailsControllerApi;
let finaLcpCertifierApi: FinaLcpCertifierV2Api;
let groupsMembershipApi: MembershipsControllerApi;
let groupsOrganizationsApi: GroupsOrganizationsControllerApi;
let groupsServiceApi: GroupsControllerApi;
let organizationsApi: OrganizationsControllerApi;
let organizationMembershipsApi: PersonsControllerApi;
let bundlesApi: BundlesControllerApi;
let payablesApi: PayablesControllerApi;
let providedPayableItemsApi: ProvidedPayableItemsControllerApi;
let paymentTransactionsApi: TransactionsControllerApi;
let surveyUsersApi: SurveyUsersControllerApi;
let surveyUsersWithoutAuthApi: SurveyUsersWithoutAuthControllerApi;
let surveyApi: SurveysControllerApi;
let answersApi: AnswersControllerApi;
let sectionsApi: SectionsControllerApi;
let questionsApi: QuestionsControllerApi;
let optionsApi: OptionsControllerApi;
let phonesApi: PhonesControllerApi;
let phonesConfigApi: PhonesConfigControllerApi;
let phoneVerifierApi: PhoneVerifierDataControllerApi;
let pinManagerApi: PinManagerControllerV2Api;
let profileManagerApi: IdentificationDataControllerApi;
let userDocumentSignerApi: UserDocumentSignerControllerApi;
let userDocumentSimpleSignerApi: UserDocumentSimpleSignerControllerApi;
let wsPayResultApi: WsPayControllerApi;

export default {
    answersApi(): AnswersControllerApi {
        if (!answersApi) {
            updateAnswersApi();
        }

        return answersApi;
    },

    bundlesApi(): BundlesControllerApi {
        if (!bundlesApi) {
            updateBundlesApi();
        }

        return bundlesApi;
    },

    consentManagerApi(): UserConfigControllerApi {
        if (!consentManagerApi) {
            updateConsentManagerApi();
        }

        return consentManagerApi;
    },

    contactsApi(): ContactsServiceControllerApi {
        if (!contactsApi) {
            updateContactsApi();
        }

        return contactsApi;
    },

    documentScannerApi(): DataControllerApi {
        if (!documentScannerApi) {
            updateDocumentScannerApi();
        }

        return documentScannerApi;
    },

    emailVerifierApi(): EmailsControllerApi {
        if (!emailVerifierApi) {
            updateEmailVerifierApi();
        }

        return emailVerifierApi;
    },

    finaLcpCertifierApi(): FinaLcpCertifierV2Api {
        if (!finaLcpCertifierApi) {
            updateFinaLcpCertifierApi();
        }

        return finaLcpCertifierApi;
    },

    groupsMembershipApi(): MembershipsControllerApi {
        if (!groupsMembershipApi) {
            updateGroupsMembershipApi();
        }

        return groupsMembershipApi;
    },

    groupsOrganizationsApi(): GroupsOrganizationsControllerApi {
        if (!groupsOrganizationsApi) {
            updateGroupsOrganizationsApi();
        }

        return groupsOrganizationsApi;
    },

    groupsServiceApi(): GroupsControllerApi {
        if (!groupsServiceApi) {
            updateGroupsServiceApi();
        }

        return groupsServiceApi;
    },

    idyCertifierApi(): IdyCertifierControllerApi {
        if (!idyCertifierApi) {
            updateIdyCertifierApi();
        }

        return idyCertifierApi;
    },

    optionsApi(): OptionsControllerApi {
        if (!optionsApi) {
            updateOptionsApi();
        }

        return optionsApi;
    },

    organizationMembershipsApi(): PersonsControllerApi {
        if (!organizationMembershipsApi) {
            updateOrganizationMembershipsApi();
        }

        return organizationMembershipsApi;
    },

    organizationsApi(): OrganizationsControllerApi {
        if (!organizationsApi) {
            updateOrganizationsApi();
        }

        return organizationsApi;
    },

    payablesApi(): PayablesControllerApi {
        if (!payablesApi) {
            updatePayablesApi();
        }

        return payablesApi;
    },

    paymentTransactionsApi(): TransactionsControllerApi {
        if (!paymentTransactionsApi) {
            updatePayableTransactionsApi();
        }

        return paymentTransactionsApi;
    },

    phonesApi(): PhonesControllerApi {
        if (!phonesApi) {
            updatePhonesApi();
        }

        return phonesApi;
    },

    phonesConfigApi(): PhonesConfigControllerApi {
        if (!phonesConfigApi) {
            updatePhonesConfigApi();
        }

        return phonesConfigApi;
    },

    phoneVerifierApi(): PhoneVerifierDataControllerApi {
        if (!phoneVerifierApi) {
            updatePhoneVerifierApi();
        }

        return phoneVerifierApi;
    },

    pinManagerApi(): PinManagerControllerV2Api {
        if (!pinManagerApi) {
            updatePinManagerApi();
        }

        return pinManagerApi;
    },

    profileManagerApi(): IdentificationDataControllerApi {
        if (!profileManagerApi) {
            updateProfileManagerApi();
        }

        return profileManagerApi;
    },

    providedPayableItemsApi(): ProvidedPayableItemsControllerApi {
        if (!providedPayableItemsApi) {
            updateProvidedPayableItemsApi();
        }

        return providedPayableItemsApi;
    },

    questionsApi(): QuestionsControllerApi {
        if (!questionsApi) {
            updateQuestionsApi();
        }

        return questionsApi;
    },

    sectionsApi(): SectionsControllerApi {
        if (!sectionsApi) {
            updateSectionsApi();
        }

        return sectionsApi;
    },

    simpleSignerApi(): IdyCertifierControllerV2Api {
        if (!simpleSignerApi) {
            updateSimpleSignerApi();
        }

        return simpleSignerApi;
    },

    surveyApi(): SurveysControllerApi {
        if (!surveyApi) {
            updateSurveyApi();
        }

        return surveyApi;
    },

    surveyUsersApi(): SurveyUsersControllerApi {
        if (!surveyUsersApi) {
            updateSurveyUsersApi();
        }

        return surveyUsersApi;
    },

    surveyUsersWithoutAuthApi(): SurveyUsersWithoutAuthControllerApi {
        if (!surveyUsersWithoutAuthApi) {
            updateSurveyUsersWithoutAuthApi();
        }

        return surveyUsersWithoutAuthApi;
    },

    update() {
        updateAnswersApi();
        updateBundlesApi();
        updateConsentManagerApi();
        updateContactsApi();
        updateDocumentScannerApi();
        updateEmailVerifierApi();
        updateFinaLcpCertifierApi();
        updateGroupsMembershipApi();
        updateGroupsOrganizationsApi();
        updateGroupsServiceApi();
        updateIdyCertifierApi();
        updateOptionsApi();
        updateOrganizationsApi();
        updateOrganizationMembershipsApi();
        updatePayablesApi();
        updatePayableTransactionsApi();
        updateProvidedPayableItemsApi();
        updatePhonesApi();
        updatePhonesConfigApi();
        updatePhoneVerifierApi();
        updatePinManagerApi();
        updateProfileManagerApi();
        updateQuestionsApi();
        updateSectionsApi();
        updateSimpleSignerApi();
        updateSurveyApi();
        updateSurveyUsersApi();
        updateSurveyUsersWithoutAuthApi();
        updateUserDocumentSignerApi();
        updateUserDocumentSimpleSignerApi();
        updateWsPayResultApi();
    },

    userDocumentSignerApi(): UserDocumentSignerControllerApi {
        if (!userDocumentSignerApi) {
            updateUserDocumentSignerApi();
        }

        return userDocumentSignerApi;
    },

    userDocumentSimpleSignerApi(): UserDocumentSimpleSignerControllerApi {
        if (!userDocumentSignerApi) {
            updateUserDocumentSimpleSignerApi();
        }

        return userDocumentSimpleSignerApi;
    },

    wsPayResultApi(): WsPayControllerApi {
        if (!wsPayResultApi) {
            updateWsPayResultApi();
        }
        return wsPayResultApi;
    }
};

function updateAnswersApi() {
    answersApi = new AnswersControllerApi(void 0, process.env.REACT_APP_SURVEY_SERVICE_API_URL, idyAxios.instance());
}

function updateBundlesApi() {
    bundlesApi = new BundlesControllerApi(void 0, process.env.REACT_APP_PAYMENT_SERVICE_API_URL, idyAxios.instance());
}

function updateConsentManagerApi() {
    consentManagerApi = new UserConfigControllerApi(void 0, process.env.REACT_APP_CONSENT_MANAGER_API_URL, idyAxios.instance());
}

function updateContactsApi() {
    contactsApi = new ContactsServiceControllerApi(void 0, process.env.REACT_APP_CONTACTS_SERVICE_API_URL, idyAxios.instance());
}

function updateDocumentScannerApi() {
    documentScannerApi = new DataControllerApi(void 0, process.env.REACT_APP_DOCUMENT_SCANNER_API_URL, idyAxios.instance());
}

function updateEmailVerifierApi() {
    emailVerifierApi = new EmailsControllerApi(void 0, process.env.REACT_APP_EMAIL_VERIFIER_API_URL, idyAxios.instance());
}

function updateFinaLcpCertifierApi() {
    finaLcpCertifierApi = new FinaLcpCertifierV2Api(void 0, process.env.REACT_APP_FINA_LCP_API_URL, idyAxios.instance());
}

function updateGroupsMembershipApi() {
    groupsMembershipApi = new MembershipsControllerApi(void 0, process.env.REACT_APP_GROUPS_API_URL, idyAxios.instance());
}

function updateGroupsOrganizationsApi() {
    groupsOrganizationsApi = new GroupsOrganizationsControllerApi(void 0, process.env.REACT_APP_GROUPS_API_URL, idyAxios.instance());
}

function updateGroupsServiceApi() {
    groupsServiceApi = new GroupsControllerApi(void 0, process.env.REACT_APP_GROUPS_API_URL, idyAxios.instance());
}

function updateIdyCertifierApi() {
    idyCertifierApi = new IdyCertifierControllerApi(void 0, process.env.REACT_APP_IDY_CERTIFIER_API_URL, idyAxios.instance());
}

function updateOptionsApi() {
    optionsApi = new OptionsControllerApi(void 0, process.env.REACT_APP_SURVEY_SERVICE_API_URL, idyAxios.instance());
}

function updateOrganizationsApi() {
    organizationsApi = new OrganizationsControllerApi(void 0, process.env.REACT_APP_ORGANIZATIONS_API_URL, idyAxios.instance());
}

function updateOrganizationMembershipsApi() {
    organizationMembershipsApi = new PersonsControllerApi(void 0, process.env.REACT_APP_ORGANIZATIONS_API_URL, idyAxios.instance());
}

function updatePayableTransactionsApi() {
    paymentTransactionsApi = new TransactionsControllerApi(void 0, process.env.REACT_APP_PAYMENT_SERVICE_API_URL, idyAxios.instance());
}

function updatePayablesApi() {
    payablesApi = new PayablesControllerApi(void 0, process.env.REACT_APP_PAYMENT_SERVICE_API_URL, idyAxios.instance());
}

function updateProvidedPayableItemsApi() {
    providedPayableItemsApi = new ProvidedPayableItemsControllerApi(void 0, process.env.REACT_APP_PAYMENT_SERVICE_API_URL, idyAxios.instance());
}

function updatePhonesApi() {
    phonesApi = new PhonesControllerApi(void 0, process.env.REACT_APP_PHONE_VERIFIER_API_URL, idyAxios.instance());
}

function updatePhonesConfigApi() {
    phonesConfigApi = new PhonesConfigControllerApi(void 0, process.env.REACT_APP_PHONE_VERIFIER_API_URL, idyAxios.instance());
}

function updatePhoneVerifierApi() {
    phoneVerifierApi = new PhoneVerifierDataControllerApi(void 0, process.env.REACT_APP_PHONE_VERIFIER_API_URL, idyAxios.instance());
}

function updatePinManagerApi() {
    pinManagerApi = new PinManagerControllerV2Api(void 0, process.env.REACT_APP_PIN_MANAGER_API_URL, idyAxios.instance());
}

function updateProfileManagerApi() {
    profileManagerApi = new IdentificationDataControllerApi(void 0, process.env.REACT_APP_PROFILE_MANAGER_API_URL, idyAxios.instance());
}

function updateQuestionsApi() {
    questionsApi = new QuestionsControllerApi(void 0, process.env.REACT_APP_SURVEY_SERVICE_API_URL, idyAxios.instance());
}

function updateSectionsApi() {
    sectionsApi = new SectionsControllerApi(void 0, process.env.REACT_APP_SURVEY_SERVICE_API_URL, idyAxios.instance());
}

function updateSimpleSignerApi() {
    simpleSignerApi = new IdyCertifierControllerV2Api(void 0, process.env.REACT_APP_IDY_CERTIFIER_API_URL, idyAxios.instance());
}

function updateSurveyApi() {
    surveyApi = new SurveysControllerApi(void 0, process.env.REACT_APP_SURVEY_SERVICE_API_URL, idyAxios.instance());
}

function updateSurveyUsersApi() {
    surveyUsersApi = new SurveyUsersControllerApi(void 0, process.env.REACT_APP_SURVEY_SERVICE_API_URL, idyAxios.instance());
}

function updateSurveyUsersWithoutAuthApi() {
    surveyUsersWithoutAuthApi = new SurveyUsersWithoutAuthControllerApi(void 0, process.env.REACT_APP_SURVEY_SERVICE_API_URL);
}

function updateUserDocumentSignerApi() {
    userDocumentSignerApi = new UserDocumentSignerControllerApi(void 0, process.env.REACT_APP_USER_DOCUMENT_SIGNER_API_URL, idyAxios.instance());
}

function updateUserDocumentSimpleSignerApi() {
    userDocumentSimpleSignerApi = new UserDocumentSimpleSignerControllerApi(void 0, process.env.REACT_APP_USER_DOCUMENT_SIGNER_API_URL);
}

function updateWsPayResultApi() {
    wsPayResultApi = new WsPayControllerApi(void 0, process.env.REACT_APP_PAYMENT_SERVICE_API_URL, idyAxios.instance());
}
