import type { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { PaymentsHelper } from '../../../utils/payments-helper';
import pricingPlan2 from '../../../assets/img/pricing/plan2.svg';
import SignatureBundleItem from './SignatureBundleItem';
import { useTranslation } from 'react-i18next';
import useSettings from '../../../hooks/useSettings';
import { THEMES } from '../../../common/constants';
import { Bundle } from 'payment-service-api';

interface ISignaturesListProps {
    isLoading: boolean;
    signatureBundles?: Bundle[];
}

const SignaturesList: FC<ISignaturesListProps> = ({ isLoading, signatureBundles }) => {
    const { t } = useTranslation();
    const { settings } = useSettings();

    const isDarkMode = settings.theme === THEMES.DARK;

    const showItems = () => {
        if (signatureBundles?.length && signatureBundles?.length > 0) {
            return (
                <Grid container spacing={ 2 } justifyContent='center'>
                    { signatureBundles.map((signatureBundle) => {
                        return (
                            <Grid item xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 } xl={ 4 } key={ signatureBundle.uuid } sx={ { mb: 5, mt: 7 } }>
                                <SignatureBundleItem uuid={ signatureBundle.uuid }
                                                     currency={ signatureBundle.amountCurrency?.currency }
                                                     description={ PaymentsHelper.getSignatureDescription(signatureBundle.placeholderName) }
                                                     features={ PaymentsHelper.getSignatureFeatures(signatureBundle.payableGroups) }
                                                     image={ pricingPlan2 }
                                                     name={ PaymentsHelper.getSignatureBundleName(signatureBundle.placeholderName) }
                                                     price={ signatureBundle.amountCurrency?.amount }
                                                     sx={ {
                                                         height: '100%',
                                                         maxWidth: 460,
                                                         mx: 'auto'
                                                     } }
                                />
                            </Grid>
                        );
                    }) }
                </Grid>
            );
        }

        return null;
    };

    return isLoading ? null : (
        <>
            <Box
                sx={ {
                    // backgroundColor: theme.palette.background.paper,
                    borderTop: isDarkMode ? '1px solid #2e333b' : '1px solid #dcdcdc',
                    // boxShadow: 'inset 0 17px 29px -7px rgba(0,0,0,0.3), inset -7px 0 9px -7px rgba(0,0,0,0.7)',
                    textAlign: 'center'
                } }>
                <Typography variant='h6' sx={ { mt: 2 } }>
                    { t('shop.signatures.title') }
                </Typography>
                { showItems() }
            </Box>
        </>
    );
};

export default SignaturesList;
