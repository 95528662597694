import type { FC } from 'react';
import { Answer, AnswerOption, Option, OptionTypeEnum } from 'survey-service-api';
import { Box } from '@mui/material';
import AnsweredCheckbox from './AnsweredCheckbox';
import AnsweredInput from './AnsweredInput';
import AnsweredRadio from './AnsweredRadio';

interface IAnswerBoxProps {
    answer?: Answer;
}

const AnswerBox: FC<IAnswerBoxProps> = (props) => {
    const { answer } = props;

    const isAnswered = (questionOption: Option, answerOptions: AnswerOption[]): boolean => {
        return answerOptions.map((value) => value.option?.uuid).includes(questionOption.uuid);
    };

    const inputText = (questionOption: Option, answerOptions: AnswerOption[]): string | undefined => {
        return answerOptions.find((value) => value.option?.uuid === questionOption.uuid)?.inputText;
    };

    return (
        <Box>
            {answer &&
                answer.question.options.map((option: Option) => (
                    <Box key={option.uuid}>
                        {option.type === OptionTypeEnum.Input && <AnsweredInput inputText={inputText(option, answer.answeredOptions)} label={option.label} />}
                        {option.type === OptionTypeEnum.Checkbox && (
                            <AnsweredCheckbox checked={isAnswered(option, answer.answeredOptions)} label={option.label} />
                        )}
                        {option.type === OptionTypeEnum.Radio && <AnsweredRadio checked={isAnswered(option, answer.answeredOptions)} label={option.label} />}
                    </Box>
                ))}
        </Box>
    );
};

export default AnswerBox;
