import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { AnswerOption, Option, OptionAnswerRequest, OptionTypeEnum } from 'survey-service-api';
import { Box, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';

interface IRadioButtonQuestionProps {
    answerOptions: (options: OptionAnswerRequest[]) => void;
    options: Option[];
    prefilledOption?: AnswerOption;
}

const RadioButtonQuestion: FC<IRadioButtonQuestionProps> = (props) => {
    const { answerOptions, options, prefilledOption } = props;

    const [selectedOption, setSelectedOption] = useState<OptionAnswerRequest>(
        prefilledOption
            ? ({
                  optionUuid: prefilledOption.option?.uuid,
                  inputValue: prefilledOption.option?.label,
              } as OptionAnswerRequest)
            : ({
                  optionUuid: options[0].uuid,
                  inputValue: options[0].label,
              } as OptionAnswerRequest)
    );

    const [inputValue, setInputValue] = useState<string>('');

    useEffect(() => {
        if (selectedOption) {
            answerOptions(new Array(selectedOption));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (value) {
            setSelectedOption({ optionUuid: name, inputValue: value });
            setInputValue('');
        }
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>, optionUuid: string) => {
        const { value } = event.target;
        setSelectedOption({ optionUuid: optionUuid, inputValue: value });
        setInputValue(value);
    };

    return (
        <Box>
            <RadioGroup value={selectedOption.inputValue} name={selectedOption.optionUuid} onChange={handleChange}>
                {options && options.filter(value => value.type === OptionTypeEnum.Radio).map((o: Option) => <FormControlLabel key={o.uuid} control={<Radio name={o.uuid} value={o.label} />} label={o.label} />)}
            </RadioGroup>
            {options && options.filter(value => value.type === OptionTypeEnum.Input).map((o: Option) => <TextField
                size="small"
                sx={{ mr: 2, mt: 2 }}
                key={o.uuid}
                id="outlined-controlled"
                value={inputValue}
                label={o.label}
                placeholder={o.label}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeInput(event, o.uuid);
                }}
            />)}

        </Box>
    );
};

export default RadioButtonQuestion;
