import type { FC } from 'react';
import { useRef, useState } from 'react';
import { Box, ButtonBase, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import {
    ArrowForward,
    ForwardToInbox,
    MoreVertRounded,
    VerifiedUserRounded,
    Visibility,
    VisibilityOff
} from '@mui/icons-material';
import { DocumentInfo, Person } from 'user-document-signer-api';
import { DocumentsHelper, isVisibleForCurrentUserUds } from '../../utils/documents-helper';
import { DocumentVisibilityType } from '../../model/common/documents/DocumentVisibilityType';
import { useAppSelector } from '../../store/hooks';
import auth from '../../auth/auth';
import { useTranslation } from 'react-i18next';

interface IDocumentActionsPopover {
    document: DocumentInfo;
    onDocumentVisibilityChange: (document: DocumentInfo, visibilityType: DocumentVisibilityType) => void;
    onSendDocumentNotification: (document: DocumentInfo) => void;
    onViewDocumentDetails: (document: DocumentInfo) => void;
    onSignDocument: (documentUuid: string) => void;
}

const DocumentActionsPopover: FC<IDocumentActionsPopover> = (props: IDocumentActionsPopover) => {
    const {
        document,
        onDocumentVisibilityChange,
        onSendDocumentNotification,
        onSignDocument,
        onViewDocumentDetails
    } = props;

    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const { t } = useTranslation();

    const [ actionPopoverOpen, setActionPopoverOpen ] = useState<boolean>(false);

    const { activeGroup } = useAppSelector(state => state.groups);
    const userUuid = auth.getUserUuid();

    const onSendDocumentNotificationHandler = (document: DocumentInfo) => {
        setActionPopoverOpen(false);
        onSendDocumentNotification(document);
    };

    const onSignDocumentHandler = (documentUuid: string) => {
        setActionPopoverOpen(false);
        onSignDocument(documentUuid);
    };

    const onDocumentVisibilityChangeHandler = (document: DocumentInfo, documentVisibilityType: DocumentVisibilityType) => {
        setActionPopoverOpen(false);
        onDocumentVisibilityChange(document, documentVisibilityType);
    };

    const showVisibilityActions = (document: DocumentInfo) => {
        const isVisible = isVisibleForCurrentUserUds(userUuid, document, activeGroup?.group.uuid);
        return (
            <MenuItem
                onClick={ () => onDocumentVisibilityChangeHandler(document, isVisible ? DocumentVisibilityType.HIDDEN : DocumentVisibilityType.VISIBLE) }>
                <ListItemIcon>
                    {
                        isVisible ? (
                            <VisibilityOff sx={ { cursor: 'pointer', mr: 2, opacity: 0.7 } }
                                           fontSize='small' />
                        ) : (
                            <Visibility sx={ { cursor: 'pointer', mr: 2, opacity: 0.7 } }
                                        fontSize='small' />
                        )
                    }
                </ListItemIcon>
                <ListItemText primary={
                    <Typography color='textSecondary' variant='overline'>
                        {
                            isVisible ?
                                t('businessProfile.details.documents.action.visibility.hide') :
                                t('businessProfile.details.documents.action.visibility.show')
                        }
                    </Typography>
                } />
            </MenuItem>
        );
    };

    const shouldShowNotifyAgainButton = (document: DocumentInfo): boolean => {
        return (
            activeGroup?.group.uuid !== undefined &&
            document?.sharedWith !== undefined &&
            document?.sharedWith?.length > 0 &&
            document?.sharedWith.some((person: Person) => !person.signatureInfo?.signed)
        ) || (
            document?.sharedWith !== undefined &&
            document?.sharedWith?.length > 0 &&
            document?.owner?.uuid === userUuid &&
            document?.sharedWith?.some((person: Person) => !person.signatureInfo?.signed && person.uuid !== userUuid)
        );
    };

    return (
        <>
            <Box ref={ anchorRef }
                 component={ ButtonBase }
                 onClick={ () => setActionPopoverOpen(true) }>
                <MoreVertRounded sx={ { cursor: 'pointer', mr: 2, opacity: 0.7 } }
                                 fontSize='small'
                                 onClick={ () => setActionPopoverOpen(false) } />
            </Box>
            <Popover anchorEl={ anchorRef.current }
                     anchorOrigin={ {
                         horizontal: 'center',
                         vertical: 'bottom'
                     } }
                     keepMounted
                     open={ actionPopoverOpen }
                     onClose={ () => setActionPopoverOpen(false) }>
                { shouldShowNotifyAgainButton(document) && (
                    <MenuItem onClick={ () => onSendDocumentNotificationHandler(document) }>
                        <ListItemIcon>
                            <ForwardToInbox sx={ { cursor: 'pointer', mr: 2, opacity: 0.7 } }
                                            fontSize='small' />
                        </ListItemIcon>
                        <ListItemText primary={
                            <Typography color='textSecondary' variant='overline'>
                                { t('document.modal.sendDocument.action.send') }
                            </Typography>
                        } />
                    </MenuItem>
                ) }
                { showVisibilityActions(document) }
                <MenuItem onClick={ () => onViewDocumentDetails(document) }>
                    <ListItemIcon>
                        <ArrowForward sx={ { cursor: 'pointer', opacity: 0.7 } }
                                      fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={
                        <Typography color='textSecondary' variant='overline'>
                            { t('dashboard.sidebar.documentDetails.title') }
                        </Typography>
                    } />
                </MenuItem>
                {
                    !DocumentsHelper.isSignedByCurrentUser(document) && (
                        <MenuItem onClick={ () => onSignDocumentHandler(document.uuid) }>
                            <ListItemIcon>
                                <VerifiedUserRounded sx={ { color: 'primary.main', cursor: 'pointer' } }
                                                     fontSize='small' />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography variant='overline' sx={ { color: 'primary.main' } }>
                                    { t('dashboard.sidebar.documentDetails.button.signDocument') }
                                </Typography>
                            } />
                        </MenuItem>
                    )
                }
            </Popover>
        </>
    );
};

export default DocumentActionsPopover;
