import { FC, useState } from 'react';
import { Card, CardContent, Zoom } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LogoImage from '../../assets/img/branding-logo.png';
import FirstScreenImage from '../../assets/img/tutorial/1.png';
import SecondScreenImage from '../../assets/img/tutorial/2.png';
import ThirdScreenImage from '../../assets/img/tutorial/4.png';
import FourthScreenImage from '../../assets/img/tutorial/3.png';
import FifthScreenImage from '../../assets/img/tutorial/5.png';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';

const Tutorial: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleLogin = () => {
        navigate('/refresh');
    };

    return (
        <Card>
            <CardContent
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 800,
                    justifyContent: 'space-between',
                    position: 'relative',
                    px: 4,
                    py: 2,
                }}>
                {activeStep !== 4 && (
                    <CommonButton btnType={ButtonTypeEnum.NO_ICON_CONTAINED} sx={{ position: 'absolute', right: 20, top: 20 }} onClick={handleLogin}>
                        {t('tutorial.skipbutton')}
                    </CommonButton>
                )}
                {activeStep === 0 && (
                    <Zoom in={activeStep === 0} style={{ transitionDelay: activeStep === 0 ? '250ms' : '0ms' }}>
                        <div className="tutorial-divs">
                            <img src={FirstScreenImage} alt="image_1" width={'50%'} />
                            <img src={LogoImage} alt="logo_image" width={'50%'} />
                            <h3>{t('tutorial.screenone.title')}</h3>
                            <p className="tutorial-paragraphs">{t('tutorial.screenone.p1')}</p>
                            <p className="tutorial-paragraphs">{t('tutorial.screenone.p2')}</p>
                            <p className="tutorial-paragraphs">{t('tutorial.screenone.p3')}</p>
                            <p className="tutorial-paragraphs">{t('tutorial.screenone.p4')}</p>
                        </div>
                    </Zoom>
                )}
                {activeStep === 1 && (
                    <Zoom in={activeStep === 1} style={{ transitionDelay: activeStep === 1 ? '250ms' : '0ms' }}>
                        <div className="tutorial-divs">
                            <img src={SecondScreenImage} alt="image_2" width={'50%'} />
                            <h3>{t('tutorial.screentwo.title')}</h3>
                            <p className="tutorial-paragraphs">{t('tutorial.screentwo.p1')}</p>
                            <p className="tutorial-paragraphs">{t('tutorial.screentwo.p2')}</p>
                        </div>
                    </Zoom>
                )}
                {activeStep === 2 && (
                    <Zoom in={activeStep === 2} style={{ transitionDelay: activeStep === 2 ? '250ms' : '0ms' }}>
                        <div className="tutorial-divs">
                            <img src={ThirdScreenImage} alt="image_3" width={'50%'} />
                            <h3>{t('tutorial.screenthree.title')}</h3>
                            <p className="tutorial-paragraphs">
                                {t('tutorial.screenthree.p')}
                                <ul>
                                    <li>{t('tutorial.screenthree.li1')}</li>
                                    <li>{t('tutorial.screenthree.li2')}</li>
                                    <li>{t('tutorial.screenthree.li3')}</li>
                                    <li>{t('tutorial.screenthree.li4')}</li>
                                    <li>{t('tutorial.screenthree.li5')}</li>
                                </ul>
                            </p>
                        </div>
                    </Zoom>
                )}
                {activeStep === 3 && (
                    <Zoom in={activeStep === 3} style={{ transitionDelay: activeStep === 3 ? '250ms' : '0ms' }}>
                        <div className="tutorial-divs">
                            <img src={FourthScreenImage} alt="image_4" width={'50%'} />
                            <h3>{t('tutorial.screenfour.title')}</h3>
                            <p className="tutorial-paragraphs">{t('tutorial.screenfour.p1')}</p>
                            <p className="tutorial-paragraphs">{t('tutorial.screenfour.p2')}</p>
                            <p className="tutorial-paragraphs">{t('tutorial.screenfour.p3')}</p>
                        </div>
                    </Zoom>
                )}
                {activeStep === 4 && (
                    <Zoom in={activeStep === 4} style={{ transitionDelay: activeStep === 4 ? '250ms' : '0ms' }}>
                        <div className="tutorial-divs">
                            <img src={FifthScreenImage} alt="image_5" width={'50%'} />
                            <h3>{t('tutorial.screenfive.title')}</h3>
                            <p className="tutorial-paragraphs">{t('tutorial.screenfive.p')}</p>
                            <CommonButton btnType={ButtonTypeEnum.NO_ICON_CONTAINED} sx={{ my: 3 }} onClick={handleLogin}>
                                {t('tutorial.screenfive.button')}
                            </CommonButton>
                        </div>
                    </Zoom>
                )}
                <MobileStepper
                    variant="dots"
                    steps={5}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width: '100%' }}
                    nextButton={
                        <CommonButton btnType={ButtonTypeEnum.NO_ICON} onClick={handleNext} disabled={activeStep === 4}>
                            {t('dashboard.sidebar.documents.newDocument.createUserContact.button.next')}
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </CommonButton>
                    }
                    backButton={
                        <CommonButton btnType={ButtonTypeEnum.NO_ICON} onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            {t('dashboard.sidebar.documents.newDocument.createUserContact.button.back')}
                        </CommonButton>
                    }
                />
            </CardContent>
        </Card>
    );
};

export default Tutorial;
