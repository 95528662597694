import { AppSettings } from '../common/app-settings';
import Cookies from 'universal-cookie';
import auth, { CLIENT_SESSION_UUID } from '../auth/auth';
import { IdentificationData } from 'profile-manager-api';
import { ApiGetDocumentsResponse } from 'document-scanner-api';

export class Misc {
    public static checkForIdyLanguage(): string {
        const cookies = new Cookies();
        const idyLanguage = cookies.get(AppSettings.IDY_LANGUAGE);

        if (idyLanguage) {
            auth.setIdyLanguage(idyLanguage);
            cookies.remove(AppSettings.IDY_LANGUAGE);
        }

        return idyLanguage;
    }

    public static checkForClientSessionUuid(): void {
        const clientSessionUuidCookieKey = CLIENT_SESSION_UUID + '_' + Misc.getEnvironment();
        const cookies = new Cookies();
        const clientSessionUuid = cookies.get(clientSessionUuidCookieKey);

        if (clientSessionUuid) {
            auth.setClientSessionUuid(clientSessionUuid);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static isEmptyObject(obj: any) {
        return !obj ? true : Object.keys(obj).length === 0;
    }

    public static isLocalEnvironment(): boolean {
        return this.getEnvironment() === 'local';
    }

    public static isDevEnvironment(): boolean {
        return this.getEnvironment() === 'dev';
    }

    public static getEnvironment(): string {
        return process.env.REACT_APP_ENVIRONMENT ?? '';
    }

    public static resolveUserIdentifier(userIdentificationData?: IdentificationData): string {
        if (!userIdentificationData) {
            return '';
        }

        const { emails, phones, fullName } = userIdentificationData;

        if (fullName) {
            return fullName;
        }

        if (emails && emails.length > 0) {
            return emails[0].email;
        }

        if (phones && phones.length > 0) {
            return phones[0].dialCode + phones[0].number;
        }

        return '';
    }

    public static getUserAvatar(profileData: ApiGetDocumentsResponse | undefined): string {
        if(!profileData) {
            return '';
        }

        if (profileData && profileData.documents) {
            const document = profileData.documents.find((doc) => doc?.images?.faceImage?.image !== null);
            return document?.images?.faceImage?.image ?? '';
        }

        return '';
    }
}
