import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import Page from '../../components/shared/Page';
import { useTranslation } from 'react-i18next';
import {
    changeMemberRole,
    changeOrganization,
    deleteMemberFromOrganization,
    deleteOrganization,
    getOrganizationDetails,
    resetActiveOrganization,
    resetFlags
} from '../../slices/organizations';
import { PrivateRoutes } from '../../routes';
import { Avatar, Box, Chip, Divider, Grid, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { DocumentsHelper } from '../../utils/documents-helper';
import OrganizationDetails from '../../components/organizations/OrganizationDetails';
import OrganizationMembers from '../../components/organizations/OrganizationMembers';
import ConfirmationDialog from '../../components/shared/ConfirmationDialog';
import OrganizationChooser from '../../components/organizations/OrganizationChooser';
import OrganizationGroups from '../../components/organizations/OrganizationGroups';
import OrganizationMembersCertificates from '../../components/organizations/OrganizationMembersCertificates';
import SkeletonBlock from '../../components/skeletons/SkeletonBlock';
import { AnalyticsRounded, ArticleRounded, GroupWorkSharp, ListRounded, PersonSearch, Verified } from '@mui/icons-material';
import UploadAvatarModal from '../../components/avatar/UploadAvatarModal';
import { OrganizationPayableStats } from '../../components/organizations/OrganizationPayableStats';
import OrganizationDocuments from '../../components/organizations/OrganizationDocuments';
import auth from '../../auth/auth';
import { MemberOut, MemberOutRoleEnum } from 'organizations-service-api';
import { resetOrganizationGroups } from '../../slices/groups';
import { getEntityPayables, resetOrganizationPayables } from '../../slices/payments';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

const tabs = [
    {
        icon: <ListRounded />,
        label: 'businessProfile.details.tabs.details',
        value: 'details',
        visibleToEveryone: true
    },
    {
        icon: <PersonSearch />,
        label: 'businessProfile.details.tabs.members',
        value: 'members',
        visibleToEveryone: true
    },
    {
        icon: <GroupWorkSharp />,
        label: 'businessProfile.details.tabs.groups',
        value: 'groups',
        visibleToEveryone: true
    },
    {
        icon: <Verified />,
        label: 'businessProfile.details.tabs.certificates',
        value: 'certificates',
        visibleToEveryone: false
    },
    {
        icon: <AnalyticsRounded />,
        label: 'businessProfile.details.tabs.stats',
        value: 'stats',
        visibleToEveryone: false
    },
    {
        icon: <ArticleRounded />,
        label: 'businessProfile.details.tabs.documents',
        value: 'documents',
        visibleToEveryone: true
    }
];

const OrganizationDetailsPage: FC = () => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [ cancelModalOpen, setCancelModalOpen ] = useState<boolean>(false);
    const [ currentTab, setCurrentTab ] = useState<string>('details');
    const [ isAvatarModalOpen, setIsAvatarModelOpen ] = useState<boolean>(false);

    const {
        isAddingMember,
        isApprovingRequest,
        isCancelingRequest,
        isChangingRoleLoading,
        isDeletingMembershipRequest,
        isLoadingDetails,
        isModifyingOrganization,
        selectedOrganization
    } = useAppSelector((state) => state.organizations);

    const { organizationUuid } = params;
    const isOrganizationAdmin = selectedOrganization?.currentUser?.role === MemberOutRoleEnum.Admin ||
        selectedOrganization?.currentUser?.role === MemberOutRoleEnum.Owner;

    useEffect(() => {
        if (!selectedOrganization && organizationUuid) {
            auth.setOrganizationUuid(organizationUuid);
            dispatch(getOrganizationDetails(organizationUuid));
        }

        dispatch(getEntityPayables());

        return () => {
            auth.setOrganizationUuid('');
            dispatch(resetActiveOrganization());
            dispatch(resetOrganizationGroups());
            dispatch(resetOrganizationPayables());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line @typescript-eslint/ban-types
    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    const handleOpenUploadAvatarModal = () => {
        isOrganizationAdmin && setIsAvatarModelOpen(true);
    };
    const handleCloseUploadAvatarModal = () => {
        setIsAvatarModelOpen(false);
    };
    const handleSaveAvatar = (image: string) => {
        dispatch(changeOrganization(
            selectedOrganization?.uuid ?? '',
            undefined,
            image
        ));
        setIsAvatarModelOpen(false);
    };

    const handleBackToBusinessProfileList = () => {
        dispatch(resetFlags());
        navigate(PrivateRoutes.private_organizations_list_route.path);
    };

    const handleDeleteMember = (memberUuid: string) => {
        organizationUuid && dispatch(
            deleteMemberFromOrganization(
                selectedOrganization?.uuid ?? '',
                memberUuid
            )
        );
    };

    const handleChangeMemberRole = (member: MemberOut, role: MemberOutRoleEnum) => {
        dispatch(
            changeMemberRole(
                selectedOrganization?.uuid ?? '',
                member.uuid,
                role
            )
        );
    };

    const handleCancelRequest = () => {
        setCancelModalOpen(true);
    };

    const handleCloseCancelModal = (isCancelingRequest: boolean) => {
        isCancelingRequest && dispatch(deleteOrganization(selectedOrganization?.uuid ?? ''));
        setCancelModalOpen(false);
        isCancelingRequest && navigate(PrivateRoutes.private_organizations_list_route.path);
    };

    const handleSaveOrganizationLogo = (image: string) => {
        dispatch(changeOrganization(
            selectedOrganization?.uuid ?? '',
            undefined,
            undefined,
            image
        ));
    };

    const handleRemoveOrganizationLogo = () => {
        dispatch(changeOrganization(
            selectedOrganization?.uuid ?? '',
            undefined,
            undefined,
            ''
        ));
    };

    return (
        <Page pageTitle={ t('businessProfile.details.title') }
              actionButton={
                  <CommonButton btnType={ ButtonTypeEnum.BACK } onClick={ handleBackToBusinessProfileList }
                                variant="text">
                      { t('businessProfile.page.backToList') }
                  </CommonButton>
              }>
            { isLoadingDetails && <SkeletonBlock height={ 500 } /> }

            { !isLoadingDetails && (
                <>
                    <Grid container justifyContent="space-between" spacing={ 3 }>
                        <Grid item sx={ { alignItems: 'center', display: 'flex', overflow: 'hidden' } }>
                            <Tooltip title={ t('actions.uploadAvatarImage') as string }>
                                <Avatar sx={ { height: 64, mr: 2, width: 64 } } onClick={ handleOpenUploadAvatarModal }
                                        src={ selectedOrganization?.avatar ? selectedOrganization?.avatar : selectedOrganization?.logo }>
                                    { DocumentsHelper.getInitials(selectedOrganization?.name?.toUpperCase() ?? '') }
                                </Avatar>
                            </Tooltip>

                            <div>
                                <Typography variant="h4">{ selectedOrganization?.name }</Typography>

                                <Box sx={ { display: 'flex', alignItems: 'center' } }>
                                    <Typography variant="subtitle2">
                                        { t('businessProfile.details.label.oib') }
                                    </Typography>
                                    <Chip sx={ { ml: 1 } } label={ selectedOrganization?.oib } size="small" />
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={ 12 } sm={ 12 } md={ 4 } lg={ 5 } xl={ 3 }>
                            <OrganizationChooser />
                        </Grid>
                    </Grid>
                    <Tabs indicatorColor="primary"
                          onChange={ handleTabsChange }
                          scrollButtons="auto"
                          sx={ { mt: 3 } }
                          textColor="primary"
                          value={ currentTab }
                          variant="scrollable">
                        { tabs.map((tab) => {
                            return tab.visibleToEveryone || isOrganizationAdmin ? (
                                <Tab key={ tab.value } label={ t(tab.label) } value={ tab.value } icon={ tab.icon } />
                            ) : (
                                <> </>
                            );
                        }) }
                    </Tabs>
                    <Divider />
                    <Box sx={ { mt: 3 } }>
                        { currentTab === 'details' && (
                            <Grid container spacing={ 3 }>
                                <Grid item xs={ 12 }>
                                    <OrganizationDetails isCancelingRequest={ isCancelingRequest }
                                                         isModifyingOrganization={ isModifyingOrganization }
                                                         onCancelRequest={ handleCancelRequest }
                                                         onRemoveImage={ handleRemoveOrganizationLogo }
                                                         onsSaveImage={ handleSaveOrganizationLogo } />
                                </Grid>
                            </Grid>
                        ) }

                        { currentTab === 'members' && (
                            <OrganizationMembers isChangingRoleLoading={ isChangingRoleLoading }
                                                 isDeletingLoading={ isDeletingMembershipRequest }
                                                 isLoadingAction={ isApprovingRequest || isAddingMember }
                                                 onDeleteMember={ handleDeleteMember }
                                                 onChangeMemberRole={ handleChangeMemberRole } />
                        ) }

                        { currentTab === 'groups' && <OrganizationGroups /> }

                        { currentTab === 'certificates' && <OrganizationMembersCertificates /> }

                        { currentTab === 'stats' && <OrganizationPayableStats /> }

                        { currentTab === 'documents' && <OrganizationDocuments /> }
                    </Box>

                    <ConfirmationDialog title={ t('businessProfile.cancelRequest.title') }
                                        message={ t('businessProfile.cancelRequest.message') }
                                        onConfirm={ () => handleCloseCancelModal(true) }
                                        onCancel={ () => handleCloseCancelModal(false) }
                                        open={ cancelModalOpen } />
                </>
            ) }

            <UploadAvatarModal existingImage={ selectedOrganization?.avatar }
                               onCancel={ handleCloseUploadAvatarModal }
                               onSave={ handleSaveAvatar }
                               open={ isAvatarModalOpen }
                               title={ t('') } />
        </Page>
    );
};

export default OrganizationDetailsPage;
