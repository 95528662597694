import type { FC } from 'react';
import { useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { PDFPageProxy } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import Move from '../../assets/icons/Move';

interface IDraggableSignatureProps {
    onEnd: (e: DraggableEvent, data: DraggableData) => void;
    boxDetails?: HTMLDivElement;
    pageDetails: PDFPageProxy;
    signatoryFullName: string;
}

const DraggableSignature: FC<IDraggableSignatureProps> = ({ onEnd }) => {
    const { t } = useTranslation();
    const [ isDragging, setIsDragging ] = useState<boolean>(false);
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    const handleOnEnd = (e: DraggableEvent, data: DraggableData) => {
        setIsDragging(false);
        onEnd(e, data);
    };

    return (
        <Draggable defaultPosition={ { x: 0, y: 0 } }
                   onStop={ handleOnEnd }
                   bounds='parent' onStart={ () => setIsDragging(true) }>
            <Grid container
                  alignItems='center'
                  direction='row'
                  justifyContent='center'
                  sx={ {
                      backgroundColor: 'rgba(86, 100, 210, 0.9)',
                      boxShadow: isDragging ? '0px 0px 10px 0px rgba(0,0,0,0.75)' : 'none',
                      cursor: isDragging ? 'grabbing' : 'grab',
                      height: '7%',
                      position: 'absolute',
                      textAlign: 'center',
                      width: '25%',
                      zIndex: 100000
                  } }>

                { !isDragging &&
                  <Grid item xs={ 3 } md={ 3 } lg={ 3 } sx={ { paddingLeft: 1 } }>
                    <Move color='#fff' size={ smUp ? 35 : 20 } />
                  </Grid>
                }

                <Grid item xs={ 9 } md={ 9 } lg={ 9 }>
                    <Typography color='white' variant='subtitle2'>
                        { isDragging ?
                            t('signatureModal.draggable.label.drop') :
                            t('signatureModal.draggable.placeholder')
                        }
                    </Typography>
                </Grid>

            </Grid>
        </Draggable>
    );
};

export default DraggableSignature;