import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Answer, AnswerOption, AnswerRequest, Question, Section } from 'survey-service-api';
import { Box, Button, IconButton, Paper, StepContent, Typography } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import QuestionBox from './QuestionBox';
import { addAnswer, editAnswer } from '../../slices/survey';
import { ArrowDownward, ArrowUpward, Check, Edit } from '@mui/icons-material';
import AnswerBox from './AnswerBox';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

interface ISectionBoxProps {
    section: Section;
    sharedSurveyUuid: string;
    surveyAnswers: Answer[] | undefined;
    changeTab: () => void;
}

const SectionBox: FC<ISectionBoxProps> = (props) => {
    const { section, sharedSurveyUuid, surveyAnswers, changeTab } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { sharedSurvey } = useAppSelector((state) => state.survey);

    const [ activeStep, setActiveStep ] = useState<number>(0);
    const [ editingQuestion, setEditingQuestion ] = useState<{ questionUuid: string; answerUuid: string } | null>(null);

    useEffect(() => {
        setActiveStep(0);
    }, [ section ]);

    useEffect(() => {
        if (activeStep === section.questions?.length) {
            changeTab();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ activeStep ]);

    const handleNext = (answer: AnswerRequest | undefined) => {
        if (answer) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            if (editingQuestion != null) {
                dispatch(editAnswer(editingQuestion.answerUuid, answer));
            } else {
                dispatch(addAnswer(sharedSurveyUuid, answer));
            }

            setEditingQuestion(null);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setEditingQuestion(null);
    };

    const handleSkip = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setEditingQuestion(null);
    };

    const isAnswered = (question: Question, surveyAnswers: Answer[] | undefined): boolean => {
        if (!surveyAnswers) {
            return false;
        }

        return surveyAnswers.some((answerOption) => answerOption.question.uuid === question.uuid);
    };

    const handleEditQuestion = (questionUuid: string, answerUuid: string) => {
        setEditingQuestion({ questionUuid: questionUuid, answerUuid: answerUuid });
    };

    const handleStepClick = (step: number) => {
        setActiveStep(step);
    };

    const getPrefilledValues = (question: Question, answers?: Answer[]): AnswerOption[] => {
        if (answers) {
            return answers[answers.findIndex((value) => value.question.uuid === question.uuid)].answeredOptions;
        }

        return [];
    };

    return (
        <Box>
            <Box sx={ { mb: 2 } }>
                <Typography variant="body1" component="div">
                    <Box sx={ { fontStyle: 'italic' } }>{ section.description }</Box>
                </Typography>
            </Box>
            <Stepper activeStep={ activeStep } orientation="vertical">
                { section &&
                    section.questions &&
                    section.questions?.map((q: Question, index: number) => (
                        <Step key={ q.uuid }>
                            { isAnswered(q, surveyAnswers) ? (
                                <Box onClick={ () => handleStepClick(index) } sx={ { '&:hover': { cursor: 'pointer' } } }>
                                    <StepLabel icon={ <Check /> }>{ q.questionText }</StepLabel>
                                </Box>
                            ) : (
                                <Box onClick={ () => handleStepClick(index) } sx={ { '&:hover': { cursor: 'pointer' } } }>
                                    <StepLabel>{ q.questionText }</StepLabel>
                                </Box>
                            ) }
                            <StepContent>
                                <Box>
                                    { index > 0 && (
                                        <Button
                                            fullWidth
                                            color="info"
                                            variant="text"
                                            size="small"
                                            sx={ { my: 1 } }
                                            startIcon={ <ArrowUpward /> }
                                            onClick={ handleBack }
                                        />
                                    ) }
                                    { q.predecessorElement && (
                                        <Typography variant="caption" color="grey" component="div">
                                            <Box sx={ { fontStyle: 'italic' } }>{ q.predecessorElement.text }</Box>
                                        </Typography>
                                    ) }
                                    { isAnswered(q, surveyAnswers) ? (
                                        <Box>
                                            { surveyAnswers &&
                                                surveyAnswers.map(
                                                    (answer: Answer) =>
                                                        answer.question.uuid === q.uuid &&
                                                        !editingQuestion && (
                                                            <Box
                                                                key={ answer.uuid }
                                                                sx={ { display: 'flex', alignItems: 'center' } }>
                                                                <AnswerBox answer={ answer } />
                                                                { sharedSurvey && !sharedSurvey.submitted && <Box>
                                                                  <IconButton onClick={ () => handleEditQuestion(q.uuid, answer.uuid) }>
                                                                    <Edit />
                                                                  </IconButton>
                                                                </Box> }
                                                            </Box>
                                                        )
                                                ) }

                                            { editingQuestion?.questionUuid === q.uuid && (
                                                <QuestionBox
                                                    question={ q }
                                                    nextButtonLabel={ t('survey.SectionBox.question.confirm') }
                                                    handleNext={ handleNext }
                                                    prefilledOptions={ getPrefilledValues(q, surveyAnswers) }
                                                />
                                            ) }
                                        </Box>
                                    ) : (
                                        <QuestionBox question={ q } nextButtonLabel={ t('survey.SectionBox.question.confirm') } handleNext={ handleNext } />
                                    ) }
                                    { q.successorElement && (
                                        <Typography variant="caption" color="grey" component="div">
                                            <Box sx={ { fontStyle: 'italic' } }>{ q.successorElement.text }</Box>
                                        </Typography>
                                    ) }
                                    <Button
                                        fullWidth
                                        color="info"
                                        variant="text"
                                        size="small"
                                        sx={ { my: 1 } }
                                        startIcon={ <ArrowDownward /> }
                                        onClick={ handleSkip }
                                    />
                                </Box>
                            </StepContent>
                        </Step>
                    )) }
            </Stepper>
            { section.questions && activeStep === section.questions?.length && (
                <Paper square elevation={ 0 } sx={ { p: 3 } }>
                    <Typography variant="overline">{ t('survey.SectionBox.sectionEnd.message') }</Typography>
                </Paper>
            ) }
        </Box>
    );
};

export default SectionBox;
