import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Grid, Theme, useMediaQuery } from '@mui/material';
import FileDropzone from '../FileDropzone';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Membership } from 'group-service-api';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { AppSettings } from '../../common/app-settings';
import SelectOrganizationUserDocuments from '../organizations/SelectOrganizationUserDocuments';
import { IOrganizationDocument } from '../../model/common/documents/IOrganizationDocument';
import { DocumentSignatureTypeEnum } from 'user-document-signer-api';
import SimpleSignatureCheckbox from './SimpleSignatureCheckbox';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setFilesForUpload } from '../../slices/documents';
import DownloadableBeforeSignatureCheckbox from './DownloadableBeforeSignatureCheckbox';

interface NewDocumentFormProps {
    activeGroup?: Membership;
    isLoading?: boolean;
    onUploadDocument: (files: File[], organizationUserDocuments: IOrganizationDocument[], signatureType: DocumentSignatureTypeEnum | undefined, downloadableBeforeSignature: boolean) => void;
}

const DocumentUpload: FC<NewDocumentFormProps> = (props) => {
    const { activeGroup, isLoading, onUploadDocument } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    const [checked, setChecked] = useState<boolean>(false);
    const [downloadableBeforeSignature, setDownloadableBeforeSignature] = useState<boolean>(true);
    const [files, setFiles] = useState<File[]>([]);
    const [organizationUserDocuments, setOrganizationUserDocuments] = useState<IOrganizationDocument[]>([]);

    const { filesForUpload } = useAppSelector(state => state.documents);

    useEffect(() => {
        return () => {
            files.length > 0 && setFiles([]);
            filesForUpload && filesForUpload.length > 0 && dispatch(setFilesForUpload([]));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (filesForUpload) {
            setFiles((oldState) => [...oldState, ...filesForUpload]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesForUpload]);

    const handleRemoveFile = (file: File): void => {
        setFiles((prevFiles) => prevFiles.filter((_file) => _file.name !== file.name));
        dispatch(setFilesForUpload([]));
    };

    const setFilesHandler = (acceptedFiles: File[]): void => {
        setFiles((oldState) => [...oldState, ...acceptedFiles]);
    };

    const uploadDocumentHandler = () => {
        if (files.length > 0) {
            onUploadDocument(
                files,
                organizationUserDocuments,
                checked ?
                    DocumentSignatureTypeEnum.IdyCertificateSimple :
                    undefined,
                downloadableBeforeSignature
            );
        }
        if (files.length === 0) {
            toast.error(t('dashboard.sidebar.documents.newDocument.error.documentMissing'));
        }
    };

    const selectDocumentHandler = (document: IOrganizationDocument) => {
        setOrganizationUserDocuments((prevDocuments) => {
            if (prevDocuments.includes(document)) {
                return prevDocuments.filter((_doc) => _doc.uuid !== document.uuid);
            }
            return [...prevDocuments, document];
        });
    };

    const handleChange = (checked: boolean) => {
        setChecked(checked);
    };

    const handleChangeDownloadableBeforeSignature = (checked: boolean) => {
        setDownloadableBeforeSignature(checked);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>

                <FileDropzone
                    maxSize={AppSettings.MAX_UPLOAD_SIZE}
                    files={files}
                    isFilesPanelVisible={ true }
                    setFiles={setFilesHandler}
                    onRemove={handleRemoveFile}
                    accept="application/pdf" />

                <SimpleSignatureCheckbox activeGroup={activeGroup} checked={checked} onChange={handleChange} />
                <DownloadableBeforeSignatureCheckbox checked={downloadableBeforeSignature} onChange={handleChangeDownloadableBeforeSignature} />

                { activeGroup?.group.organizationUuid &&
                  <SelectOrganizationUserDocuments onSelectDocument={ selectDocumentHandler } />
                }

                <Box sx={{ mt: smUp ? 10 : 3, textAlign: 'center', mb: 3 }}>
                    <CommonButton
                        btnType={ButtonTypeEnum.SEND}
                        loading={isLoading}
                        disabled={isLoading || files.length === 0}
                        onClick={uploadDocumentHandler}>
                        {t('dashboard.sidebar.documents.newDocument.button.upload')}
                    </CommonButton>
                </Box>

            </Grid>
        </Grid>
    );
};

export default DocumentUpload;
