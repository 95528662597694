import type { FC } from 'react';
import React, { ChangeEvent, useState } from 'react';
import {
    Box,
    Card,
    CardHeader,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import Scrollbar from '../scrollbar/Scrollbar';
import { VisibilityOutlined } from '@mui/icons-material';
import { applyPaginationOrganization, resolveOrganizationStatus } from '../../utils/organizations-helper';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Label from '../Label';
import { OrganizationOut, OrganizationOutStatusEnum } from 'organizations-service-api';

interface IOrganizationsListProps {
    isLoadingAction: boolean;
    onViewDetails: (uuid: string) => void;
    organizations: OrganizationOut[];
}

const OrganizationsList: FC<IOrganizationsListProps> = ({ onViewDetails, organizations }) => {
    const { t } = useTranslation();

    const [ page, setPage ] = useState<number>(0);
    const [ limit, setLimit ] = useState<number>(20);

    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const organizationStatuses = Object.values(OrganizationOutStatusEnum);
    const sortedOrganizations = [ ...organizations ].sort((a, b) => {
        return organizationStatuses.indexOf(a.status) - organizationStatuses.indexOf(b.status);
    });
    const paginatedOrganizations = applyPaginationOrganization(sortedOrganizations, page, limit);

    const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value, 10));
    };

    return (
        <Grid container spacing={ 3 }>
            { organizations && organizations.length > 0 && (
                <Grid item md={ 12 } xs={ 12 }>
                    <Card>
                        <CardHeader title={ t('businessProfile.list.title') } />
                        <Scrollbar>
                            <Box sx={ { minWidth: smUp ? 700 : 150 } }>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{ t('businessProfile.list.column.status') }</TableCell>
                                            <TableCell>{ t('businessProfile.list.column.companyName') }</TableCell>
                                            <TableCell>{ t('businessProfile.list.column.companyOib') }</TableCell>
                                            <TableCell>{ t('businessProfile.list.column.createdAt') }</TableCell>
                                            { smUp &&
                                              <TableCell>
                                                  { t('businessProfile.list.column.modifiedAt') }
                                              </TableCell> }
                                            { smUp &&
                                              <TableCell sx={ { textAlign: 'center' } }>
                                                  { t('businessProfile.list.column.actions') }
                                              </TableCell> }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={ { cursor: 'pointer' } }>
                                        { paginatedOrganizations?.map((organization: OrganizationOut, index: number) => {
                                            return (
                                                <TableRow hover key={ index }>
                                                    <TableCell onClick={ () => (organization.uuid) }>
                                                        <Label color={ resolveOrganizationStatus(organization).color }>
                                                            { t(resolveOrganizationStatus(organization).label) }
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell onClick={ () => onViewDetails(organization.uuid) }>
                                                        { organization.name }
                                                    </TableCell>
                                                    <TableCell onClick={ () => onViewDetails(organization.uuid) }>
                                                        { organization.oib }
                                                    </TableCell>
                                                    <TableCell onClick={ () => onViewDetails(organization.uuid) }>
                                                        { organization.lastModifiedAt && (
                                                            <Typography color='textSecondary' variant='subtitle2'>
                                                                { format(new Date(organization.createdAt), 'dd.MM.yyyy.') }
                                                            </Typography>
                                                        ) }
                                                    </TableCell>
                                                    { smUp && (
                                                        <TableCell onClick={ () => onViewDetails(organization.uuid) }>
                                                            { organization.createdAt && (
                                                                <Typography color='textSecondary' variant='subtitle2'>
                                                                    { format(new Date(organization.lastModifiedAt), 'dd.MM.yyyy.') }
                                                                </Typography>
                                                            ) }
                                                        </TableCell>
                                                    ) }
                                                    { smUp && (
                                                        <TableCell
                                                            sx={ { textAlign: 'center', verticalAlign: 'middle' } }>
                                                            <Tooltip
                                                                title={ t('businessProfile.list.column.actions.view.tooltip') as string }>
                                                                <IconButton
                                                                    onClick={ () => onViewDetails(organization.uuid) }>
                                                                    <VisibilityOutlined sx={ {
                                                                        cursor: 'pointer',
                                                                        opacity: '0.7'
                                                                    } }
                                                                                        fontSize='small' />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    ) }
                                                </TableRow>
                                            );
                                        }) }
                                    </TableBody>
                                </Table>
                            </Box>
                        </Scrollbar>
                        <TablePagination component='div'
                                         count={ organizations.length }
                                         labelRowsPerPage={ t('rowsPerPage') }
                                         onPageChange={ handlePageChange }
                                         onRowsPerPageChange={ handleLimitChange }
                                         page={ page }
                                         rowsPerPage={ limit }
                                         rowsPerPageOptions={ [ 10, 20, 50 ] } />
                    </Card>
                </Grid>
            ) }
        </Grid>
    );
};

export default OrganizationsList;
