import type { FC } from 'react';
import ImageUploading, { ImageListType, ImageType } from 'react-images-uploading';
import { useEffect, useState } from 'react';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import ConfirmationDialog from '../shared/ConfirmationDialog';
import { DocumentsHelper } from '../../utils/documents-helper';

interface IImageUploaderProps {
    existingImage?: string;
    isRemovingImage?: boolean;
    isSavingImage?: boolean;
    maxNumber?: number;
    onRemoveImage: () => void;
    onSaveImage: (image: string) => void;
}

const ImageUploader: FC<IImageUploaderProps> = (props: IImageUploaderProps) => {
    const {
        existingImage,
        isRemovingImage,
        isSavingImage,
        maxNumber,
        onRemoveImage,
        onSaveImage
    } = props;
    const { t } = useTranslation();

    const [images, setImages] = useState<ImageListType>([]);
    const [hasUnsavedData, setHasUnsavedData] = useState<boolean>(false);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);
    const [index, setIndex] = useState<number | undefined>(undefined);
    const [imageWidth, setImageWidth] = useState<number>(250);

    const maxFileSize = 4_194_304;

    useEffect(() => {
        if (existingImage) {
            setImages([{ dataURL: existingImage } as ImageType]);
        } else {
            setImages([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existingImage]);

    const onChange = (imageList: ImageListType) => {
        setImages(imageList);
        setHasUnsavedData(imageList.length > 0);
    };

    const handleSaveImage = () => {
        const image = (images.length > 0 && images[0].dataURL) ? images[0].dataURL : '';
        setHasUnsavedData(false);
        onSaveImage(image);
    };

    const handleImageRemove = (index: number) => {
        setIsRemoveModalOpen(true);
        setIndex(index);
    };

    const handleRemoveModalClose = (isRemovingImage: boolean) => {
        setIsRemoveModalOpen(false);
        if (isRemovingImage) {
            setImages(prevState => [...prevState.filter((image: ImageType, i: number) => i !== index)]);
            onRemoveImage();
        }
    };

    return (
        <>
            <ImageUploading
                acceptType={['jpg', 'png']}
                multiple={ false }
                maxFileSize={maxFileSize}
                value={ images }
                onChange={ onChange }
                maxNumber={ maxNumber }>
                { ({
                       imageList,
                       onImageUpload,
                       errors
                   }) => (
                    <>
                        {
                            errors && <div>
                                {errors.maxNumber && <span style={{ color: 'red' }}>{t('imageUploader.remove.maxNumber', {number: maxNumber})}</span>}
                                {errors.acceptType && <span style={{ color: 'red' }}>{t('imageUploader.error.acceptType')}</span>}
                                {errors.maxFileSize && <span style={{ color: 'red' }}>{t('imageUploader.error.maxFileSize', {size: DocumentsHelper.formatSizeUnits(maxFileSize)})}</span>}
                          </div>
                        }

                        { (!images || images.length === 0) && (
                            <CommonButton
                                sx={ { mt: 2, textDecoration: 'underline' } }
                                btnType={ ButtonTypeEnum.NO_ICON }
                                variant='text'
                                onClick={ onImageUpload }>
                                { t('actions.uploadLogo') }
                            </CommonButton>
                        ) }

                        { imageList.map((image, index) => (
                            <Box key={ index }>
                                <img
                                    src={ image.dataURL }
                                    alt={ image.file?.name }
                                    width={imageWidth}
                                    onClick={() => setImageWidth(prevState => prevState === 250 ? 500 : 250)} />
                                <Box sx={ { mt: 2 } }>
                                    <CommonButton
                                        sx={ { ml: 2, mr: 2 } }
                                        btnType={ ButtonTypeEnum.REMOVE }
                                        onClick={ () => handleImageRemove(index) }
                                        loading={ isRemovingImage }>
                                        { t('actions.remove') }
                                    </CommonButton>

                                    <CommonButton
                                        sx={ { ml: 2 } }
                                        btnType={ ButtonTypeEnum.SAVE }
                                        onClick={ handleSaveImage }
                                        loading={ isSavingImage }
                                        disabled={ !hasUnsavedData }>
                                        { t('actions.save') }
                                    </CommonButton>
                                </Box>
                            </Box>
                        )) }
                    </>
                ) }
            </ImageUploading>
            <ConfirmationDialog
                title={ t('imageUploader.remove.title') }
                message={ t('imageUploader.remove.message') }
                onConfirm={ () => handleRemoveModalClose(true) }
                onCancel={ () => handleRemoveModalClose(false) }
                open={ isRemoveModalOpen } />
        </>
    );
};

export default ImageUploader;
