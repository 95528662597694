import type { FC } from 'react';
import numeral from 'numeral';
import {
    Box,
    Card,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { IProduct } from '../../pages/payments/CheckoutPage';
import { useTranslation } from 'react-i18next';
import { PaymentsHelper } from '../../utils/payments-helper';


interface CheckoutOrderSummaryProps {
    product?: IProduct;
    total: number;
}

const CheckoutOrderSummary: FC<CheckoutOrderSummaryProps> = (props) => {
    const { product, total, ...other } = props;
    const { t } = useTranslation();

    return (
        <Card variant='outlined' sx={{ p: 3 }} {...other}>
            <Typography variant='h6'>
                {t('dashboard.profile.plans.checkout.orderSummary')}
            </Typography>
            <List sx={{ mt: 2 }}>
                {product && (
                    <ListItem disableGutters>
                        <ListItemAvatar sx={{ pr: 2 }}>
                            <Box
                                sx={{
                                    '& img': {
                                        width: '100%',
                                        height: 'auto',
                                    },
                                    alignItems: 'center',
                                    display: 'flex',
                                    height: 100,
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    width: 100,
                                }}>
                                <img alt={product.name} src={product.image} />
                            </Box>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography sx={{ fontWeight: 'fontWeightBold' }} variant='subtitle2'>
                                    {t(product.name)}
                                </Typography>
                            }
                            secondary={
                                <Box sx={{ display: 'flex' }}>
                                    <Typography color='textSecondary' variant='h6'>
                                        {PaymentsHelper.getCurrencySymbol(product.currency) + ' '}
                                        {numeral(product.price).format('0.00')}
                                    </Typography>
                                    {product.price > 0 && (
                                        <Typography
                                            color='textSecondary'
                                            sx={{
                                                alignSelf: 'flex-end',
                                                ml: 1,
                                            }}
                                            variant='subtitle2'>
                                            {PaymentsHelper.getHrkPrice(product.price)}
                                        </Typography>
                                    )}
                                </Box>
                            }
                        />
                    </ListItem>
                )}
            </List>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='subtitle2'>{t('dashboard.profile.plans.checkout.totalPrice')}</Typography>
                <Typography variant='subtitle2'>{numeral(total).format('0.00') + ' ' + product?.currency}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {total > 0 &&
                  <Typography
                    color='textSecondary'
                    sx={{
                        alignSelf: 'flex-end',
                        ml: 1,
                    }}
                    variant='subtitle2'>
                      {PaymentsHelper.getHrkPrice(total)}
                  </Typography>
                }
            </Box>
        </Card>
    );
};

export default CheckoutOrderSummary;
