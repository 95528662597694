import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import TanInsertionModal from '../../../components/TanInsertionModal';
import {
    getDocumentContent,
    getDocumentDetails,
    resetSelectedDocument,
    resetSendSuccessful,
    resetSignSuccessful,
    shareDocument, signDocumentsSimpleSignature
} from '../../../slices/documents';
import { showTanModal } from '../../../slices/tan-modal';
import { useTranslation } from 'react-i18next';
import QrCodeModal from '../../../components/QrCodeModal';
import { checkHasIdyCertAndSign, closeCertificateSelectModal, issueIdyCertificateAndSign } from '../../../slices/certificates';
import CertificateSelectModal from '../CertificateSelectModal';
import { hasValidFinaPKICloudCertificate, resetFinaFlags, sendTan, signDocumentFina } from '../../../slices/fina-lcp';
import DocumentDetails from 'components/document-details/DocumentDetails';
import DocumentActions from 'components/document-details/DocumentActions';
import DocumentQrCodeStatus from './DocumentQrCodeStatus';
import Page from '../../../components/shared/Page';
import CommonButton, { ButtonTypeEnum } from '../../../components/shared/CommonButton';
import { GroupRoutes, PrivateRoutes, UnprotectedRoutes } from '../../../routes';
import { toast } from 'react-hot-toast';
import { DocumentDetailsPageUtil } from './DocumentDetailsPage.util';
import FinaCertificateInfoModal from '../FinaCertificateInfoModal';
import { getEntityPayables, setIssueFinaCertAutomatically } from '../../../slices/payments';
import { PaymentsHelper } from '../../../utils/payments-helper';
import OrganizationUserDocuments from '../../../components/organizations/OrganizationUserDocuments';
import DocumentContentViewer from './DocumentContentViewer';
import DocumentShare from '../../../components/documents/DocumentShare';
import { IDocumentReceiver, IVisualSignatureCoordinates } from '../../../model/common/documents/document-user';
import auth from '../../../auth/auth';
import { ErrorType } from '../../../model/common/error';
import NotFound from '../../../components/NotFound';
import SkeletonBlock from '../../../components/skeletons/SkeletonBlock';
import { clearErrorMessage } from '../../../slices/errors-handling';
import { ISignatures } from '../../../model/common/payments/ISignatures';
import ConfirmationDialog from '../../../components/shared/ConfirmationDialog';
import PdfSignatureModal from '../../../components/pdf-signature-modal/PdfSignatureModal';
import { getUserIdentifier } from '../../../slices/profile-data';

const DocumentDetailsPage: FC = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        hasValidFinaCertificate,
        isFinaSignSuccessful,
        isFinaSigning
    } = useAppSelector((state) => state.finaLcp);
    const isLoadingFina = useAppSelector((state) => state.finaLcp.isLoading);
    const {
        selectedDocumentDetails,
        selectedDocumentContent,
        isLoadingDocContent,
        isLoadingDocDetails,
        isSendingDocument,
        isSigning,
        isSignSuccessful,
        sendSuccessful,
        tempSignSuccess
    } = useAppSelector((state) => state.documents);
    const { userPayables } = useAppSelector(state => state.payments);
    const { activeGroup } = useAppSelector((state) => state.groups);
    const { error } = useAppSelector(state => state.errors);
    const { userIdentificationData } = useAppSelector(state => state.profileData);

    const [ isQrCodeModalOpen, setIsQrCodeModalOpen ] = useState<boolean>(false);
    const [ isFinaCertModalOpen, setIsFinaCertModalOpen ] = useState<boolean>(false);
    const [ isShareDocumentShown, setIsShareDocumentShown ] = useState<boolean>(false);
    const [ isSignConfirmModalOpen, setIsSignConfirmModalOpen ] = useState<boolean>(false);
    const [ isSignatureModalOpen, setIsSignatureModalOpen ] = useState<boolean>(false);
    const [ isSimpleSignatureModalOpen, setIsSimpleSignatureModalOpen ] = useState<boolean>(false);
    const [ visualCoordinatesForSigner, setVisualCoordinatesForSigner ] = useState<IVisualSignatureCoordinates | undefined>(undefined);

    const { documentUuid, sentOrReceived, groupUuid } = params;
    const userUuid = auth.getUserUuid();
    const isSigned = DocumentDetailsPageUtil.isSigned(selectedDocumentDetails, userUuid);
    const needSignatureFromCurrentUser = DocumentDetailsPageUtil.needSignatureFromCurrentUser(selectedDocumentDetails, userUuid) || !!activeGroup?.group?.uuid;
    const hasFinaCertPayable: boolean = PaymentsHelper.hasFinaCertPayable(userPayables);
    const isNotFoundDocument = error?.errorType === ErrorType.NOT_FOUND;
    const isNotAllowedDocument = error?.errorType === ErrorType.NOT_ALLOWED;
    const signatures: ISignatures | undefined = PaymentsHelper.getSignaturesFromPayables(userPayables);
    const isProfileVerified = Boolean(userIdentificationData?.fullName && userIdentificationData?.personalNumber);

    useEffect(() => {
        if (!userPayables) {
            dispatch(getEntityPayables());
        }

        if (!hasValidFinaCertificate) {
            dispatch(hasValidFinaPKICloudCertificate());
        }

        if (!userIdentificationData) {
            dispatch(getUserIdentifier());
        }

        dispatch(getDocumentDetails(
            documentUuid ?? '',
            !activeGroup?.group.uuid && !groupUuid ?
                undefined :
                activeGroup?.group.uuid || groupUuid
        ));

        dispatch(getDocumentContent(
            documentUuid ?? '',
            !activeGroup?.group.uuid && !groupUuid ?
                undefined :
                activeGroup?.group.uuid || groupUuid
        ));

        return () => {
            dispatch(resetSelectedDocument());
            dispatch(clearErrorMessage());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sendSuccessful) {
            toast.success(t('dashboard.sidebar.documentDetails.send.successful'));
            dispatch(resetSendSuccessful());
            setIsShareDocumentShown(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sendSuccessful ]);

    useEffect(() => {
        if (isFinaSignSuccessful || isSignSuccessful) {
            toast.success(t('dashboard.sidebar.documentDetails.sign.successful'));
            dispatch(resetSignSuccessful());
            dispatch(resetFinaFlags());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isFinaSignSuccessful, isSignSuccessful ]);

    const signDocumentHandler = (visualCoordinates?: IVisualSignatureCoordinates) => {
        if (hasFinaCertPayable && !hasValidFinaCertificate) {
            setIsFinaCertModalOpen(true);
        } else {
            signDocuments(visualCoordinates);
        }
    };

    const handleSimpleSignDocument = () => {
        setIsSimpleSignatureModalOpen(false);
        dispatch(signDocumentsSimpleSignature(
            [ selectedDocumentDetails?.uuid ?? '' ],
            activeGroup?.group?.uuid,
            activeGroup?.group.organizationUuid
        ));
    };

    const signDocuments = (visualCoordinates?: IVisualSignatureCoordinates) => {
        // korisnik ima izdan fina cert - potpis ide s njim
        if (hasValidFinaCertificate) {
            dispatch(sendTan());
            dispatch(showTanModal());
            return;
        }

        // provjeri ima li idy cert i potpiši s njim ako ima
        // ako nema idy cert pitaj korisnika s kojim certom želi potpisati
        dispatch(checkHasIdyCertAndSign(
            [ documentUuid ?? '' ],
            false,
            !activeGroup?.group.uuid && !groupUuid ?
                undefined :
                activeGroup?.group.uuid || groupUuid,
            activeGroup?.group.organizationUuid ?
                activeGroup?.group.organizationUuid :
                undefined,
            visualCoordinates ? visualCoordinates : visualCoordinatesForSigner
        ));
    };

    const generateQrCodeHandler = () => {
        setIsQrCodeModalOpen(true);
    };

    const closeQrCodeModalHandler = () => {
        setIsQrCodeModalOpen(false);
    };

    const signingDocumentsConfirmTanHandler = (tan: string) => {
        const uuidForGroup = !activeGroup?.group.uuid && !groupUuid
            ? undefined
            : activeGroup?.group.uuid || groupUuid;

        dispatch(signDocumentFina(
            [ documentUuid ?? '' ],
            tan,
            uuidForGroup,
            visualCoordinatesForSigner
        ));
    };

    const backToDocumentsSummaryClickHandler = () => {
        dispatch(clearErrorMessage());
        dispatch(resetSelectedDocument());
        navigate(activeGroup || groupUuid
            ? sentOrReceived === 'sent' ? GroupRoutes.groups_sent_documents_route
                .path
                .replace(
                    ':groupUuid',
                    activeGroup?.group?.uuid || groupUuid || ''
                ) : GroupRoutes.groups_received_documents_route
                .path
                .replace(
                    ':groupUuid',
                    activeGroup?.group?.uuid || groupUuid || ''
                )
            : sentOrReceived === 'sent' ? PrivateRoutes.private_sent_documents_route.path : PrivateRoutes.private_received_documents_route.path);
    };

    const handleCertificateModalClose = () => {
        dispatch(closeCertificateSelectModal());
    };

    const handleIdentyumCertificateSelect = () => {
        dispatch(closeCertificateSelectModal());
        dispatch(issueIdyCertificateAndSign(
            [ documentUuid ?? '' ],
            !activeGroup?.group.uuid && !groupUuid ?
                undefined :
                activeGroup?.group.uuid || groupUuid,
            activeGroup?.group.organizationUuid ?
                activeGroup?.group.organizationUuid :
                undefined,
            visualCoordinatesForSigner
        ));
    };

    const handleFinaCertificateSelect = () => {
        dispatch(closeCertificateSelectModal());
        navigate(PrivateRoutes.private_shop_route.path);
    };

    const shareDocumentClickHandler = () => {
        setIsShareDocumentShown(true);
    };

    const handleFinaIssueCertificate = () => {
        setIsFinaCertModalOpen(false);
        dispatch(setIssueFinaCertAutomatically(true));
        navigate(PrivateRoutes.private_shop_route.path);
    };

    const handleFinaCertIssueCancel = () => {
        setIsFinaCertModalOpen(false);
        signDocuments();
    };

    const handleDocumentShare = (
        shareWith: IDocumentReceiver[],
        documentUuid: string,
        signSeparateCopy: boolean,
        groupUuid: string | undefined,
        organizationUuid?: string
    ) => {
        dispatch(
            shareDocument(
                shareWith,
                documentUuid,
                signSeparateCopy,
                groupUuid,
                organizationUuid
            )
        );
    };

    const handleDocumentShareCancel = () => {
        setIsShareDocumentShown(false);
    };

    const handleSignModalOpen = (isOpen: boolean, openSignatureModal?: boolean) => {
        if (isProfileVerified){
            setVisualCoordinatesForSigner(undefined);
            setIsSignConfirmModalOpen(isOpen);
            openSignatureModal && setIsSignatureModalOpen(openSignatureModal);
        }

        if (!isProfileVerified) {
            setIsSimpleSignatureModalOpen(isOpen);
        }
    };

    const handleSignatureModalOpen = (isOpen: boolean) => {
        setIsSignatureModalOpen(isOpen);
    };

    const handleOnFinish = (visualCoordinates: IVisualSignatureCoordinates) => {
        setIsSignatureModalOpen(false);
        setVisualCoordinatesForSigner(visualCoordinates);
        signDocumentHandler(visualCoordinates);
    };

    const handleSignAutomatically = () => {
        setVisualCoordinatesForSigner(undefined);
        setIsSignConfirmModalOpen(false);
        signDocumentHandler();
    };

    const handleVerifyProfile = () => {
        if (UnprotectedRoutes.unprotected_refresh_route.path) {
            navigate(UnprotectedRoutes.unprotected_refresh_route.path);
            localStorage.setItem('fullScopeRedirectUrl', window.location.href);
        }
    };

    return (
        <Page
            pageTitle={ t('dashboard.sidebar.documentDetails.title') }
            actionButton={
                <CommonButton btnType={ ButtonTypeEnum.BACK } onClick={ backToDocumentsSummaryClickHandler }>
                    { t('dashboard.sidebar.documentDetails.button.backToDocumentSummary') }
                </CommonButton>
            }>
            { isNotFoundDocument || isNotAllowedDocument ?
                <NotFound is403={ isNotAllowedDocument } /> :
                <Box sx={ { mt: 3 } }>
                    <Grid container spacing={ 3 }>
                        <Grid item xs={ 12 } md={ 12 } lg={ 12 }>
                            { isLoadingDocDetails ?
                                <SkeletonBlock height={ 282 } /> :
                                <DocumentDetails userUuid={ userUuid }
                                                 document={ selectedDocumentDetails }
                                                 isSigned={ tempSignSuccess }
                                /> }
                        </Grid>
                        <Grid item xs={ 12 } md={ 12 } lg={ 12 }>
                            <DocumentQrCodeStatus document={ selectedDocumentDetails } />
                        </Grid>

                        { !isShareDocumentShown &&
                          <Grid container item xs={ 12 } md={ 12 } lg={ 12 } spacing={ 2 }>
                            <Grid item
                                  xs={ 12 }
                                  sm={ 12 }
                                  md={ activeGroup?.group.organizationUuid && selectedDocumentDetails?.organizationMemberDocuments ? 6 : 12 }
                                  lg={ activeGroup?.group.organizationUuid && selectedDocumentDetails?.organizationMemberDocuments ? 6 : 12 }
                                  xl={ activeGroup?.group.organizationUuid && selectedDocumentDetails?.organizationMemberDocuments ? 6 : 12 }>
                              <DocumentContentViewer content={ selectedDocumentContent?.value }
                                                     isLoading={ isLoadingDocContent }
                                                     title={ t('dashboard.sidebar.documentDetails.pdfPreview.title') } />
                            </Grid>
                              { activeGroup?.group.organizationUuid &&
                                  selectedDocumentDetails?.organizationMemberDocuments?.length &&
                                  selectedDocumentDetails?.organizationMemberDocuments?.length > 0 &&
                                <Grid item xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
                                  <OrganizationUserDocuments documents={ selectedDocumentDetails?.organizationMemberDocuments } />
                                </Grid>
                              }
                          </Grid>
                        }

                        { isShareDocumentShown &&
                          <Grid item xs={ 12 } md={ 12 } lg={ 12 }>
                            <DocumentShare activeGroup={ activeGroup }
                                           isLoading={ isSendingDocument }
                                           onDocumentShare={ handleDocumentShare }
                                           onCancel={ handleDocumentShareCancel }
                                           document={ selectedDocumentDetails } />
                          </Grid>
                        }

                        { isLoadingDocDetails || isLoadingFina ? false : (
                            <DocumentActions isSigning={ isSigning || isFinaSigning }
                                             onGenerateQrCode={ generateQrCodeHandler }
                                             onShareDocument={ shareDocumentClickHandler }
                                             onSignDocument={ () => handleSignModalOpen(true) }
                                             isSigned={ isSigned || tempSignSuccess }
                                             selectedDocumentDetails={ selectedDocumentDetails }
                                             needSignatureFromCurrentUser={ needSignatureFromCurrentUser }
                                             selectedDocumentContent={ selectedDocumentContent }
                                             signatures={ signatures } />
                        ) }

                    </Grid>
                </Box> }

            <TanInsertionModal onConfirm={ signingDocumentsConfirmTanHandler } />

            <QrCodeModal onClose={ closeQrCodeModalHandler }
                         open={ isQrCodeModalOpen }
                         documentTitle={ selectedDocumentDetails?.title?.defaultText } />

            <CertificateSelectModal onClose={ handleCertificateModalClose }
                                    onIdentyumCertificateSelect={ handleIdentyumCertificateSelect }
                                    onFinaCertificateSelect={ handleFinaCertificateSelect } />

            <FinaCertificateInfoModal onCancel={ handleFinaCertIssueCancel }
                                      onClose={ () => setIsFinaCertModalOpen(false) }
                                      onIssueFinaCertificate={ handleFinaIssueCertificate }
                                      open={ isFinaCertModalOpen } />

            <ConfirmationDialog open={ isSignConfirmModalOpen }
                                preventSmUpBreakpoint={ true }
                                showCloseButton={ true }
                                closeButtonText={ t('signatureModal.button.cancel') as string }
                                confirmButtonText={ t('signatureModal.button.confirm') as string }
                                title={ t('signatureModal.title') }
                                message={ t('signatureModal.subtitle') }
                                onConfirm={ () => handleSignModalOpen(false, true) }
                                onNo={ () => handleSignAutomatically() }
                                onCancel={ () => handleSignModalOpen(false, false) } />

            <ConfirmationDialog open={ isSimpleSignatureModalOpen }
                                preventSmUpBreakpoint={ true }
                                showCloseButton={ true }
                                cancelButtonType={ ButtonTypeEnum.NO_ICON }
                                confirmButtonType={ ButtonTypeEnum.SIGN }
                                closeButtonText={ t('simpleSignModal.button.sign') as string }
                                confirmButtonText={ t('simpleSignModal.button.verify') as string }
                                title={ t('simpleSignModal.title') }
                                message={ t('simpleSignModal.subtitle') }
                                onConfirm={ () => handleVerifyProfile() }
                                onNo={ () => handleSimpleSignDocument() }
                                onCancel={ () => setIsSimpleSignatureModalOpen(false) } />

            <PdfSignatureModal documentContent={ selectedDocumentContent?.value || '' }
                               documentDetails={ selectedDocumentDetails }
                               onClose={ () => handleSignatureModalOpen(false) }
                               onFinish={ handleOnFinish }
                               open={ isSignatureModalOpen } />
        </Page>
    );
};

export default DocumentDetailsPage;
