import { FC, useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PendingMembershipsList from '../../components/groups/PendingMembershipsList';
import Page from '../../components/shared/Page';
import { cancelPendingMembership, confirmMembership, getMemberships } from '../../slices/groups';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Membership } from 'group-service-api';

const GroupPendingPage: FC = () => {
    const { t } = useTranslation();
    const { pendingMemberships } = useAppSelector((state) => state.groups);
    const dispatch = useAppDispatch();

    const getMembershipsCallback = useCallback(() => {
        dispatch(getMemberships());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getMembershipsCallback();
    }, [ getMembershipsCallback ]);

    const handleConfirmAccess = (membership: Membership) => {
        dispatch(confirmMembership(membership));
    };

    const handleRejectMembership = (membership: Membership) => {
        dispatch(cancelPendingMembership(membership));
    };

    return (
        <Page
            pageTitle={ t('groups.pending.request.title') }
            pageDescription={ t('groups.pending.request.subTitle') }>
            <Grid item md={ 12 } xs={ 12 } mt={ 3 }>
                <PendingMembershipsList
                    pendingMemberships={ pendingMemberships }
                    confirmAccess={ handleConfirmAccess }
                    rejectMembership={ handleRejectMembership }
                />
            </Grid>
        </Page>
    );
};

export default GroupPendingPage;
