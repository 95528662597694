import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddPhoneRequest, DialCode, Phone, PhoneIn } from 'phone-verifier-api';
import { AppThunk } from '../store';
import apis from '../auth/apis';
import { ErrorType, IError } from 'model/common/error';
import otpModalSlice from './otp-modal';
import { Reason } from 'common/constants';

enum PhonesReason {
    PHONE_NUMBER_INVALID = 'PHONE_NUMBER_INVALID',
    PHONE_NUMBER_TAKEN = 'PHONE_NUMBER_TAKEN',
}

interface IPhonesState {
    errorPhone: IError | undefined;
    isLoadingPhone: boolean;
    isSubmittingPhone: boolean;
    phoneCodes: {
        defaultPhoneCode: DialCode | undefined;
        enabledDialCodes: DialCode[];
    };
    phones: Phone[];
}

const initialState: IPhonesState = {
    errorPhone: undefined,
    isLoadingPhone: false,
    isSubmittingPhone: false,
    phoneCodes: {
        defaultPhoneCode: undefined,
        enabledDialCodes: [],
    },
    phones: [],
};

const phonesSlice = createSlice({
    name: 'phones',
    initialState,
    reducers: {
        addPhoneToList(state: IPhonesState, action: PayloadAction<Phone>): void {
            state.phones.push(action.payload);
        },
        clearErrorMessage(state: IPhonesState) {
            state.errorPhone = undefined;
        },
        deletePhone(state: IPhonesState, action: PayloadAction<string>): void {
            state.phones = state.phones.filter((phone: Phone) => phone.uuid !== action.payload);
        },
        setDefault(state: IPhonesState, action: PayloadAction<string>): void {
            state.phones = state.phones.map((phone: Phone) => {
                if (phone.uuid === action.payload) {
                    return { ...phone, defaultPhone: true };
                }
                return { ...phone, defaultPhone: false };
            });
        },
        setError(state: IPhonesState, action: PayloadAction<IError>): void {
            state.errorPhone = action.payload;
        },
        setIsLoadingPhone(state: IPhonesState, action: PayloadAction<boolean>): void {
            state.isLoadingPhone = action.payload;
        },
        setIsSubmittingPhone(state: IPhonesState, action: PayloadAction<boolean>): void {
            state.isSubmittingPhone = action.payload;
        },
        setPhoneCodesList(state: IPhonesState, action: PayloadAction<{ defaultDialCode: DialCode | undefined; enabledDialCodes: DialCode[] }>): void {
            state.phoneCodes.defaultPhoneCode = action.payload.defaultDialCode;
            state.phoneCodes.enabledDialCodes = action.payload.enabledDialCodes;
        },
        setPhonesList(state: IPhonesState, action: PayloadAction<Phone[]>): void {
            state.phones = action.payload;
        },
    },
});

export const requestPhoneOtp =
    (phone: PhoneIn): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(phonesSlice.actions.setIsSubmittingPhone(true));
        apis.phonesApi()
            .requestOtp(phone)
            .then(() => {
                dispatch(otpModalSlice.actions.setPhone(phone));
                dispatch(otpModalSlice.actions.showOtpModal('phone'));
            })
            .catch((err) => {
                if (err?.response?.status === 422) {
                    if (err?.response?.data?.type === PhonesReason.PHONE_NUMBER_INVALID) {
                        dispatch(
                            phonesSlice.actions.setError({
                                error: true,
                                errorMessage: 'dashboard.profile.contact.invalidPhone',
                                errorType: ErrorType.GENERIC_ERROR,
                            })
                        );
                        return;
                    }
                    if (err?.response?.data?.type === PhonesReason.PHONE_NUMBER_TAKEN) {
                        dispatch(
                            phonesSlice.actions.setError({
                                error: true,
                                errorMessage: 'dashboard.profile.contact.phoneRegistered',
                                errorType: ErrorType.GENERIC_ERROR,
                            })
                        );
                        return;
                    }
                }
                return dispatch(
                    phonesSlice.actions.setError({
                        error: true,
                        errorMessage: 'dashboard.profile.error',
                        errorType: ErrorType.GENERIC_ERROR,
                    })
                );
            })
            .finally(() => dispatch(phonesSlice.actions.setIsSubmittingPhone(false)));
    };

export const deletePhone =
    (uuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(phonesSlice.actions.deletePhone(uuid));
        apis.phonesApi()
            .deletePhone(uuid)
            .catch(() => {
                dispatch(
                    otpModalSlice.actions.setError({
                        error: true,
                        errorMessage: 'dashboard.profile.contact.deleteErrorPhone',
                        errorType: ErrorType.DELETE_PHONE_ERROR,
                    })
                );
            });
    };

export const getPhoneCodes =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(phonesSlice.actions.clearErrorMessage());
        dispatch(phonesSlice.actions.setIsLoadingPhone(true));
        apis.phonesConfigApi()
            .getConfig()
            .then((response) => {
                dispatch(
                    phonesSlice.actions.setPhoneCodesList({
                        defaultDialCode: response.data.defaultDialCode,
                        enabledDialCodes: Array.from(response.data.enabledDialCodes),
                    })
                );
                dispatch(phonesSlice.actions.setIsLoadingPhone(false));
            })
            .catch(() => {
                dispatch(phonesSlice.actions.setIsLoadingPhone(false));
                dispatch(
                    phonesSlice.actions.setError({
                        error: true,
                        errorMessage: 'dashboard.profile.error',
                        errorType: ErrorType.GET_PHONECODES_ERROR,
                    })
                );
            });
    };

export const getPhones =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(phonesSlice.actions.clearErrorMessage());
        dispatch(phonesSlice.actions.setIsLoadingPhone(true));
        apis.phonesApi()
            .getPhones()
            .then((response) => {
                dispatch(phonesSlice.actions.setPhonesList(Array.from(response.data.hits)));
                dispatch(phonesSlice.actions.setIsLoadingPhone(false));
            })
            .catch(() => {
                dispatch(phonesSlice.actions.setIsLoadingPhone(false));
                dispatch(
                    phonesSlice.actions.setError({
                        error: true,
                        errorMessage: 'dashboard.profile.error',
                        errorType: ErrorType.GET_PHONES_ERROR,
                    })
                );
            });
    };

export const setDefaultPhone =
    (uuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(phonesSlice.actions.setDefault(uuid));
        apis.phonesApi()
            .updatePhone(uuid, { defaultPhone: true })
            .catch(() => {
                dispatch(
                    phonesSlice.actions.setError({
                        error: true,
                        errorMessage: 'dashboard.profile.contact.defaultErrorPhone',
                        errorType: ErrorType.SET_DEFAULT_PHONE_ERROR,
                    })
                );
            });
    };

export const addPhone =
    (addPhoneRequest: AddPhoneRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(otpModalSlice.actions.setIsSubmitingOtp(true));
        apis.phonesApi()
            .addPhone(addPhoneRequest)
            .then((response) => {
                dispatch(phonesSlice.actions.addPhoneToList(response.data));
                dispatch(otpModalSlice.actions.setIsSubmitingOtp(false));
                dispatch(otpModalSlice.actions.hideOtpModal());
            })
            .catch((err) => {
                dispatch(otpModalSlice.actions.setIsSubmitingOtp(false));

                if (err?.response?.status === 410) {
                    dispatch(
                        otpModalSlice.actions.setError({
                            error: true,
                            errorMessage: 'otp.submit.otpExpired',
                            errorType: ErrorType.SUBMIT_OTP_ERROR,
                        })
                    );
                    return;
                }
                if (err?.response?.status === 422) {
                    if (err?.response?.data?.type === Reason.OTP_INVALID) {
                        dispatch(
                            otpModalSlice.actions.setError({
                                error: true,
                                errorMessage: 'otp.submit.otpInvalid',
                                errorType: ErrorType.SUBMIT_OTP_ERROR,
                            })
                        );
                        return;
                    }
                    if (err?.response?.data?.type === Reason.OTP_TEMPORARY_BLOCKED) {
                        dispatch(otpModalSlice.actions.setSubmitBlocked());
                        dispatch(
                            otpModalSlice.actions.setError({
                                error: true,
                                errorMessage: 'otp.submit.otpBlocked',
                                errorType: ErrorType.SUBMIT_OTP_ERROR,
                            })
                        );
                        return;
                    }
                }
                dispatch(
                    otpModalSlice.actions.setError({
                        error: true,
                        errorMessage: 'dashboard.profile.error',
                        errorType: ErrorType.SUBMIT_OTP_ERROR,
                    })
                );
            });
    };

export const { reducer } = phonesSlice;

export const { clearErrorMessage } = phonesSlice.actions;

export default phonesSlice;
