import type { FC } from 'react';
import { Box, Link, Theme, useMediaQuery } from '@mui/material';
import signLogo from '../../assets/img/illustrations/Identyum-SIGN-logo.svg';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { UnprotectedRoutes } from '../../routes';

const IdentifyFooter: FC = () => {
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const { t } = useTranslation();

    return (
        <Box
            sx={ {
                background: '#fcfcfc',
                bottom: 0,
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                marginTop: '16px',
                padding: '16px',
                position: 'fixed',
                width: '100%'
            } }>
            <Box display='flex' alignItems='center' justifyContent='center' mb={ 2 }>
                <img alt='idy_logo' src={ signLogo } style={ { height: '26px', marginLeft: '8px', width: 'auto' } } />
            </Box>
            { smUp &&
              <Box display='flex' justifyContent='center' mb={ 1 }>
                <Link
                  target={ '_blank' }
                  sx={ { '&:hover': { cursor: 'pointer' }, mr: 2, textAlign: 'center' } }
                  color='textSecondary'
                  component={ RouterLink }
                  to={ UnprotectedRoutes.terms_sale_route.path }
                  variant='caption'>
                    { t('dashboard.sidebar.terms.sale') }
                </Link>
                <Link
                  target={ '_blank' }
                  sx={ { '&:hover': { cursor: 'pointer' }, mr: 2, textAlign: 'center' } }
                  color='textSecondary'
                  component={ RouterLink }
                  to={ UnprotectedRoutes.terms_protection_route.path }
                  variant='caption'>
                    { t('dashboard.sidebar.terms.dataProtection') }
                </Link>
                <Link
                  target={ '_blank' }
                  sx={ { '&:hover': { cursor: 'pointer' }, mr: 2, textAlign: 'center' } }
                  color='textSecondary'
                  component={ RouterLink }
                  to={ UnprotectedRoutes.terms_data_usage_route.path }
                  variant='caption'>
                    { t('dashboard.sidebar.terms.dataUsage') }
                </Link>
                <Link
                  target={ '_blank' }
                  sx={ { '&:hover': { cursor: 'pointer' }, mr: 2, textAlign: 'center' } }
                  color='textSecondary'
                  component={ RouterLink }
                  to={ UnprotectedRoutes.terms_conversion_route.path }
                  variant='caption'>
                    { t('dashboard.sidebar.terms.conversion') }
                </Link>
              </Box>
            }
        </Box>
    );
};

export default IdentifyFooter;
