import { FC, PropsWithChildren, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useTranslation } from 'react-i18next';
import { Grid, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { PDFPageProxy } from 'react-pdf';

type Props = {
    document: string;
};

const PDFViewer: FC<PropsWithChildren<Props>> = ({ document }) => {
    const { t } = useTranslation();
    const [ numPages, setNumPages ] = useState<number>(0);
    const [ pageNumber, setPageNumber ] = useState<number>(1);

    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    const previousPage = () => {
        changePage(-1);
    };

    const nextPage = () => {
        changePage(1);
    };

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
        setPageNumber(1);
    };

    const changePage = (offset: number) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    };

    const handleLoadSuccess = (page: PDFPageProxy) => {
        if (page.pageNumber && page.pageNumber > 1) {
            setPageNumber(page.pageNumber);
        }
    };

    return (
        <>
            <Grid container direction='column' alignItems='center' justifyContent='center'>
                <Grid item>
                    <Document
                        file={ document.startsWith('http') ? document : 'data:application/pdf;base64,' + document }
                        noData={ t('dashboard.sidebar.documentDetails.pdfPreview.error') }
                        onLoadSuccess={ onDocumentLoadSuccess }>
                        <Page pageNumber={ pageNumber }
                              onLoadSuccess={ (page: PDFPageProxy) => handleLoadSuccess(page)}
                              loading={
                                  <Skeleton
                                      sx={ {
                                          borderRadius: 1,
                                          width: '100%'
                                      } }
                                      variant='rectangular'
                                  />
                              } />
                    </Document>
                </Grid>
            </Grid>

            <Grid container direction='column' alignItems='center' justifyContent='center'>
                <Grid item sx={ { mb: 1 } }>
                    <Typography variant='body1'>
                        { t('dashboard.sidebar.documentDetails.pdfPreview.pages', {
                            page: `${ pageNumber || ( numPages ? 1 : '--' ) }`,
                            of: `${ numPages || '--' }`
                        }) }
                    </Typography>
                </Grid>
                <Grid item>
                    <CommonButton btnType={ ButtonTypeEnum.PREVIOUS }
                                  disabled={ pageNumber <= 1 }
                                  onClick={ previousPage }
                                  sx={ { mr: 1 } }>
                        { smUp ? t('dashboard.sidebar.documentDetails.button.previousPage') : '' }
                    </CommonButton>
                    <CommonButton btnType={ ButtonTypeEnum.NEXT }
                                  disabled={ pageNumber >= numPages }
                                  onClick={ nextPage }
                                  sx={ { ml: 1 } }>
                        { smUp ? t('dashboard.sidebar.documentDetails.button.nextPage') : '' }
                    </CommonButton>
                </Grid>
            </Grid>

        </>
    );
};

export default PDFViewer;
