import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { AnswerOption, Option, OptionAnswerRequest, OptionTypeEnum } from 'survey-service-api';
import { Box, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';

interface ICheckboxesQuestionProps {
    answerOptions: (options: OptionAnswerRequest[]) => void;
    options: Option[];
    prefilledOptions?: AnswerOption[];
}

const CheckboxesQuestionBox: FC<ICheckboxesQuestionProps> = (props) => {
    const { answerOptions, options, prefilledOptions } = props;

    const [checkedItems, setCheckedItems] = useState<OptionAnswerRequest[]>(
        prefilledOptions
            ? prefilledOptions.map((po: AnswerOption) => {
                  return { optionUuid: po.option?.uuid, inputValue: undefined } as OptionAnswerRequest;
              })
            : []
    );

    useEffect(() => {
        answerOptions(checkedItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedItems]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = event.target;
        if (checked) {
            const newCheckedItem = { optionUuid: name, inputValue: value };
            setCheckedItems((prevCheckedItems) => prevCheckedItems.concat(newCheckedItem));
        } else {
            setCheckedItems((prevCheckedItems) => prevCheckedItems.filter((item) => item.optionUuid !== name));
        }
    };

    const getValue = (uuid: string): string | undefined => {
        return checkedItems.find((value) => value.optionUuid === uuid)?.inputValue;
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>, optionUuid: string) => {
        const { value } = event.target;
        const newInputOption = { optionUuid: optionUuid, inputValue: value };
        setCheckedItems((prevFilledInputFields) => {
            const index = prevFilledInputFields.findIndex((value1) => value1.optionUuid === optionUuid);
            if (index >= 0) {
                return prevFilledInputFields.map(value1 => value1.optionUuid === optionUuid ? newInputOption : value1);
            }

            return prevFilledInputFields.concat(newInputOption);
        });
    };

    return (
        <Box>
            <FormGroup>
                {options.map((o: Option) =>
                    o.type === OptionTypeEnum.Checkbox ? (
                        <FormControlLabel
                            key={o.uuid}
                            control={
                                <Checkbox
                                    name={o.uuid}
                                    value={o.label}
                                    checked={checkedItems.some((item) => item.optionUuid === o.uuid)}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={o.label}
                        />
                    ) : (
                        <TextField
                            size="small"
                            sx={{ mr: 2, mt: 2 }}
                            key={o.uuid}
                            id="outlined-controlled"
                            value={getValue(o.uuid)}
                            label={o.label}
                            placeholder={o.label}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleChangeInput(event, o.uuid);
                            }}
                        />
                    )
                )}
            </FormGroup>
        </Box>
    );
};

export default CheckboxesQuestionBox;
