import type { FC } from 'react';
import {Box, CircularProgress, Dialog, DialogContent, IconButton, Typography} from '@mui/material';
import PdfViewer from '../pdf-viewer/PdfViewer';
import { useTranslation } from 'react-i18next';
import {Close} from '@mui/icons-material';

interface ISurveyPreviewDialogProps {
    open: boolean;
    onClose: () => void;
    pdfBase64?: string;
    isLoadingPdf: boolean;
}

const SurveyPreviewDialog: FC<ISurveyPreviewDialogProps> = (props) => {
    const { t } = useTranslation();

    const { isLoadingPdf, open, onClose, pdfBase64 } = props;

    return (
        <Dialog open={open} onClose={onClose}>
            <Box display='flex' justifyContent='right' m={1} borderBottom='1px solid lightgrey'>
                <IconButton onClick={onClose}><Close /></IconButton>
            </Box>
            <DialogContent  sx={{ p: 4 }}>
                {isLoadingPdf ? (
                    <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Box>
                            <CircularProgress />
                        </Box>
                        <Box mt={6}>
                            <Typography>{t('survey.SurveyPdfPage.progressDescription')}</Typography>
                        </Box>
                    </Box>
                ) : (
                    pdfBase64 && <PdfViewer document={pdfBase64}/>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default SurveyPreviewDialog;
