import { Document as DocumentApi, Label } from 'user-document-signer-api';
import { DocumentsHelper } from './documents-helper';

export class DocumentApiHelper {

    public static mapFilesToDocuments = async (files: File[]): Promise<DocumentApi[]> => {
        const documents: DocumentApi[] = [];
        const base64Files: Promise<string | ArrayBuffer | null>[] = [];
        files.forEach((file) => {
            base64Files.push(DocumentsHelper.fileToBase64(file));
        });
        const base64Resolved = await Promise.all(base64Files);
        files.forEach((file, index) =>
            documents.push({
                content: base64Resolved[index],
                title: {
                    defaultText: file.name,
                } as Label,
            } as DocumentApi)
        );

        return Promise.resolve(documents);
    };
}
