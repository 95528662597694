import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import ContactEmailModal from '../../components/contacts/ContactEmailModal';
import ContactPhoneModal from '../../components/contacts/ContactPhoneModal';
import { EmailRequest, EmailResponse, PhoneRequest, PhoneResponse, UpdateContactRequest } from 'contacts-service-api';
import {
    addEmailToUserContact,
    addPhoneToUserContact,
    deleteUserContactEmail,
    deleteUserContactPhoneNumber,
    updateContactEmail,
    updateContactPhoneNumber,
    updateUserContactName
} from '../../slices/user-contacts';
import SkeletonInput from '../../components/skeletons/SkeletonInput';
import { PhoneNumber } from 'contacts-service-api/lib/cjs';
import UserContactEmailList from '../../components/contacts/UserContactEmailList';
import UserContactPhoneNumberList from '../../components/contacts/UserContactPhoneNumberList';
import Page from '../../components/shared/Page';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import { PrivateRoutes } from '../../routes';
import ConfirmationDialog from '../../components/shared/ConfirmationDialog';

const UserContactDetails: FC = () => {
    const { currentUserContact, isLoading } = useAppSelector(state => state.userContacts);

    const [hasUnsavedData, setHasUnsavedData] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [username, setUsername] = useState<string>(currentUserContact?.name ?? '');
    const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);
    const [isPhoneModalOpen, setIsPhoneModalOpen] = useState<boolean>(false);
    const [editedEmail, setEditedEmail] = useState<EmailResponse>({} as EmailResponse);
    const [editedPhoneNumber, setEditedPhoneNumber] = useState<PhoneResponse>({} as PhoneResponse);
    const [isDeleteEmailModalOpen, setIsDeleteEmailModalOpen] = useState<boolean>(false);
    const [emailUuidToDelete, setEmailUuidToDelete] = useState<string>('');
    const [isDeletePhoneModalOpen, setIsDeletePhoneModalOpen] = useState<boolean>(false);
    const [phoneUuidToDelete, setPhoneUuidToDelete] = useState<string>('');

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        setHasUnsavedData(username !== currentUserContact?.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username]);

    useEffect(() => {
        setUsername(currentUserContact?.name ?? '');
    }, [currentUserContact?.name]);

    const addNewEmailModalConfirmHandler = (email: EmailResponse) => {
        !editedEmail?.email ? addNewEmailHandler(email) : editExistingEmailHandler(email);
    };

    const addNewEmailHandler = (email: EmailResponse) => {
        dispatch(addEmailToUserContact(
            currentUserContact?.uuid ?? '',
            { email: email.email, isDefault: email.isDefault } as EmailRequest
        ));
        setEditedEmail({} as EmailResponse);
        addNewEmailModalCloseHandler();
    };

    const editExistingEmailHandler = (email: EmailResponse) => {
        dispatch(updateContactEmail(
            currentUserContact?.uuid ?? '',
            editedEmail.uuid,
            { email: email.email, isDefault: email.isDefault } as EmailRequest
        ));
        setEditedEmail({} as EmailResponse);
        addNewEmailModalCloseHandler();
    };

    const addNewEmailModalCloseHandler = () => {
        setEditedEmail({} as EmailResponse);
        setIsEmailModalOpen(false);
    };

    const addNewPhoneModalConfirmHandler = (phoneNumber: PhoneResponse) => {
        !editedPhoneNumber?.value?.number ? addNewPhoneNumberHandler(phoneNumber) : editExistingPhoneNumberHandler(phoneNumber);
    };

    const addNewPhoneNumberHandler = (phoneNumber: PhoneResponse) => {
        dispatch(addPhoneToUserContact(
            currentUserContact?.uuid ?? '',
            {
                value:
                    {
                        dialCode: phoneNumber.value.dialCode,
                        number: phoneNumber.value.number
                    } as PhoneNumber,
                isDefault: phoneNumber.isDefault
            } as PhoneRequest
        ));
        setEditedPhoneNumber({} as PhoneResponse);
        addNewPhoneModalCloseHandler();
    };

    const editExistingPhoneNumberHandler = (phoneNumber: PhoneResponse) => {
        dispatch(updateContactPhoneNumber(
            currentUserContact?.uuid ?? '',
            editedPhoneNumber.uuid,
            {
                value:
                    {
                        dialCode: phoneNumber.value.dialCode,
                        number: phoneNumber.value.number
                    } as PhoneNumber,
                isDefault: phoneNumber.isDefault
            } as PhoneRequest
        ));
        setEditedPhoneNumber({} as PhoneResponse);
        addNewPhoneModalCloseHandler();
    };

    const addNewPhoneModalCloseHandler = () => {
        setEditedPhoneNumber({} as PhoneResponse);
        setIsPhoneModalOpen(false);
    };

    const saveContactNameClickHandler = () => {
        dispatch(updateUserContactName(
            currentUserContact?.uuid ?? '',
            { name: username } as UpdateContactRequest
        ));
        setUsername('');
    };

    const isSaveContactNameButtonDisabled = () => {
        return username === currentUserContact?.name || username.length < 1;
    };

    const backToUserContactListClickHandler = () => {
        if (hasUnsavedData) {
            setShowConfirmModal(true);
        } else {
            navigate(PrivateRoutes.private_contacts_route.path);
        }
    };

    const backToDashboardModalConfirmHandler = () => {
        backToDashboardModalCloseHandler();
        navigate(PrivateRoutes.private_contacts_route.path);
    };

    const backToDashboardModalCloseHandler = () => {
        setShowConfirmModal(false);
    };

    const removePhoneNumberClickHandler = (phoneNumber: PhoneResponse) => {
        setPhoneUuidToDelete(phoneNumber.uuid);
        setIsDeletePhoneModalOpen(true);
    };

    const removePhoneNumberModalConfirmHandler = () => {
        dispatch(deleteUserContactPhoneNumber(currentUserContact?.uuid ?? '', phoneUuidToDelete));
        removePhoneNumberModalCloseHandler();
    };

    const removePhoneNumberModalCloseHandler = () => {
        setPhoneUuidToDelete('');
        setIsDeletePhoneModalOpen(false);
    };

    const removeEmailClickHandler = (email: EmailResponse) => {
        setEmailUuidToDelete(email.uuid);
        setIsDeleteEmailModalOpen(true);
    };

    const removeEmailModalConfirmHandler = () => {
        dispatch(deleteUserContactEmail(currentUserContact?.uuid ?? '', emailUuidToDelete));
        removeEmailModalCloseHandler();
    };

    const removeEmailModalCloseHandler = () => {
        setEmailUuidToDelete('');
        setIsDeleteEmailModalOpen(false);
    };

    const editPhoneNumberClickHandler = (phoneNumber: PhoneResponse) => {
        setEditedPhoneNumber(phoneNumber);
        setIsPhoneModalOpen(true);
    };

    const editEmailClickHandler = (email: EmailResponse) => {
        setEditedEmail(email);
        setIsEmailModalOpen(true);
    };

    return (
        <>
            <Page
                  pageTitle={ t('dashboard.sidebar.userContacts.contactDetails') }
                  actionButton={
                      <CommonButton btnType={ ButtonTypeEnum.BACK } onClick={ backToUserContactListClickHandler }>
                          { t('dashboard.sidebar.userContacts.newContact.button.backToUserContactsSummary') }
                      </CommonButton>
                  }
            >
                <Card sx={ { mt: 3, pb: 3 } }>
                    <CardHeader title={ t('dashboard.sidebar.userContacts.newContact.username') } sx={ { mt: 3 } } />
                    <CardContent>
                        { isLoading && (
                            <SkeletonInput />
                        ) }
                        { !isLoading && (
                            <>
                                <Box sx={ { pt: 3 } }>
                                    <Grid container spacing={ 2 } direction='row'>
                                        <Grid item xs={ 12 } sm={ 9 } md={ 9 } lg={ 9 }>
                                            <TextField
                                                required
                                                autoFocus
                                                fullWidth
                                                label={ t('dashboard.sidebar.userContacts.newContact.username.name') }
                                                placeholder={ t('dashboard.sidebar.userContacts.newContact.username.name.placeholder') }
                                                rows={ 1 }
                                                variant='outlined'
                                                value={ username }
                                                onChange={ (e) => setUsername(e.target.value) } />
                                        </Grid>
                                        <Grid item xs={ 12 } sm={ 3 } md={ 3 } lg={ 3 } alignSelf='center'>
                                            <CommonButton
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: 'primary.main',
                                                        cursor: 'pointer',
                                                        color: 'primary.contrastText'
                                                    }
                                                }}
                                                onClick={ () => saveContactNameClickHandler() }
                                                btnType={ ButtonTypeEnum.SAVE }
                                                variant='outlined'
                                                disabled={ isSaveContactNameButtonDisabled() }>
                                                { t('dashboard.sidebar.userContacts.editContact.save') }
                                            </CommonButton>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Grid container direction='row' spacing={ 4 }>
                                    <Grid item xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 }>
                                        <Card sx={ { mt: 3, border: 'none', boxShadow: 'none' } }>
                                            <CardHeader
                                                title={ t('dashboard.sidebar.userContacts.newContact.email') }
                                                action={
                                                    !isLoading && (
                                                        <Box sx={ { m: 2, textAlign: 'center' } }>
                                                            <CommonButton
                                                                sx={{
                                                                    '&:hover': {
                                                                        backgroundColor: 'primary.main',
                                                                        cursor: 'pointer',
                                                                        color: 'primary.contrastText'
                                                                    }
                                                                }}
                                                                btnType={ ButtonTypeEnum.ADD }
                                                                variant='outlined'
                                                                onClick={ (): void => setIsEmailModalOpen(true) }>
                                                                { t('dashboard.sidebar.userContacts.newContact.email.addNew') }
                                                            </CommonButton>
                                                        </Box>
                                                    ) } />
                                            <CardContent>
                                                <UserContactEmailList
                                                    emails={ currentUserContact?.emails ?? [] }
                                                    editEmailHandler={ editEmailClickHandler }
                                                    deleteEmailHandler={ removeEmailClickHandler }
                                                />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 }>
                                        <Card sx={ { mt: 3, border: 'none', boxShadow: 'none' } }>
                                            <CardHeader
                                                title={ t('dashboard.sidebar.userContacts.newContact.phoneNumber') }
                                                action={
                                                    !isLoading && (
                                                        <Box sx={ { m: 2, textAlign: 'center' } }>
                                                            <CommonButton
                                                                sx={{
                                                                    '&:hover': {
                                                                        backgroundColor: 'primary.main',
                                                                        cursor: 'pointer',
                                                                        color: 'primary.contrastText'
                                                                    }
                                                                }}
                                                                btnType={ ButtonTypeEnum.ADD }
                                                                variant='outlined'
                                                                onClick={ (): void => setIsPhoneModalOpen(true) }>
                                                                { t('dashboard.sidebar.userContacts.newContact.phoneNumber.addNew') }
                                                            </CommonButton>
                                                        </Box>
                                                    ) } />
                                            <CardContent>
                                                <UserContactPhoneNumberList
                                                    phoneNumbers={ currentUserContact?.phones ?? [] }
                                                    editPhoneNumberHandler={ editPhoneNumberClickHandler }
                                                    deletePhoneNumberHandler={ removePhoneNumberClickHandler }
                                                />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </>
                        ) }
                    </CardContent>
                </Card>

            </Page>

            <ConfirmationDialog
                open={ isDeletePhoneModalOpen }
                title={ t('dashboard.sidebar.documents.confirmModal.removeContactPhoneNumber.title') }
                message={ t('dashboard.sidebar.documents.confirmModal.removeContactPhoneNumber.message') }
                onConfirm={ removePhoneNumberModalConfirmHandler }
                onCancel={ removePhoneNumberModalCloseHandler } />

            <ConfirmationDialog
                open={ isDeleteEmailModalOpen }
                title={ t('dashboard.sidebar.documents.confirmModal.removeContactEmail.title') }
                message={ t('dashboard.sidebar.documents.confirmModal.removeContactEmail.message') }
                onConfirm={ removeEmailModalConfirmHandler }
                onCancel={ removeEmailModalCloseHandler } />

            <ConfirmationDialog
                open={ showConfirmModal }
                title={ t('dashboard.sidebar.documents.confirmModal.unsavedData.title') }
                message={ t('dashboard.sidebar.documents.confirmModal.unsavedData.message') }
                onCancel={ backToDashboardModalCloseHandler }
                onConfirm={ backToDashboardModalConfirmHandler } />

            <ContactEmailModal
                open={ isEmailModalOpen }
                editedEmail={ editedEmail }
                emails={ currentUserContact?.emails }
                onConfirm={ addNewEmailModalConfirmHandler }
                onClose={ addNewEmailModalCloseHandler } />

            <ContactPhoneModal
                open={ isPhoneModalOpen }
                editedPhoneNumber={ editedPhoneNumber }
                phoneNumbers={ currentUserContact?.phones }
                onConfirm={ addNewPhoneModalConfirmHandler }
                onClose={ addNewPhoneModalCloseHandler } />
        </>
    );

};

export default UserContactDetails;
