import type { FC } from 'react';
import { ContactResponse } from 'contacts-service-api';
import {
    Avatar,
    Box,
    CircularProgress,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { AddMemberContactsListUtil } from './AddMemberContactsList.util';
import { Invitation } from 'group-service-api';
import { AddCircle, ImportContactsRounded } from '@mui/icons-material';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';
import SkeletonBlock from '../skeletons/SkeletonBlock';
import EmptyState from '../empty-states/EmptyState';

interface IAddMemberContactsListProps {
    contacts: ContactResponse[] | undefined;
    onClick: (invitation: Invitation, index?: number) => void;
    emptyStateTitle?: string;
    emptyStateSubtitle?: string;
}

const AddMemberContactsList: FC<IAddMemberContactsListProps> = (props) => {
    const { contacts, onClick, emptyStateTitle, emptyStateSubtitle } = props;
    const { t } = useTranslation();
    const { isLoading } = useAppSelector((state) => state.userContacts);
    const { selectedGroup, isLoadingAction, isLoadingIndex } = useAppSelector((state) => state.groups);
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    const renderFunction = () => {
        if (isLoading) {
            return <SkeletonBlock />;
        }
        if (contacts && contacts.length && contacts?.length > 0) {
            const filteredContacts = AddMemberContactsListUtil.filterContacts(selectedGroup, contacts);
            if (filteredContacts?.length) {
                return (
                    <>
                        <List
                            sx={{
                                border: '1px solid lightgrey',
                                borderRadius: '10px',
                                maxWidth: '450px',
                                p: 3,
                                pr: 1,
                                pt: 1,
                                width: '100%',
                            }}>
                            {filteredContacts?.map((contact: ContactResponse, index: number) => (
                                <Box key={contact.uuid}>
                                    <ListItem
                                        alignItems='flex-start'
                                        sx={{ pl: 0, pr: 0 }}
                                        secondaryAction={
                                            smUp ? (
                                                <CommonButton
                                                    btnType={ButtonTypeEnum.ADD}
                                                    loading={isLoadingAction && isLoadingIndex === index}
                                                    onClick={() => onClick(AddMemberContactsListUtil.invitation(contact), index)}>
                                                    {t('groups.memberships.add')}
                                                </CommonButton>
                                            ) : (
                                                <IconButton color='primary' onClick={() => onClick(AddMemberContactsListUtil.invitation(contact), index)}>
                                                    {isLoadingAction && isLoadingIndex === index ? (
                                                        <CircularProgress size={14} color='warning' />
                                                    ) : (
                                                        <AddCircle />
                                                    )}
                                                </IconButton>
                                            )
                                        }>
                                        {smUp && (
                                            <ListItemAvatar>
                                                <Avatar alt={contact.name} src='' />
                                            </ListItemAvatar>
                                        )}
                                        <ListItemText
                                            primary={contact.name}
                                            secondary={
                                                <Typography sx={{ display: 'inline' }} component='span' variant='body2' color='text.primary'>
                                                    {AddMemberContactsListUtil.secondaryListItemText(contact)}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant='fullWidth' component='li' sx={{ marginRight: '8px' }} />
                                </Box>
                            ))}
                        </List>
                    </>
                );
            }
            return (
                <EmptyState
                    message={emptyStateTitle ? emptyStateTitle : t('userContacts.emptyFilteredState.title')}
                    icon={<ImportContactsRounded />}
                    subtitle={emptyStateSubtitle}
                />
            );
        }
        return (
            <EmptyState
                message={emptyStateTitle ? emptyStateTitle : t('userContacts.emptyState.title')}
                icon={<ImportContactsRounded />}
                subtitle={emptyStateSubtitle}
            />
        );
    };

    return <>{renderFunction()}</>;
};

export default AddMemberContactsList;
