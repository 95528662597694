import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import {
    DocumentInfo as UDSDocumentInfo,
    DocumentSignatureTypeEnum,
    DocumentsResponse,
    GetDocumentsResponse,
    PatchDocumentRequest,
    Person,
    UploadDocumentsRequest
} from 'user-document-signer-api';
import { IDocumentReceiver, IVisualSignatureCoordinates } from '../model/common/documents/document-user';
import tanModalSlice from './tan-modal';
import { DocumentsHelper } from '../utils/documents-helper';
import errorHandlingSlice, { handleError } from './errors-handling';
import { Document, DocumentContent, DocumentInfo, SignRequest } from 'idy-certifier-api';
import deepCopy from '../utils/deepCopy';
import apis from '../auth/apis';
import { TokenHelper } from '../utils/token-helper';
import { ErrorType, IError } from '../model/common/error';
import { RequestHelper } from '../utils/request-helper';
import { getEntityPayables } from './payments';
import { IOrganizationDocument } from '../model/common/documents/IOrganizationDocument';
import { DocumentResponse } from 'fina-lcp-certifier-api';
import { DocumentVisibilityType } from '../model/common/documents/DocumentVisibilityType';
import { DocumentsRetrieveType } from '../model/common/documents/DocumentsRetrieveType';
import { AppSettings } from '../common/app-settings';

interface IDocumentState {
    documents: GetDocumentsResponse | undefined;
    documentUuidSigningList: string[];
    fetchingDocumentSuccessful: boolean;
    filesForUpload: File[] | undefined;
    hasValidCertificate: boolean;
    isLoadingDocContent: boolean;
    isLoadingDocDetails: boolean;
    isLoadingReceivedDocuments: boolean;
    isLoadingSentDocuments: boolean;
    isSendingDocument: boolean;
    isSigning: boolean;
    isSignSuccessful: boolean;
    isTogglingDocumentVisibility: boolean;
    isUploadingDocuments: boolean;
    notifyRecipientsSuccessfully: boolean;
    redirectTo: string;
    selectedDocumentContent: DocumentContent | undefined;
    selectedDocumentDetails: DocumentInfo | undefined;
    sendSuccessful: boolean;
    sharedDocuments: DocumentsResponse | undefined;
    tempSignSuccess: boolean;
    uploadDocumentSuccessful: boolean;
    uploadedDocument: DocumentInfo | undefined;
}

const initialState: IDocumentState = {
    documents: undefined,
    documentUuidSigningList: [],
    fetchingDocumentSuccessful: false,
    filesForUpload: undefined,
    hasValidCertificate: false,
    isLoadingDocContent: false,
    isLoadingDocDetails: false,
    isLoadingReceivedDocuments: false,
    isLoadingSentDocuments: false,
    isSendingDocument: false,
    isSigning: false,
    isSignSuccessful: false,
    isTogglingDocumentVisibility: false,
    isUploadingDocuments: false,
    notifyRecipientsSuccessfully: false,
    redirectTo: '',
    selectedDocumentContent: undefined,
    selectedDocumentDetails: undefined,
    sendSuccessful: false,
    sharedDocuments: undefined,
    tempSignSuccess: false,
    uploadDocumentSuccessful: false,
    uploadedDocument: undefined
};

const documentSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        getDocumentContent(state: IDocumentState, action: PayloadAction<DocumentContent>): void {
            state.selectedDocumentContent = { value: action.payload.value };
            state.fetchingDocumentSuccessful = true;
            state.isLoadingDocContent = false;
        },
        getDocumentInfo(state: IDocumentState, action: PayloadAction<DocumentInfo>): void {
            state.selectedDocumentDetails = action.payload;
            state.fetchingDocumentSuccessful = true;
            state.isLoadingDocDetails = false;
        },
        getDocuments(state: IDocumentState, action: PayloadAction<GetDocumentsResponse>): void {
            state.documents = action.payload;
            state.isLoadingReceivedDocuments = false;
            state.isLoadingSentDocuments = false;
        },
        getReceivedDocuments(state: IDocumentState, action: PayloadAction<GetDocumentsResponse>): void {
            state.documents = {
                receivedDocuments: action.payload.receivedDocuments || [],
                sharedDocuments: state.documents?.sharedDocuments || []
            };
            state.isLoadingReceivedDocuments = false;
        },
        getSentDocuments(state: IDocumentState, action: PayloadAction<GetDocumentsResponse>): void {
            state.documents = {
                receivedDocuments: state.documents?.receivedDocuments || [],
                sharedDocuments: action.payload.sharedDocuments || []
            };
            state.isLoadingSentDocuments = false;
        },
        notifyRecipients(state: IDocumentState): void {
            state.notifyRecipientsSuccessfully = true;
            state.isSendingDocument = false;
        },
        redirectTo(state: IDocumentState, action: PayloadAction<string>) {
            state.redirectTo = action.payload;
        },
        resetDocuments(state: IDocumentState): void {
            state.documents = undefined;
        },
        resetFetchingDocumentSuccessful(state: IDocumentState): void {
            state.fetchingDocumentSuccessful = false;
        },
        resetNotifyRecipients(state: IDocumentState): void {
            state.notifyRecipientsSuccessfully = false;
        },
        resetSelectedDocument(state: IDocumentState): void {
            state.selectedDocumentDetails = undefined;
            state.selectedDocumentContent = undefined;
            state.fetchingDocumentSuccessful = false;
            state.tempSignSuccess = false;
        },
        resetSendSuccessful(state: IDocumentState): void {
            state.sendSuccessful = false;
        },
        resetSignSuccessful(state: IDocumentState): void {
            state.isSignSuccessful = false;
        },
        setFilesForUpload(state: IDocumentState, action: PayloadAction<File[] | undefined>): void {
            state.filesForUpload = action.payload;
        },
        setIsLoadingDocDetails(state: IDocumentState, action: PayloadAction<boolean>): void {
            state.isLoadingDocDetails = action.payload;
        },
        setIsSendingDocument(state: IDocumentState, action: PayloadAction<boolean>): void {
            state.isSendingDocument = action.payload;
        },
        setIsTogglingDocumentVisibility(state: IDocumentState, action: PayloadAction<boolean>): void {
            state.isTogglingDocumentVisibility = action.payload;
        },
        setIsUploadingDocuments(state: IDocumentState, action: PayloadAction<boolean>): void {
            state.isUploadingDocuments = action.payload;
        },
        setLoadingContent(state: IDocumentState, action: PayloadAction<boolean>): void {
            state.isLoadingDocContent = action.payload;
        },
        setLoadingReceivedDocuments(state: IDocumentState, action: PayloadAction<boolean>): void {
            state.isLoadingReceivedDocuments = action.payload;
        },
        setLoadingSentDocuments(state: IDocumentState, action: PayloadAction<boolean>): void {
            state.isLoadingSentDocuments = action.payload;
        },
        setSigning(state: IDocumentState, action: PayloadAction<{
            isSigning: boolean,
            documentUuidList: string[]
        }>): void {
            state.isSigning = action.payload.isSigning;
            state.documentUuidSigningList = action.payload.documentUuidList;
        },
        setUploadDocumentSuccessFlag(state: IDocumentState, action: PayloadAction<boolean>): void {
            state.uploadDocumentSuccessful = action.payload;
        },
        setUploadedDocument(state: IDocumentState, action: PayloadAction<DocumentInfo>): void {
            state.uploadedDocument = action.payload;
        },
        shareDocuments(state: IDocumentState, action: PayloadAction<DocumentsResponse>): void {
            const documents = state.documents;
            const newDocument = deepCopy(action.payload.documents.pop());

            if (newDocument && documents) {
                state.documents = DocumentsHelper.changeDocumentSharedWith(documents, newDocument);
            }

            state.isSendingDocument = false;
            state.sendSuccessful = true;
        },
        signDocument(state: IDocumentState, action: PayloadAction<Document[]>): void {
            const documents = state.documents;
            const selectedDocumentDetails = state.selectedDocumentDetails;
            const selectedDocumentContent = state.selectedDocumentContent;
            if (selectedDocumentDetails) {
                state.selectedDocumentDetails = deepCopy(
                    action.payload.find((doc: Document) => doc.documentInfo.uuid === selectedDocumentDetails.uuid)?.documentInfo
                ) as DocumentInfo;
            }

            if (selectedDocumentContent) {
                state.selectedDocumentContent = { value: action.payload[0].value };
            }
            state.isSigning = false;
            state.documentUuidSigningList = [];
            state.isSignSuccessful = true;
            state.tempSignSuccess = true;
            if (documents) {
                state.documents = DocumentsHelper.updateSignedDocumentsIdy(documents, action.payload);
            }
        },
        updateDocumentInList(state: IDocumentState, action: PayloadAction<DocumentInfo | undefined>): void {
            const docDetails = action.payload;
            const inReceivedDocsIndex: number = state.documents?.receivedDocuments?.findIndex((doc: UDSDocumentInfo) => doc?.uuid === docDetails?.uuid) ?? -1;
            if (inReceivedDocsIndex !== -1 && docDetails) {
                const docToModify = state.documents?.receivedDocuments[inReceivedDocsIndex] as UDSDocumentInfo;
                state.documents?.receivedDocuments?.splice(inReceivedDocsIndex, 1, DocumentsHelper.mapDocumentInfoToUDSDocumentInfo(docDetails, docToModify));
                return;
            }
            const inSharedDocsIndex: number = state.documents?.sharedDocuments?.findIndex((doc: UDSDocumentInfo) => doc?.uuid === docDetails?.uuid) ?? -1;
            if (inSharedDocsIndex !== -1 && docDetails) {
                const docToModify = state.documents?.sharedDocuments[inSharedDocsIndex] as UDSDocumentInfo;
                state.documents?.sharedDocuments?.splice(inSharedDocsIndex, 1, DocumentsHelper.mapDocumentInfoToUDSDocumentInfo(docDetails, docToModify));
            }
        },
        updateDocumentsSignedFina(state: IDocumentState, action: PayloadAction<DocumentResponse[]>): void {
            const documents = state.documents;
            const selectedDocumentDetails = state.selectedDocumentDetails;
            const selectedDocumentContent = state.selectedDocumentContent;
            const selectedDocument = action.payload.find((doc: DocumentResponse) => doc.documentInfo.uuid === selectedDocumentDetails?.uuid);

            if (selectedDocumentDetails && selectedDocument) {
                state.selectedDocumentDetails = deepCopy(selectedDocument.documentInfo) as DocumentInfo;
            }

            if (selectedDocumentContent && selectedDocument) {
                state.selectedDocumentContent = { value: selectedDocument.value };
            }

            if (documents) {
                state.documents = DocumentsHelper.updateSignedDocumentsFina(documents, action.payload);
            }
        },
        updateDocumentVisibility(state: IDocumentState, action: PayloadAction<{
            documentUuid: string;
            userUuid: string;
            isVisible: boolean
        }>): void {
            const documents = state.documents;
            const selectedDocumentDetails = state.selectedDocumentDetails;
            const documentUuid = action.payload.documentUuid;
            const userUuid = action.payload.userUuid;
            const isVisible = action.payload.isVisible;
            if (selectedDocumentDetails && documentUuid === selectedDocumentDetails.uuid) {
                state.selectedDocumentDetails = deepCopy(
                    DocumentsHelper.changeDocumentVisibility(selectedDocumentDetails, documentUuid, userUuid, isVisible)
                ) as DocumentInfo;
            }

            if (documents) {
                state.documents = DocumentsHelper.changeDocumentsVisibility(documents, documentUuid, userUuid, isVisible);
            }
            state.isTogglingDocumentVisibility = false;
        },
        uploadDocument(state: IDocumentState, action: PayloadAction<DocumentsResponse>): void {
            const docs = deepCopy(action.payload.documents);
            state.isUploadingDocuments = false;
            state.uploadedDocument = docs.pop();
            state.uploadDocumentSuccessful = true;

            const documents = state.documents;
            const payload = deepCopy(action.payload);
            const newDocument = payload.documents.pop();
            if (documents && newDocument) {
                documents.sharedDocuments.push(newDocument);
                state.documents = documents;
            }
        }
    }
});

export const getDocuments = (
    visibilityType: DocumentVisibilityType = DocumentVisibilityType.VISIBLE,
    retrieveType: DocumentsRetrieveType = DocumentsRetrieveType.ALL,
    groupUuid?: string
): AppThunk =>
    async (dispatch): Promise<void> => {
        ( retrieveType === DocumentsRetrieveType.RECEIVED || retrieveType === DocumentsRetrieveType.ALL ) &&
        dispatch(documentSlice.actions.setLoadingReceivedDocuments(true));
        ( retrieveType === DocumentsRetrieveType.SENT || retrieveType === DocumentsRetrieveType.ALL ) &&
        dispatch(documentSlice.actions.setLoadingSentDocuments(true));

        apis.userDocumentSignerApi()
            // .getDocuments('ALL', groupUuid)
            .getDocuments(retrieveType, visibilityType, groupUuid)
            .then((response) => {
                const data = response.data as GetDocumentsResponse;
                retrieveType === DocumentsRetrieveType.ALL && dispatch(documentSlice.actions.getDocuments(data));
                retrieveType === DocumentsRetrieveType.RECEIVED && dispatch(documentSlice.actions.getReceivedDocuments(data));
                retrieveType === DocumentsRetrieveType.SENT && dispatch(documentSlice.actions.getSentDocuments(data));
            })
            .catch((err) => {
                ( retrieveType === DocumentsRetrieveType.RECEIVED || retrieveType === DocumentsRetrieveType.ALL ) &&
                dispatch(documentSlice.actions.setLoadingReceivedDocuments(false));
                ( retrieveType === DocumentsRetrieveType.SENT || retrieveType === DocumentsRetrieveType.ALL ) &&
                dispatch(documentSlice.actions.setLoadingSentDocuments(false));

                dispatch(handleError(err));
            });
    };

export const getDocumentDetails =
    (documentUuid: string, groupUuid: string | undefined): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(documentSlice.actions.setIsLoadingDocDetails(true));
            apis.idyCertifierApi()
                .getDocumentDetails(documentUuid, groupUuid)
                .then(({ data }) => {
                    dispatch(documentSlice.actions.getDocumentInfo(data));
                    dispatch(documentSlice.actions.updateDocumentInList(data));
                })
                .catch((err) => {
                    dispatch(documentSlice.actions.setIsLoadingDocDetails(false));
                    dispatch(handleError(err));
                });
        };

export const getDocumentContent =
    (documentUuid: string, groupUuid: string | undefined): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(documentSlice.actions.setLoadingContent(true));
            apis.idyCertifierApi()
                .getDocumentContent(documentUuid, TokenHelper.checkForPin(), groupUuid)
                .then((response) => {
                    dispatch(documentSlice.actions.getDocumentContent(response.data));
                })
                .catch((err) => {
                    dispatch(documentSlice.actions.setLoadingContent(false));
                    dispatch(handleError(err));
                });
        };

export const shareDocument = (
    shareWith: IDocumentReceiver[],
    documentUuid: string,
    signSeparateCopy: boolean,
    groupUuid: string | undefined,
    organizationUuid?: string
): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(documentSlice.actions.setIsSendingDocument(true));
        const shareDocumentsRequest = DocumentsHelper.getShareDocumentsRequestData(shareWith, documentUuid, signSeparateCopy, groupUuid, organizationUuid);
        apis.userDocumentSignerApi()
            .shareDocuments(shareDocumentsRequest)
            .then((response) => {
                dispatch(documentSlice.actions.shareDocuments(response.data));
                dispatch(getDocumentDetails(documentUuid, groupUuid));
            })
            .catch((err) => {
                if (RequestHelper.isUnprocessableEntityError(err)) {
                    dispatch(
                        errorHandlingSlice.actions.setErrorMessage({
                            error: true,
                            errorMessage: 'dashboard.sidebar.documentDetails.error.sendingDocument.wrongPhoneNumber',
                            errorType: ErrorType.PHONE_NUMBER_INVALID
                        } as IError)
                    );
                } else {
                    dispatch(handleError(err));
                }
                dispatch(documentSlice.actions.setIsSendingDocument(false));
            });
    };

export const uploadDocument = (
    files: File[],
    groupUuid: string | undefined,
    organizationUserDocuments: IOrganizationDocument[],
    signatureType: DocumentSignatureTypeEnum | undefined,
    downloadableBeforeSignature: boolean
): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(documentSlice.actions.setIsUploadingDocuments(true));

        const uploadDocumentRequest: UploadDocumentsRequest = await DocumentsHelper.getUploadDocumentRequestData(
            files,
            groupUuid,
            organizationUserDocuments,
            signatureType,
            downloadableBeforeSignature
        );

        apis.userDocumentSignerApi()
            .uploadDocuments(uploadDocumentRequest)
            .then((response) => {
                dispatch(documentSlice.actions.uploadDocument(response.data));
            })
            .catch((err) => {
                dispatch(documentSlice.actions.setIsUploadingDocuments(false));
                dispatch(handleError(err));
            });
    };

export const signDocumentsWithIdyCert = (
    documentUuidList: string[],
    groupUuid?: string,
    organizationUuid?: string,
    visualCoordinatesForSigner?: IVisualSignatureCoordinates
): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(documentSlice.actions.setSigning({ isSigning: true, documentUuidList: documentUuidList }));
        const signRequest: SignRequest = {
            signDatas: DocumentsHelper.getSignDatas(documentUuidList, visualCoordinatesForSigner),
            groupUuid: groupUuid,
            organizationUuid: organizationUuid
        };
        apis.idyCertifierApi()
            .signV2(signRequest)
            .then((response) => {
                const data: Document[] = response.data;
                dispatch(documentSlice.actions.signDocument(data));
                dispatch(getEntityPayables());
            })
            .catch((err) => {
                if (RequestHelper.isPreconditionFailed(err)) {
                    dispatch(
                        errorHandlingSlice.actions.setErrorMessage({
                            error: true,
                            errorMessage: 'dashboard.sidebar.documentDetails.error.signingDocument.notEnoughSignatures',
                            errorType: ErrorType.NOT_ENOUGH_SIGNATURES
                        } as IError)
                    );
                } else {
                    dispatch(handleError(err));
                }
                dispatch(documentSlice.actions.setSigning({ isSigning: false, documentUuidList: [] }));
            });
    };

export const signDocumentsSimpleSignature = (
    documentUuidList: string[],
    groupUuid?: string,
    organizationUuid?: string
): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(documentSlice.actions.setSigning({ isSigning: true, documentUuidList: documentUuidList }));
        const signRequest: SignRequest = {
            signDatas: DocumentsHelper.getSignDatas(documentUuidList, undefined),
            groupUuid: groupUuid,
            organizationUuid: organizationUuid
        };
        apis.simpleSignerApi()
            .signSimple(signRequest)
            .then((response) => {
                const data: Document[] = response.data;
                dispatch(documentSlice.actions.signDocument(data));
            })
            .catch((err) => {
                dispatch(handleError(err));
                dispatch(documentSlice.actions.setSigning({ isSigning: false, documentUuidList: [] }));
            });
    };

export const toggleDocumentsVisibility = (
    documentUuid: string,
    documentVisibilityType: DocumentVisibilityType,
    groupUuid?: string
): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(documentSlice.actions.setIsTogglingDocumentVisibility(true));
        apis.userDocumentSignerApi()
            .patchDocument(documentUuid, {
                isVisible: documentVisibilityType === DocumentVisibilityType.VISIBLE,
                groupUuid: groupUuid,
                isForGroup: groupUuid !== undefined
            } as PatchDocumentRequest)
            .then(() => {
                dispatch(
                    documentSlice.actions.updateDocumentVisibility({
                        documentUuid: documentUuid,
                        userUuid: groupUuid ? groupUuid : ( window.localStorage.getItem(AppSettings.USER_UUID) ?? '' ),
                        isVisible: documentVisibilityType === DocumentVisibilityType.VISIBLE
                    })
                );
            })
            .catch((err) => {
                dispatch(documentSlice.actions.setIsTogglingDocumentVisibility(false));
                dispatch(handleError(err));
            });
    };

export const notifyRecipients =
    (recipients: Person[], documentUuid: string, groupUuid?: string, organizationUuid?: string): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(documentSlice.actions.setIsSendingDocument(true));
            apis.userDocumentSignerApi()
                .notifyRecipients(DocumentsHelper.getNotifyRecipientRequest(recipients, documentUuid, groupUuid ?? '', organizationUuid ?? ''))
                .then(() => {
                    dispatch(documentSlice.actions.notifyRecipients());
                })
                .catch((err) => {
                    dispatch(documentSlice.actions.setIsSendingDocument(false));
                    dispatch(handleError(err));
                });
        };

export const resetSendSuccessful =
    (): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(documentSlice.actions.resetSendSuccessful());
            dispatch(tanModalSlice.actions.clearErrorMessage());
            dispatch(documentSlice.actions.setUploadDocumentSuccessFlag(false));
        };

export const resetSelectedDocument =
    (): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(documentSlice.actions.resetSelectedDocument());
            dispatch(tanModalSlice.actions.clearErrorMessage());
        };

export const {
    resetDocuments,
    resetNotifyRecipients,
    resetSignSuccessful,
    setFilesForUpload,
    setUploadDocumentSuccessFlag
} = documentSlice.actions;
export const { reducer } = documentSlice;

export default documentSlice;
