import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { Lock } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const SecureCheckoutLabel: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                }}>
                <Lock fontWeight='small' sx={{ color: 'text.secondary' }} />
                <Typography sx={{ ml: 2 }} variant='subtitle2'>
                    {t('dashboard.profile.plans.checkout.secureCheckout')}
                </Typography>
            </Box>
            <Typography color='textSecondary' sx={{ mt: 2 }} variant='body2'>
                {t('dashboard.profile.plans.checkout.secureCheckout.additional')}
            </Typography>
        </>
    );
};

export default SecureCheckoutLabel;
