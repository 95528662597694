export enum ContactType {
    EMAIL = 'EMAIL',
    MOBILE_NUMBER = 'MOBILE_NUMBER'
}

export interface IPhone {
    dialCode: string;
    number: string;
}

export interface IVisualSignatureCoordinates {
    pageNumber: number;
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface ISignatureInfo {
    isSignatureRequested: boolean;
    visualSignatureCoordinates?: IVisualSignatureCoordinates;
}

export interface IDocumentReceiver {
    name?: string;
    email?: string;
    phone?: IPhone;
    contactType: ContactType;
    signatureRequested: boolean;
}
