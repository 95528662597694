import {UserConsent, UserConsentStatusEnum} from 'consent-manager-api';
import { FC, useState } from 'react';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import { ProfileConsentsThreeDotMenu } from './ProfileConsentsThreeDotMenu';
import ProfileConsentsViewer from './ProfileConsentsViewer';
import { useTranslation } from 'react-i18next';
import { ConsentType } from './ProfileConsents';
import { useAppDispatch } from '../../store/hooks';
import { revokeClientConsent, revokeIdentyumConsent } from '../../slices/consents';
import { downloadPdf } from '../../utils/download';
import {Check, Close} from '@mui/icons-material';

type Props = {
    consent: UserConsent;
    consentType: ConsentType;
};

const ProfileConsentRow: FC<Props> = ({ consent, consentType }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleRevokeConsent = () => {
        setIsModalOpen(false);
        if (consentType === ConsentType.IDENTYUM) {
            dispatch(revokeIdentyumConsent(consent));
            return;
        }

        dispatch(revokeClientConsent(consent));
    };

    const handleDownloadConsent = () => {
        downloadPdf(consent.url);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleViewConsent = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <TableRow key={consent.uuid}>
                <TableCell width={100}>
                    <Box sx={{ p: 1, display: 'flex' }}>
                        <Typography color="textSecondary" variant="subtitle2" align="center">
                            {format(new Date(consent.updatedAt), 'd').toUpperCase()}.
                        </Typography>
                        {/*<Typography color="textSecondary" variant="subtitle2" align="center">*/}
                        {/*    {format(new Date(consent.updatedAt), 'LLLL', { locale: i18n.language === 'en' ? enUS : hr }).toUpperCase()}*/}
                        {/*</Typography>*/}
                        <Typography color="textSecondary" variant="subtitle2" align="center">
                            {format(new Date(consent.updatedAt), 'M').toUpperCase()}.
                        </Typography>
                        <Typography color="textSecondary" variant="subtitle2" align="center">
                            {format(new Date(consent.updatedAt), 'yyyy').toUpperCase()}.
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell align="center">
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            {t(`dashboard.profile.consents.table.identyum.type.${consent.consentType}`)}
                        </Typography>
                    </div>
                </TableCell>
                <TableCell align="center">
                    {consent.status === UserConsentStatusEnum.Revoked ? <Close color='error' /> : <Check color='success'/> }
                </TableCell>
                <TableCell align="center">
                    <ProfileConsentsThreeDotMenu
                        consentStatus={consent.status}
                        onRevokeConsent={handleRevokeConsent}
                        onDownloadConsent={handleDownloadConsent}
                        onViewConsent={handleViewConsent}
                    />
                </TableCell>
            </TableRow>
            <ProfileConsentsViewer
                consentUrl={consent.url}
                consentStatus={consent.status}
                isModalOpen={isModalOpen}
                onClose={handleModalClose}
                revokeConsentHandler={handleRevokeConsent}
            />
        </>
    );
};

export default ProfileConsentRow;
