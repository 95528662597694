import type { FC } from 'react';
import { useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { SectionRequest } from 'survey-service-api';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../../shared/CommonButton';

interface IAddSectionDialogProps {
    onClose: (request?: SectionRequest) => void;
    open: boolean;
}

const AddSectionDialog: FC<IAddSectionDialogProps> = (props) => {
    const { onClose, open } = props;
    const { t } = useTranslation();

    const [request, setRequest] = useState<SectionRequest>({ title: '', description: '', ordinal: 1 });

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(request);
        setRequest({ title: '', description: '', ordinal: 1 });
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setRequest({
            ...request,
            title: value,
        });
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = (event.target as HTMLTextAreaElement).value;
        setRequest({
            ...request,
            description: value,
        });
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (open && (e.code === 'Enter' || e.key === 'Enter')) {
            onClose(request);
            setRequest({title: '', description: '', ordinal: 1});
        }
    };

    return (
        <Dialog fullWidth open={open} onClose={() => onClose()} onKeyUp={handleKeyUp}>
            <DialogTitle>{t('survey.AddSectionDialog.title')}</DialogTitle>
            <DialogContent dividers>
                <Box sx={{ my: 2 }}>
                    <TextField
                        autoFocus
                        fullWidth
                        placeholder={t('survey.AddSectionDialog.placeholder.title')}
                        label={t('survey.AddSectionDialog.placeholder.title')}
                        name={t('survey.AddSectionDialog.placeholder.title')}
                        value={request.title}
                        onChange={handleTitleChange}
                    />
                </Box>
                <Box sx={{ my: 2 }}>
                    <TextField
                        multiline
                        fullWidth
                        rows={5}
                        placeholder={t('survey.AddSectionDialog.placeholder.description')}
                        label={t('survey.AddSectionDialog.placeholder.description')}
                        name={t('survey.AddSectionDialog.placeholder.description')}
                        value={request.description}
                        onChange={handleDescriptionChange}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <CommonButton btnType={ButtonTypeEnum.CANCEL} onClick={handleCancel}>{t('survey.AddSectionDialog.dialogActions.cancel')}</CommonButton>
                <CommonButton btnType={ButtonTypeEnum.ADD} onClick={handleOk}>{t('survey.AddSectionDialog.dialogActions.add')}</CommonButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddSectionDialog;
