import { FC, useEffect, useState } from 'react';
import Avatar from 'react-avatar-edit';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { toast } from 'react-hot-toast';

interface IUploadImageAvatarEditProps {
    existingImage?: string;
    onSave: (image: string) => void;
    onCancel: () => void;
    open: boolean;
    title: string;
}

const UploadAvatarModal: FC<IUploadImageAvatarEditProps> = (props) => {
    const { existingImage, onSave, onCancel, open, title } = props;

    const { t } = useTranslation();

    const [ preview, setPreview ] = useState<string | null>(null);
    const [ width, setWidth ] = useState<number>(300);
    const [ height, setHeight ] = useState<number>(330);
    const [ imageSrc ] = useState<string>('');

    const maxFileSize = 2_097_152;

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth / 2);
            setHeight(window.innerHeight / 4);
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [ setWidth ]);

    const onClose = () => {
        setPreview(null);
    };

    const onCrop = (newPreview: string) => {
        setPreview(newPreview);
    };

    const onModalCancel = () => {
        setPreview(null);
        onCancel();
    };

    const onBeforeFileLoad = (elem: React.ChangeEvent<HTMLInputElement>) => {
        if (elem.target.files && elem.target.files[0].size > maxFileSize) {
            toast.error(t('error.fileSize'));
            elem.target.value = '';
        }
    };

    return (
        <Dialog open={ open } maxWidth='lg' onClose={ onModalCancel }>
            <DialogTitle sx={ { textAlign: 'center' } }>
                { title }
            </DialogTitle>
            <DialogContent sx={ { justifyContent: 'center', overflow: 'scroll' } } style={ { overflow: 'hidden' } }>
                <Avatar width={ width }
                        height={ height }
                        exportSize={ 500 }
                        onBeforeFileLoad={ onBeforeFileLoad }
                        onCrop={ onCrop }
                        onClose={ onClose }
                        src={ imageSrc ? imageSrc : existingImage }
                        label={ t('actions.chooseFile') } />
            </DialogContent>
            <DialogActions sx={ { justifyContent: 'center', mb: 2, mt: 2 } }>
                <CommonButton btnType={ ButtonTypeEnum.CANCEL }
                              onClick={ onModalCancel }>
                    { t('actions.cancel') }
                </CommonButton>
                <CommonButton btnType={ ButtonTypeEnum.SAVE }
                              onClick={ () => onSave(preview ?? '') }>
                    { t('actions.confirm') }
                </CommonButton>
            </DialogActions>
        </Dialog>
    );
};
export default UploadAvatarModal;
