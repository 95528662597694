import type { FC } from 'react';
import { useState } from 'react';
import { Box, Card, CardHeader, Divider, Grid, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DocumentInfo, Person } from 'idy-certifier-api';
import { Person as UdsPerson } from 'user-document-signer-api';
import { format } from 'date-fns';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import ConfirmActionModal from '../ConfirmActionModal';
import { visibilityActions } from '../documents/DocumentList';
import { DocumentsHelper, isVisibleForCurrentUserIdy } from '../../utils/documents-helper';
import { toggleDocumentsVisibility } from '../../slices/documents';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { DocumentVisibilityType } from '../../model/common/documents/DocumentVisibilityType';
import SendDocumentModal from '../SendDocumentModal';
import { CheckCircle, ForwardToInbox, Remove, RemoveCircle, Visibility, VisibilityOff } from '@mui/icons-material';

interface IDocumentDetailsProps {
    userUuid: string | null;
    document: DocumentInfo | undefined;
    isSigned: boolean;
}

const DocumentDetails: FC<IDocumentDetailsProps> = (props: IDocumentDetailsProps) => {
    const { document, isSigned, userUuid } = props;

    const { i18n, t } = useTranslation();
    const dispatch = useAppDispatch();

    const { isTogglingDocumentVisibility } = useAppSelector((state) => state.documents);
    const { activeGroup } = useAppSelector((state) => state.groups);

    const [ isVisibilityConfirmModalOpen, setIsVisibilityConfirmModalOpen ] = useState<boolean>(false);
    const [ isSendDocumentModalOpen, setIsSendDocumentModalOpen ] = useState<boolean>(false);

    const toggleDocumentVisibilityHandler = () => {
        setIsVisibilityConfirmModalOpen(false);
        dispatch(
            toggleDocumentsVisibility(
                document?.uuid ?? '',
                isVisibleForCurrentUserIdy(userUuid, document, activeGroup?.group.uuid) ?
                    DocumentVisibilityType.HIDDEN :
                    DocumentVisibilityType.VISIBLE,
                activeGroup?.group.uuid
            )
        );
    };

    const shouldShowNotifyAgainButton: boolean =
        (
            activeGroup?.group.uuid !== undefined &&
            document?.sharedWith !== undefined &&
            document?.sharedWith?.length > 0 &&
            document?.sharedWith.some((person: Person) => !person.signatureInfo?.signed && person.uuid !== userUuid)
        ) || (
            document?.sharedWith !== undefined &&
            document?.sharedWith?.length > 0 &&
            document?.owner?.uuid === userUuid &&
            document?.sharedWith?.some((sharedWith: Person) => !sharedWith.signatureInfo?.signed && sharedWith.uuid !== userUuid)
        );

    const getGroupName = (): string => {
        const groupName = `${ t('dashboard.sidebar.documentDetails.label.group') as string }: ${ document?.owner?.fullName?.toUpperCase() }`;
        const organizationName = activeGroup?.group?.organizationUuid
            ? `, ${ t('dashboard.sidebar.documentDetails.label.organizationGroup') }: ${ activeGroup?.group?.organizationName }`
            : '';
        return groupName + organizationName;
    };

    const getOwnerIdentifier = () => {
        return DocumentsHelper.getDocumentSignerDataIdy(document?.owner) + ' ' + (
            document?.owner?.uuid === userUuid
                ? t('dashboard.sidebar.documentDetails.label.you') as string
                : ''
        );
    };

    const getRecipientsToNotify = (): UdsPerson[] => {
        const persons = document?.sharedWith?.filter((p: Person) => p.signatureInfo?.signatureRequested && !p.signatureInfo?.signed && p.uuid !== userUuid) ?? [];
        return DocumentsHelper.mapIdyPersonToUdsPerson(persons);
    };

    return (
        <Box sx={ { alignItems: 'center' } }>
            <Card sx={ { mb: 2 } }>
                <CardHeader title={ t('dashboard.sidebar.documentDetails.title') } sx={ { textAlign: 'center' } } />
                <Divider />
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography color="textPrimary" variant="subtitle2">
                                    { t('dashboard.sidebar.documentDetails.documentUuid') }
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography color="textSecondary" variant="body2">
                                    { document?.uuid }
                                </Typography>

                                <CommonButton sx={ { mt: 2 } }
                                              btnType={ ButtonTypeEnum.NO_ICON }
                                              loading={ isTogglingDocumentVisibility }
                                              disabled={ isTogglingDocumentVisibility }
                                              startIcon={ isVisibleForCurrentUserIdy(userUuid, document, activeGroup?.group.uuid)
                                                  ? <VisibilityOff fontSize="small" />
                                                  : <Visibility fontSize="small" />
                                              }
                                              variant={ 'outlined' }
                                              onClick={ () => setIsVisibilityConfirmModalOpen(true) }>
                                    { isVisibleForCurrentUserIdy(userUuid, document, activeGroup?.group.uuid)
                                        ? t('businessProfile.details.documents.action.visibility.hide')
                                        : t('businessProfile.details.documents.action.visibility.show') }
                                </CommonButton>

                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                <Typography color="textPrimary" variant="subtitle2">
                                    { t('dashboard.sidebar.documentDetails.documentName') }
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography color="textSecondary" variant="body2">
                                    { document?.title?.defaultText }
                                </Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                <Typography color="textPrimary" variant="subtitle2">
                                    { t('dashboard.sidebar.documentDetails.documentUploadDate') }
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography color="textSecondary" variant="body2">
                                    { document && document.createdAt && format(new Date(document?.createdAt), 'dd.MM.yyyy.') }
                                </Typography>
                            </TableCell>
                        </TableRow>

                        {/*Owner ili Uploaded by (ako je grupa u pitanju)*/ }
                        <TableRow>
                            <TableCell sx={ { width: '30%' } }>
                                <Typography color="textPrimary" variant="subtitle2">
                                    { activeGroup?.group?.uuid
                                        ? t('dashboard.sidebar.documents.list.table.column.documentUploadedBy')
                                        : t('dashboard.sidebar.documents.list.table.column.documentOwner')
                                    }
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Grid container direction="row" sx={ { flexWrap: 'nowrap' } }>
                                    { !document?.owner?.isGroup && ( isSigned || document?.owner?.signatureInfo?.signed ) &&
                                      <Tooltip title={ t('dashboard.sidebar.documentDetails.label.userSigned') as string }>
                                        <CheckCircle fontSize={ 'small' } color="primary" />
                                      </Tooltip>
                                    }

                                    { !document?.owner?.isGroup && !isSigned && !document?.owner?.signatureInfo?.signed &&
                                      <Tooltip title={ t('dashboard.sidebar.documentDetails.label.userNotSigned') as string }>
                                        <RemoveCircle fontSize="small" />
                                      </Tooltip>
                                    }

                                    <Typography color={ 'textSecondary' } variant="body2" sx={ { ml: document?.owner?.isGroup ? 0 : 2 } }>
                                        { document?.owner?.isGroup
                                            ? getGroupName()
                                            : getOwnerIdentifier()
                                        }
                                    </Typography>
                                </Grid>
                            </TableCell>
                        </TableRow>

                        {/*Shared Withs*/ }
                        { document?.sharedWith && document?.sharedWith?.length > 0 &&
                          <TableRow>
                            <TableCell>
                              <Typography color="textPrimary" variant="subtitle2">
                                  { t('dashboard.sidebar.documentDetails.sharedWith') }
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <>
                                  { document.sharedWith.map((sw: Person) => {
                                      return (
                                          <Grid container item direction="row" key={ sw.uuid } sx={ { flexWrap: 'nowrap' } }>
                                              { sw.signatureInfo?.signed &&
                                                <Tooltip title={ t('dashboard.sidebar.documentDetails.label.userSigned') as string }>
                                                  <CheckCircle fontSize="small" color="primary" />
                                                </Tooltip>
                                              }

                                              { !sw.signatureInfo?.signed &&
                                                <Tooltip title={ t('dashboard.sidebar.documentDetails.label.userNotSigned') as string }>
                                                  <RemoveCircle fontSize="small" />
                                                </Tooltip>
                                              }

                                              { !sw.signatureInfo?.signed && !sw.signatureInfo.signatureRequested &&
                                                <Tooltip title={ t('dashboard.sidebar.documentDetails.label.userNotRequestedToSign') as string }>
                                                  <Remove fontSize="small" />
                                                </Tooltip>
                                              }

                                              <Typography color={ 'textSecondary' } variant="body2" sx={ { ml: 2 } }>
                                                  { DocumentsHelper.getDocumentSignerDataIdy(sw) + ' ' +
                                                      ( sw.uuid === userUuid ? t('dashboard.sidebar.documentDetails.label.you') as string : '' )
                                                  }
                                              </Typography>
                                          </Grid>
                                      );
                                  }) }
                                  { shouldShowNotifyAgainButton && (
                                      <CommonButton sx={ { mt: 2 } }
                                                    btnType={ ButtonTypeEnum.NO_ICON }
                                                    startIcon={ <ForwardToInbox fontSize="small" /> }
                                                    variant={ 'outlined' }
                                                    onClick={ () => setIsSendDocumentModalOpen(true) }>
                                          { t('document.modal.sendDocument.action.send') }
                                      </CommonButton>
                                  ) }
                              </>
                            </TableCell>
                          </TableRow>
                        }
                    </TableBody>
                </Table>
            </Card>

            <ConfirmActionModal onConfirm={ toggleDocumentVisibilityHandler }
                                onClose={ () => setIsVisibilityConfirmModalOpen(false) }
                                open={ isVisibilityConfirmModalOpen }
                                title={ t('businessProfile.details.documents.action.visibility.youSure.title', {
                                    action: isVisibleForCurrentUserIdy(userUuid, document, activeGroup?.group.uuid)
                                        ? visibilityActions[i18n.language]?.hide ?? ''
                                        : visibilityActions[i18n.language]?.show ?? ''
                                }) } />

            <SendDocumentModal documentUuid={ document?.uuid ?? '' }
                               recipients={ getRecipientsToNotify() }
                               open={ isSendDocumentModalOpen }
                               onClose={ () => setIsSendDocumentModalOpen(false) } />
        </Box>
    );
};

export default DocumentDetails;
