import type { FC } from 'react';
import { useEffect, useState } from 'react';
import Page from '../../components/shared/Page';
import { Box, Card, CardActions, CardContent, CircularProgress, Typography } from '@mui/material';
import PdfViewer from '../../components/pdf-viewer/PdfViewer';
import EmptyState from '../../components/empty-states/EmptyState';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import { generatePdf, getMySurvey, getSurveyUserInitiatorData, submitSurvey } from '../../slices/survey';
import { useNavigate, useParams } from 'react-router-dom';
import { PrivateRoutes } from '../../routes';
import ConfirmationDialog from '../../components/shared/ConfirmationDialog';
import { getBasicUserData } from '../../slices/profile-data';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

const SurveyPdfPage: FC = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { pdfBase64, isLoadingPdf, isSubmittingSurvey, surveyInitiatorData, sharedSurvey } = useAppSelector((state) => state.survey);
    const { basicUserData } = useAppSelector((state) => state.profileData);

    const { surveyUserUuid } = params;

    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (surveyUserUuid) {
            dispatch(getMySurvey(surveyUserUuid));
            dispatch(generatePdf(surveyUserUuid));
            dispatch(getSurveyUserInitiatorData(surveyUserUuid));
        }

        if (!basicUserData) {
            dispatch(getBasicUserData());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (surveyUserUuid && sharedSurvey && sharedSurvey.documentUuid) {
            navigate(PrivateRoutes.private_document_details_route_sentReceived.path.replace(':documentUuid', sharedSurvey.documentUuid ?? ''));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedSurvey]);

    const handleUploadClick = () => {
        setConfirmationDialogOpen(true);
    };

    const handleConfirmUpload = () => {
        if (surveyUserUuid && pdfBase64 && surveyInitiatorData) {
            dispatch(submitSurvey(surveyUserUuid));
        } else {
            toast.error(t('survey.SurveyPdfPage.errorMessage.nonShareable'));
        }

        setConfirmationDialogOpen(false);
    };

    return (
        <Page pageTitle={t('survey.SurveyPdfPage.title')} pageDescription={t('survey.SurveyPdfPage.description')}>
            <Card>
                <CardContent>
                    {isLoadingPdf ? (
                        <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Box>
                                <CircularProgress />
                            </Box>
                            <Box mt={6}>
                                <Typography>{t('survey.SurveyPdfPage.progressDescription')}</Typography>
                            </Box>
                        </Box>
                    ) : pdfBase64 ? (
                        <PdfViewer document={pdfBase64} />
                    ) : (
                        <EmptyState message={t('survey.SurveyPdfPage.emptyState.message')} />
                    )}
                </CardContent>
                {pdfBase64 && (
                    <CardActions sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Box>
                            <Typography variant="caption" color="grey">
                                {t('survey.SurveyPdfPage.cardActions.message')}
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <CommonButton btnType={ButtonTypeEnum.SIGN} onClick={handleUploadClick} loading={isSubmittingSurvey}>
                                {t('survey.SurveyPdfPage.cardActions.button.uploadAndSign')}
                            </CommonButton>
                        </Box>
                    </CardActions>
                )}
            </Card>
            <ConfirmationDialog
                title={t('survey.SurveyPdfPage.confirmationDialog.title')}
                message={t('survey.SurveyPdfPage.confirmationDialog.message')}
                onConfirm={handleConfirmUpload}
                onCancel={() => setConfirmationDialogOpen(false)}
                open={confirmationDialogOpen}
            />
        </Page>
    );
};

export default SurveyPdfPage;
