import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, Typography } from '@mui/material';
import SignaturesCounter from '../../profile/SignaturesCounter';
import { IdentificationData } from 'profile-manager-api';
import { Misc } from '../../../utils/misc';
import { Email, Person, Phone } from '@mui/icons-material';
import { ApiGetDocumentsResponse } from 'document-scanner-api';

interface IAvatarInfo {
    isProfileVerified: boolean;
    profileData?: ApiGetDocumentsResponse;
    userIdentificationData?: IdentificationData;
}

const PersonalAvatarPanel: FC<IAvatarInfo> = ({ isProfileVerified, profileData, userIdentificationData }) => {
    const userIdentifier = Misc.resolveUserIdentifier(userIdentificationData);

    const faceImage = Misc.getUserAvatar(profileData);
    const userAvatar = faceImage ? `data:image/jpeg;base64,${ faceImage }` : '';
    const getIconComponent = () => {
        const hasEmails = ( userIdentificationData?.emails?.length ?? 0 ) > 0;
        const hasPhones = ( userIdentificationData?.phones?.length ?? 0 ) > 0;

        return userIdentificationData?.fullName
            ? <Person fontSize="large" />
            : hasEmails
                ? <Email fontSize="large" />
                : hasPhones
                    ? <Phone fontSize="large" />
                    : <Person fontSize="large" />;
    };

    return (
        <>
            <RouterLink to="/dashboard/profile/general" style={ { textDecoration: 'none' } }>
                <Avatar src={userAvatar} sx={ { cursor: 'pointer', height: 48, textDecoration: 'none', width: 48 } } variant="rounded">
                    { userAvatar ? null : getIconComponent() }
                </Avatar>
            </RouterLink>

            <Box sx={ { ml: 2 } }>
                <Typography color="textPrimary" variant="subtitle2">
                    { userIdentifier ?? '' }
                </Typography>
                { isProfileVerified && <SignaturesCounter /> }
            </Box>
        </>
    );
};

export default PersonalAvatarPanel;
