import type { FC } from 'react';
import { useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import pricingPlanMax from '../../../assets/img/pricing/PricingPlanMax2_croped.png';
import { useTranslation } from 'react-i18next';
import ProvidedPayables from './ProvidedPayables';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { PayableItemPayableEnum, ProvidedPayableItem, ProvidedPayableItemPayableEnum } from 'payment-service-api';
import { getProvidedPayablesItems } from '../../../slices/payments';
import auth from '../../../auth/auth';
import { ProvidedPayableItemType } from '../../../model/common/payments/ProvidedPayableItemType';

interface IShopHeading {
    onIssueCertificate: () => void;
}

const ShopHeading: FC<IShopHeading> = ({ onIssueCertificate }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { hasValidFinaCertificate, isLoadingCertificateStatus } = useAppSelector((state) => state.finaLcp);
    const { receivedPayables, isLoading } = useAppSelector((state) => state.payments);
    const { selectedOrganization } = useAppSelector((state) => state.organizations);

    const userUuid = auth.getUserUuid();

    const receivedFinaCertificate: ProvidedPayableItem | undefined = receivedPayables
        ?.find((rp: ProvidedPayableItem) =>
            rp
            && rp.payable === ProvidedPayableItemPayableEnum.FinaLcpCertificate
            && rp.sentToEntityUuid === userUuid
            && rp.amount > 0
        );

    useEffect(() => {
        if (!receivedPayables && userUuid) {
            dispatch(getProvidedPayablesItems(
                [ userUuid ],
                [ PayableItemPayableEnum.FinaLcpCertificate ],
                ProvidedPayableItemType.RECEIVED)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Box sx={ { backgroundColor: 'background.default' } }>
            <Container maxWidth='lg'>

                { !hasValidFinaCertificate && receivedFinaCertificate
                    ?
                    <ProvidedPayables hasValidFinaCertificate={ hasValidFinaCertificate }
                                      isLoading={ isLoading }
                                      isLoadingCertificateStatus={ isLoadingCertificateStatus }
                                      providedFinaCertificate={ receivedFinaCertificate }
                                      onIssueCertificate={ onIssueCertificate }
                                      selectedOrganization={ selectedOrganization } />
                    :
                    <Grid container alignItems='center' spacing={ 2 } flexWrap='nowrap'>
                        <Grid item md={ 7 } xs={ 12 }>
                            <Typography variant='h3'>{ t('dashboard.profile.plans.landingPage.title') }</Typography>
                            <Typography color='textSecondary' sx={ { my: 2 } } variant='body1'>
                                { t('dashboard.profile.plans.landingPage.subtitle') }
                            </Typography>
                        </Grid>
                        <Grid item
                              md={ 5 }
                              sx={ {
                                  display: {
                                      md: 'block',
                                      xs: 'none'
                                  }
                              } }>
                            <Box sx={ {
                                '& img': {
                                    height: 'auto',
                                    position: 'absolute',
                                    top: 0,
                                    width: '70%'
                                },
                                height: 320,
                                maxWidth: 419,
                                position: 'relative'
                            } }>
                                <img alt='Pricing hero' src={ pricingPlanMax } />
                            </Box>
                        </Grid>
                    </Grid>
                }
            </Container>
        </Box>
    );
};

export default ShopHeading;
