import type {FC} from 'react';
import {Skeleton, TableBody, TableCell, TableRow} from '@mui/material';

interface ISkeletonProps {
    numberOfColumns: number;
    withCheckBox: boolean;
}

const SkeletonList: FC<ISkeletonProps> = (props) => {

    const { withCheckBox, numberOfColumns} = props;
    const skeletonArray = Array(4).fill('');

    return (
        <TableBody>
            {
                skeletonArray.map((item, index) => {
                    return (
                        <TableRow key={index}>
                            {withCheckBox &&(
                                <TableCell>
                                    <Skeleton variant={'rectangular'} width={30} animation={'wave'}/>
                                </TableCell>
                            )}
                            <TableCell colSpan={numberOfColumns} align={'center'}>
                                <Skeleton variant='text' animation={'wave'}/>
                            </TableCell>
                        </TableRow>
                    );
                })
            }
        </TableBody>
    );
};

export default SkeletonList;
