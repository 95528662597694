import type { FC } from 'react';
import { useEffect, useState } from 'react';
import ShopHeading from './ShopHeading';
import PlansList from './PlanList';
import ShopLegalDocs from './ShopLegalDocs';
import CertificateIssue from '../../../pages/fina-certificate/CertificateIssue';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { loadPlans, setIsPaymentSuccessful, setIssueFinaCertAutomatically } from '../../../slices/payments';
import SignaturesList from './SignaturesList';
import EurPriceDisclaimer from './EurPriceDisclaimer';
import { hasValidFinaPKICloudCertificate } from '../../../slices/fina-lcp';
import { getProfileData } from '../../../slices/profile-data';
import Page from '../../shared/Page';
import VerifyProfile from '../VerifyProfile';
import SkeletonBlock from '../../skeletons/SkeletonBlock';

const Shop: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [ isFinaCertIssueShown, setIsFinaCertIssueShown ] = useState<boolean>(false);

    const {
        availablePlans,
        isLoading,
        isPaymentSuccessful,
        issueFinaCertAutomatically,
        receivedPayables,
        signatureBundles,
        userPayables
    } = useAppSelector(state => state.payments);
    const isLoadingUserStorage = useAppSelector((state) => state.payments).isLoading;
    const { hasValidFinaCertificate, isLoadingCertificateStatus } = useAppSelector((state) => state.finaLcp);
    const { profileData } = useAppSelector((state) => state.profileData);
    const isLoadingProfileData = useAppSelector((state) => state.profileData.isLoading);
    const isProfileVerified = profileData?.documents && profileData?.documents.length >= 1;

    useEffect(() => {
        dispatch(hasValidFinaPKICloudCertificate());
        dispatch(loadPlans());
        dispatch(getProfileData());

        return () => setIsFinaCertIssueShown(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isPaymentSuccessful) {
            toast.success(t('WSPay.message.payment.SUCCESS') as string);
            dispatch(setIsPaymentSuccessful(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isPaymentSuccessful ]);

    useEffect(() => {
        if (issueFinaCertAutomatically) {
            setIsFinaCertIssueShown(!hasValidFinaCertificate);
            dispatch(setIssueFinaCertAutomatically(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ issueFinaCertAutomatically ]);

    const toggleCertificateIssueShown = () => {
        setIsFinaCertIssueShown(prevValue => !prevValue);
    };

    return (
        <Page pageTitle={ t('dashboard.sidebar.plans.title') }>
            { isLoadingProfileData && <SkeletonBlock height={ 200 } /> }

            { isProfileVerified && isFinaCertIssueShown && <CertificateIssue onBackToPlans={ toggleCertificateIssueShown } /> }

            { isProfileVerified && !isFinaCertIssueShown &&
              <>
                <ShopHeading onIssueCertificate={ toggleCertificateIssueShown } />

                <PlansList userPayables={ userPayables }
                           receivedPayables={ receivedPayables }
                           profileData={ profileData }
                           availablePlans={ availablePlans }
                           hasValidCertificate={ hasValidFinaCertificate }
                           isLoading={ isLoading }
                           isLoadingUserData={ isLoading || isLoadingProfileData || isLoadingUserStorage || isLoadingCertificateStatus }
                           onIssueFinaCert={ toggleCertificateIssueShown } />

                <SignaturesList isLoading={ isLoading }
                                signatureBundles={ signatureBundles } />

                <EurPriceDisclaimer />
              </>
            }

            { isProfileVerified && <ShopLegalDocs /> }

            { !isProfileVerified && !isLoadingProfileData && <VerifyProfile /> }
        </Page>
    );
};

export default Shop;
