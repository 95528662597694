import { FC, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Login from '../../pages/authentication/Login';
import { TokenHelper } from 'utils/token-helper';
import PinInsertionPage from 'pages/authentication/PinInsertionPage';
import { Box, CircularProgress } from '@mui/material';
import auth from '../../auth/auth';

interface AuthGuardProps {
    children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
    const { children } = props;
    const location = useLocation();
    const pinFromSessionStorage = TokenHelper.checkForPin();
    const [ isLoggedIn, setIsLoggedIn ] = useState<boolean>(false);
    const [ refreshInProgress, setRefreshInProgress ] = useState<boolean>(false);

    useEffect(() => {
        if (localStorage.getItem('fullScopeRedirectUrl')) {
            localStorage.removeItem('fullScopeRedirectUrl');
        }

        const checkIfLoggedIn = async () => {
            setRefreshInProgress(true);
            auth.isLoggedIn()
                .then((isLoggedIn) => setIsLoggedIn(isLoggedIn))
                .catch(() => setIsLoggedIn(false))
                .finally(() => setRefreshInProgress(false));
        };

        if (!isLoggedIn) {
            checkIfLoggedIn();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setRedirectLink = () => {
        localStorage.removeItem('redirectLink');
        if (location.pathname && location.pathname !== '/' && !location.pathname.includes('login') && !location.pathname.includes('redirect')) {
            localStorage.setItem('redirectLink', location.pathname);
        }
    };

    if (!isLoggedIn || !TokenHelper.isTokenExpirationDateValid()) {
        setRedirectLink();
        return refreshInProgress ?
            <Box sx={ { left: '50%', opacity: 0.6, position: 'absolute', top: '50%' } }>
                <CircularProgress size="5rem" />
            </Box> :
            <Login />;
    }

    if (!pinFromSessionStorage) {
        setRedirectLink();
        return <PinInsertionPage />;
    }

    return <>{ children }</>;
};

export default AuthGuard;
