import { FC } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';

interface ICertificateCardProps {
    image: string,
    title: string,
    buttonTitle: string,
    description: string,
    onConfirm: () => void,
    onDetails: () => void
}

const CertificateCard: FC<ICertificateCardProps> = (props: ICertificateCardProps ) => {
    const { image, title, description, onConfirm, onDetails, buttonTitle } = props;
    const { t } = useTranslation();

    return (
        <Card sx={{ border: 'none', boxShadow: 'none', p: 2 }}>
            <CardContent>
                <Grid container spacing={2} direction='column' justifyContent='center' alignItems='center'>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                        < Box sx={{
                            height: 52,
                            width: 90,
                            '& img': {
                                height: 'auto',
                                width: '100%',
                            },
                        }}>
                            <img src={image} alt='certificateLogo'/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                        <Typography variant='h5' >
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={5}>
                        <Typography variant='subtitle1' sx={{ mb: 3, textAlign: 'center' }}>
                            {description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} lg={1}>
                        <CommonButton btnType={ButtonTypeEnum.NO_ICON} onClick={onConfirm}>
                            {buttonTitle}
                        </CommonButton>
                        <CommonButton btnType={ButtonTypeEnum.NO_ICON} onClick={onDetails} sx={{ ml: 2 }}>
                            {t('certificateSelectModal.button.details')}
                        </CommonButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CertificateCard;
