import {FC} from 'react';
import {Skeleton} from '@mui/material';


const SkeletonInput: FC = () => {

    return (
        <Skeleton
            variant='rectangular'
            animation='wave'
            height={56} sx={{borderRadius: '16px', my: 3}}/>
    );
};

export default SkeletonInput;