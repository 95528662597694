import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Dialog, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ErrorType, IError } from '../../model/common/error';
import { DocumentsHelper } from '../../utils/documents-helper';
import { EmailResponse } from 'contacts-service-api';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';

interface ContactEmailModalProps {
    emails: EmailResponse[] | undefined;
    editedEmail?: EmailResponse;
    onConfirm: (email: EmailResponse) => void;
    onClose: () => void;
    open: boolean;
}

const ContactEmailModal: FC<ContactEmailModalProps> = (props) => {
    const { onConfirm, onClose, open, emails, editedEmail, ...other } = props;
    const [ email, setEmail ] = useState<string>('');
    const [ validationError, setValidationError ] = useState<IError>({
        error: false,
        errorMessage: '',
        errorType: ErrorType.VALIDATION_ERROR
    });
    const [ isEditingEmail, setIsEditingEmail ] = useState<boolean>(false);
    const { t } = useTranslation();

    const modalTitle = isEditingEmail
        ? t('dashboard.sidebar.userContacts.newContact.updateEmail.modal.title')
        : t('dashboard.sidebar.userContacts.newContact.newEmail.modal.title');

    const buttonTitle = isEditingEmail
        ? t('dashboard.sidebar.userContacts.newContact.email.modal.updateEmail')
        : t('dashboard.sidebar.userContacts.newContact.email.modal.addEmail');

    useEffect(() => {
        setIsEditingEmail(!!editedEmail?.email);
        setEmail(editedEmail?.email ?? '');
    }, [ editedEmail ]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setValidationError({ error: false, errorMessage: '', errorType: ErrorType.VALIDATION_ERROR });
        setEmail(event.target.value);
    };

    const emailValidation = (): boolean => {
        if (editedEmail?.email) {
            const filteredEmails = emails?.filter(e => e.email !== editedEmail.email);
            const existingEmail = filteredEmails?.some(e => e.email === email);
            return existingEmail ?? false;
        }
        const isExistingEmail = emails?.some(e => e.email === email);
        return isExistingEmail ?? false;
    };

    const handleConfirm = () => {
        const validEmail = DocumentsHelper.isEmailFormat(email);
        const existingEmail = emailValidation();

        if (validEmail && !existingEmail) {
            onConfirm({ email, isDefault: false } as EmailResponse);
            setEmail('');
            setValidationError({ error: false, errorMessage: '', errorType: ErrorType.VALIDATION_ERROR });
        }

        if (existingEmail) {
            setValidationError({
                error: true,
                errorMessage: t('dashboard.sidebar.userContacts.newContact.email.modal.emailAddress.existingEmail') as string,
                errorType: ErrorType.VALIDATION_ERROR
            });
        }

        if (!validEmail) {
            setValidationError({
                error: true,
                errorMessage: t('dashboard.sidebar.userContacts.newContact.email.modal.emailAddress.validationError') as string,
                errorType: ErrorType.VALIDATION_ERROR
            });
        }
    };

    const isButtonDisabled = () => {
        return Boolean(validationError.errorMessage) || email.trim().length === 0;
    };

    const onCloseHandler = () => {
        setValidationError({ error: false, errorMessage: '', errorType: ErrorType.VALIDATION_ERROR });
        onClose();
    };

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            handleConfirm();
        }
    };

    return (
        <Dialog maxWidth="lg" onClose={ onCloseHandler } open={ open } { ...other }>
            <Box sx={ { ml: 3, mr: 3, p: 3 } }>
                <Grid container direction="column">
                    <Box sx={ { pl: 7, pr: 7 } }>
                        <Typography align="center" color="textPrimary" gutterBottom variant="h5">
                            { modalTitle }
                        </Typography>
                    </Box>
                    <Grid item>
                        <Box sx={ { mt: 3, textAlign: 'center' } }>
                            <TextField
                                error={ Boolean(validationError.errorMessage) }
                                helperText={ Boolean(validationError.errorMessage) && validationError.errorMessage }
                                autoFocus
                                fullWidth
                                label={ t('dashboard.sidebar.userContacts.newContact.email.modal.emailAddress') }
                                onChange={ handleChange }
                                onKeyDown={ keyDownHandler }
                                placeholder={ t('dashboard.sidebar.userContacts.newContact.email.modal.emailAddress.placeholder') }
                                rows={ 1 }
                                value={ email }
                                variant="outlined"
                            />
                        </Box>
                    </Grid>
                    <Box sx={ { mt: 3, p: 3 } }>
                        <CommonButton fullWidth
                                      btnType={ ButtonTypeEnum.CONFIRM }
                                      onClick={ handleConfirm }
                                      disabled={ isButtonDisabled() }>
                            { buttonTitle }
                        </CommonButton>
                    </Box>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default ContactEmailModal;
