import type { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FinaCertBasicInfo: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <Typography sx={ { mt: 2, pb: 2, pt: 2 } } color='textSecondary' variant='h6'>
                { t('dashboard.profile.general.finaLcp.newCert.firstStep') }
            </Typography>
            <Typography sx={ { pb: 2, pt: 2 } } color='textSecondary' variant='body1'>
                { t('dashboard.profile.general.finaLcp.newCert.info') }
            </Typography>
            <Typography sx={ { pb: 2, pt: 2 } } color='textSecondary' variant='body1'>
                { t('dashboard.profile.general.finaLcp.newCert.validationPeriod') }
            </Typography>
        </>
    );
};

export default FinaCertBasicInfo;
