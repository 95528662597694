import PropTypes from 'prop-types';

const Move = ({ size = 40, color = '#5664d2' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={ size } height={ size } viewBox="0 0 24 24" fill="none" stroke={ color } strokeWidth="2"
         strokeLinecap="round" strokeLinejoin="bevel">
        <path d="M5.2 9l-3 3 3 3M9 5.2l3-3 3 3M15 18.9l-3 3-3-3M18.9 9l3 3-3 3M3.3 12h17.4M12 3.2v17.6" />
    </svg> );

Move.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
};

Move.displayName = 'Move';
export default Move;