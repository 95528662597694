import { FC, useEffect, useState } from 'react';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PDFViewer from 'components/pdf-viewer/PdfViewer';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import TanInsert from '../../pages/certificates/TanInsert';
import LoadingComponent from '../LoadingComponent';
import { useAppSelector } from '../../store/hooks';
import { RestartAltRounded } from '@mui/icons-material';

interface IFinaContractPreviewProps {
    finaContractContent: string;
    isLoadingContract: boolean;
    isSendingTan: boolean;
    isSigningContract: boolean;
    onLoadContract: () => void;
    onSendTan: () => void;
    onSignContract: (tan: string) => void;
    onSetFromBeginning: () => void;
}

const FinaContract: FC<IFinaContractPreviewProps> = (props: IFinaContractPreviewProps) => {
    const {
        finaContractContent,
        isLoadingContract,
        isSendingTan,
        isSigningContract,
        onLoadContract,
        onSendTan,
        onSignContract,
        onSetFromBeginning
    } = props;

    const { t } = useTranslation();
    const [isTanInsertShown, setIsTanInsertShown] = useState<boolean>(false);
    const { isTanModalVisible } = useAppSelector(state => state.tanModal);
    const { isTanBlocked } = useAppSelector((state) => state.finaLcp);

    useEffect(() => {
        onLoadContract();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const isLoading = isSigningContract || isSendingTan || isLoadingContract;
    const loadingTitle = isLoadingContract
        ? t('dashboard.profile.general.finaLcp.steps.third.fetchingContract')
        : isSigningContract
            ? t('dashboard.profile.general.finaLcp.steps.third.label')
            : isSendingTan
                ? t('tan.sending')
                : '';

    const handleSignContract = () => {
        onSendTan();
        setIsTanInsertShown(true);
    };

    const getComponent = () => {
        if (isTanBlocked) {
            return (
                <Box sx={{ ml: mdUp ? 7 : 0, mr: mdUp ? 7 : 0, mt: 3, textAlign: 'center' }}>
                    <Typography sx={ { mt: 2, pb: 2, pt: 2 } } color='warningMain' variant='body1'>
                        { t('dashboard.sidebar.documents.enterTan.tanBlocked.message') }
                    </Typography>
                    <Box sx={ { mt: 7, pl: !mdUp ? 3 : 0, pr: !mdUp ? 3 : 0 } }>
                        <CommonButton
                            fullWidth={ !mdUp }
                            btnType={ ButtonTypeEnum.NO_ICON_CONTAINED }
                            startIcon={ <RestartAltRounded fontSize='small' /> }
                            onClick={ onSetFromBeginning }>
                            { t('dashboard.profile.general.finaLcp.startFromBeginning') }
                        </CommonButton>
                    </Box>
                </Box>
            );
        }

        if (isTanInsertShown || isTanModalVisible) {
            return <TanInsert onConfirm={onSignContract} />;
        }

        return (
            <Box>
                <Typography
                    sx={{ mb: 2, mt: 2, textAlign: 'center', width: '100%' }}
                    color="textSecondary"
                    variant="h6">
                    { t('dashboard.profile.general.finaLcp.signWarning') }
                </Typography>

                <PDFViewer document={finaContractContent} />

                <Box sx={{ mt: 7, pl: !mdUp ? 3 : 0, pr: !mdUp ? 3 : 0, textAlign: 'center' }}>
                    <CommonButton
                        fullWidth={!mdUp}
                        btnType={ButtonTypeEnum.SIGN}
                        onClick={handleSignContract}>
                        { t('dashboard.profile.general.finaLcp.signContract') }
                    </CommonButton>
                </Box>
            </Box>
        );
    };

    return (
        <>
            <Box>
                {isLoading ?
                    <LoadingComponent title={loadingTitle} /> :
                    getComponent()
                }
            </Box>
        </>
    );
};

export default FinaContract;
