import type { FC } from 'react';
import { Box, Chip, Divider, Theme, Typography } from '@mui/material';
import { PaymentsHelper } from '../../../utils/payments-helper';
import CommonButton, { ButtonTypeEnum } from '../../shared/CommonButton';
import numeral from 'numeral';
import useSettings from '../../../hooks/useSettings';
import { THEMES } from '../../../common/constants';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { AmountCurrencyCurrencyEnum } from 'payment-service-api';
import { IFeature } from './PricingPlan';
import { setCheckoutSignatureItem } from '../../../slices/payments';
import { PrivateRoutes } from '../../../routes';
import { useAppDispatch } from '../../../store/hooks';
import { useNavigate } from 'react-router-dom';

interface ISignatureBundleItemProps {
    currency: AmountCurrencyCurrencyEnum;
    description: string;
    features: IFeature[];
    image: string;
    name: string;
    price: number;
    sx?: SxProps<Theme>;
    uuid: string;
}

const SignatureBundleItem: FC<ISignatureBundleItemProps> = (props) => {
    const { currency, description, features, image, name, price, sx, uuid, ...other } = props;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const { t } = useTranslation();
    const { settings } = useSettings();

    const isDarkMode = settings.theme === THEMES.DARK;

    const handlePaySignatures = () => {
        dispatch(setCheckoutSignatureItem(uuid));
        navigate(PrivateRoutes.private_checkout_route.path ?? '');
    };

    return (
        <Box sx={ {
            backgroundColor: theme.palette.background.paper,
            border: isDarkMode ? '' : '1px solid #dcdcdc',
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            ...sx
        } }
             { ...other }>
            <Box sx={ { p: 3 } }>
                <Box
                    sx={ {
                        height: 52,
                        width: 52,
                        '& img': {
                            height: 'auto',
                            width: '100%'
                        }
                    } }>
                    <img alt='' src={ image } />
                </Box>
                <Box sx={ { display: 'flex' } }>
                    <Typography variant='h4'>
                        { PaymentsHelper.getCurrencySymbol(currency) + ' ' }
                        { numeral(price).format('0.00') }
                    </Typography>
                    { price > 0 && (
                        <Typography color='textSecondary'
                                    sx={ {
                                        alignSelf: 'flex-end',
                                        ml: 1
                                    } }
                                    variant='subtitle2'>
                            { PaymentsHelper.getHrkPrice(price) }
                        </Typography>
                    ) }
                </Box>
                <Typography sx={ { mt: 2 } } variant='h6'>
                    { t(name) }
                </Typography>
                <Typography color='primaryContrastText' sx={ { mt: 2 } } variant='body2'>
                    { t(description) }
                </Typography>
            </Box>
            <Divider />
            <Box sx={ {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                p: 3
            } }>
                { features.map((feature, index: number) => (
                    <Box key={ index }
                         sx={ {
                             alignItems: 'center',
                             display: 'flex',
                             '& + &': {
                                 mt: 2
                             }
                         } }>
                        <Chip size='small' label={ feature.quantity } sx={ { width: '40px' } } />
                        <Typography sx={ {
                            fontWeight: 500,
                            ml: 2
                        } }
                                    variant='body2'>
                            { t(feature.name) }
                        </Typography>
                    </Box>
                )) }
                <Box sx={ { flexGrow: 1 } } />
                <Box sx={ {
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 6
                } }>
                    <CommonButton variant='outlined'
                                  btnType={ ButtonTypeEnum.NO_ICON }
                                  fullWidth={ true }
                                  onClick={ handlePaySignatures }>
                        { t('shop.signatures.buy') }
                    </CommonButton>
                </Box>
            </Box>
        </Box>
    );
};

export default SignatureBundleItem;
