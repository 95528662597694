import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../components/shared/Page';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getGroup, revokeAccess, updateMembership } from '../../slices/groups';
import MembersTable from '../../components/groups/MembersTable';
import {
    MembershipRoleEnum,
    MembershipSimple,
    MembershipSimpleRoleEnum,
    UpdateMembershipRequestRoleEnum,
} from 'group-service-api';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import ConfirmationDialog from '../../components/shared/ConfirmationDialog';

const MembersPage: FC = () => {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { selectedGroup, isLoadingGroup, activeGroup } = useAppSelector((state) => state.groups);

    const [membershipToDelete, setMemberShipToDelete] = useState<MembershipSimple | undefined>(undefined);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const { groupUuid } = params;

    useEffect(() => {
        if (!selectedGroup && groupUuid) {
            dispatch(getGroup(groupUuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddNewMemberClick = () => {
        navigate('add');
    };

    const handleRoleChange = (membership: MembershipSimple) => {
        const newRole = membership.role == MembershipSimpleRoleEnum.Regular ? UpdateMembershipRequestRoleEnum.Admin : UpdateMembershipRequestRoleEnum.Regular;
        dispatch(updateMembership(selectedGroup?.uuid ?? '', membership.uuid, { role: newRole }));
    };

    const handleMemberRemoveClick = (membership: MembershipSimple) => {
        if (membership) {
            setMemberShipToDelete(membership);
            setDeleteModalOpen(true);
        }
    };

    const handleCloseDeleteDialog = (isDeletingMember: boolean) => {
        if (isDeletingMember) {
            dispatch(revokeAccess(selectedGroup?.uuid ?? '', membershipToDelete?.uuid ?? ''));
        }
        setDeleteModalOpen(false);
    };

    return (
        <Page
            pageTitle={t('group.members.title')}
            actionButton={
                activeGroup?.role !== MembershipRoleEnum.Regular ? (
                    <CommonButton btnType={ButtonTypeEnum.ADD} onClick={handleAddNewMemberClick}>
                        {t('groups.memberships.add.member')}{' '}
                    </CommonButton>
                ) : (
                    <></>
                )
            }>
            <MembersTable
                memberships={selectedGroup?.memberships}
                isLoading={isLoadingGroup}
                userRole={activeGroup?.role}
                onRoleChange={handleRoleChange}
                onMemberRemoveClick={handleMemberRemoveClick}
            />
            <ConfirmationDialog
                title={t('group.members.deleteMember.title')}
                message={t('group.members.deleteMember.message')}
                onConfirm={() => handleCloseDeleteDialog(true)}
                onCancel={() => handleCloseDeleteDialog(false)}
                open={deleteModalOpen}
            />
        </Page>
    );
};

export default MembersPage;
