import { DocumentInfo as DocumentInfoIdyCertifier, Person } from 'idy-certifier-api';
import { DocumentInfo, Person as UserDocSignerPerson } from 'user-document-signer-api';

export class DocumentDetailsPageUtil {
    public static needSignatureFromCurrentUser = (selectedDocumentDetails: DocumentInfoIdyCertifier | undefined, userUuid: string | null): boolean => {
        if (selectedDocumentDetails?.owner?.uuid === userUuid) {
            return selectedDocumentDetails?.owner?.signatureInfo?.signatureRequested;
        }

        return (
            selectedDocumentDetails
                ?.sharedWith
                ?.find(
                    (signer: Person) => signer?.uuid === userUuid
                ) as Person )
            ?.signatureInfo
            ?.signatureRequested;
    };

    public static needSignatureForUser = (document: DocumentInfo, userName: string | null): boolean => {
        if (document.owner?.uuid === userName) {
            return document.owner?.signatureInfo?.signatureRequested;
        }

        return ( document.sharedWith
            ?.find((signer: UserDocSignerPerson) => signer?.uuid === userName) as UserDocSignerPerson )
            ?.signatureInfo
            ?.signatureRequested;
    };

    public static isSigned = (selectedDocumentDetails: DocumentInfoIdyCertifier | undefined, userName: string | null): boolean => {
        if (selectedDocumentDetails?.owner?.uuid === userName) {
            return selectedDocumentDetails?.ownerSigned;
        }
        return ( selectedDocumentDetails?.sharedWith?.find((signer: Person) => signer?.uuid === userName) as Person )?.signatureInfo?.signed;
    };

    public static getCurrentUser(selectedDocumentDetails: DocumentInfoIdyCertifier | undefined, userName: string | null): Person | undefined {
        if (selectedDocumentDetails?.owner?.uuid === userName) {
            return selectedDocumentDetails?.owner;
        }
        return selectedDocumentDetails?.sharedWith?.find((signer: Person) => signer?.uuid === userName) as Person;
    }
}
