import type { FC } from 'react';
import { useEffect, useState } from 'react';
import {
    Box,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getAllOrganizations } from '../slices/organizations';
import { HelpRounded } from '@mui/icons-material';
import { MemberOutRoleEnum, OrganizationOut, OrganizationOutStatusEnum } from 'organizations-service-api';

interface IOrganizationSelectProps {
    onOrganizationSelect: (organizationUuid: string) => void;
    organization?: OrganizationOut;
}

const OrganizationSelect: FC<IOrganizationSelectProps> = ({ organization, onOrganizationSelect }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { allOrganizations } = useAppSelector((state) => state.organizations);

    const [isBusinessGroup, setIsBusinessGroup] = useState<boolean>(!!organization?.uuid);
    const [organizationUuid, setOrganizationUuid] = useState<string>(organization?.uuid ?? '');

    const organizations: OrganizationOut[] = allOrganizations
        ?.filter(
            (o: OrganizationOut) => o.status === OrganizationOutStatusEnum.Approved
        ) ?? [];

    const filteredOrganizations = organizations
        ?.filter(
            (org: OrganizationOut) =>
                org?.currentUser?.role === MemberOutRoleEnum.Admin ||
                org?.currentUser?.role === MemberOutRoleEnum.Owner
        );

    useEffect(() => {
        if (!allOrganizations) {
            dispatch(getAllOrganizations());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOrganizationChange = (event: SelectChangeEvent): void => {
        setOrganizationUuid(event.target.value as string);
        onOrganizationSelect(event.target.value as string);
    };

    const handleSwitchBusinessGroup = () => {
        setIsBusinessGroup((prevState) => !prevState);
        setOrganizationUuid('');
        onOrganizationSelect('');
    };

    return (
        <>
            <FormControlLabel
                sx={ { p: 2 } }
                control={
                    <Switch
                        disabled={ !filteredOrganizations || filteredOrganizations?.length === 0 || !!organization?.uuid }
                        checked={ isBusinessGroup }
                        color='primary'
                        edge='start'
                        name='isVerified'
                        onChange={ handleSwitchBusinessGroup }
                        value={ isBusinessGroup }
                    />
                }
                label={ t('groups.summary.addGroup.isBusinessGroup') as string } />
            <FormControl fullWidth disabled={!isBusinessGroup || !!organization?.uuid}>
                <InputLabel id='idOrganizationSelect'>
                    { t('select.company') }
                </InputLabel>
                <Box sx={ { display: 'flex', flexDirection: 'row' } }>
                    <Select
                        fullWidth
                        id='selectCompanyId'
                        disabled={ !isBusinessGroup || !!organization?.uuid }
                        value={ organizationUuid }
                        onChange={ handleOrganizationChange }
                        autoWidth
                        label={ t('select.company') }>
                        { filteredOrganizations?.map((org: OrganizationOut) => {
                            return (
                                <MenuItem key={ org.uuid } value={ org.uuid }>
                                    { `${ org.name } - ${ org.oib }` }
                                </MenuItem>
                            );
                        }) }
                    </Select>
                    <Tooltip title={ t('select.company.help.tooltipText') as string }>
                        <HelpRounded
                            sx={ {
                                cursor: 'pointer',
                                ml: 1,
                                my: 'auto',
                                opacity: '0.7'
                            } }
                            fontSize='medium'
                        />
                    </Tooltip>
                </Box>
            </FormControl>
        </>
    );
};

export default OrganizationSelect;
