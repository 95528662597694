import type { FC } from 'react';
import { useState } from 'react';
import { AnswerOption, AnswerRequest, OptionAnswerRequest, Question, QuestionTypeEnum } from 'survey-service-api';
import { Box, Button } from '@mui/material';
import CheckboxesQuestionBox from './CheckboxesQuestionBox';
import InputQuestion from './InputQuestion';
import RadioButtonQuestion from './RadioButtonQuestion';

interface IQuestionBoxProps {
    question: Question;
    nextButtonLabel: string;
    handleNext: (answer: AnswerRequest | undefined) => void;
    prefilledOptions?: AnswerOption[];
}

const QuestionBox: FC<IQuestionBoxProps> = (props) => {
    const { question, nextButtonLabel, handleNext, prefilledOptions } = props;

    const [answer, setAnswer] = useState<AnswerRequest>();

    const handleAnswerOptionsChange = (options: OptionAnswerRequest[]) => {
        setAnswer({ questionUuid: question.uuid, options: options });
    };

    return (
        <Box>
            {question.type === QuestionTypeEnum.Input && <InputQuestion options={question.options} prefilledOptions={prefilledOptions} answerOptions={handleAnswerOptionsChange} />}
            {question.type === QuestionTypeEnum.Checkbox && <CheckboxesQuestionBox options={question.options} prefilledOptions={prefilledOptions} answerOptions={handleAnswerOptionsChange} />}
            {question.type === QuestionTypeEnum.Radio && <RadioButtonQuestion options={question.options} prefilledOption={prefilledOptions ? prefilledOptions[0] : undefined} answerOptions={handleAnswerOptionsChange} />}
            <Box sx={{ my: 2 }}>
                <Button variant="contained" onClick={() => handleNext(answer)} sx={{ mt: 1, mr: 1 }}>
                    {nextButtonLabel}
                </Button>
            </Box>
        </Box>
    );
};

export default QuestionBox;
