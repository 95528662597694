import axios from 'axios';
import { handleError } from '../slices/errors-handling';

export const downloadPdf = (url: string) => {
    const filename = url.split('/').pop();
    axios
        .get(url, {
            responseType: 'blob'
        })
        .then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', filename ?? 'file.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch((err) => {
            handleError(err);
        });
};

export const downloadPdfContent = (content: string, fileName: string) => {
    const link = document.createElement('a');
    link.download = fileName;
    link.href = 'data:application/pdf;base64,' + content;

    document.body.appendChild(link);
    link.click();
    link.remove();
};
