import type { FC } from 'react';
import { useEffect } from 'react';
import Page from '../../components/shared/Page';
import { Box, Card, CardActions, CardContent, Divider, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { generatePdf, getSharedSurveyResult } from '../../slices/survey';
import { Answer, Question, Section } from 'survey-service-api';
import SkeletonBlock from '../../components/skeletons/SkeletonBlock';
import AnswerBox from '../../components/survey/AnswerBox';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import {GroupRoutes, PrivateRoutes} from '../../routes';

const SurveyUserPage: FC = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { surveyUserUuid, groupUuid } = params;

    const { submittedSurvey, isLoading } = useAppSelector((state) => state.survey);

    // const [showPdfViewer, setShowPdfViewer] = useState<boolean>(false);

    useEffect(() => {
        if (surveyUserUuid) {
            dispatch(getSharedSurveyResult(surveyUserUuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const matchAnswer = (question: Question): Answer | undefined => {
        if (submittedSurvey) {
            return submittedSurvey.answers.find((answer: Answer) => answer.question.uuid === question.uuid);
        }

        return undefined;
    };

    const handleGeneratePdf = () => {
        if (surveyUserUuid) {
            dispatch(generatePdf(surveyUserUuid));
            handleRedirectToPdf();
        }
    };

    const handleRedirectToPdf = () => {
        if (surveyUserUuid) {
            navigate(PrivateRoutes.private_survey_user_results_pdf_route.path.replace(':surveyUserUuid', surveyUserUuid ?? ''));
        }
    };

    const handleRedirectToDocumentPage = () => {


        if (submittedSurvey?.documentUuid) {
            if (groupUuid != null) {
                navigate(GroupRoutes.group_document_details_route.path
                    .replace(':documentUuid', submittedSurvey.documentUuid)
                    .replace(':groupUuid', groupUuid));

            } else {
                navigate(PrivateRoutes.private_document_details_route_sentReceived.path
                    .replace(':documentUuid', submittedSurvey.documentUuid)
                    .replace(':sentOrReceived', 'received'));
            }
        }
    };

    return (
        <Page pageTitle={t('survey.SurveyUserPage.title')}>
            {isLoading ? (
                <SkeletonBlock />
            ) : (
                <Box>
                    <Typography variant="h4" mb={2}>
                        {submittedSurvey?.survey?.name}
                    </Typography>
                    <Typography variant="body1" component="div">
                        <Box sx={{ fontStyle: 'italic' }}>{submittedSurvey?.survey?.description}</Box>
                    </Typography>

                    <Card>
                        <CardContent>
                            {submittedSurvey &&
                                submittedSurvey.survey?.sections
                                    .slice()
                                    .sort((a: Section, b: Section) => a.ordinal - b.ordinal)
                                    .map((p: Section) => (
                                        <Box key={p.uuid}>
                                            <Box sx={{ m: 2 }}>
                                                <Typography variant="h6">{p.title}</Typography>
                                                <Typography variant="body2" component="div">
                                                    <Box sx={{ fontStyle: 'italic' }}>{p.description}</Box>
                                                </Typography>
                                            </Box>
                                            <Box>
                                                {p.questions
                                                    .slice()
                                                    .sort((a, b) => a.ordinal - b.ordinal)
                                                    .map((q: Question) => (
                                                        <Box key={q.uuid} ml={2} mt={1}>
                                                            <Typography variant="caption" component="div">
                                                                <Box sx={{ fontStyle: 'italic', mt: 1 }}>{q.predecessorElement?.text}</Box>
                                                            </Typography>
                                                            <Typography variant="body1" pb={1} mt={1}>
                                                                {q.ordinal}. {q.questionText}
                                                            </Typography>
                                                            <AnswerBox answer={matchAnswer(q)} />
                                                            <Typography variant="caption" component="div">
                                                                <Box sx={{ fontStyle: 'italic', mt: 1 }}>{q.successorElement?.text}</Box>
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                            </Box>
                                            <Divider />
                                        </Box>
                                    ))}
                        </CardContent>
                        <CardActions>
                            {submittedSurvey?.documentUuid ? (
                                <CommonButton btnType={ButtonTypeEnum.GOTO} onClick={handleRedirectToDocumentPage}>
                                    {t('survey.SurveyUserPage.cardAction.button.showDocument')}
                                </CommonButton>
                            ) : (
                                <CommonButton btnType={ButtonTypeEnum.DOWNLOAD} onClick={() => handleGeneratePdf()}>
                                    {t('survey.SurveyUserPage.cardAction.button.generatePdf')}
                                </CommonButton>
                            )}
                        </CardActions>
                    </Card>
                </Box>
            )}

            {/*<Dialog open={isLoadingPdf} onClose={() => handleRedirectToPdf()}>*/}
            {/*    <DialogContent>*/}
            {/*        <Typography>Generating PDF. After PDF is generated you will be redirected to new page</Typography>*/}
            {/*        <CircularProgress />*/}
            {/*    </DialogContent>*/}
            {/*</Dialog>*/}
        </Page>
    );
};

export default SurveyUserPage;
