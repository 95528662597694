import type { FC } from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import Page from '../../components/shared/Page';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes } from '../../routes';
import { Box, Card, CardContent, CardHeader, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { InfoRounded } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { createOrganization, resetFlags } from '../../slices/organizations';
import SuccessInfo from '../../components/SuccessInfo';
import auth from '../../auth/auth';

const NewOrganization: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const [ organizationOib, setOrganizationOib ] = useState<string>('');
    const [ organizationName, setOrganizationName ] = useState<string>('');
    const [ isOibValid, setIsOibValid ] = useState<boolean>(true);
    const [ isValidName, setIsValidName ] = useState<boolean>(true);

    const { createdOrganization, isLoading, sentSuccessful } = useAppSelector(state => state.organizations);

    useEffect(() => {
        return () => {
            // Clean up the sentSuccessful flag
            sentSuccessful && dispatch(resetFlags());
        };
    });

    useEffect(() => {
        if (sentSuccessful && createdOrganization?.uuid) {
            auth.setOrganizationUuid(createdOrganization?.uuid);
            navigate(
                PrivateRoutes.private_organizations_details_route.path
                    .replace(':organizationUuid', createdOrganization?.uuid)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sentSuccessful ]);

    const handleBackToBusinessProfileList = () => {
        dispatch(resetFlags());
        navigate(PrivateRoutes.private_organizations_list_route.path);
    };

    const handleOrganizationOibChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const onlyNumbers = event.target.value.replace(/[^0-9]/g, '');
        setOrganizationOib(onlyNumbers);
    };

    const validateOib = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const onlyNumbers = event.target.value.replace(/[^0-9]/g, '');
        setIsOibValid(onlyNumbers.length > 9);
    };

    const handleSendRequest = () => {
        dispatch(createOrganization(organizationOib, organizationName));
    };

    return (
        <>
            <Page
                pageTitle={ t('businessProfile.page.subtitle') }
                pageDescription={ t('businessProfile.page.title') }
                actionButton={ !sentSuccessful ?
                    <CommonButton btnType={ ButtonTypeEnum.BACK } onClick={ handleBackToBusinessProfileList }>
                        { t('businessProfile.page.backToList') }
                    </CommonButton> : <></>
                }>
                { !sentSuccessful &&
                  <Card>
                    <CardHeader title={ t('businessProfile.form.title') } />
                    <CardContent>
                      <Grid container direction="row" spacing={ 4 }>
                          {/*grid kontejner za formu*/ }
                        <Grid item
                              container
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              spacing={ 2 }
                              xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
                          <Grid item sx={ { width: '100%' } }>
                            <TextField label={ t('businessProfile.form.field.oib') }
                                       placeholder={ t('businessProfile.form.field.oib.placeholder') }
                                       autoFocus
                                       fullWidth
                                       variant="outlined"
                                       error={ !isOibValid }
                                       onBlur={ (e) => validateOib(e) }
                                       onFocus={ () => setIsOibValid(true) }
                                       inputProps={ { maxLength: 11 } }
                                       value={ organizationOib }
                                       onChange={ (e) => handleOrganizationOibChange(e) }
                                       InputProps={ {
                                           endAdornment: (
                                               <InputAdornment position="end">
                                                   { organizationOib.length } / { 11 }
                                               </InputAdornment>
                                           )
                                       } } />
                          </Grid>
                          <Grid item sx={ { width: '100%' } }>
                            <TextField label={ t('businessProfile.form.field.name') }
                                       placeholder={ t('businessProfile.form.field.name.placeholder') }
                                       fullWidth
                                       variant="outlined"
                                       value={ organizationName }
                                       onChange={ (e) => setOrganizationName(e.target.value) }
                                       error={ !isValidName }
                                       onBlur={ (e) => setIsValidName(e.target.value.length > 0) }
                                       onFocus={ () => setIsValidName(true) } />
                          </Grid>
                          <Grid item>
                            <CommonButton btnType={ ButtonTypeEnum.SEND }
                                          loading={ isLoading }
                                          onClick={ handleSendRequest }
                                          disabled={ !organizationOib || !organizationName || organizationOib.length < 10 || isLoading }>
                                { t('businessProfile.form.button.send') }
                            </CommonButton>
                          </Grid>
                        </Grid>

                          {/*grid kontejner za info box*/ }
                        <Grid item xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
                          <Box sx={ {
                              backgroundColor: theme.palette.background.paper,
                              mb: 5,
                              textAlign: 'center',
                              whiteSpace: 'pre-line'
                          } }>
                            <Box sx={ { pb: 2 } }>
                              <InfoRounded fontSize="large" />
                            </Box>
                            <Typography variant="body2" align="justify">
                                { t('businessProfile.form.infoBox.body') }
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                }
                { sentSuccessful &&
                  <SuccessInfo buttonTitle={ t('businessProfile.form.message.success.buttonTitle') as string }
                               title={ t('businessProfile.form.message.success.title') as string }
                               onClick={ handleBackToBusinessProfileList } />
                }
            </Page>
        </>
    );
};

export default NewOrganization;
