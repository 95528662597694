import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import apis from '../auth/apis';
import { handleError } from './errors-handling';
import { DocumentsHelper } from '../utils/documents-helper';
import {
    DocumentIn,
    DocumentSimpleOut,
    MemberIn,
    MemberOut,
    MemberOutRoleEnum,
    MemberPatch,
    MemberPatchRoleEnum,
    Membership,
    MembershipPatch,
    MembershipPatchStatusEnum,
    OrganizationIn,
    OrganizationOut,
    OrganizationPatch
} from 'organizations-service-api';
import { downloadPdfContent } from '../utils/download';

interface IOrganizationsState {
    allOrganizations: OrganizationOut[] | undefined;
    createdOrganization: OrganizationOut | undefined;
    isAddingMember: boolean;
    isApprovingRequest: boolean;
    isCancelingRequest: boolean;
    isChangingRoleLoading: boolean;
    isConfirmingMembershipRequest: boolean;
    isConfirmedMembershipSuccessful: boolean;
    isDeletingFile: boolean;
    isDeletingMembershipRequest: boolean;
    isLoading: boolean;
    isLoadingDetails: boolean;
    isLoadingInvites: boolean;
    isLoadingMembers: boolean;
    isLoadingPayables: boolean;
    isMembersSuccessfulAdded: boolean;
    isModifyingOrganization: boolean;
    isLoadingFiles: boolean;
    isUploadingFile: boolean;
    isUploadingFilesSuccessful: boolean;
    memberships: Membership[] | undefined;
    organizationMembers: MemberOut[] | undefined;
    selectedOrganization: OrganizationOut | undefined;
    sentSuccessful: boolean;
    userDocuments: DocumentSimpleOut[] | undefined;
}

const initialState: IOrganizationsState = {
    allOrganizations: undefined,
    createdOrganization: undefined,
    isAddingMember: false,
    isApprovingRequest: false,
    isCancelingRequest: false,
    isChangingRoleLoading: false,
    isConfirmedMembershipSuccessful: false,
    isConfirmingMembershipRequest: false,
    isDeletingFile: false,
    isDeletingMembershipRequest: false,
    isLoading: false,
    isLoadingDetails: false,
    isLoadingFiles: false,
    isLoadingInvites: false,
    isLoadingMembers: false,
    isLoadingPayables: false,
    isMembersSuccessfulAdded: false,
    isModifyingOrganization: false,
    isUploadingFile: false,
    isUploadingFilesSuccessful: false,
    memberships: undefined,
    organizationMembers: undefined,
    selectedOrganization: undefined,
    sentSuccessful: false,
    userDocuments: undefined
};

const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
        changeMemberRole(state: IOrganizationsState, action: PayloadAction<MemberOut>): void {
            state.organizationMembers?.splice(
                state.organizationMembers?.findIndex(
                    (member: MemberOut) => member.uuid === action.payload.uuid
                ),
                1,
                action.payload
            );
        },
        changeMembership(state: IOrganizationsState, action: PayloadAction<Membership>): void {
            const membership = action.payload;
            state.memberships?.splice(
                state.memberships?.findIndex(
                    (m: Membership) => m.uuid === membership.uuid
                ),
                1,
                membership
            );
        },
        clearMemberSuccessful(state: IOrganizationsState): void {
            state.isMembersSuccessfulAdded = false;
        },
        createOrganization(state: IOrganizationsState, action: PayloadAction<OrganizationOut>): void {
            const organization = action.payload;
            state.createdOrganization = organization;
            state.sentSuccessful = true;
            state.isLoading = false;
            if (state.allOrganizations && state.allOrganizations.length > 0) {
                state.allOrganizations = [ ...state.allOrganizations, organization ];
            }
        },
        deleteMemberFromOrganization(state: IOrganizationsState, action: PayloadAction<string>): void {
            state.organizationMembers?.splice(
                state.organizationMembers?.findIndex(
                    (m: MemberOut) => m.uuid === action.payload
                ),
                1
            );
        },
        deleteMembership(state: IOrganizationsState, action: PayloadAction<string>): void {
            state.memberships?.splice(
                state.memberships?.findIndex(
                    (m: Membership) => m.uuid === action.payload
                ),
                1
            );
        },
        deleteOrganization(state: IOrganizationsState, action: PayloadAction<string>): void {
            state.allOrganizations?.splice(
                state.allOrganizations?.findIndex((c) => c.uuid === action.payload),
                1
            );
        },
        deleteUserDocument(state: IOrganizationsState, action: PayloadAction<string>): void {
            state.userDocuments?.splice(
                state.userDocuments?.findIndex(
                    (doc: DocumentSimpleOut) => doc.uuid === action.payload
                ),
                1
            );
        },
        getMemberships(state: IOrganizationsState, action: PayloadAction<Membership[]>): void {
            state.memberships = action.payload;
        },
        getOrganizationMembers(state: IOrganizationsState, action: PayloadAction<MemberOut[]>): void {
            state.organizationMembers = action.payload;
        },
        getOrganizations(state: IOrganizationsState, action: PayloadAction<OrganizationOut[]>): void {
            state.allOrganizations = action.payload;
        },
        resetActiveOrganization(state: IOrganizationsState): void {
            state.selectedOrganization = undefined;
            state.isLoading = false;
            state.organizationMembers = undefined;
            state.userDocuments = undefined;
        },
        resetFlags(state: IOrganizationsState): void {
            state.createdOrganization = undefined;
            state.sentSuccessful = false;
            state.isUploadingFilesSuccessful = false;
        },
        setAvatarToSelectedOrganization(state: IOrganizationsState, action: PayloadAction<string | undefined>): void {
            if (state.selectedOrganization && action.payload) {
                state.selectedOrganization.avatar = action.payload;
            }
        },
        setIsAddingMember(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isAddingMember = action.payload;
        },
        setIsApproving(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isApprovingRequest = action.payload;
        },
        setIsChangingRoleLoading(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isChangingRoleLoading = action.payload;
        },
        setIsConfirmedMembershipSuccessful(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isConfirmedMembershipSuccessful = action.payload;
        },
        setIsConfirmingMembershipRequest(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isConfirmingMembershipRequest = action.payload;
        },
        setIsDeletingFile(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isDeletingFile = action.payload;
        },
        setIsDeletingMembershipRequest(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isDeletingMembershipRequest = action.payload;
        },
        setIsDeletingOrganization(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isCancelingRequest = action.payload;
        },
        setIsLoading(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isLoading = action.payload;
        },
        setIsLoadingDetails(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isLoadingDetails = action.payload;
        },
        setIsLoadingFiles(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isLoadingFiles = action.payload;
        },
        setIsLoadingInvites(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isLoadingInvites = action.payload;
        },
        setIsLoadingMembers(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isLoadingMembers = action.payload;
        },
        setIsLoadingPayables(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isLoadingPayables = action.payload;
        },
        setIsModifyingOrganization(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isModifyingOrganization = action.payload;
        },
        setIsUploadingFile(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isUploadingFile = action.payload;
        },
        setIsUploadingFilesSuccessful(state: IOrganizationsState, action: PayloadAction<boolean>): void {
            state.isUploadingFilesSuccessful = action.payload;
        },
        setOrganizationLogoToSelectedOrganization(state: IOrganizationsState, action: PayloadAction<string | undefined>): void {
            if (state.selectedOrganization && action.payload) {
                state.selectedOrganization.logo = action.payload;
            }
        },
        setSelectedOrganization(state: IOrganizationsState, action: PayloadAction<OrganizationOut | undefined>): void {
            state.selectedOrganization = action.payload;
            state.isLoadingDetails = false;
        },
        setUserDocuments(state: IOrganizationsState, action: PayloadAction<DocumentSimpleOut[]>): void {
            state.userDocuments = action.payload;
        },
        updateOrganizationMembers(state: IOrganizationsState, action: PayloadAction<MemberOut[]>): void {
            state.organizationMembers?.push(...action.payload);
            state.isMembersSuccessfulAdded = true;
        },
        uploadUserDocument(state: IOrganizationsState, action: PayloadAction<DocumentSimpleOut>): void {
            state.userDocuments?.push(action.payload);
        }
    }
});

export const createOrganization = (organizationOib: string, organizationName: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsLoading(true));
        apis.organizationsApi().createOrganization({
            name: organizationName,
            oib: organizationOib
        } as OrganizationIn)
            .then((response) => {
                dispatch(organizationsSlice.actions.createOrganization(response.data as OrganizationOut));
            })
            .catch((err) => {
                dispatch(organizationsSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const getAllOrganizations = (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsLoading(true));
        apis.organizationsApi()
            .getOrganizations()
            .then(({ data }) => {
                dispatch(organizationsSlice.actions.getOrganizations(data));
                dispatch(organizationsSlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(organizationsSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const deleteOrganization = (organizationUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsDeletingOrganization(true));
        apis.organizationsApi()
            .deleteOrganization(organizationUuid)
            .then(() => {
                dispatch(organizationsSlice.actions.deleteOrganization(organizationUuid));
                dispatch(organizationsSlice.actions.setIsDeletingOrganization(false));
            })
            .catch((err) => {
                dispatch(organizationsSlice.actions.setIsDeletingOrganization(false));
                dispatch(handleError(err));
            });
    };

export const getOrganizationDetails = (organizationUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsLoadingDetails(true));
        apis.organizationsApi()
            .getOrganizationDetails(organizationUuid)
            .then(({ data }) => {
                dispatch(organizationsSlice.actions.setSelectedOrganization(data));
            })
            .catch((err) => {
                dispatch(organizationsSlice.actions.setIsLoadingDetails(false));
                dispatch(handleError(err));
            });
    };

export const changeOrganization = (organizationUuid: string, organizationName?: string, avatarImage?: string, organizationLogo?: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsModifyingOrganization(true));
        apis.organizationsApi()
            .changeOrganization(
                organizationUuid,
                {
                    avatar: avatarImage,
                    name: organizationName,
                    logo: organizationLogo
                } as OrganizationPatch
            )
            .then(() => {
                if (avatarImage !== undefined) {
                    dispatch(organizationsSlice.actions.setAvatarToSelectedOrganization(avatarImage));
                }
                if (organizationLogo !== undefined) {
                    dispatch(organizationsSlice.actions.setOrganizationLogoToSelectedOrganization(organizationLogo));
                }
                dispatch(organizationsSlice.actions.setIsModifyingOrganization(false));
            })
            .catch((err) => {
                dispatch(organizationsSlice.actions.setIsModifyingOrganization(false));
                dispatch(handleError(err));
            });
    };

export const addMembersToOrganization = (organizationUuid: string, members: MemberIn[]): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsAddingMember(true));
        apis.organizationsApi()
            .addMembers(
                organizationUuid,
                members
            )
            .then(({ data }) => {
                dispatch(organizationsSlice.actions.updateOrganizationMembers(data));
                dispatch(organizationsSlice.actions.setIsAddingMember(false));
            }).catch((err) => {
            dispatch(organizationsSlice.actions.setIsAddingMember(false));
            dispatch(handleError(err));
        });
    };

export const getOrganizationMembers = (organizationUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsLoadingMembers(true));
        apis.organizationsApi()
            .getOrganizationMembers(organizationUuid)
            .then(({ data }) => {
                dispatch(organizationsSlice.actions.getOrganizationMembers(data));
                dispatch(organizationsSlice.actions.setIsLoadingMembers(false));
            }).catch((err) => {
            dispatch(organizationsSlice.actions.setIsLoadingMembers(false));
            dispatch(handleError(err));
        });
    };

export const getOrganizationInvites = (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsLoadingInvites(true));
        apis.organizationMembershipsApi()
            .getMembershipsForUser()
            .then(({ data }) => {
                dispatch(organizationsSlice.actions.getMemberships(data));
                dispatch(organizationsSlice.actions.setIsLoadingInvites(false));
            })
            .catch((err) => {
                dispatch(organizationsSlice.actions.setIsLoadingInvites(false));
                dispatch(handleError(err));
            });
    };

export const deleteMemberFromOrganization = (organizationUuid: string, memberUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsDeletingMembershipRequest(true));
        apis.organizationsApi()
            .deleteMember(organizationUuid, memberUuid)
            .then(() => {
                dispatch(organizationsSlice.actions.deleteMemberFromOrganization(memberUuid));
                dispatch(organizationsSlice.actions.deleteMembership(memberUuid));
                dispatch(organizationsSlice.actions.setIsDeletingMembershipRequest(false));
            })
            .catch((err) => {
                dispatch(organizationsSlice.actions.setIsDeletingMembershipRequest(false));
                dispatch(handleError(err));
            });
    };

export const deleteMembershipFromOrganization = (membershipUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsDeletingMembershipRequest(true));
        apis.organizationMembershipsApi()
            .deleteMembership(membershipUuid)
            .then(() => {
                dispatch(organizationsSlice.actions.deleteMembership(membershipUuid));
                dispatch(organizationsSlice.actions.setIsDeletingMembershipRequest(false));
            })
            .catch((err) => {
                dispatch(organizationsSlice.actions.setIsDeletingMembershipRequest(false));
                dispatch(handleError(err));
            });
    };

export const changeMemberRole = (organizationUuid: string, memberUuid: string, role: MemberOutRoleEnum): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsChangingRoleLoading(true));
        const patchRole = role === MemberOutRoleEnum.Admin
            ? MemberPatchRoleEnum.Admin
            : role === MemberOutRoleEnum.Writer
                ? MemberPatchRoleEnum.Writer
                : MemberPatchRoleEnum.Reader;

        apis.organizationsApi()
            .changeMember(
                organizationUuid,
                memberUuid,
                {
                    role: patchRole
                } as MemberPatch
            )
            .then(({ data }) => {
                dispatch(organizationsSlice.actions.changeMemberRole(data));
                dispatch(organizationsSlice.actions.setIsChangingRoleLoading(false));
            })
            .catch((err) => {
                dispatch(organizationsSlice.actions.setIsChangingRoleLoading(false));
                dispatch(handleError(err));
            });
    };

export const confirmMembershipRequest = (organizationUuid: string, membershipUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsConfirmingMembershipRequest(true));
        apis.organizationMembershipsApi()
            .changeMembership(
                membershipUuid,
                {
                    status: MembershipPatchStatusEnum.Accepted
                } as MembershipPatch
            )
            .then(({ data }) => {
                dispatch(organizationsSlice.actions.changeMembership(data));
                dispatch(organizationsSlice.actions.setIsConfirmingMembershipRequest(false));
                dispatch(organizationsSlice.actions.setIsConfirmedMembershipSuccessful(true));
            })
            .catch((err) => {
                dispatch(organizationsSlice.actions.setIsConfirmingMembershipRequest(false));
                dispatch(handleError(err));
            });

    };

export const uploadUserDocuments = (memberUuid: string, files: File[], documentName: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsUploadingFile(true));
        const request: DocumentIn[] = await DocumentsHelper.getUploadDocumentRequest(files, documentName);

        apis.organizationMembershipsApi()
            .saveMemberDocument(memberUuid, request[0])
            .then(({ data }) => {
                dispatch(organizationsSlice.actions.uploadUserDocument(data));
                dispatch(organizationsSlice.actions.setIsUploadingFile(false));
                dispatch(organizationsSlice.actions.setIsUploadingFilesSuccessful(true));
            })
            .catch((err) => {
                dispatch(organizationsSlice.actions.setIsUploadingFile(false));
                dispatch(handleError(err));
            });
    };

export const getUserDocuments = (memberUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsLoadingFiles(true));
        apis.organizationMembershipsApi()
            .getMemberDocuments(memberUuid)
            .then(({ data }) => {
                dispatch(organizationsSlice.actions.setUserDocuments(data));
                dispatch(organizationsSlice.actions.setIsLoadingFiles(false));
            })
            .catch((err) => {
                dispatch(organizationsSlice.actions.setIsLoadingFiles(false));
                dispatch(handleError(err));
            });
    };

export const deleteUserDocuments = (memberUuid: string, documentUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(organizationsSlice.actions.setIsDeletingFile(true));
        apis.organizationMembershipsApi()
            .deleteMemberDocument(memberUuid, documentUuid)
            .then(() => {
                dispatch(organizationsSlice.actions.deleteUserDocument(documentUuid));
                dispatch(organizationsSlice.actions.setIsDeletingFile(false));
            })
            .catch((err) => {
                dispatch(organizationsSlice.actions.setIsDeletingFile(false));
                dispatch(handleError(err));
            });
    };

export const getMemberDocumentDetails = (documentUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        apis.organizationMembershipsApi()
            .getMemberDocumentDetails(documentUuid)
            .then(({ data }) => {
                data.content && downloadPdfContent(data.content, data.data.fileName);
            })
            .catch((err) => {
                dispatch(handleError(err));
            });
    };

export const { reducer } = organizationsSlice;

export const {
    clearMemberSuccessful,
    resetActiveOrganization,
    resetFlags,
    setIsConfirmedMembershipSuccessful,
    setIsLoading
} = organizationsSlice.actions;
