import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { handleError } from './errors-handling';
import { ApiGetDocumentsResponse, BasicUserData } from 'document-scanner-api';
import apis from '../auth/apis';
import { GetIdentificationDataIdentificationDataTypesEnum, IdentificationData } from 'profile-manager-api';

interface IProfileState {
    basicUserData: BasicUserData | undefined;
    isLoading: boolean;
    isLoadingBasicUserData: boolean;
    profileData: ApiGetDocumentsResponse | undefined;
    userIdentificationData: IdentificationData | undefined;
}

const initialState: IProfileState = {
    basicUserData: undefined,
    isLoading: false,
    isLoadingBasicUserData: false,
    profileData: undefined,
    userIdentificationData: undefined,

};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        clearProfileData(state: IProfileState): void {
            state.profileData = undefined;
        },
        getData(state: IProfileState, action: PayloadAction<ApiGetDocumentsResponse>): void {
            state.profileData = action.payload as ApiGetDocumentsResponse;
            state.isLoading = false;
        },
        getIdentificationData(state: IProfileState, action: PayloadAction<IdentificationData>): void {
            state.userIdentificationData = action.payload as IdentificationData;
            state.isLoading = false;
        },
        setBasicUserData(state: IProfileState, action: PayloadAction<BasicUserData>): void {
            state.basicUserData = action.payload;
            state.isLoadingBasicUserData = false;
        },
        setIsLoading(state: IProfileState, action: PayloadAction<boolean>): void {
            state.isLoading = action.payload;
        },
        setLoadingBasicUserData(state: IProfileState, action: PayloadAction<boolean>): void {
            state.isLoadingBasicUserData = action.payload;
        },
    },
});

export const getProfileData = (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(profileSlice.actions.setIsLoading(true));
        apis.documentScannerApi()
            .getAll()
            .then(({ data }) => {
                dispatch(profileSlice.actions.getData(data));
            })
            .catch((err) => {
                dispatch(profileSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const getBasicUserData = (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(profileSlice.actions.setLoadingBasicUserData(true));
        apis.documentScannerApi()
            .getBasicData()
            .then(({ data }) => {
                dispatch(profileSlice.actions.setBasicUserData(data));
            })
            .catch((err) => {
                dispatch(profileSlice.actions.setLoadingBasicUserData(false));
                dispatch(handleError(err));
            });
    };

export const getUserIdentifier = (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(profileSlice.actions.setIsLoading(true));
        apis.profileManagerApi()
            .getIdentificationData([
                GetIdentificationDataIdentificationDataTypesEnum.FullName,
                GetIdentificationDataIdentificationDataTypesEnum.Oib,
                GetIdentificationDataIdentificationDataTypesEnum.Emails,
                GetIdentificationDataIdentificationDataTypesEnum.Phones
            ])
            .then(({ data }) => {
                dispatch(profileSlice.actions.getIdentificationData(data));
            })
            .catch((err) => {
                dispatch(profileSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const {reducer} = profileSlice;

export const {clearProfileData} = profileSlice.actions;

export default profileSlice;
