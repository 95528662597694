import type { FC } from 'react';
import { Box } from '@mui/material';

interface ITabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel: FC<ITabPanelProps> = (props) => {
    const { children, value, index } = props;

    return (
        <Box role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </Box>
    );
};

export default TabPanel;