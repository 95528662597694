import { FC, useState } from 'react';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';

export interface IFilters {
    startDate?: Date | null;
    endDate?: Date | null;
}

interface InvoiceListFiltersProps {
    disabled: boolean;
    filters?: IFilters;
    onChange?: (filters: IFilters) => void;
}

export const PayableFilters: FC<InvoiceListFiltersProps> = (props) => {
    const { disabled, filters = {}, onChange } = props;
    const { t } = useTranslation();
    const [ newFilters, setNewFilters ] = useState<IFilters>(filters);
    const isSearchDisabled = filters.startDate === newFilters.startDate && filters.endDate === newFilters.endDate;

    const handleStartDateChange = (date: Date | null): void => {
        const newFilters: IFilters = {
            ...filters,
            startDate: date
        };

        // Prevent end date to be before start date
        if (newFilters.endDate && date && date > newFilters.endDate) {
            newFilters.endDate = date;
        }
        setNewFilters(newFilters);
    };

    const handleEndDateChange = (date: Date | null): void => {
        const newFilters: IFilters = {
            ...filters,
            endDate: date
        };

        // Prevent start date to be after end date
        if (newFilters.startDate && date && date < newFilters.startDate) {
            newFilters.startDate = date;
        }
        setNewFilters(newFilters);
    };

    const handleSearch = () => {
        onChange?.(newFilters);
    };

    return (
        <Box sx={ {
            pb: 3,
            pt: {
                xs: 3,
                lg: 3
            },
            px: 3
        } }>
            <Typography color="textSecondary" sx={ { mt: 3 } } variant="subtitle2">
                { t('businessProfile.details.stats.filter.usageDate') }
            </Typography>

            <Stack spacing={ 2 } sx={ { mt: 2 } }>
                <LocalizationProvider dateAdapter={ DateFnsUtils }>
                    <DatePicker disabled={ disabled }
                                inputFormat="dd.MM.yyyy"
                                label="From"
                                onChange={ handleStartDateChange }
                                renderInput={ (inputProps) => <TextField { ...inputProps } /> }
                                value={ newFilters.startDate } />

                    <DatePicker disabled={ disabled }
                                inputFormat="dd.MM.yyyy"
                                label="To"
                                onChange={ handleEndDateChange }
                                renderInput={ (inputProps) => <TextField { ...inputProps } /> }
                                value={ newFilters.endDate } />
                </LocalizationProvider>

                <CommonButton btnType={ ButtonTypeEnum.SEARCH }
                              onClick={ handleSearch }
                              disabled={ isSearchDisabled }
                              sx={ { mt: 2 } }>
                    { t('businessProfile.details.stats.filter.search') }
                </CommonButton>
            </Stack>
        </Box>
    );
};

export default PayableFilters;
