import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import {Box, Button, Dialog, InputAdornment, TextField, Typography} from '@mui/material';
import { hideTanModal } from '../slices/tan-modal';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import CommonButton, { ButtonTypeEnum } from './shared/CommonButton';
import { ErrorType } from '../model/common/error';
import { sendTan } from '../slices/fina-lcp';
import auth from '../auth/auth';
import { useNavigate } from 'react-router-dom';

interface TanInsertionModalProps {
    onConfirm: (tan: string) => void;
    onClose?: () => void;
}

const TanInsertionModal: FC<TanInsertionModalProps> = (props) => {
    const { onConfirm, onClose, ...other } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();


    const [userTan, setUserTan] = useState<string>('');

    const { error, isTanModalVisible, remainingAttempts } = useAppSelector(state => state.tanModal);
    const { isFinaSigning, tanCompareValue } = useAppSelector(state => state.finaLcp);

    const TAN_LENGTH = 6;
    const tanCompareValueText = tanCompareValue
        ? t('tan.compareValue') as string + ': ' + tanCompareValue
        : '';

    useEffect(() => {
        if (remainingAttempts == 0) {
            auth.clearLoginData();
            navigate('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remainingAttempts]);

    useEffect(() => {
        if (!isTanModalVisible) {
            setUserTan('');
        }
    }, [isTanModalVisible]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value as string;
        const onlyNumbersAndLetters = value.replace(/[^A-Za-z0-9]/g, '');
        setUserTan(onlyNumbersAndLetters.toUpperCase());
    };

    const handleConfirm = (): void => {
        onConfirm(userTan);
    };

    const handleSendTan = (): void => {
        dispatch(sendTan());
    };

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            userTan.length === TAN_LENGTH && onConfirm(userTan);
        }
    };

    const isButtonDisabled = () => {
        return TAN_LENGTH - userTan.length !== 0;
    };

    const closeTanModalHandler = () => {
        dispatch(hideTanModal());
        onClose && onClose();
    };

    return (
        <Dialog maxWidth={'sm'} onClose={closeTanModalHandler} open={isTanModalVisible} {...other}>
            <Box sx={{ p: 3 }}>
                <Typography align='center' color='textPrimary' gutterBottom variant='h5'>
                    {t('dashboard.sidebar.documents.pinModal.enterTan.title')}
                </Typography>
                <Typography align='center' color='textSecondary' variant='body1'>
                    {t('dashboard.sidebar.documents.enterTan.subtitle')}
                </Typography>
                <Typography align='center' color='error' variant='body1' fontWeight={500} sx={{ m: 2 }}>
                    {error?.errorMessage ? t(error?.errorMessage) : ''}
                </Typography>
                <Box sx={{ mt: 3, textAlign: 'center' }}>
                    <TextField
                        error={Boolean(error?.errorMessage)}
                        helperText={tanCompareValueText}
                        type={'text'}
                        autoFocus
                        inputProps={{ maxLength: 6 }}
                        fullWidth
                        label={'TAN'}
                        onChange={handleChange}
                        onKeyDown={keyDownHandler}
                        placeholder={t('dashboard.sidebar.documents.enterTan.textField.title')}
                        rows={1}
                        value={userTan}
                        variant='outlined'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    {userTan.length} / {TAN_LENGTH}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                {error?.errorType === ErrorType.AUTHORIZATION_ERROR &&
                    <Box sx={{ mt: 3, textAlign: 'center' }}>
                        <Button
                          onClick={handleSendTan}
                          disabled={isFinaSigning}>
                            {t('tan.send')}
                        </Button>
                    </Box>
                }
                <Box sx={{ mt: 3, p: 3 }}>
                    <CommonButton btnType={ButtonTypeEnum.CONFIRM} fullWidth={true} onClick={handleConfirm} disabled={isButtonDisabled()}>
                        {t('dashboard.sidebar.documents.pinModal.enterTan.button.title')}
                    </CommonButton>
                </Box>
            </Box>
        </Dialog>
    );
};

export default TanInsertionModal;
