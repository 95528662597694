import type { FC } from 'react';
import { Avatar, Box, Card, Grid, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Verified, VerifiedUser } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TransactionStat, TransactionStatistics, TransactionStatPayableEnum } from 'payment-service-api';
import { useAppSelector } from '../../store/hooks';
import SkeletonCard from '../skeletons/SkeletonCard';

interface IPayableItemsProps {
    transactionStats?: TransactionStatistics;
}

const PayableItems: FC<IPayableItemsProps> = ({ transactionStats }) => {
    const { t } = useTranslation();
    const { isLoading } = useAppSelector(state => state.payments);

    const finaCerts = transactionStats
        ?.transactionStat
        ?.find((ts: TransactionStat) => ts.payable === TransactionStatPayableEnum.FinaLcpCertificate)
        ?.amountUsed ?? 0;
    const freeSignatures = transactionStats
        ?.transactionStat
        ?.find((ts: TransactionStat) => ts.payable === TransactionStatPayableEnum.FreeSignature)
        ?.amountUsed ?? 0;
    const paidSignatures = transactionStats
        ?.transactionStat
        ?.find((ts: TransactionStat) => ts.payable === TransactionStatPayableEnum.PaidSignature)
        ?.amountUsed ?? 0;

    return (
        <Grid container spacing={ 2 } sx={ { mb: 2, pt: { xs: 3, lg: 8 } } } justifyContent="center" alignItems="center">

            {/* Fina certificates used for the period */ }
            <Grid item lg={ 5 } md={ 5 } sm={ 5 } xs={ 12 } sx={ { m: 1 } }>
                { isLoading ? <SkeletonCard height={90} /> : <Card sx={ { p: 3 } }>
                    <Box sx={ { alignItems: 'center', display: 'flex', mb: 1 } }>
                        <Avatar variant="rounded"
                                sx={ {
                                    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                                    color: 'primary.main',
                                    mr: 1
                                } }>
                            <Verified fontSize="small" />
                        </Avatar>
                        <Typography variant="h5">
                            { freeSignatures + paidSignatures }
                        </Typography>
                    </Box>
                    <Typography color="textSecondary"
                                variant="body2">
                        { t('businessProfile.details.stats.signatures') }
                    </Typography>
                </Card> }
            </Grid>

            {/* Signatures used for the period */ }
            <Grid item lg={ 5 } md={ 5 } sm={ 5 } xs={ 12 } sx={ { m: 1 } }>
                { isLoading ? <SkeletonCard height={90} /> : <Card sx={ { p: 3 } }>
                    <Box sx={ { alignItems: 'center', display: 'flex', mb: 1 } }>
                        <Avatar variant="rounded"
                                sx={ {
                                    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                                    color: 'primary.main',
                                    mr: 1
                                } }>
                            <VerifiedUser fontSize="small" />
                        </Avatar>
                        <Typography variant="h5">
                            { finaCerts }
                        </Typography>
                    </Box>
                    <Typography color="textSecondary" variant="body2">
                        { t('businessProfile.details.stats.finaCertNr') }
                    </Typography>
                </Card> }
            </Grid>
        </Grid>

    );
};

export default PayableItems;
