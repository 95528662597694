import { FC } from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserConsentStatusEnum } from 'consent-manager-api';
import PDFViewer from 'components/pdf-viewer/PdfViewer';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';

interface ProfileConsentsViewerProps {
    consentUrl: string;
    consentStatus: UserConsentStatusEnum;
    onClose?: () => void;
    isModalOpen: boolean;
    revokeConsentHandler: () => void;
}

const ProfileConsentsViewer: FC<ProfileConsentsViewerProps> = (props) => {
    const { consentUrl, consentStatus, onClose, isModalOpen, revokeConsentHandler } = props;
    const { t } = useTranslation();

    return (
        <Dialog maxWidth='lg' open={isModalOpen} onClose={onClose}>
            <DialogTitle>{t('dashboard.profile.consents.table.identyum.consent.content')}</DialogTitle>
            <DialogContent sx={{ justifyContent: 'center', overflowY: 'auto', overflowX: 'hidden' }}>
                <PDFViewer document={consentUrl} />
            </DialogContent>
            <DialogActions sx={{justifyContent: 'center', mt: 2}}>
                <CommonButton btnType={ButtonTypeEnum.CLOSE} onClick={onClose}>{t('dashboard.profile.consents.table.identyum.close')}</CommonButton>
                <Button variant='contained' color='error' sx={{mr: 2}} onClick={() => revokeConsentHandler()} disabled={consentStatus === UserConsentStatusEnum.Revoked}>
                    {t('dashboard.profile.consents.table.identyum.actions.revoke')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProfileConsentsViewer;
