import ClientOAuth2 from 'client-oauth2';

/**
 * @author Mladen Abaz
 * @description This file contains all the settings used application wide
 */
export class AppSettings {
    public static MAX_UPLOAD_SIZE: number = 20971520;

    /**
     * Custom cookies used in every http request to API
     */
    public static REFRESH_TOKEN_COOKIE: string = 'refresh_token';
    public static USER_UUID: string = 'user_uuid';

    public static USER_PIN: string = 'X-USER-PIN';

    /**
     * Custom keys for storing data in browsers localStorage
     */
    public static SETTINGS_LS_KEY = 'settings';
    public static IDY_LANGUAGE = 'IDY-LANGUAGE';

    /**
     * Get oAuth client config object, used in components for
     * fetching auth code and access token afterwards
     */
    public static get OAUTH_CLIENT(): ClientOAuth2 {
        return new ClientOAuth2({
            accessTokenUri: process.env.REACT_APP_ACCESS_TOKEN_URL,
            authorizationUri: process.env.REACT_APP_AUTHORIZATION_URL,
            clientId: process.env.REACT_APP_CLIENT_ID,
            clientSecret: process.env.REACT_APP_CLIENT_SECRET,
            redirectUri: `${process.env.REACT_APP_REDIRECT_URL}/redirect`,
        });
    }

    public static get LOCAL_DEV_OAUTH_CLIENT(): ClientOAuth2 {
        return new ClientOAuth2({
            accessTokenUri: process.env.REACT_APP_ACCESS_TOKEN_URL,
            authorizationUri: process.env.REACT_APP_AUTHORIZATION_URL,
            clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
            clientSecret: process.env.REACT_APP_CLIENT_SECRET,
            redirectUri: `${process.env.REACT_APP_REDIRECT_URL}/oauthRedirect`,
        });
    }

    public static get OAUTH_SCOPES(): string {
        return process.env.REACT_APP_OAUTH_SCOPES as string;
    }

    /**
     * Payments & Plans related constants
     */
    public static BUNDLE_UUID: string = 'BUNDLE_UUID';
}
