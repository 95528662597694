import type { FC } from 'react';
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { EmailResponse } from 'contacts-service-api';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { UserContactsHelper } from '../../utils/user-contacts-helper';
import { useTranslation } from 'react-i18next';

interface INewContactEmailListProps {
    emails: EmailResponse[],
    editEmailHandler: (email: EmailResponse) => void,
    deleteEmailHandler: (email: EmailResponse) => void
}

const UserContactEmailList: FC<INewContactEmailListProps> = (props) => {
    const { emails, editEmailHandler, deleteEmailHandler } = props;
    const { t } = useTranslation();
    const sortedEmails = UserContactsHelper.sortEmails(emails);

    if (!sortedEmails || sortedEmails.length === 0) {
        return <Typography variant='caption'>
            { t('userContacts.noEmails') }
        </Typography>;
    }

    return (
        <Table>
            <TableBody>
                { sortedEmails?.map((email: EmailResponse, index: number) => {
                    return (
                        <TableRow key={ index } hover>
                            <TableCell onClick={ () => editEmailHandler(email) } sx={{ borderBottom: 'unset' }}>
                                { email.email }
                            </TableCell>

                            <TableCell sx={ { borderBottom: 'unset', textAlign: 'right' } }>
                                <Edit sx={ { cursor: 'pointer', mr: 2, opacity: '0.7' } }
                                      fontSize='small'
                                      onClick={ () => editEmailHandler(email) } />
                                <DeleteOutline sx={ { cursor: 'pointer', opacity: '0.7' } }
                                               fontSize='small'
                                               color='error'
                                               onClick={ () => deleteEmailHandler(email) } />
                            </TableCell>
                        </TableRow>
                    );
                }) }
            </TableBody>
        </Table>
    );
};
export default UserContactEmailList;

