import { FC, useEffect, useState } from 'react';
import { Card, CardContent, Grid, Theme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DocumentUpload from '../../components/documents/DocumentUpload';
import { useTranslation } from 'react-i18next';
import ConfirmActionModal, { ConfirmActionModalActionType } from '../../components/ConfirmActionModal';
import {
    resetSendSuccessful,
    setUploadDocumentSuccessFlag,
    uploadDocument
} from '../../slices/documents';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GroupRoutes, PrivateRoutes } from '../../routes';
import Page from '../../components/shared/Page';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import SuccessInfo from '../../components/SuccessInfo';
import { IOrganizationDocument } from '../../model/common/documents/IOrganizationDocument';
import { DocumentSignatureTypeEnum } from 'user-document-signer-api';

const DocumentUploadPage: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        isUploadingDocuments,
        isSendingDocument,
        sendSuccessful,
        uploadedDocument,
        uploadDocumentSuccessful
    } = useAppSelector((state) => state.documents);
    const { activeGroup } = useAppSelector((state) => state.groups);

    const [ showConfirmModal, setShowConfirmModal ] = useState<boolean>(false);
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    useEffect(() => {
        return () => {
            dispatch(resetSendSuccessful());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (uploadDocumentSuccessful) {
            navigate(
                activeGroup?.group?.uuid ?
                    GroupRoutes
                        .group_document_details_route
                        .path
                        .replace(':groupUuid', activeGroup?.group?.uuid)
                        .replace(':documentUuid', uploadedDocument?.uuid ?? '') :
                    PrivateRoutes.private_document_details_route_sentReceived
                        .path
                        .replace(':documentUuid', uploadedDocument?.uuid ?? '')
                        .replace(':sentOrReceived', 'sent')
            );
            dispatch(setUploadDocumentSuccessFlag(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ uploadDocumentSuccessful ]);

    const handleUploadDocument = (files: File[], organizationUserDocuments: IOrganizationDocument[], signatureType: DocumentSignatureTypeEnum | undefined, downloadableBeforeSignature: boolean) => {
        dispatch(
            uploadDocument(
                files,
                activeGroup?.group.uuid === '' ? undefined : activeGroup?.group.uuid,
                organizationUserDocuments,
                signatureType,
                downloadableBeforeSignature
            )
        );
    };

    const backToDocumentsSummaryClickHandler = () => {
        dispatch(resetSendSuccessful());
        navigate(
            activeGroup
                ? GroupRoutes.groups_sent_documents_route.path.replace(':groupUuid', activeGroup?.group?.uuid)
                : PrivateRoutes.private_sent_documents_route.path
        );
    };

    const backToDashboardModalConfirmHandler = () => {
        dispatch(resetSendSuccessful());
        backToDashboardModalCloseHandler();
        navigate(
            activeGroup ?
                GroupRoutes.groups_sent_documents_route.path.replace(':groupUuid', activeGroup?.group?.uuid) :
                PrivateRoutes.private_sent_documents_route.path
        );
    };

    const backToDashboardModalCloseHandler = () => {
        setShowConfirmModal(false);
    };

    const handleResetSendSuccessful = () => {
        navigate(
            activeGroup ?
                GroupRoutes.groups_sent_documents_route.path.replace(':groupUuid', activeGroup?.group?.uuid) :
                PrivateRoutes.private_sent_documents_route.path
        );
        dispatch(resetSendSuccessful());
    };

    return (
        <Page
            pageTitle={ t('dashboard.sidebar.documents.newDocument.page.title') }
            actionButton={
                !sendSuccessful ? (
                    <CommonButton
                        disabled={ isUploadingDocuments || isSendingDocument }
                        btnType={ ButtonTypeEnum.BACK }
                        onClick={ backToDocumentsSummaryClickHandler }>
                        { t('dashboard.sidebar.documents.newDocument.button.backToDocumentSummary') }
                    </CommonButton>
                ) : (
                    <></>
                )
            }>
            <Card sx={ { mt: 3, pb: 3 } }>
                <CardContent>
                    <Grid container spacing={ 2 } direction='column' sx={ { mt: 5 } }>
                        <Grid item xs={ 12 } sm={ 12 } md={ 8 } lg={ 8 } sx={ { ml: smUp ? 5 : 0, mr: smUp ? 5 : 0 } }>
                            { !uploadDocumentSuccessful && !sendSuccessful && (
                                <DocumentUpload
                                    activeGroup={ activeGroup }
                                    isLoading={ isUploadingDocuments }
                                    onUploadDocument={ handleUploadDocument } />
                            ) }

                            { uploadDocumentSuccessful && sendSuccessful && (
                                <SuccessInfo
                                    buttonTitle={ t('dashboard.sidebar.documents.newDocument.button.backToDocumentSummary') as string }
                                    title={ t('dashboard.sidebar.documents.newDocument.successfullySent') as string }
                                    onClick={ handleResetSendSuccessful } />
                            ) }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <ConfirmActionModal
                actionType={ ConfirmActionModalActionType.UNSAVED_DATA }
                onClose={ backToDashboardModalCloseHandler }
                onConfirm={ backToDashboardModalConfirmHandler }
                open={ showConfirmModal } />
        </Page>
    );
};

export default DocumentUploadPage;
