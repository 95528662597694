import type { FC } from 'react';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Card,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import { applyPaginationOrganizationPersons } from '../../utils/organizations-helper';
import { AssignmentTurnedInRounded, DeleteForever } from '@mui/icons-material';
import Scrollbar from '../scrollbar/Scrollbar';
import ConfirmationDialog from '../shared/ConfirmationDialog';
import Label from '../Label';
import { getProvidedPayablesItems, provideFinaCertPayable, revokeFinaCertPayable } from '../../slices/payments';
import { PayableItemPayableEnum, ProvidedPayableItem, ProvidedPayableItemPayableEnum } from 'payment-service-api';
import { MemberOut, MemberOutRoleEnum, MemberOutStatusEnum, OrganizationOutStatusEnum } from 'organizations-service-api';
import { getOrganizationMembers } from '../../slices/organizations';
import SkeletonBlock from '../skeletons/SkeletonBlock';
import { fetchCertStatuses } from '../../slices/fina-lcp';
import { CertStatusResponse } from 'fina-lcp-certifier-api';
import { ProvidedPayableItemType } from '../../model/common/payments/ProvidedPayableItemType';

const OrganizationMembersCertificates: FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [ page, setPage ] = useState<number>(0);
    const [ limit, setLimit ] = useState<number>(10);
    const [ actionMemberUuid, setActionMemberUuid ] = useState<string>('');
    const [ providePayableModalOpen, setProvidePayableModalOpen ] = useState<boolean>(false);
    const [ revokePayableModalOpen, setRevokePayableModalOpen ] = useState<boolean>(false);
    const [ payableToRevokeUuid, setPayableToRevokeUuid ] = useState<string>('');

    const { isLoadingMembers, organizationMembers, selectedOrganization } = useAppSelector((state) => state.organizations);
    const { isLoadingPayables, providedPayables } = useAppSelector((state) => state.payments);
    const { isLoadingCertStatuses, certStatuses } = useAppSelector((state) => state.finaLcp);

    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const acceptedMembers = organizationMembers?.filter((m: MemberOut) => m.status === MemberOutStatusEnum.Accepted);
    const paginatedMembers = applyPaginationOrganizationPersons(acceptedMembers, page, limit);
    const isOrganizationAdmin = selectedOrganization?.currentUser?.role === MemberOutRoleEnum.Admin ||
        selectedOrganization?.currentUser?.role === MemberOutRoleEnum.Owner;

    useEffect(() => {
        if (!organizationMembers &&
            selectedOrganization?.uuid &&
            selectedOrganization?.status === OrganizationOutStatusEnum.Approved) {
            dispatch(getOrganizationMembers(selectedOrganization?.uuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (organizationMembers && organizationMembers.length > 0 && !certStatuses) {
            const userUuids: string[] = organizationMembers
                .map((m: MemberOut) => m.person.userUuid)
                .filter(u => u) as string[];
            dispatch(fetchCertStatuses(userUuids));
        }

        const organizationMembersUuids: string[] = acceptedMembers
            ?.map((m: MemberOut) => m.person.userUuid ? m.person.userUuid : '')
            ?.filter(uuid => uuid) ?? [];

        if (!providedPayables && selectedOrganization?.status === OrganizationOutStatusEnum.Approved && organizationMembersUuids?.length > 0) {
            dispatch(getProvidedPayablesItems(
                organizationMembersUuids,
                [ PayableItemPayableEnum.FinaLcpCertificate ],
                ProvidedPayableItemType.SENT
            ));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ organizationMembers ]);

    const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value, 10));
    };

    const isCertProvidedForUser = (memberUserUuid?: string) => {
        if (!memberUserUuid) {
            return false;
        }
        return (
            providedPayables?.find((p: ProvidedPayableItem) => {
                return (
                    p.sentToEntityUuid === memberUserUuid &&
                    p.sentFromEntityUuid === selectedOrganization?.uuid &&
                    p.amount &&
                    p.amount > 0 &&
                    p.payable === ProvidedPayableItemPayableEnum.FinaLcpCertificate
                );
            }) !== undefined
        );
    };

    const isCertUsedByUser = (memberUserUuid?: string) => {
        if (!memberUserUuid) {
            return false;
        }

        return (
            providedPayables?.find((p: ProvidedPayableItem) => {
                return (
                    p.sentToEntityUuid === memberUserUuid &&
                    p.sentFromEntityUuid === selectedOrganization?.uuid &&
                    p.initialAmount &&
                    p.initialAmount > 0 &&
                    p.amount !== p.initialAmount &&
                    p.payable === ProvidedPayableItemPayableEnum.FinaLcpCertificate
                );
            }) !== undefined
        );
    };

    const getOrganizationMemberContact = (member: MemberOut) => {
        const email = member.person.email ?? '-';
        const phone = member.person.phone && member.person.phone.dialCode && member.person.phone.number ? member.person.phone?.dialCode + member.person.phone?.number : '-';
        return (
            <>
                <Box>{ email }</Box>
                <Box>{ phone }</Box>
            </>
        );
    };

    const handleProvideCertPayable = (member: MemberOut) => {
        setActionMemberUuid(member.person.userUuid ?? '');
        setProvidePayableModalOpen(true);
    };

    const handleRevokeCertPayable = (member: MemberOut) => {
        const revokePayableUuid = providedPayables?.find((p: ProvidedPayableItem) => {
            return (
                p.sentToEntityUuid === member.person.userUuid &&
                p.sentFromEntityUuid === selectedOrganization?.uuid &&
                p.amount &&
                p.amount > 0 &&
                p.payable === ProvidedPayableItemPayableEnum.FinaLcpCertificate
            );
        });
        setActionMemberUuid(member.person.userUuid ?? '');
        setPayableToRevokeUuid(revokePayableUuid?.uuid ?? '');
        setRevokePayableModalOpen(true);
    };

    const handleCloseRevokePayableDialog = (isRevokingPayable: boolean) => {
        isRevokingPayable && dispatch(revokeFinaCertPayable(payableToRevokeUuid));
        setRevokePayableModalOpen(false);
    };

    const handleCloseProvidePayableDialog = (isProvidingPayable: boolean) => {
        isProvidingPayable && dispatch(provideFinaCertPayable(actionMemberUuid));
        setProvidePayableModalOpen(false);
    };

    const userHasValidCert = (memberUserUuid?: string): boolean => {
        return !memberUserUuid || ( certStatuses?.find((c: CertStatusResponse) => c.userUuid === memberUserUuid)?.hasValidFinaCert ?? false );
    };

    const hasProvidedUnusedPayable = (memberUserUuid?: string) => {
        return memberUserUuid && providedPayables?.find((p: ProvidedPayableItem) => {
            return p.payable === ProvidedPayableItemPayableEnum.FinaLcpCertificate
                && p.sentToEntityUuid === memberUserUuid
                && p.amount > 0;
        });
    };

    const getMemberActions = (member: MemberOut) => {
        if (isLoadingCertStatuses) {
            return <CircularProgress size='1rem' />;
        }

        if (isLoadingPayables && member.person.userUuid === actionMemberUuid) {
            return <CircularProgress size='1rem' />;
        }

        if (userHasValidCert(member.person.userUuid) && hasProvidedUnusedPayable(member.person.userUuid)) {
            return (
                <>
                    <></>
                    <Tooltip title={ t('businessProfile.details.groups.certificates.revokeCertPayable') as string }>
                        <IconButton onClick={ () => handleRevokeCertPayable(member) }>
                            <DeleteForever
                                sx={ {
                                    cursor: 'pointer',
                                    ml: 1,
                                    opacity: '0.7'
                                } }
                                fontSize='large'
                            />
                        </IconButton>
                    </Tooltip>
                </>
            );
        }

        if (isOrganizationAdmin && !userHasValidCert(member.person.userUuid)) {
            return (
                <>
                    <Tooltip title={ t('businessProfile.details.groups.certificates.provideCertPayable') as string }>
                        <IconButton
                            onClick={ () => handleProvideCertPayable(member) }
                            disabled={ isCertProvidedForUser(member.person.userUuid) }>
                            <AssignmentTurnedInRounded
                                sx={ {
                                    cursor: 'pointer',
                                    ml: 1,
                                    opacity: '0.7'
                                } }
                                fontSize='large'
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={ t('businessProfile.details.groups.certificates.revokeCertPayable') as string }>
                        <IconButton
                            onClick={ () => handleRevokeCertPayable(member) }
                            disabled={
                                !isCertProvidedForUser(member.person.userUuid) ||
                                isCertUsedByUser(member.person.userUuid)
                            }>
                            <DeleteForever
                                sx={ {
                                    cursor: 'pointer',
                                    ml: 1,
                                    opacity: '0.7'
                                } }
                                fontSize='large'
                            />
                        </IconButton>
                    </Tooltip>
                </>

            );
        }

        return null;
    };

    const getLabel = (member: MemberOut) => {
        const hasValidCert = userHasValidCert(member.person.userUuid);
        const isCertProvided = isCertProvidedForUser(member.person.userUuid);
        const isCertUsed = isCertUsedByUser(member.person.userUuid);
        return (
            <Label
                style={ { width: '100%' } }
                color={ hasValidCert || ( isCertProvided && isCertUsed )
                    ? 'success'
                    : isCertProvided && !isCertUsed
                        ? 'warning'
                        : 'info'
                }>
                { t(
                    hasValidCert || ( isCertProvided && isCertUsed )
                        ? 'businessProfile.details.groups.certificates.provideCertPayable.status.used'
                        : isCertProvided && !isCertUsed
                            ? 'businessProfile.details.groups.certificates.provideCertPayable.status.provided'
                            : 'businessProfile.details.groups.certificates.provideCertPayable.status.notProvided'
                ) }
            </Label>
        );
    };

    return selectedOrganization?.status === OrganizationOutStatusEnum.Approved ? (
        <>
            { isLoadingMembers ? <SkeletonBlock height={ 250 } /> :
                <Card>
                    <Scrollbar>
                        <Box sx={ { minWidth: smUp ? 700 : 150 } }>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={ { textAlign: 'center', width: '20%' } }>
                                            { t('businessProfile.details.members.list.column.status') }
                                        </TableCell>
                                        <TableCell>{ t('businessProfile.details.members.list.column.name') }</TableCell>
                                        <TableCell>{ t('businessProfile.details.members.list.column.contact') }</TableCell>
                                        { isOrganizationAdmin && (
                                            <TableCell sx={ { textAlign: 'center' } }>
                                                { t('businessProfile.details.members.list.column.actions') }
                                            </TableCell>
                                        ) }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { paginatedMembers?.map((member: MemberOut) => {
                                        return (
                                            <Fragment key={ member.uuid }>
                                                <TableRow>
                                                    <TableCell sx={ { textAlign: 'center' } }>
                                                        { member && getLabel(member) }
                                                    </TableCell>
                                                    <TableCell>
                                                        { member.person.fullName }
                                                    </TableCell>
                                                    <TableCell>
                                                        { getOrganizationMemberContact(member) }
                                                    </TableCell>
                                                    { isOrganizationAdmin && (
                                                        <TableCell
                                                            sx={ { textAlign: 'center', verticalAlign: 'middle' } }>
                                                            { getMemberActions(member) }
                                                        </TableCell>
                                                    ) }
                                                </TableRow>
                                            </Fragment>
                                        );
                                    }) }
                                </TableBody>
                            </Table>
                        </Box>
                    </Scrollbar>
                    <TablePagination
                        component='div'
                        count={ organizationMembers?.length ?? 0 }
                        labelRowsPerPage={ t('rowsPerPage') }
                        onPageChange={ handlePageChange }
                        onRowsPerPageChange={ handleLimitChange }
                        page={ page }
                        rowsPerPage={ limit }
                        rowsPerPageOptions={ [ 10, 20, 50 ] }
                    />
                </Card>
            }

            <ConfirmationDialog
                title={ t('businessProfile.details.groups.certificates.provideCertPayable.modal.title') }
                message={ t('businessProfile.details.groups.certificates.provideCertPayable.modal.message') }
                onConfirm={ () => handleCloseProvidePayableDialog(true) }
                onCancel={ () => handleCloseProvidePayableDialog(false) }
                open={ providePayableModalOpen }
            />

            <ConfirmationDialog
                title={ t('businessProfile.details.groups.certificates.revokeCertPayable.modal.title') }
                message={ t('businessProfile.details.groups.certificates.revokeCertPayable.modal.message') }
                onConfirm={ () => handleCloseRevokePayableDialog(true) }
                onCancel={ () => handleCloseRevokePayableDialog(false) }
                open={ revokePayableModalOpen }
            />
        </>
    ) : (
        <Box sx={ { p: 3 } }>
            <Typography color='textSecondary' variant='caption' sx={ { mb: 3 } }>
                { t('businessProfile.details.label.certificates.notVerified') }
            </Typography>
        </Box>
    );
};

export default OrganizationMembersCertificates;
