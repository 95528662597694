import {OptionRequestIdyWalletFieldEnum} from 'survey-service-api';

export interface IdyWalletField {
    displayName: string;
    field: OptionRequestIdyWalletFieldEnum;
}

export const THEMES = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
    NATURE: 'NATURE'
};

export enum Reason {
    OTP_INVALID = 'OTP_INVALID',
    OTP_TEMPORARY_BLOCKED = 'OTP_TEMPORARY_BLOCKED',
    OTP_EXPIRED = 'OTP_EXPIRED',
}

export const IdyWalletFields: IdyWalletField[] = [
    {
        displayName: 'idyWalletField.fullname',
        field: OptionRequestIdyWalletFieldEnum.FullName
    },

    {
        displayName: 'idyWalletField.personalNumber',
        field: OptionRequestIdyWalletFieldEnum.Oib
    },
    {
        displayName: 'idyWalletField.phone',
        field: OptionRequestIdyWalletFieldEnum.Phones
    },
    {
        displayName: 'idyWalletField.email',
        field: OptionRequestIdyWalletFieldEnum.Emails
    },
    {
        displayName: 'idyWalletField.address',
        field: OptionRequestIdyWalletFieldEnum.Address
    },
    {
        displayName: 'idyWalletField.citizenship',
        field: OptionRequestIdyWalletFieldEnum.Citizenship
    },
    {
        displayName: 'idyWalletField.dateOfBirth',
        field: OptionRequestIdyWalletFieldEnum.DateOfBirth
    },
    {
        displayName: 'idyWalletField.documentType',
        field: OptionRequestIdyWalletFieldEnum.DocumentType
    },
    {
        displayName: 'idyWalletField.documentNumber',
        field: OptionRequestIdyWalletFieldEnum.DocumentNumber
    },
    {
        displayName: 'idyWalletField.documentExpiryDate',
        field: OptionRequestIdyWalletFieldEnum.DocumentExpiryDate
    },
    {
        displayName: 'idyWalletField.documentIssueDate',
        field: OptionRequestIdyWalletFieldEnum.DocumentIssueDate
    },
    {
        displayName: 'idyWalletField.documentIssuedBy',
        field: OptionRequestIdyWalletFieldEnum.DocumentIssuedBy
    },
];