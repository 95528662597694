import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';

interface ICertificateDetailsProps {
    onBack: () => void;
    certificateDetails: string;
}

const CertificateDetails:FC<ICertificateDetailsProps> = (props) => {

    const { onBack, certificateDetails } = props;

    const { t } = useTranslation();

    return (
        <>
            <Box sx={ { p: 3 } }>
                <Typography variant='body2'>
                    { certificateDetails }
                </Typography>
            </Box>
            <Box sx={ { mt: 3, textAlign: 'center' } }>
                <CommonButton btnType={ ButtonTypeEnum.BACK } sx={ { ml: 1, mr: 1 } } onClick={ onBack }>
                    { t('certificateSelectModal.button.back') }
                </CommonButton>
            </Box>
        </>
    );
};

export default CertificateDetails;
