import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import DocumentList from '../../components/documents/DocumentList';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getDocuments, resetSignSuccessful, setFilesForUpload } from '../../slices/documents';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { hasValidFinaPKICloudCertificate, resetFinaFlags, sendTan, signDocumentFina } from '../../slices/fina-lcp';
import { checkHasIdyCertAndSign, closeCertificateSelectModal, issueIdyCertificateAndSign } from '../../slices/certificates';
import Page from '../../components/shared/Page';
import TanInsertionModal from '../../components/TanInsertionModal';
import { showTanModal } from '../../slices/tan-modal';
import { PrivateRoutes } from '../../routes';
import { toast } from 'react-hot-toast';
import { DocumentsRetrieveType } from '../../model/common/documents/DocumentsRetrieveType';
import { DocumentVisibilityType } from '../../model/common/documents/DocumentVisibilityType';
import FileDropzone from '../../components/FileDropzone';
import { AppSettings } from '../../common/app-settings';
import SkeletonBlock from '../../components/skeletons/SkeletonBlock';
import { clearErrorMessage } from '../../slices/errors-handling';
import { ISignatures } from '../../model/common/payments/ISignatures';
import { PaymentsHelper } from '../../utils/payments-helper';
import { getEntityPayables } from '../../slices/payments';

interface IProps {
    type: DocumentsRetrieveType;
}

const DocumentsPage: FC<IProps> = ({ type }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { documents, isLoadingReceivedDocuments, isLoadingSentDocuments, isSigning, isSignSuccessful, isTogglingDocumentVisibility, isUploadingDocuments } =
        useAppSelector((state) => state.documents);
    const { certInfo, hasValidFinaCertificate, isFinaSignSuccessful, isFinaSigning } = useAppSelector((state) => state.finaLcp);
    const { userPayables } = useAppSelector((state) => state.payments);

    const [ unsignedDocuments, setUnsignedDocuments ] = useState<string[]>([]);

    const signatures: ISignatures | undefined = PaymentsHelper.getSignaturesFromPayables(userPayables);

    useEffect(() => {
        !certInfo && dispatch(hasValidFinaPKICloudCertificate());
        type === DocumentsRetrieveType.RECEIVED && dispatch(getDocuments(DocumentVisibilityType.VISIBLE, DocumentsRetrieveType.RECEIVED));
        type === DocumentsRetrieveType.SENT && dispatch(getDocuments(DocumentVisibilityType.VISIBLE, DocumentsRetrieveType.SENT));
        !userPayables && dispatch(getEntityPayables());

        return () => {
            dispatch(clearErrorMessage());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        type === DocumentsRetrieveType.RECEIVED && dispatch(getDocuments(DocumentVisibilityType.VISIBLE, DocumentsRetrieveType.RECEIVED));
        type === DocumentsRetrieveType.SENT && dispatch(getDocuments(DocumentVisibilityType.VISIBLE, DocumentsRetrieveType.SENT));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ type ]);

    useEffect(() => {
        if (isFinaSignSuccessful || isSignSuccessful) {
            toast.success(t('dashboard.sidebar.documentDetails.sign.successful'));
            dispatch(resetSignSuccessful());
            dispatch(resetFinaFlags());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isFinaSignSuccessful, isSignSuccessful ]);

    const documentDetailsClickHandler = (documentUuid: string) => {
        navigate(
            PrivateRoutes.private_document_details_route_sentReceived
                .path
                .replace(':documentUuid', documentUuid)
                .replace(':sentOrReceived', type === DocumentsRetrieveType.RECEIVED ? 'received' : 'sent')
        );
    };

    const signAllDocumentsHandler = (unsignedDocuments: string[]) => {
        if (hasValidFinaCertificate) {
            setUnsignedDocuments(unsignedDocuments);
            dispatch(sendTan());
            dispatch(showTanModal());
            return;
        }
        dispatch(checkHasIdyCertAndSign(unsignedDocuments, false, undefined));
    };

    const signingDocumentsConfirmTanHandler = (tan: string) => {
        dispatch(signDocumentFina(unsignedDocuments, tan, undefined, undefined));
    };

    const handleFinaCertificateSelect = () => {
        dispatch(closeCertificateSelectModal());
        navigate(PrivateRoutes.private_shop_route.path);
    };

    const handleCertificateModalClose = () => {
        dispatch(closeCertificateSelectModal());
    };

    const handleIdentyumCertificateSelect = (unsignedDocuments: string[]) => {
        dispatch(closeCertificateSelectModal());
        dispatch(issueIdyCertificateAndSign(unsignedDocuments));
    };

    const handleFilterChange = (visibilityType: DocumentVisibilityType, retrieveType: DocumentsRetrieveType) => {
        dispatch(getDocuments(visibilityType, retrieveType));
    };

    const uploadDocumentHandler = (acceptedFiles: File[]): void => {
        dispatch(setFilesForUpload(acceptedFiles));
        navigate(PrivateRoutes.private_new_document_route.path);
    };

    const documentsToShow = () => {
        if (type === DocumentsRetrieveType.SENT) {
            return documents?.sharedDocuments ?? [];
        }
        return documents?.receivedDocuments ?? [];
    };

    return (
        <>
            <Page
                pageTitle={ t('dashboard.sidebar.documents.privateDocuments.title') }
                pageDescription={ t('dashboard.sidebar.documents.privateDocuments.description') }>
                { type === DocumentsRetrieveType.SENT && (
                    <Box sx={ { mb: 5 } }>
                        { isUploadingDocuments ? (
                            <SkeletonBlock height={ 150 } />
                        ) : (
                            <FileDropzone
                                accept="application/pdf"
                                files={ [] }
                                isFilesPanelVisible={ false }
                                maxSize={ AppSettings.MAX_UPLOAD_SIZE }
                                setFiles={ uploadDocumentHandler }
                                title={ t('dashboard.sidebar.documents.newDocument.fileDropzone.uploadFile') }
                            />
                        ) }
                    </Box>
                ) }

                <Grid container spacing={ 3 }>
                    <Grid item md={ 12 } xs={ 12 }>
                        <Accordion defaultExpanded>
                            <AccordionSummary>
                                <Typography variant="h6">
                                    { type === DocumentsRetrieveType.SENT
                                        ? t('dashboard.sidebar.documents.list.sent.title')
                                        : t('dashboard.sidebar.documents.list.received.title') }
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DocumentList
                                    key={ 1 }
                                    documents={ documentsToShow() }
                                    received={ type === DocumentsRetrieveType.RECEIVED }
                                    isLoadingDocuments={ type === DocumentsRetrieveType.RECEIVED ? isLoadingReceivedDocuments : isLoadingSentDocuments }
                                    isSigning={ isSigning || isFinaSigning }
                                    isTogglingDocumentVisibility={ isTogglingDocumentVisibility }
                                    onDocumentClick={ documentDetailsClickHandler }
                                    onSignAllDocuments={ signAllDocumentsHandler }
                                    onCertificateModalClose={ handleCertificateModalClose }
                                    onIdentyumCertificateSelect={ handleIdentyumCertificateSelect }
                                    onFinaCertificateSelect={ handleFinaCertificateSelect }
                                    signatures={ signatures }
                                    onFilterChange={ (filter) =>
                                        handleFilterChange(
                                            filter,
                                            type === DocumentsRetrieveType.RECEIVED ? DocumentsRetrieveType.RECEIVED : DocumentsRetrieveType.SENT
                                        )
                                    }
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Page>
            <TanInsertionModal onConfirm={ signingDocumentsConfirmTanHandler } />
        </>
    );
};
export default DocumentsPage;
