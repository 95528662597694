import type { FC } from 'react';
import { useEffect } from 'react';
import { Membership } from 'group-service-api';
import { Avatar, Box, Typography } from '@mui/material';
import { DocumentsHelper } from '../../../utils/documents-helper';
import SignaturesCounter from '../../profile/SignaturesCounter';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getOrganizationDetails } from '../../../slices/organizations';
import { BusinessRounded, GroupsOutlined, PersonOutlined } from '@mui/icons-material';
import auth from '../../../auth/auth';
import { IdentificationData } from 'profile-manager-api';
import { Misc } from '../../../utils/misc';

interface IAvatarInfo {
    activeGroup?: Membership;
    userIdentificationData?: IdentificationData;
}

const GroupAvatarPanel: FC<IAvatarInfo> = ({ activeGroup, userIdentificationData }) => {
    const dispatch = useAppDispatch();

    const { selectedOrganization } = useAppSelector(state => state.organizations);
    const userIdentifier = Misc.resolveUserIdentifier(userIdentificationData);

    useEffect(() => {
        if (!selectedOrganization && activeGroup?.group.organizationUuid) {
            auth.setOrganizationUuid(activeGroup?.group.organizationUuid ?? '');
            dispatch(getOrganizationDetails(activeGroup?.group.organizationUuid ?? ''));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Avatar
                sx={ { cursor: 'pointer', height: 48, width: 48 } }
                src={ selectedOrganization?.avatar ? selectedOrganization?.avatar : selectedOrganization?.logo }>
                { DocumentsHelper.getInitials(activeGroup?.group?.name?.toUpperCase() ?? '') }
            </Avatar>
            <Box sx={ { ml: 2 } }>
                <Box sx={ { display: 'flex', flexDirection: 'row' } }>
                    <GroupsOutlined fontSize='small' sx={ { mr: 1 } } />
                    <Typography color='textPrimary' variant='subtitle2'>
                        { activeGroup?.group?.name ?? '' }
                    </Typography>
                </Box>

                { activeGroup?.group.organizationUuid ? (
                    <Box sx={ { display: 'flex', flexDirection: 'row' } }>
                        <BusinessRounded fontSize='small' sx={ { mr: 1, opacity: '0.7' } } />
                        <Typography color='textSecondary' variant='subtitle2'>
                            { selectedOrganization?.name ?? '' }
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <Box sx={ { display: 'flex', flexDirection: 'row' } }>
                            <PersonOutlined fontSize='small' sx={ { mr: 1, opacity: '0.7' } } />
                            <Typography color='textSecondary' variant='subtitle2'>
                                { userIdentifier ?? '' }
                            </Typography>
                        </Box>
                        <SignaturesCounter />
                    </>
                ) }
            </Box>
        </>
    );
};

export default GroupAvatarPanel;
