import type { FC } from 'react';
import { Box, FormControlLabel, Radio } from '@mui/material';

interface IAnsweredRadioProps {
    checked: boolean;
    label: string;
}

const AnsweredRadio: FC<IAnsweredRadioProps> = (props) => {
    const { checked, label } = props;

    return (
        <Box>
            <FormControlLabel control={<Radio disabled={true} checked={checked} />} label={label} />
        </Box>
    );
};

export default AnsweredRadio;
