import type { FC } from 'react';
import { Box, Dialog, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Person } from 'user-document-signer-api';
import { CheckCircle, RemoveCircle } from '@mui/icons-material';
import { DocumentsHelper } from '../utils/documents-helper';
import CommonButton, { ButtonTypeEnum } from './shared/CommonButton';

interface RecipientsModalProps {
    recipients: Person[];
    onClose: () => void;
    open: boolean;
}

const RecipientsModal: FC<RecipientsModalProps> = (props) => {
    const { open, onClose, recipients } = props;
    const { t } = useTranslation();

    return (
        <Dialog maxWidth="lg" open={open} onClose={onClose}>
            <Box sx={{ ml: 7, mr: 7, mt: 3, p: 3 }}>
                <Typography align="center" color="textPrimary" gutterBottom variant="h5">
                    {t('dashboard.sidebar.documents.recipientsModal.title')}
                </Typography>
                <Typography align="center" color="textSecondary" variant="body1">
                    {t('dashboard.sidebar.documents.recipientsModal.subTitle')}
                </Typography>
            </Box>
            <Box sx={{ mt: 3, mb: 5 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ textAlign: 'center' }}>{t('dashboard.sidebar.documents.recipientsModal.table.signed')}</TableCell>
                            <TableCell>{t('dashboard.sidebar.documents.recipientsModal.table.recipientName')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {recipients.map((person: Person, index: number) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell sx={{ textAlign: 'center' }}>
                                        <Typography variant="body2" sx={{ mr: 1, opacity: '0.5' }}>
                                            {person.signatureInfo?.signed ? <CheckCircle fontSize="small" color="primary" /> : <RemoveCircle fontSize="small" />}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color={'textSecondary'} variant="body2">
                                            {DocumentsHelper.getDocumentSignerDataUDS(person)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
            <Box sx={{ mb: 5, textAlign: 'center' }}>
                <CommonButton btnType={ButtonTypeEnum.CLOSE} onClick={() => onClose()} sx={{ pl: 5, pr: 5 }}>
                    {t('dashboard.sidebar.documents.recipientsModal.button.close')}
                </CommonButton>
            </Box>
        </Dialog>
    );
};

export default RecipientsModal;
