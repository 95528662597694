import { FC, useCallback, useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTheme } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useSettings from '../../../hooks/useSettings';
import { THEMES } from '../../../common/constants';
import image404Dark from '../../../assets/img/error/error404_light.svg';
import image404Light from '../../../assets/img/error/error404_light.svg';
import CommonButton, { ButtonTypeEnum } from '../../shared/CommonButton';
import { HOME_ROUTE } from '../../../routes';

const Page404: FC = () => {
    const [ count, setCount ] = useState<number>(7);
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const { settings } = useSettings();

    const isDarkMode = settings.theme === THEMES.DARK;

    const getBackToHomepage = useCallback(() => {
        navigate(HOME_ROUTE.path ?? '/', { replace: true });
    }, [ navigate ]);

    useEffect(() => {
        // wait 5 second before redirecting to home page
        const interval = setInterval(() => {
            setCount(prevCounter => prevCounter - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        count === 0 && getBackToHomepage();
    }, [ count, getBackToHomepage ]);

    return (
        <Box sx={ {
            alignItems: 'center',
            backgroundColor: 'background.default',
            display: 'flex',
            minHeight: '100%',
            px: 3,
            py: '80px'
        } }>
            <Container maxWidth="lg">
                <Typography align="center" color="textPrimary" variant={ mobileDevice ? 'h4' : 'h1' }>
                    { t('page404.notFound') }
                </Typography>

                <Typography align="center" color="textSecondary" variant="subtitle2">
                    { t('page404.notFound.description') }
                </Typography>

                <Box sx={ { display: 'flex', justifyContent: 'center', mt: 6 } }>
                    <Box component="img"
                         src={ isDarkMode ? image404Dark : image404Light }
                         sx={ { height: 'auto', maxWidth: '100%', width: 400 } } />
                </Box>

                <Box sx={ { display: 'flex', justifyContent: 'center', mt: 6 } }>
                    <CommonButton btnType={ ButtonTypeEnum.BACK }
                                  onClick={ () => getBackToHomepage() }
                                  variant="contained">
                        { t('page404.goHome') }
                    </CommonButton>
                </Box>
                <Box sx={ { display: 'flex', justifyContent: 'center', mt: 2 } }>
                    <Trans i18nKey="page404.notFound.redirecting.label"
                           values={ { seconds: count } } />
                </Box>
            </Container>
        </Box>
    );
};

export default Page404;