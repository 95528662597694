import type { FC } from 'react';
import { useEffect } from 'react';
import { PaymentsHelper } from '../../utils/payments-helper';
import { useAppDispatch } from '../../store/hooks';
import { sendWsPayResult } from '../../slices/payments';
import { Misc } from '../../utils/misc';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ApiWsPayResultRequest } from 'payment-service-api';
import { handleWsPayError } from '../../slices/errors-handling';
import { PrivateRoutes } from '../../routes';

const WsPayResult: FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const request: ApiWsPayResultRequest = PaymentsHelper.paramsToApiWsPayResultRequest(searchParams);

        if (!Misc.isEmptyObject(request) && request.Success !== '0') {
            dispatch(sendWsPayResult(request));
        }

        if (!Misc.isEmptyObject(request) && request.Success === '0') {
            dispatch(handleWsPayError(request));
        }
        navigate(PrivateRoutes.private_shop_route.path);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ ml: 7, mr: 7, mt: 3, p: 3, textAlign: 'center' }}>
            <Typography align='center' color='textPrimary' gutterBottom variant='h5'>
                {t('WSPay.message.payment.processingResult')}
            </Typography>
            <CircularProgress sx={{ mt: 5, mb: 5 }} size='5rem' />
        </Box>
    );
};

export default WsPayResult;
