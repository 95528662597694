import type { FC } from 'react';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserContactsList from '../../components/contacts/UserContactsList';
import { getUserContacts, resetSendSuccessful } from '../../slices/user-contacts';
import Page from '../../components/shared/Page';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import SkeletonCard from '../../components/skeletons/SkeletonCard';
import { ImportContactsRounded } from '@mui/icons-material';
import EmptyState from '../../components/empty-states/EmptyState';
import { Box } from '@mui/material';
import { toast } from 'react-hot-toast';

const UserContactsSummaryPage: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { userContacts, isLoading, sendSuccessful } = useAppSelector((state) => state.userContacts);

    useEffect(() => {
        if (!userContacts || userContacts.length === 0) {
            dispatch(getUserContacts());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sendSuccessful) {
            dispatch(resetSendSuccessful());
            toast.success(t('dashboard.sidebar.userContacts.newContact.successfullySent'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sendSuccessful ]);

    const createContactHandler = () => {
        navigate('/dashboard/userContacts/newContact');
    };

    return (
        <Page
            pageTitle={ t('dashboard.sidebar.userContacts.allContacts.title') }
            actionButton={
                <CommonButton btnType={ ButtonTypeEnum.ADD } onClick={ createContactHandler }>
                    { t('dashboard.sidebar.userContacts.allContacts.newContact') }
                </CommonButton>
            }>
            { !isLoading && (
                <>
                    { userContacts?.length && userContacts?.length > 0 ? (
                        <UserContactsList userContacts={ userContacts ?? [] } />
                    ) : (
                        <>
                            <EmptyState message={ t('userContacts.emptyState.title') }
                                        icon={ <ImportContactsRounded /> } />

                            <Box sx={ { textAlign: 'center' } }>
                                <CommonButton btnType={ ButtonTypeEnum.ADD_PERSON }
                                              onClick={ () => createContactHandler() }>
                                    { t('dashboard.sidebar.userContacts.allContacts.newContact') }
                                </CommonButton>
                            </Box>
                        </>
                    ) }
                </>
            ) }
            { isLoading && <SkeletonCard /> }
        </Page>
    );
};

export default UserContactsSummaryPage;
