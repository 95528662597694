import type { FC } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getDialCodes } from '../slices/phone-verifier';

interface ICountryCodeChooser {
    disabled?: boolean
    fallbackDialCode: string;
    flagsEnabled?: boolean;
    onSelect: (countryCode: string) => void;
    selectedDialCode?: string;
    validationErrorMessage: string;
}

const CountryCodeChooser: FC<ICountryCodeChooser> = ({ fallbackDialCode, flagsEnabled, onSelect, selectedDialCode, validationErrorMessage, ...other }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { dialCodes } = useAppSelector((state) => state.phoneVerifier);
    const [dialCode, setDialCode] = useState<string>('');

    useEffect(() => {
        if (!dialCodes) {
            dispatch(getDialCodes());
        }
        const initialDialCode = selectedDialCode
            ? selectedDialCode
            : dialCodes?.defaultDialCode && dialCodes?.defaultDialCode.code
                ? dialCodes?.defaultDialCode.code
                : fallbackDialCode
                    ? fallbackDialCode : '385';
        setDialCode(initialDialCode);
        onSelect(initialDialCode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDialCodeChange = (event: SelectChangeEvent): void => {
        setDialCode(event.target.value as string);
        onSelect(event.target.value as string);
    };

    const availableDialCodes = dialCodes?.enabledDialCodes
        ? Array.from(dialCodes?.enabledDialCodes)
        : [];


    return (
        <>{availableDialCodes && availableDialCodes.length > 0 ? (
            <FormControl fullWidth>
                <InputLabel id='ilDialCode'>{t('receiver.input.mobile.dialCode')}</InputLabel>
                <Select
                    fullWidth
                    error={Boolean(validationErrorMessage)}
                    id='selectDialCodeId'
                    value={dialCode}
                    onChange={handleDialCodeChange}
                    autoWidth label={t('receiver.input.mobile.dialCode')}
                    {...other}>
                    {availableDialCodes.map((dc) => {
                        return (
                            <MenuItem key={dc.code} value={dc.code}>
                                <Box>
                                    {flagsEnabled &&
                                        (
                                            <img
                                                loading='lazy'
                                                width='20'
                                                src={`https://flagcdn.com/w20/${dc.countryCode.toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${dc.countryCode.toLowerCase()}.png 2x`}
                                                alt={`Flag of ${dc.countryCode}`}
                                            />
                                        )
                                    }
                                    {`  +${dc.code}`}
                                </Box>
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        ) : (
            <TextField
                label={t('receiver.input.mobile.dialCode')}
                value={`+${fallbackDialCode}`}
                fullWidth
                variant='outlined'
                disabled
            />
        )}</>

    );
};

export default CountryCodeChooser;
