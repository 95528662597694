import type { FC } from 'react';
import { useRef, useState } from 'react';
import DraggableSignature from './DraggableSignature';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { PDFPageProxy } from 'react-pdf';
import { DraggableData, DraggableEvent } from 'react-draggable';
import { Box, Dialog, Grid, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import { Close } from '@mui/icons-material';
import { IVisualSignatureCoordinates } from '../../model/common/documents/document-user';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { useTranslation } from 'react-i18next';
import { DocumentInfo, Person } from 'idy-certifier-api';
import { DocumentDetailsPageUtil } from '../../pages/documents/details/DocumentDetailsPage.util';
import auth from '../../auth/auth';

interface IPdfSignatureModalProps {
    documentContent: string;
    documentDetails?: DocumentInfo;
    onClose: () => void;
    onFinish: (visualCoordinates: IVisualSignatureCoordinates) => void;
    open: boolean;
    signer?: Person;
}

const visualSignatureDimensions = {
    height: 50,
    width: 150
};

const PdfSignatureModal: FC<IPdfSignatureModalProps> = ({ documentContent, documentDetails, onClose, onFinish, open }) => {
    const { t } = useTranslation();
    const userName = auth.getUserUuid();

    const [ pageNum, setPageNum ] = useState<number>(1);
    const [ totalPages, setTotalPages ] = useState<number>(0);
    const [ pageDetails, setPageDetails ] = useState<PDFPageProxy | null>(null);
    const [ position, setPosition ] = useState<DraggableData | null>(null);
    // const [ offsets, setOffsets ] = useState<IOffsetData | null>(null);
    const documentRef = useRef<HTMLDivElement | undefined>(undefined);
    const signer = DocumentDetailsPageUtil.getCurrentUser(documentDetails, userName);
    const signatoryName = signer?.fullName || signer?.email || `${ ( signer?.phone?.dialCode ?? '' ) + ( signer?.phone?.number ?? '' ) }` || '';

    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    const previousPage = () => {
        changePage(-1);
    };

    const nextPage = () => {
        changePage(1);
    };

    const changePage = (offset: number) => {
        setPageNum((prevPageNumber) => prevPageNumber + offset);
    };

    const onDocumentLoadSuccess = (numPages: number) => {
        const totalPages = documentDetails?.qrCodeIncluded ? numPages - 1 : numPages;
        setTotalPages(totalPages);
        setPageNum(1);
    };

    const handleDraggableEnd = (e: DraggableEvent, data: DraggableData) => {
        setPosition(data);
    };

    const handleOnFinish = () => {
        const ratioX = ( pageDetails?.originalWidth ?? 0 ) / ( documentRef.current?.clientWidth ?? 1 );
        const ratioY = ( pageDetails?.originalHeight ?? 0 ) / ( documentRef.current?.clientHeight ?? 1 );
        const { lastX, lastY } = position as DraggableData;
        const visualSignatureCoordinates = {
            height: visualSignatureDimensions.height,
            pageNumber: pageNum - 1,
            width: visualSignatureDimensions.width,
            x: lastX * ratioX,
            y: ( pageDetails?.originalHeight ?? 0 ) - lastY * ratioY - visualSignatureDimensions.height
        } as IVisualSignatureCoordinates;

        onFinish(visualSignatureCoordinates);
    };

    return (
        <Dialog maxWidth='xl' onClose={ onClose } open={ open }>
            <IconButton aria-label='close'
                        onClick={ () => onClose() }
                        sx={ {
                            color: (theme) => theme.palette.grey[500],
                            position: 'absolute',
                            right: 8,
                            top: 8
                        } }>
                <Close />
            </IconButton>
            <Grid container direction='column' alignItems='center' justifyContent='center'>
                <Grid item>
                    <Box ref={ documentRef } sx={ { overflow: 'hidden' } }>
                        { pageDetails &&
                          <DraggableSignature signatoryFullName={ signatoryName }
                                              boxDetails={ documentRef.current }
                                              pageDetails={ pageDetails }
                                              onEnd={ handleDraggableEnd } />
                        }

                        <Document file={ 'data:application/pdf;base64,' + documentContent }
                                  onLoadSuccess={ (data) => onDocumentLoadSuccess(data.numPages) }>
                            <Page pageNumber={ pageNum }
                                  onLoadSuccess={ (data) => setPageDetails(data) } />
                        </Document>
                    </Box>
                </Grid>
            </Grid>
            <Grid container direction='column' alignItems='center' justifyContent='center'>
                <Grid item sx={ { mb: 1 } }>
                    <Typography variant='body1'>
                        { t('dashboard.sidebar.documentDetails.pdfPreview.pages', { page: `${ pageNum || ( totalPages ? 1 : '--' ) }`, of: `${ totalPages || '--' }` }) }
                    </Typography>
                </Grid>
                <Grid item>
                    <CommonButton btnType={ ButtonTypeEnum.PREVIOUS }
                                  disabled={ pageNum <= 1 }
                                  onClick={ previousPage }
                                  sx={ { mr: 1 } }>
                        { smUp ? t('dashboard.sidebar.documentDetails.button.previousPage') : '' }
                    </CommonButton>
                    <CommonButton btnType={ ButtonTypeEnum.NEXT }
                                  disabled={ pageNum >= totalPages }
                                  onClick={ nextPage }
                                  sx={ { ml: 1 } }>
                        { smUp ? t('dashboard.sidebar.documentDetails.button.nextPage') : '' }
                    </CommonButton>
                </Grid>
            </Grid>
            <CommonButton btnType={ ButtonTypeEnum.CONFIRM }
                          onClick={ handleOnFinish }
                          variant='contained'
                          sx={ { mb: 2, minWidth: '40%', mt: 3, mx: 'auto' } }>
                { t('actions.confirm') }
            </CommonButton>
        </Dialog>
    );
};

export default PdfSignatureModal;