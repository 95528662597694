import { combineReducers } from '@reduxjs/toolkit';
import { reducer as documentsReducer } from '../slices/documents';
import { reducer as authReducer } from '../slices/auth';
import { reducer as tanModalReducer } from '../slices/tan-modal';
import { reducer as userContactsReducer } from '../slices/user-contacts';
import { reducer as emailsReducer } from '../slices/emails';
import { reducer as errorHandlingReducer } from '../slices/errors-handling';
import { reducer as profileDataReducer } from '../slices/profile-data';
import { reducer as paymentReducer } from '../slices/payments';
import { reducer as finaLcpReducer } from '../slices/fina-lcp';
import { reducer as phonesReducer } from '../slices/phones';
import { reducer as phoneVerifier } from '../slices/phone-verifier';
import { reducer as pinManagerReducer } from '../slices/pin-manager';
import { reducer as groupsReducer } from '../slices/groups';
import { reducer as certificatesReducer } from '../slices/certificates';
import { reducer as consentsReducer } from '../slices/consents';
import { reducer as organizationsReducer } from '../slices/organizations';
import { reducer as surveyReducer } from '../slices/survey';
import { reducer as otpModalReducer } from '../slices/otp-modal';

const rootReducer = combineReducers({
    auth: authReducer,
    certificates: certificatesReducer,
    consents: consentsReducer,
    documents: documentsReducer,
    emails: emailsReducer,
    errors: errorHandlingReducer,
    finaLcp: finaLcpReducer,
    groups: groupsReducer,
    organizations: organizationsReducer,
    otpModal: otpModalReducer,
    payments: paymentReducer,
    phones: phonesReducer,
    phoneVerifier: phoneVerifier,
    pinManager: pinManagerReducer,
    profileData: profileDataReducer,
    survey: surveyReducer,
    tanModal: tanModalReducer,
    userContacts: userContactsReducer,
});

export default rootReducer;
