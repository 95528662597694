import { FC } from 'react';
import { MemberIn } from 'organizations-service-api';
import { useTranslation } from 'react-i18next';
import { Box, Grid, List, ListItem, ListItemText } from '@mui/material';
import OrganizationMemberChip from '../../components/receivers/OrganizationMemberChip';
import { ContactResponse, EmailResponse, PhoneResponse } from 'contacts-service-api';
import { useAppSelector } from '../../store/hooks';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';

interface IOrganizationMemberPanelProps {
    isAddingMember: boolean;
    members: MemberIn[];
    onAddMembers: () => void;
    onRemoveMember: (member: MemberIn) => void;
}

const OrganizationMemberPanel: FC<IOrganizationMemberPanelProps> = (props: IOrganizationMemberPanelProps) => {
    const { isAddingMember, members, onAddMembers, onRemoveMember } = props;
    const { t } = useTranslation();
    const { userContacts } = useAppSelector(state => state.userContacts);

    const getMemberNameFromContact = (member: MemberIn) => userContacts
        ?.find((contact: ContactResponse) =>
            contact.phones?.some((p: PhoneResponse) =>
                p.value.dialCode === member.phone?.dialCode && p.value.number === member.phone?.number
            )
        )?.name ?? userContacts
        ?.find((contact: ContactResponse) =>
            contact.emails?.some((e: EmailResponse) =>
                e.email === member.email
            )
        )?.name ?? '';

    return (
        <Box sx={ {
            p: 3,
            pt: 1,
            width: '100%'
        } }>
            <Grid item
                  container
                  direction='row'
                  justifyContent='left'
                  spacing={ 2 }>
                { members.map((member: MemberIn) => (
                    <OrganizationMemberChip key={ member.email ?
                        member.email :
                        member.phone?.dialCode.concat(member.phone?.number) }
                                            member={ member }
                                            memberName={ getMemberNameFromContact(member) }
                                            onRemoveMember={ onRemoveMember } />
                )) }
            </Grid>


            { members.length === 0 && (
                <Box>
                    <List>
                        <ListItem sx={ { '& + &': { mt: 1 }, textAlign: 'center' } }>
                            <ListItemText
                                primary={ t('organization.membersPanel.notAdded.info') }
                                secondary={ t('organization.membersPanel.notAdded.description') }
                                primaryTypographyProps={ {
                                    color: 'textPrimary',
                                    variant: 'subtitle2'
                                } } />
                        </ListItem>
                    </List>
                </Box>
            ) }

                <CommonButton sx={{ mt: 4 }}
                          btnType={ ButtonTypeEnum.SEND }
                          onClick={ () => onAddMembers() }
                          disabled={ isAddingMember || members.length === 0 }>
                { members.length === 1 ?
                    t('organization.membersPanel.action.add.single') :
                    t('organization.membersPanel.action.add.multiple')
                }
            </CommonButton>
        </Box>
    );
};

export default OrganizationMemberPanel;
