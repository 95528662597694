import { useCallback, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import './CardInfosTest.css';
import testCards from './TEST CARDS INFORMATIONS ALL CURRENCIES 2023.pdf';
import useResizeObserver from '../../utils/useResizeObserver';
import { Box, Typography } from '@mui/material';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { CopyAll, OpenInBrowser } from '@mui/icons-material';
import { toast } from 'react-hot-toast';

const resizeObserverOptions = {};
const maxWidth = 800;

export default function CardInfosTest() {
    const [ numPages, setNumPages ] = useState<number>();
    const [ containerRef, setContainerRef ] = useState<HTMLElement | null>(null);
    const [ containerWidth, setContainerWidth ] = useState<number>();

    const cardNumbers = [
        '30127619081974',
        '30361712519966',
        '36259600000004',
        '377504509671000',
        '377500998821007',
        '377500963281005',
        '5488661621675007',
        '4785611000000189',
        '4574180527069023',
        '4267330591691010'
    ];
    const random = Math.floor(Math.random() * cardNumbers.length);
    const testCardNumber = cardNumbers[random];

    const onResize = useCallback<ResizeObserverCallback>((entries) => {
        const [ entry ] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };

    function copyToClipboard() {
        toast.success(`Broj kartice je kopiran u međuspremnik ${testCardNumber}`);
        return navigator.clipboard.writeText(testCardNumber);
    }

    return (
        <Box className="TestCards">
            <Typography variant="body1">Testne kartice za sljedeći korak</Typography>
            <CommonButton sx={{ mr: 2 }}
                          btnType={ ButtonTypeEnum.NO_ICON_CONTAINED }
                          startIcon={ <OpenInBrowser /> }
                          onClick={ () => window.open(testCards) }>
                Preuzmi testne kartice
            </CommonButton>
            <CommonButton btnType={ ButtonTypeEnum.NO_ICON_CONTAINED }
                          startIcon={ <CopyAll /> }
                          onClick={ () => copyToClipboard() }>
                Kopiraj broj kartice u miš :)
            </CommonButton>
            <Box className="TestCards__container__document" ref={ setContainerRef }>
                <Document file={ testCards } onLoadSuccess={ onDocumentLoadSuccess }>
                    { Array.from(new Array(numPages), (_el, index) => (
                        <Page key={ `page_${ index + 1 }` }
                              pageNumber={ index + 1 }
                              width={ containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth } />
                    )) }
                </Document>
            </Box>
        </Box>
    );
}
