import type { FC, ReactNode } from 'react';
import { THEMES } from '../common/constants';
import { AppSettings } from '../common/app-settings';
import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export interface ISettings {
    compact?: boolean;
    direction?: 'ltr' | 'rtl';
    responsiveFontSizes?: boolean;
    roundedCorners?: boolean;
    theme?: string;
}

interface ISettingsProviderProps {
    children?: ReactNode;
}

export interface ISettingsContext {
    settings: ISettings;
    saveSettings: (update: ISettings) => void;
}

const initialSettings: ISettings = {
    compact: true,
    direction: 'ltr',
    responsiveFontSizes: true,
    roundedCorners: true,
    theme: THEMES.LIGHT,
};

const restoreSettings = (): ISettings => {
    let settings: ISettings = {};

    try {
        const storedData: string | null = window.localStorage.getItem(AppSettings.SETTINGS_LS_KEY);
        settings = storedData
            ? JSON.parse(storedData)
            : {
                compact: true,
                direction: 'ltr',
                responsiveFontSizes: true,
                roundedCorners: true,
                theme: window.matchMedia('(prefers-color-scheme: dark)').matches
                    ? THEMES.DARK
                    : THEMES.LIGHT,
            };
    } catch (err) {
        console.log(err);
        // Ako podaci nisu stringify-ani u prije spremanja u localStorage
        // moguće je da pukne dohvat pa se zato lovi error
    }

    return settings;
};

const storeSettings = (settings: ISettings): void => {
    window.localStorage.setItem(AppSettings.SETTINGS_LS_KEY, JSON.stringify(settings));
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const SettingsContext = createContext<ISettingsContext>({ settings: initialSettings, saveSettings: () => {} });

export const SettingsProvider: FC<ISettingsProviderProps> = (props) => {
    const { children } = props;
    const [settings, setSettings] = useState<ISettings>(initialSettings);

    useEffect(() => {
        const restoredSettings = restoreSettings();
        if (restoredSettings) {
            setSettings(restoredSettings);
        }
    }, []);

    const saveSettings = (updatedSettings: ISettings): void => {
        setSettings(updatedSettings);
        storeSettings(updatedSettings);
    };

    return (
        <SettingsContext.Provider value={{settings, saveSettings}}>
            {children}
        </SettingsContext.Provider>
    );
};

SettingsProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default SettingsContext;
