import type { FC } from 'react';
import {
    Card,
    CardContent, CardHeader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { OrganizationMemberDocument } from 'idy-certifier-api';
import { FileDownloadRounded } from '@mui/icons-material';
import { getMemberDocumentDetails } from '../../slices/organizations';
import { useAppDispatch } from '../../store/hooks';

interface IOrganizationUserDocumentsProps {
    documents: OrganizationMemberDocument[];
}

const OrganizationUserDocuments: FC<IOrganizationUserDocumentsProps> = ({ documents }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleDocumentDownload = (doc: OrganizationMemberDocument) => {
        dispatch(getMemberDocumentDetails(doc.uuid));
    };

    return (
        <Card sx={ { mb: 5 } }>
            <CardHeader title={ t('organizationDocuments.title.details') } sx={ { textAlign: 'center' } } />
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{ t('organizationDocuments.documentName') }</TableCell>
                            <TableCell>{ t('organizationDocuments.fileName') }</TableCell>
                            <TableCell>{ t('organizationDocuments.documentCreatedAt') }</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { documents && documents.map((doc: OrganizationMemberDocument, index: number) => {
                            return (
                                <TableRow key={ index }>
                                    <TableCell sx={ { borderBottom: 'none' } }>
                                        <Typography color='textSecondary' variant='body2'>
                                            { doc.documentName }
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={ { borderBottom: 'none' } }>
                                        <Typography color='textSecondary' variant='body2'>
                                            { doc.fileName }
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={ { borderBottom: 'none' } }>
                                        <Typography color='textSecondary' variant='body2'>
                                            { format(new Date(doc.documentCreatedAt), 'dd.MM.yyyy. HH:mm') }
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={ { borderBottom: 'none' } }>
                                        <Tooltip
                                            title={ t('businessProfile.details.documents.action.downloadDocument') }
                                            sx={ { m: 1 } }>
                                            <FileDownloadRounded
                                                fontSize='small'
                                                onClick={ () => handleDocumentDownload(doc) } />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        }) }
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default OrganizationUserDocuments;
