import type { FC } from 'react';
import { useEffect, useState } from 'react';
import Page from '../../components/shared/Page';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserContacts } from '../../slices/user-contacts';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Box, Card, Tab, Tabs } from '@mui/material';
import TabPanel from '../../components/shared/TabPanel';
import AddMemberContactsList from '../../components/groups/AddMemberContactsList';
import AddMemberEmailCard from '../../components/groups/AddMemberEmailCard';
import AddMemberPhoneCard from '../../components/groups/AddMemberPhoneCard';
import { Invitation } from 'group-service-api';
import { getGroup, giveAccessToGroup, resetFlags } from '../../slices/groups';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import { getOrganizationMembers } from '../../slices/organizations';
import { convertMembersToContacts } from '../../utils/organizations-helper';
import SkeletonBlock from '../../components/skeletons/SkeletonBlock';
import auth from '../../auth/auth';
import { toast } from 'react-hot-toast';

const AddMemberPage: FC = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { groupUuid } = params;
    const dispatch = useAppDispatch();

    const { isLoading, userContacts } = useAppSelector((state) => state.userContacts);
    const { isAddedToGroupSuccessful, isLoadingGroup, selectedGroup } = useAppSelector((state) => state.groups);
    const { organizationMembers } = useAppSelector((state) => state.organizations);
    const isLoadingOrganization = useAppSelector((state) => state.organizations.isLoading);
    const [value, setValue] = useState<number>(0);
    const { t } = useTranslation();

    const loadingInProgress = isLoading || isLoadingGroup || isLoadingOrganization;
    const organizationContacts = convertMembersToContacts(organizationMembers);

    useEffect(() => {
        if (!userContacts || userContacts.length === 0) {
            dispatch(getUserContacts());
        }

        if (!selectedGroup && groupUuid) {
            dispatch(getGroup(groupUuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!organizationMembers && selectedGroup?.organizationUuid) {
            auth.setOrganizationUuid(selectedGroup?.organizationUuid);
            dispatch(getOrganizationMembers(selectedGroup?.organizationUuid ?? ''));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGroup?.organizationUuid]);

    useEffect(() => {
        if (isAddedToGroupSuccessful) {
            toast.success(t('groups.memberships.add.success'));
            dispatch(resetFlags());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddedToGroupSuccessful]);

    const handleAddNewUserClick = (invitation: Invitation, index?: number) => {
        dispatch(giveAccessToGroup(groupUuid ?? '', invitation, index));
    };

    const handleNavigateBack = () => {
        navigate('/dashboard/groups/' + groupUuid + '/members');
    };

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const renderAddMemberComponent = (): JSX.Element => {
        return (
            selectedGroup?.organizationUuid ? (
                <Card>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} variant='scrollable' scrollButtons='auto'>
                            <Tab label={t('groups.memberships.add.fromBusinessMembers')} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <AddMemberContactsList
                            contacts={organizationContacts}
                            onClick={handleAddNewUserClick}
                            emptyStateTitle={t('userContacts.emptyState.company.title') as string}
                            emptyStateSubtitle={t('userContacts.emptyState.company.subtitle') as string} />
                    </TabPanel>
                </Card>
            ) : (
                <Card>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} variant='scrollable' scrollButtons='auto'>
                            <Tab label={t('groups.memberships.add.byEmail')} />
                            <Tab label={t('groups.memberships.add.byPhone')} />
                            <Tab label={t('groups.memberships.add.fromContacts')} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <AddMemberEmailCard onClick={handleAddNewUserClick} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <AddMemberPhoneCard onClick={handleAddNewUserClick} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <AddMemberContactsList contacts={userContacts} onClick={handleAddNewUserClick} />
                    </TabPanel>
                </Card>
            )
        );
    };

    return (
        <Page
            pageTitle={t('groups.settings.addUser')}
            actionButton={
                <CommonButton btnType={ButtonTypeEnum.BACK} onClick={handleNavigateBack}>
                    {t('go.back')}
                </CommonButton>
            }>
            {loadingInProgress ? <SkeletonBlock /> : renderAddMemberComponent()}
        </Page>
    );
};

export default AddMemberPage;
