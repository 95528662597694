import type { FC } from 'react';
import { useEffect } from 'react';
import Page from '../../components/shared/Page';
import SkeletonBlock from '../../components/skeletons/SkeletonBlock';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes } from '../../routes';
import EmptyState from '../../components/empty-states/EmptyState';
import { Inbox } from '@mui/icons-material';
import { Box } from '@mui/material';
import { getAllOrganizations } from '../../slices/organizations';
import OrganizationsList from '../../components/organizations/OrganizationsList';
import auth from '../../auth/auth';

const OrganizationListPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { allOrganizations, isCancelingRequest, isLoading } = useAppSelector((state) => state.organizations);

    useEffect(() => {
        dispatch(getAllOrganizations());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNewBusinessProfileRequest = () => {
        navigate(PrivateRoutes.private_organizations_new_route.path);
    };

    const handleOrganizationDetailsClick = (uuid: string) => {
        auth.setOrganizationUuid(uuid);
        navigate(
            PrivateRoutes
                .private_organizations_details_route
                .path
                .replace(
                    ':organizationUuid',
                    uuid
                )
        );
    };

    const showOrganizations = () => {
        if (!allOrganizations || allOrganizations.length === 0) {
            return (
                <>
                    <EmptyState message={ t('emptyState.noBusinessRequests') } icon={ <Inbox /> } />
                    <Box sx={ { textAlign: 'center' } }>
                        <CommonButton btnType={ ButtonTypeEnum.ADD } onClick={ handleNewBusinessProfileRequest }>
                            { t('businessProfile.newRequest') }
                        </CommonButton>
                    </Box>
                </>
            );
        }
        return (
            <OrganizationsList isLoadingAction={ isCancelingRequest }
                               onViewDetails={ handleOrganizationDetailsClick }
                               organizations={ allOrganizations } />
        );
    };

    return (
        <>
            <Page
                  pageTitle={ t('businessProfile.list.title') }
                  actionButton={
                      <CommonButton btnType={ ButtonTypeEnum.ADD } onClick={ handleNewBusinessProfileRequest }>
                          { t('businessProfile.newRequest') }
                      </CommonButton>
                  }>
                { isLoading ? <SkeletonBlock height={ 250 } /> : showOrganizations() }
            </Page>
        </>
    );
};

export default OrganizationListPage;
