import { FC } from 'react';
import { Box, Skeleton } from '@mui/material';

interface ISkeletonBlockProps {
    height?: number;
}

const SkeletonBlock: FC<ISkeletonBlockProps> = (props) => {
    return (
        <Box sx={{ m: 2 }}>
            <Skeleton
                sx={{ borderRadius: 1 }}
                height={props.height ? props.height : 200}
                animation='wave'
                variant='rectangular'
            />
        </Box>
    );
};

export default SkeletonBlock;
