import type { FC } from 'react';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import { PersonAddAlt1Rounded, PersonRemoveAlt1Rounded } from '@mui/icons-material';
import Scrollbar from '../scrollbar/Scrollbar';
import { useTranslation } from 'react-i18next';
import { applyPaginationOrganizationPersons } from '../../utils/organizations-helper';
import { Group, Invitation, MembershipSimple, MembershipSimpleStatusEnum } from 'group-service-api';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { giveAccessToGroup, revokeAccess } from '../../slices/groups';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ConfirmationDialog from '../shared/ConfirmationDialog';
import Label from '../Label';
import { MemberOut, MemberOutRoleEnum, MemberOutStatusEnum } from 'organizations-service-api';
import { getOrganizationMembers } from '../../slices/organizations';
import SkeletonBlock from '../skeletons/SkeletonBlock';
import auth from '../../auth/auth';
import Stack from '@mui/material/Stack';

interface IOrganizationGroupMembersProps {
    group?: Group;
    groupName?: string;
    isLoading: boolean;
    onBackToGroups: () => void;
}

const OrganizationGroupMembers: FC<IOrganizationGroupMembersProps> = (props: IOrganizationGroupMembersProps) => {
    const {
        group,
        groupName,
        isLoading,
        onBackToGroups
    } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [ page, setPage ] = useState<number>(0);
    const [ limit, setLimit ] = useState<number>(10);
    const [ actionMemberUuid, setActionMemberUuid ] = useState<string>('');
    const [ deleteModalOpen, setDeleteModalOpen ] = useState<boolean>(false);

    const { isLoadingMembers, organizationMembers, selectedOrganization } = useAppSelector(state => state.organizations);

    const userUuid = auth.getUserUuid();
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const acceptedMembers = organizationMembers?.filter((m) => m.status === MemberOutStatusEnum.Accepted);
    const paginatedMembers = applyPaginationOrganizationPersons(acceptedMembers, page, limit);
    const isOrganizationAdmin = selectedOrganization?.currentUser?.role === MemberOutRoleEnum.Admin || selectedOrganization?.currentUser?.role === MemberOutRoleEnum.Owner;

    useEffect(() => {
        if (!organizationMembers && selectedOrganization?.uuid) {
            dispatch(
                getOrganizationMembers(
                    selectedOrganization?.uuid
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value, 10));
    };

    const handleRemoveFromGroup = (member: MemberOut) => {
        setActionMemberUuid(member.person.userUuid ?? '');
        setDeleteModalOpen(true);
    };

    const handleCloseDeleteDialog = (isDeletingMember: boolean) => {
        if (isDeletingMember) {
            const membershipToDelete = group?.memberships?.find((m) => m.userUuid === actionMemberUuid);
            dispatch(revokeAccess(group?.uuid ?? '', membershipToDelete?.uuid ?? ''));
        }
        setDeleteModalOpen(false);
    };

    const handleAddToGroup = (member: MemberOut) => {
        setActionMemberUuid(member.person.userUuid ?? '');
        const invitation: Invitation = member.person.email
            ? { email: member.person.email }
            : { phone: { dialCode: member.person.phone?.dialCode ?? '', number: member.person.phone?.number ?? '' } };
        dispatch(giveAccessToGroup(group?.uuid ?? '', invitation));
    };

    const getOrganizationMemberContact = (member: MemberOut) => {
        const email = member.person.email ?? '-';
        const phone = member.person.phone && member.person.phone.dialCode && member.person.phone.number ?
            member.person.phone?.dialCode + member.person.phone?.number :
            '-';

        return (
            <>
                <Box>{ email }</Box>
                <Box>{ phone }</Box>
            </>
        );
    };

    const isMemberAlreadyInGroup = (memberUuid?: string) => {
        return (
            group?.memberships?.findIndex((m) => m.userUuid === memberUuid) !== undefined &&
            group?.memberships?.findIndex((m) => m.userUuid === memberUuid) > -1
        );
    };

    const getAddActionTooltip = (memberUuid?: string) => {
        return isMemberAlreadyInGroup(memberUuid)
            ? t('businessProfile.details.groups.action.addToGroup.alreadyIn')
            : t('businessProfile.details.groups.action.addToGroup');
    };

    const getRemoveActionTooltip = (memberUuid?: string) => {
        return isMemberAlreadyInGroup(memberUuid)
            ? t('businessProfile.details.groups.action.removeFromGroup')
            : t('businessProfile.details.groups.action.removeFromGroup.notYetAdded');
    };

    const isMemberInGroup = (memberUserUuid?: string) => {
        return group?.memberships?.find((m: MembershipSimple) => m.userUuid === memberUserUuid && m.status === MembershipSimpleStatusEnum.Confirmed) !== undefined;
    };

    const isMemberInPendingStatus = (memberUserUuid?: string) => {
        return group?.memberships?.find((m: MembershipSimple) => m.userUuid === memberUserUuid && m.status === MembershipSimpleStatusEnum.Pending) !== undefined;
    };

    return (
        <Box>
            <CommonButton sx={ { mb: 2 } } btnType={ ButtonTypeEnum.BACK } onClick={ onBackToGroups } variant='text'>
                { t('businessProfile.details.groups.action.backToGroups') }
            </CommonButton>
            <Card>
                <CardContent>
                    <Stack direction='row' spacing={1} >
                        <Typography variant='subtitle1'>
                            { t('businessProfile.details.groups.users.title') }
                        </Typography>
                        <Chip label={ ((group?.name || groupName) as string).toUpperCase() }
                              color='primary'
                              sx={{fontWeight: 'bold'}} />
                    </Stack>
                </CardContent>
                <Scrollbar>
                    <Box sx={ { minWidth: smUp ? 700 : 150 } }>
                        { isLoadingMembers ?
                            <SkeletonBlock height={ 250 } /> :
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={ { textAlign: 'center', width: '20%' } }>
                                            { t('businessProfile.details.members.list.column.status') }
                                        </TableCell>
                                        <TableCell>{ t('businessProfile.details.members.list.column.name') }</TableCell>
                                        <TableCell>{ t('businessProfile.details.members.list.column.contact') }</TableCell>
                                        { isOrganizationAdmin && (
                                            <TableCell sx={ { textAlign: 'center' } }>
                                                { t('businessProfile.details.members.list.column.actions') }
                                            </TableCell>
                                        ) }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { paginatedMembers?.map((member: MemberOut) => {
                                        return (
                                            <Fragment key={ member.uuid }>
                                                <TableRow>
                                                    <TableCell sx={ { textAlign: 'center' } }>
                                                        <Label
                                                            color={
                                                                isMemberInGroup(member.person.userUuid)
                                                                    ? 'success'
                                                                    : isMemberInPendingStatus(member.person.userUuid)
                                                                        ? 'warning'
                                                                        : 'info'
                                                            }
                                                            style={ { width: '100%' } }>
                                                            { t(
                                                                isMemberInGroup(member.person.userUuid)
                                                                    ? 'businessProfile.details.members.list.column.status.inGroup'
                                                                    : isMemberInPendingStatus(member.person.userUuid)
                                                                        ? 'businessProfile.details.members.list.column.status.requestSent'
                                                                        : 'businessProfile.details.members.list.column.status.notInGroup'
                                                            ) }
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell>{ member.person.fullName }</TableCell>
                                                    <TableCell>{ getOrganizationMemberContact(member) }</TableCell>
                                                    { isOrganizationAdmin && member.person.userUuid !== userUuid && (
                                                        <TableCell
                                                            sx={ {
                                                                textAlign: 'center',
                                                                verticalAlign: 'middle'
                                                            } }>
                                                            { isLoading && actionMemberUuid === member.person.userUuid ? (
                                                                <CircularProgress size='1rem' />
                                                            ) : (
                                                                <>
                                                                    <Tooltip
                                                                        title={ getAddActionTooltip(member.person.userUuid) }>
                                                                        <IconButton
                                                                            onClick={ () => handleAddToGroup(member) }
                                                                            disabled={ isMemberAlreadyInGroup(member.person.userUuid) }>
                                                                            <PersonAddAlt1Rounded
                                                                                sx={ {
                                                                                    cursor: 'pointer',
                                                                                    ml: 1,
                                                                                    opacity: '0.7'
                                                                                } }
                                                                                fontSize='large'
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip
                                                                        title={ getRemoveActionTooltip(member.person.userUuid) }>
                                                                        <IconButton
                                                                            onClick={ () => handleRemoveFromGroup(member) }
                                                                            disabled={ !isMemberAlreadyInGroup(member.person.userUuid) }>
                                                                            <PersonRemoveAlt1Rounded
                                                                                sx={ {
                                                                                    cursor: 'pointer',
                                                                                    ml: 1,
                                                                                    opacity: '0.7'
                                                                                } }
                                                                                fontSize='large'
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                </>
                                                            ) }
                                                        </TableCell>
                                                    ) }
                                                </TableRow>
                                            </Fragment>
                                        );
                                    }) }
                                </TableBody>
                            </Table> }
                    </Box>
                </Scrollbar>
                <TablePagination
                    component='div'
                    count={ organizationMembers?.length ?? 0 }
                    labelRowsPerPage={ t('rowsPerPage') }
                    onPageChange={ handlePageChange }
                    onRowsPerPageChange={ handleLimitChange }
                    page={ page }
                    rowsPerPage={ limit }
                    rowsPerPageOptions={ [ 10, 20, 50 ] }
                />
            </Card>

            <ConfirmationDialog
                title={ t('group.members.deleteMember.title') }
                message={ t('group.members.deleteMember.message') }
                onConfirm={ () => handleCloseDeleteDialog(true) }
                onCancel={ () => handleCloseDeleteDialog(false) }
                open={ deleteModalOpen }
            />
        </Box>
    );
};

export default OrganizationGroupMembers;
