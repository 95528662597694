import type { FC } from 'react';
import { FinaUserData } from 'fina-lcp-certifier-api';
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IFinaUserDataProps {
    finaUserData?: FinaUserData;
}

const FinaUserDataTable: FC<IFinaUserDataProps> = ({ finaUserData }) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography sx={ { mt: 5 } } color='textSecondary' variant='body1'>
                { t('dashboard.profile.general.finaLcp.userData') }
            </Typography>
            <Table sx={ { mt: 4 } }>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography color='textPrimary' variant='subtitle2'>
                                { t('dashboard.profile.general.documentNumber') }
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color='textSecondary' variant='body2'>
                                { finaUserData?.documentNo }
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography color='textPrimary' variant='subtitle2'>
                                { t('dashboard.profile.general.document') }
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color='textSecondary' variant='body2'>
                                { t(`dashboard.profile.general.documentType.${ finaUserData?.documentType }`) }
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography color='textPrimary' variant='subtitle2'>
                                E-mail
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color='textSecondary' variant='body2'>
                                { finaUserData?.email }
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography color='textPrimary' variant='subtitle2'>
                                { t('dashboard.profile.plans.checkout.billing.firstName') }
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color='textSecondary' variant='body2'>
                                { finaUserData?.firstName }
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography color='textPrimary' variant='subtitle2'>
                                { t('dashboard.profile.plans.checkout.billing.lastName') }
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color='textSecondary' variant='body2'>
                                { finaUserData?.lastName }
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography color='textPrimary' variant='subtitle2'>
                                { t('dashboard.sidebar.userContacts.contactDetails.table.phoneNumber') }
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color='textSecondary' variant='body2'>
                                { finaUserData?.mobileNumber }
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography color='textPrimary' variant='subtitle2'>
                                { t('dashboard.profile.general.personalNumber') }
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color='textSecondary' variant='body2'>
                                { finaUserData?.oib }
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
};

export default FinaUserDataTable;
