import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { IError } from 'model/common/error';
import { PhoneIn } from 'phone-verifier-api';

interface IOtpModalState {
    email: string;
    error: IError | undefined;
    isOtpModalVisible: boolean;
    isSubmittingOtp: boolean;
    phone: PhoneIn;
    submitBlocked: boolean;
    type: 'email' | 'phone';
}

const initialState: IOtpModalState = {
    email: '',
    error: undefined,
    isOtpModalVisible: false,
    isSubmittingOtp: false,
    phone: {
        dialCode: '',
        number: '',
    },
    submitBlocked: false,
    type: 'email',
};

const otpModalSlice = createSlice({
    name: 'otpModal',
    initialState,
    reducers: {
        clearErrorMessage(state: IOtpModalState) {
            state.error = undefined;
        },
        hideOtpModal(state: IOtpModalState): void {
            state.isOtpModalVisible = false;
            state.submitBlocked = false;
            state.error = undefined;
        },
        setEmail(state: IOtpModalState, action: PayloadAction<string>): void {
            state.email = action.payload;
        },
        setError(state: IOtpModalState, action: PayloadAction<IError>): void {
            state.error = action.payload;
        },
        setIsSubmitingOtp(state: IOtpModalState, action: PayloadAction<boolean>): void {
            state.isSubmittingOtp = action.payload;
        },
        setPhone(state: IOtpModalState, action: PayloadAction<PhoneIn>): void {
            state.phone = action.payload;
        },
        setSubmitBlocked(state: IOtpModalState): void {
            state.submitBlocked = true;
        },
        showOtpModal(state: IOtpModalState, action: PayloadAction<'email' | 'phone'>): void {
            state.type = action.payload;
            state.isOtpModalVisible = true;
        },
    },
});

export const { clearErrorMessage, hideOtpModal } = otpModalSlice.actions;

export const { reducer } = otpModalSlice;

export default otpModalSlice;
