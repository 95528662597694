import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import { UserContactsHelper } from '../../utils/user-contacts-helper';
import { resetSendSuccessful, saveNewUserContact } from '../../slices/user-contacts';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import ConfirmActionModal, { ConfirmActionModalActionType } from '../../components/ConfirmActionModal';
import ContactEmailModal from '../../components/contacts/ContactEmailModal';
import ContactPhoneModal from '../../components/contacts/ContactPhoneModal';
import { EmailResponse, PhoneResponse } from 'contacts-service-api';
import Page from '../../components/shared/Page';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import UserContactPhoneNumberList from '../../components/contacts/UserContactPhoneNumberList';
import UserContactEmailList from '../../components/contacts/UserContactEmailList';
import { PrivateRoutes } from '../../routes';
import { getDialCodes } from '../../slices/phone-verifier';

const NewUserContactsPage: FC = () => {
    const [ hasUnsavedData, setHasUnsavedData ] = useState<boolean>(false);
    const [ showConfirmModal, setShowConfirmModal ] = useState<boolean>(false);
    const [ emails, setEmails ] = useState<EmailResponse[]>([]);
    const [ username, setUsername ] = useState<string>('');
    const [ phoneNumbers, setPhoneNumbers ] = useState<PhoneResponse[]>([]);
    const [ isEmailModalOpen, setIsEmailModalOpen ] = useState<boolean>(false);
    const [ isPhoneModalOpen, setIsPhoneModalOpen ] = useState<boolean>(false);
    const [ emailToDelete, setEmailToDelete ] = useState<string>('');
    const [ phoneNumberToDelete, setPhoneNumberToDelete ] = useState<string>('');
    const [ confirmModalActionType, setConfirmModalActionType ] = useState<ConfirmActionModalActionType>(ConfirmActionModalActionType.DELETE_CONTACT_DATA_EMAIL);
    const [ editedEmail, setEditedEmail ] = useState<EmailResponse>({} as EmailResponse);
    const [ editedPhoneNumber, setEditedPhoneNumber ] = useState<PhoneResponse>({} as PhoneResponse);
    const { sendSuccessful, isLoading } = useAppSelector((state) => state.userContacts);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();


    useEffect(() => {
        dispatch(resetSendSuccessful());
        dispatch(getDialCodes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setHasUnsavedData(username.length > 0 || emails.length > 0 || phoneNumbers.length > 0);
        setConfirmModalActionType(ConfirmActionModalActionType.UNSAVED_DATA);
    }, [ username, emails, phoneNumbers ]);

    const addNewEmailModalConfirmHandler = (email: EmailResponse) => {
        !editedEmail?.email ? addNewEmailHandler(email) : editExistingEmailHandler(email);
    };

    const addNewEmailHandler = (email: EmailResponse) => {
        setEmails((prevState) => [ ...prevState, email ]);
        addNewEmailModalCloseHandler();
    };

    const editExistingEmailHandler = (email: EmailResponse) => {
        const editedEmailIndex = emails.findIndex(value => value.email === editedEmail?.email);
        emails.splice(editedEmailIndex, 1, email);
        setEmails(emails);
        addNewEmailModalCloseHandler();
    };

    const addNewEmailModalCloseHandler = () => {
        setIsEmailModalOpen(false);
        setEditedEmail({} as EmailResponse);
    };

    const addNewPhoneModalConfirmHandler = (phoneNumber: PhoneResponse) => {
        !editedPhoneNumber ? addNewPhoneNumberHandler(phoneNumber) : editExistingPhoneNumberHandler(phoneNumber);
    };

    const addNewPhoneNumberHandler = (phoneNumber: PhoneResponse) => {
        setPhoneNumbers((prevState) => [ ...prevState, phoneNumber ]);
        addNewPhoneModalCloseHandler();
    };

    const editExistingPhoneNumberHandler = (phoneNumber: PhoneResponse) => {
        const editedPhoneNumberIndex = phoneNumbers.findIndex(value => value?.value?.number === editedPhoneNumber?.value?.number);
        const newPhoneNumbers = [ ...phoneNumbers.slice(0, editedPhoneNumberIndex), phoneNumber, ...phoneNumbers.slice(editedPhoneNumberIndex + 1) ];
        setPhoneNumbers(newPhoneNumbers);
        addNewPhoneModalCloseHandler();
    };

    const addNewPhoneModalCloseHandler = () => {
        setIsPhoneModalOpen(false);
        setEditedPhoneNumber({} as PhoneResponse);
    };

    const handleSaveButton = () => {
        setShowConfirmModal(false);
        setHasUnsavedData(false);
        dispatch(
            saveNewUserContact(
                UserContactsHelper.convertToContactData(username, emails, phoneNumbers)
            )
        );
        navigate(PrivateRoutes.private_contacts_route.path);
    };

    const backToUserContactListClickHandler = () => {
        if (hasUnsavedData) {
            setShowConfirmModal(true);
        } else {
            navigate(PrivateRoutes.private_contacts_route.path);
        }
    };

    const backToDashboardModalConfirmHandler = () => {
        backToDashboardModalCloseHandler();
        navigate(PrivateRoutes.private_contacts_route.path);
    };

    const backToDashboardModalCloseHandler = () => {
        setShowConfirmModal(false);
        setHasUnsavedData(false);
        dispatch(resetSendSuccessful());
    };

    const editPhoneNumberClickHandler = (phoneNumber: PhoneResponse) => {
        setEditedPhoneNumber(phoneNumber);
        setIsPhoneModalOpen(true);
    };

    const editEmailClickHandler = (email: EmailResponse) => {
        setEditedEmail(email);
        setIsEmailModalOpen(true);
    };

    const removeDataClickHandler = () => {
        confirmModalActionType === ConfirmActionModalActionType.UNSAVED_DATA
            ? backToDashboardModalConfirmHandler()
            : confirmModalActionType === ConfirmActionModalActionType.DELETE_CONTACT_DATA_EMAIL
                ? removeEmailHandler(emailToDelete)
                : removePhoneNumber(phoneNumberToDelete);
    };

    const removePhoneNumber = (phoneNumber: string) => {
        const filteredPhoneNumbers = phoneNumbers.filter(p => p.value.number !== phoneNumber);
        setPhoneNumbers(filteredPhoneNumbers);
        setShowConfirmModal(false);
        setConfirmModalActionType(ConfirmActionModalActionType.UNSAVED_DATA);
    };

    const removeEmailHandler = (email: string) => {
        const filteredEmails = emails.filter(e => e.email !== email);
        setEmails(filteredEmails);
        setShowConfirmModal(false);
        setConfirmModalActionType(ConfirmActionModalActionType.UNSAVED_DATA);
    };

    const deletePhoneNumberClickHandler = (phoneNumber: PhoneResponse) => {
        setPhoneNumberToDelete(phoneNumber.value.number);
        setConfirmModalActionType(ConfirmActionModalActionType.DELETE_CONTACT_DATA_PHONE_NUMBER);
        setShowConfirmModal(true);
    };

    const deleteEmailClickHandler = (email: EmailResponse) => {
        setEmailToDelete(email.email);
        setConfirmModalActionType(ConfirmActionModalActionType.DELETE_CONTACT_DATA_EMAIL);
        setShowConfirmModal(true);
    };

    return (

        <Page
            pageTitle={ t('dashboard.sidebar.userContacts.newContact.title') }
            actionButton={
                <CommonButton btnType={ ButtonTypeEnum.BACK } onClick={ backToUserContactListClickHandler }>
                    { t('dashboard.sidebar.userContacts.newContact.button.backToUserContactsSummary') }
                </CommonButton>
            }>
            { !sendSuccessful && (
                <>
                    <Card sx={ { mt: 3, pb: 3 } }>
                        <CardHeader title={ t('dashboard.sidebar.userContacts.newContact.username') }
                                    sx={ { mt: 3 } } />
                        <CardContent>
                            <Box sx={ { pt: 3 } }>
                                <TextField required
                                           autoFocus
                                           fullWidth
                                           label={ t('dashboard.sidebar.userContacts.newContact.username.name') }
                                           placeholder={ t('dashboard.sidebar.userContacts.newContact.username.name.placeholder') }
                                           rows={ 1 }
                                           variant='outlined'
                                           value={ username }
                                           onChange={ (e) => setUsername(e.target.value) } />
                            </Box>
                            <Grid container direction='row' spacing={ 2 }>
                                <Grid item xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } justifyContent='stretch'>
                                    <Card sx={ { mt: 3, border: 'none', boxShadow: 'none' } }>
                                        <CardHeader
                                            title={ t('dashboard.sidebar.userContacts.newContact.email') }
                                            action={
                                                <Box sx={ { m: 2, textAlign: 'center' } }>
                                                    <CommonButton btnType={ ButtonTypeEnum.ADD }
                                                                  variant={ 'outlined' }
                                                                  onClick={ (): void => setIsEmailModalOpen(true) }>
                                                        { t('dashboard.sidebar.userContacts.newContact.email.addNew') }
                                                    </CommonButton>
                                                </Box>
                                            } />
                                        <CardContent>
                                            <UserContactEmailList emails={ emails ?? [] }
                                                                  editEmailHandler={ editEmailClickHandler }
                                                                  deleteEmailHandler={ deleteEmailClickHandler } />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } justifyContent='stretch'>
                                    <Card sx={ { mt: 3, border: 'none', boxShadow: 'none' } }>
                                        <CardHeader title={ t('dashboard.sidebar.userContacts.newContact.phoneNumber') }
                                                    action={
                                                        <Box sx={ { m: 2, textAlign: 'center' } }>
                                                            <CommonButton btnType={ ButtonTypeEnum.ADD }
                                                                          variant={ 'outlined' }
                                                                          onClick={ (): void => setIsPhoneModalOpen(true) }>
                                                                { t('dashboard.sidebar.userContacts.newContact.phoneNumber.addNew') }
                                                            </CommonButton>
                                                        </Box>
                                                    } />
                                        <CardContent>
                                            <UserContactPhoneNumberList phoneNumbers={ phoneNumbers ?? [] }
                                                                        editPhoneNumberHandler={ editPhoneNumberClickHandler }
                                                                        deletePhoneNumberHandler={ deletePhoneNumberClickHandler } />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Box sx={ { mt: 6, textAlign: 'center' } }>
                                <CommonButton loading={ isLoading }
                                              btnType={ ButtonTypeEnum.SAVE }
                                              onClick={ () => handleSaveButton() }
                                              disabled={ username.length < 1 || (phoneNumbers.length < 1 && emails.length < 1) }>
                                    { t('dashboard.sidebar.userContacts.newContact.save') }
                                </CommonButton>
                            </Box>
                        </CardContent>
                    </Card>

                </> ) }

            <ConfirmActionModal actionType={ confirmModalActionType }
                                onClose={ () => setShowConfirmModal(false) }
                                onConfirm={ removeDataClickHandler }
                                open={ showConfirmModal } />

            <ContactEmailModal editedEmail={ editedEmail }
                               emails={ emails }
                               onConfirm={ addNewEmailModalConfirmHandler }
                               onClose={ addNewEmailModalCloseHandler }
                               open={ isEmailModalOpen } />

            <ContactPhoneModal editedPhoneNumber={ editedPhoneNumber }
                               phoneNumbers={ phoneNumbers }
                               onConfirm={ addNewPhoneModalConfirmHandler }
                               onClose={ addNewPhoneModalCloseHandler }
                               open={ isPhoneModalOpen } />
        </Page>
    );
};

export default NewUserContactsPage;
