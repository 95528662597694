import type { FC } from 'react';
import { useEffect } from 'react';
import { AppBar, AppBarProps, Box, experimentalStyled, IconButton, Toolbar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../../assets/img/illustrations/Identyum-SIGN-logo.svg';
import logoLight from '../../../assets/img/illustrations/Identyum-SIGN-logo-light.svg';
import { useAppDispatch } from 'store/hooks';
import Menu from '../../../assets/icons/menu';
import LanguagePopover from '../../dashboard/LanguagePopover';
import AccountPopover from '../../dashboard/AccountPopover';
import { getMemberships } from '../../../slices/groups';
import { Membership } from 'group-service-api';
import { GroupRoutes, PrivateRoutes } from '../../../routes';
import PrivateProfileSwitch from '../../dashboard/PrivateProfileSwitch';
import useSettings from '../../../hooks/useSettings';
import { THEMES } from '../../../common/constants';
import { IdentificationData } from 'profile-manager-api';
import { ApiGetDocumentsResponse } from 'document-scanner-api';

interface DashboardNavbarProps extends AppBarProps {
    activeGroup?: Membership;
    onBackToPrivateProfile?: () => void;
    onSidebarMobileOpen?: () => void;
    profileData?: ApiGetDocumentsResponse;
    userIdentificationData?: IdentificationData;
    isProfileVerified: boolean;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ( {
    ...( theme.palette.mode === 'light' && {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
        color: theme.palette.primary.contrastText
    } ),
    ...( theme.palette.mode === 'dark' && {
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${ theme.palette.divider }`,
        boxShadow: 'none'
    } ),
    zIndex: theme.zIndex.drawer + 100
} ));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
    const { settings } = useSettings();
    const {
        activeGroup,
        isProfileVerified,
        onBackToPrivateProfile,
        onSidebarMobileOpen,
        profileData,
        userIdentificationData,
        ...other
    } = props;
    const dispatch = useAppDispatch();
    const isDarkMode = settings.theme === THEMES.DARK;

    useEffect(() => {
        dispatch(getMemberships());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logoRoute = activeGroup?.group?.uuid
        ? GroupRoutes.groups_sent_documents_route.path.replace(':groupUuid', activeGroup?.group?.uuid)
        : PrivateRoutes.private_received_documents_route.path;

    return (
        <DashboardNavbarRoot { ...other }>
            <Toolbar sx={ { minHeight: 64 } }>
                <IconButton color='inherit' onClick={ onSidebarMobileOpen } sx={ { display: { lg: 'none' } } }>
                    <Menu fontSize='small' />
                </IconButton>

                <RouterLink to={ logoRoute }>
                    <Box
                        sx={ {
                            '& > img': {
                                maxHeight: '100%',
                                width: 'auto',
                                display: {
                                    lg: 'inline',
                                    xs: 'none'
                                }
                            },
                            height: 60,
                            marginLeft: '50px'
                        } }>
                        <img src={ isDarkMode ? logoLight : logo } alt='IdentyumLogo' />
                    </Box>
                </RouterLink>

                <Box sx={ { flexGrow: 1, ml: 2 } } />

                { activeGroup && <PrivateProfileSwitch onBackToPrivateProfile={ onBackToPrivateProfile } /> }

                <LanguagePopover />

                <AccountPopover activeGroup={ activeGroup }
                                isProfileVerified={ isProfileVerified }
                                profileData={ profileData }
                                userIdentificationData={ userIdentificationData } />
            </Toolbar>
        </DashboardNavbarRoot>
    );
};

export default DashboardNavbar;
