import { LabelColor } from '../components/Label';
import { Membership } from 'group-service-api';
import { ContactResponse } from 'contacts-service-api';
import {
    MemberOut,
    MemberOutRoleEnum,
    MemberOutStatusEnum,
    OrganizationOut,
    OrganizationOutStatusEnum
} from 'organizations-service-api';

export interface UsedPayables {
    finaCertsUsed: number,
    signaturesUsed: number
}

export interface IStatus {
    color: LabelColor;
    label: string;
}

export type Sort = 'status|desc' | 'status|asc' | 'lastModifiedAt|desc' | 'lastModifiedAt|asc';

export type GroupedMemberships = {
    [key: string]: Membership[];
};

export const applyPaginationOrganization = (organizations: OrganizationOut[] | undefined, page: number, limit: number): OrganizationOut[] | undefined => {
    return organizations?.slice(page * limit, page * limit + limit) ?? organizations;
};

export const applyPaginationOrganizationPersons = (requests: MemberOut[] | undefined, page: number, limit: number): MemberOut[] | undefined => {
    return requests?.slice(page * limit, page * limit + limit) ?? requests;
};


const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
    return order === 'desc'
        ? (a: MemberOut, b: MemberOut) => descendingComparator(a, b, orderBy)
        : (a: MemberOut, b: MemberOut) => -descendingComparator(a, b, orderBy);
};

const descendingComparator = (a: MemberOut, b: MemberOut, orderBy: string): number => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }

    if (b[orderBy] > a[orderBy]) {
        return 1;
    }

    return 0;
};

export const applySortByAdminRole = (persons: MemberOut[]): MemberOut[] => {
    return persons
        .sort(
            (a, b) =>
                Number(b.role === MemberOutRoleEnum.Admin) - Number(a.role === MemberOutRoleEnum.Admin)
        )
        .sort(
            (a, b) =>
                Number(b.role === MemberOutRoleEnum.Owner) - Number(a.role === MemberOutRoleEnum.Owner)
        );
};

export const applySort = (persons: MemberOut[], sort: Sort): MemberOut[] => {
    const validPersons = persons.filter(doc => doc.status && doc.lastModifiedAt);
    const [orderBy, order] = sort.split('|') as [string, 'asc' | 'desc'];
    const comparator = getComparator(order, orderBy);
    const stabilizedThis = validPersons.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newOrder = comparator(a[0], b[0]);

        if (newOrder !== 0) {
            return newOrder;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return a[1] - b[1];
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const personsWoDate = persons.filter(person => !person.lastModifiedAt);
    return stabilizedThis.map((el) => el[0]).concat(personsWoDate) as MemberOut[];
};

export const resolveOrganizationStatus = (organization: OrganizationOut): IStatus => {
    return organization?.status == null || organization?.status === OrganizationOutStatusEnum.Pending
        ? { color: 'warning', label: 'businessProfile.details.status.pending' } as IStatus
        : organization.status === OrganizationOutStatusEnum.Canceled
            ? { color: 'info', label: 'businessProfile.details.status.canceled' } as IStatus
            : { color: 'success', label: 'businessProfile.details.status.approved' } as IStatus;
};

export const groupMembershipsByOrganization = (organizations: OrganizationOut[] | undefined, memberships: Membership[] | undefined): GroupedMemberships | undefined => {
    if (!organizations || organizations.length === 0) {
        return undefined;
    }

    if (!memberships || memberships.length === 0) {
        return undefined;
    }

    const organizationNames = organizations.reduce(
        (acc, organization: OrganizationOut) => {
            return {
                ...acc,
                [organization.uuid]: organization.name
            };
        },
        {}
    );

    return memberships.reduce(
        (acc, membership) => {
            const { organizationUuid } = membership.group;
            const key = organizationUuid ? organizationNames[organizationUuid] : 'private_groups';
            const values = acc[key] ? [...acc[key], membership] : [membership];

            return {
                ...acc,
                [key]: values
            };
        },
        {
            private_groups: []
        }
    );
};

export const convertMembersToContacts = (organizationMembers: MemberOut[] | undefined): ContactResponse[] | undefined => {
    const contacts: ContactResponse[] = [];
    if (!organizationMembers || organizationMembers.length === 0) {
        return undefined;
    }
    organizationMembers
        .filter(member => member.status === MemberOutStatusEnum.Accepted)
        .map((member) => {
            const contact: ContactResponse = {
                emails: member.person.email ? [
                    {
                        uuid: '',
                        email: member?.person.email ?? '',
                        isDefault: true
                    }
                ] : undefined,
                name: member.person.fullName ?? '',
                phones: member.person.phone && member.person.phone.number ? [
                    {
                        uuid: '',
                        value: {
                            dialCode: member.person.phone?.dialCode ?? '',
                            number: member.person.phone?.number ?? ''
                        },
                        isDefault: true
                    }
                ] : undefined,
                uuid: member?.uuid ?? ''
            };
            contacts.push(contact);
        });

    return contacts;
};