export interface IError {
  error: boolean;
  errorMessage: string;
  errorType: ErrorType;
}

export enum ErrorType {
  AUTHORIZATION_ERROR,
  API_CALL_ERROR,
  INCORRECT_USER_PIN,
  PHONE_NUMBER_INVALID,
  GENERIC_ERROR,
  VALIDATION_ERROR,
  PIN_INVALID_ERROR,
  USER_EXISTS_ERROR,
  WSPAY_ERROR,
  NOT_ENOUGH_SIGNATURES,
  NOT_FOUND,
  NOT_ALLOWED,
  REQUEST_OTP_ERROR,
  SUBMIT_OTP_ERROR,
  UNPROCESSABLE_ENTITY,
  GET_EMAILS_ERROR,
  DELETE_EMAIL_ERROR,
  DELETE_PHONE_ERROR,
  SET_DEFAULT_EMAIL_ERROR,
  SET_DEFAULT_PHONE_ERROR,
  GET_PHONECODES_ERROR,
  GET_PHONES_ERROR,
}
