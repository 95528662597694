import type { FC } from 'react';
import { IMenuSection } from '../../../model/common/navigation/menu-section';
import { Box, Divider } from '@mui/material';
import NavSection from '../../navsection/NavSection';

interface ISidebarMenu {
    menu: IMenuSection[];
}

const SidebarMenu: FC<ISidebarMenu> = ({ menu }) => {
    return (
        <>
            <Divider />
            <Box sx={{ p: 2 }}>
                {menu.map((section: IMenuSection) => (
                    <NavSection key={section.title} pathname={location.pathname} {...section} sx={{ '& + &': { mt: 3 } }} />
                ))}
            </Box>
            <Divider sx={{ p: 2 }} />
        </>
    );
};

export default SidebarMenu;
