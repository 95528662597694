import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { handleError } from './errors-handling';
import {
    CreateGroupRequest,
    Group,
    GroupSimple,
    Invitation,
    Membership,
    MembershipRoleEnum,
    MembershipSimple,
    MembershipSimpleRoleEnum,
    MembershipStatusEnum,
    OrganizationGroups,
    UpdateMembershipRequest,
} from 'group-service-api';
import apis from '../auth/apis';
import { AxiosResponse } from 'axios';

interface IGroupsState {
    activeGroup: Membership | undefined;
    confirmedMemberships: Membership[] | undefined;
    creatingGroupOrganizationUuid: string | undefined;
    isAddedToGroupSuccessful: boolean;
    isCreatingPrivateGroup: boolean;
    isLoading: boolean;
    isLoadingAction: boolean;
    isLoadingGroup: boolean;
    isLoadingGroupMembership: boolean;
    isLoadingGroupRename: boolean;
    isLoadingIndex: number | undefined;
    isLoadingMemberships: boolean;
    organizationGroups: OrganizationGroups | undefined;
    pendingMemberships: Membership[] | undefined;
    selectedGroup: Group | undefined;
}

const initialState: IGroupsState = {
    activeGroup: undefined,
    confirmedMemberships: undefined,
    creatingGroupOrganizationUuid: undefined,
    isAddedToGroupSuccessful: false,
    isCreatingPrivateGroup: false,
    isLoading: false,
    isLoadingAction: false,
    isLoadingGroup: false,
    isLoadingGroupMembership: false,
    isLoadingGroupRename: false,
    isLoadingIndex: undefined,
    isLoadingMemberships: false,
    organizationGroups: undefined,
    pendingMemberships: undefined,
    selectedGroup: undefined,
};

const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        cancelMembership(state: IGroupsState, action: PayloadAction<Membership>): void {
            state.confirmedMemberships?.splice(
                state.confirmedMemberships?.findIndex((membership: Membership) => membership.uuid === action.payload.uuid),
                1
            );
            state.activeGroup = undefined;
            state.isLoadingAction = false;
        },
        cancelPendingMembership(state: IGroupsState, action: PayloadAction<Membership>): void {
            state.pendingMemberships?.splice(
                state.pendingMemberships?.findIndex((membership: Membership) => membership.uuid === action.payload.uuid),
                1
            );
            state.isLoadingAction = false;
        },
        confirmMembership(state: IGroupsState, action: PayloadAction<Membership>): void {
            state.pendingMemberships?.splice(
                state.pendingMemberships?.findIndex((membership: Membership) => membership.uuid === action.payload.uuid),
                1
            );
            state.confirmedMemberships?.push(action.payload);
            state.isLoadingAction = false;
        },
        createGroup(state: IGroupsState, action: PayloadAction<Group>): void {
            state.isLoadingAction = false;
            const group = action.payload;
            const membershipSimple = group.memberships[0];
            const membership = {
                email: membershipSimple.email,
                fullName: membershipSimple.fullName,
                group: {
                    uuid: group.uuid,
                    name: group.name,
                    organizationUuid: group.organizationUuid ?? '',
                } as GroupSimple,
                phone: membershipSimple.phone,
                role: MembershipRoleEnum.Owner,
                status: MembershipStatusEnum.Confirmed,
                userUuid: membershipSimple.userUuid,
                uuid: membershipSimple.uuid,
            } as Membership;
            state.confirmedMemberships?.push(membership);
            state.activeGroup = membership;
            state.isCreatingPrivateGroup = false;
            state.creatingGroupOrganizationUuid = undefined;
        },
        deleteGroup(state: IGroupsState, action: PayloadAction<string>): void {
            state.confirmedMemberships?.splice(
                state.confirmedMemberships?.findIndex((membership: Membership) => membership.group.uuid === action.payload),
                1
            );
            state.activeGroup = undefined;
            state.isLoadingAction = false;
        },
        getConfirmedMemberships(state: IGroupsState, action: PayloadAction<Membership[]>): void {
            state.confirmedMemberships = action.payload.filter((membership: Membership) => membership.status === MembershipStatusEnum.Confirmed);
            state.isLoadingMemberships = false;
        },
        getGroup(state: IGroupsState, action: PayloadAction<Group>): void {
            state.selectedGroup = action.payload;
            state.isLoadingGroup = false;
        },
        getPendingMemberships(state: IGroupsState, action: PayloadAction<Membership[]>): void {
            state.pendingMemberships = action.payload.filter((membership: Membership) => membership.status === MembershipStatusEnum.Pending);
            state.isLoadingMemberships = false;
        },
        giveAccessToGroup(state: IGroupsState, action: PayloadAction<MembershipSimple>): void {
            state.selectedGroup?.memberships.push(action.payload);
            state.isLoadingAction = false;
            state.isAddedToGroupSuccessful = true;
        },
        initGroupMembership(state: IGroupsState, action: PayloadAction<Membership | undefined>): void {
            state.activeGroup = action.payload;
            state.isLoadingGroupMembership = false;
        },
        renameGroup(state: IGroupsState, action: PayloadAction<GroupSimple>): void {
            if (state.selectedGroup && state.activeGroup) {
                state.selectedGroup.name = action.payload.name;
                state.activeGroup.group.name = action.payload.name;
            }
            state.isLoadingGroupRename = false;
        },
        resetActiveGroup(state: IGroupsState): void {
            state.isLoading = false;
            state.activeGroup = undefined;
            state.selectedGroup = undefined;
        },
        resetCreatingGroup(state: IGroupsState): void {
            state.isCreatingPrivateGroup = false;
            state.creatingGroupOrganizationUuid = undefined;
        },
        resetFlags(state: IGroupsState): void {
            state.isAddedToGroupSuccessful = false;
        },
        resetOrganizationGroups(state: IGroupsState): void {
            state.organizationGroups = undefined;
        },
        setActiveMembership(state: IGroupsState, action: PayloadAction<Membership>): void {
            state.isLoading = false;
            state.activeGroup = action.payload;
        },
        setIsCreatingGroup(state: IGroupsState, action: PayloadAction<CreateGroupRequest>): void {
            state.isCreatingPrivateGroup = !action.payload.organizationUuid;
            state.creatingGroupOrganizationUuid = action.payload.organizationUuid;
        },
        setLoading(state: IGroupsState, action: PayloadAction<boolean>): void {
            state.isLoading = action.payload;
        },
        setLoadingAction(state: IGroupsState, action: PayloadAction<boolean>): void {
            state.isLoadingAction = action.payload;
        },
        setLoadingGroup(state: IGroupsState, action: PayloadAction<boolean>): void {
            state.isLoadingGroup = action.payload;
        },
        setLoadingGroupMembership(state: IGroupsState, action: PayloadAction<boolean>): void {
            state.isLoadingGroupMembership = action.payload;
        },
        setLoadingGroupRename(state: IGroupsState, action: PayloadAction<boolean>): void {
            state.isLoadingGroupRename = action.payload;
        },
        setLoadingIndex(state: IGroupsState, action: PayloadAction<number | undefined>): void {
            state.isLoadingIndex = action.payload;
        },
        setLoadingMemberships(state: IGroupsState, action: PayloadAction<boolean>): void {
            state.isLoadingMemberships = action.payload;
        },
        setOrganizationGroups(state: IGroupsState, action: PayloadAction<OrganizationGroups>): void {
            state.organizationGroups = action.payload;
        },
        updateMembership(state: IGroupsState, action: PayloadAction<MembershipSimple>): void {
            if (action.payload.role === MembershipSimpleRoleEnum.Owner) {
                const currentOwner = state.selectedGroup?.memberships.find((m: MembershipSimple) => m.role === MembershipSimpleRoleEnum.Owner);

                if (currentOwner) {
                    const updatedOwner: MembershipSimple = currentOwner;
                    updatedOwner.role = MembershipSimpleRoleEnum.Admin;

                    state.selectedGroup?.memberships.splice(
                        state.selectedGroup?.memberships.findIndex((m: MembershipSimple) => m.uuid === updatedOwner.uuid),
                        1,
                        updatedOwner
                    );

                    if (state.activeGroup && state.activeGroup.role === MembershipRoleEnum.Owner) {
                        state.activeGroup.role = MembershipRoleEnum.Admin;
                    }
                }
            }

            state.selectedGroup?.memberships.splice(
                state.selectedGroup?.memberships.findIndex((m: MembershipSimple) => m.uuid === action.payload.uuid),
                1,
                action.payload
            );

            state.isLoadingAction = false;
        },
    },
});

export const updateMembership =
    (groupUuid: string, membershipUuid: string, updateMembershipRequest: UpdateMembershipRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            dispatch(groupsSlice.actions.setLoadingAction(true));
            const membership: AxiosResponse<MembershipSimple> = await apis.groupsServiceApi().changeRole(groupUuid, membershipUuid, updateMembershipRequest);
            dispatch(groupsSlice.actions.updateMembership(membership.data));
        } catch (e) {
            dispatch(handleError(e));
            dispatch(groupsSlice.actions.setLoadingAction(false));
        }
    };

export const confirmMembership =
    (membership: Membership): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(groupsSlice.actions.setLoadingAction(true));
        apis.groupsMembershipApi()
            .confirmMembership(membership.uuid)
            .then(() => {
                dispatch(groupsSlice.actions.confirmMembership(membership));
            })
            .catch((e) => {
                dispatch(groupsSlice.actions.setLoadingAction(false));
                return dispatch(handleError(e));
            });
    };

export const cancelMembership =
    (membership: Membership): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(groupsSlice.actions.setLoadingGroup(true));
        apis.groupsMembershipApi()
            .cancelMembership(membership.uuid)
            .then(() => {
                dispatch(groupsSlice.actions.cancelMembership(membership));
            })
            .catch((e) => {
                dispatch(groupsSlice.actions.setLoadingGroup(false));
                return dispatch(handleError(e));
            });
    };

export const cancelPendingMembership =
    (membership: Membership): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(groupsSlice.actions.setLoadingAction(true));
        apis.groupsMembershipApi()
            .cancelMembership(membership.uuid)
            .then(() => {
                dispatch(groupsSlice.actions.cancelPendingMembership(membership));
            })
            .catch((e) => {
                dispatch(groupsSlice.actions.setLoadingAction(false));
                return dispatch(handleError(e));
            });
    };

export const createGroup =
    (createGroupRequest: CreateGroupRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(groupsSlice.actions.setIsCreatingGroup(createGroupRequest));
        apis.groupsServiceApi()
            .createGroup(createGroupRequest)
            .then(({ data }) => {
                dispatch(groupsSlice.actions.createGroup(data));
                if (createGroupRequest.organizationUuid) {
                    dispatch(getOrganizationGroups(createGroupRequest.organizationUuid));
                }
            })
            .catch((e) => {
                dispatch(groupsSlice.actions.resetCreatingGroup());
                return dispatch(handleError(e));
            });
    };

export const deleteGroup =
    (groupUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(groupsSlice.actions.setLoadingGroup(true));
        apis.groupsServiceApi()
            .deleteGroup(groupUuid)
            .then(() => {
                dispatch(groupsSlice.actions.deleteGroup(groupUuid));
            })
            .catch((e) => {
                dispatch(groupsSlice.actions.setLoadingGroup(false));
                dispatch(handleError(e));
            });
    };

export const renameGroup =
    (groupUuid: string, groupName: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(groupsSlice.actions.setLoadingGroupRename(true));
        apis.groupsServiceApi()
            .renameGroup(groupUuid, { name: groupName })
            .then(({ data }) => {
                dispatch(groupsSlice.actions.renameGroup(data));
            })
            .catch((e) => {
                dispatch(groupsSlice.actions.setLoadingGroupRename(false));
                dispatch(handleError(e));
            });
    };

export const getGroup =
    (groupUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(groupsSlice.actions.setLoadingGroup(true));
        apis.groupsServiceApi()
            .getGroup(groupUuid)
            .then(({ data }) => {
                dispatch(groupsSlice.actions.getGroup(data));
            })
            .catch((e) => {
                dispatch(groupsSlice.actions.setLoadingGroup(false));
                return dispatch(handleError(e));
            });
    };

export const getMemberships =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(groupsSlice.actions.setLoadingMemberships(true));
        apis.groupsMembershipApi()
            .getMemberships()
            .then(({ data }) => {
                dispatch(groupsSlice.actions.getConfirmedMemberships(data));
                dispatch(groupsSlice.actions.getPendingMemberships(data));
            })
            .catch((e) => {
                dispatch(groupsSlice.actions.setLoadingMemberships(false));
                return dispatch(handleError(e));
            });
    };

export const getOrganizationGroups =
    (organizationUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(groupsSlice.actions.setLoadingMemberships(true));
        apis.groupsOrganizationsApi()
            .getOrganizationGroups(organizationUuid)
            .then(({ data }) => {
                dispatch(groupsSlice.actions.setOrganizationGroups(data));
                dispatch(groupsSlice.actions.setLoadingMemberships(false));
            })
            .catch((e) => {
                dispatch(groupsSlice.actions.setLoadingMemberships(false));
                return dispatch(handleError(e));
            });
    };

export const giveAccessToGroup =
    (groupUuid: string, invitation: Invitation, index?: number): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(groupsSlice.actions.setLoadingAction(true));
        dispatch(groupsSlice.actions.setLoadingIndex(index));
        apis.groupsServiceApi()
            .giveAccessToGroup(groupUuid, invitation)
            .then(({ data }) => {
                dispatch(groupsSlice.actions.giveAccessToGroup(data));
            })
            .catch((e) => {
                dispatch(groupsSlice.actions.setLoadingAction(false));
                return dispatch(handleError(e));
            });
    };

export const revokeAccess =
    (groupUuid: string, membershipUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            dispatch(groupsSlice.actions.setLoadingAction(true));
            await apis.groupsServiceApi().revokeAccess(groupUuid, membershipUuid);
            const group: AxiosResponse<Group> = await apis.groupsServiceApi().getGroup(groupUuid);
            dispatch(groupsSlice.actions.getGroup(group.data));
            dispatch(groupsSlice.actions.setLoadingAction(false));
        } catch (e) {
            dispatch(handleError(e));
            dispatch(groupsSlice.actions.setLoadingAction(false));
        }
    };

export const initGroupMembership =
    (groupUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            dispatch(groupsSlice.actions.setLoadingGroupMembership(true));
            const group: AxiosResponse<Group> = await apis.groupsServiceApi().getGroup(groupUuid);
            const memberships: AxiosResponse<Membership[]> = await apis.groupsMembershipApi().getMemberships();

            const groupData: Group = group?.data;
            const membershipsData: Membership[] = memberships?.data;
            const membership: Membership | undefined = membershipsData?.find((m) => m.group.uuid === groupData.uuid);

            dispatch(groupsSlice.actions.getGroup(group.data));
            dispatch(groupsSlice.actions.initGroupMembership(membership));
        } catch (e) {
            dispatch(handleError(e));
            dispatch(groupsSlice.actions.setLoadingAction(false));
        }
    };

export const { resetActiveGroup, resetFlags, resetOrganizationGroups } = groupsSlice.actions;
export const { reducer } = groupsSlice;

export default groupsSlice;
