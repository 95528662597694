import type { FC } from 'react';
import { useEffect, useState } from 'react';
import Page from '../../components/shared/Page';
import { Box, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import { createSurvey, getCreatedSurveys } from '../../slices/survey';
import {GroupRoutes, PrivateRoutes} from '../../routes';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import SkeletonBlock from '../../components/skeletons/SkeletonBlock';
import { SurveyAdmin, SurveyRequest } from 'survey-service-api';
import AddSurveyDialog from '../../components/survey/AddSurveyDialog';
import EmptyState from '../../components/empty-states/EmptyState';
import { DocumentScannerOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RolesHelper } from '../../utils/roles-helper';
import auth from '../../auth/auth';

const SurveyAdminPage: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { groupUuid } = useParams();

    const { createdSurveys, isLoading } = useAppSelector((state) => state.survey);

    const [createSurveyDialogOpened, setCreateSurveyDialogOpened] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getCreatedSurveys(groupUuid));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddSurvey = () => {
        setCreateSurveyDialogOpened(true);
    };

    const handleAddSurveyClose = (request?: SurveyRequest) => {
        if (request) {
            dispatch(createSurvey({ name: request.name, description: request.description, templatePdfBase64: request.templatePdfBase64 }));
        }
        setCreateSurveyDialogOpened(false);
    };

    const handleDetailsClick = (uuid?: string) => {
        if (groupUuid && uuid) {
            navigate(GroupRoutes.group_survey_users_results_route.path.replace(':groupUuid', groupUuid ?? '').replace(':surveyUuid', uuid ?? ''));
        } else if (uuid) {
            navigate(PrivateRoutes.private_survey_users_results_route.path.replace(':surveyUuid', uuid ?? ''));
        }
    };

    return (
        <Page
            pageTitle={t('survey.SurveyAdminPage.title')}
            pageDescription={t('survey.SurveyAdminPage.description')}
            actionButton={
                <>
                    {RolesHelper.hasSurveyCreatorRole() && (
                        <CommonButton btnType={ButtonTypeEnum.ADD} onClick={handleAddSurvey} sx={{ mb: 2 }}>
                            {t('survey.SurveyAdminPage.actionButton.add')}
                        </CommonButton>
                    )}
                </>
            }>
            {isLoading ? (
                <SkeletonBlock />
            ) : createdSurveys && createdSurveys.length > 0 ? (
                <Grid container spacing={3} direction="row">
                    {createdSurveys &&
                        createdSurveys.map((survey: SurveyAdmin) => (
                            <Grid item key={survey.uuid} lg={3} md={4} xl={3} xs={12} sm={12}>
                                <Card>
                                    <CardContent sx={{ minHeight: '250px' }}>
                                        {survey.name && (
                                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '250px' }}>
                                                <Box>
                                                    <Typography variant="h6">
                                                        {survey.name.substring(0, 100)}
                                                        {survey.name.length > 99 ? '...' : ''}
                                                    </Typography>
                                                    {survey.description && (
                                                        <Typography variant="caption">
                                                            {survey.description.substring(0, 220)}
                                                            {survey.description.length > 219 ? '...' : ''}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Box>
                                                    <Typography variant="body1" color="grey" sx={{ mt: 1 }}>
                                                        {t('survey.SurveyAdminPage.card.sharedWith')}{' '}
                                                        <strong> {survey.sharedWith.filter((value) => value !== null && value.userUuid !== auth.getUserUuid()).length}</strong>{' '}
                                                        {survey.sharedWith.filter((value) => value !== null && value.userUuid !== auth.getUserUuid()).length === 1
                                                            ? t('survey.SurveyAdminPage.card.user')
                                                            : t('survey.SurveyAdminPage.card.users')}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}
                                    </CardContent>
                                    <CardActions>
                                        <CommonButton btnType={ButtonTypeEnum.DETAILS} onClick={() => handleDetailsClick(survey.uuid)} sx={{ mr: 2 }}>
                                            {t('survey.SurveyAdminPage.cardActions.button.details')}
                                        </CommonButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                </Grid>
            ) : (
                <EmptyState message={t('survey.SurveyAdminPage.emptyState.message')} icon={<DocumentScannerOutlined />} />
            )}
            <AddSurveyDialog onClose={handleAddSurveyClose} open={createSurveyDialogOpened} />
        </Page>
    );
};

export default SurveyAdminPage;
