import type { FC } from 'react';
import { Box, Dialog, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';

interface IFinaCertificateInfoModalProps{
    onCancel: () => void;
    onClose: () => void;
    onIssueFinaCertificate: () => void;
    open: boolean;
}

const FinaCertificateInfoModal:FC<IFinaCertificateInfoModalProps> = (props) => {
    const { onCancel, onClose, onIssueFinaCertificate, open } = props;
    const { t } = useTranslation();

    return (
        <Dialog maxWidth='sm' onClose={ onClose } open={ open }>
            <Box sx={ { ml: 7, mr: 7, mt: 3, p: 3 } }>
                <Typography align='center' color='textPrimary' gutterBottom variant='h5'>
                    { t('documentDetails.modal.finaCertFinish.title') }
                </Typography>
                <Typography align='center' color='textSecondary' variant='body1'>
                    { t('documentDetails.modal.finaCertFinish.subtitle') }
                </Typography>
                <Grid container direction='row' alignItems='center' justifyContent='center' sx={{ mt: 7 }}>
                    <Grid item sx={{mr: 1}}>
                        <Box>
                            <CommonButton btnType={ButtonTypeEnum.CANCEL} onClick={() => onCancel()} >
                                {t('documentDetails.modal.finaCertFinish.action.no')}
                            </CommonButton>
                        </Box>
                    </Grid>
                    <Grid item sx={{ml: 1}}>
                        <Box>
                            <CommonButton btnType={ButtonTypeEnum.CONFIRM} onClick={() => onIssueFinaCertificate()} >
                                {t('documentDetails.modal.finaCertFinish.action.yes')}
                            </CommonButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>

    );
};

export default FinaCertificateInfoModal;
