import type { FC } from 'react';
import { Box, Typography } from '@mui/material';

interface IPaymentErrorProps {
    errorMessage: string;
}

const PaymentError: FC<IPaymentErrorProps> = ({errorMessage}) => {
    return (
        <Box sx={{ backgroundColor: '#f2f3f6', mb: 5, p: 2, whiteSpace: 'pre-line' }}>
            <Typography variant='caption' color='red'>
                {errorMessage}
            </Typography>
        </Box>
    );
};

export default PaymentError;
