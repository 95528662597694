import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateConsentStatusEnum, UserConfig, UserConsent, UserConsentStatusEnum } from 'consent-manager-api';
import { AppThunk } from '../store';
import apis from '../auth/apis';
import { handleError } from './errors-handling';

interface IConsentsState {
    userConfig: UserConfig | undefined;
    loading: boolean;
}

const initialState: IConsentsState = {
    userConfig: undefined,
    loading: true,
};

const consentsSlice = createSlice({
    name: 'consents',
    initialState,
    reducers: {
        clearUserConfig(state: IConsentsState): void {
            state.userConfig = undefined;
        },
        clientConsentUpdated(state: IConsentsState, action: PayloadAction<UserConsent>) {
            const consent = action.payload;
            const userConfig = state.userConfig;
            state.userConfig = {
                identyumConsents: userConfig?.identyumConsents ?? [],
                clientConsents:
                    userConfig?.clientConsents.map((cList) => {
                        return {
                            client: cList.client,
                            consents: cList.consents.map((c) => (c.uuid === consent.uuid ? consent : c)) ?? [],
                        };
                    }) ?? [],
            };
            state.loading = false;
        },
        identyumConsentUpdated(state: IConsentsState, action: PayloadAction<UserConsent>): void {
            const consent = action.payload;
            const userConfig = state.userConfig;
            state.userConfig = {
                identyumConsents: userConfig?.identyumConsents.map((c) => (c.uuid === consent.uuid ? consent : c)) ?? [],
                clientConsents: userConfig?.clientConsents ?? [],
            };
            state.loading = false;
        },
        setIsLoading(state: IConsentsState, action: PayloadAction<boolean>): void {
            state.loading = action.payload;
        },
        setUserConfig(state: IConsentsState, action: PayloadAction<UserConfig>): void {
            state.userConfig = action.payload;
            state.loading = false;
        },
    },
});

export const getUserConfig = (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(consentsSlice.actions.setIsLoading(true));
        apis.consentManagerApi()
            .getUserConfig()
            .then((result) => {
                dispatch(consentsSlice.actions.setUserConfig(result.data));
            })
            .catch((err) => {
                dispatch(handleError(err));
            });
    };

export const revokeIdentyumConsent = (consent: UserConsent): AppThunk =>
    async (dispatch): Promise<void> => {
        apis.consentManagerApi()
            .updateIdentyumConsent(consent.uuid, {
                status: UpdateConsentStatusEnum.Revoked,
            })
            .catch((err) => {
                dispatch(handleError(err));
            });
        dispatch(consentsSlice.actions.identyumConsentUpdated({ ...consent, status: UserConsentStatusEnum.Revoked }));
    };

export const revokeClientConsent = (consent: UserConsent): AppThunk =>
    async (dispatch): Promise<void> => {
        apis.consentManagerApi()
            .updateClientConsent(consent.uuid, {
                status: UpdateConsentStatusEnum.Revoked,
            })
            .catch((err) => {
                dispatch(handleError(err));
            });
        dispatch(consentsSlice.actions.clientConsentUpdated({ ...consent, status: UserConsentStatusEnum.Revoked }));
    };

export const { reducer } = consentsSlice;

export const { clearUserConfig } = consentsSlice.actions;
