import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Dialog, InputAdornment, Link, TextField, Typography } from '@mui/material';
import otpModalSlice, { hideOtpModal } from '../slices/otp-modal';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import CommonButton, { ButtonTypeEnum } from './shared/CommonButton';
import { ErrorType } from '../model/common/error';
import { addEmail, requestOtp } from 'slices/emails';
import { addPhone, requestPhoneOtp } from 'slices/phones';

const OtpInsertionModal: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { error, isOtpModalVisible, isSubmittingOtp, phone, submitBlocked, type, email } = useAppSelector((state) => state.otpModal);
    const [userOtp, setUserOtp] = useState<string>('');

    const OTP_LENGTH = 6;

    useEffect(() => {
        if (!isOtpModalVisible) {
            setUserOtp('');
        }
    }, [isOtpModalVisible]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value as string;
        const onlyNumbers = value.replace(/[^0-9]/g, '');
        if (error?.error && error.errorType === ErrorType.VALIDATION_ERROR) {
            handleError(false, '', ErrorType.VALIDATION_ERROR);
        }
        setUserOtp(onlyNumbers.toUpperCase());
    };

    const handleSendOtp = (): void => {
        if (userOtp === '') {
            handleError(true, 'otp.submit.emptyError', ErrorType.VALIDATION_ERROR);
            return;
        }
        if (userOtp.length < OTP_LENGTH) {
            handleError(true, 'otp.submit.lengthError', ErrorType.VALIDATION_ERROR);
            return;
        }
        if (type === 'email') {
            dispatch(addEmail(email, userOtp));
            return;
        }
        dispatch(addPhone({ phone: phone, otp: userOtp }));
    };

    const handleRequestAgain = (): void => {
        if (type === 'email') {
            dispatch(requestOtp(email));
            return;
        }
        dispatch(requestPhoneOtp(phone));
    };

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            handleSendOtp();
        }
    };

    const handleError = (error: boolean, message: string, type: ErrorType) => {
        dispatch(
            otpModalSlice.actions.setError({
                error: error,
                errorMessage: message,
                errorType: type,
            }),
        );
    };

    const closeOtpModalHandler = () => {
        dispatch(hideOtpModal());
    };

    return (
        <Dialog maxWidth={'sm'} onClose={closeOtpModalHandler} open={isOtpModalVisible}>
            <Box sx={{ p: 3 }}>
                <Typography align='center' color='textPrimary' gutterBottom variant='h5'>
                    {type === 'email' ? t('dashboard.profile.contact.otpEmail') : t('dashboard.profile.contact.otpPhone')}
                </Typography>
                <Box sx={{ mt: 3, textAlign: 'center' }}>
                    <TextField
                        error={Boolean(error?.errorMessage)}
                        helperText={Boolean(error?.errorMessage) && t(error?.errorMessage ?? '')}
                        type={'text'}
                        autoFocus
                        inputProps={{ maxLength: 6 }}
                        fullWidth
                        label={'OTP'}
                        onChange={handleChange}
                        onKeyDown={keyDownHandler}
                        placeholder={t('dashboard.profile.contact.otp.placeholder')}
                        rows={1}
                        value={userOtp}
                        variant='outlined'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    {userOtp.length} / {OTP_LENGTH}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{ mt: 3, p: 3, textAlign: 'center' }}>
                    <Link
                        onClick={handleRequestAgain}
                        sx={{ mr: 2, textAlign: 'center' }}
                        color='textSecondary'
                        variant='caption'
                    >
                        {t('otp.request.resend')}
                    </Link>
                </Box>
                <Box sx={{ mt: 3, p: 3, textAlign: 'center' }}>
                    <CommonButton btnType={ButtonTypeEnum.NO_ICON} fullWidth={true} onClick={handleSendOtp} disabled={submitBlocked || isSubmittingOtp}>
                        {t('otp.submit')}
                    </CommonButton>
                </Box>
            </Box>
        </Dialog>
    );
};

export default OtpInsertionModal;
