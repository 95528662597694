import type { FC } from 'react';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import checkMarkGreen from '../assets/img/illustrations/idy-checkmark-green.png';
import CommonButton, { ButtonTypeEnum } from './shared/CommonButton';

interface IDocumentSentMessageProps {
    buttonTitle: string;
    title: string;
    onClick: () => void;
}

const SuccessInfo: FC<IDocumentSentMessageProps> = ({ buttonTitle, title, onClick }) => {
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    return (
        <Box sx={{ alignItems: 'center' }}>
            <Typography color="textPrimary" variant="h5" sx={{ mb: 4, textAlign: 'center' }}>
                {title}
            </Typography>
            <Box sx={{ textAlign: 'center' }}>
                <img src={checkMarkGreen} alt="successfullySent" width={smUp ? '10%' : '30%'} />
            </Box>
            <Box sx={{ mb: 5, mt: 10, textAlign: 'center' }}>
                <CommonButton btnType={ButtonTypeEnum.BACK} onClick={() => onClick()}>
                    {buttonTitle}
                </CommonButton>
            </Box>
        </Box>
    );
};

export default SuccessInfo;
