import type { FC } from 'react';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import {
    Box,
    Card,
    CircularProgress,
    Grid,
    IconButton,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import Scrollbar from '../scrollbar/Scrollbar';
import { applyPaginationOrganizationPersons, applySort, applySortByAdminRole } from '../../utils/organizations-helper';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../shared/ConfirmationDialog';
import { HighlightOffRounded, ManageAccountsRounded } from '@mui/icons-material';
import { format } from 'date-fns';
import AddNewOrganizationMemberPage from '../../pages/organizations/AddNewOrganizationMemberPage';
import Label from '../Label';
import ChangeMemberRoleDialog from './ChangeMemberRoleDialog';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { MemberOut, MemberOutRoleEnum, MemberOutStatusEnum, OrganizationOutStatusEnum } from 'organizations-service-api';
import { getOrganizationMembers } from '../../slices/organizations';
import SkeletonBlock from '../skeletons/SkeletonBlock';
import auth from '../../auth/auth';

interface IOrganizationMembersProps {
    isChangingRoleLoading: boolean;
    isDeletingLoading: boolean;
    isLoadingAction: boolean;
    onDeleteMember: (uuid: string) => void;
    onChangeMemberRole: (member: MemberOut, role: MemberOutRoleEnum) => void;
}

const OrganizationMembers: FC<IOrganizationMembersProps> = (props) => {
    const {
        isChangingRoleLoading,
        isDeletingLoading,
        isLoadingAction,
        onChangeMemberRole,
        onDeleteMember
    } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();


    const { isLoadingMembers, organizationMembers, selectedOrganization } = useAppSelector(state => state.organizations);

    const [ page, setPage ] = useState<number>(0);
    const [ limit, setLimit ] = useState<number>(10);
    const [ deleteMembershipRequestModalOpen, setDeleteMembershipRequestModalOpen ] = useState<boolean>(false);
    const [ changeMemberRoleModalOpen, setChangeMemberRoleModalOpen ] = useState<boolean>(false);
    const [ memberToDelete, setMemberToDelete ] = useState<MemberOut | undefined>(undefined);
    const [ memberToChangeRole, setMemberToChangeRole ] = useState<MemberOut | undefined>(undefined);

    const userUuid = auth.getUserUuid();
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const paginatedMembers = applyPaginationOrganizationPersons(organizationMembers, page, limit);
    const sortedByAdminRoleMembers = paginatedMembers ? applySortByAdminRole(paginatedMembers) : paginatedMembers;
    const sortedMembers = sortedByAdminRoleMembers ? applySort(sortedByAdminRoleMembers, 'status|asc') : sortedByAdminRoleMembers;

    const isOrganizationAdmin = organizationMembers
        ?.filter((member: MemberOut) => member.person.userUuid === userUuid)
        ?.some(
            (member: MemberOut) =>
                ( member.role === MemberOutRoleEnum.Owner || member.role === MemberOutRoleEnum.Admin )
                && member.status === MemberOutStatusEnum.Accepted
        );

    useEffect(() => {
        if (!organizationMembers) {
            dispatch(
                getOrganizationMembers(
                    selectedOrganization?.uuid ?? ''
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value, 10));
    };

    const handleDeleteMember = (member: MemberOut) => {
        setDeleteMembershipRequestModalOpen(true);
        setMemberToDelete(member);
    };

    const handleCloseDeleteMemberRequestModal = (isDeletingRequest: boolean) => {
        isDeletingRequest && onDeleteMember(memberToDelete?.uuid ?? '');
        setDeleteMembershipRequestModalOpen(false);
    };

    const handleChangeMemberRole = (member: MemberOut) => {
        setChangeMemberRoleModalOpen(true);
        setMemberToChangeRole(member);
    };

    const handleCloseChangeMemberRoleModal = (isChangingRole: boolean, role?: MemberOutRoleEnum) => {
        isChangingRole && memberToChangeRole && role && onChangeMemberRole(memberToChangeRole, role);
        setChangeMemberRoleModalOpen(false);
    };

    const getOrganizationMemberContact = (member: MemberOut) => {
        const email = member.person.email ?? '-';
        const phone = member.person.phone && member.person.phone.dialCode && member.person.phone.number ?
            member.person.phone?.dialCode + member.person.phone?.number : '-';
        return (
            <>
                <Box>{ email }</Box>
                <Box>{ phone }</Box>
            </>
        );
    };

    const getAddMembersComponent = () => {
        if (selectedOrganization?.status !== OrganizationOutStatusEnum.Approved) {
            return (
                <Box sx={ { p: 3 } }>
                    <Typography color="textSecondary" variant="caption" sx={ { mb: 3 } }>
                        { t('businessProfile.details.label.members.add.warning.notAcceptedCompany') }
                    </Typography>
                </Box>
            );
        }
        return isOrganizationAdmin ? (
            <AddNewOrganizationMemberPage />
        ) : (
            <Box sx={ { p: 3 } }>
                <Typography color="textSecondary" variant="caption" sx={ { mb: 3 } }>
                    { t('businessProfile.details.label.members.add.warning.notAdmin') }
                </Typography>
            </Box>
        );
    };

    const getMemberActions = (member: MemberOut) => {
        return (
            <TableCell sx={ { textAlign: 'center', verticalAlign: 'middle' } }>
                { ( isDeletingLoading || isChangingRoleLoading ) && ( memberToDelete?.uuid === member.uuid || memberToChangeRole?.uuid === member.uuid ) ? (
                    <CircularProgress size="1rem" />
                ) : (
                    member.person.userUuid !== userUuid && (
                        <>
                            <Tooltip title={ t('businessProfile.list.column.actions.delete.tooltip') as string }>
                                <IconButton onClick={ () => handleDeleteMember(member) }>
                                    <HighlightOffRounded sx={ { cursor: 'pointer', ml: 1, opacity: '0.7' } } fontSize="large" />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={ t('businessProfile.list.column.actions.changeRole.tooltip') as string }>
                                <IconButton onClick={ () => handleChangeMemberRole(member) }>
                                    <ManageAccountsRounded sx={ { cursor: 'pointer', ml: 1, opacity: '0.7' } } fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </>
                    )
                ) }
            </TableCell>
        );
    };

    const getRemoveMemberConfirmationMessage = (): string => {
        return memberToDelete?.status === MemberOutStatusEnum.Accepted
            ? t('businessProfile.details.actions.deleteMembershipRequest.message.additionalWarning')
            : t('businessProfile.details.actions.deleteMembershipRequest.message', { member: memberToDelete?.person?.fullName });

    };

    return ( isLoadingMembers ? <SkeletonBlock height={ 250 } /> :
            <Card>
                <Scrollbar>
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={ 2 }>
                        <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } xl={ 12 }>
                            <Box sx={ { p: 2 } }>
                                <Box sx={ { mb: 2 } }>
                                    <Typography color="textSecondary" variant="button">
                                        { `${ t('businessProfile.details.label.members') as string } (${ selectedOrganization?.name })` }
                                    </Typography>
                                </Box>
                                <Grid container spacing={ 3 }>
                                    { organizationMembers && organizationMembers.length > 0 && (
                                        <Grid item md={ 12 } xs={ 12 }>
                                            <Scrollbar>
                                                <Box sx={ { minWidth: smUp ? 700 : 150 } }>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell sx={ { textAlign: 'center', width: '10%' } }>
                                                                    { t('businessProfile.details.members.list.column.status') }
                                                                </TableCell>
                                                                <TableCell>{ t('businessProfile.details.members.list.column.name') }</TableCell>
                                                                <TableCell>{ t('businessProfile.details.members.list.column.contact') }</TableCell>
                                                                <TableCell>{ t('businessProfile.details.members.list.column.createdAt') }</TableCell>
                                                                <TableCell>{ t('businessProfile.details.members.list.column.lastModifiedAt') }</TableCell>
                                                                { isOrganizationAdmin && (
                                                                    <TableCell sx={ { textAlign: 'center' } }>
                                                                        { t('businessProfile.details.members.list.column.actions') }
                                                                    </TableCell>
                                                                ) }
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            { sortedMembers?.map((member: MemberOut, index: number) => {
                                                                return (
                                                                    <Fragment key={ member.uuid }>
                                                                        <TableRow>
                                                                            <TableCell sx={ { textAlign: 'center' } }>
                                                                                <Label
                                                                                    style={ { width: '100%' } }
                                                                                    color={ member.status === MemberOutStatusEnum.Pending ? 'warning' : 'success' }>
                                                                                    { t(
                                                                                        member.status === MemberOutStatusEnum.Pending
                                                                                            ? 'businessProfile.details.members.list.column.status.requestSent'
                                                                                            : 'businessProfile.details.members.list.column.status.inOrganization'
                                                                                    ) }
                                                                                </Label>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                { member.person.fullName }
                                                                                { member.person.fullName &&
                                                                                  <Box>
                                                                                    <Typography color="textSecondary"
                                                                                                variant="caption">
                                                                                        { member.role === MemberOutRoleEnum.Admin ? '(Admin)' : '' }
                                                                                        { member.role === MemberOutRoleEnum.Owner ? '(Owner)' : '' }
                                                                                    </Typography>
                                                                                  </Box>
                                                                                }                                                                        </TableCell>
                                                                            <TableCell>{ getOrganizationMemberContact(member) }</TableCell>
                                                                            <TableCell>
                                                                                <Typography color="textSecondary"
                                                                                            variant="subtitle2">
                                                                                    { format(new Date(member.createdAt), 'dd.MM.yyyy.') }
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography color="textSecondary"
                                                                                            variant="subtitle2">
                                                                                    { member.lastModifiedAt && format(new Date(member.lastModifiedAt), 'dd.MM.yyyy.') }
                                                                                </Typography>
                                                                            </TableCell>
                                                                            { isOrganizationAdmin && (
                                                                                getMemberActions(member)
                                                                            ) }
                                                                        </TableRow>
                                                                        { isLoadingAction && index + 1 === sortedMembers?.length && (
                                                                            <TableRow>
                                                                                <TableCell colSpan={ 5 } align={ 'center' }>
                                                                                    <Skeleton variant="text"
                                                                                              animation={ 'wave' }
                                                                                              height={ 50 } />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ) }
                                                                    </Fragment>
                                                                );
                                                            }) }
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Scrollbar>
                                            <TablePagination
                                                component="div"
                                                count={ organizationMembers.length }
                                                labelRowsPerPage={ t('rowsPerPage') }
                                                onPageChange={ handlePageChange }
                                                onRowsPerPageChange={ handleLimitChange }
                                                page={ page }
                                                rowsPerPage={ limit }
                                                rowsPerPageOptions={ [ 10, 20, 50 ] }
                                            />
                                        </Grid>
                                    ) }
                                    <ConfirmationDialog
                                        isWarnMessage={ memberToDelete?.status === MemberOutStatusEnum.Accepted }
                                        title={ t('businessProfile.details.actions.deleteMembershipRequest.title', { member: memberToDelete?.person?.fullName }) }
                                        message={ getRemoveMemberConfirmationMessage() }
                                        onConfirm={ () => handleCloseDeleteMemberRequestModal(true) }
                                        onCancel={ () => handleCloseDeleteMemberRequestModal(false) }
                                        open={ deleteMembershipRequestModalOpen }
                                    />
                                    <ChangeMemberRoleDialog
                                        member={ memberToChangeRole }
                                        onConfirm={ (role: MemberOutRoleEnum) => handleCloseChangeMemberRoleModal(true, role) }
                                        onCancel={ () => handleCloseChangeMemberRoleModal(false) }
                                        open={ changeMemberRoleModalOpen }
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } xl={ 12 }>
                            <Box sx={ { p: 2 } }>
                                <Typography color="textSecondary" variant="button" sx={ { mb: 3 } }>
                                    { t('businessProfile.details.label.members.add') }
                                </Typography>
                                { getAddMembersComponent() }
                            </Box>
                        </Grid>
                    </Grid>
                </Scrollbar>
            </Card>
    );
};

export default OrganizationMembers;
