import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Theme,
    Typography,
    useMediaQuery
} from '@mui/material';
import { format } from 'date-fns';
import { DocumentInfo, Person } from 'user-document-signer-api';
import Scrollbar from '../scrollbar/Scrollbar';
import { CheckCircle, FilterAlt, FilterAltOff, GroupRounded, Inbox, RemoveCircle, Search } from '@mui/icons-material';
import { DocumentsHelper, Sort, sortOptions } from '../../utils/documents-helper';
import { useTranslation } from 'react-i18next';
import RecipientsModal from '../RecipientsModal';
import CertificateSelectModal from '../../pages/documents/CertificateSelectModal';
import DocumentRecipients from './DocumentRecipients';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { ISignatures } from '../../model/common/payments/ISignatures';
import FinaCertificateInfoModal from '../../pages/documents/FinaCertificateInfoModal';
import { setIssueFinaCertAutomatically } from '../../slices/payments';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { PaymentsHelper } from '../../utils/payments-helper';
import { DocumentDetailsPageUtil } from '../../pages/documents/details/DocumentDetailsPage.util';
import { DocumentVisibilityType } from '../../model/common/documents/DocumentVisibilityType';
import { toggleDocumentsVisibility } from '../../slices/documents';
import { GroupRoutes, PrivateRoutes } from '../../routes';
import EmptyState from '../empty-states/EmptyState';
import ConfirmActionModal from '../ConfirmActionModal';
import SendDocumentModal from '../SendDocumentModal';
import auth from '../../auth/auth';
import DocumentActionsPopover from './DocumentActionPopover';
import { loadingTableRow } from './DocumentList.utils';

export const visibilityActions = {
    en: {
        show: 'show',
        hide: 'hide'
    },
    hr: {
        show: 'prikazati',
        hide: 'sakriti'
    }
};

interface IDocumentListProps {
    documents: DocumentInfo[];
    received: boolean;
    isLoadingDocuments: boolean;
    isSigning: boolean;
    isTogglingDocumentVisibility?: boolean;
    onDocumentClick: (documentUuid: string) => void;
    onSignAllDocuments: (unsignedDocuments: string[]) => void;
    onFinaCertificateSelect: () => void;
    onCertificateModalClose: () => void;
    onIdentyumCertificateSelect: (unsignedDocuments: string[]) => void;
    signatures?: ISignatures;
    onFilterChange: (visibility: DocumentVisibilityType) => void;
}

const DocumentList: FC<IDocumentListProps> = (props) => {
    const {
        documents,
        isLoadingDocuments,
        isSigning,
        onDocumentClick,
        onSignAllDocuments,
        onFinaCertificateSelect,
        onIdentyumCertificateSelect,
        onCertificateModalClose,
        signatures,
        onFilterChange
    } = props;
    const [ selectedDocuments, setSelectedDocuments ] = useState<string[]>([]);
    const [ page, setPage ] = useState<number>(0);
    const [ limit, setLimit ] = useState<number>(10);
    const [ query, setQuery ] = useState<string>('');
    const [ sort, setSort ] = useState<Sort>(sortOptions[0].value);
    const [ visibility, setVisibility ] = useState<DocumentVisibilityType>(DocumentVisibilityType.VISIBLE);
    const [ recipients, setRecipients ] = useState<Person[]>([]);
    const [ isRecipientsModalOpen, setIsRecipientsModalOpen ] = useState<boolean>(false);
    const [ isSendDocumentModalOpen, setIsSendDocumentModalOpen ] = useState<boolean>(false);
    const [ documentToSend, setDocumentToSend ] = useState<DocumentInfo | undefined>(undefined);
    const [ isShowUnsignedDocuments, setIsShowUnsignedDocuments ] = useState<boolean>(false);
    const [ isFilterOpen, setIsFilterOpen ] = useState<boolean>(false);
    const [ isFinaCertModalOpen, setIsFinaCertModalOpen ] = useState<boolean>(false);
    const [ isVisibilityConfirmModalOpen, setIsVisibilityConfirmModalOpen ] = useState<boolean>(false);
    const [ docToChangeVisibility, setDocToChangeVisibility ] = useState<{
        uuid: string;
        visibility: DocumentVisibilityType
    } | undefined>(undefined);

    const { userPayables } = useAppSelector((state) => state.payments);
    const { hasValidFinaCertificate } = useAppSelector((state) => state.finaLcp);
    const idyDocumentUuidSigningList = useAppSelector((state) => state.documents.documentUuidSigningList);
    const finaDocumentUuidSigningList = useAppSelector((state) => state.finaLcp.documentUuidSigningList);
    const { activeGroup } = useAppSelector((state) => state.groups);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const hasFinaCertPayable: boolean = PaymentsHelper.hasFinaCertPayable(userPayables);
    const userUuid = auth.getUserUuid();

    useEffect(() => {
        setSelectedDocuments([]);
        if (unsignedDocumentsByCurrentUser.length === 0) {
            setIsShowUnsignedDocuments(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ documents ]);

    const canUseOrganizationSignatures = activeGroup?.group.organizationUuid;
    const signaturesAvailable = ( signatures?.freeSignatures ?? 0 ) + ( signatures?.paidSignatures ?? 0 );

    const unsignedDocumentsByCurrentUser = useMemo(
        () => documents.filter((doc: DocumentInfo) =>
            !DocumentsHelper.isSignedByCurrentUser(doc) // dokumenti koje korisnik još nije potpisao
            // dokumenti za koje se traži korisnikov potpis (u grupama se korisnik ne nalazi u sharedWith zato na početku stoji uvjet --> !activeGroup?.group.uuid )
            && ( !activeGroup?.group.uuid ? DocumentDetailsPageUtil.needSignatureForUser(doc, userUuid) : true )
        ), [ documents, userUuid, activeGroup?.group.uuid ]);

    const unsignedDocuments = isShowUnsignedDocuments ? unsignedDocumentsByCurrentUser : documents;

    const paginatedDocuments = useMemo(
        () => DocumentsHelper.sortFilterAndPaginateDocuments(unsignedDocuments, query, sort, page, limit),
        [ unsignedDocuments, query, sort, page, limit ]);

    const visibleDocuments = useMemo(
        () => DocumentsHelper.filterDocumentsByVisibility(paginatedDocuments, visibility, userUuid, activeGroup?.group.uuid),
        [ paginatedDocuments, visibility, userUuid, activeGroup?.group.uuid ]);

    const enableBulkActions = selectedDocuments.length > 0;

    const handleSelectOneDocument = (_event: ChangeEvent<HTMLInputElement>, documentUuid: string): void => {
        if (!selectedDocuments.includes(documentUuid)) {
            setSelectedDocuments((prevSelected) => [ ...prevSelected, documentUuid ]);
        } else {
            setSelectedDocuments((prevSelected) => prevSelected.filter((uuid) => uuid !== documentUuid));
        }
    };

    const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value, 10));
    };

    const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setQuery(event.target.value);
    };

    const handleSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setSort(event.target.value as Sort);
    };

    const handleVisibilityChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setVisibility(event.target.value as DocumentVisibilityType);
        onFilterChange(event.target.value as DocumentVisibilityType);
        setPage(0);
    };

    const signDocumentHandler = () => {
        if (hasFinaCertPayable && !hasValidFinaCertificate) {
            setIsFinaCertModalOpen(true);
        } else {
            signAllDocuments();
        }
    };

    const signAllDocuments = () => {
        const unsignedDocuments: string[] = documents
            .filter((document) => selectedDocuments.includes(document.uuid) && !DocumentsHelper.isSignedByCurrentUser(document))
            .map((doc) => doc.uuid);
        onSignAllDocuments(unsignedDocuments);
    };

    const isShowUnsignedDocumentsToggleHandler = () => {
        setIsShowUnsignedDocuments((prevValue) => !prevValue);
    };

    const showAllRecipientsClickHandler = (personsSigning: Person[]) => {
        setIsRecipientsModalOpen(true);
        setRecipients(personsSigning);
    };

    const recipientsModalCloseHandler = () => {
        setIsRecipientsModalOpen(false);
        setDocumentToSend(undefined);
    };

    const sendDocumentModalOpenHandler = (selectedDocuments: DocumentInfo) => {
        setIsSendDocumentModalOpen(true);
        setDocumentToSend(selectedDocuments);
    };

    const sendDocumentModalCloseHandler = () => {
        setIsSendDocumentModalOpen(false);
    };

    const identyumCertificateSelectHandler = () => {
        const unsignedDocuments: string[] = documents
            .filter((document) => selectedDocuments.includes(document.uuid) && !DocumentsHelper.isSignedByCurrentUser(document))
            .map((doc) => doc.uuid);
        onIdentyumCertificateSelect(unsignedDocuments);
    };

    const filterOpenHandle = () => {
        setIsFilterOpen((prevState) => !prevState);
    };

    const shouldDisableSelectingDocument = (document: DocumentInfo) => {
        // disable checkbox if user has already selected same number of documents as there are signatures available
        // but don't disable checkboxes for previously selected documents
        return selectedDocuments.indexOf(document.uuid) === -1 && ( signaturesAvailable ? signaturesAvailable <= selectedDocuments.length : false );
    };

    const handleFinaIssueCertificate = () => {
        setIsFinaCertModalOpen(false);
        dispatch(setIssueFinaCertAutomatically(true));
        navigate(PrivateRoutes.private_shop_route.path);
    };

    const handleFinaCertIssueCancel = () => {
        setIsFinaCertModalOpen(false);
        signAllDocuments();
    };

    const toggleDocumentVisibilityHandler = (document: DocumentInfo, visibility: DocumentVisibilityType) => {
        setIsVisibilityConfirmModalOpen(true);
        setDocToChangeVisibility({ uuid: document.uuid, visibility: visibility });
    };

    const confirmVisibilityActionHandler = () => {
        docToChangeVisibility?.uuid &&
        dispatch(
            toggleDocumentsVisibility(
                docToChangeVisibility?.uuid ?? '',
                docToChangeVisibility?.visibility,
                activeGroup?.group.uuid
            )
        );
        setIsVisibilityConfirmModalOpen(false);
        setDocToChangeVisibility(undefined);
    };

    const isSignInProgress = (document: DocumentInfo) => {
        return idyDocumentUuidSigningList?.includes(document.uuid) || finaDocumentUuidSigningList?.includes(document.uuid);
    };

    const newDocumentClickHandler = () => {
        if (!activeGroup?.group.uuid) {
            navigate(PrivateRoutes.private_new_document_route.path);
        }

        if (activeGroup?.group.uuid) {
            navigate(GroupRoutes.groups_new_document_route.path.replace(':groupUuid', activeGroup?.group?.uuid ?? ''));
        }
    };

    const showFilters = () => {
        return (
            <Grid container direction="row" alignItems="ceter" justifyContent="space-between" spacing={ 2 }
                  sx={ { p: 2 } }>
                <Grid item sm={ 6 } md={ 4 } lg={ 4 } sx={ { width: '100%' } }>
                    <TextField fullWidth
                               label={ t('dashboard.sidebar.documents.list.sort.by') }
                               name="sort"
                               onChange={ handleSortChange }
                               select
                               SelectProps={ { native: true } }
                               value={ sort }
                               variant="outlined"
                               size="small">
                        { sortOptions.map((option) => (
                            <option key={ option.value } value={ option.value }>
                                { t(option.label) }
                            </option>
                        )) }
                    </TextField>
                </Grid>
                <Grid item sm={ 6 } md={ 4 } lg={ 4 } sx={ { width: '100%' } }>
                    <TextField fullWidth
                               label={ t('dashboard.sidebar.documents.list.visibility') }
                               name="showDocuments"
                               onChange={ handleVisibilityChange }
                               select
                               SelectProps={ { native: true } }
                               value={ visibility }
                               variant="outlined"
                               size="small">
                        <option key={ DocumentVisibilityType.ALL_DOCS } value={ DocumentVisibilityType.ALL_DOCS }>
                            { t('dashboard.sidebar.documents.list.visibility.all') }
                        </option>
                        <option key={ DocumentVisibilityType.VISIBLE } value={ DocumentVisibilityType.VISIBLE }>
                            { t('dashboard.sidebar.documents.list.visibility.visible') }
                        </option>
                        <option key={ DocumentVisibilityType.HIDDEN } value={ DocumentVisibilityType.HIDDEN }>
                            { t('dashboard.sidebar.documents.list.visibility.hidden') }
                        </option>
                    </TextField>
                </Grid>
                <Grid item sm={ 6 } md={ 4 } lg={ 4 } sx={ { width: '100%' } }>
                    <FormGroup sx={ { ml: 1 } }>
                        <FormControlLabel
                            control={
                                <Switch checked={ isShowUnsignedDocuments }
                                        color="primary"
                                        edge="start"
                                        name="isVerified"
                                        onChange={ () => isShowUnsignedDocumentsToggleHandler() }
                                        value={ isShowUnsignedDocuments }
                                />
                            }
                            label={ t('dashboard.sidebar.documents.list.filter.unsigned') as string }
                        />
                    </FormGroup>
                </Grid>
            </Grid>
        );
    };

    const showList = () => {
        if (visibleDocuments.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={ 6 } align={ 'center' }>
                        <EmptyState message={ t('emptyState.noDocuments.title') }
                                    subtitle={ t('emptyState.noDocuments.subtitle') }
                                    icon={ <Inbox /> } />
                        <Box sx={ { mb: 5, textAlign: 'center' } }>
                            <CommonButton btnType={ ButtonTypeEnum.ADD }
                                          onClick={ newDocumentClickHandler }>
                                { t('dashboard.sidebar.documents.summary.uploadNewDocument') }
                            </CommonButton>
                        </Box>
                    </TableCell>
                </TableRow>
            );
        }

        return visibleDocuments.map((document: DocumentInfo, index: number) => {
            const isDocumentSelected = selectedDocuments.includes(document.uuid);
            return (
                isSignInProgress(document) ? loadingTableRow(smUp) :
                    <TableRow hover key={ index }
                              selected={ isDocumentSelected && !DocumentsHelper.isSignedByCurrentUser(document) }>
                        <TableCell padding="checkbox">
                            { !DocumentsHelper.isSignedByCurrentUser(document) &&
                                ( DocumentDetailsPageUtil.needSignatureForUser(document, userUuid) || !!activeGroup?.group?.uuid ) &&
                                ( signaturesAvailable > 0 || canUseOrganizationSignatures ) && (
                                    <Checkbox checked={ isDocumentSelected }
                                              color="primary"
                                              onChange={ (event) => handleSelectOneDocument(event, document.uuid) }
                                              value={ isDocumentSelected }
                                              disabled={ shouldDisableSelectingDocument(document) } />
                                ) }
                        </TableCell>

                        <TableCell onClick={ () => onDocumentClick(document.uuid) }>
                            { document.title.defaultText }
                        </TableCell>

                        <TableCell onClick={ () => onDocumentClick(document.uuid) }>
                            { document.updatedAt && (
                                <Box sx={ { display: 'flex' } }>
                                    <Typography color="textSecondary" variant="subtitle2">
                                        { format(new Date(document.updatedAt), 'dd.MM.yyyy.') }
                                    </Typography>
                                </Box>
                            ) }
                        </TableCell>

                        { smUp && !activeGroup?.group?.uuid &&
                          <TableCell sx={ { textAlign: 'center' } } onClick={ () => onDocumentClick(document.uuid) }>
                              {
                                  isSignInProgress(document)
                                      ? <CircularProgress color="info" size={ 20 } />
                                      : <Grid container item direction="row" key={ index } sx={ { flexWrap: 'nowrap' } }>
                                          <Typography variant="body2" sx={ { mr: 1 } }>
                                              { document?.owner?.isGroup || document?.owner?.group
                                                  ? <GroupRounded fontSize="small" sx={ { opacity: 0.5 } } />
                                                  : document?.owner?.signatureInfo?.signed
                                                      ? <CheckCircle fontSize="small" color="primary" />
                                                      : <RemoveCircle fontSize="small" sx={ { opacity: 0.5 } } /> }
                                          </Typography>

                                          <Typography color={ 'textSecondary' } variant="body2">
                                              { DocumentsHelper.getDocumentSignerDataUDS(document?.owner) }
                                          </Typography>
                                      </Grid>
                              }
                          </TableCell>
                        }

                        { smUp && (
                            <TableCell>
                                <DocumentRecipients document={ document }
                                                    enableBulkActions={ enableBulkActions }
                                                    showAllRecipientsClickHandler={ showAllRecipientsClickHandler } />
                            </TableCell>
                        ) }

                        <TableCell sx={ { pr: 3, textAlign: 'right' } }>
                            { !enableBulkActions &&
                              <DocumentActionsPopover document={ document }
                                                      onDocumentVisibilityChange={ toggleDocumentVisibilityHandler }
                                                      onSendDocumentNotification={ sendDocumentModalOpenHandler }
                                                      onSignDocument={ (documentUuid) => onSignAllDocuments([ documentUuid ]) }
                                                      onViewDocumentDetails={ (document: DocumentInfo) => onDocumentClick(document.uuid) } />
                            }
                        </TableCell>
                    </TableRow>
            );
        });
    };

    return (
        <>
            <Box sx={ { display: 'flex', justifyContent: 'space-between', p: 2 } }>
                <TextField fullWidth
                           InputProps={ {
                               startAdornment: (
                                   <InputAdornment position="start">
                                       <Search fontSize="small" />
                                   </InputAdornment>
                               )
                           } }
                           onChange={ handleQueryChange }
                           placeholder={ t('dashboard.sidebar.documents.list.search.placeholder') }
                           value={ query }
                           variant="outlined"
                           size="small"
                />
                <IconButton onClick={ filterOpenHandle }>
                    { isFilterOpen ? <FilterAltOff /> : <FilterAlt /> }
                </IconButton>
            </Box>

            { isFilterOpen && showFilters() }

            { documents.length > 0 && (
                <Box sx={ { textAlign: smUp ? 'right' : 'left' } }>
                    <CommonButton sx={ { ml: 2 } }
                                  btnType={ ButtonTypeEnum.SIGN }
                                  onClick={ () => signDocumentHandler() }
                                  disabled={ isSigning || !enableBulkActions }
                                  loading={ isSigning }>
                        { isSigning
                            ? t('dashboard.sidebar.documents.list.signAllDocuments.inProgress')
                            : t('dashboard.sidebar.documents.list.signAllDocuments') }
                    </CommonButton>
                </Box>
            ) }
            <Scrollbar>
                <Box sx={ { minWidth: smUp ? 700 : 150 } }>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" />
                                <TableCell>{ t('dashboard.sidebar.documents.list.table.column.documentTitle') }</TableCell>
                                <TableCell>{ t('dashboard.sidebar.documents.list.table.column.date') }</TableCell>
                                { smUp && !activeGroup?.group?.uuid &&
                                  <TableCell>
                                      { t('dashboard.sidebar.documents.list.table.column.uploadedBy') }
                                  </TableCell>
                                }
                                { smUp &&
                                  <TableCell>
                                      { t('dashboard.sidebar.documents.list.table.column.recipients') }
                                  </TableCell>
                                }
                                <TableCell sx={ { pr: 5, textAlign: 'right' } }>
                                    { smUp && t('dashboard.sidebar.documents.list.table.column.actions') }
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            { isLoadingDocuments ? loadingTableRow(smUp) : showList() }
                        </TableBody>
                    </Table>
                </Box>
            </Scrollbar>
            <TablePagination component="div"
                             count={ documents.length }
                             labelRowsPerPage={ t('dashboard.sidebar.documents.list.rowsPerPage') }
                             onPageChange={ handlePageChange }
                             onRowsPerPageChange={ handleLimitChange }
                             page={ page }
                             rowsPerPage={ limit }
                             rowsPerPageOptions={ [ 10, 20, 50 ] } />

            <RecipientsModal recipients={ recipients }
                             open={ isRecipientsModalOpen }
                             onClose={ recipientsModalCloseHandler } />

            <SendDocumentModal documentUuid={ documentToSend?.uuid ?? '' }
                               recipients={ documentToSend?.sharedWith?.filter((p: Person) => p.signatureInfo?.signatureRequested && !p.signatureInfo?.signed && p.uuid !== userUuid) ?? [] }
                               open={ isSendDocumentModalOpen }
                               onClose={ sendDocumentModalCloseHandler } />

            <CertificateSelectModal onClose={ onCertificateModalClose }
                                    onIdentyumCertificateSelect={ identyumCertificateSelectHandler }
                                    onFinaCertificateSelect={ onFinaCertificateSelect } />

            <FinaCertificateInfoModal onCancel={ handleFinaCertIssueCancel }
                                      onClose={ () => setIsFinaCertModalOpen(false) }
                                      onIssueFinaCertificate={ handleFinaIssueCertificate }
                                      open={ isFinaCertModalOpen } />

            <ConfirmActionModal onConfirm={ confirmVisibilityActionHandler }
                                onClose={ () => setIsVisibilityConfirmModalOpen(false) }
                                open={ isVisibilityConfirmModalOpen }
                                title={ t('businessProfile.details.documents.action.visibility.youSure.title', {
                                    action:
                                        docToChangeVisibility?.visibility === DocumentVisibilityType.VISIBLE
                                            ? visibilityActions[i18n.language]?.show ?? ''
                                            : visibilityActions[i18n.language]?.hide ?? ''
                                }) } />
        </>
    );
};

export default DocumentList;
