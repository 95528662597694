import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/loader/loader';
import { useTranslation } from 'react-i18next';
import auth from '../../auth/auth';
import { Typography } from '@mui/material';
import { Misc } from '../../utils/misc';
import { toast } from 'react-hot-toast';

export const RedirectPage: FC = () => {
      const navigate = useNavigate();
      const { i18n, t } = useTranslation();
      const [ showLoader, setShowLoader ] = useState(true);
      const [ error, setError ] = useState<string>('');

      if (error) {
        error === 'access_denied' && toast.error(t('login.error.accessDenied'));
        navigate('/login', { replace: true });
      }

      useEffect(() => {
        (async () => {
          // check for any errors
          const searchParams = new URLSearchParams(window.location.search);
          const error = searchParams.get('error');
          if (error) {
            setShowLoader(false);
            setError(error);
            return;
          }

          auth.setOrganizationUuid('');

          const idyLanguage = Misc.checkForIdyLanguage();
          idyLanguage && await i18n.changeLanguage(idyLanguage);
          auth.setIdyLanguage(idyLanguage);

          const redirectLink = localStorage.getItem('redirectLink') as string;
          setShowLoader(false);
          navigate(redirectLink ? redirectLink : '/dashboard/documents/received', { replace: true });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return (
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Loader appear={ showLoader } />
            <Typography variant="h4" component="h4" gutterBottom>
              { t('login.loading') }
            </Typography>
          </Grid>
      );
    }
;
