import type { FC } from 'react';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import finaLogo from '../../assets/img/illustrations/FINA_logo.svg';
import { useTranslation } from 'react-i18next';
import useSettings from '../../hooks/useSettings';
import { THEMES } from '../../common/constants';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';

interface IFinaFinishProps {
    onBackToDocuments: () => void;
}

const FinaFinish: FC<IFinaFinishProps> = ({ onBackToDocuments }) => {
    const { t } = useTranslation();
    const { settings } = useSettings();

    const isDarkMode = settings.theme === THEMES.DARK;
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Box sx={{ mt: 3, p: 3, textAlign: 'center' }}>
            <Box sx={{ backgroundColor: isDarkMode ? '#f4f5f7' : 'background.paper' }}>
                <img src={finaLogo} alt='finaLogo' />
            </Box>
            <Typography sx={{ p: 2 }} color='textSecondary' variant='body2'>
                {t('dashboard.profile.general.finaLcp.newCert.finished')}
            </Typography>
            <Box sx={{ mt: 7, pl: !mdUp ? 3 : 0, pr: !mdUp ? 3 : 0, textAlign: 'center' }}>
                <CommonButton
                    fullWidth={!mdUp}
                    btnType={ButtonTypeEnum.NO_ICON_CONTAINED}
                    onClick={onBackToDocuments}>
                    {t('dashboard.profile.general.finaLcp.newCert.finished.button')}
                </CommonButton>
            </Box>
        </Box>
    );
};

export default FinaFinish;
