import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addMembersToOrganization, clearMemberSuccessful, getOrganizationMembers } from '../../slices/organizations';
import AddEmailOrPhoneNumber from '../../components/receivers/AddEmailOrPhoneNumber';
import { MemberIn, MemberInRoleEnum, MemberOut, Phone } from 'organizations-service-api';
import { IPhone } from '../../model/common/documents/document-user';
import { IContact } from '../../components/ContactsChooserModal';
import OrganizationMemberPanel from './OrganizationMemberPanel';

const AddNewOrganizationMemberPage: FC = () => {
    const dispatch = useAppDispatch();
    const [ newMembers, setNewMembers ] = useState<MemberIn[]>([]);
    const { isAddingMember, isMembersSuccessfulAdded, organizationMembers, selectedOrganization } = useAppSelector((state) => state.organizations);

    const existingEmails = organizationMembers
        ?.filter((member: MemberOut) => member.person.email)
        ?.map((member: MemberOut) => member.person.email as string) ?? [];

    const newMembersEmails = newMembers
        .filter((member: MemberIn) => member.email)
        .map((member: MemberIn) => member.email as string);

    const existingPhones = organizationMembers
        ?.filter((member: MemberOut) => member.person.phone)
        ?.map((member: MemberOut) => {
            const phone = member.person.phone as Phone;
            return {
                dialCode: phone.dialCode,
                number: phone.number
            } as IPhone;
        }) ?? [];

    const newMembersPhones = newMembers
        .filter((member: MemberIn) => member.phone)
        .map((member: MemberIn) => {
            const phone = member.phone as Phone;
            return {
                dialCode: phone.dialCode,
                number: phone.number
            } as IPhone;
        });

    useEffect(() => {
        if (isMembersSuccessfulAdded) {
            setNewMembers([]);
            dispatch(clearMemberSuccessful());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isMembersSuccessfulAdded ]);

    useEffect(() => {
        if (!organizationMembers) {
            dispatch(getOrganizationMembers(selectedOrganization?.uuid ?? ''));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddNewMember = () => {
        dispatch(addMembersToOrganization(selectedOrganization?.uuid ?? '', newMembers));
    };

    const handleRemoveMember = (member: MemberIn) => {
        setNewMembers((prevState: MemberIn[]) => prevState
            .filter((m: MemberIn) => {
                    return member.email ?
                        m.email !== member.email :
                        m.phone?.dialCode !== member.phone?.dialCode && m.phone?.number !== member.phone?.number;
                }
            )
        );
    };

    const onAddContacts = (contacts: IContact[]) => {
        const newMembers = contacts
            .filter((contact) =>
                !existingEmails.includes(contact.email) &&
                !existingPhones.some((p) => p.dialCode === contact.phone?.dialCode && p.number === contact.phone?.number)
            )?.map((contact) => {
                return {
                    email: contact.email,
                    phone: contact.phone,
                    role: MemberInRoleEnum.Writer
                } as MemberIn;
            });
        setNewMembers(prevState => [ ...prevState, ...newMembers ]);
    };

    const onAddEmails = (emails: string[]) => {
        const newMembers = emails
            .filter((email) => !existingEmails.includes(email))
            ?.map((email) => {
                return {
                    email: email,
                    role: MemberInRoleEnum.Writer
                } as MemberIn;
            });
        setNewMembers(prevState => [ ...prevState, ...newMembers ]);
    };

    const onAddPhones = (phones: IPhone[]) => {
        const newMembers = phones
            .filter((phone) => !existingPhones.some((p) => p.dialCode === phone.dialCode && p.number === phone.number))
            ?.map((phone) => {
                return {
                    phone: {
                        dialCode: phone.dialCode,
                        number: phone.number
                    },
                    role: MemberInRoleEnum.Writer
                } as MemberIn;
            });
        setNewMembers(prevState => [ ...prevState, ...newMembers ]);
    };

    return (
        <Box sx={ { p: 2 } }>
            <AddEmailOrPhoneNumber existingEmails={ [ ...existingEmails, ...newMembersEmails ] }
                                   existingPhones={ [ ...existingPhones, ...newMembersPhones ] }
                                   onAddContacts={ onAddContacts }
                                   onAddEmails={ onAddEmails }
                                   onAddPhones={ onAddPhones }
                                   skipAddingToPanel={ true } />

            <OrganizationMemberPanel isAddingMember={ isAddingMember }
                                     members={ newMembers }
                                     onAddMembers={ handleAddNewMember }
                                     onRemoveMember={ handleRemoveMember } />
        </Box>
    );
};

export default AddNewOrganizationMemberPage;
