import type { FC } from 'react';
import { Box, Dialog, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QRCode } from 'react-qrcode-logo';
import html2canvas from 'html2canvas';
import logo from '../assets/img/illustrations/Identyum-SIGN-logo.svg';
import { useTheme } from '@material-ui/core/styles';
import CommonButton, { ButtonTypeEnum } from './shared/CommonButton';

interface QrCodeModalProps {
    documentTitle?: string;
    onClose: () => void;
    open: boolean;
}

const QrCodeModal: FC<QrCodeModalProps> = (props) => {
    const { open, onClose, documentTitle } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    const downloadQrCodeHandler = () => {
        html2canvas(document.querySelector('#react-qrcode-logo') as HTMLCanvasElement)
            .then(function(canvas) {
                const link = document.createElement('a');
                link.download = `${ documentTitle ? documentTitle.split('.')[0].trim() : 'identyum-now-document-details' }.png`;
                link.href = canvas.toDataURL();
                link.click();
            });
    };

    return (
        <Dialog maxWidth='lg' open={ open } onClose={ onClose }>
            <Box sx={ { ml: 7, mr: 7, mt: 5 } }>
                <Typography align='center' color='textPrimary' gutterBottom variant='h5'>
                    { t('dashboard.sidebar.documents.qrCodeModal.title') }
                </Typography>
                <Typography align='center' color='textSecondary' variant='body1'>
                    { t('dashboard.sidebar.documents.qrCodeModal.subTitle') }
                </Typography>
                <Box sx={ { mt: 3, textAlign: 'center' } }>
                    <QRCode eyeRadius={ 5 }
                            fgColor={ theme.palette.primary.main }
                            logoImage={ logo }
                            logoWidth={ mdUp ? 180 : 60 }
                            logoHeight={ mdUp ? 90 : 30 }
                            qrStyle='dots'
                            size={ mdUp ? 256 : 128 }
                            value={ window.location.href } />
                </Box>
            </Box>
            <Box sx={ { mb: 5, textAlign: 'center' } }>
                <CommonButton btnType={ ButtonTypeEnum.NO_ICON }
                              onClick={ () => onClose() }
                              sx={ { p: 1, m: 1, width: smUp ? '25%' : '50%' } }>
                    { t('dashboard.sidebar.documents.qrCodeModal.button.close') }
                </CommonButton>
                <CommonButton btnType={ ButtonTypeEnum.DOWNLOAD }
                              onClick={ () => downloadQrCodeHandler() }
                              sx={ { p: 1, m: 1, width: smUp ? '25%' : '50%' } }>
                    { t('dashboard.sidebar.documents.qrCodeModal.button.download') }
                </CommonButton>
            </Box>
        </Dialog>
    );
};

export default QrCodeModal;
