import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ErrorType, IError } from '../model/common/error';
import { AppThunk } from '../store';
import { handleError } from './errors-handling';
import apis from '../auth/apis';
import { RequestHelper } from '../utils/request-helper';
import auth from '../auth/auth';

interface IPinState {
    error: IError | undefined;
    isLoading: boolean;
    isPasswordResetMessageVisible: boolean;
    remainingAttempts: number | undefined;
}

const initialState: IPinState = {
    error: undefined,
    isLoading: false,
    isPasswordResetMessageVisible: false,
    remainingAttempts: undefined,
};

const pinManagerSlice = createSlice({
    name: 'pinManager',
    initialState,
    reducers: {
        checkPin(state: IPinState): void {
            state.isLoading = false;
        },
        clearErrorMessage(state: IPinState): void {
            state.error = undefined;
        },
        clearRemainingAttempts(state: IPinState): void {
            state.remainingAttempts = undefined;
        },
        setErrorMessage(state: IPinState, action: PayloadAction<IError>): void {
            state.error = action.payload;
        },
        setIsLoading(state: IPinState, action: PayloadAction<boolean>): void {
            state.isLoading = action.payload;
        },
        setIsPasswordResetMessageVisible(state: IPinState): void {
            state.isPasswordResetMessageVisible = true;
        },
        setRemainingAttempts(state: IPinState, action: PayloadAction<number>): void {
            state.remainingAttempts = action.payload;
        },
    },
});

export const setAttempts = (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(pinManagerSlice.actions.clearErrorMessage());
        dispatch(pinManagerSlice.actions.clearRemainingAttempts());
        dispatch(pinManagerSlice.actions.setIsPasswordResetMessageVisible());
    };

export const checkPin = (token: string, userPin: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(pinManagerSlice.actions.setIsLoading(true));
        auth.setUserPin(userPin);
        apis.pinManagerApi()
            .checkPin(userPin)
            .then(() => {
                const redirectLink = window.localStorage.getItem('redirectLink') as string;
                window.location.href = redirectLink ? redirectLink : '/';
                dispatch(pinManagerSlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                if (RequestHelper.isUnprocessableEntityError(err)) {
                    auth.removePin();
                    dispatch(pinManagerSlice.actions.setRemainingAttempts(RequestHelper.getRemainingAttempts(err)));
                    dispatch(pinManagerSlice.actions.setErrorMessage({
                        error: true,
                        errorMessage: 'dashboard.sidebar.documents.pinModal.wrongPin',
                        errorType: ErrorType.AUTHORIZATION_ERROR
                    } as IError));
                } else {
                    dispatch(handleError(err));
                }
                dispatch(pinManagerSlice.actions.setIsLoading(false));
            });
    };

export const { reducer } = pinManagerSlice;

export const {
    clearErrorMessage,
    clearRemainingAttempts,
    setIsPasswordResetMessageVisible,
    setErrorMessage,
    setRemainingAttempts
} = pinManagerSlice.actions;

export default pinManagerSlice;
