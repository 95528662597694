import type { FC } from 'react';
import { Box, Checkbox, Tooltip, Typography } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface IDownloadableBeforeSignatureProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
}

const DownloadableBeforeSignatureCheckbox: FC<IDownloadableBeforeSignatureProps> = (props) => {
    const { t } = useTranslation();

    const { checked, onChange } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    };

    return (
        <Box display="flex" alignItems="center">
            <Checkbox checked={checked} onChange={handleChange} />
            <Typography sx={{ mr: 1 }}>{t('documentUpload.downloadableBeforeSignature.checkbox.label')}</Typography>
            <Tooltip title={t('documentUpload.downloadableBeforeSignature.checkbox.helperIcon.tooltip.title') as string} enterTouchDelay={0}>
                <HelpOutline fontSize="small" />
            </Tooltip>
        </Box>
    );
};

export default DownloadableBeforeSignatureCheckbox;
