import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import {
    ContactRequest,
    ContactResponse, EmailRequest,
    EmailResponse,
    PhoneRequest,
    PhoneResponse
} from 'contacts-service-api';
import { UpdateContactRequest} from 'contacts-service-api';
import  { handleError } from './errors-handling';
import {UserContactsHelper} from '../utils/user-contacts-helper';
import apis from '../auth/apis';

interface ExistingContactEmail {
    userContactUuid : string;
    email: EmailResponse;
}

interface ExitingContactPhone {
    userContactUuid : string;
    phone: PhoneResponse;
}

interface IUserContactsState {
    userContacts: ContactResponse[] | undefined;
    isLoading: boolean;
    isDeleteContactLoading: boolean;
    isDeleteEmailLoading: boolean;
    isDeletePhoneLoading: boolean;
    redirectTo: string;
    currentUserContact: ContactResponse | undefined;
    sendSuccessful: boolean;
    userContactSavedFromDocumentUpload: boolean;
}

const initialState: IUserContactsState = {
    currentUserContact: undefined,
    isDeleteContactLoading: false,
    isDeleteEmailLoading: false,
    isDeletePhoneLoading: false,
    isLoading: false,
    redirectTo: '',
    sendSuccessful: false,
    userContacts: [],
    userContactSavedFromDocumentUpload: false
};

const userContactsSlice = createSlice({
    name: 'user-contacts',
    initialState,
    reducers:{
        addNewEmail(state: IUserContactsState, action: PayloadAction<EmailResponse>): void {
            // const userContact = state.currentUserContact;
            // userContact?.emails?.push(action.payload);
            state.currentUserContact?.emails?.push(action.payload);
        },
        addNewEmailToExistingContact(state: IUserContactsState, action: PayloadAction<ExistingContactEmail>): void {
            const userContacts = state.userContacts;
            if(userContacts) {
                state.userContacts = UserContactsHelper.addEmailToExistingUserContact(userContacts, action.payload.userContactUuid, action.payload.email);
            }
        },
        addNewPhoneNumber(state: IUserContactsState, action: PayloadAction<PhoneResponse>): void {
            state.currentUserContact?.phones?.push(action.payload);
        },
        addNewPhoneToExistingContact(state: IUserContactsState, action: PayloadAction<ExitingContactPhone>): void {
            const userContacts = state.userContacts;
            if(userContacts) {
                state.userContacts = UserContactsHelper.addPhoneNumberToExistingUserContact(userContacts, action.payload.userContactUuid, action.payload.phone);
            }
        },
        clearUserContacts(state: IUserContactsState): void {
            state.userContacts = [];
        },
        deleteCurrentUserContactEmail(state: IUserContactsState, action: PayloadAction<string>): void {
            const userContact = state.currentUserContact;
            const filteredEmails = userContact?.emails?.filter(email => email.uuid !== action.payload);
            if (userContact) {
                userContact.emails = filteredEmails;
            }
            state.currentUserContact = userContact;
            state.isDeleteEmailLoading = false;
        },
        deleteCurrentUserContactPhoneNumber(state: IUserContactsState, action: PayloadAction<string>): void {
            const userContact = state.currentUserContact;
            const filteredPhoneNumbers = userContact?.phones?.filter(phone => phone.uuid !== action.payload);
            if (userContact) {
                userContact.phones = filteredPhoneNumbers;
            }
            state.currentUserContact = userContact;
            state.isDeletePhoneLoading = false;
        },
        removeDeletedContact(state: IUserContactsState, action: PayloadAction<string>): void {
            state.isDeleteContactLoading = false;
            const userContacts = state.userContacts;
            state.userContacts = userContacts?.filter(contact => contact.uuid !== action.payload);
        },
        resetSendSuccessful(state:IUserContactsState): void {
            state.sendSuccessful = false;
        },
        saveNewUserContact(state: IUserContactsState, action: PayloadAction<ContactResponse>): void {
            const userContacts = state.userContacts || [];
            userContacts.push(action.payload as ContactResponse);
            state.userContacts = userContacts;
            state.sendSuccessful = true;
            state.isLoading = false;
        },
        saveUserContactDetailsFromDocuments(state: IUserContactsState): void {
            state.isLoading = false;
            state.userContactSavedFromDocumentUpload = true;
        },
        setCurrentUserContact(state: IUserContactsState, action: PayloadAction<ContactResponse>): void {
            state.isLoading = false;
            state.currentUserContact = action.payload;
        },
        setDeleteEmailInProgress(state: IUserContactsState, action: PayloadAction<boolean>): void {
            state.isDeleteEmailLoading = action.payload;
        },
        setDeleteInProgress(state: IUserContactsState, action: PayloadAction<boolean>): void {
            state.isDeleteContactLoading = action.payload;
        },
        setDeletePhoneInProgress(state: IUserContactsState, action: PayloadAction<boolean>): void {
            state.isDeletePhoneLoading = action.payload;
        },
        setIsLoading(state: IUserContactsState, action: PayloadAction<boolean>): void {
            state.isLoading = action.payload;
        },
        setUserContacts(state: IUserContactsState, action: PayloadAction<ContactResponse[]>) {
            state.userContacts = action.payload as ContactResponse[];
            state.isLoading = false;
        },
        updateEmail(state: IUserContactsState, action: PayloadAction<EmailResponse>): void {
            const userContact = state.currentUserContact;
            const filteredEmails = userContact?.emails?.filter(email => email.uuid !== action.payload.uuid);
            if (userContact) {
                userContact.emails = filteredEmails ? [...filteredEmails, action.payload] : [action.payload];
            }
            state.currentUserContact = userContact;
        },
        updatePhoneNumber(state: IUserContactsState, action: PayloadAction<PhoneResponse>): void {
            const userContact = state.currentUserContact;
            const filteredPhones = userContact?.phones?.filter(phone => phone.uuid !== action.payload.uuid);
            if (userContact) {
                userContact.phones = filteredPhones ? [...filteredPhones, action.payload] : [action.payload];
            }
            state.currentUserContact = userContact;
        }
    }
});

export const getUserContacts = (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(userContactsSlice.actions.setIsLoading(true));
        apis.contactsApi()
            .getContacts()
            .then((result) => {
                dispatch(userContactsSlice.actions.setUserContacts(result.data));
            })
            .catch((err) => {
                dispatch(userContactsSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const saveNewUserContact = (contact: ContactRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(userContactsSlice.actions.setIsLoading(true));
        apis.contactsApi()
            .addContact(contact)
            .then((result) => {
                dispatch(userContactsSlice.actions.saveNewUserContact(result.data));
            })
            .catch((err) => {
                dispatch(userContactsSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const updateUserContactName = (contactUuid: string, contactNameRequest: UpdateContactRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(userContactsSlice.actions.setIsLoading(true));
        apis.contactsApi()
            .updateContact(contactUuid, contactNameRequest)
            .then((result) => {
                dispatch(userContactsSlice.actions.setCurrentUserContact(result.data));
            })
            .catch((err) => {
                dispatch(userContactsSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const deleteUserContactEmail = (contactUuid: string, emailUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(userContactsSlice.actions.setDeleteEmailInProgress(true));
        apis.contactsApi()
            .deleteEmail(contactUuid, emailUuid)
            .then(() => {
                dispatch(userContactsSlice.actions.deleteCurrentUserContactEmail(emailUuid));
            })
            .catch((err) => {
                dispatch(userContactsSlice.actions.setDeleteEmailInProgress(false));
                dispatch(handleError(err));
            });
    };

export const deleteUserContactPhoneNumber = (contactUuid: string, phoneNumberUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(userContactsSlice.actions.setDeletePhoneInProgress(true));
        apis.contactsApi()
            .deletePhone(contactUuid, phoneNumberUuid)
            .then(() => {
                dispatch(userContactsSlice.actions.deleteCurrentUserContactPhoneNumber(phoneNumberUuid));
            })
            .catch((err) => {
                dispatch(userContactsSlice.actions.setDeletePhoneInProgress(true));
                dispatch(handleError(err));
            });
    };

export const deleteUserContact = (contactUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(userContactsSlice.actions.setDeleteInProgress(true));
        apis.contactsApi()
            .deleteContact(contactUuid)
            .then(() => {
                dispatch(userContactsSlice.actions.removeDeletedContact(contactUuid));
            })
            .catch((err) => {
                dispatch(userContactsSlice.actions.setDeleteInProgress(false));
                dispatch(handleError(err));
            });
    };

export const addEmailToUserContact = (contactUuid: string, emailRequest: EmailRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(userContactsSlice.actions.setIsLoading(true));
        apis.contactsApi()
            .addEmail(contactUuid, emailRequest)
            .then((response) => {
                dispatch(userContactsSlice.actions.addNewEmail(response.data));
                dispatch(userContactsSlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(userContactsSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const addPhoneToUserContact = (contactUuid: string, phoneRequest: PhoneRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(userContactsSlice.actions.setIsLoading(true));
        apis.contactsApi()
            .addPhoneNumber(contactUuid, phoneRequest)
            .then((response) => {
                dispatch(userContactsSlice.actions.addNewPhoneNumber(response.data));
                dispatch(userContactsSlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(userContactsSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const addEmailPhoneNumberToExistingUserContact = (contactUuid: string, emailRequest?: EmailRequest, phoneRequest?: PhoneRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(userContactsSlice.actions.setIsLoading(true));
        if (emailRequest) {
            apis.contactsApi()
                .addEmail(contactUuid, emailRequest)
                .then((response) => {
                    dispatch(userContactsSlice.actions.addNewEmailToExistingContact({userContactUuid: contactUuid, email: response.data}));
                })
                .catch((err) => {
                    dispatch(userContactsSlice.actions.setIsLoading(false));
                    dispatch(handleError(err));
                });
        }
        if (phoneRequest) {
            apis.contactsApi()
                .addPhoneNumber(contactUuid, phoneRequest)
                .then((response) => {
                    dispatch(userContactsSlice.actions.addNewPhoneToExistingContact({userContactUuid: contactUuid, phone: response.data}));
                })
                .catch((err) => {
                    dispatch(userContactsSlice.actions.setIsLoading(false));
                    dispatch(handleError(err));
                });
        }
        dispatch(userContactsSlice.actions.saveUserContactDetailsFromDocuments());
    };

export const updateContactEmail = (contactUuid: string, emailUuid: string, emailRequest: EmailRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(userContactsSlice.actions.setIsLoading(true));
        apis.contactsApi()
            .updateEmail(contactUuid, emailUuid, emailRequest)
            .then((response) => {
                dispatch(userContactsSlice.actions.updateEmail(response.data));
                dispatch(userContactsSlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(userContactsSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const updateContactPhoneNumber = (contactUuid: string, phoneNumberUuid: string, phoneRequest: PhoneRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(userContactsSlice.actions.setIsLoading(true));
        apis.contactsApi()
            .updatePhone(contactUuid, phoneNumberUuid, phoneRequest)
            .then((response) => {
                dispatch(userContactsSlice.actions.updatePhoneNumber(response.data));
                dispatch(userContactsSlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(userContactsSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };


export const setCurrentUserContactHandler = (contact: ContactResponse): AppThunk =>
    async (dispatch): Promise<void> => {
    dispatch(userContactsSlice.actions.setIsLoading(true));
    dispatch(userContactsSlice.actions.setCurrentUserContact(contact));
};

export const { reducer } = userContactsSlice;
export const { setCurrentUserContact, clearUserContacts, resetSendSuccessful } = userContactsSlice.actions;

export default userContactsSlice;
