import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { DashboardLayoutContainer, DashboardLayoutContent, DashboardLayoutProps, DashboardLayoutRoot, DashboardLayoutWrapper } from '../common/CommonLayout';
import DashboardNavbar from '../common/DashboardNavbar';
import DashboardSidebar from '../common/DashboardSidebar';
import { IMenuSection } from '../../../model/common/navigation/menu-section';
import { useParams } from 'react-router';
import { getGroupMenu } from '../../../utils/menu';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { initGroupMembership, resetActiveGroup } from '../../../slices/groups';
import { getUserIdentifier } from '../../../slices/profile-data';
import { PrivateRoutes } from '../../../routes';
import { resetActiveOrganization } from '../../../slices/organizations';
import auth from '../../../auth/auth';
import { resetDocuments } from '../../../slices/documents';

const GroupDashboardLayout: FC<DashboardLayoutProps> = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [ isSidebarMobileOpen, setIsSidebarMobileOpen ] = useState<boolean>(false);
    const [ groupUUID, setGroupUUID ] = useState<string>('');

    const { activeGroup, isLoadingGroupMembership } = useAppSelector(state => state.groups);
    const { userIdentificationData, isLoadingBasicUserData } = useAppSelector((state) => state.profileData);

    const menu: IMenuSection[] = getGroupMenu(groupUUID ?? '');
    const isProfileVerified = Boolean(userIdentificationData?.fullName && userIdentificationData?.personalNumber);

    useEffect(() => {
        const { groupUuid } = params;
        setGroupUUID(groupUuid ?? '');
        if (!activeGroup) {
            dispatch(initGroupMembership(groupUuid ?? ''));
        }
        if (!userIdentificationData) {
            dispatch(getUserIdentifier());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnBackToPrivateProfile = () => {
        auth.setOrganizationUuid('');
        dispatch(resetActiveGroup());
        dispatch(resetActiveOrganization());
        dispatch(resetDocuments());
        navigate(PrivateRoutes.private_groups_route.path);
    };

    return (
        <DashboardLayoutRoot>
            <DashboardNavbar
                activeGroup={ activeGroup }
                isProfileVerified={ isProfileVerified }
                onBackToPrivateProfile={ handleOnBackToPrivateProfile }
                onSidebarMobileOpen={ (): void => setIsSidebarMobileOpen((prevValue) => !prevValue) }
                userIdentificationData={ userIdentificationData } />

            <DashboardSidebar
                activeGroup={ activeGroup }
                isLoading={ isLoadingGroupMembership || isLoadingBasicUserData }
                isProfileVerified={ isProfileVerified }
                menu={ menu }
                onMobileClose={ (): void => setIsSidebarMobileOpen(false) }
                openMobile={ isSidebarMobileOpen }
                userIdentificationData={ userIdentificationData } />

            <DashboardLayoutWrapper>
                <DashboardLayoutContainer>
                    <DashboardLayoutContent>
                        <Outlet />
                    </DashboardLayoutContent>
                </DashboardLayoutContainer>
            </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
    );
};

export default GroupDashboardLayout;
