import type { FC } from 'react';
import { GroupSimple, Membership } from 'group-service-api';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    IconButton,
    Stack,
    Theme, Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import { GroupCardUtil } from './GroupCard.util';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { ArrowCircleRight, DoubleArrow, PersonAdd } from '@mui/icons-material';
import { toast } from 'react-hot-toast';

interface IGroupCardProps {
    isSwitchToGroupDisabled?: boolean;
    membership: Membership;
    onGroupSelect: (groupUuid: string) => void;
    additionalButton?: JSX.Element;
    onAdditionalButtonMobileClick?: (group: GroupSimple) => void;
}

const GroupCard: FC<IGroupCardProps> = (props) => {
    const { additionalButton, isSwitchToGroupDisabled, membership, onAdditionalButtonMobileClick, onGroupSelect } = props;
    const { t } = useTranslation();
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    return smUp ? (
        <Card sx={ { p: 2 } }>
            <CardContent sx={ { mb: 2 } }>
                <Typography color='textSecondary' variant='overline'>
                    { t(GroupCardUtil.roleText(membership.role)) }
                </Typography>
                <Typography color='textPrimary' variant='h5'>
                    { membership.group.name }
                </Typography>
            </CardContent>
            <CardActions>
                <Grid container direction='row' justifyContent={ additionalButton ? 'space-between' : 'right' } alignItems='center'>
                    { additionalButton && additionalButton }
                    { isSwitchToGroupDisabled ? (
                        <Tooltip title={ t('groups.summary.selectGroup.label.disabled') as string }>
                            <Button
                                sx={ { color: 'rgba(0, 0, 0, 0.26)', cursor: 'not-allowed' } }
                                variant='text'
                                color={ additionalButton ? 'secondary' : 'primary' }
                                onClick={ () => toast(t('groups.summary.selectGroup.label.disabled')) }
                                startIcon={ <DoubleArrow fontSize='small' /> }>
                                { t('groups.summary.selectGroup.label') }
                            </Button>
                        </Tooltip>
                    ) : (
                        <CommonButton
                            btnType={ ButtonTypeEnum.GOTO }
                            color={ additionalButton ? 'secondary' : 'primary' }
                            variant={ 'text' }
                            onClick={ () => onGroupSelect(membership.group.uuid) }>
                            { t('groups.summary.selectGroup.label') }
                        </CommonButton>
                    ) }
                </Grid>
            </CardActions>
        </Card>
    ) : (
        <Card sx={ { p: 1, pl: 2 } }>
            <Stack direction='row' sx={ { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } }>
                <Typography color='textPrimary' variant='body1'>
                    { membership.group.name }
                </Typography>
                <Grid item>
                    { additionalButton &&
                        <IconButton color='primary' onClick={ () => onAdditionalButtonMobileClick ? onAdditionalButtonMobileClick(membership.group) : null}>
                            <PersonAdd />
                        </IconButton>
                    }
                    <IconButton color='primary' onClick={ () => onGroupSelect(membership.group.uuid) }>
                        <ArrowCircleRight />
                    </IconButton>
                </Grid>
            </Stack>
        </Card>
    );
};

export default GroupCard;
