import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Page from '../../components/shared/Page';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Check, CloseOutlined, GroupAdd } from '@mui/icons-material';
import EmptyState from '../../components/empty-states/EmptyState';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import { useNavigate, useParams } from 'react-router-dom';
import {
    confirmMembershipRequest,
    deleteMembershipFromOrganization,
    getOrganizationInvites,
    setIsConfirmedMembershipSuccessful
} from '../../slices/organizations';
import { PrivateRoutes } from '../../routes';
import SkeletonBlock from '../../components/skeletons/SkeletonBlock';
import { Membership, MembershipStatusEnum } from 'organizations-service-api';

const OrganizationInvitesPage: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const [confirmedInviteOrganizationUuid, setConfirmedInviteOrganizationUuid] = useState<string>('');
    const [inviteInAction, setInviteInAction] = useState<string>('');

    const { inviteUuid } = params;

    const {
        memberships,
        isConfirmedMembershipSuccessful,
        isConfirmingMembershipRequest,
        isDeletingMembershipRequest,
        isLoadingInvites
    } = useAppSelector((state) => state.organizations);

    const pendingMemberships = memberships?.filter((m: Membership) => m.status === MembershipStatusEnum.Pending);
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    useEffect(() => {
        dispatch(getOrganizationInvites());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isConfirmedMembershipSuccessful) {
            navigate(PrivateRoutes.private_organizations_details_route.path.replace(':organizationUuid', confirmedInviteOrganizationUuid));
            setConfirmedInviteOrganizationUuid('');
            dispatch(setIsConfirmedMembershipSuccessful(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConfirmedMembershipSuccessful]);

    const handleConfirmInvite = (membership: Membership) => {
        setInviteInAction(membership.uuid);
        setConfirmedInviteOrganizationUuid(membership.organization.uuid);
        dispatch(
            confirmMembershipRequest(
                membership.organization.uuid,
                membership.uuid
            )
        );
    };

    const handleRejectInvite = (membership: Membership) => {
        setInviteInAction(membership.uuid);
        dispatch(
            deleteMembershipFromOrganization(
                membership.uuid
            )
        );
    };

    const showInvites = () => {
        if (!pendingMemberships || pendingMemberships.length === 0) {
            return <EmptyState message={t('businessProfile.invites.emptyState.message')} icon={<GroupAdd />} />;
        }

        return pendingMemberships.map((membership: Membership) => {
            return (
                <Card key={membership.uuid} sx={{ mb: 3, border: inviteUuid === membership.uuid ? '1px solid #89CFF0' : 'none' }}>
                    <CardContent sx={{ display: 'flex', flexDirection: mdUp ? 'row' : 'column', justifyContent: 'space-between', pt: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography ml={1} color={membership.status === MembershipStatusEnum.Pending ? 'orange' : 'green'} sx={{ fontWeight: 'fontWeightBold' }}>
                                {membership.status}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography ml={1}>{membership.organization.name}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography ml={1}>{membership.organization.oib}</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', mt: mdUp ? 0 : 3 }}>
                            <CommonButton
                                sx={{ mr: 1 }}
                                btnType={ButtonTypeEnum.NO_ICON}
                                loading={isDeletingMembershipRequest && membership.uuid === inviteInAction}
                                disabled={isConfirmingMembershipRequest || isDeletingMembershipRequest}
                                startIcon={<CloseOutlined fontSize='small' />}
                                variant='text'
                                onClick={() => handleRejectInvite(membership)}>
                                {t('businessProfile.invites.action.refuse')}
                            </CommonButton>
                            <CommonButton
                                sx={{ ml: 1 }}
                                btnType={ButtonTypeEnum.NO_ICON_CONTAINED}
                                loading={isConfirmingMembershipRequest && membership.uuid === inviteInAction}
                                disabled={isConfirmingMembershipRequest || isDeletingMembershipRequest}
                                startIcon={<Check fontSize='small' />}
                                variant='contained'
                                onClick={() => handleConfirmInvite(membership)}>
                                {t('businessProfile.invites.action.confirm')}
                            </CommonButton>
                        </Box>
                    </CardContent>
                </Card>
            );
        });
    };

    return (
        <Page
            pageTitle={t('businessProfile.invites.title')}
            pageDescription={t('businessProfile.invites.subtitle')}>
            <Grid item md={12} xs={12} mt={3}>
                {isLoadingInvites ? <SkeletonBlock height={250} /> : showInvites()}
            </Grid>
        </Page>
    );
};

export default OrganizationInvitesPage;
