import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Card, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import SkeletonBlock from '../skeletons/SkeletonBlock';
import PayablesFilters, { IFilters } from '../payables/PayablesFilters';
import PayableItems from '../payables/PayableItems';
import { endOfMonth, startOfMonth } from 'date-fns';
import { getTransactionStats } from '../../slices/payments';

export const OrganizationPayableStats: FC = () => {
    const dispatch = useAppDispatch();
    const [ filters, setFilters ] = useState<IFilters>({ startDate: startOfMonth(new Date()), endDate: endOfMonth(new Date()) });
    const { isLoadingPayables, transactionStatistics } = useAppSelector(state => state.payments);

    useEffect(() => {
        if (!transactionStatistics) {
            dispatch(getTransactionStats());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(getTransactionStats(filters));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ filters ]);

    const handleChangeFilters = (newFilters: IFilters): void => {
        setFilters(newFilters);
    };

    return (
        // isLoadingPayables ? <SkeletonBlock height={ 200 } /> :
        <Card>
            <Grid container direction='row'>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 } xl={ 4 }>
                    <PayablesFilters
                        disabled={ isLoadingPayables }
                        filters={ filters }
                        onChange={ handleChangeFilters } />
                </Grid>

                <Grid item xs={ 12 } sm={ 12 } md={ 8 } lg={ 8 } xl={ 8 }>
                    { isLoadingPayables ?
                        <SkeletonBlock height={ 200 } /> :
                        <PayableItems transactionStats={ transactionStatistics } /> }
                </Grid>
            </Grid>
        </Card>
    );
};
