import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getSurveyUserWithoutAuth } from '../../slices/survey';
import { Box, Card, Tab, Tabs, Typography } from '@mui/material';
import Page from '../../components/shared/Page';
import SkeletonBlock from '../../components/skeletons/SkeletonBlock';
import { Section } from 'survey-service-api';
import TabPanel from '../../components/shared/TabPanel';
import SectionBox from '../../components/survey/SectionBox';
import EmptyState from '../../components/empty-states/EmptyState';
import { CheckCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {PrivateRoutes, UnprotectedRoutes} from '../../routes';
import ConfirmationDialog from '../../components/shared/ConfirmationDialog';

const MySurveyPage: FC = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { surveyUuid } = params;

    const { sharedSurvey, isLoading } = useAppSelector((state) => state.survey);
    const { profileData } = useAppSelector((state) => state.profileData);

    const isProfileVerified = Boolean(profileData?.documents && profileData?.documents.length >= 1);

    const [sortedSections, setSortedSections] = useState<Section[]>([]);

    const [confirmWalletCreateDialog, setConfirmWalletCreateDialog] = useState<boolean>(false);
    const [confirmSubmitDialog, setConfirmSubmitDialog] = useState<boolean>(false);

    const [tab, setTab] = useState<number>(0);
    const [selectedSection, setSelectedSection] = useState<Section>();

    useEffect(() => {
        if (surveyUuid) {
            dispatch(getSurveyUserWithoutAuth(surveyUuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sharedSurvey) {
            const sortedList = [...sharedSurvey.survey.sections].sort((a, b) => a.ordinal - b.ordinal);
            setSortedSections(sortedList);
            setSelectedSection(sortedList[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedSurvey?.uuid]);

    const handleSubmitClick = () => {
        if (!isProfileVerified) {
            setConfirmWalletCreateDialog(true);
        }

        if (surveyUuid && isProfileVerified) {
            setConfirmSubmitDialog(true);
        }
    };

    const handleConfirmRedirect = () => {
        if (UnprotectedRoutes.unprotected_refresh_route.path) {
            navigate(UnprotectedRoutes.unprotected_refresh_route.path);
            localStorage.setItem('redirectLink', PrivateRoutes.private_survey_user_wallet_data_route.path.replace(':surveyUserUuid', surveyUuid ?? ''));
            localStorage.setItem('fullScopeRedirectUrl', window.location.href);
        }
    };

    const handleConfirmSubmit = () => {
        if (surveyUuid) {
            navigate(PrivateRoutes.private_survey_user_wallet_data_route.path.replace(':surveyUserUuid', surveyUuid ?? ''));
        }
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
        if (newValue) {
            setSelectedSection(sortedSections[newValue]);
        } else {
            setSelectedSection(sortedSections[0]);
        }
    };

    const handleChangeTab = () => {
        const newTab = tab + 1;
        setTab(newTab);
        if (sortedSections[newTab]) {
            setSelectedSection(sortedSections[newTab]);
        } else {
            setSelectedSection(undefined);
        }
    };

    return (
        <Page pageTitle={t('survey.MySurveyPage.title')}>
            {isLoading ? (
                <SkeletonBlock />
            ) : (
                sharedSurvey && (
                    <Box>
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="h5">{sharedSurvey.survey.name}</Typography>
                            <Typography variant="body1">{sharedSurvey.survey.description}</Typography>
                        </Box>
                        <Card sx={{ border: sharedSurvey.submitted ? '2px solid lightgreen' : 'none' }}>
                            {sharedSurvey.submitted && (
                                <Box borderBottom='2px solid lightgreen' p={2} textAlign='center'>
                                    <Typography variant='body1'>{t('survey.MySurveyPage.notification.confirmed')}</Typography>
                                </Box>
                            )}
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs scrollButtons='auto' variant="scrollable" value={tab} onChange={handleChange}>
                                        {sortedSections.map((s: Section) => (
                                            <Tab key={s.uuid} label={s.title} />
                                        ))}
                                        <Tab label={t('survey.MySurveyPage.tabs.confirmation')} value={sharedSurvey.survey.sections.length} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={tab} index={tab}>
                                    {selectedSection && (
                                        <SectionBox
                                            sharedSurveyUuid={sharedSurvey.uuid}
                                            section={selectedSection}
                                            surveyAnswers={sharedSurvey.answers}
                                            changeTab={handleChangeTab}
                                        />
                                    )}
                                </TabPanel>
                                <TabPanel value={tab} index={sharedSurvey.survey.sections.length}>
                                    {!sharedSurvey.submitted ? (
                                        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            <CommonButton btnType={ButtonTypeEnum.CONFIRM} sx={{ my: 6 }} onClick={handleSubmitClick}>
                                                {t('survey.MySurveyPage.tabs.confirmation.button.confirm')}
                                            </CommonButton>
                                            <EmptyState message={t('survey.MySurveyPage.tabs.confirmation.message')} />
                                        </Box>
                                    ) : (
                                        <Box>
                                            <EmptyState message={t('survey.MySurveyPage.emptyState.confirmedMessage')} icon={<CheckCircle />} />
                                        </Box>
                                    )}
                                </TabPanel>
                            </Box>
                        </Card>
                    </Box>
                )
            )}
            <ConfirmationDialog
                title={t('survey.MySurveyPage.tabs.confirmation.dialog.wallet.title')}
                message={t('survey.MySurveyPage.tabs.confirmation.dialog.wallet.message')}
                onConfirm={handleConfirmRedirect}
                onCancel={() => setConfirmWalletCreateDialog(false)}
                open={confirmWalletCreateDialog}
            />
            <ConfirmationDialog
                title={t('survey.MySurveyPage.tabs.confirmation.dialog.confirm.title')}
                message={t('survey.MySurveyPage.tabs.confirmation.dialog.confirm.message')}
                onConfirm={handleConfirmSubmit}
                onCancel={() => setConfirmSubmitDialog(false)}
                open={confirmSubmitDialog}
            />
        </Page>
    );
};

export default MySurveyPage;
