import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import CheckoutOrderSummary from '../../components/checkout/CheckoutOrderSummary';
import advancedPlanImg from '../../assets/img/pricing/plan2.svg';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import { PaymentsHelper } from '../../utils/payments-helper';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import { AmountCurrencyCurrencyEnum, Bundle, BundleOfferGroupEnum } from 'payment-service-api';
import CheckoutRegulatoryData from './CheckoutRegulatoryData';
import SecureCheckoutLabel from './SecureCheckoutLabel';
import PaymentError from './PaymentError';
import CheckoutHeading from './CheckoutHeading';
import { initiateTransaction } from '../../slices/payments';
import EurPriceDisclaimer from '../../components/profile/plans/EurPriceDisclaimer';
import { AppSettings } from '../../common/app-settings';
import CardInfosTest from '../../components/test-cards/CardInfosTest';
import { Misc } from '../../utils/misc';

export interface IProduct {
    id: string;
    image: string;
    name: string;
    price: number;
    currency: AmountCurrencyCurrencyEnum;
    quantity: number;
}

const CheckoutPage: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { isLoading, wsPayRedirectUrl } = useAppSelector(state => state.payments);

    const [ product, setProduct ] = useState<IProduct | undefined>(undefined);
    const [ error, setError ] = useState<string>('');

    const total = PaymentsHelper.getTotalCheckoutAmount(product);
    const showCardInfos = Misc.isLocalEnvironment() || Misc.isDevEnvironment();

    useEffect(() => {
        const bundleInfo = localStorage.getItem(AppSettings.BUNDLE_UUID);

        if (bundleInfo) {
            const plan = JSON.parse(bundleInfo) as Bundle;
            setProduct(
                {
                    currency: plan?.amountCurrency?.currency,
                    id: plan?.uuid,
                    image: advancedPlanImg,
                    name: plan.offerGroup === BundleOfferGroupEnum.Plans
                        ? PaymentsHelper.getPlanName(plan?.placeholderName ?? '')
                        : PaymentsHelper.getSignatureBundleName(plan?.placeholderName ?? ''),
                    price: plan?.amountCurrency.amount,
                    quantity: 1
                } as IProduct
            );
        }

        return () => {
            setError('');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (wsPayRedirectUrl) {
            window.location.assign(wsPayRedirectUrl);
        }
    }, [ wsPayRedirectUrl ]);

    const handleCompleteOrder = () => {
        setError('');
        dispatch(initiateTransaction(product?.id ?? ''));
    };

    return (
        <>
            <Box
                component='main'
                sx={ {
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                    py: 8
                } }>
                <Container maxWidth='lg'>
                    <CheckoutHeading />
                    <Box mt={ 6 }>
                        <Grid container spacing={ 3 } justifyContent='space-between' alignItems='flex-start' direction='row'>
                            <Grid item lg={ 6 } md={ 6 } xs={ 12 }>
                                { showCardInfos
                                    ? <CardInfosTest />
                                    : <CheckoutRegulatoryData />
                                }
                            </Grid>
                            <Grid item lg={ 6 } md={ 6 } xs={ 12 }>
                                { error && <PaymentError errorMessage={ error } /> }
                                <CheckoutOrderSummary product={ product } total={ total } />
                                <Box sx={ { mt: 6 } }>
                                    <SecureCheckoutLabel />
                                    <CommonButton fullWidth sx={ { mt: 3 } }
                                                  btnType={ ButtonTypeEnum.CHECKOUT }
                                                  loading={ isLoading }
                                                  onClick={ handleCompleteOrder }>
                                        { t('dashboard.profile.plans.checkout.completeOrder') }
                                    </CommonButton>
                                </Box>
                                <EurPriceDisclaimer />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default CheckoutPage;
