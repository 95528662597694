import type { FC } from 'react';
import { Box, Checkbox, Tooltip, Typography } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DocumentsHelper } from '../../utils/documents-helper';
import { Membership } from 'group-service-api';

interface ISimpleSignatureCheckboxProps {
    activeGroup?: Membership;
    checked: boolean;
    onChange: (checked: boolean) => void;
}

const SimpleSignatureCheckbox: FC<ISimpleSignatureCheckboxProps> = ({ activeGroup, checked, onChange }) => {
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    };

    const show = DocumentsHelper.shouldShowSimpleSignatureCheckbox(activeGroup?.group?.uuid)
        || DocumentsHelper.shouldShowSimpleForOrganization(activeGroup?.group?.organizationUuid);

    return (
        show ?
            <Box display='flex' alignItems='center'>
                <Checkbox checked={ checked } onChange={ handleChange } />
                <Typography sx={ { mr: 1 } }>{ t('documentUpload.signatureType.checkbox.label') }</Typography>
                <Tooltip title={ t('documentUpload.signatureType.checkbox.helperIcon.tooltip.title') as string }
                         enterTouchDelay={ 0 }>
                    <HelpOutline fontSize='small' />
                </Tooltip>
            </Box> :
            <></>
    );
};

export default SimpleSignatureCheckbox;
