import type { FC } from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AppSettings } from '../../common/app-settings';
import FileDropzone from '../FileDropzone';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
    deleteUserDocuments,
    getMemberDocumentDetails,
    getUserDocuments,
    resetFlags,
    uploadUserDocuments
} from '../../slices/organizations';
import { toast } from 'react-hot-toast';
import DuplicateIcon from '../../assets/icons/Duplicate';
import XIcon from '../../assets/icons/X';
import ConfirmationDialog from '../shared/ConfirmationDialog';
import SkeletonBlock from '../skeletons/SkeletonBlock';
import { CloudUploadRounded, FileDownloadRounded } from '@mui/icons-material';
import EmptyState from '../empty-states/EmptyState';
import { DocumentSimpleOut } from 'organizations-service-api';

const OrganizationDocuments: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [files, setFiles] = useState<File[]>([]);
    const [documentName, setDocumentName] = useState<string>('');
    const [isRemovingDocumentModalOpen, setIsRemovingDocumentModalOpen] = useState<boolean>(false);
    const [docUuidToDelete, setDocUuidToDelete] = useState<string>('');

    const {
        isDeletingFile,
        isLoadingFiles,
        isUploadingFile,
        isUploadingFilesSuccessful,
        selectedOrganization,
        userDocuments
    } = useAppSelector(state => state.organizations);

    useEffect(() => {
        if (!userDocuments) {
            dispatch(
                getUserDocuments(
                    selectedOrganization?.currentUser?.uuid ?? ''
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isUploadingFilesSuccessful) {
        toast.success(t('businessProfile.details.documents.message.successfulUpload'));
        dispatch(resetFlags());
    }

    const handleDocumentNameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setDocumentName(event.target.value);
    };

    const setFilesHandler = (acceptedFiles: File[]): void => {
        setFiles((oldState) => [...oldState, ...acceptedFiles]);
    };

    const handleRemoveFile = (file: File): void => {
        setFiles((prevFiles) => prevFiles.filter((_file) => _file.name !== file.name));
    };

    const handleUploadOrganizationUserDocument = () => {
        dispatch(
            uploadUserDocuments(
                selectedOrganization?.currentUser?.uuid ?? '',
                files,
                documentName
            )
        );

        setDocumentName('');
        setFiles([]);
    };

    const handleRemoveDocument = (uuid: string) => {
        setDocUuidToDelete(uuid);
        setIsRemovingDocumentModalOpen(true);
    };

    const handleRemoveUserDocument = (isRemovingDocument: boolean) => {
        isRemovingDocument && dispatch(
            deleteUserDocuments(
                selectedOrganization?.currentUser?.uuid ?? '',
                docUuidToDelete
            )
        );
        setIsRemovingDocumentModalOpen(false);
    };

    const uploadNewDocumentComponent = () => {
        return (
            <Grid container direction='row' spacing={ 4 } sx={ { mt: 5 } }>
                <Grid item container direction='column' justifyContent='flex-start' alignItems='center'
                      spacing={ 2 } xs={ 12 } sm={ 12 } md={ 5 } lg={ 5 } xl={ 5 }>
                    <Grid item sx={ { width: '100%' } }>
                        <TextField
                            disabled={ isUploadingFile }
                            label={ t('businessProfile.details.documents.input.documentName') }
                            placeholder={ t('businessProfile.details.documents.input.documentName.placeholder') }
                            autoFocus
                            fullWidth
                            variant='outlined'
                            value={ documentName }
                            onChange={ (e) => handleDocumentNameChange(e) } />
                    </Grid>
                    <Grid item>
                        <CommonButton
                            btnType={ ButtonTypeEnum.SEND }
                            startIcon={ <CloudUploadRounded /> }
                            loading={ isUploadingFile }
                            onClick={ handleUploadOrganizationUserDocument }
                            disabled={ isLoadingFiles || files.length <= 0 || !documentName }>
                            { t('businessProfile.details.documents.action.uploadDocument') }
                        </CommonButton>
                    </Grid>
                </Grid>

                <Grid item xs={ 12 } sm={ 12 } md={ 7 } lg={ 7 } xl={ 7 }>
                    <FileDropzone
                        maxSize={ AppSettings.MAX_UPLOAD_SIZE }
                        files={ files }
                        isFilesPanelVisible={ true }
                        setFiles={ setFilesHandler }
                        onRemove={ handleRemoveFile }
                        accept='application/pdf' />
                </Grid>
            </Grid>
        );
    };

    const handleDocumentDownload = (documentUuid: string) => {
        dispatch(getMemberDocumentDetails(documentUuid));
    };

    const getDocumentList = () => {
        if (userDocuments && userDocuments?.length <= 0) {
            return (
                <EmptyState message={ t('businessProfile.details.documents.empty') } />
            );
        }

        return (
            userDocuments && userDocuments?.length > 0 &&
            <Box sx={ { mt: 2 } }>
              <List>
                  { userDocuments?.map((document: DocumentSimpleOut) => (
                      <ListItem
                          key={ document.fileName }
                          sx={ {
                              '& + &': {
                                  mt: 1
                              },
                              border: 1,
                              borderColor: 'divider',
                              borderRadius: 1
                          } }>
                          <ListItemIcon>
                              <DuplicateIcon fontSize='small' />
                          </ListItemIcon>
                          <ListItemText
                              primary={ document.documentName }
                              primaryTypographyProps={ {
                                  color: 'textPrimary',
                                  variant: 'subtitle2'
                              } }
                              secondary={ document.fileName } />

                          { isDeletingFile && docUuidToDelete === document.uuid ?
                              <CircularProgress /> :
                              <>
                                  <Tooltip
                                      title={ t('businessProfile.details.documents.action.downloadDocument') }
                                      sx={ { m: 1 } }>
                                      <FileDownloadRounded
                                          fontSize='small'
                                          onClick={() => handleDocumentDownload(document.uuid)} />
                                  </Tooltip>
                                  <Tooltip title={ t('businessProfile.details.documents.action.removeDocument') }
                                           sx={ { m: 1 } }>
                                      <IconButton edge='end' onClick={ () => handleRemoveDocument(document.uuid) }>
                                          <XIcon fontSize='small' />
                                      </IconButton>
                                  </Tooltip>
                              </>
                          }
                      </ListItem>
                  )) }
              </List>
            </Box>
        );
    };

    const userDocumentsList = () => {
        return (
            isLoadingFiles && !isDeletingFile ?
                <SkeletonBlock height={ 150 } /> :
                getDocumentList()
        );
    };

    return (
        <>
            <Card>
                <CardHeader title={ t('businessProfile.details.documents.title') } />
                <CardContent>
                    { userDocumentsList() }
                    { uploadNewDocumentComponent() }
                </CardContent>
            </Card>
            <ConfirmationDialog
                title={ t('businessProfile.details.documents.action.removeDocument.title') }
                message={ t('businessProfile.details.documents.action.removeDocument.message') }
                onConfirm={ () => handleRemoveUserDocument(true) }
                onCancel={ () => handleRemoveUserDocument(false) }
                open={ isRemovingDocumentModalOpen } />
        </>
    );
};

export default OrganizationDocuments;
