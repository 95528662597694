import type { FC } from 'react';
import { useRef, useState } from 'react';
import { Avatar, Box, ButtonBase, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import maxImage from '../../assets/img/avatars/max.jpg';
import { DarkMode, LightMode, LogoutRounded, Person } from '@mui/icons-material';
import useSettings from '../../hooks/useSettings';
import { THEMES } from '../../common/constants';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Membership } from 'group-service-api';
import SignaturesCounter from '../profile/SignaturesCounter';
import auth from '../../auth/auth';
import { Misc } from '../../utils/misc';
import { IdentificationData } from 'profile-manager-api';
import { ApiGetDocumentsResponse } from 'document-scanner-api';

interface IAccountPopover {
    activeGroup?: Membership;
    isProfileVerified: boolean;
    profileData?: ApiGetDocumentsResponse;
    userIdentificationData?: IdentificationData;
}

const AccountPopover: FC<IAccountPopover> = ({ activeGroup, isProfileVerified, profileData, userIdentificationData }) => {
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const navigate = useNavigate();
    const { settings, saveSettings } = useSettings();
    const [ open, setOpen ] = useState<boolean>(false);
    const { t } = useTranslation();

    const isDarkMode = settings.theme === THEMES.DARK;
    const userIdentifier = Misc.resolveUserIdentifier(userIdentificationData);
    const faceImage = Misc.getUserAvatar(profileData);
    const userAvatar = faceImage ? `data:image/jpeg;base64,${ faceImage }` : maxImage;


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        auth.clearLoginData();
        navigate('/login');
    };

    const handleNavigateProfile = () => {
        handleClose();
        navigate('/dashboard/profile/general');
    };

    const changeThemeHandler = () => {
        const updatedSettings = { ...settings };
        updatedSettings.theme = isDarkMode ? THEMES.LIGHT : THEMES.DARK;
        saveSettings(updatedSettings);
    };

    return (
        <>
            <Box component={ ButtonBase }
                 onClick={ handleOpen }
                 ref={ anchorRef }
                 sx={ { alignItems: 'center', display: 'flex' } }>
                <Avatar src={ userAvatar } sx={ { height: 32, width: 32 } } variant="rounded" />
            </Box>
            <Popover anchorEl={ anchorRef.current }
                     anchorOrigin={ {
                         horizontal: 'center',
                         vertical: 'bottom'
                     } }
                     keepMounted
                     open={ open }
                     onClose={ handleClose }
                     PaperProps={ { sx: { width: 300 } } }>
                <Box sx={ { alignItems: 'center', p: 2, display: 'flex' } }>
                    <Avatar src={ userAvatar }
                            sx={ {
                                height: 45,
                                width: 45
                            } } />

                    <Box sx={ { ml: 1 } }>
                        <Typography color="textPrimary" variant="subtitle2">
                            { userIdentifier ?? '' }
                        </Typography>

                        { activeGroup && (
                            <Typography color="textSecondary" variant="body2">
                                { activeGroup?.group?.name ?? '' }
                            </Typography>
                        ) }

                        { isProfileVerified && <SignaturesCounter /> }
                    </Box>
                </Box>

                <Divider />
                <Box sx={ { mb: 1, mt: 2 } }>
                    { activeGroup === undefined &&
                      <MenuItem onClick={ handleNavigateProfile }>
                        <ListItemIcon>
                          <Person fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={
                            <Typography color="textPrimary" variant="subtitle2">
                                { t('dashboard.sidebar.documents.account.profile') }
                            </Typography>
                        }
                        />
                      </MenuItem>
                    }
                    <MenuItem onClick={ changeThemeHandler }>
                        <ListItemIcon>{ isDarkMode ? <DarkMode fontSize="small" /> : <LightMode
                            fontSize="small" /> }</ListItemIcon>
                        <ListItemText primary={
                            <Typography color="textPrimary" variant="subtitle2">
                                { isDarkMode ? t('accountPopover.darkMode.on') : t('accountPopover.darkMode.off') }
                            </Typography>
                        }
                        />
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={ handleLogout }>
                        <ListItemIcon>
                            <LogoutRounded fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={
                            <Typography color="textPrimary" variant="subtitle2">
                                { t('accountPopover.logout') }
                            </Typography>
                        }
                        />
                    </MenuItem>
                </Box>
            </Popover>
        </>
    );
};

export default AccountPopover;
