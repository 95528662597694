import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import {
    ApiInitPaymentResponse,
    ApiWsPayResultRequest,
    Bundle,
    InitPaymentRequest,
    ListResponsePayableItem,
    PayableGroup,
    PayableGroupPayableEnum,
    PayableItem,
    PayableItemPayableEnum,
    ProvidedPayableItem,
    ProvidePayableRequest,
    ProvidePayableRequestPayableEnum,
    TransactionStatistics
} from 'payment-service-api';
import { PaymentsHelper } from '../utils/payments-helper';
import { handleError } from './errors-handling';
import apis from 'auth/apis';
import { AppSettings } from '../common/app-settings';
import { IFilters } from '../components/payables/PayablesFilters';
import { endOfMonth, startOfMonth } from 'date-fns';
import { ProvidedPayableItemType } from '../model/common/payments/ProvidedPayableItemType';
import { UnprotectedRoutes } from '../routes';

interface IPaymentState {
    availablePlans: Bundle[] | undefined;
    isLoading: boolean;
    isLoadingPayables: boolean;
    isPaymentSuccessful: boolean;
    issueFinaCertAutomatically: boolean;
    paymentSuccessful: boolean;
    providedPayables: ProvidedPayableItem[] | undefined;
    receivedPayables: ProvidedPayableItem[] | undefined;
    selectedPlan: Bundle | undefined;
    signatureBundles: Bundle[] | undefined;
    transactionStatistics: TransactionStatistics | undefined;
    userPayables: ListResponsePayableItem | undefined;
    wsPayRedirectUrl: string | undefined;
}

const initialState: IPaymentState = {
    availablePlans: undefined,
    isLoading: false,
    isLoadingPayables: false,
    isPaymentSuccessful: false,
    issueFinaCertAutomatically: false,
    paymentSuccessful: false,
    providedPayables: undefined,
    receivedPayables: undefined,
    selectedPlan: undefined,
    signatureBundles: undefined,
    transactionStatistics: undefined,
    userPayables: undefined,
    wsPayRedirectUrl: undefined
};

const paymentSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        addPaidSignatures(state: IPaymentState, action: PayloadAction<number>): void {
            const userPayables = state.userPayables;
            if (userPayables) {
                const paidSignaturesIndex = userPayables.hits.findIndex((pi: PayableItem) => pi.payable === PayableItemPayableEnum.PaidSignature);
                if (paidSignaturesIndex !== -1) {
                    const paidSignatures = userPayables.hits[paidSignaturesIndex];
                    paidSignatures.amount += action.payload;
                    userPayables.hits[paidSignaturesIndex] = paidSignatures;
                    state.userPayables = userPayables;
                }
            }
        },
        deleteProvidedPayableItem(state: IPaymentState, action: PayloadAction<string>): void {
            const providedPayables = state.providedPayables || [];
            const index = providedPayables.findIndex((pp: ProvidedPayableItem) => pp.uuid === action.payload);
            providedPayables.splice(index, 1);
            state.providedPayables = providedPayables;
        },
        providedPayableItem(state: IPaymentState, action: PayloadAction<ProvidedPayableItem>): void {
            const providedPayables = state.providedPayables || [];
            providedPayables.push(action.payload);
            state.providedPayables = providedPayables;
        },
        resetOrganizationPayables(state: IPaymentState): void {
            state.providedPayables = undefined;
            state.receivedPayables = undefined;
            state.userPayables = undefined;
        },
        resetPaymentSuccessful(state: IPaymentState): void {
            state.paymentSuccessful = false;
        },
        setAllPlans(state: IPaymentState, action: PayloadAction<Bundle[] | undefined>): void {
            state.availablePlans = action.payload;
            state.isLoading = false;
        },
        setCheckoutItem(state: IPaymentState, action: PayloadAction<string>): void {
            const selectedPlan = state.availablePlans?.find((b: Bundle) => b.uuid === action.payload);
            localStorage.removeItem(AppSettings.BUNDLE_UUID);
            localStorage.setItem(AppSettings.BUNDLE_UUID, JSON.stringify(selectedPlan));
            state.selectedPlan = selectedPlan;
            state.isLoading = false;
        },
        setCheckoutSignatureItem(state: IPaymentState, action: PayloadAction<string>): void {
            const selectedSignatureBundles = state.signatureBundles?.find((b: Bundle) => b.uuid === action.payload);
            localStorage.removeItem(AppSettings.BUNDLE_UUID);
            localStorage.setItem(AppSettings.BUNDLE_UUID, JSON.stringify(selectedSignatureBundles));
            state.selectedPlan = selectedSignatureBundles;
            state.isLoading = false;
        },
        setIsLoading(state: IPaymentState, action: PayloadAction<boolean>): void {
            state.isLoading = action.payload;
        },
        setIsLoadingPayables(state: IPaymentState, action: PayloadAction<boolean>): void {
            state.isLoadingPayables = action.payload;
        },
        setIsPaymentSuccessful(state: IPaymentState, action: PayloadAction<boolean>): void {
            state.isPaymentSuccessful = action.payload;
        },
        setIssueFinaCertAutomatically(state: IPaymentState, action: PayloadAction<boolean>): void {
            state.issueFinaCertAutomatically = action.payload;
        },
        setPaymentSuccessful(state: IPaymentState): void {
            state.paymentSuccessful = true;
        },
        setProvidedPayables(state: IPaymentState, action: PayloadAction<ProvidedPayableItem[] | undefined>): void {
            state.providedPayables = action.payload;
        },
        setReceivedPayables(state: IPaymentState, action: PayloadAction<ProvidedPayableItem[] | undefined>): void {
            state.receivedPayables = action.payload;
        },
        setSignatureBundles(state: IPaymentState, action: PayloadAction<Bundle[] | undefined>): void {
            state.signatureBundles = action.payload;
        },
        setTransactionStatistics(state: IPaymentState, action: PayloadAction<TransactionStatistics | undefined>): void {
            state.transactionStatistics = action.payload;
        },
        setUserPayables(state: IPaymentState, action: PayloadAction<ListResponsePayableItem | undefined>): void {
            state.userPayables = action.payload;
            state.isLoading = false;
        },
        setWsPayRedirectUrl(state: IPaymentState, action: PayloadAction<ApiInitPaymentResponse>): void {
            state.wsPayRedirectUrl = action.payload.redirectUrl;
        }
    }
});

export const loadPlans = (): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            dispatch(paymentSlice.actions.setIsLoading(true));
            const allBundles = await apis.bundlesApi().bundles(); //vraća: Bundle[]
            const allPlans = PaymentsHelper.filterBundlesForPlans(allBundles.data); // vraća: Bundle[] | undefined
            const allSignatureBundles = PaymentsHelper.filterBundlesForSignatures(allBundles.data); //vraća: Bundle[] | undefined

            dispatch(paymentSlice.actions.setAllPlans(allPlans));
            dispatch(paymentSlice.actions.setSignatureBundles(allSignatureBundles));
        } catch (err) {
            dispatch(paymentSlice.actions.setIsLoading(false));
            dispatch(handleError(err));
        }
    };

export const getEntityPayables = (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(paymentSlice.actions.setIsLoading(true));
        apis.payablesApi()
            .getPayables()
            .then(({ data }) => {
                dispatch(paymentSlice.actions.setUserPayables(data));
            })
            .catch((err) => {
                dispatch(paymentSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const getProvidedPayablesItems = (
    entityUuidList: string[],
    providedPayableTypeList: PayableItemPayableEnum[],
    type: ProvidedPayableItemType
): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(paymentSlice.actions.setIsLoadingPayables(true));
        apis.providedPayableItemsApi()
            .getProvidedPayableItems(
                providedPayableTypeList,
                entityUuidList,
                type
            )
            .then(({ data }) => {
                const payload = data && data.hits
                    ? Array.from(data.hits)
                    : undefined;
                type === ProvidedPayableItemType.RECEIVED && dispatch(paymentSlice.actions.setReceivedPayables(payload));
                type === ProvidedPayableItemType.SENT && dispatch(paymentSlice.actions.setProvidedPayables(payload));
                dispatch(paymentSlice.actions.setIsLoadingPayables(false));
            })
            .catch((err) => {
                dispatch(paymentSlice.actions.setIsLoadingPayables(false));
                dispatch(handleError(err));
            });
    };

export const initiateTransaction = (bundleUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(paymentSlice.actions.setIsLoading(true));
        apis.wsPayResultApi()
            .initiatePayment({
                bundleUuid: bundleUuid,
                returnUrl: process.env.REACT_APP_REDIRECT_URL + UnprotectedRoutes.unprotected_wsPay_result_route.path,
            } as InitPaymentRequest)
            .then(({ data }) => {
                dispatch(paymentSlice.actions.setWsPayRedirectUrl(data));
            })
            .catch((err) => {
                dispatch(paymentSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const sendWsPayResult = (apiWsPayResultRequest: ApiWsPayResultRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(paymentSlice.actions.setIsLoading(true));
        apis.wsPayResultApi()
            .wsPayResult(apiWsPayResultRequest)
            .then(() => {
                dispatch(paymentSlice.actions.setIsPaymentSuccessful(true));
                const bundleInfo = localStorage.getItem(AppSettings.BUNDLE_UUID);
                if (bundleInfo) {
                    const bundle: Bundle = JSON.parse(bundleInfo);
                    const paidSignatureBundle = Array.from(bundle.payableGroups)
                        ?.find((pg: PayableGroup) => pg.payable === PayableGroupPayableEnum.PaidSignature);
                    const isPaidSignatureBundle: boolean = bundle && !!paidSignatureBundle;
                    dispatch(paymentSlice.actions.setIssueFinaCertAutomatically(!isPaidSignatureBundle));
                    isPaidSignatureBundle && dispatch(paymentSlice.actions.addPaidSignatures(paidSignatureBundle?.amount || 0));
                }
                localStorage.removeItem(AppSettings.BUNDLE_UUID);
            })
            .catch((err) => {
                dispatch(paymentSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const getTransactionStats = (filters?: IFilters): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(paymentSlice.actions.setIsLoading(true));
        const dateFrom = filters && filters.startDate ? filters.startDate.toISOString() : startOfMonth(new Date()).toISOString();
        const dateTo = filters && filters.endDate ? filters.endDate.toISOString() : endOfMonth(new Date()).toISOString();
        apis.paymentTransactionsApi()
            .getStatistics(dateFrom, dateTo)
            .then(({ data }) => {
                dispatch(paymentSlice.actions.setTransactionStatistics(data));
                dispatch(paymentSlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(paymentSlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const provideFinaCertPayable = (providedForUserUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(paymentSlice.actions.setIsLoadingPayables(true));
        const request: ProvidePayableRequest = {
            amount: 1,
            payable: ProvidePayableRequestPayableEnum.FinaLcpCertificate,
            providedForUserUuid: providedForUserUuid
        };
        apis.providedPayableItemsApi()
            .providePayableItem(request)
            .then(({ data }) => {
                dispatch(paymentSlice.actions.providedPayableItem(data));
                dispatch(paymentSlice.actions.setIsLoadingPayables(false));
            })
            .catch((err) => {
                dispatch(paymentSlice.actions.setIsLoadingPayables(false));
                dispatch(handleError(err));
            });
    };

export const revokeFinaCertPayable = (providedPayableUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(paymentSlice.actions.setIsLoadingPayables(true));
        apis.providedPayableItemsApi()
            .deleteProvidedPayable(providedPayableUuid)
            .then(() => {
                dispatch(paymentSlice.actions.deleteProvidedPayableItem(providedPayableUuid));
                dispatch(paymentSlice.actions.setIsLoadingPayables(false));
            })
            .catch((err) => {
                dispatch(paymentSlice.actions.setIsLoadingPayables(false));
                dispatch(handleError(err));
            });
    };

export const { reducer } = paymentSlice;

export const {
    resetOrganizationPayables,
    setIsPaymentSuccessful,
    setIssueFinaCertAutomatically,
    setCheckoutItem,
    setCheckoutSignatureItem
} = paymentSlice.actions;

export default paymentSlice;
