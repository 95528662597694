import type { FC } from 'react';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import MySurveyPage from './MySurveyPage';
import LanguagePopover from '../../components/dashboard/LanguagePopover';
import idyLogo from '../../assets/img/illustrations/Identyum-SIGN-logo-light.svg';
import {useTranslation} from 'react-i18next';

const MySurveyUnprotected: FC = () => {
    const { t } = useTranslation();
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Box>
            <Box sx={{ textAlign: 'right' }}>
                {mdUp ?? <Typography>Language</Typography>}
                <LanguagePopover />
            </Box>
            <Box textAlign="center" margin="auto" mb={4}>
                <img alt={'Idy_logo'} src={idyLogo} style={{ height: mdUp ? 100 : 50, width: 'auto' }} />
                <Typography>{t('MySurveyUnprotected.header.welcomeInfo')}</Typography>
            </Box>
            <MySurveyPage />
        </Box>
    );
};

export default MySurveyUnprotected;
