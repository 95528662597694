import type { FC } from 'react';
import { Box, Dialog, LinearProgress, Typography } from '@mui/material';
import { People } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface IGroupSwitchOverlayProps {
    groupName: string | undefined;
    open: boolean;
}

const GroupSwitchOverlay: FC<IGroupSwitchOverlayProps> = (props) => {
    const { groupName, open } = props;

    const { t } = useTranslation();

    return (
        <Dialog open={open} fullWidth={true}>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Box sx={{ mb: 5, mt: 10, opacity: '0.3', transform: 'scale(4)' }}>
                    <People />
                </Box>
                <Typography align="center" variant="body1" sx={{ mb: 5 }}>
                    {t('groups.summary.redirectMessage')}
                </Typography>
                <Typography style={{ fontSize: '1.125rem' }} sx={{ mb: 5 }}>
                    <b>{groupName}</b>
                </Typography>
            </Box>
            <LinearProgress variant="indeterminate" />
        </Dialog>
    );
};

export default GroupSwitchOverlay;