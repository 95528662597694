import type { FC } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { DocumentInfo } from 'idy-certifier-api';
import { useTranslation } from 'react-i18next';

interface IDocumentQrCodeStatus {
    document?: DocumentInfo;
}

const DocumentQrCodeStatus: FC<IDocumentQrCodeStatus> = ({ document }) => {
    const { t } = useTranslation();

    return (
        !document?.qrCodeIncluded ? (
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Box sx={{ mt: 1, textAlign: 'center', fontStyle: 'italic' }}>
                        <Typography color={'red'} variant='body2'>
                            {t('dashboard.sidebar.documentDetails.qrCodeNotIncluded')}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        ) : null
    );
};

export default DocumentQrCodeStatus;
