import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { MemberOut, MemberOutRoleEnum } from 'organizations-service-api';

interface IChangeMemberRoleDialogProps {
    member?: MemberOut;
    open: boolean;
    onCancel: () => void;
    onConfirm: (role: MemberOutRoleEnum) => void;
}

const ChangeMemberRoleDialog: FC<IChangeMemberRoleDialogProps> = (props: IChangeMemberRoleDialogProps) => {
    const { member, onCancel, onConfirm, open } = props;
    const { t } = useTranslation();

    const [selectedRole, setSelectedRole] = useState<MemberOutRoleEnum | undefined>(member?.role);

    useEffect(() => {
        setSelectedRole(member?.role);
    }, [member]);

    const handleConfirm = () => {
        selectedRole && onConfirm(selectedRole);
    };

    const handleCancel = () => {
        setSelectedRole(member?.role);
        onCancel();
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle sx={{ ml: 2, mr: 2, mt: 2, textAlign: 'center' }}>
                {t('businessProfile.details.member.changeRoleModal.title', { memberName: member?.person.fullName })}
            </DialogTitle>

            <DialogContent sx={{ textAlign: 'center' }}>
                <Typography color='textSecondary' variant='body1'>{t('businessProfile.details.member.changeRoleModal.currentRoles', { currentRole: member?.role })}</Typography>

                <Typography sx={{ mb: 2, mt: 5 }} color='textPrimary' variant='body1'>{t('businessProfile.details.member.changeRoleModal.assignRole')}</Typography>
                <ButtonGroup>
                    <CommonButton
                        btnType={ButtonTypeEnum.NO_ICON_CONTAINED}
                        onClick={() => setSelectedRole(MemberOutRoleEnum.Admin)}
                        color='primary'
                        variant={selectedRole === MemberOutRoleEnum.Admin ? 'contained' : 'outlined'}>
                        {t('businessProfile.details.actions.changeRole.admin')}
                    </CommonButton>

                    <CommonButton
                        btnType={ButtonTypeEnum.NO_ICON_CONTAINED}
                        onClick={() => setSelectedRole(MemberOutRoleEnum.Writer)}
                        color='primary'
                        variant={selectedRole === MemberOutRoleEnum.Writer ? 'contained' : 'outlined'}>
                        {t('businessProfile.details.actions.changeRole.writer')}
                    </CommonButton>

                    <CommonButton
                        btnType={ButtonTypeEnum.NO_ICON_CONTAINED}
                        onClick={() => setSelectedRole(MemberOutRoleEnum.Reader)}
                        color='primary'
                        variant={selectedRole === MemberOutRoleEnum.Reader ? 'contained' : 'outlined'}>
                        {t('businessProfile.details.actions.changeRole.reader')}
                    </CommonButton>
                </ButtonGroup>
            </DialogContent>

            <DialogActions sx={{ mb: 2, ml: 2, mr: 2, mt: 5, mx: 'auto' }}>
                <CommonButton btnType={ButtonTypeEnum.CLOSE} sx={{ mr: 2 }} onClick={handleCancel} color='secondary'>
                    {t('actions.cancel')}
                </CommonButton>
                <CommonButton btnType={ButtonTypeEnum.CONFIRM} onClick={handleConfirm} color='primary' variant='contained'>
                    {t('actions.confirm')}
                </CommonButton>
            </DialogActions>
        </Dialog>
    );
};

export default ChangeMemberRoleDialog;
