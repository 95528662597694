import type { FC } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';

interface IAnsweredCheckboxProps {
    checked: boolean;
    label: string;
}

const AnsweredCheckbox: FC<IAnsweredCheckboxProps> = (props) => {
    const { label, checked } = props;

    return (
        <Box sx={{ mx: 3 }}>
            <FormControlLabel
                control={<Checkbox disabled name={label} value={label} checked={checked} inputProps={{ 'aria-label': 'controlled' }} />}
                label={label}
            />
        </Box>
    );
};

export default AnsweredCheckbox;
