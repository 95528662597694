import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@mui/material';
import image403Light from '../assets/img/error/error401_light.svg';
import image403Dark from '../assets/img/error/error401_dark.svg';
import image404Light from '../assets/img/error/error404_light.svg';
import image404Dark from '../assets/img/error/error404_light.svg';
import useSettings from '../hooks/useSettings';
import { THEMES } from '../common/constants';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from './shared/CommonButton';
import { useAppDispatch } from '../store/hooks';
import { clearErrorMessage } from '../slices/errors-handling';
import { useEffect } from 'react';

interface INotFoundProps {
    is403?: boolean;
}

const NotFound: FC<INotFoundProps> = ({ is403 }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const { settings } = useSettings();
    const isDarkMode = settings.theme === THEMES.DARK;

    const getBackToHomepage = () => {
        navigate('/');
        dispatch(clearErrorMessage());
    };

    useEffect(() => {
        return () => {
            dispatch(clearErrorMessage());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={ {
            alignItems: 'center',
            backgroundColor: 'background.default',
            display: 'flex',
            minHeight: '100%',
            px: 3,
            py: '80px'
        } }>
            <Container maxWidth='lg'>
                <Typography align='center'
                            color='textPrimary'
                            variant={ mobileDevice ? 'h4' : 'h1' }>
                    { is403 ? t('notFound.title.403') : t('notFound.title.404') }
                </Typography>
                <Typography align='center'
                            color='textSecondary'
                            variant='subtitle2'>
                    { is403 ? t('notFound.subtitle.403') : t('notFound.subtitle.404') }
                </Typography>
                <Box sx={ {
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 6
                } }>
                    {
                        is403 ? (
                            <Box component='img'
                                 src={ isDarkMode ? image403Dark : image403Light }
                                 sx={ {
                                     height: 'auto',
                                     maxWidth: '100%',
                                     width: 400
                                 } } />
                        ) : (
                            <Box component='img'
                                 src={ isDarkMode ? image404Dark : image404Light }
                                 sx={ {
                                     height: 'auto',
                                     maxWidth: '100%',
                                     width: 400
                                 } } />
                        )
                    }
                </Box>
                <Box sx={ {
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 6
                } }>
                    <CommonButton btnType={ ButtonTypeEnum.BACK }
                                  onClick={() => getBackToHomepage()}
                                  variant='outlined'>
                        { t('dashboard.navbar.button.backToHomepage') }
                    </CommonButton>
                </Box>
            </Container>
        </Box>
    );
};

export default NotFound;
