import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import apis from '../auth/apis';
import { PhonesConfig } from 'phone-verifier-api';

interface IPhoneVerifierState {
    dialCodes: PhonesConfig | undefined;
    isLoading: boolean;
}

const initialState: IPhoneVerifierState = {
    dialCodes: undefined,
    isLoading: false,
};

const phoneVerifierSlice = createSlice({
    name: 'phoneVerifier',
    initialState,
    reducers: {
        setDialCodes(state: IPhoneVerifierState, action: PayloadAction<PhonesConfig>) {
            state.dialCodes = action.payload;
            state.isLoading = false;
        },
        setIsLoading(state: IPhoneVerifierState, action: PayloadAction<boolean>): void {
            state.isLoading = action.payload;
        },
    },
});

export const getDialCodes = (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(phoneVerifierSlice.actions.setIsLoading(true));
        apis.phoneVerifierApi()
            .getDialCodes()
            .then(({ data }) => {
                dispatch(phoneVerifierSlice.actions.setDialCodes(data));
            })
            .catch((err) => {
                console.log('**** err ****', err);
            });
    };

export const { reducer } = phoneVerifierSlice;
