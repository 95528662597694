import type { FC } from 'react';
import { Card, CardHeader, Divider, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import Label from '../Label';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { resolveOrganizationStatus } from '../../utils/organizations-helper';
import ImageUploader from '../image-uploader/ImageUploader';
import { MemberOutRoleEnum, OrganizationOutStatusEnum } from 'organizations-service-api';
import { useAppSelector } from '../../store/hooks';

interface IOrganizationDetailsProps {
    isCancelingRequest: boolean;
    isModifyingOrganization: boolean;
    onCancelRequest: () => void;
    onRemoveImage: () => void;
    onsSaveImage: (image: string) => void;
}

const OrganizationDetails: FC<IOrganizationDetailsProps> = (props: IOrganizationDetailsProps) => {
    const {
        isCancelingRequest,
        isModifyingOrganization,
        onCancelRequest,
        onRemoveImage,
        onsSaveImage
    } = props;

    const { t } = useTranslation();
    const { selectedOrganization } = useAppSelector(state => state.organizations);

    const organizationStatus = selectedOrganization ? resolveOrganizationStatus(selectedOrganization) : undefined;
    const statusLabel = organizationStatus?.label ?? 'businessProfile.details.status.na';
    const statusColor = organizationStatus?.color ?? 'info';
    const isUserAdminInOrganization =
        selectedOrganization?.currentUser?.role === MemberOutRoleEnum.Admin ||
        selectedOrganization?.currentUser?.role === MemberOutRoleEnum.Owner;

    return (
        <Card sx={ { mb: 2 } }>
            <CardHeader title={ t('businessProfile.details.title') } />
            <Divider />
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell sx={ { width: '30%' } }>
                            <Typography color="textSecondary" variant="body2">
                                { t('businessProfile.details.label.status') }
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Grid container direction="row" justifyContent="space-between"
                                  sx={ { flexWrap: 'nowrap' } }>
                                <Grid item sx={ { verticalAlign: 'middle' } }>
                                    <Label color={ statusColor }>{ t(statusLabel) }</Label>
                                </Grid>
                                <Grid item>
                                    { selectedOrganization?.status === OrganizationOutStatusEnum.Pending && isUserAdminInOrganization && (
                                        <CommonButton
                                            btnType={ ButtonTypeEnum.CANCEL }
                                            loading={ isCancelingRequest }
                                            disabled={ isCancelingRequest }
                                            onClick={ () => onCancelRequest() }
                                            variant="text">
                                            { t('businessProfile.details.action.cancel') }
                                        </CommonButton>
                                    ) }
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography color="textSecondary" variant="body2">
                                { t('businessProfile.details.label.companyName') }
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="textPrimary" variant="subtitle2">
                                { selectedOrganization?.name }
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography color="textSecondary" variant="body2">
                                { t('businessProfile.details.label.companyOib') }
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="textPrimary" variant="subtitle2">
                                { selectedOrganization?.oib }
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography color="textSecondary" variant="body2">
                                { t('businessProfile.details.label.createdAt') }
                            </Typography>
                        </TableCell>
                        <TableCell>
                            { selectedOrganization?.createdAt && (
                                <Typography color="textPrimary" variant="subtitle2">
                                    { format(new Date(selectedOrganization.createdAt), 'dd.MM.yyyy.') }
                                </Typography>
                            ) }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography color="textSecondary" variant="body2">
                                { t('businessProfile.details.label.lastModifiedAt') }
                            </Typography>
                        </TableCell>
                        <TableCell>
                            { selectedOrganization?.lastModifiedAt && (
                                <Typography color="textPrimary" variant="subtitle2">
                                    { format(new Date(selectedOrganization.lastModifiedAt), 'dd.MM.yyyy.') }
                                </Typography>
                            ) }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography color="textSecondary" variant="body2">
                                { t('businessProfile.details.label.uuid') }
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="textPrimary" variant="subtitle2">
                                { selectedOrganization?.uuid }
                            </Typography>
                        </TableCell>
                    </TableRow>
                    {isUserAdminInOrganization && <TableRow>
                        <TableCell>
                            <Typography color="textSecondary" variant="body2">
                                { t('businessProfile.details.label.logo') }
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <ImageUploader
                                existingImage={ selectedOrganization?.logo }
                                onRemoveImage={ onRemoveImage }
                                onSaveImage={ onsSaveImage }
                                maxNumber={ 1 }
                                isRemovingImage={ isModifyingOrganization }
                                isSavingImage={ isModifyingOrganization } />
                        </TableCell>
                    </TableRow> }
                </TableBody>
            </Table>
        </Card>
    );
};

export default OrganizationDetails;
