import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

interface IAuthState {
    isUserAuthenticated: boolean;
    token: string;
}

interface IAuthStatePayload {
    isUserAuthenticated: boolean;
    token: string;
}

const initialState: IAuthState = {
    isUserAuthenticated: false,
    token: ''
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuthenticated(state: IAuthState, action: PayloadAction<IAuthStatePayload>): void {
            state.isUserAuthenticated = action.payload.isUserAuthenticated;
            state.token = action.payload.token;
        }
    }
});

export const setIsUserAuthenticated = (authPayload: IAuthStatePayload): AppThunk => async (dispatch): Promise<void> => {
    dispatch(authSlice.actions.setIsAuthenticated(authPayload));
};

export const { reducer } = authSlice;

export default authSlice;
