import type { FC } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface ILoadingComponentProps {
    size?: number;
    title?: string;
}

const LoadingComponent: FC<ILoadingComponentProps> = ({size, title}) => {

    return (
        <Box sx={{ ml: 7, mr: 7, mt: 3, p: 3, textAlign: 'center' }}>
            <Typography
                align='center'
                color='textPrimary'
                gutterBottom variant='h5'>
                {title ?? ''}
            </Typography>
            <CircularProgress
                sx={{ mt: 5, mb: 5 }}
                size={size ? `${size}rem` : '5rem'} />
        </Box>
    );
};

export default LoadingComponent;
