import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { UnprotectedRoutes } from '../../routes';
import { useNavigate } from 'react-router-dom';

interface IVerifyProfileProps {
  title?: string;
  subtitle?: string;
  description?: string;
}

const VerifyProfile: FC<IVerifyProfileProps> = ({ title, subtitle, description }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleVerifyProfile = () => {
      if (UnprotectedRoutes.unprotected_refresh_route.path) {
          navigate(UnprotectedRoutes.unprotected_refresh_route.path);
          localStorage.setItem('fullScopeRedirectUrl', window.location.href);
      }
  };

  return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Typography variant="h3" sx={{mb: 3}}>
          { title ?? t('profile.verifyProfile.title') }
        </Typography>
        <Typography variant="subtitle1" sx={{mb: 3}}>
          { subtitle ?? t('profile.verifyProfile.subtitle') }
        </Typography>
        <Typography variant="body2"
                    sx={{
                      backgroundColor: theme.palette.info.main,
                      borderRadius: 1,
                      color: theme.palette.info.contrastText,
                      m: 5,
                      padding: 2,
                      textAlign: 'center',
                    }}>
          { description ?? t('profile.verifyProfile.description') }
        </Typography>

        <CommonButton btnType={ButtonTypeEnum.SIGN} onClick={handleVerifyProfile} sx={{mb: 3}}>
          { t('profile.verifyProfile.btn') }
        </CommonButton>
      </Box>
  );
};

export default VerifyProfile;