import { FC } from 'react';
import { Card, CardContent, Skeleton } from '@mui/material';

interface ISkeletonBlockProps {
    height?: number;
}

const SkeletonCard: FC<ISkeletonBlockProps> = (props) => {
    return (
        <Card sx={ { mb: 2, mt: 2 } }>
            <CardContent>
                <Skeleton sx={ { borderRadius: 1 } }
                          height={ props.height ? props.height : 200 }
                          animation="wave"
                          variant="rectangular" />
            </CardContent>
        </Card>
    );
};

export default SkeletonCard;
