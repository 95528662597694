import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Email } from 'email-verifier-api';
import { AppThunk } from '../store';
import apis from '../auth/apis';
import { ErrorType, IError } from 'model/common/error';
import otpModalSlice from './otp-modal';
import { Reason } from 'common/constants';

interface IEmailsState {
    emails: Email[];
    error: IError | undefined;
    isLoading: boolean;
    isSubmitingEmail: boolean;
}

const initialState: IEmailsState = {
    emails: [],
    error: undefined,
    isLoading: false,
    isSubmitingEmail: false,
};

const emailsSlice = createSlice({
    name: 'emails',
    initialState,
    reducers: {
        addEmailToList(state: IEmailsState, action: PayloadAction<Email>): void {
            state.emails.push(action.payload);
        },
        clearErrorMessage(state: IEmailsState) {
            state.error = undefined;
        },
        deleteEmail(state: IEmailsState, action: PayloadAction<string>): void {
            state.emails = state.emails.filter((email: Email) => email.uuid !== action.payload);
        },
        setDefault(state: IEmailsState, action: PayloadAction<string>): void {
            state.emails = state.emails.map((email: Email) => {
                if (email.uuid === action.payload) {
                    return { ...email, defaultEmail: true };
                }
                return { ...email, defaultEmail: false };
            });
        },
        setEmailList(state: IEmailsState, action: PayloadAction<Email[]>): void {
            state.emails = action.payload;
        },
        setError(state: IEmailsState, action: PayloadAction<IError>): void {
            state.error = action.payload;
        },
        setIsLoading(state: IEmailsState, action: PayloadAction<boolean>): void {
            state.isLoading = action.payload;
        },
        setIsSubmitingEmail(state: IEmailsState, action: PayloadAction<boolean>): void {
            state.isSubmitingEmail = action.payload;
        },
    },
});

export const requestOtp =
    (email: string): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(emailsSlice.actions.setIsSubmitingEmail(true));
            apis.emailVerifierApi()
                .requestOtp(
                    {
                        email: email,
                    },
                )
                .then(
                    () => {
                        dispatch(otpModalSlice.actions.setEmail(email));
                        dispatch(otpModalSlice.actions.showOtpModal('email'));
                    },
                )
                .catch(
                    () => {
                        dispatch(
                            emailsSlice.actions.setError(
                                {
                                    error: true,
                                    errorMessage: 'dashboard.profile.error',
                                    errorType: ErrorType.GENERIC_ERROR,
                                },
                            ),
                        );
                    },
                )
                .finally(
                    () => {
                        dispatch(emailsSlice.actions.setIsSubmitingEmail(false));
                    },
                );
        };

export const deleteMail =
    (uuid: string): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(emailsSlice.actions.deleteEmail(uuid));
            apis.emailVerifierApi()
                .deleteEmail(uuid)
                .catch(() => {
                    dispatch(emailsSlice.actions.setError({
                        error: true,
                        errorMessage: 'dashboard.profile.contact.deleteError',
                        errorType: ErrorType.DELETE_EMAIL_ERROR,
                    }));
                });
        };

export const getEmails =
    (): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(emailsSlice.actions.clearErrorMessage());
            dispatch(emailsSlice.actions.setIsLoading(true));
            apis.emailVerifierApi()
                .getEmails()
                .then((response) => {
                    dispatch(emailsSlice.actions.setEmailList(Array.from(response.data.hits)));
                    dispatch(emailsSlice.actions.setIsLoading(false));
                })
                .catch(() => {
                    dispatch(emailsSlice.actions.setIsLoading(false));
                    dispatch(
                        emailsSlice.actions.setError({
                            error: true,
                            errorMessage: 'dashboard.profile.error',
                            errorType: ErrorType.GET_EMAILS_ERROR,
                        }),
                    );
                });
        };

export const setDefaultEmail =
    (uuid: string): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(emailsSlice.actions.setDefault(uuid));
            apis.emailVerifierApi()
                .updateEmail(uuid, { defaultEmail: true })
                .catch(() => {
                    dispatch(emailsSlice.actions.setError({
                        error: true,
                        errorMessage: 'dashboard.profile.contact.defaultError',
                        errorType: ErrorType.SET_DEFAULT_EMAIL_ERROR,
                    }));
                });
        };

export const addEmail =
    (email: string, otp: string): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(otpModalSlice.actions.setIsSubmitingOtp(true));
            apis.emailVerifierApi()
                .addEmail({ email: email, otp: otp })
                .then((response) => {
                    dispatch(emailsSlice.actions.addEmailToList(response.data));
                    dispatch(otpModalSlice.actions.hideOtpModal());
                })
                .catch((err) => {
                    if (err?.response?.status === 422) {
                        if (err?.response?.data?.type === Reason.OTP_INVALID) {
                            dispatch(
                                otpModalSlice.actions.setError({
                                    error: true,
                                    errorMessage: 'otp.submit.otpInvalid',
                                    errorType: ErrorType.SUBMIT_OTP_ERROR,
                                }),
                            );
                            return;
                        }
                        if (err?.response?.data?.type === Reason.OTP_TEMPORARY_BLOCKED) {
                            dispatch(otpModalSlice.actions.setSubmitBlocked());
                            dispatch(
                                otpModalSlice.actions.setError({
                                    error: true,
                                    errorMessage: 'otp.submit.otpBlocked',
                                    errorType: ErrorType.SUBMIT_OTP_ERROR,
                                }),
                            );
                            return;
                        }
                        if (err?.response?.data?.type === Reason.OTP_EXPIRED) {
                            dispatch(
                                otpModalSlice.actions.setError({
                                    error: true,
                                    errorMessage: 'otp.submit.otpExpired',
                                    errorType: ErrorType.SUBMIT_OTP_ERROR,
                                }),
                            );
                            return;
                        }
                    }
                    dispatch(
                        otpModalSlice.actions.setError({
                            error: true,
                            errorMessage: 'dashboard.profile.error',
                            errorType: ErrorType.SUBMIT_OTP_ERROR,
                        }),
                    );
                })
                .finally(
                    () => {
                        dispatch(otpModalSlice.actions.setIsSubmitingOtp(false));
                    },
                );
        };

export const { reducer } = emailsSlice;

export const { clearErrorMessage } = emailsSlice.actions;

export default emailsSlice;
