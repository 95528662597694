import type { FC } from 'react';
import { ChangeEvent, useState } from 'react';
import { SurveyRequest } from 'survey-service-api';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { UploadFile } from '@mui/icons-material';
import { DocumentsHelper } from '../../utils/documents-helper';

interface IAddSurveyDialogProps {
    onClose: (request?: SurveyRequest) => void;
    open: boolean;
}

const AddSurveyDialog: FC<IAddSurveyDialogProps> = (props) => {
    const { open, onClose } = props;
    const { t } = useTranslation();

    const [request, setRequest] = useState<SurveyRequest>({ name: '', description: '', templatePdfBase64: undefined });
    const [fileName, setFileName] = useState<string>('');

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(request);
    };

    const handleNameValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            name: (event.target as HTMLInputElement).value,
        });
    };

    const handleDescriptionValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            description: (event.target as HTMLInputElement).value,
        });
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (open && (e.code === 'Enter' || e.key === 'Enter')) {
            onClose(request);
            setRequest({ name: '', description: '', templatePdfBase64: undefined });
            setFileName('');
        }
    };

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.item(0);

        if (file) {
            DocumentsHelper.fileToBase64(file).then((value) => {
                setFileName(file.name);

                const content = value as string;

                setRequest({
                    ...request,
                    templatePdfBase64: content.replace('data:application/pdf;base64,', ''),
                });
            });
        }
    };

    return (
        <Dialog fullWidth open={open} onClose={() => onClose()} onKeyUp={handleKeyUp}>
            <DialogTitle>{t('survey.AddSurveyDialog.title')}</DialogTitle>
            <DialogContent dividers>
                <Box m={1}>
                    <TextField
                        autoFocus
                        fullWidth
                        label={t('survey.AddSurveyDialog.textField.placeholder.title')}
                        placeholder={t('survey.AddSurveyDialog.textField.placeholder.title')}
                        required
                        value={request.name}
                        onChange={handleNameValueChange}
                    />
                </Box>
                <Box m={1}>
                    <TextField
                        multiline
                        fullWidth
                        rows={5}
                        placeholder={t('survey.AddSurveyDialog.textField.placeholder.description')}
                        label={t('survey.AddSurveyDialog.textField.placeholder.description')}
                        name={t('survey.AddSurveyDialog.textField.placeholder.description')}
                        value={request.description}
                        onChange={handleDescriptionValueChange}
                    />
                </Box>
                <Box sx={{ textAlign: 'center' }} py={4} borderBottom="1px solid lightgrey">
                    <Box>
                        <Typography variant="caption">{t('survey.AddSurveyDialog.fileUpload.description')}</Typography>
                    </Box>
                    <Button variant="outlined" startIcon={<UploadFile fontSize="small" />} component="label" sx={{ mt: 3 }}>
                        {t('survey.AddSurveyDialog.fileUpload.button.upload')}
                        <input type="file" hidden onChange={handleFileUpload} />
                    </Button>
                    {fileName && <Box border='1px solid lightgrey' borderRadius={8} mt={2} p={2}>{fileName}</Box>}
                </Box>
            </DialogContent>
            <DialogActions>
                <CommonButton btnType={ButtonTypeEnum.CANCEL} onClick={handleCancel}>
                    {t('survey.AddSurveyDialog.dialogActions.cancel')}
                </CommonButton>
                <CommonButton btnType={ButtonTypeEnum.ADD} onClick={handleOk}>
                    {t('survey.AddSurveyDialog.dialogActions.add')}
                </CommonButton>
            </DialogActions>
        </Dialog>
    );
};
export default AddSurveyDialog;
