import { FC } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { Membership } from 'group-service-api';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import EmptyState from '../empty-states/EmptyState';
import { GroupAdd } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

interface IPendingMembershipsList {
    pendingMemberships: Membership[] | undefined;
    confirmAccess: (membership: Membership) => void;
    rejectMembership: (membership: Membership) => void;

}

const PendingMembershipsList: FC<IPendingMembershipsList> = (props) => {
    const { pendingMemberships, confirmAccess, rejectMembership } = props;

    const { t } = useTranslation();
    const params = useParams();
    const theme = useTheme();


    const { membershipUuid } = params;

    return (
        <Box>
            { pendingMemberships && pendingMemberships?.length > 0 ? (
                pendingMemberships.map((membership: Membership) => (
                    <Card key={ membership.uuid }
                          sx={ { mb: 3, border: membershipUuid === membership.uuid ? '1px solid #89CFF0' : 'none' } }>
                        <CardContent sx={ { display: 'flex', pt: 3, justifyContent: 'space-between' } }>
                            <Grid container direction='column' justifyContent='center' alignItems='flex-start'>
                                <Grid item>
                                    <Box sx={ { display: 'flex', alignItems: 'center' } }>
                                        <Typography sx={{ fontWeight: 'bold', ml: 1 }} >
                                            { membership.group.name.toUpperCase() }
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    { membership.group.organizationName &&
                                      <Box sx={{ ml: 1, mt: 1, color: theme.palette.text.secondary }}>
                                        <Trans
                                            i18nKey='groups.pending.memberships.list.organizationGroup'
                                            values={{ organizationName: membership.group.organizationName }}
                                            components={ { italic: <i />, bold: <strong /> } } />
                                      </Box>
                                    }
                                </Grid>
                            </Grid>


                            <Box>
                                <CommonButton
                                    fullWidth={true}
                                    btnType={ ButtonTypeEnum.CLOSE }
                                    variant='text'
                                    onClick={ () => rejectMembership(membership) } sx={ { mt: 1 } }>
                                    { t('groups.pending.memberships.list.decline') }
                                </CommonButton>
                                <CommonButton
                                    fullWidth={true}
                                    btnType={ ButtonTypeEnum.CONFIRM }
                                    variant='contained'
                                    onClick={ () => confirmAccess(membership) } sx={ { mb: 1 } }>
                                    { t('groups.pending.memberships.list.confirm') }
                                </CommonButton>
                            </Box>
                        </CardContent>
                    </Card>
                ))
            ) : (
                <EmptyState message={ t('groups.pending.memberships.list.empty.message') } icon={ <GroupAdd /> } />
            ) }
        </Box>
    );
};

export default PendingMembershipsList;
