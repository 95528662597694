import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import { Box, TextField, Theme, useMediaQuery } from '@mui/material';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';

interface IOibInsertProps {
    onConfirm: (oib: string) => void;
}

const OibInsert: FC<IOibInsertProps> = ({ onConfirm }) => {
    const { t } = useTranslation();

    const [value, setValue] = useState<string>('');

    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value as string;
        const onlyNumbersAndLetters = value.replace(/[^0-9]/g, '');
        setValue(onlyNumbersAndLetters);
    };

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            onConfirm(value);
        }
    };

    const handleConfirm = () => {
        onConfirm(value);
    };

    return (
        <Box sx={ { mt: 3, textAlign: 'center' } }>
            <TextField
                type={ 'text' }
                autoFocus
                inputProps={ { maxLength: 11 } }
                fullWidth
                label={ 'OIB' }
                onChange={ handleChange }
                onKeyDown={ keyDownHandler }
                placeholder={ t('dashboard.sidebar.documents.enterPin.textField.title') }
                rows={ 1 }
                value={ value }
                variant='outlined' />
            <Box sx={{ mt: 7, pl: !mdUp ? 3 : 0, pr: !mdUp ? 3 : 0 }}>
                <CommonButton
                    fullWidth={!mdUp}
                    btnType={ButtonTypeEnum.NO_ICON_CONTAINED}
                    onClick={handleConfirm}>
                    {t('dashboard.profile.general.finaLcp.createUser')}
                </CommonButton>
            </Box>
        </Box>
    );
};

export default OibInsert;
