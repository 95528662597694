import type { FC } from 'react';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material';
import { Box, Chip, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { setCheckoutItem } from '../../../slices/payments';
import { PaymentsHelper, PlanType } from '../../../utils/payments-helper';
import { PrivateRoutes } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import CommonButton, { ButtonTypeEnum } from '../../shared/CommonButton';
import { AmountCurrencyCurrencyEnum, ListResponsePayableItem, ProvidedPayableItem, ProvidedPayableItemPayableEnum } from 'payment-service-api';
import numeral from 'numeral';
import { useTheme } from '@mui/material/styles';
import useSettings from '../../../hooks/useSettings';
import { THEMES } from '../../../common/constants';
import { ApiGetDocumentsResponse } from 'document-scanner-api';

export interface IFeature {
    name: string;
    quantity: number | string;
}

interface IPricingPlanProps {
    currency: AmountCurrencyCurrencyEnum;
    description: string;
    features: IFeature[];
    hasValidFinaCert: boolean;
    image: string;
    isLoadingUserData: boolean;
    name: string;
    onIssueFinaCert: () => void;
    placeholderName: string;
    planType: string;
    price: number;
    profileData?: ApiGetDocumentsResponse;
    sx?: SxProps<Theme>;
    uuid: string;
    userPayables?: ListResponsePayableItem;
    receivedPayables?: ProvidedPayableItem[];
}

const PricingPlan: FC<IPricingPlanProps> = (props) => {
    const {
        currency,
        description,
        features,
        hasValidFinaCert,
        image,
        isLoadingUserData,
        name,
        onIssueFinaCert,
        placeholderName,
        planType,
        price,
        profileData,
        receivedPayables,
        sx,
        userPayables,
        uuid,
        ...other
    } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const { settings } = useSettings();

    const activePlan = hasValidFinaCert ? PlanType.ADVANCED_PLAN : PlanType.BASIC_PLAN;
    const receivedFinaPayablesAmount: number = receivedPayables?.reduce((acc: number, curr: ProvidedPayableItem) => curr.payable === ProvidedPayableItemPayableEnum.FinaLcpCertificate ? acc + curr.amount : acc, 0) ?? 0;
    const hasFinaCertPayable: boolean = PaymentsHelper.hasFinaCertPayable(userPayables) || receivedFinaPayablesAmount > 0;

    const isDarkMode = settings.theme === THEMES.DARK;

    const hasCroatianDocument = PaymentsHelper.checkDocumentNationalityCode('HRV', profileData);
    const hasHungarianDocument = PaymentsHelper.checkDocumentNationalityCode('HUN', profileData);

    const upgradePlanClickHandler = () => {
        dispatch(setCheckoutItem(uuid));
        navigate(PrivateRoutes.private_checkout_route.path ?? '');
    };

    const getActionButton = () => {
        if (planType === PlanType.BASIC_PLAN) {
            return null;
        }

        if (planType === PlanType.ADVANCED_PLAN) {
            return getAdvancedPlanActionButton();
        }

        return null;
    };

    const getAdvancedPlanActionButton = () => {
        // korisnik nema HR ili HU dokument
        // Coming soon...
        if (!hasCroatianDocument && !hasHungarianDocument) {
            return (
                <CommonButton
                    variant="contained"
                    btnType={ ButtonTypeEnum.NO_ICON }
                    disabled={ true }
                    fullWidth={ true }>
                    { t('shop.comingSoon') }
                </CommonButton>
            );
        }

        // korisnik nema certifikat, a ima kupljenu mogućnost izdavanja certa
        // issue fina cert - akcija
        if (!hasValidFinaCert && hasFinaCertPayable) {
            return (
                <CommonButton
                    variant="contained"
                    btnType={ ButtonTypeEnum.NO_ICON }
                    fullWidth={ true }
                    onClick={ () => onIssueFinaCert() }>
                    { t('dashboard.profile.plans.currentPlan.finaEligible') }
                </CommonButton>
            );
        }

        // korisnik ima fina certifikat
        // current plan
        if (hasValidFinaCert) {
            return (
                <CommonButton
                    variant="contained"
                    btnType={ ButtonTypeEnum.NO_ICON }
                    disabled={ true }
                    fullWidth={ true }>
                    { t('dashboard.profile.plans.currentPlan') }
                </CommonButton>
            );
        }

        // upgrade plan - akcija
        return (
            <CommonButton
                variant="contained"
                btnType={ ButtonTypeEnum.NO_ICON }
                fullWidth={ true }
                onClick={ upgradePlanClickHandler }>
                { t('dashboard.profile.plans.upgradePlan') }
            </CommonButton>
        );
    };

    return (
        <Box
            sx={ {
                backgroundColor: theme.palette.background.paper,
                border: isDarkMode ? '' : '1px solid #dcdcdc',
                borderRadius: 1,
                boxShadow: `${ placeholderName === activePlan ? '0px 0px 40px 3px #688eff;' : '' }`,
                display: 'flex',
                flexDirection: 'column',
                ...sx
            } }
            { ...other }>
            <Box sx={ { p: 3 } }>
                <Box
                    sx={ {
                        height: 52,
                        width: 52,
                        '& img': {
                            height: 'auto',
                            width: '100%'
                        }
                    } }>
                    <img alt="" src={ image } />
                </Box>
                <Box sx={ { display: 'flex' } }>
                    <Typography variant="h4">
                        { PaymentsHelper.getCurrencySymbol(currency) + ' ' }
                        { numeral(price).format('0.00') }
                    </Typography>
                    { price > 0 &&
                      <Typography
                        color="textSecondary"
                        sx={ {
                            alignSelf: 'flex-end',
                            ml: 1
                        } }
                        variant="subtitle2">
                          { PaymentsHelper.getHrkPrice(price) }
                      </Typography>
                    }
                </Box>
                <Typography sx={ { mt: 2 } } variant="h6">
                    { t(name) }
                </Typography>
                <Typography color="primaryContrastText" sx={ { mt: 2 } } variant="body2">
                    { t(description) }
                </Typography>
            </Box>
            <Divider />
            <Box
                sx={ {
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    p: 3
                } }>
                { features.map((feature, index: number) => (
                    <Box
                        key={ index }
                        sx={ {
                            alignItems: 'center',
                            display: 'flex',
                            '& + &': {
                                mt: 2
                            }
                        } }>
                        <Chip size="small" label={ feature.quantity } sx={ { width: '30px' } } />
                        <Typography
                            sx={ {
                                fontWeight: 500,
                                ml: 2
                            } }
                            variant="body2">
                            { t(feature.name) }
                        </Typography>
                    </Box>
                )) }
                <Box sx={ { flexGrow: 1 } } />
                <Box
                    sx={ {
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 6
                    } }>
                    { isLoadingUserData && planType !== PlanType.BASIC_PLAN ? (
                        <CommonButton
                            btnType={ ButtonTypeEnum.NO_ICON }
                            fullWidth={ true }
                            loading={ true }>
                            { t('dashboard.profile.plans.currentPlan.finaEligible.waiting') }
                        </CommonButton>
                    ) : getActionButton() }
                </Box>
            </Box>
        </Box>
    );
};

export default PricingPlan;
