import type { FC } from 'react';
import { MembershipRoleEnum, MembershipSimple, MembershipSimpleRoleEnum } from 'group-service-api';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { MembersTableUtil } from './MembersTable.util';

interface IAdminSwitchProps {
    membership: MembershipSimple;
    userRole: MembershipRoleEnum | undefined;
    onChange: (membership: MembershipSimple) => void;
}

const MembersTableAdminSwitch: FC<IAdminSwitchProps> = (props) => {
    const { membership, userRole, onChange } = props;

    return (
        <FormGroup sx={{ alignContent: 'center' }}>
            <FormControlLabel
                sx={{ m: 0 }}
                control={
                    <Switch
                        checked={membership.role === MembershipSimpleRoleEnum.Admin}
                        disabled={!MembersTableUtil.hasOwnerRights(userRole) && !MembersTableUtil.hasAdminRights(userRole)}
                        onChange={() => onChange(membership)}
                    />
                }
                label=''
            />
        </FormGroup>
    );
};

export default MembersTableAdminSwitch;