import { FC, useEffect, useState } from 'react';
import {
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { issueIdyCertificate, loadCertificates } from '../../slices/certificates';
import { format } from 'date-fns';
import Label from 'components/Label';
import CommonButton, { ButtonTypeEnum } from 'components/shared/CommonButton';
import { CertInfoStatusEnum } from 'fina-lcp-certifier-api';
import PDFViewer from 'components/pdf-viewer/PdfViewer';
import useSettings from '../../hooks/useSettings';
import { THEMES } from '../../common/constants';
import { getContractPreview, getSignedContract } from '../../slices/fina-lcp';
import { Misc } from '../../utils/misc';
import { enUS, hr } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes } from '../../routes';

const ProfileCertificates: FC = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { settings } = useSettings();

    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const {
        idyCertificate,
        isLoadingIdyCertificate,
        isIssuingIdyCert
    } = useAppSelector((state) => state.certificates);
    const {
        certInfo,
        finaContractContent,
        isLoading,
        hasValidFinaCertificate
    } = useAppSelector((state) => state.finaLcp);

    const hasValidCert = certInfo?.status === CertInfoStatusEnum.Valid && certInfo?.contractSigned;
    const isDarkMode = settings.theme === THEMES.DARK;

    useEffect(() => {
        if (!idyCertificate || !certInfo) {
            dispatch(loadCertificates());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (hasValidFinaCertificate) {
            dispatch(getSignedContract());
        }

        if (certInfo?.status === CertInfoStatusEnum.Valid && !certInfo.contractSigned) {
            dispatch(getContractPreview());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ certInfo, hasValidFinaCertificate ]);

    const handleIssueIdyCert = () => {
        dispatch(issueIdyCertificate());
    };

    const onClose = () => {
        setIsModalOpen(false);
    };

    const onOpen = () => {
        setIsModalOpen(true);
    };

    const handleIssueCertificate = () => {
        navigate(PrivateRoutes.private_shop_route.path);
    };

    return (
        <Grid container spacing={ 3 }>
            <Grid item lg={ 12 } md={ 12 } xl={ 12 } xs={ 12 }>
                <Card>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{ t('dashboard.profile.certificates.table.cert') }</TableCell>
                                <TableCell>{ t('dashboard.profile.certificates.table.cert.status') }</TableCell>
                                <TableCell
                                    align='right'>{ t('dashboard.profile.certificates.table.date') }</TableCell>
                                <TableCell align='right' />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow hover>
                                { !idyCertificate && isLoadingIdyCertificate ? (
                                    <TableCell colSpan={ 4 } align={ 'center' }>
                                        <Skeleton variant='text' animation={ 'wave' } />
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell>
                                            <Typography color='textPrimary' variant='subtitle2'>
                                                { t('dashboard.profile.certificates.table.cert.identyum') }
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Label color={ idyCertificate ? 'success' : 'error' }>
                                                { idyCertificate
                                                    ? t('dashboard.profile.certificates.table.cert.issued')
                                                    : t('dashboard.profile.certificates.table.cert.notIssued') }
                                            </Label>
                                        </TableCell>
                                        <TableCell align='right'>
                                            { idyCertificate && idyCertificate.validThru
                                                ? format(new Date(idyCertificate.validThru), 'dd MMM, yyyy HH:mm:ss', {
                                                    locale: i18n.language === 'en' ? enUS : hr
                                                })
                                                : '-' }
                                        </TableCell>
                                        <TableCell align='center'>
                                            <CommonButton variant='contained'
                                                          btnType={ ButtonTypeEnum.NO_ICON }
                                                          onClick={ handleIssueIdyCert }
                                                          disabled={ isIssuingIdyCert || !Misc.isEmptyObject(idyCertificate) }
                                                          loading={ isIssuingIdyCert }>
                                                { idyCertificate ? t('dashboard.profile.certificates.issued') : t('dashboard.profile.certificates.issue') }
                                            </CommonButton>
                                        </TableCell>
                                    </>
                                ) }
                            </TableRow>
                            <TableRow hover>
                                { !certInfo ? (
                                    <TableCell colSpan={ 4 } align={ 'center' }>
                                        <Skeleton variant='text' animation={ 'wave' } />
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell>
                                            <Typography color='textPrimary' variant='subtitle2'>
                                                { t('dashboard.profile.certificates.table.cert.fina') }
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Label color={ hasValidCert ? 'success' : 'error' }>
                                                { hasValidCert
                                                    ? t('dashboard.profile.certificates.table.cert.issued')
                                                    : t('dashboard.profile.certificates.table.cert.notIssued') }
                                            </Label>
                                        </TableCell>
                                        <TableCell align='right'>
                                            { hasValidCert && certInfo?.validThru
                                                ? format(new Date(certInfo?.validThru), 'dd MMM, yyyy HH:mm:ss', {
                                                    locale: i18n.language === 'en' ? enUS : hr
                                                })
                                                : '-' }
                                        </TableCell>
                                        <TableCell align='center'>
                                            { hasValidCert ? (
                                                <CommonButton variant='contained'
                                                              btnType={ ButtonTypeEnum.NO_ICON }
                                                              onClick={ onOpen }
                                                              loading={ isLoading }
                                                              disabled={ !finaContractContent }>
                                                    { t('dashboard.profile.certificates.button.preview') }
                                                </CommonButton>
                                            ) : (
                                                <CommonButton variant='contained'
                                                              btnType={ ButtonTypeEnum.NO_ICON }
                                                              onClick={ handleIssueCertificate }
                                                              loading={ isLoading }>
                                                    { t('dashboard.profile.certificates.issue') }
                                                </CommonButton>
                                            ) }
                                        </TableCell>
                                    </>
                                ) }
                            </TableRow>
                        </TableBody>
                        <Dialog open={ isModalOpen } onClose={ onClose } maxWidth='lg'>
                            <DialogTitle>
                                { t('dashboard.profile.consents.table.identyum.consent.content') }
                            </DialogTitle>

                            <DialogContent
                                sx={ { justifyContent: 'center', overflowY: 'auto', overflowX: 'hidden' } }>
                                <PDFViewer document={ finaContractContent ?? '' } />
                            </DialogContent>
                            <DialogActions sx={ { mx: 'auto', mt: 2 } }>
                                { finaContractContent && (
                                    <a href={ 'data:application/pdf;base64,' + finaContractContent }
                                       title={ t('dashboard.profile.certificates.table.cert.download') }
                                       download={ t('dashboard.profile.certificates.table.cert.download.filename') as string }
                                       style={ {
                                           textDecoration: 'none',
                                           color: isDarkMode ? '#688eff' : '#5664D2',
                                           margin: 'auto'
                                       } }>
                                        <CommonButton btnType={ ButtonTypeEnum.DOWNLOAD }
                                                      sx={ {
                                                          backgroundColor: isDarkMode ? '171c24' : 'f4f5f7',
                                                          justifyContent: 'space-between',
                                                          m: 1
                                                      } }>
                                            { t('dashboard.profile.certificates.table.cert.download') }
                                        </CommonButton>
                                    </a>
                                ) }
                                <CommonButton btnType={ ButtonTypeEnum.NO_ICON_CONTAINED } onClick={ onClose }>
                                    { t('dashboard.profile.consents.table.identyum.close') }
                                </CommonButton>
                            </DialogActions>
                        </Dialog>
                    </Table>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ProfileCertificates;
