import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Checkbox, Dialog, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Person } from 'user-document-signer-api';
import { DocumentsHelper } from '../utils/documents-helper';
import CommonButton, { ButtonTypeEnum } from './shared/CommonButton';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { notifyRecipients, resetNotifyRecipients } from '../slices/documents';
import { toast } from 'react-hot-toast';
import auth from '../auth/auth';

interface ISendDocumentModalProps {
    documentUuid: string;
    recipients: Person[]
    onClose: () => void;
    open: boolean;
}

const SendDocumentModal: FC<ISendDocumentModalProps> = (props) => {
    const { documentUuid, onClose, open, recipients } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { activeGroup } = useAppSelector((state) => state.groups);
    const { isSendingDocument, notifyRecipientsSuccessfully } = useAppSelector((state) => state.documents);

    const [ selectedRecipients, setSelectedRecipients ] = useState<Person[]>([]);

    const userUuid = auth.getUserUuid();

    useEffect(() => {
        return () => {
            setSelectedRecipients([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedRecipients(recipients.filter((p: Person) => p.signatureInfo?.signatureRequested && !p.signatureInfo?.signed && p.uuid !== userUuid));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ recipients ]);

    useEffect(() => {
        if (notifyRecipientsSuccessfully) {
            toast.success(t('document.modal.sendDocument.success'));
            dispatch(resetNotifyRecipients());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ notifyRecipientsSuccessfully ]);

    const selectedAllRecipients: boolean = selectedRecipients.length === recipients.length;
    const selectedSomeRecipients: boolean = selectedRecipients?.length > 0 && selectedRecipients?.length < recipients?.length;

    const handleSelectAllRecipients = (event: ChangeEvent<HTMLInputElement>): void => {
        setSelectedRecipients(event.target.checked ? recipients : []);
    };

    const hasSameUuid = (p: Person, person: Person) => p.uuid !== undefined && person.uuid !== undefined && p.uuid === person.uuid;

    const hasSameEmail = (p: Person, person: Person) => p.email !== undefined && person.email !== undefined && p.email === person.email;

    const hasSamePhone = (p: Person, person: Person) => p.phone !== undefined && person.phone !== undefined && p.phone.dialCode === person.phone.dialCode && p.phone.number === person.phone.number;

    const handleSelectRecipient = (event: ChangeEvent<HTMLInputElement>, person: Person): void => {
        const personIndex = selectedRecipients
            ?.findIndex((p: Person) =>
                hasSameUuid(p, person) || hasSameEmail(p, person) || hasSamePhone(p, person)
            ) !== -1;

        if (personIndex) {
            setSelectedRecipients((prevState) => prevState.filter((p: Person) =>
                !hasSameUuid(p, person) && !hasSameEmail(p, person) && !hasSamePhone(p, person)
            ));
        }

        if (!personIndex) {
            setSelectedRecipients((prevState) => [ ...prevState, person ]);
        }
    };

    const send = () => {
        dispatch(
            notifyRecipients(
                selectedRecipients,
                documentUuid,
                activeGroup?.group.uuid,
                activeGroup?.group.organizationUuid
            )
        );
        onClose();
    };

    return (
        <Dialog maxWidth='sm' open={ open } onClose={ onClose }>
            <Box sx={ { ml: 7, mr: 7, mt: 3, p: 3, textAlign: 'center' } }>
                <Typography color='textPrimary' gutterBottom variant='h5'>
                    { t('document.modal.sendDocument.title') }
                </Typography>
                <Typography color='textSecondary' variant='body1'>
                    { t('document.modal.sendDocument.subtitle') }
                </Typography>
                <Typography color='textSecondary' variant='caption'>
                    { t('document.modal.sendDocument.subtitle.caption') }
                </Typography>
            </Box>
            <Box sx={ { mt: 3, mb: 5, p: 3 } }>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={ 'checkbox' }>
                                { recipients && recipients?.length > 0 && (
                                    <Checkbox checked={ selectedAllRecipients }
                                              indeterminate={ selectedSomeRecipients }
                                              color='primary'
                                              onChange={ handleSelectAllRecipients }
                                    />
                                ) }
                            </TableCell>

                            <TableCell sx={ { textAlign: 'center' } }>
                                { t('document.modal.sendDocument.recipient.name') }
                            </TableCell>

                            <TableCell>
                                { t('document.modal.sendDocument.recipient.identifier') }
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { recipients
                            ?.filter((p: Person) => p.signatureInfo?.signatureRequested && !p.signatureInfo?.signed && p.uuid !== userUuid)
                            .map((person: Person, index: number) => {
                                return (
                                    <TableRow key={ index }>
                                        <TableCell padding={ 'checkbox' }>
                                            <Checkbox
                                                checked={ selectedRecipients.some((p) => hasSameUuid(p, person) || hasSameEmail(p, person) || hasSamePhone(p, person)) }
                                                value={ selectedRecipients.some((p) => hasSameUuid(p, person) || hasSameEmail(p, person) || hasSamePhone(p, person)) }
                                                color='primary'
                                                onChange={ (event: ChangeEvent<HTMLInputElement>) => handleSelectRecipient(event, person) } />
                                        </TableCell>
                                        <TableCell sx={ { textAlign: 'center' } }>
                                            <Typography color='textSecondary' variant='body2'>
                                                { person.fullName ?? '-' }
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography color='textPrimary' variant='body2'>
                                                { DocumentsHelper.getDocumentRecipientIdentifier(person) }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) }
                    </TableBody>
                </Table>
            </Box>
            <Box sx={ { mb: 5, mt: 5, textAlign: 'center' } }>
                <CommonButton sx={ { mr: 2, pl: 5, pr: 5 } }
                              btnType={ ButtonTypeEnum.CLOSE }
                              onClick={ () => onClose() }
                              disabled={ isSendingDocument }>
                    { t('document.modal.sendDocument.button.close') }
                </CommonButton>
                <CommonButton sx={ { ml: 2, pl: 5, pr: 5 } }
                              btnType={ ButtonTypeEnum.SEND }
                              onClick={ () => send() }
                              disabled={ isSendingDocument }>
                    { t('document.modal.sendDocument.button.send') }
                </CommonButton>
            </Box>
        </Dialog>
    );
};

export default SendDocumentModal;
