import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import cardsLogo from '../../assets/img/pricing/kartice-bundle.png';
import keksPaycekAircashLogo from '../../assets/img/pricing/keks-aircash-paycek-logo.png';
import wsPayLogo from '../../assets/img/pricing/wsPayWebSecureLogo.png';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

const CheckoutRegulatoryData: FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();


    return (
        <>
            <Box sx={{ backgroundColor: theme.palette.background.paper, mb: 5, p: 2, whiteSpace: 'pre-line' }}>
                <Typography variant='caption'>{t('dashboard.profile.plans.checkout.wsPayInfo')}</Typography>
            </Box>
            <Box sx={{ backgroundColor: theme.palette.background.paper, mb: 5, p: 2, whiteSpace: 'pre-line' }}>
                <Typography variant='caption'>{t('dashboard.profile.plans.checkout.companyData')}</Typography>
            </Box>
            <Box>
                <img src={cardsLogo} alt='Credit cards logo' style={{ opacity: '0.5' }} />
                <img src={keksPaycekAircashLogo} alt='KeksPay Paycek Aircash logo' style={{ opacity: '0.5' }} />
            </Box>
            <Box>
                <img src={wsPayLogo} alt='WsPay logo' style={{ opacity: '0.5' }} onClick={() => window.open('https://www.wspay.info', '_blank')} />
            </Box>
        </>
    );
};

export default CheckoutRegulatoryData;
