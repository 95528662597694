import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import hr from './locales/hr.json';

const resources = {
    en: {
        translation: en,
    },
    hr: {
        translation: hr,
    },
};

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
        format: function(value, format) {
            if (format === 'uppercase') return value.toUpperCase();
            return value;
        }
    },
    lng: 'hr',
    resources,
});
