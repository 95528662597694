import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/styles/index.css';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import { SettingsProvider } from './context/SettingsContext';
import { Provider as ReduxProvider } from 'react-redux';
import './i18n';
import ErrorHandler from './components/errors/ErrorHandler';
import { store } from './store';

if (process.env.NODE_ENV === 'production') {
    console.log = function() {
        return;
    };
}

ReactDOM.render(
    <React.StrictMode>
        <ReduxProvider store={ store }>
            <StyledEngineProvider>
                <SettingsProvider>
                    <BrowserRouter>
                        <ErrorHandler>
                            <App />
                        </ErrorHandler>
                    </BrowserRouter>
                </SettingsProvider>
            </StyledEngineProvider>
        </ReduxProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
