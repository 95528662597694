import type { FC } from 'react';
import { Box, TextField } from '@mui/material';

interface IAnsweredInputProps {
    inputText?: string;
    label: string;
}

const AnsweredInput: FC<IAnsweredInputProps> = (props) => {
    const { inputText, label } = props;

    return (
        <Box sx={{ mx: 3, my: 2 }}>
            <TextField size='small' disabled value={inputText} label={label} />
        </Box>
    );
};

export default AnsweredInput;
