import { Group, MembershipSimple, MembershipSimpleRoleEnum, MembershipSimpleStatusEnum } from 'group-service-api';
import { FC, useState } from 'react';
import { Dialog, DialogActions, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Check, PersonOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DocumentsHelper } from '../../utils/documents-helper';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';

interface IOwnershipDialogProps {
    selectedGroup: Group | undefined;
    open: boolean;
    onClose: (membership: MembershipSimple | undefined) => void;
}

const OwnershipDialog: FC<IOwnershipDialogProps> = (props) => {
    const { selectedGroup, open, onClose } = props;
    const [selectedMembership, setSelectedMembership] = useState<MembershipSimple>();
    const { t } = useTranslation();
    const handleSelectedMembership = (membership: MembershipSimple) => {
        setSelectedMembership(membership);
    };

    const handleClose = (membership: MembershipSimple | undefined) => {
        setSelectedMembership(undefined);
        onClose(membership);
    };

    return (
        <Dialog onClose={() => handleClose(undefined)} open={open} PaperProps={{ style: { padding: 20 } }}>
            <DialogTitle>{t('groups.ownership.dialog.title')}</DialogTitle>
            <List>
                {selectedGroup &&
                    selectedGroup.memberships
                        .filter(
                            (membership: MembershipSimple) =>
                                membership.status === MembershipSimpleStatusEnum.Confirmed && membership.role !== MembershipSimpleRoleEnum.Owner
                        )
                        .map((membership: MembershipSimple) => (
                            <ListItem key={membership.uuid} disablePadding>
                                <ListItemButton
                                    onClick={() => handleSelectedMembership(membership)}
                                    sx={{ background: selectedMembership === membership ? 'lightgrey' : '' }}>
                                    <ListItemIcon>{selectedMembership === membership ? <Check /> : <PersonOutline />}</ListItemIcon>
                                    <ListItemText
                                        primary={
                                            membership.fullName
                                                ? DocumentsHelper.capitalizeEachWord(membership.fullName)
                                                : membership.email
                                                ? membership.email
                                                : membership.phone
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
            </List>
            <DialogActions>
                <CommonButton btnType={ButtonTypeEnum.CONFIRM} disabled={!selectedMembership} onClick={() => handleClose(selectedMembership)}>
                    {t('groups.ownership.dialog.changeOwner')}
                </CommonButton>
            </DialogActions>
        </Dialog>
    );
};

export default OwnershipDialog;