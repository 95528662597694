import type { FC } from 'react';
import { Box, Dialog, DialogActions, DialogTitle, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from './CommonButton';
import { CloseOutlined, WarningAmberRounded } from '@mui/icons-material';

interface IConfirmationDialogProps {
    cancelButtonType?: ButtonTypeEnum;
    closeButtonText?: string;
    confirmButtonText?: string;
    confirmButtonType?: ButtonTypeEnum;
    isWarnMessage?: boolean;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
    onNo?: () => void;
    open: boolean;
    preventSmUpBreakpoint?: boolean;
    showCloseButton?: boolean;
    title: string;
}

const ConfirmationDialog: FC<IConfirmationDialogProps> = (props) => {
    const {
        cancelButtonType,
        closeButtonText,
        confirmButtonText,
        confirmButtonType,
        isWarnMessage,
        message,
        onCancel,
        onConfirm,
        onNo,
        open,
        preventSmUpBreakpoint,
        showCloseButton,
        title
    } = props;
    const { t } = useTranslation();
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    return (
        <Dialog open={ open } onClose={ onCancel } fullScreen={ !smUp }>
            <DialogTitle sx={ { m: 2, textAlign: 'center' } }>{ title }</DialogTitle>
            { showCloseButton && <IconButton
              aria-label="close"
              onClick={ onCancel }
              sx={ {
                  position: 'absolute',
                  right: 8,
                  top: 8
              } }>
              <CloseOutlined />
            </IconButton>
            }
            <>
                { isWarnMessage &&
                  <Box sx={ { color: 'orange', textAlign: 'center', transform: 'scale(2)' } }>
                    <WarningAmberRounded />
                  </Box>
                }

                <Typography sx={ {
                    color: isWarnMessage ? 'orange' : 'text.primary',
                    mb: 2,
                    ml: 3,
                    mr: 3,
                    mt: 2,
                    textAlign: 'center'
                } }>
                    { message }
                </Typography>

                <DialogActions sx={ { m: 2, mx: 'auto' } }>
                    <CommonButton btnType={ cancelButtonType ? cancelButtonType : ButtonTypeEnum.CLOSE }
                                  preventSmUpBreakpoint={ preventSmUpBreakpoint }
                                  sx={ { mr: 2 } }
                                  onClick={ onNo ? onNo : onCancel }
                                  color="secondary">
                        { closeButtonText ? closeButtonText : t('groups.settings.removeUser.cancelButton') }
                    </CommonButton>

                    <CommonButton btnType={ confirmButtonType ? confirmButtonType : ButtonTypeEnum.CONFIRM }
                                  preventSmUpBreakpoint={ preventSmUpBreakpoint }
                                  onClick={ onConfirm }
                                  color="primary"
                                  variant="contained">
                        { confirmButtonText ? confirmButtonText : t('groups.settings.admin.confirmButton') }
                    </CommonButton>
                </DialogActions>
            </>
        </Dialog>
    );
};

export default ConfirmationDialog;
