import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import errorHandlingSlice, { handleError, setErrorMessage } from './errors-handling';
import {
    CertInfo,
    CertInfoStatusEnum,
    CertStatusRequest,
    CertStatusResponse,
    Contract,
    ContractPreview,
    DocumentResponse,
    FinaUser,
    FinaUserData,
    SendTanResponse,
    SignContractRequest,
    SignRequestV2,
    StartRequest
} from 'fina-lcp-certifier-api';
import apis from '../auth/apis';
import tanModalSlice from './tan-modal';
import documentSlice from './documents';
import { RequestHelper } from '../utils/request-helper';
import { ErrorType, IError } from '../model/common/error';
import { getEntityPayables } from './payments';
import { IVisualSignatureCoordinates } from '../model/common/documents/document-user';
import { DocumentsHelper } from '../utils/documents-helper';

interface IFinaState {
    certInfo: CertInfo | undefined;
    certStatuses: CertStatusResponse[] | undefined;
    createFinaUserSuccessFul: boolean;
    documentUuidSigningList: string[];
    finaContractContent: string | undefined;
    finaUserData: FinaUserData | undefined;
    hasValidFinaCertificate: boolean;
    isCreatingFinaUser: boolean;
    isFinaContractSignedSuccessful: boolean;
    isFinaSigning: boolean;
    isFinaSignSuccessful: boolean;
    isIssuingCertificate: boolean;
    isLoading: boolean;
    isLoadingCertificateStatus: boolean;
    isLoadingCertStatuses: boolean;
    isLoadingContract: boolean;
    isOibMissing: boolean;
    isSendingTan: boolean;
    isSigningContract: boolean;
    issueCertificateSuccessful: boolean;
    isTanBlocked: boolean;
    tanCompareValue: string | undefined;
    tanSuccessfulSent: boolean;
}

const initialState: IFinaState = {
    certInfo: undefined,
    certStatuses: undefined,
    createFinaUserSuccessFul: false,
    documentUuidSigningList: [],
    finaContractContent: undefined,
    finaUserData: undefined,
    hasValidFinaCertificate: false,
    isCreatingFinaUser: false,
    isFinaContractSignedSuccessful: false,
    isFinaSigning: false,
    isFinaSignSuccessful: false,
    isIssuingCertificate: false,
    isLoading: false,
    isLoadingCertificateStatus: false,
    isLoadingCertStatuses: false,
    isLoadingContract: false,
    isOibMissing: false,
    isSendingTan: false,
    isSigningContract: false,
    issueCertificateSuccessful: false,
    isTanBlocked: false,
    tanCompareValue: undefined,
    tanSuccessfulSent: false
};

const finaLcpSlice = createSlice({
    name: 'fina',
    initialState,
    reducers: {
        clearCertStatuses(state: IFinaState): void {
            state.certStatuses = undefined;
            state.isLoadingCertStatuses = false;
        },
        clearFinaCertInfo(state: IFinaState): void {
            state.hasValidFinaCertificate = false;
            state.certInfo = undefined;
        },
        createFinaUser(state: IFinaState, action: PayloadAction<FinaUser>): void {
            state.finaUserData = action.payload.data;
            state.hasValidFinaCertificate = action.payload.certInfo.status === CertInfoStatusEnum.Valid && action.payload.certInfo.contractSigned;
            state.isLoading = false;
            state.isCreatingFinaUser = false;
            state.createFinaUserSuccessFul = true;
        },
        hasValidFinaPKICloudCertificate(state: IFinaState, action: PayloadAction<CertInfo>): void {
            state.certInfo = action.payload;
            state.hasValidFinaCertificate = action.payload.status === CertInfoStatusEnum.Valid && action.payload.contractSigned;
            state.isLoadingCertificateStatus = false;
        },
        issueCertificate(state: IFinaState): void {
            state.isLoading = false;
            state.hasValidFinaCertificate = true;
            state.isIssuingCertificate = false;
            state.issueCertificateSuccessful = true;
        },
        resetFinaFlags(state: IFinaState): void {
            state.isFinaSignSuccessful = false;
            state.tanSuccessfulSent = false;
            state.createFinaUserSuccessFul = false;
            state.issueCertificateSuccessful = false;
            state.isFinaContractSignedSuccessful = false;
            state.isTanBlocked = false;
            state.isOibMissing = false;
        },
        resetIssueCertificateSuccessful(state: IFinaState): void {
            state.issueCertificateSuccessful = false;
        },
        sendTan(state: IFinaState, action: PayloadAction<SendTanResponse>): void {
            state.tanCompareValue = action.payload.compareValue;
            state.isLoading = false;
            state.isSendingTan = false;
            state.tanSuccessfulSent = true;
        },
        setCertStatuses(state: IFinaState, action: PayloadAction<CertStatusResponse[]>): void {
            state.certStatuses = action.payload;
            state.isLoadingCertStatuses = false;
        },
        setContractPreview(state: IFinaState, action: PayloadAction<ContractPreview>): void {
            state.finaContractContent = action.payload.content;
            state.isLoading = false;
            state.isLoadingContract = false;
        },
        setIsCreatingUser(state: IFinaState, action: PayloadAction<boolean>): void {
            state.isCreatingFinaUser = action.payload;
        },
        setIsIssuingCertificate(state: IFinaState, action: PayloadAction<boolean>): void {
            state.isIssuingCertificate = action.payload;
        },
        setIsLoading(state: IFinaState, action: PayloadAction<boolean>): void {
            state.isLoading = action.payload;
        },
        setIsLoadingCertificateStatus(state: IFinaState, action: PayloadAction<boolean>): void {
            state.isLoadingCertificateStatus = action.payload;
        },
        setIsLoadingCertStatuses(state: IFinaState, action: PayloadAction<boolean>): void {
            state.isLoadingCertStatuses = action.payload;
        },
        setIsLoadingContract(state: IFinaState, action: PayloadAction<boolean>): void {
            state.isLoadingContract = action.payload;
        },
        setIsOibMissing(state: IFinaState, action: PayloadAction<boolean>): void {
            state.isOibMissing = action.payload;
        },
        setIsSendingTan(state: IFinaState, action: PayloadAction<boolean>): void {
            state.isSendingTan = action.payload;
        },
        setIsSigning(state: IFinaState, action: PayloadAction<{ isSigning: boolean, documentUuidList: string[] }>): void {
            state.isFinaSigning = action.payload.isSigning;
            state.documentUuidSigningList = action.payload.documentUuidList;
        },
        setIsSigningContract(state: IFinaState, action: PayloadAction<boolean>): void {
            state.isSigningContract = action.payload;
        },
        setIsTanBlocked(state: IFinaState, action: PayloadAction<boolean>): void {
            state.isTanBlocked = action.payload;
        },
        setSignedContract(state: IFinaState, action: PayloadAction<Contract>): void {
            state.finaContractContent = action.payload.content;
            state.isFinaContractSignedSuccessful = true;
            state.isLoading = false;
            state.isSigningContract = false;
            state.isLoadingContract = false;
        },
        signDocumentFina(state: IFinaState): void {
            state.isLoading = false;
            state.isFinaSignSuccessful = true;
            state.isFinaSigning = false;
            state.documentUuidSigningList = [];
        },
    },
});

export const createFinaUser = (oib?: string): AppThunk =>
    async (dispatch): Promise<void> => {
    dispatch(finaLcpSlice.actions.setIsLoading(true));
    dispatch(finaLcpSlice.actions.setIsCreatingUser(true));
    dispatch(finaLcpSlice.actions.setIsOibMissing(false));
    dispatch(finaLcpSlice.actions.setIsTanBlocked(false));
    apis.finaLcpCertifierApi()
        .getOrCreateFinaUser({ oib: oib ? oib : null } as StartRequest)
        .then(({ data }) => {
            dispatch(finaLcpSlice.actions.setIsLoading(false));
            dispatch(finaLcpSlice.actions.setIsCreatingUser(false));
            dispatch(finaLcpSlice.actions.createFinaUser(data));
        })
        .catch((err) => {
            console.log('**** error na fini ****', JSON.stringify(err, null, 2));
            if (RequestHelper.isPreconditionFailed(err)) { // case OIB_MISSING
                dispatch(finaLcpSlice.actions.setIsOibMissing(true));
                dispatch(setErrorMessage({
                    error: true,
                    errorMessage: 'dashboard.sidebar.documents.enterPin.errorMessage',
                    errorType: ErrorType.GENERIC_ERROR
                } as IError));
            } else if (RequestHelper.isTanBlocked(err)) { // case TAN_BLOCKED
                dispatch(finaLcpSlice.actions.clearFinaCertInfo());
                dispatch(finaLcpSlice.actions.setIsTanBlocked(true));
                dispatch(setErrorMessage({
                    error: true,
                    errorMessage: 'dashboard.sidebar.documents.enterTan.tanBlocked',
                    errorType: ErrorType.GENERIC_ERROR
                } as IError));
            } else {
                dispatch(handleError(err));
            }
            dispatch(finaLcpSlice.actions.setIsLoading(false));
            dispatch(finaLcpSlice.actions.setIsCreatingUser(false));
        });
};

export const getContractPreview = (): AppThunk =>
    async (dispatch): Promise<void> => {
    dispatch(finaLcpSlice.actions.setIsLoading(true));
    dispatch(finaLcpSlice.actions.setIsLoadingContract(true));
    apis.finaLcpCertifierApi()
        .getContractPreview()
        .then((response) => {
            dispatch(finaLcpSlice.actions.setContractPreview(response.data));
        })
        .catch((err) => {
            dispatch(finaLcpSlice.actions.setIsLoading(false));
            dispatch(finaLcpSlice.actions.setIsLoadingContract(false));
            dispatch(handleError(err));
        });
};

export const getSignedContract = (): AppThunk =>
    async (dispatch): Promise<void> => {
    dispatch(finaLcpSlice.actions.setIsLoading(true));
        dispatch(finaLcpSlice.actions.setIsLoadingContract(true));
    apis.finaLcpCertifierApi()
        .getSignedContract()
        .then((response) => {
            dispatch(finaLcpSlice.actions.setSignedContract(response.data));
        })
        .catch((err) => {
            dispatch(finaLcpSlice.actions.setIsLoading(false));
            dispatch(finaLcpSlice.actions.setIsLoadingContract(false));
            dispatch(handleError(err));
        });
};

export const hasValidFinaPKICloudCertificate = (): AppThunk =>
    async (dispatch): Promise<void> => {
    dispatch(finaLcpSlice.actions.setIsLoadingCertificateStatus(true));
    apis.finaLcpCertifierApi()
        .hasValidFinaPKICloudCertificate()
        .then((response) => {
            dispatch(finaLcpSlice.actions.hasValidFinaPKICloudCertificate(response.data));
        })
        .catch((err) => {
            dispatch(handleError(err));
            dispatch(finaLcpSlice.actions.setIsLoadingCertificateStatus(true));
        });
};

export const issueCertificate = (tan: string): AppThunk =>
    async (dispatch): Promise<void> => {
    dispatch(finaLcpSlice.actions.setIsLoading(true));
    dispatch(finaLcpSlice.actions.setIsIssuingCertificate(true));
    apis.finaLcpCertifierApi()
        .issueCertificate1({ tan: tan })
        .then(() => {
            dispatch(finaLcpSlice.actions.issueCertificate());
            dispatch(tanModalSlice.actions.clearRemainingAttempts());
            dispatch(tanModalSlice.actions.clearErrorMessage());
            dispatch(tanModalSlice.actions.hideTanModal());
        })
        .catch((err) => {
            if (RequestHelper.isUnprocessableEntityError(err)) { // case TAN_INVALID
                dispatch(tanModalSlice.actions.setRemainingAttempts(RequestHelper.getRemainingAttempts(err)));
                dispatch(tanModalSlice.actions.setErrorMessage({
                    error: true,
                    errorMessage: 'dashboard.sidebar.documents.enterTan.wrongTan',
                    errorType: ErrorType.AUTHORIZATION_ERROR
                } as IError));
                dispatch(tanModalSlice.actions.showTanModal());
            } else if (RequestHelper.isTanBlocked(err)) { // case TAN_BLOCKED
                dispatch(finaLcpSlice.actions.clearFinaCertInfo());
                dispatch(finaLcpSlice.actions.setIsTanBlocked(true));
                dispatch(setErrorMessage({
                    error: true,
                    errorMessage: 'dashboard.sidebar.documents.enterTan.tanBlocked',
                    errorType: ErrorType.GENERIC_ERROR
                } as IError));
                dispatch(tanModalSlice.actions.hideTanModal());
            } else {
                dispatch(tanModalSlice.actions.setErrorMessage({
                    error: true,
                    errorMessage: 'dashboard.sidebar.documents.enterTan.somethingWentWrong',
                    errorType: ErrorType.GENERIC_ERROR
                } as IError));
                dispatch(handleError(err));
                dispatch(tanModalSlice.actions.showTanModal());
            }
            dispatch(finaLcpSlice.actions.setIsLoading(false));
            dispatch(finaLcpSlice.actions.setIsIssuingCertificate(false));
        });
};

export const sendTan = (): AppThunk =>
    async (dispatch): Promise<void> => {
    dispatch(finaLcpSlice.actions.setIsLoading(true));
    dispatch(finaLcpSlice.actions.setIsSendingTan(true));
    apis.finaLcpCertifierApi()
        .sendTan1()
        .then(({data}) => {
            dispatch(finaLcpSlice.actions.sendTan(data));
        })
        .catch((err) => {
            dispatch(finaLcpSlice.actions.setIsLoading(false));
            dispatch(finaLcpSlice.actions.setIsSendingTan(false));
            dispatch(handleError(err));
        });
};

export const signContract = (tan: string): AppThunk =>
    async (dispatch): Promise<void> => {
    dispatch(finaLcpSlice.actions.setIsLoading(true));
    dispatch(finaLcpSlice.actions.setIsSigningContract(true));
    dispatch(tanModalSlice.actions.hideTanModal());
    apis.finaLcpCertifierApi()
        .signContract({ tan: tan } as SignContractRequest)
        .then((response) => {
            dispatch(finaLcpSlice.actions.setSignedContract(response.data));
            dispatch(tanModalSlice.actions.clearRemainingAttempts());
            dispatch(tanModalSlice.actions.clearErrorMessage());
        })
        .catch((err) => {
            if (RequestHelper.isTanBlocked(err)) { // case TAN_BLOCKED
                dispatch(finaLcpSlice.actions.clearFinaCertInfo());
                dispatch(finaLcpSlice.actions.setIsTanBlocked(true));
                dispatch(setErrorMessage({
                    error: true,
                    errorMessage: 'dashboard.sidebar.documents.enterTan.tanBlocked',
                    errorType: ErrorType.GENERIC_ERROR
                } as IError));
                dispatch(tanModalSlice.actions.hideTanModal());
            } else if (RequestHelper.isUnprocessableEntityError(err)) { // case TAN_INVALID
                dispatch(tanModalSlice.actions.setRemainingAttempts(RequestHelper.getRemainingAttempts(err)));
                dispatch(tanModalSlice.actions.setErrorMessage({
                    error: true,
                    errorMessage: 'dashboard.sidebar.documents.enterTan.wrongTan',
                    errorType: ErrorType.AUTHORIZATION_ERROR
                } as IError));
                dispatch(tanModalSlice.actions.showTanModal());
            } else {
                dispatch(tanModalSlice.actions.setErrorMessage({
                    error: true,
                    errorMessage: 'dashboard.sidebar.documents.enterTan.somethingWentWrong',
                    errorType: ErrorType.GENERIC_ERROR
                } as IError));
                dispatch(handleError(err));
                dispatch(tanModalSlice.actions.showTanModal());
            }
            dispatch(finaLcpSlice.actions.setIsLoading(false));
            dispatch(finaLcpSlice.actions.setIsSigningContract(false));
        });
};

export const signDocumentFina = (
    documentUuids: string[],
    tan: string,
    groupUuid?: string,
    signatureCoordinates?: IVisualSignatureCoordinates
): AppThunk =>
    async (dispatch): Promise<void> => {
    dispatch(tanModalSlice.actions.hideTanModal());
    dispatch(finaLcpSlice.actions.setIsSigning({ isSigning: true, documentUuidList: documentUuids }));
    const signRequest: SignRequestV2 = {
        groupUuid: groupUuid,
        signDatas: DocumentsHelper.getFinaSignDatas(documentUuids, signatureCoordinates),
        tan: tan,
    };
    apis.finaLcpCertifierApi()
        .signDocuments(signRequest)
        .then((response) => {
            const signedDocuments: DocumentResponse[] = Array.from(response.data);
            dispatch(finaLcpSlice.actions.signDocumentFina());
            dispatch(documentSlice.actions.updateDocumentsSignedFina(signedDocuments));
            dispatch(getEntityPayables());
            dispatch(tanModalSlice.actions.clearRemainingAttempts());
            dispatch(tanModalSlice.actions.clearErrorMessage());
        })
        .catch((err) => {
            if (RequestHelper.isUnprocessableEntityError(err)) {
                dispatch(tanModalSlice.actions.setRemainingAttempts(RequestHelper.getRemainingAttempts(err)));
                dispatch(tanModalSlice.actions.setErrorMessage({
                    error: true,
                    errorMessage: 'dashboard.sidebar.documents.enterTan.wrongTan',
                    errorType: ErrorType.AUTHORIZATION_ERROR
                } as IError));
                dispatch(tanModalSlice.actions.showTanModal());
            }

            if (RequestHelper.isPreconditionFailed(err)) {
                dispatch(
                    errorHandlingSlice.actions.setErrorMessage({
                        error: true,
                        errorMessage: 'dashboard.sidebar.documentDetails.error.signingDocument.notEnoughSignatures',
                        errorType: ErrorType.NOT_ENOUGH_SIGNATURES,
                    } as IError)
                );
            } else {
                dispatch(handleError(err));
            }
            dispatch(finaLcpSlice.actions.setIsSigning({ isSigning: false, documentUuidList: [] }));
        });
};

export const fetchCertStatuses = (userUuids: string[]): AppThunk =>
  async (dispatch): Promise<void> => {
      dispatch(finaLcpSlice.actions.setIsLoadingCertStatuses(true));
      apis.finaLcpCertifierApi()
        .getCertStatusForUsers({ userUuids: userUuids } as CertStatusRequest)
        .then(({data}) => {
            dispatch(finaLcpSlice.actions.setCertStatuses(data));
        })
        .catch((err) => {
            dispatch(finaLcpSlice.actions.setIsLoadingCertStatuses(false));
            dispatch(handleError(err));
        });
  };

export const { clearCertStatuses, clearFinaCertInfo, resetFinaFlags, resetIssueCertificateSuccessful } = finaLcpSlice.actions;

export const { reducer } = finaLcpSlice;

export default finaLcpSlice;
