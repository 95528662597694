import type { FC } from 'react';
import { Box, Chip, Grid, List, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SurveyInvitation } from 'survey-service-api';

interface ISurveyReceiverListProps {
    receivers: SurveyInvitation[];
    onRemoveReceiver: (index: number) => void;
}

const SurveyReceiverList: FC<ISurveyReceiverListProps> = (props) => {
    const { receivers, onRemoveReceiver } = props;

    const { t } = useTranslation();

    return (
        <Box
            sx={{
                mt: 3,
                p: 3,
                pr: 1,
                pt: 1,
                width: '100%',
            }}>
            <Grid item container direction="row" justifyContent="center" spacing={2}>
                {receivers.map((receiver: SurveyInvitation, index: number) => (
                    <Chip
                        sx={{ m: '2px' }}
                        key={index}
                        label={receiver.email ?? receiver.phone?.dialCode.concat(receiver.phone?.number)}
                        variant="outlined"
                        onDelete={() => onRemoveReceiver(index)}
                    />
                ))}
            </Grid>

            {receivers.length === 0 && (
                <Box>
                    <List>
                        <ListItem sx={{ '& + &': { mt: 1 }, textAlign: 'center' }}>
                            <ListItemText
                                primary={t('dashboard.sidebar.documents.newDocument.receiver.notAdded.info')}
                                secondary={t('dashboard.sidebar.documents.newDocument.receiver.notAdded.description')}
                                primaryTypographyProps={{
                                    color: 'textPrimary',
                                    variant: 'subtitle2',
                                }}
                            />
                        </ListItem>
                    </List>
                </Box>
            )}
        </Box>
    );
};

export default SurveyReceiverList;
