import type { FC } from 'react';
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import ShopLegalDocs from '../profile/plans/ShopLegalDocs';

const ConversionStatement: FC = () => {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8,
                }}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid alignItems="center" container justifyContent="space-between" spacing={3} item xs={12}>
                            <Grid item>
                                <Typography color="textPrimary" variant="h5">
                                    Izjava o konverziji
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>
                                        Cijene u drugim valutama - za korisnike van hrvatske
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Naplata se uvijek vrši u Hrvatskim kunama.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Iznos koji će teretiti Vašu kreditnu karticu dobija se na način da se Hrvatska Kuna pretvara u Vašu valutu prema trenutnoj tečajnoj listi Hrvatske Narodne Banke. Kada se Vama naplaćuje iznos se pretvara u Vašu valutu prema tečaju kuće kreditne kartice kojom ste platili.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Kao rezultat pretvaranja valuta može doći do male razlike u cijeni od one koja je naznačena na našoj internet stranici.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <ShopLegalDocs />
        </>
    );
};

export default ConversionStatement;
