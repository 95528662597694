import type { FC } from 'react';
import { Card, CardContent, Grid, Skeleton } from '@mui/material';

const SkeletonGridCard: FC = () => {
    return (
        <Grid item md={4} xs={12}>
            <Card>
                <CardContent>
                    <Skeleton sx={{ borderRadius: 1 }} height={155} animation="wave" variant="rectangular" />
                </CardContent>
            </Card>
        </Grid>
    );
};

export default SkeletonGridCard;