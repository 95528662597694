import { MembershipRoleEnum } from 'group-service-api';

export class GroupCardUtil {
    public static roleText = (role: MembershipRoleEnum): string => {
        switch (role) {
            case MembershipRoleEnum.Owner:
                return 'groups.memberships.owner';
            case MembershipRoleEnum.Admin:
                return 'groups.details.admin';
            case MembershipRoleEnum.Regular:
                return 'groups.memberships.member';

            default:
                return '-';
        }
    };
}
