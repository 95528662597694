import { FC } from 'react';
import { Box, Grid, List, ListItem, ListItemText } from '@mui/material';
import { ContactType, IDocumentReceiver } from 'model/common/documents/document-user';
import { useTranslation } from 'react-i18next';
import ReceiverCard from '../receivers/ReceiverCard';
import SignSeparateCopiesSwitch from './SignSeparateCopiesSwitch';

interface IDocumentReceiverListProps {
    receivers: IDocumentReceiver[];
    onRemoveReceiver: (receiver: IDocumentReceiver) => void;
    onShouldSignToggle: (receiver: IDocumentReceiver, signatureRequested: boolean) => void;
    isSignSeparateCopies: boolean;
    onSignSeparateCopies: () => void;
}

const DocumentReceiverList: FC<IDocumentReceiverListProps> = (props: IDocumentReceiverListProps) => {
    const {
        receivers,
        onRemoveReceiver,
        onShouldSignToggle,
        isSignSeparateCopies,
        onSignSeparateCopies
    } = props;
    const { t } = useTranslation();

    return (
        <Box sx={ {
            mt: 3,
            p: 3,
            pr: 1,
            pt: 1,
            width: '100%'
        } }>
            <Grid item container direction='row' justifyContent='center' spacing={ 2 }>
                { receivers.map((receiver: IDocumentReceiver, index: number) => (
                    <ReceiverCard key={
                        receiver.contactType === ContactType.EMAIL ?
                            receiver.email :
                            receiver.phone ? ( receiver.phone?.dialCode + receiver.phone?.number ) : index
                    }
                                  receiver={ receiver }
                                  onSignToggle={ (receiver) => onShouldSignToggle(receiver, !receiver.signatureRequested) }
                                  onRemoveReceiver={ onRemoveReceiver } />
                ))
                }
            </Grid>

            { receivers.length > 1 &&
              <SignSeparateCopiesSwitch isChecked={ isSignSeparateCopies }
                                        onChange={ onSignSeparateCopies } />
            }

            { receivers.length === 0 && (
                <Box>
                    <List>
                        <ListItem sx={ { '& + &': { mt: 1 }, textAlign: 'center' } }>
                            <ListItemText primary={ t('dashboard.sidebar.documents.newDocument.receiver.notAdded.info') }
                                          secondary={ t('dashboard.sidebar.documents.newDocument.receiver.notAdded.description') }
                                          primaryTypographyProps={ {
                                              color: 'textPrimary',
                                              variant: 'subtitle2'
                                          } } />
                        </ListItem>
                    </List>
                </Box>
            ) }
        </Box>
    );
};

export default DocumentReceiverList;
