import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { DashboardLayoutContainer, DashboardLayoutContent, DashboardLayoutProps, DashboardLayoutRoot, DashboardLayoutWrapper } from '../common/CommonLayout';
import DashboardNavbar from '../common/DashboardNavbar';
import DashboardSidebar from '../common/DashboardSidebar';
import { IMenuSection } from '../../../model/common/navigation/menu-section';
import { getPrivateMenu } from '../../../utils/menu';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { resetActiveGroup } from '../../../slices/groups';
import { getProfileData, getUserIdentifier } from '../../../slices/profile-data';

const PrivateDashboardLayout: FC<DashboardLayoutProps> = () => {
    const dispatch = useAppDispatch();
    const [ isSidebarMobileOpen, setIsSidebarMobileOpen ] = useState<boolean>(false);
    const { userIdentificationData, isLoadingBasicUserData, profileData } = useAppSelector((state) => state.profileData);

    const menu: IMenuSection[] = getPrivateMenu();
    const isProfileVerified = Boolean(userIdentificationData?.fullName && userIdentificationData?.personalNumber);

    useEffect(() => {
        dispatch(resetActiveGroup());
        !userIdentificationData && dispatch(getUserIdentifier());
        !profileData && dispatch(getProfileData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DashboardLayoutRoot>
            <DashboardNavbar
                isProfileVerified={ isProfileVerified }
                onSidebarMobileOpen={ (): void => setIsSidebarMobileOpen(prevValue => !prevValue) }
                profileData={ profileData }
                userIdentificationData={ userIdentificationData } />

            <DashboardSidebar
                isLoading={ isLoadingBasicUserData }
                isProfileVerified={ isProfileVerified }
                menu={ menu }
                onMobileClose={ (): void => setIsSidebarMobileOpen(false) }
                openMobile={ isSidebarMobileOpen }
                profileData={ profileData }
                userIdentificationData={ userIdentificationData } />

            <DashboardLayoutWrapper>
                <DashboardLayoutContainer>
                    <DashboardLayoutContent>
                        <Outlet />
                    </DashboardLayoutContent>
                </DashboardLayoutContainer>
            </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
    );
};

export default PrivateDashboardLayout;
