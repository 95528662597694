import type { FC } from 'react';
import { Grid } from '@mui/material';
import { PaymentsHelper } from '../../../utils/payments-helper';
import pricingPlan2 from '../../../assets/img/pricing/plan2.svg';
import PricingPlan from './PricingPlan';
import { Bundle, ListResponsePayableItem, ProvidedPayableItem } from 'payment-service-api';
import { ApiGetDocumentsResponse } from 'document-scanner-api';
import SkeletonBlock from '../../skeletons/SkeletonBlock';

interface IPlanListProps {
    availablePlans?: Bundle[];
    hasValidCertificate: boolean;
    isLoading: boolean;
    isLoadingUserData: boolean;
    onIssueFinaCert: () => void;
    profileData?: ApiGetDocumentsResponse;
    userPayables?: ListResponsePayableItem;
    receivedPayables?: ProvidedPayableItem[];
}

const PlanList: FC<IPlanListProps> = (props) => {
    const {
        availablePlans,
        hasValidCertificate,
        isLoading,
        isLoadingUserData,
        onIssueFinaCert,
        profileData,
        receivedPayables,
        userPayables
    } = props;

    const showItems = () => {
        if (availablePlans?.length && availablePlans?.length > 0) {
            return (
                <Grid container spacing={ 1 } justifyContent='center'>
                    { availablePlans.map((bundle: Bundle) => {
                        return (
                            <Grid sx={ { mb: 5, ml: 2, mr: 2, mt: 7 } }
                                  item
                                  xs={ 12 } sm={ 12 } md={ 5 }
                                  key={ bundle.uuid }>
                                <PricingPlan uuid={ bundle.uuid }
                                             placeholderName={ bundle.placeholderName }
                                             hasValidFinaCert={ hasValidCertificate }
                                             currency={ bundle.amountCurrency?.currency }
                                             description={ PaymentsHelper.getPlanDescription(bundle.placeholderName) }
                                             features={ PaymentsHelper.getPlanFeatures(bundle.payableGroups) }
                                             image={ pricingPlan2 }
                                             name={ PaymentsHelper.getPlanName(bundle.placeholderName) }
                                             price={ bundle.amountCurrency?.amount }
                                             onIssueFinaCert={ onIssueFinaCert }
                                             planType={ PaymentsHelper.getPlanType(bundle.placeholderName) }
                                             userPayables={ userPayables }
                                             receivedPayables={ receivedPayables }
                                             profileData={ profileData }
                                             isLoadingUserData={ isLoadingUserData }
                                             sx={ {
                                                 height: '100%',
                                                 maxWidth: 460,
                                                 mx: 'auto'
                                             } } />
                            </Grid>
                        );
                    }) }
                </Grid>
            );
        }

        return null;
    };

    return isLoading ? (
        <SkeletonBlock height={ 500 } />
    ) : (
        <>
            { showItems() }
        </>
    );
};

export default PlanList;
