import type { FC } from 'react';
import React, { useState } from 'react';
import {Button, Menu, MenuItem, Theme, useMediaQuery} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserConsentStatusEnum } from 'consent-manager-api';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';

interface IProfileConsentsThreeDotMenu {
    consentStatus: UserConsentStatusEnum;
    onRevokeConsent(): void;
    onDownloadConsent(): void;
    onViewConsent(): void;
}

export const ProfileConsentsThreeDotMenu: FC<IProfileConsentsThreeDotMenu> = (props) => {
    const { onRevokeConsent, onDownloadConsent, onViewConsent, consentStatus } = props;
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | HTMLButtonElement | null>(null);

    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {smUp && (
                <Button variant='outlined' onClick={() => onViewConsent()}>
                    {t('dashboard.profile.consents.table.identyum.actions.viewPDF')}
                </Button>
            )}

            <CommonButton btnType={ButtonTypeEnum.MORE} onClick={handleClick} />

            <Menu id='card-actions-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {!smUp && <MenuItem onClick={() => onViewConsent()}>{t('dashboard.profile.consents.table.identyum.actions.viewPDF')}</MenuItem>}

                <MenuItem onClick={() => onRevokeConsent()} disabled={consentStatus === UserConsentStatusEnum.Revoked}>
                    {t('dashboard.profile.consents.table.identyum.actions.revoke')}
                </MenuItem>

                <MenuItem onClick={() => onDownloadConsent()}>{t('dashboard.profile.consents.table.identyum.actions.downloadPDF')}</MenuItem>
            </Menu>
        </>
    );
};
