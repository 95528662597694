import { Skeleton, TableCell, TableRow } from '@mui/material';

export const loadingTableRow = (smUp: boolean) => <><TableRow>
        <TableCell align={ 'center' } />

        <TableCell align={ 'center' }>
            <Skeleton variant='text' animation={ 'wave' } height={ 50 } />
        </TableCell>

        <TableCell align={ 'center' }>
            <Skeleton variant='text' animation={ 'wave' } height={ 50 } />
        </TableCell>

        { smUp &&
          <TableCell align={ 'center' }>
            <Skeleton variant='text' animation={ 'wave' } height={ 50 } />
          </TableCell> }

        { smUp &&
          <TableCell align={ 'center' }>
            <Skeleton variant='text' animation={ 'wave' } height={ 50 } />
          </TableCell> }

        <TableCell align={ 'center' }>
            <Skeleton variant='text' animation={ 'wave' } height={ 50 } />
        </TableCell>
    </TableRow>
    </>
;
