import type { FC } from 'react';
import {useEffect } from 'react';
import logoLight from '../../assets/img/login/new_login_light.svg';
import logo from '../../assets/img/login/new_login_dark.svg';
import useSettings from '../../hooks/useSettings';
import { Grid } from '@mui/material';
import { Misc } from '../../utils/misc';
import { THEMES } from '../../common/constants';

export const AuthorizePage: FC = () => {
  const { settings } = useSettings();

  useEffect(() => {
    const fullScopeRedirectUrl = localStorage.getItem('fullScopeRedirectUrl');
    const authCodeUrl = new URL(process.env.REACT_APP_AUTHORIZATION_URL ?? '');
    const searchParams = new URLSearchParams({
      client_id: process.env.REACT_APP_CLIENT_ID ?? '',
      redirect_uri: `${ process.env.REACT_APP_REDIRECT_URL }${ Misc.isLocalEnvironment() ? '/oauthRedirect' : '/redirect' }` ?? '',
      response_type: 'code',
      scope: !fullScopeRedirectUrl ? (process.env.REACT_APP_OAUTH_SCOPES ?? '') : (process.env.REACT_APP_OAUTH_SCOPES_VERIFIED ?? '')
    });
    authCodeUrl.search = searchParams.toString();

    window.location.href = authCodeUrl.toString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDarkMode = settings.theme === THEMES.DARK;

  return (
      <Grid container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{
              fontFamily: 'roboto',
              height: '100vh',
              paddingBottom: '3rem',
              width: '100%'
            }}>
        <img style={{
          bottom: 0,
          height: '60vh',
          left: 0,
          opacity: '20%',
          position: 'fixed',
          right: 0,
          top: '25%',
          width: '100%'
        }}
             src={ isDarkMode ? logo : logoLight }
             alt="IdentyumLogo" />
      </Grid>
  );
};
