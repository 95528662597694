import type { FC } from 'react';
import { useEffect, useState } from 'react';
import Page from '../../components/shared/Page';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import { getIdyWalletData, getMySurvey, submitIdyWalletAnswers } from '../../slices/survey';
import { Box, Card, CardActions, CardContent, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { WalletAnswer, WalletDataRequest, WalletPossibleInput, WalletPossibleInputRequest } from 'survey-service-api';
import { WalletAnswerOption } from 'survey-service-api';
import { PrivateRoutes } from '../../routes';
import EmptyState from '../../components/empty-states/EmptyState';
import { Wallet } from '@mui/icons-material';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import SkeletonBlock from '../../components/skeletons/SkeletonBlock';

interface IRadioButtonGroupOptions {
    answerOptionUuid: string;
    selectedInput: WalletPossibleInputRequest;
}

const SurveyWalletDataPage: FC = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { idyWalletData, sharedSurvey, isLoading, isSubmittingIdyWalletAnswers, submittedIdyWalletAnswers } = useAppSelector((state) => state.survey);

    const { surveyUserUuid } = params;

    const [selectedInputs, setSelectedInputs] = useState<IRadioButtonGroupOptions[]>([]);

    useEffect(() => {
        if (surveyUserUuid) {
            dispatch(getIdyWalletData(surveyUserUuid));
            if (!sharedSurvey) {
                dispatch(getMySurvey(surveyUserUuid));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (idyWalletData) {
            const updatedInputs: IRadioButtonGroupOptions[] = idyWalletData?.walletAnswers.reduce((acc: IRadioButtonGroupOptions[], answer: WalletAnswer) => {
                answer.answerOptions.forEach((option: WalletAnswerOption) => {
                    const firstPossibleInput = option.possibleInputs[0];
                    if (firstPossibleInput && firstPossibleInput.uuid) {
                        acc.push({
                            answerOptionUuid: option.uuid,
                            selectedInput: { uuid: firstPossibleInput.uuid, selectedInput: firstPossibleInput.possibleInput },
                        });
                    }
                });
                return acc;
            }, []);

            setSelectedInputs(updatedInputs);
        }
    }, [idyWalletData]);

    useEffect(() => {
        if (sharedSurvey?.submitted && submittedIdyWalletAnswers.length > 1) {
            navigate(PrivateRoutes.private_survey_user_results_pdf_route.path.replace(':surveyUserUuid', surveyUserUuid ?? ''));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedSurvey, submittedIdyWalletAnswers]);

    const handleConfirm = () => {
        if (surveyUserUuid && sharedSurvey && !sharedSurvey.submitted) {
            const walletDataRequest: WalletDataRequest = {
                selectedInputs: selectedInputs.map((value) => value.selectedInput as WalletPossibleInputRequest),
            };
            dispatch(submitIdyWalletAnswers(surveyUserUuid, walletDataRequest));
        }

        if (surveyUserUuid && sharedSurvey && sharedSurvey.submitted) {
            navigate(PrivateRoutes.private_survey_user_results_pdf_route.path.replace(':surveyUserUuid', surveyUserUuid ?? ''));
        }
    };

    const handleContinue = () => {
        if (surveyUserUuid) {
            navigate(PrivateRoutes.private_survey_user_results_pdf_route.path.replace(':surveyUserUuid', surveyUserUuid ?? ''));
        }
    };

    const handleCancel = () => {
        if (surveyUserUuid) {
            navigate(PrivateRoutes.my_survey_route.path.replace(':surveyUserUuid', surveyUserUuid ?? ''));
        }
    };

    const handleChangePossibleInput = (
        event: React.ChangeEvent<HTMLInputElement>,
        walletPossibleInput: WalletPossibleInput,
        answerOption: WalletAnswerOption
    ) => {
        const { uuid, possibleInput } = walletPossibleInput;

        const mappedWalletAnswerOptionRequest: WalletPossibleInputRequest = {
            uuid: uuid,
            selectedInput: possibleInput,
        };

        setSelectedInputs((prevInputs) => {
            const existingIndex = prevInputs.findIndex((item) => item.answerOptionUuid === answerOption.uuid);

            const updatedInputs = [...prevInputs];

            updatedInputs.splice(existingIndex, 1, {
                answerOptionUuid: answerOption.uuid,
                selectedInput: { uuid: mappedWalletAnswerOptionRequest.uuid, selectedInput: mappedWalletAnswerOptionRequest.selectedInput },
            });

            return updatedInputs;
        });
    };

    return (
        <Page pageTitle={t('survey.SurveyWalletDataPage.title')}>
            <Card>
                <CardContent>
                    <Box>
                        <Typography variant="caption" color="grey" component="div">
                            <Box sx={{ fontStyle: 'italic' }}>{t('survey.SurveyWalletDataPage.description')}</Box>
                        </Typography>
                    </Box>
                    {isLoading ? (
                        <SkeletonBlock />
                    ) : idyWalletData ? (
                        idyWalletData.walletAnswers.map((walletAnswer: WalletAnswer, index: number) => (
                            <Box key={walletAnswer.uuid}>
                                <Box m={2}>
                                    <Typography variant={'body1'}>
                                        {index + 1}. {walletAnswer.question.questionText}
                                    </Typography>
                                </Box>
                                <Box>
                                    {walletAnswer.answerOptions.map((answerOption: WalletAnswerOption) => (
                                        <Box key={answerOption.option?.uuid}>
                                            <Box m={2}>
                                                <Typography variant={'body2'}>{t(answerOption.option?.label)}</Typography>
                                            </Box>
                                            <Box>
                                                {answerOption.possibleInputs.length > 1 ? (
                                                    <Box>
                                                        <RadioGroup value={answerOption.option.uuid} name={answerOption.option.label}>
                                                            {answerOption.possibleInputs.map((o: WalletPossibleInput) => (
                                                                <FormControlLabel
                                                                    key={o.uuid}
                                                                    control={
                                                                        <Radio
                                                                            checked={
                                                                                selectedInputs.findIndex((value) => value.selectedInput.uuid === o.uuid) !== -1
                                                                            }
                                                                            name={o.uuid}
                                                                            value={o.uuid}
                                                                            onChange={(e) => handleChangePossibleInput(e, o, answerOption)}
                                                                        />
                                                                    }
                                                                    label={o.possibleInput}
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    </Box>
                                                ) : (
                                                    answerOption.possibleInputs.map((possibleInput: WalletPossibleInput) => (
                                                        <Box key={possibleInput.uuid} mx={2}>
                                                            <TextField value={possibleInput.possibleInput} disabled size={'small'} />
                                                        </Box>
                                                    ))
                                                )}
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <EmptyState icon={<Wallet />} message={t('survey.SurveyWalletDataPage.emptyState')} />
                    )}
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                    {sharedSurvey && !sharedSurvey.submitted ? (
                        <CommonButton btnType={ButtonTypeEnum.CONFIRM} onClick={handleConfirm} loading={isSubmittingIdyWalletAnswers}>
                            {t('survey.SectionBox.question.confirm')}
                        </CommonButton>
                    ) : (
                        <CommonButton btnType={ButtonTypeEnum.CONFIRM} onClick={handleContinue}>
                            {t('survey.SectionBox.question.confirm')}
                        </CommonButton>
                    )}
                    <CommonButton btnType={ButtonTypeEnum.CANCEL} onClick={handleCancel}>{t('survey.AddQuestionDialog.dialogActions.button.cancel')}</CommonButton>
                </CardActions>
            </Card>
        </Page>
    );
};

export default SurveyWalletDataPage;
