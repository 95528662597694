import type { FC } from 'react';
import { useEffect } from 'react';
import { Box, Card, CardHeader } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SkeletonCard from '../skeletons/SkeletonCard';
import ProfileConsentsTable from './ProfileConsentsTable';
import { ClientConsentList } from 'consent-manager-api';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getUserConfig } from '../../slices/consents';

export enum ConsentType {
    IDENTYUM,
    CLIENT,
}

const ProfileConsents: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { userConfig, loading } = useAppSelector((state) => state.consents);

    const noIdentyumConsents = userConfig?.identyumConsents?.length === 0;
    const noClientConsents = userConfig?.clientConsents?.length === 0;

    useEffect(() => {
        if (!userConfig) {
            dispatch(getUserConfig());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            { loading && <SkeletonCard /> }
            { noIdentyumConsents && noClientConsents && !loading && (
                <Card>
                    <CardHeader title={ t('dashboard.profile.consents.table.noConsents') } sx={{ color: 'text.secondary', fontStyle: 'italic'}} />
                </Card>
            ) }

            { userConfig?.identyumConsents && userConfig?.identyumConsents.length > 0 && (
                <Box>
                    <Card>
                        <CardHeader title={ t('dashboard.profile.consents.table.identyum.title') } />
                        <ProfileConsentsTable consents={ userConfig?.identyumConsents } consentType={ ConsentType.IDENTYUM } />
                    </Card>
                </Box>
            ) }

            { userConfig?.clientConsents && userConfig?.clientConsents.length > 0 &&
                userConfig.clientConsents.map((clientConsents: ClientConsentList) => {
                    return (
                        <Box key={ clientConsents.client } sx={ { mt: 3 } }>
                            <Card>
                                <CardHeader title={ clientConsents.client + ` ${ t('dashboard.profile.consents.table.identyum.consent') }` } />
                                <ProfileConsentsTable consents={ clientConsents?.consents } consentType={ ConsentType.CLIENT } />
                            </Card>
                        </Box>
                    );
                }) }
        </>
    );
};

export default ProfileConsents;
