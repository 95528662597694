import type { FC } from 'react';
import { Box, CircularProgress, Dialog, Typography } from '@mui/material';

interface LoadingModalProps {
    modalTitle?: string;
    open: boolean;
}

const LoadingModal: FC<LoadingModalProps> = (props) => {
    const { modalTitle, open, ...other } = props;

    return (
        <Dialog maxWidth='lg' open={open} {...other}>
            <Box sx={{ ml: 7, mr: 7, mt: 3, p: 3, textAlign: 'center' }}>
                <Typography align='center' color='textPrimary' gutterBottom variant='h5'>
                    {modalTitle ? modalTitle : ''}
                </Typography>
                <CircularProgress sx={{mt: 5, mb: 5}} size='5rem'/>
            </Box>
        </Dialog>
    );
};

export default LoadingModal;
