import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { FC, ReactNode, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { clearErrorMessage } from '../../slices/errors-handling';
import { ErrorType } from '../../model/common/error';

interface IErrorHandlerProps {
    children?: ReactNode;
}

const ErrorHandler: FC<IErrorHandlerProps> = (props) => {
    const { children } = props;

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { error } = useAppSelector((state) => state.errors);

    useEffect(() => {
        if (error && error?.errorType === ErrorType.NOT_FOUND || error?.errorType === ErrorType.NOT_ALLOWED) {
            toast.error(t(error.errorMessage));
        } else if (error && error?.errorType !== ErrorType.AUTHORIZATION_ERROR && error?.errorType !== ErrorType.WSPAY_ERROR) {
            toast.error(t(error.errorMessage));
            dispatch(clearErrorMessage());
        } else if (error && error?.errorType === ErrorType.PHONE_NUMBER_INVALID) {
            toast.error(t(error.errorMessage));
            dispatch(clearErrorMessage());
        } else if (error) {
            toast.error(t(error.errorMessage));
            dispatch(clearErrorMessage());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ error ]);

    return (
        <>
            { children }
        </>
    );
};

export default ErrorHandler;
