import type { FC } from 'react';
import SkeletonBlock from '../../../components/skeletons/SkeletonBlock';
import { Card, CardContent, CardHeader } from '@mui/material';
import PDFViewer from '../../../components/pdf-viewer/PdfViewer';

interface IDocumentContentViewerProps {
    content?: string;
    isLoading: boolean;
    title: string;
}

const DocumentContentViewer: FC<IDocumentContentViewerProps> = (props: IDocumentContentViewerProps) => {
    const { content, isLoading, title } = props;

    return (
        isLoading ? (
            <SkeletonBlock height={ 282 } />
        ) : (
            <Card sx={ { mb: 2 } }>
                <CardHeader
                    title={ title }
                    sx={ { textAlign: 'center' } } />
                <CardContent sx={ { textAlign: 'center' } }>
                    { content?.length && content?.length > 0 &&
                      <PDFViewer document={ content } /> }
                </CardContent>
            </Card>
        )

    );
};

export default DocumentContentViewer;
