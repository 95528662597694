import type { FC } from 'react';
import TanInsert from '../../pages/certificates/TanInsert';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingComponent from '../LoadingComponent';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearErrorMessage, clearRemainingAttempts } from '../../slices/tan-modal';
import { setIssueFinaCertAutomatically } from '../../slices/payments';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { RestartAltRounded } from '@mui/icons-material';

interface IFinaIssueCertificateProps {
    isIssuingCertificate: boolean;
    isSendingTan: boolean;
    onIssueCertificate: (tan: string) => void;
    onSendTan: () => void;
    onSetFromBeginning: () => void;
}

const FinaIssueCertificate: FC<IFinaIssueCertificateProps> = (props: IFinaIssueCertificateProps) => {
    const {
        isIssuingCertificate,
        isSendingTan,
        onIssueCertificate,
        onSendTan,
        onSetFromBeginning
    } = props;

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [isTanInsertShown, setIsTanInsertShown] = useState<boolean>(true);
    const { isTanModalVisible } = useAppSelector((state) => state.tanModal);
    const { isTanBlocked } = useAppSelector((state) => state.finaLcp);

    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    useEffect(() => {
        onSendTan();
        return () => {
            dispatch(clearRemainingAttempts());
            dispatch(clearErrorMessage());
            dispatch(setIssueFinaCertAutomatically(false));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleIssueCertificate = (tan: string) => {
        onIssueCertificate(tan);
        setIsTanInsertShown(false);
    };

    const isLoading = isIssuingCertificate || isSendingTan;
    const loadingTitle = isSendingTan
        ? t('tan.sending')
        : isIssuingCertificate
            ? t('dashboard.profile.general.finaLcp.steps.second.label')
            : '';

    return (
        <Box>
            { !isLoading && (isTanInsertShown || isTanModalVisible) &&
                <TanInsert
                  onConfirm={handleIssueCertificate}
                  onSendTan={onSendTan}/>
            }

            {!isLoading && isTanBlocked &&
              <Box sx={{ ml: mdUp ? 7 : 0, mr: mdUp ? 7 : 0, mt: 3, textAlign: 'center' }}>
                <Typography sx={ { mt: 2, pb: 2, pt: 2 } } color='warningMain' variant='body1'>
                    { t('dashboard.sidebar.documents.enterTan.tanBlocked.message') }
                </Typography>
                <Box sx={ { mt: 7, pl: !mdUp ? 3 : 0, pr: !mdUp ? 3 : 0 } }>
                  <CommonButton
                    fullWidth={ !mdUp }
                    btnType={ ButtonTypeEnum.NO_ICON_CONTAINED }
                    startIcon={ <RestartAltRounded fontSize='small' /> }
                    onClick={ onSetFromBeginning }>
                      { t('dashboard.profile.general.finaLcp.startFromBeginning') }
                  </CommonButton>
                </Box>
              </Box>
            }

            {isLoading &&
                <LoadingComponent title={loadingTitle} />
            }
        </Box>
    );
};

export default FinaIssueCertificate;
