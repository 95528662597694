import type { FC } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Drawer, Theme, useMediaQuery } from '@mui/material';
import { IMenuSection } from '../../../model/common/navigation/menu-section';
import { Membership } from 'group-service-api';
import { SidebarContent } from './SidebarContent';
import { IdentificationData } from 'profile-manager-api';
import { ApiGetDocumentsResponse } from 'document-scanner-api';

interface DashboardSidebarProps {
    activeGroup?: Membership;
    isLoading?: boolean;
    isProfileVerified: boolean;
    menu: IMenuSection[];
    onMobileClose: () => void;
    openMobile: boolean;
    profileData?: ApiGetDocumentsResponse;
    userIdentificationData?: IdentificationData;
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
    const {
        activeGroup,
        isLoading,
        isProfileVerified,
        menu,
        onMobileClose,
        openMobile,
        profileData,
        userIdentificationData,
    } = props;
    const location = useLocation();

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ location.pathname ]);

    if (lgUp) {
        return (
            <Drawer anchor="left"
                    open
                    PaperProps={ {
                        sx: {
                            backgroundColor: 'Background.paper',
                            height: 'calc(100% - 64px) !important',
                            top: '64px !important',
                            width: 280
                        }
                    } }
                    variant="permanent">
                <SidebarContent activeGroup={ activeGroup }
                                isLoading={ isLoading }
                                isProfileVerified={ isProfileVerified }
                                menu={ menu }
                                profileData={ profileData }
                                userIdentificationData={ userIdentificationData } />
            </Drawer>
        );
    }

    return (
        <Drawer anchor="left"
                onClose={ onMobileClose }
                open={ openMobile }
                PaperProps={ {
                    sx: {
                        backgroundColor: 'background.paper',
                        top: '64px !important',
                        width: 280
                    }
                } }
                variant="temporary">
            <SidebarContent activeGroup={ activeGroup }
                            isLoading={ isLoading }
                            isProfileVerified={ isProfileVerified }
                            menu={ menu }
                            profileData={ profileData }
                            userIdentificationData={ userIdentificationData } />
        </Drawer>
    );
};

export default DashboardSidebar;
