import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Replay } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import auth, { Token } from '../../auth/auth';
import { Button, Grid } from '@mui/material';
import { baseAxiosInstance } from '../../auth/idyAxios';
import { Misc } from '../../utils/misc';
import { ICustomJwtPayload } from '../../utils/token-helper';
import jwtDecode from 'jwt-decode';
import SplashScreen from '../../components/SplashScreen';
import { Loader } from '../../components/loader/loader';

interface AccessTokenResponse {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
}

export const AuthorizationCodeCallbackPage: FC = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [ showLoader, setShowLoader ] = useState(true);
  const [ error, setError ] = useState<string>('');
  const [ isProcessing, setIsProcessing ] = useState<boolean>(false);
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.has('code') ? searchParams.get('code') as string : '';

  useEffect(() => {
    setShowLoader(false);
    setIsProcessing(true);
    baseAxiosInstance
        .post(
            `${ process.env.REACT_APP_ACCESS_TOKEN_URL }`,
            new URLSearchParams({
              client_id: process.env.REACT_APP_CLIENT_ID ?? '',
              code: code,
              grant_type: 'authorization_code',
              redirect_uri: `${ process.env.REACT_APP_REDIRECT_URL }${ Misc.isLocalEnvironment() ? '/oauthRedirect' : '/redirect' }`,
            })
        )
        .then((response) => {
          handleSuccess(response.data as AccessTokenResponse).then(() => {
            setIsProcessing(false);
          });
        })
        .catch(() => {
          handleError();
          setIsProcessing(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    error === 'access_denied' && toast.error(t('login.error.accessDenied'));
    navigate('/login', { replace: true });
  }

  const handleSuccess = async (token: AccessTokenResponse) => {
    const idyLanguage = Misc.checkForIdyLanguage();
    idyLanguage && ( await i18n.changeLanguage(idyLanguage) );
    auth.setIdyLanguage(idyLanguage);

    const decodedToken: ICustomJwtPayload = jwtDecode(token.access_token);
    auth.setUserUuid(decodedToken.preferred_username);

    Misc.checkForClientSessionUuid();
    auth.setToken(
        {
          access_token: token.access_token,
          expires_in: token.expires_in,
          refresh_expires_in: token.refresh_expires_in,
          refresh_token: token.refresh_token,
          session_state: ''
        } as Token
    );

    const redirectLink = localStorage.getItem('redirectLink') as string;
    navigate(redirectLink ? redirectLink : '/', { replace: true });
  };

  const handleError = () => {
    setShowLoader(false);
    const searchParams = new URLSearchParams(window.location.search);
    const error = searchParams.get('error') ?? 'login_error';
    setError(error);
  };

  const handleTryAgain = () => {
    navigate('/');
  };

  function getRenderComponent() {
    return (
        <div>
          { isProcessing && <SplashScreen /> }
          { error && (
              <>
                <p style={{color: 'red', fontSize: '1.25rem'}}>
                  An error occurred: { error }
                </p>
                <Grid container
                      direction="row"
                      alignItems="center"
                      justifyContent="center">
                  <Button variant='outlined'
                          color='primary'
                          style={{
                              alignItems: 'center',
                              borderWidth: '3px',
                              display: 'flex',
                              flexDirection: 'row',
                              marginBottom: 'auto',
                              marginTop: 'auto',
                              zIndex: 100
                            }}
                          onClick={ handleTryAgain }>
                    { t('please.try.again') }
                    <Replay style={ { marginLeft: '0.5rem' } } />
                  </Button>
                </Grid>
              </>
          ) }
        </div>
    );
  }

  return (
      <Grid container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{
                height: '100vh',
                padding: '3rem',
                width: '100%'
            }}>
        { showLoader && <Loader /> }
        { getRenderComponent() }
      </Grid>
  );
};
