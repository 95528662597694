import type { FC } from 'react';
import {
    AddOutlined,
    ArrowBack,
    ArrowBackIos,
    ArrowForwardIos,
    CancelOutlined,
    Check,
    CloseOutlined,
    DeleteOutlined,
    DoubleArrow,
    Edit,
    FileDownloadRounded,
    FolderSharedRounded,
    InfoOutlined,
    IosShareRounded,
    ManageAccounts,
    MoreHorizOutlined,
    NavigateNext,
    PersonAdd,
    PersonOutline,
    QrCode2Rounded,
    RemoveCircleOutline,
    SaveOutlined, Search,
    ShoppingCartSharp,
    SkipNext,
    VerifiedUserRounded
} from '@mui/icons-material';
import { Button, ButtonProps, CircularProgress, Theme, useMediaQuery } from '@mui/material';

export enum ButtonTypeEnum {
    ADD,
    DELETE,
    REMOVE,
    CONFIRM,
    CANCEL,
    BACK,
    SAVE,
    SIGN,
    CLOSE,
    SEARCH,
    SEND,
    SKIP,
    NO_ICON,
    DETAILS,
    DOWNLOAD,
    SWITCH,
    MORE,
    ADD_PERSON,
    EDIT_PERSON,
    NEXT,
    PREVIOUS,
    NO_ICON_CONTAINED,
    SHARE,
    QR_CODE,
    MANAGE,
    CHECKOUT,
    GOTO,
    EDIT
}

interface ICommonBtnProps extends ButtonProps {
    btnType: ButtonTypeEnum;
    loading?: boolean;
    preventSmUpBreakpoint?: boolean;
}

const CommonButton: FC<ICommonBtnProps> = (props) => {
    const { btnType, loading, children, preventSmUpBreakpoint, ...other } = props;

    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm')) || preventSmUpBreakpoint;

    const loadingButton = (children: React.ReactNode): JSX.Element => {
        return (
            <Button disabled={true} variant='contained' {...other} startIcon={<></>}>
                <CircularProgress color='info' size={10} />
                <span style={{ marginLeft: '8px' }}>{children}</span>
            </Button>
        );
    };

    const buttonType = (): JSX.Element => {
        switch (btnType) {
            case ButtonTypeEnum.ADD:
                return smUp ? (
                    <Button variant='contained' startIcon={<AddOutlined fontSize='small' />} {...other}>
                        {children}
                    </Button>
                ) : (
                    <Button variant='contained' {...other}>
                        <AddOutlined fontSize='small' />
                    </Button>
                );

            case ButtonTypeEnum.DELETE:
                return smUp ? (
                    <Button variant='outlined' color='error' startIcon={<DeleteOutlined fontSize='small' />} {...other}>
                        {children}
                    </Button>
                ) : (
                    <Button variant='outlined' {...other}>
                        <DeleteOutlined fontSize='small' />
                    </Button>
                );

            case ButtonTypeEnum.REMOVE:
                return smUp ? (
                    <Button variant='outlined' color='error' startIcon={<RemoveCircleOutline fontSize='small' />} {...other}>
                        {children}
                    </Button>
                ) : (
                    <Button variant='outlined' {...other}>
                        <RemoveCircleOutline fontSize='small' />
                    </Button>
                );

            case ButtonTypeEnum.CONFIRM:
                return smUp ? (
                    <Button variant='outlined' startIcon={<Check fontSize='small' />} {...other}>
                        {children}
                    </Button>
                ) : (
                    <Button variant='outlined' {...other}>
                        <Check fontSize='small' />
                    </Button>
                );

            case ButtonTypeEnum.CANCEL:
                return smUp ? (
                    <Button variant='outlined' color='secondary' startIcon={<CancelOutlined fontSize='small' />} {...other}>
                        {children}
                    </Button>
                ) : (
                    <Button variant='outlined' {...other}>
                        <CancelOutlined fontSize='small' />
                    </Button>
                );

            case ButtonTypeEnum.BACK:
                return smUp ? (
                    <Button variant='outlined' startIcon={<ArrowBack fontSize='small' />} {...other}>
                        {children}
                    </Button>
                ) : (
                    <Button variant='outlined' {...other}>
                        <ArrowBack fontSize='small' />
                    </Button>
                );

            case ButtonTypeEnum.SAVE:
                return smUp ? (
                    <Button variant='contained' startIcon={<SaveOutlined fontSize='small' />} {...other}>
                        {children}
                    </Button>
                ) : (
                    <Button variant='contained' {...other}>
                        <SaveOutlined fontSize='small' />
                    </Button>
                );

            case ButtonTypeEnum.SIGN:
                return (
                    <Button variant='contained' startIcon={<VerifiedUserRounded fontSize='small' />} {...other}>
                        {children}
                    </Button>
                );

            case ButtonTypeEnum.CLOSE:
                return smUp ? (
                    <Button variant='outlined' color='primary' startIcon={<CloseOutlined fontSize='small' />} {...other}>
                        {children}
                    </Button>
                ) : (
                    <Button variant='outlined' {...other}>
                        <CloseOutlined fontSize='small' />
                    </Button>
                );

            case ButtonTypeEnum.DETAILS:
                return (
                    <Button variant='outlined' color='primary' startIcon={<InfoOutlined fontSize='small' />} {...other}>
                        {children}
                    </Button>
                );

            case ButtonTypeEnum.SEARCH:
                return (
                    <Button variant='contained' color='primary' startIcon={<Search fontSize='small' />} {...other}>
                        {children}
                    </Button>
                );

            case ButtonTypeEnum.SEND:
                return (
                    <Button variant='contained' color='primary' startIcon={<NavigateNext fontSize='small' />} {...other}>
                        {children}
                    </Button>
                );

            case ButtonTypeEnum.SKIP:
                return smUp ? (
                    <Button variant='outlined' color='primary' startIcon={<SkipNext fontSize='small' />} {...other}>
                        {children}
                    </Button>
                ) : (
                    <Button variant='outlined' {...other}>
                        <SkipNext fontSize='small' />
                    </Button>
                );

            case ButtonTypeEnum.NO_ICON:
                return (
                    <Button variant='outlined' color='primary' {...other}>
                        {children}
                    </Button>
                );

            case ButtonTypeEnum.DOWNLOAD:
                return (
                    <Button variant='outlined' color='primary' startIcon={<FileDownloadRounded fontSize='small' />} {...other}>
                        {children}
                    </Button>
                );

            case ButtonTypeEnum.SWITCH:
                return (
                    <Button variant='outlined' color='primary' startIcon={<FolderSharedRounded fontSize='small' />} {...other}>
                        {children}
                    </Button>
                );

            case ButtonTypeEnum.MORE:
                return smUp ? (
                    <Button
                        startIcon={<MoreHorizOutlined fontSize='small' />}
                        {...other}>
                        {children}
                    </Button>
                ) : (
                    <Button variant='outlined' {...other}>
                        <MoreHorizOutlined fontSize='small' />
                    </Button>);

            case ButtonTypeEnum.ADD_PERSON:
                return (
                    <Button variant='outlined' color='primary' startIcon={<PersonAdd fontSize='small' />} {...other}>
                        {children}
                    </Button>
                );

            case ButtonTypeEnum.EDIT_PERSON:
                return (
                    <Button variant='outlined' color='primary' startIcon={<PersonOutline fontSize='small' />} {...other}>
                        {children}
                    </Button>
                );

            case ButtonTypeEnum.NEXT:
                return smUp ? (
                    <Button variant='outlined' color='primary' endIcon={<ArrowForwardIos fontSize='small' />} {...other}>
                        {children}
                    </Button>
                ) : (
                    <Button variant='outlined' {...other}>
                        <ArrowForwardIos fontSize='small' />
                    </Button>
                );

            case ButtonTypeEnum.PREVIOUS:
                return smUp ? (
                    <Button variant='outlined' color='primary' startIcon={<ArrowBackIos fontSize='small' />} {...other}>
                        {children}
                    </Button>
                ) : (
                    <Button variant='outlined' {...other}>
                        <ArrowBackIos fontSize='small' />
                    </Button>
                );

            case ButtonTypeEnum.NO_ICON_CONTAINED:
                return (
                    <Button variant='contained' color='primary' {...other}>
                        {children}
                    </Button>
                );
            case ButtonTypeEnum.SHARE:
                return (
                    <Button variant='outlined' color='primary' startIcon={<IosShareRounded fontSize='small' />} {...other}>
                        {children}
                    </Button>
                );

            case ButtonTypeEnum.QR_CODE:
                return (
                    <Button variant='outlined' color='primary' startIcon={<QrCode2Rounded fontSize='small' />} {...other}>
                        {children}
                    </Button>
                );

            case ButtonTypeEnum.MANAGE:
                return (
                    <Button variant='outlined' color='primary' startIcon={<ManageAccounts fontSize='small' />} {...other}>
                        {children}
                    </Button>
                );

            case ButtonTypeEnum.EDIT:
                return (
                    <Button variant='outlined' color='primary' startIcon={<Edit fontSize='small' />} {...other}>
                        {children}
                    </Button>
                );

            case ButtonTypeEnum.CHECKOUT:
                return (
                    <Button variant='contained' color='primary' size='large' startIcon={<ShoppingCartSharp fontSize='small' />} {...other}>
                        {children}
                    </Button>
                );

            case ButtonTypeEnum.GOTO:
                return (
                    <Button variant='outlined' color='primary' startIcon={<DoubleArrow fontSize='small' />} {...other}>
                        {children}
                    </Button>
                );

            default:
                return <Button>/</Button>;
        }
    };

    return <>{loading ? loadingButton(children) : buttonType()}</>;
};

export default CommonButton;
