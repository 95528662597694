import { ErrorType, IError } from '../model/common/error';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { PaymentsHelper } from '../utils/payments-helper';
import { ApiWsPayResultRequest } from 'payment-service-api';

interface IErrorsHandlingState {
    error: IError | undefined;
}

enum HttpErrorCodes {
    CONFLICT = 409,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    UNPROCESSABLE_ENTITY = 422
}

const initialState: IErrorsHandlingState = {
    error: undefined
};

const errorHandlingSlice = createSlice({
    name: 'errorHandling',
    initialState,
    reducers: {
        clearErrorMessage(state: IErrorsHandlingState) {
            state.error = undefined;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        handleError(state: IErrorsHandlingState, action: PayloadAction<Error | AxiosError | any>) {
            if (action.payload?.response?.status === HttpErrorCodes.UNPROCESSABLE_ENTITY) {
                state.error = {
                    error: true,
                    errorMessage: action.payload?.response?.data?.message,
                    errorType: ErrorType.UNPROCESSABLE_ENTITY
                };
                return;
            }

            if (action.payload?.response?.status === HttpErrorCodes.CONFLICT) {
                state.error = {
                    error: true,
                    errorMessage: 'error.userExists',
                    errorType: ErrorType.USER_EXISTS_ERROR
                };
                return;
            }

            if (action.payload?.response?.status === HttpErrorCodes.FORBIDDEN) {
                state.error = {
                    error: true,
                    errorMessage: 'error.notAllowed',
                    errorType: ErrorType.NOT_ALLOWED
                };
                return;
            }

            if (action.payload?.response?.status === HttpErrorCodes.NOT_FOUND) {
                state.error = {
                    error: true,
                    errorMessage: 'error.notFound',
                    errorType: ErrorType.NOT_FOUND
                };
                return;
            }

            state.error = {
                error: true,
                errorMessage: 'error.unexpectedError',
                errorType: ErrorType.GENERIC_ERROR
            };
        },
        handleWsPayError(state: IErrorsHandlingState, action: PayloadAction<ApiWsPayResultRequest>) {
            const apiWsPayResultRequest: ApiWsPayResultRequest = action.payload;
            state.error = {
                error: true,
                errorType: ErrorType.WSPAY_ERROR,
                errorMessage: PaymentsHelper.getResultMessageFromWsPayResult(apiWsPayResultRequest)
            };
        },
        setErrorMessage(state: IErrorsHandlingState, action: PayloadAction<IError>) {
            state.error = action.payload;
        }
    }
});

export const { reducer } = errorHandlingSlice;

export const { setErrorMessage, clearErrorMessage, handleError, handleWsPayError } = errorHandlingSlice.actions;

export default errorHandlingSlice;
