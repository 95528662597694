import {Membership, MembershipRoleEnum, MembershipSimple, MembershipSimpleRoleEnum, MembershipSimpleStatusEnum} from 'group-service-api';
import {DocumentsHelper} from '../../utils/documents-helper';

export class MembersTableUtil {
    public static roleText = (role: MembershipSimpleRoleEnum): string => {
        switch (role) {
            case MembershipSimpleRoleEnum.Owner:
                return 'groups.memberships.owner';
            case MembershipSimpleRoleEnum.Admin:
                return 'groups.details.admin';
            case MembershipSimpleRoleEnum.Regular:
                return 'groups.memberships.member';

            default:
                return '-';
        }
    };

    public static hasOwnerRights = (role: MembershipRoleEnum | undefined): boolean => {
        return role === MembershipRoleEnum.Owner;
    };

    public static hasAdminRights = (role: MembershipRoleEnum | undefined): boolean => {
        return role === MembershipRoleEnum.Admin;
    };

    public static hasRegularRights = (role: MembershipRoleEnum | undefined): boolean => {
        return role === MembershipRoleEnum.Regular;
    };

    public static fullName = (membership: MembershipSimple) => {
        return membership.fullName ? DocumentsHelper.capitalizeEachWord(membership.fullName) : membership.email ? membership.email : membership.phone;
    };

    public static showSwitch = (userRole: MembershipRoleEnum | undefined, membershipRole: MembershipSimpleRoleEnum): boolean => {
        return membershipRole !== MembershipSimpleRoleEnum.Owner && !MembersTableUtil.hasRegularRights(userRole);
    };

    public static showTooltipTitle = (statusEnum: MembershipSimpleStatusEnum): string => {
        switch (statusEnum) {
            case MembershipSimpleStatusEnum.Confirmed:
                return 'groups.memberships.status.confirmed';
            case MembershipSimpleStatusEnum.Pending:
                return 'groups.details.status.pending';

            default:
                return '-';
        }
    };

    public static showDelete = (activeGroup: Membership | undefined, membership: MembershipSimple): boolean => {

        if (!activeGroup) {
            return false;
        }

        if (activeGroup.role === MembershipRoleEnum.Regular) {
            return false;
        }

        if (activeGroup.uuid === membership.uuid) {
            return false;
        }

        if (activeGroup.role === MembershipRoleEnum.Owner) {
            return true;
        }

        return activeGroup?.role === MembershipRoleEnum.Admin && membership.role !== MembershipSimpleRoleEnum.Owner;


    };
}
