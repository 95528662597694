import type { FC } from 'react';
import { ChangeEvent, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ErrorType, IError } from '../../model/common/error';
import { DocumentsHelper } from '../../utils/documents-helper';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';

interface IAddEmailProps {
    existingEmails: string[];
    onAddEmails: (emails: string[]) => void;
    onOpenContactModal: (isOpen: boolean) => void;
}

const AddEmail: FC<IAddEmailProps> = ({ existingEmails, onAddEmails, onOpenContactModal }) => {
    const { t } = useTranslation();

    const [ email, setEmail ] = useState<string>('');
    const [ validationError, setValidationError ] = useState<IError>({
        error: false,
        errorMessage: '',
        errorType: ErrorType.VALIDATION_ERROR
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setValidationError({ error: false, errorMessage: '', errorType: ErrorType.VALIDATION_ERROR });
        setEmail(event.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            handleAddClick();
        }
    };

    const handleAddClick = () => {
        const isValidEmail = DocumentsHelper.isEmailFormat(email);
        const isAlreadyAdded = existingEmails.filter(e => e === email)?.length > 0;

        if (!isValidEmail) {
            setValidationError({
                error: true,
                errorMessage: t('receiver.error.email.wrongFormat') as string,
                errorType: ErrorType.VALIDATION_ERROR
            });
        }

        if (isAlreadyAdded) {
            setValidationError({
                error: true,
                errorMessage: t('receiver.error.email.alreadyAdded') as string,
                errorType: ErrorType.VALIDATION_ERROR
            });
            setEmail('');
        }

        if (isValidEmail && !isAlreadyAdded) {
            onAddEmails([email]);
            setEmail('');
        }
    };

    return (
        <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={ 2 }>
            <Grid item xs={ 12 } sm={ 12 } md={ 8 } lg={ 8 } xl={ 8 }>
                <TextField error={ Boolean(validationError.errorMessage) }
                           helperText={ Boolean(validationError.errorMessage) && validationError.errorMessage }
                           autoFocus
                           fullWidth
                           label={ t('receiver.input.email') }
                           onChange={ handleChange }
                           onKeyDown={ handleKeyDown }
                           placeholder={ t('receiver.input.email.example') }
                           rows={ 1 }
                           value={ email }
                           variant='outlined'
                />
            </Grid>
            <Grid item xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 } xl={ 4 }>
                <CommonButton btnType={ ButtonTypeEnum.ADD }
                              onClick={ () => handleAddClick() }
                              disabled={ !email || email?.indexOf('@') === -1 }>
                    { t('receiver.button.add') }
                </CommonButton>
                <CommonButton btnType={ ButtonTypeEnum.NO_ICON }
                              onClick={ () => onOpenContactModal(true) }
                              variant='text'>
                    { t('receiver.button.addFromContacts') }
                </CommonButton>
            </Grid>
        </Grid>
    );
};

export default AddEmail;
