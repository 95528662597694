import type {ChangeEvent, FC} from 'react';
import { Box, Button, Typography } from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';

interface IImportExcelFileProps {
    onMailsImport: (mails: string[]) => void;
}

const ImportExcelFile: FC<IImportExcelFileProps> = (props) => {
    const { t } = useTranslation();

    const { onMailsImport } = props;

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        const reader = new FileReader();

        reader.onload = (e) => {
            const data = e.target?.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            const extractedEmails = XLSX.utils
                .sheet_to_json(worksheet, { header: 1 })
                .map((row: never) => row[0]);

            const validEmails = extractedEmails.filter((email: string) => email && email.trim() !== '');

            onMailsImport(validEmails);
        };

        if (file) {
            reader.readAsBinaryString(file);
        }
    };

    return (
        <div>
            <Box sx={{ textAlign: 'center' }} py={4} borderBottom="1px solid lightgrey">
                <Typography variant="body2">{t('survey.SurveyUsersPage.tabs.importXls.info')}</Typography>
                <Button variant="outlined" startIcon={<UploadFile fontSize="small" />} component="label" sx={{ mt: 3 }}>
                    {t('survey.SurveyUsersPage.tabs.importXls.button.import')}
                    <input type="file" hidden onChange={handleFileUpload} />
                </Button>
            </Box>
        </div>
    );
};

export default ImportExcelFile;
