import type { FC } from 'react';
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { PhoneResponse } from 'contacts-service-api';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { UserContactsHelper } from '../../utils/user-contacts-helper';
import { useTranslation } from 'react-i18next';

interface INewContactPhoneNumberListProps {
    phoneNumbers: PhoneResponse[],
    editPhoneNumberHandler: (phoneNumber: PhoneResponse) => void,
    deletePhoneNumberHandler: (phoneNumber: PhoneResponse) => void
}

const UserContactPhoneNumberList: FC<INewContactPhoneNumberListProps> = (props) => {
    const { phoneNumbers, editPhoneNumberHandler, deletePhoneNumberHandler } = props;
    const { t } = useTranslation();
    const sortedPhones = UserContactsHelper.sortPhones(phoneNumbers);

    if (!sortedPhones || sortedPhones.length === 0) {
        return <Typography variant='caption'>
            { t('userContacts.noPhones') }
        </Typography>;
    }

    return (
        <Table>
            <TableBody>
                { sortedPhones?.map((phoneNumber: PhoneResponse, index: number) => {
                    return (
                        <TableRow key={ index } hover>
                            <TableCell onClick={ () => editPhoneNumberHandler(phoneNumber) } sx={{ borderBottom: 'unset' }}>
                                { '+' + phoneNumber?.value?.dialCode + ' ' + phoneNumber?.value?.number }
                            </TableCell>
                            <TableCell sx={ { borderBottom: 'unset',  textAlign: 'right' } }>
                                <Edit sx={ { cursor: 'pointer', mr: 2, opacity: '0.7' } }
                                      fontSize='small'
                                      onClick={ () => editPhoneNumberHandler(phoneNumber) } />
                                <DeleteOutline sx={ { cursor: 'pointer', opacity: '0.7' } }
                                               fontSize='small'
                                               color='error'
                                               onClick={ () => deletePhoneNumberHandler(phoneNumber) } />
                            </TableCell>
                        </TableRow>
                    );
                }) }
            </TableBody>
        </Table>
    );
};
export default UserContactPhoneNumberList;
