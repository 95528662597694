import { FC, useEffect, useState } from 'react';
import { Box, Card, CardContent, Container, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    createFinaUser,
    getContractPreview,
    getSignedContract,
    hasValidFinaPKICloudCertificate,
    issueCertificate,
    resetFinaFlags,
    resetIssueCertificateSuccessful,
    sendTan,
    signContract
} from 'slices/fina-lcp';
import { getProfileData } from 'slices/profile-data';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GroupRoutes, PrivateRoutes } from '../../routes';
import FinaStepper from '../../components/fina/FinaStepper';
import FinaIntro from '../../components/fina/FinaIntro';
import FinaContract from '../../components/fina/FinaContract';
import FinaFinish from '../../components/fina/FinaFinish';
import FinaIssueCertificate from '../../components/fina/FinaIssueCertificate';
import { CertInfoStatusEnum } from 'fina-lcp-certifier-api';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';

interface ICertificateIssueProps {
    onBackToPlans: () => void;
}

const CertificateIssue: FC<ICertificateIssueProps> = ({ onBackToPlans }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        certInfo,
        createFinaUserSuccessFul,
        finaContractContent,
        hasValidFinaCertificate,
        isCreatingFinaUser,
        isFinaContractSignedSuccessful,
        isIssuingCertificate,
        isLoadingContract,
        isOibMissing,
        isSendingTan,
        isSigningContract,
        issueCertificateSuccessful,
        isTanBlocked,
        finaUserData
    } = useAppSelector((state) => state.finaLcp);
    const { profileData } = useAppSelector((state) => state.profileData);
    const { activeGroup } = useAppSelector((state) => state.groups);

    const [ activeStep, setActiveStep ] = useState<number>(0);
    const [ isUserDataDisplayed, setIsUserDataDisplayed ] = useState<boolean>(false);

    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    useEffect(() => {
        !profileData && dispatch(getProfileData());
        dispatch(hasValidFinaPKICloudCertificate());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (hasValidFinaCertificate) {
            setActiveStep(3);
        }

        if (certInfo?.status === CertInfoStatusEnum.Valid && !certInfo.contractSigned) {
            setActiveStep(2);
        }

        return () => {
            dispatch(resetFinaFlags());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (createFinaUserSuccessFul) {
            setIsUserDataDisplayed(true);
        }

        if (issueCertificateSuccessful) {
            setActiveStep((prevState) => prevState + 1);
            dispatch(resetIssueCertificateSuccessful());
            setIsUserDataDisplayed(false);
        }

        if (isFinaContractSignedSuccessful) {
            setActiveStep((prevState) => prevState + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ createFinaUserSuccessFul, issueCertificateSuccessful, isFinaContractSignedSuccessful ]);

    const handleOnBackToDocuments = () => {
        navigate(activeGroup
            ? GroupRoutes.groups_sent_documents_route.path.replace(':groupUuid', activeGroup?.group?.uuid)
            : PrivateRoutes.private_received_documents_route.path);
    };

    const handleOnBackToPlans = () => {
        onBackToPlans();
    };

    const handleNextStep = () => {
        setActiveStep((prevState) => prevState + 1);
        setIsUserDataDisplayed(false);
    };

    const setFromBeginning = () => {
        dispatch(resetFinaFlags());
        setActiveStep(0);
    };

    const handleCreateFinaUser = (pin?: string) => {
        dispatch(createFinaUser(pin ? pin : undefined));
    };

    const handleSendTan = () => {
        dispatch(sendTan());
    };

    const handleIssueCertificate = (tan: string) => {
        dispatch(issueCertificate(tan));
    };

    const handleSignContract = (tan: string) => {
        dispatch(signContract(tan));
    };

    const handleLoadContract = () => {
        if (certInfo?.contractSigned) {
            dispatch(getSignedContract());
        }

        if (!certInfo?.contractSigned) {
            dispatch(getContractPreview());
        }
    };

    return (
        <>
            <Box sx={ { backgroundColor: 'background.default', minHeight: '100%', pb: 8 } }>
                <Container>
                    <Grid container item alignItems="center" justifyContent="space-between" spacing={ 3 } xs={ 12 } mb={ 4 }>
                        <Grid item>
                            <Typography color="textPrimary" variant="h5">
                                { t('dashboard.profile.general.finaLcp') }
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CommonButton
                                btnType={ ButtonTypeEnum.BACK }
                                onClick={ handleOnBackToPlans }>
                                { t('dashboard.profile.general.finaLcp.backToPlans') }
                            </CommonButton>
                        </Grid>
                    </Grid>
                    <Card sx={ { mt: 3, pb: 3 } }>
                        <CardContent>
                            <Box sx={ { m: mdUp ? 3 : 0, pt: 3 } }>
                                <Grid container spacing={ 2 } direction="row">
                                    <Grid item xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 }
                                          sx={ { borderRight: mdUp ? '1px solid #dcdcdc' : 'unset' } }>
                                        <FinaStepper activeStep={ activeStep } />
                                    </Grid>
                                    <Grid item xs={ 12 } sm={ 12 } md={ 8 } lg={ 8 } alignSelf="center">
                                        { activeStep === 0 && (
                                            <FinaIntro isUserDataDisplayed={ isUserDataDisplayed }
                                                       isOibInsertionVisible={ isOibMissing }
                                                       isTanBlocked={ isTanBlocked }
                                                       finaUserData={ finaUserData }
                                                       isCreatingFinaUser={ isCreatingFinaUser }
                                                       isSendingTan={ isSendingTan }
                                                       onCreateFinaUser={ handleCreateFinaUser }
                                                       onNextStep={ handleNextStep }
                                                       onSetFromBeginning={ setFromBeginning } />
                                        ) }

                                        { activeStep === 1 && (
                                            <FinaIssueCertificate isSendingTan={ isSendingTan }
                                                                  isIssuingCertificate={ isIssuingCertificate }
                                                                  onIssueCertificate={ handleIssueCertificate }
                                                                  onSendTan={ handleSendTan }
                                                                  onSetFromBeginning={ setFromBeginning } />
                                        ) }

                                        { activeStep === 2 && (
                                            <FinaContract isSendingTan={ isSendingTan }
                                                          isSigningContract={ isSigningContract }
                                                          finaContractContent={ finaContractContent ?? '' }
                                                          onLoadContract={ handleLoadContract }
                                                          onSendTan={ handleSendTan }
                                                          onSignContract={ handleSignContract }
                                                          onSetFromBeginning={ setFromBeginning }
                                                          isLoadingContract={ isLoadingContract } />
                                        ) }

                                        { activeStep === 3 && (
                                            <FinaFinish onBackToDocuments={ handleOnBackToDocuments } />
                                        ) }
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default CertificateIssue;
