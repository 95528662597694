import type {FC} from 'react';
import {ChangeEvent, useEffect, useState} from 'react';
import Page from '../../components/shared/Page';
import {Box, Card, CardContent, TextField, Typography} from '@mui/material';
import {cancelMembership, deleteGroup, getGroup, renameGroup, resetActiveGroup, updateMembership} from '../../slices/groups';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {MembershipRoleEnum, MembershipSimple, MembershipSimpleRoleEnum, MembershipSimpleStatusEnum, UpdateMembershipRequestRoleEnum,} from 'group-service-api';
import OwnershipDialog from '../../components/groups/OwnershipDialog';
import ConfirmationDialog from '../../components/shared/ConfirmationDialog';
import CommonButton, {ButtonTypeEnum} from '../../components/shared/CommonButton';

const GroupSettingsPage: FC = () => {
    const { selectedGroup, activeGroup, isLoadingAction, isLoadingGroupRename } = useAppSelector((state) => state.groups);
    const params = useParams();
    const { groupUuid } = params;

    const [groupName, setGroupName] = useState<string | undefined>(selectedGroup?.name);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const [openDeleteGroupDialog, setOpenDeleteGroupDialog] = useState<boolean>(false);
    const [openCancelMembershipDialog, setOpenCancelMembershipDialog] = useState<boolean>(false);
    const [openChangeOwnerDialog, setOpenChangeOwnerDialog] = useState<boolean>(false);

    useEffect(() => {
        if (!selectedGroup && groupUuid) {
            dispatch(getGroup(groupUuid));
        }
        setGroupName(selectedGroup?.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGroup]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setGroupName(event.target.value);
    };

    const handleOpenCancelMembershipDialog = () => {
        setOpenCancelMembershipDialog(true);
    };

    const handleCloseCancelMembershipDialog = (confirmation: boolean) => {
        setOpenCancelMembershipDialog(false);
        if (confirmation) {
            handleCancelMembership();
        }
    };

    const handleCancelMembership = () => {
        if (activeGroup) {
            dispatch(cancelMembership(activeGroup));
            dispatch(resetActiveGroup());
            navigate('/dashboard');
        }
    };

    const handleOpenDeleteGroupDialog = () => {
        setOpenDeleteGroupDialog(true);
    };

    const handleCloseDeleteGroupDialog = (confirmation: boolean) => {
        setOpenDeleteGroupDialog(false);
        if (confirmation) {
            handleDeleteGroup(groupUuid);
        }
    };

    const handleDeleteGroup = (groupUuid: string | undefined) => {
        if (groupUuid) {
            dispatch(deleteGroup(groupUuid));
            dispatch(resetActiveGroup());
            navigate('/dashboard');
        }
    };

    const handleChangeOwner = (newOwner: MembershipSimple | undefined) => {
        setOpenChangeOwnerDialog(false);
        if (newOwner) {
            dispatch(updateMembership(groupUuid ?? '', newOwner.uuid, { role: UpdateMembershipRequestRoleEnum.Owner }));
            navigate('/dashboard/groups/' + groupUuid + '/members');
        }
    };

    const handleOpenChangeOwnerDialog = () => {
        setOpenChangeOwnerDialog(true);
    };

    const handleRename = () => {
        if (activeGroup?.group.name !== groupName && groupUuid && groupName) {
            dispatch(renameGroup(groupUuid, groupName));
        }
    };

    const validOwnerMemberships = () => {
        return selectedGroup?.memberships
            .filter((member: MembershipSimple) => member.role !== MembershipSimpleRoleEnum.Owner)
            .filter((member: MembershipSimple) => member.status === MembershipSimpleStatusEnum.Confirmed);
    };

    return (
        <Page pageTitle={t('groups.settings.page.title')}>
            <Card sx={{ p: 3, width: 'fit-content' }}>
                <CardContent>
                    <Box sx={{ mb: 3 }}>
                        <TextField
                            disabled={activeGroup?.role !== MembershipRoleEnum.Owner}
                            sx={{ mr: 3, mb: 3 }}
                            size='small'
                            focused={!!groupName}
                            onChange={handleChange}
                            label={t('groups.summary.addGroup.placeholder')}
                            rows={1}
                            value={groupName ? groupName : '-'}
                            variant='outlined'
                        />
                        <CommonButton
                            btnType={ButtonTypeEnum.SAVE}
                            onClick={handleRename}
                            disabled={activeGroup?.group.name === groupName}
                            loading={isLoadingGroupRename}
                        >
                            {t('groups.summary.groupNameChange.button')}
                        </CommonButton>

                        {activeGroup?.role === MembershipRoleEnum.Owner && (
                            <Box sx={{ mt: 3 }}>
                                <CommonButton
                                    btnType={ButtonTypeEnum.MANAGE}
                                    disabled={!(validOwnerMemberships && validOwnerMemberships.length > 0)}
                                    onClick={handleOpenChangeOwnerDialog}>
                                    {t('groups.ownership.dialog.changeOwner')}
                                </CommonButton>
                                {!selectedGroup?.memberships
                                    .filter((member: MembershipSimple) => member.role !== MembershipSimpleRoleEnum.Owner)
                                    .filter((member: MembershipSimple) => member.status === MembershipSimpleStatusEnum.Confirmed) && (
                                    <Typography variant='caption' sx={{ fontStyle: 'italic' }}>
                                        {t('groups.ownership.dialog.changeOwner.noUsers')}
                                    </Typography>
                                )}
                            </Box>
                        )}

                        {activeGroup?.role === MembershipRoleEnum.Owner && (
                            <Box sx={{ mt: 3 }}>
                                <CommonButton btnType={ButtonTypeEnum.DELETE} loading={isLoadingAction} onClick={handleOpenDeleteGroupDialog}>
                                    {t('groups.summary.deleteGroup.title')}
                                </CommonButton>
                            </Box>
                        )}

                        {activeGroup?.role === MembershipRoleEnum.Admin || activeGroup?.role === MembershipRoleEnum.Regular && (
                            <Box sx={{ mt: 3 }}>
                                <CommonButton btnType={ButtonTypeEnum.CANCEL} onClick={handleOpenCancelMembershipDialog}>
                                    {t('groups.memberships.cancelMembership')}
                                </CommonButton>
                            </Box>
                        )}
                    </Box>
                </CardContent>
            </Card>

            <ConfirmationDialog
                title={t('groups.summary.deleteGroup.title')}
                message={t('groups.settings.deleteGroup.message')}
                onConfirm={() => handleCloseDeleteGroupDialog(true)}
                onCancel={() => handleCloseDeleteGroupDialog(false)}
                open={openDeleteGroupDialog} />

            <ConfirmationDialog
                title={t('groups.memberships.cancelMembership')}
                message={t('groups.settings.leaveGroup.message')}
                onConfirm={() => handleCloseCancelMembershipDialog(true)}
                onCancel={() => handleCloseCancelMembershipDialog(false)}
                open={openCancelMembershipDialog} />

            <OwnershipDialog selectedGroup={selectedGroup} open={openChangeOwnerDialog} onClose={handleChangeOwner} />
        </Page>
    );
};

export default GroupSettingsPage;
