import type { FC } from 'react';
import { useState } from 'react';
import { Box, Tab, Tabs, Theme, useMediaQuery } from '@mui/material';
import {EmailRounded, PhoneRounded, UploadFileOutlined} from '@mui/icons-material';
import TabPanel from '../shared/TabPanel';
import AddEmail from './AddEmail';
import AddPhoneNumber from './AddPhoneNumber';
import { useTranslation } from 'react-i18next';
import { IPhone } from '../../model/common/documents/document-user';
import ContactsChooserModal, { IContact } from '../ContactsChooserModal';
import ImportExcelFile from '../survey/ImportExcelFile';

interface IAddEmailOrPhoneNumberProps {
    onAddEmails: (emails: string[]) => void;
    onAddPhones: (phones: IPhone[]) => void;
    onAddContacts: (contacts: IContact[]) => void;
    existingEmails: string[];
    existingPhones: IPhone[];
    skipAddingToPanel?: boolean;
    xlsImportEnabled?: boolean;
    onMailsImport?: (emails: string[]) => void;
}

const AddEmailOrPhoneNumber: FC<IAddEmailOrPhoneNumberProps> = (props: IAddEmailOrPhoneNumberProps) => {
    const {
        existingEmails,
        existingPhones,
        onAddContacts,
        onAddEmails,
        onAddPhones,
        skipAddingToPanel,
        onMailsImport,
        xlsImportEnabled
    } = props;

    const { t } = useTranslation();
    const [ value, setValue ] = useState<number>(0);
    const [ isContactModalOpen, setIsContactModalOpen ] = useState<boolean>(false);

    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleOpenContactModal = (isOpen: boolean) => {
        setIsContactModalOpen(isOpen);
    };

    return (
        <>
            <Box sx={ { borderBottom: 1, borderColor: 'divider', mt: 1 } }>
                <Tabs value={ value }
                      onChange={ handleChange }
                      variant={ 'scrollable' }
                      scrollButtons={ 'auto' }
                      sx={ {
                          '& .MuiTabs-flexContainer': {
                              justifyContent: smUp ? 'normal' : 'space-evenly'
                          }
                      } }>
                    <Tab icon={ <EmailRounded /> }
                         label={ smUp ? t('receiver.tabs.email') : '' } />
                    <Tab icon={ <PhoneRounded /> }
                         label={ smUp ? t('receiver.tabs.mobile') : '' } />
                    {xlsImportEnabled && <Tab icon={ <UploadFileOutlined /> }
                         label={ smUp ? t('receiver.tabs.xls') : '' } />}
                </Tabs>
            </Box>

            <TabPanel value={ value } index={ 0 }>
                <AddEmail onAddEmails={ onAddEmails }
                          existingEmails={ existingEmails }
                          onOpenContactModal={ handleOpenContactModal } />
            </TabPanel>

            <TabPanel value={ value } index={ 1 }>
                <AddPhoneNumber onAddPhones={ onAddPhones }
                                existingPhones={ existingPhones }
                                onOpenContactModal={ handleOpenContactModal } />
            </TabPanel>

            {xlsImportEnabled && onMailsImport && <TabPanel value={ value } index={ 2 } >
                <ImportExcelFile onMailsImport={ onMailsImport } />
            </TabPanel>}

            <ContactsChooserModal existingEmails={ existingEmails }
                                  existingPhones={ existingPhones }
                                  onAdd={ onAddContacts }
                                  onClose={ () => handleOpenContactModal(false) }
                                  open={ isContactModalOpen }
                                  skipAddingToPanel={skipAddingToPanel} />
        </>
    );
};

export default AddEmailOrPhoneNumber;
