import type { FC } from 'react';
import { useRef, useState } from 'react';
import { ElementRequestTypeEnum, OptionRequestTypeEnum, QuestionRequest, QuestionRequestTypeEnum } from 'survey-service-api';
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../../shared/CommonButton';

interface IAddQuestionDialogProps {
    onClose: (request?: QuestionRequest) => void;
    open: boolean;
}

interface IOptionType {
    enabled: boolean;
    type: OptionRequestTypeEnum;
}

const options: IOptionType[] = [
    { enabled: true, type: QuestionRequestTypeEnum.Input },
    { enabled: true, type: QuestionRequestTypeEnum.Checkbox },
    { enabled: true, type: QuestionRequestTypeEnum.Radio },
];

const AddQuestionDialog: FC<IAddQuestionDialogProps> = (props) => {
    const { onClose, open } = props;
    const { t } = useTranslation();

    const [request, setRequest] = useState<QuestionRequest>({
        idyWalletQuestion: false,
        ordinal: 1,
        predecessorElement: undefined,
        successorElement: undefined,
        text: '',
        type: QuestionRequestTypeEnum.Input,
    });

    const radioGroupRef = useRef<HTMLElement>(null);

    const handleCancel = () => {
        onClose();
        options.map((value) => (value.enabled = true));
    };

    const handleOk = () => {
        onClose(request);
        setRequest({
            idyWalletQuestion: false,
            ordinal: 1,
            predecessorElement: undefined,
            successorElement: undefined,
            text: '',
            type: QuestionRequestTypeEnum.Input,
        });
        options.map((value) => (value.enabled = true));
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            type: (event.target as HTMLInputElement).value as QuestionRequestTypeEnum,
        });
    };

    const handleQuestionTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setRequest({
            ...request,
            text: value,
        });
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (open && (e.code === 'Enter' || e.key === 'Enter')) {
            onClose(request);
            setRequest({
                idyWalletQuestion: false,
                ordinal: 1,
                predecessorElement: undefined,
                successorElement: undefined,
                text: '',
                type: QuestionRequestTypeEnum.Input,
            });
            options.map((value) => (value.enabled = true));
        }
    };

    const handlePredecessorTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setRequest({
            ...request,
            predecessorElement: { text: value, type: ElementRequestTypeEnum.Description },
        });
    };

    const handleSuccessorTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setRequest({
            ...request,
            successorElement: { text: value, type: ElementRequestTypeEnum.Description },
        });
    };

    const handleChangeIdyWalletQuestion = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setRequest({
            ...request,
            idyWalletQuestion: isChecked,
            type: isChecked ? OptionRequestTypeEnum.Input : request.type,
        });

        if (isChecked) {
            options.map((value) => (value.enabled = value.type !== OptionRequestTypeEnum.Input));
        } else {
            options.map((value) => (value.enabled = true));
        }
    };

    return (
        <Dialog open={open} onClose={() => onClose()} onKeyUp={handleKeyUp} fullWidth>
            <DialogTitle>{t('survey.AddQuestionDialog.title')}</DialogTitle>
            <DialogContent dividers>
                <Box>
                    <FormControlLabel
                        control={<Checkbox checked={request.idyWalletQuestion} onChange={handleChangeIdyWalletQuestion} name={'idyWalletQuestion'} />}
                        label={t('AddQuestionDialog.formControl.label.info')}
                    />
                </Box>
                <Box>
                    <Typography variant="caption">{t('survey.AddQuestionDialog.question.type')}</Typography>
                    <RadioGroup ref={radioGroupRef} value={request?.type} onChange={handleTypeChange}>
                        {options.map((option) => (
                            <FormControlLabel value={option.type} key={option.type} control={<Radio />} label={option.type} disabled={!option.enabled} />
                        ))}
                    </RadioGroup>
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        multiline
                        rows={5}
                        placeholder="Predecessor element text"
                        label="Predecessor element text"
                        name="Predecessor element text"
                        value={request.predecessorElement?.text}
                        onChange={handlePredecessorTextChange}
                    />
                </Box>

                <Box sx={{ my: 4 }}>
                    <TextField
                        autoFocus
                        fullWidth
                        multiline
                        rows={5}
                        placeholder={t('survey.AddQuestionDialog.question.placeholder')}
                        label={t('survey.AddQuestionDialog.question.placeholder')}
                        name={t('survey.AddQuestionDialog.question.placeholder')}
                        value={request.text}
                        onChange={handleQuestionTextChange}
                    />
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        multiline
                        rows={5}
                        placeholder="Successor element text"
                        label="Successor element text"
                        name="Successor element text"
                        value={request.successorElement?.text}
                        onChange={handleSuccessorTextChange}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <CommonButton btnType={ButtonTypeEnum.CANCEL} onClick={handleCancel}>
                    {t('survey.AddQuestionDialog.dialogActions.button.cancel')}
                </CommonButton>
                <CommonButton btnType={ButtonTypeEnum.ADD} onClick={handleOk}>
                    {t('survey.AddQuestionDialog.dialogActions.button.add')}
                </CommonButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddQuestionDialog;
