import type { FC, ReactNode } from 'react';
import {Box, BoxProps, Container, Grid, Theme, Typography, useMediaQuery} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

interface IPageProps extends BoxProps {
    children: ReactNode;
    pageTitle: string;
    pageDescription?: string;
    actionButton?: ReactJSXElement;
}

const Page: FC<IPageProps> = (props) => {
    const { settings } = useSettings();
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    const { pageTitle, pageDescription, children, actionButton, ...other } = props;

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: smUp ? 8 : 3
                }} {...other}>
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid alignItems='center' container justifyContent='space-between' spacing={3} item xs={12} mb={ smUp ? 4 : 2 }>
                        <Grid item>
                            <Typography color='textSecondary' variant='overline'>
                                {pageTitle}
                            </Typography>
                            {smUp && <Typography color='textPrimary' variant='h5'>
                                {pageDescription}
                            </Typography>}
                        </Grid>
                        {actionButton && <Grid item>{actionButton}</Grid>}
                    </Grid>
                    <>{children}</>
                </Container>
            </Box>
        </>
    );
};

export default Page;
