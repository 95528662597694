import type { FC } from 'react';
import { useEffect } from 'react';
import { Link, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getEntityPayables } from '../../slices/payments';
import { PrivateRoutes } from '../../routes';
import { ISignatures } from '../../model/common/payments/ISignatures';
import { PaymentsHelper } from '../../utils/payments-helper';

const SignaturesCounter: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { userPayables } = useAppSelector((state) => state.payments);

    const signatures: ISignatures | undefined = PaymentsHelper.getSignaturesFromPayables(userPayables);

    useEffect(() => {
        if (!userPayables) {
            dispatch(getEntityPayables());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Table>
            <TableBody>
                { signatures?.freeSignatures !== undefined && signatures?.freeSignatures > 0 &&
                    <TableRow>
                        <TableCell sx={ { borderBottom: 'unset', pb: 'unset', pl: 'unset', pt: 'unset' } }>
                            <Link
                                sx={ { fontWeight: 600, textDecoration: 'none' } }
                                color='textSecondary'
                                component={ RouterLink }
                                to={ PrivateRoutes.private_shop_route.path }
                                variant='caption'>
                                { `${ t('accountPopover.freeSignatures') }: ${ signatures?.freeSignatures ?? 0 }` }
                            </Link>
                        </TableCell>
                    </TableRow>
                }
                <TableRow>
                    <TableCell sx={ { borderBottom: 'unset', pb: 'unset', pl: 'unset', pt: 'unset' } }>
                        <Link
                            sx={ { fontWeight: 600, textDecoration: 'none' } }
                            color='textSecondary'
                            component={ RouterLink }
                            to={ PrivateRoutes.private_shop_route.path }
                            variant='caption'>
                            { `${ t('accountPopover.paidSignatures') }: ${ signatures?.paidSignatures ?? 0 }` }
                        </Link>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default SignaturesCounter;
