import type { FC } from 'react';
import { useEffect } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../../shared/CommonButton';
import { VerifiedRounded } from '@mui/icons-material';
import SkeletonBlock from '../../skeletons/SkeletonBlock';
import { getOrganizationDetails } from '../../../slices/organizations';
import { useAppDispatch } from '../../../store/hooks';
import { OrganizationOut } from 'organizations-service-api';
import { ProvidedPayableItem } from 'payment-service-api';

interface IProvidedPayables {
    hasValidFinaCertificate: boolean;
    isLoading: boolean;
    isLoadingCertificateStatus: boolean;
    onIssueCertificate: () => void;
    providedFinaCertificate?: ProvidedPayableItem;
    selectedOrganization?: OrganizationOut;
}

const ProvidedPayables: FC<IProvidedPayables> = (props: IProvidedPayables) => {
    const {
        hasValidFinaCertificate,
        isLoading,
        isLoadingCertificateStatus,
        onIssueCertificate,
        providedFinaCertificate,
        selectedOrganization
    } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (!hasValidFinaCertificate &&
            !selectedOrganization &&
            providedFinaCertificate?.sentFromEntityUuid
        ) {
            dispatch(getOrganizationDetails(providedFinaCertificate?.sentFromEntityUuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            { ( isLoadingCertificateStatus || isLoading )
                ? <SkeletonBlock height={ 80 } />
                : (
                    ( !hasValidFinaCertificate &&
                        providedFinaCertificate?.amount &&
                        providedFinaCertificate?.amount > 0
                    ) ? (
                        <Alert
                            sx={ {
                                '& .MuiAlert-icon': {
                                    color: '#ffffff'
                                },
                                backgroundColor: '#6c63ff',
                                color: '#ffffff'
                            } }
                            severity='info'
                            action={
                                <CommonButton
                                    sx={ {
                                        '&:hover': {
                                            color: 'primary.contrastText'
                                        },
                                        backgroundColor: 'primary.contrastText',
                                        color: 'primary.main',
                                        my: 'auto'
                                    } }
                                    onClick={ onIssueCertificate }
                                    btnType={ ButtonTypeEnum.NO_ICON_CONTAINED }
                                    startIcon={ <VerifiedRounded fontSize='small' /> }>
                                    { t('providedPayables.action').toUpperCase() }
                                </CommonButton>
                            }>
                            <AlertTitle>
                                { t('providedPayables.title') }
                            </AlertTitle>
                            <Trans
                                i18nKey='providedPayables.message'
                                values={ {
                                    organizationName:
                                        selectedOrganization?.name ?
                                            selectedOrganization?.name :
                                            providedFinaCertificate?.sentFromEntityUuid
                                } }
                                components={ { italic: <i />, bold: <strong /> } } />
                        </Alert>
                    ) : (
                        <></>
                    )
                ) }
        </>
    );
};

export default ProvidedPayables;
