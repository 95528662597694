import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, IconButton, MenuItem, Select, SelectChangeEvent, Table, TableBody, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { requestOtp, getEmails } from 'slices/emails';
import { Email } from 'email-verifier-api';
import EmailRow from './EmailRow';
import CommonButton, { ButtonTypeEnum } from 'components/shared/CommonButton';
import SkeletonCard from 'components/skeletons/SkeletonCard';
import { ErrorType, IError } from 'model/common/error';
import { DocumentsHelper } from 'utils/documents-helper';
import { AddCircleOutline } from '@mui/icons-material';
import LoaderElement from 'components/loader/loader-element';
import OtpInsertionModal from 'components/OtpInsertionModal';
import { getPhoneCodes, getPhones, requestPhoneOtp } from 'slices/phones';
import { DialCode, Phone } from 'phone-verifier-api';
import PhoneRow from './PhoneRow';
import Scrollbar from 'components/scrollbar/Scrollbar';

// TODO: Ova komponenta je prevelika i treba ju podijeliti na manje komponente
// TODO: U novu ProfileContactsUtil.tsx izdvojiti manje komponente
const ProfileContacts: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { emails, error, isLoading, isSubmitingEmail } = useAppSelector((state) => state.emails);
    const { phoneCodes, phones, errorPhone, isLoadingPhone, isSubmittingPhone } = useAppSelector((state) => state.phones);
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [dialCode, setDialCode] = useState<string>('');
    const [validationErrorStateEmail, setValidationErrorStateEmail] = useState<IError | undefined>();
    const [validationErrorStatePhone, setValidationErrorStatePhone] = useState<IError | undefined>();

    useEffect(() => {
        if (emails.length === 0) {
            dispatch(getEmails());
        }
        if (phoneCodes.enabledDialCodes.length === 0) {
            dispatch(getPhoneCodes());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (phoneCodes.enabledDialCodes.length > 0 && phones.length === 0) {
            dispatch(getPhones());
            if (!phoneCodes.defaultPhoneCode) {
                setDialCode(phoneCodes.enabledDialCodes[0].code);
                return;
            }
            setDialCode(phoneCodes.defaultPhoneCode.code);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phoneCodes]);

    useEffect(() => {
        if (error?.errorMessage) {
            handleValidationErrorEmail({ error: error.error, errorMessage: error.errorMessage, errorType: error.errorType });
        }
    }, [error]);

    useEffect(() => {
        if (errorPhone?.errorMessage) {
            handleValidationErrorPhone({ error: errorPhone.error, errorMessage: errorPhone.errorMessage, errorType: errorPhone.errorType });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorPhone]);

    const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>): void => {
        if (validationErrorStateEmail?.errorType === ErrorType.VALIDATION_ERROR) {
            handleValidationErrorEmail(undefined);
        }
        setEmail(event.target.value);
    };

    const handleChangePhone = (event: ChangeEvent<HTMLInputElement>): void => {
        if (validationErrorStatePhone?.errorType === ErrorType.VALIDATION_ERROR) {
            handleValidationErrorPhone(undefined);
        }
        setPhone(event.target.value);
    };

    const keyDownHandlerEmail = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            handleSubmitEmail();
        }
    };

    const keyDownHandlerPhone = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            handleSubmitPhone();
        }
    };

    const handleValidationErrorEmail = (error: IError | undefined) => {
        setValidationErrorStateEmail(error);
    };

    const handleValidationErrorPhone = (error: IError | undefined) => {
        setValidationErrorStatePhone(error);
    };

    const handleSubmitEmail = () => {
        if (email === '') {
            handleValidationErrorEmail({ error: true, errorMessage: 'dashboard.profile.contact.emptyMail', errorType: ErrorType.VALIDATION_ERROR });
            return;
        }
        if (!DocumentsHelper.isEmailFormat(email)) {
            handleValidationErrorEmail({ error: true, errorMessage: 'dashboard.profile.contact.invalidMail', errorType: ErrorType.VALIDATION_ERROR });
            return;
        }
        if (emails.find((eml: Email) => eml.email === email)) {
            handleValidationErrorEmail({ error: true, errorMessage: 'dashboard.profile.contact.existingMail', errorType: ErrorType.VALIDATION_ERROR });
            return;
        }
        handleValidationErrorEmail(undefined);
        dispatch(requestOtp(email));
        setEmail('');
    };

    const handleSubmitPhone = () => {
        const phoneNr = phone.startsWith('0') ? DocumentsHelper.stripLeadingZeros(phone) : phone;
        if (phoneNr === '') {
            handleValidationErrorPhone({
                error: true,
                errorMessage: 'dashboard.sidebar.userContacts.newContact.phoneNumber.modal.phoneNumber.placeholder',
                errorType: ErrorType.VALIDATION_ERROR,
            });
            return;
        }
        if (dialCode === '385' && !DocumentsHelper.isCroatianMobileNumberFormat(`+${dialCode}${phoneNr}`)) {
            handleValidationErrorPhone({
                error: true,
                errorMessage: 'dashboard.sidebar.userContacts.newContact.phoneNumber.modal.phoneNumber.validationError',
                errorType: ErrorType.VALIDATION_ERROR,
            });
            return;
        }
        if (dialCode !== '385' && !DocumentsHelper.isValidInternationalPhoneNumber(`+${dialCode}${phoneNr}`)) {
            handleValidationErrorPhone({
                error: true,
                errorMessage: 'dashboard.sidebar.userContacts.newContact.phoneNumber.modal.phoneNumber.validationError',
                errorType: ErrorType.VALIDATION_ERROR,
            });
            return;
        }
        if (phones.find((phn: Phone) => phn.number === phoneNr)) {
            handleValidationErrorPhone({
                error: true,
                errorMessage: 'dashboard.sidebar.userContacts.newContact.phoneNumber.modal.phoneNumber.existingPhoneNumber',
                errorType: ErrorType.VALIDATION_ERROR,
            });
            return;
        }
        handleValidationErrorPhone(undefined);
        dispatch(requestPhoneOtp({ dialCode: dialCode, number: phoneNr }));
        setPhone('');
    };

    const handleRefreshPhones = () => {
        if (errorPhone?.errorType === ErrorType.GET_PHONECODES_ERROR) {
            dispatch(getPhoneCodes());
            return;
        }
        dispatch(getPhones());
    };

    const handleDialCodeChange = (event: SelectChangeEvent): void => {
        setDialCode(event.target.value as string);
    };

    return (
        <Grid container spacing={3}>
            <OtpInsertionModal />
            <Grid item lg={12} md={12} xl={12} xs={12} alignItems='center' justifyContent='center'>
                {error?.errorType === ErrorType.GET_EMAILS_ERROR && !isLoading && (
                    <Card>
                        <CardContent>
                            <Grid container spacing={3} direction='column' justifyContent='center' alignItems='center'>
                                <Grid item lg={6} md={6} xl={6} xs={12} sm={12}>
                                    <Typography sx={{ flex: '1 1 100%' }} variant='h6' id='tableTitle' component='div' textAlign='center'>
                                        {t('dashboard.profile.error')}
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xl={6} xs={12} sm={12}>
                                    <CommonButton variant='contained' btnType={ButtonTypeEnum.NO_ICON} onClick={() => dispatch(getEmails())}>
                                        {t('dashboard.profile.contact.refreshBtn')}
                                    </CommonButton>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}

                {error?.errorType !== ErrorType.GET_EMAILS_ERROR && isLoading && <SkeletonCard />}

                {error?.errorType !== ErrorType.GET_EMAILS_ERROR && !isLoading && (
                    <Box>
                        <Card>
                            <Scrollbar>
                                <CardContent>
                                    <Typography sx={{ flex: '1 1 100%', marginTop: 2 }} variant='h6' id='tableTitle'>
                                        {t('dashboard.profile.contact.verifiedEmails')}
                                    </Typography>
                                    {
                                        emails && emails.length > 0 &&
                                        <Table>
                                            <TableBody>
                                                {emails.map((email: Email) => (
                                                    <EmailRow key={email.uuid} emailData={email} />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    }

                                    {
                                        emails.length < 1 &&
                                        <Typography sx={{ color: 'text.secondary', fontStyle: 'italic', marginTop: 2 }} variant='body1'>
                                            {t('dashboard.profile.contact.noVerifiedEmails')}
                                        </Typography>
                                    }

                                    <Grid container padding={3} direction='row' alignItems='flex-start'>
                                        <Grid item>
                                            <TextField error={Boolean(validationErrorStateEmail?.errorMessage)}
                                                       helperText={
                                                           Boolean(validationErrorStateEmail?.errorMessage) && t(validationErrorStateEmail?.errorMessage ?? '')
                                                       }
                                                       autoFocus
                                                       fullWidth
                                                       label={t('dashboard.sidebar.userContacts.newContact.email.modal.emailAddress')}
                                                       onChange={handleChangeEmail}
                                                       onKeyDown={keyDownHandlerEmail}
                                                       placeholder={t('dashboard.sidebar.userContacts.newContact.email.modal.emailAddress.placeholder')}
                                                       rows={1}
                                                       value={email}
                                                       variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton sx={{ marginTop: 1, marginLeft: 2 }} onClick={handleSubmitEmail} disabled={isSubmitingEmail}>
                                                {!isSubmitingEmail ? <AddCircleOutline color='info' /> : <LoaderElement size={22.5} />}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Scrollbar>
                        </Card>
                    </Box>
                )}

                {(errorPhone?.errorType === ErrorType.GET_PHONES_ERROR || errorPhone?.errorType === ErrorType.GET_PHONECODES_ERROR) && !isLoadingPhone && (
                    <Card sx={{ mt: 3 }}>
                        <CardContent>
                            <Grid container spacing={3} direction='column' justifyContent='center' alignItems='center'>
                                <Grid item lg={6} md={6} xl={6} xs={12} sm={12}>
                                    <Typography sx={{ flex: '1 1 100%' }} variant='h6' id='tableTitle' component='div' textAlign='center'>
                                        {t('dashboard.profile.error')}
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xl={6} xs={12} sm={12}>
                                    <CommonButton variant='contained' btnType={ButtonTypeEnum.NO_ICON} onClick={() => handleRefreshPhones()}>
                                        {t('dashboard.profile.contact.refreshBtn')}
                                    </CommonButton>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}

                {errorPhone?.errorType !== ErrorType.GET_PHONES_ERROR && errorPhone?.errorType !== ErrorType.GET_PHONECODES_ERROR && isLoadingPhone && (
                    <SkeletonCard />
                )}

                {errorPhone?.errorType !== ErrorType.GET_PHONES_ERROR && errorPhone?.errorType !== ErrorType.GET_PHONECODES_ERROR && !isLoadingPhone && (
                    <Box sx={{ mt: 3 }}>
                        <Card>
                            <Scrollbar>
                                <CardContent>
                                    <Typography sx={{ flex: '1 1 100%', marginTop: 2 }} variant='h6' id='tableTitle'>
                                        {t('dashboard.profile.contact.verifiedPhones')}
                                    </Typography>
                                    <Table>
                                        <TableBody>
                                            {phones.map((phone: Phone) => (
                                                <PhoneRow key={phone.uuid} phoneData={phone} />
                                            ))}
                                        </TableBody>
                                    </Table>

                                    <Grid container padding={3} direction='row' alignItems='flex-start' flexWrap='nowrap'>
                                        <Grid item marginRight={1}>
                                            <Select fullWidth
                                                    value={dialCode}
                                                    onChange={handleDialCodeChange}
                                                    autoWidth
                                                    label={t('receiver.input.mobile.dialCode')}>
                                                {phoneCodes?.enabledDialCodes.map((dc: DialCode) => {
                                                    return (
                                                        <MenuItem key={dc.code} value={dc.code}>
                                                            <Box>{`+${dc.code}`}</Box>
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </Grid>
                                        <Grid item>
                                            <TextField error={Boolean(validationErrorStatePhone?.errorMessage)}
                                                       helperText={
                                                           Boolean(validationErrorStatePhone?.errorMessage) && t(validationErrorStatePhone?.errorMessage ?? '')
                                                       }
                                                       autoFocus
                                                       fullWidth
                                                       label={t('dashboard.sidebar.userContacts.newContact.phoneNumber.modal.phoneNumber')}
                                                       onChange={handleChangePhone}
                                                       onKeyDown={keyDownHandlerPhone}
                                                       placeholder={t('dashboard.sidebar.userContacts.newContact.phoneNumber.modal.phoneNumber.placeholder')}
                                                       rows={1}
                                                       value={phone}
                                                       variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton sx={{ marginTop: 1, marginLeft: 2 }} onClick={handleSubmitPhone} disabled={isSubmittingPhone}>
                                                {!isSubmittingPhone ? <AddCircleOutline color='info' /> : <LoaderElement size={22.5} />}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Scrollbar>
                        </Card>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default ProfileContacts;
