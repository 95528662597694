import type { FC } from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import { AlternateEmail, PhoneIphone } from '@mui/icons-material';
import { MemberIn } from 'organizations-service-api';

interface IOrganizationMemberChipProps {
    member: MemberIn;
    memberName: string;
    onRemoveMember: (member: MemberIn) => void;
}

const OrganizationMemberChip: FC<IOrganizationMemberChipProps> = ({ member, memberName, onRemoveMember }) => {
    return (
        <Grid item
              key={ member.email ?
                  member.email :
                  member.phone?.dialCode?.concat(member.phone?.number) }>
            <Chip sx={ {
                '&.MuiChip-root': {
                    height: '40px'
                }
            } }
                  icon={ member.email ? <AlternateEmail /> : <PhoneIphone /> }
                  label={
                      <Typography color='textSecondary' variant='subtitle2'>
                          {
                              member.email ?
                                  member.email + ( memberName ? ' (' + memberName + ')' : '' ) :
                                  '+' + member.phone?.dialCode?.concat(member.phone?.number) + ( memberName ? ' (' + memberName + ')' : '' )
                          }
                      </Typography>
                  }
                  variant='filled'
                  onDelete={ () => onRemoveMember(member) } />
        </Grid>
    );
};

export default OrganizationMemberChip;
