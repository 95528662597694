import { ContactResponse, EmailResponse, PhoneResponse } from 'contacts-service-api';
import { Group, Invitation, MembershipSimple } from 'group-service-api';

export class AddMemberContactsListUtil {
    public static secondaryListItemText = (contact: ContactResponse): string => {
        if (contact.phones && contact.phones?.length > 0) {
            return '+' + contact.phones[0]?.value.dialCode + ' ' + contact.phones[0]?.value.number;
        }

        if (contact.emails && contact.emails?.length > 0) {
            return contact.emails[0]?.email;
        }

        return '-';
    };

    public static invitation = (contact: ContactResponse): Invitation => {
        if (contact.phones && contact.phones?.length > 0) {
            return {
                phone: {
                    dialCode: contact.phones[0].value.dialCode,
                    number: contact.phones[0].value.number,
                },
            };
        }

        if (contact.emails && contact.emails?.length > 0) {
            return { email: contact.emails[0]?.email };
        }

        return { phone: undefined, email: undefined };
    };

    public static filterContacts = (selectedGroup?: Group, contacts?: Array<ContactResponse>) => {
        const memberMails = selectedGroup?.memberships.map((member: MembershipSimple) => member.email);
        const memberPhones = selectedGroup?.memberships.map((member: MembershipSimple) => member.phone);

        return contacts?.filter(
            (contact: ContactResponse) =>
                contact?.emails?.map((mail: EmailResponse) => mail.email)?.find((mail: string) => !memberMails?.includes(mail)) ||
                contact?.phones
                    ?.map((number: PhoneResponse) => number.value.dialCode + number.value.number)
                    ?.find((number: string) => !memberPhones?.includes(number))
        );
    };
}
