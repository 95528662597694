import type { FC } from 'react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Avatar,
    Box,
    Chip,
    Dialog,
    InputAdornment, Slide,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField, Theme,
    Typography, useMediaQuery
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from './shared/CommonButton';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { ContactResponse, EmailResponse, PhoneResponse } from 'contacts-service-api';
import { Email, ExpandMoreOutlined, PhoneIphone, Search } from '@mui/icons-material';
import { getUserContacts } from '../slices/user-contacts';
import Stack from '@mui/material/Stack';
import { PhoneNumber } from 'contacts-service-api';
import { DocumentsHelper } from '../utils/documents-helper';
import { IPhone } from '../model/common/documents/document-user';

interface IContactsChooserModalProps {
    existingEmails: string[];
    existingPhones: IPhone[];
    onAdd: (contacts: IContact[]) => void;
    onClose: () => void;
    open: boolean;
    skipAddingToPanel?: boolean;
}

export interface IContact {
    name: string;
    type: 'email' | 'phone';
    email: string;
    phone: PhoneNumber;
}

const ContactsChooserModal: FC<IContactsChooserModalProps> = (props: IContactsChooserModalProps) => {
    const { existingEmails, existingPhones, onAdd, onClose, open, skipAddingToPanel } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const containerRef = useRef(null);

    const { userContacts } = useAppSelector((state) => state.userContacts);

    const [ selectedContacts, setSelectedContacts ] = useState<IContact[]>([]);
    const [ query, setQuery ] = useState<string>('');
    const [ expandedContactUuid, setExpandedContactUuid ] = useState<string[]>([]);

    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const filteredContacts = userContacts?.filter((contact: ContactResponse) => {
        if (!query) {
            return userContacts;
        }

        return contact.name.toLowerCase().includes(query.toLowerCase()) ||
            contact.emails?.some(email => email.email.toLowerCase().includes(query.toLowerCase())) ||
            contact.phones?.some(phone =>
                ( phone?.value?.dialCode + phone?.value?.number ).trim().toLowerCase().includes(query.toLowerCase()));
    });

    useEffect(() => {
        setSelectedContacts([]);
        if (!userContacts || userContacts.length === 0) {
            dispatch(getUserContacts());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (userContacts && userContacts.length > 0) {
            setExpandedContactUuid([ userContacts[0].uuid ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ userContacts ]);

    const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setQuery(event.target.value);
    };

    const onAddContact = (contact: IContact) => {
        skipAddingToPanel ?
            onAdd([ contact ]) :
            setSelectedContacts((prevState) => [ ...prevState, contact ]);
    };

    const toggleExpandedAccordion = (contactUuid: string) => {
        setExpandedContactUuid(prevState => {
            if (prevState.includes(contactUuid)) {
                return prevState.filter(uuid => uuid !== contactUuid);
            }
            return [ ...prevState, contactUuid ];
        });
    };

    const onRemoveSelectedContact = (contact: IContact) => {
        setSelectedContacts(prevState =>
            prevState.filter(c =>
                c.email !== contact.email ||
                c.phone?.dialCode?.concat(c.phone?.number) !== contact.phone?.dialCode?.concat(contact.phone?.number)
            )
        );
    };

    const handleClose = () => {
        setSelectedContacts([]);
        onClose();
    };

    const handleAddContacts = (contacts: IContact[]) => {
        handleClose();
        onAdd(contacts);
    };

    const isDisabledEmail = (email: EmailResponse) =>
        selectedContacts.some(c => c.email === email.email) || existingEmails.includes(email.email);

    const isDisabledPhone = (phone: PhoneResponse) =>
        selectedContacts.some(c => c?.phone?.dialCode === phone?.value?.dialCode && c?.phone?.number === phone?.value?.number) ||
        existingPhones.some(p => p?.dialCode === phone?.value?.dialCode && p?.number === phone?.value?.number);

    return (
        <Dialog maxWidth='lg' open={ open } onClose={ onClose } fullWidth={ !smUp }>
            <Box sx={ { ml: 7, mr: 7, mt: 3 } }>
                <Typography align='center' color='textPrimary' gutterBottom variant='h5'>
                    { t('modal.contacts.title') }
                </Typography>
                { !skipAddingToPanel &&
                  <Typography align='center' color='textSecondary' variant='body1'>
                      { `${ t('modal.contacts.selectedContacts') } ${ selectedContacts.length }` }
                  </Typography>
                }
            </Box>

            {/* Selected accounts panel */ }
            { !skipAddingToPanel && <Box ref={ containerRef }
                                         sx={ {
                                             alignContent: 'flex-start',
                                             alignItems: 'flex-start',
                                             backgroundColor: 'background.default',
                                             borderRadius: '10px',
                                             display: 'flex',
                                             flexWrap: 'wrap',
                                             gap: 1,
                                             height: smUp ? '185px' : '150px',
                                             justifyContent: selectedContacts.length === 0 ? 'center' : 'flex-start',
                                             m: 1,
                                             overflow: 'auto',
                                             p: 1,
                                             width: smUp ? '500px' : 'unset'
                                         } }>
                { selectedContacts.length === 0 ? (
                    <Typography variant='caption' color='textSecondary'>
                        { t('modal.contacts.noContactsSelected') }
                    </Typography>
                ) : (
                    selectedContacts.map((contact) =>
                        <Slide key={ contact.email || contact.phone.number }
                               direction='up'
                               in
                               container={ containerRef.current }>
                            <Chip sx={ { height: '48px' } }
                                  label={
                                      <Stack>
                                          <Typography variant='body2' color='textSecondary'>
                                              { contact.name }
                                          </Typography>
                                          <Typography variant='body2' fontWeight='bold'>
                                              { contact.email || ( contact.phone.dialCode + contact.phone.number ) }
                                          </Typography>
                                      </Stack>
                                  }
                                  onDelete={ () => onRemoveSelectedContact(contact) } />
                        </Slide>
                    )
                ) }
            </Box> }

            {/* Search bar */ }
            { userContacts && userContacts.length >= 10 &&
              <TextField sx={ { mb: 1, mt: 1, pl: 3, pr: 3 } }
                         fullWidth
                         InputProps={ {
                             startAdornment: (
                                 <InputAdornment position='start'>
                                     <Search fontSize='small' />
                                 </InputAdornment>
                             )
                         } }
                         onChange={ handleQueryChange }
                         placeholder={ t('search') }
                         value={ query }
                         variant='outlined' />
            }

            {/* Contacts list - accordion list */ }
            <Box sx={ { height: '500px', mb: 5, overflow: 'auto', p: 1, width: smUp ? '500px' : 'unset' } }>
                { filteredContacts && filteredContacts?.length > 0 && (
                    filteredContacts.map((contact: ContactResponse) =>
                        <Accordion key={ contact.uuid }
                                   sx={ {
                                       alignItems: 'center',
                                       backgroundColor: 'divider',
                                       marginBottom: '0px !important',
                                       marginTop: '0px !important'
                                   } }
                                   expanded={ expandedContactUuid.includes(contact.uuid) }>
                            <AccordionSummary expandIcon={ <ExpandMoreOutlined /> }
                                              onClick={ () => toggleExpandedAccordion(contact.uuid) }>
                                <Avatar
                                    sx={ { fontSize: '1rem', height: 32, mr: 2, textDecoration: 'none', width: 32 } }>
                                    { DocumentsHelper.getInitials(contact.name).toUpperCase() }
                                </Avatar>
                                <Typography variant='h6'>
                                    { contact.name }
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Table>
                                    <TableBody>
                                        { contact.emails?.map((email: EmailResponse) =>
                                            <TableRow key={ email.uuid }>
                                                <TableCell sx={ { border: 0, p: 0, width: '10%' } }>
                                                    <Email fontSize='small' />
                                                </TableCell>
                                                <TableCell sx={ {
                                                    border: 0,
                                                    pl: 1,
                                                    width: '80%'
                                                } }>{ email.email }</TableCell>
                                                <TableCell sx={ { border: 0, p: 0, textAlign: 'right', width: '10%' } }>
                                                    <CommonButton btnType={ ButtonTypeEnum.ADD }
                                                                  onClick={ () => onAddContact(
                                                                      {
                                                                          name: contact.name,
                                                                          type: 'email',
                                                                          email: email.email
                                                                      } as IContact) }
                                                                  variant='text'
                                                                  disabled={ isDisabledEmail(email) }>
                                                        {
                                                            selectedContacts.some(c => c.email === email.email) || isDisabledEmail(email) ?
                                                                t('modal.contacts.added') :
                                                                t('modal.contacts.actions.add')
                                                        }
                                                    </CommonButton>
                                                </TableCell>
                                            </TableRow>
                                        ) }

                                        { contact.phones?.map((phone: PhoneResponse) =>
                                            <TableRow key={ phone.uuid }>
                                                <TableCell sx={ { border: 0, p: 0 } }>
                                                    <PhoneIphone fontSize='small' />
                                                </TableCell>
                                                <TableCell sx={ { border: 0, pl: 1 } }>
                                                    { phone.value.dialCode + phone.value.number }
                                                </TableCell>
                                                <TableCell sx={ { border: 0, p: 0 } }>
                                                    <CommonButton btnType={ ButtonTypeEnum.ADD }
                                                                  onClick={ () => onAddContact(
                                                                      {
                                                                          name: contact.name,
                                                                          type: 'phone',
                                                                          phone: phone.value
                                                                      } as IContact) }
                                                                  variant='text'
                                                                  disabled={ isDisabledPhone(phone) }>
                                                        {
                                                            selectedContacts.some(c => c.phone?.dialCode === phone.value.dialCode && c.phone?.number === phone.value.number) || isDisabledPhone(phone) ?
                                                                t('modal.contacts.added') :
                                                                t('modal.contacts.actions.add')
                                                        }
                                                    </CommonButton>
                                                </TableCell>
                                            </TableRow>
                                        ) }
                                    </TableBody>
                                </Table>
                            </AccordionDetails>
                        </Accordion>
                    )
                ) }
                { filteredContacts && filteredContacts?.length === 0 && (
                    <Typography variant='caption' color='textSecondary'>
                        { t('modal.contacts.noContactsFound') }
                    </Typography>
                ) }
            </Box>

            <Box sx={ { mb: 5, textAlign: 'center' } }>
                { !skipAddingToPanel &&
                  <CommonButton sx={ { mr: 2, pl: 5, pr: 5 } }
                                btnType={ ButtonTypeEnum.NO_ICON }
                                onClick={ () => handleClose() }>
                      { t('modal.contacts.actions.close') }
                  </CommonButton>
                }

                <CommonButton sx={ { ml: 2, pl: 5, pr: 5 } }
                              btnType={ ButtonTypeEnum.NO_ICON_CONTAINED }
                              onClick={ () => handleAddContacts(selectedContacts) }>
                    { skipAddingToPanel ? t('modal.contacts.actions.close') : t('modal.contacts.actions.ok') }
                </CommonButton>
            </Box>
        </Dialog>
    );
};

export default ContactsChooserModal;
