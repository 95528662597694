import { FC, useEffect } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Phone } from 'phone-verifier-api';
import CommonButton, { ButtonTypeEnum } from 'components/shared/CommonButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearErrorMessage, deletePhone, setDefaultPhone } from 'slices/phones';
import { useTranslation } from 'react-i18next';
import { ErrorType } from 'model/common/error';
import toast from 'react-hot-toast';
import Label from 'components/Label';
import { DeleteOutline } from '@mui/icons-material';

interface IPhoneRowProps {
    phoneData: Phone;
}

const PhoneRow: FC<IPhoneRowProps> = ({ phoneData }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { errorPhone } = useAppSelector((state) => state.phones);

    useEffect(() => {
        if (errorPhone && errorPhone?.errorType === ErrorType.DELETE_PHONE_ERROR) {
            toast.error(t(errorPhone.errorMessage));
            dispatch(clearErrorMessage());
            return;
        }
        if (errorPhone && errorPhone?.errorType === ErrorType.SET_DEFAULT_PHONE_ERROR) {
            toast.error(t(errorPhone.errorMessage));
            dispatch(clearErrorMessage());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorPhone]);

    return (
        <TableRow>
            <TableCell sx={{ width: '10%' }}>
                {!phoneData.defaultPhone && (
                    <DeleteOutline
                        sx={{ cursor: 'pointer', opacity: '0.7' }}
                        fontSize="small"
                        color="error"
                        onClick={() => dispatch(deletePhone(phoneData.uuid))}
                    />
                )}
            </TableCell>
            <TableCell sx={{ width: '50%' }}>+{phoneData.dialCode.code + phoneData.number}</TableCell>
            <TableCell align="center" sx={{ width: '25%' }}>
                {phoneData.defaultPhone && <Label color={'info'}>{t('dashboard.profile.contact.default')}</Label>}
                {!phoneData.defaultPhone && (
                    <CommonButton btnType={ButtonTypeEnum.NO_ICON} onClick={() => dispatch(setDefaultPhone(phoneData.uuid))}>
                        {t('dashboard.profile.contact.setDefault')}
                    </CommonButton>
                )}
            </TableCell>
        </TableRow>
    );
};

export default PhoneRow;
