import type { FC } from 'react';
import { DocumentInfo, Person } from 'user-document-signer-api';
import { Grid, Typography } from '@mui/material';
import { CheckCircle, Remove, RemoveCircle } from '@mui/icons-material';
import { DocumentsHelper } from '../../utils/documents-helper';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';

interface IDocumentRecipientsProps {
    document: DocumentInfo;
    enableBulkActions: boolean;
    showAllRecipientsClickHandler: (personsSigning: Person[]) => void;
}

const DocumentRecipients: FC<IDocumentRecipientsProps> = (props) => {
    const { document, enableBulkActions, showAllRecipientsClickHandler } = props;
    const { t } = useTranslation();

    let documentReceivers: Person[] = [];

    if (document?.sharedWith?.length > 0) {
        documentReceivers.push(...document.sharedWith);
    }

    documentReceivers = documentReceivers
        .sort((a, b) => Number(b.signatureInfo?.signatureRequested) - Number(a.signatureInfo?.signatureRequested));

    return (
        <div>
            <Grid container direction='column'>
                { documentReceivers?.slice(0, 3).map((person: Person, index: number) => {
                    return (
                        <Grid container item direction='row' key={ index } sx={ { flexWrap: 'nowrap' } }>
                            <Typography variant='body2' sx={ { mr: 1 } }>
                                { !person.signatureInfo?.signatureRequested
                                    ? <Remove fontSize='small' sx={ { opacity: 0.5 } } />
                                    : person.signatureInfo?.signed
                                        ? <CheckCircle fontSize='small' color='primary' />
                                        : <RemoveCircle fontSize='small' sx={ { opacity: 0.5 } } /> }
                            </Typography>

                            <Typography color={ 'textSecondary' } variant='body2'>
                                { DocumentsHelper.getDocumentSignerDataUDS(person) }
                            </Typography>
                        </Grid>
                    );
                }) }
                { document.sharedWith?.length > 3 &&
                  <CommonButton
                    btnType={ ButtonTypeEnum.MORE }
                    startIcon={ <></> }
                    variant='text'
                    onClick={ () => showAllRecipientsClickHandler(documentReceivers) }
                    disabled={ enableBulkActions }>
                      { t('dashboard.sidebar.documents.list.table.column.button.allRecipients') }
                  </CommonButton> }
            </Grid>
        </div>
    );
};
export default DocumentRecipients;
