import type { FC } from 'react';
import { MembershipRoleEnum, MembershipSimple, MembershipSimpleStatusEnum } from 'group-service-api';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@mui/material';
import { TableHead } from '@material-ui/core';
import SkeletonList from '../skeletons/SkeletonList';
import { Check, DeleteOutlined, MoreHoriz } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import MembersTableAdminSwitch from './MembersTableAdminSwitch';
import { MembersTableUtil } from './MembersTable.util';
import { useAppSelector } from '../../store/hooks';

interface IMembersTableProps {
    memberships: MembershipSimple[] | undefined;
    isLoading: boolean;
    userRole: MembershipRoleEnum | undefined;
    onRoleChange: (membership: MembershipSimple) => void;
    onMemberRemoveClick: (membership: MembershipSimple) => void;
}

const MembersTable: FC<IMembersTableProps> = (props) => {
    const { memberships, isLoading, userRole, onRoleChange, onMemberRemoveClick } = props;
    const { t } = useTranslation();
    const { activeGroup } = useAppSelector((state) => state.groups);

    return (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table sx={{ minWidth: 650 }} size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('groups.settings.user.fullName')}</TableCell>
                        <TableCell align='center'>{t('groups.details.status')}</TableCell>
                        <TableCell align='center'>{t('groups.details.role')}</TableCell>
                        {activeGroup?.role !== MembershipRoleEnum.Regular && <TableCell align='center'>{t('groups.details.admin')}</TableCell>}
                        {activeGroup?.role !== MembershipRoleEnum.Regular && <TableCell align='center'>{t('groups.details.remove')}</TableCell>}
                    </TableRow>
                </TableHead>
                {isLoading ? (
                    <SkeletonList numberOfColumns={5} withCheckBox={false} />
                ) : (
                    <TableBody>
                        {memberships &&
                            memberships
                                .slice()
                                .sort((a: MembershipSimple, b: MembershipSimple) => a.status.toLocaleLowerCase().localeCompare(b.status.toLocaleLowerCase()))
                                .map((membership: MembershipSimple) => (
                                    <TableRow key={membership.uuid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component='th' scope='row'>
                                            {MembersTableUtil.fullName(membership)}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {membership.status === MembershipSimpleStatusEnum.Confirmed ? (
                                                <Tooltip title={t(MembersTableUtil.showTooltipTitle(MembershipSimpleStatusEnum.Confirmed)) ?? ''}>
                                                    <Check />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={t(MembersTableUtil.showTooltipTitle(MembershipSimpleStatusEnum.Pending)) ?? ''}>
                                                    <MoreHoriz />
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                        <TableCell align='center'>{t(MembersTableUtil.roleText(membership.role))}</TableCell>
                                        {activeGroup?.role !== MembershipRoleEnum.Regular && (
                                            <TableCell align='center'>
                                                {MembersTableUtil.showSwitch(userRole, membership.role) && (
                                                    <MembersTableAdminSwitch membership={membership} userRole={userRole} onChange={onRoleChange} />
                                                )}
                                            </TableCell>
                                        )}
                                        {activeGroup?.role !== MembershipRoleEnum.Regular && (
                                            <TableCell align='center'>
                                                {MembersTableUtil.showDelete(activeGroup, membership) && (
                                                    <IconButton onClick={() => onMemberRemoveClick(membership)}>
                                                        <DeleteOutlined />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

export default MembersTable;
