import type { FC } from 'react';
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import OrganizationSelect from '../OrganizationSelect';
import { OrganizationOut } from 'organizations-service-api';

interface IAddGroupDialogProps {
    open: boolean;
    onCloseConfirmed: (groupName: string, organizationUuid: string) => void;
    onCloseCanceled: () => void;
    organization?: OrganizationOut;
}

const AddGroupDialog: FC<IAddGroupDialogProps> = (props) => {
    const { open, onCloseConfirmed, onCloseCanceled, organization } = props;
    const { t } = useTranslation();

    const [groupName, setGroupName] = useState('');
    const [organizationUuid, setOrganizationUuid] = useState<string>(organization?.uuid ?? '');

    const handleGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGroupName(event.target.value);
    };

    const handleCloseConfirmed = (groupName: string) => {
        setGroupName('');
        onCloseConfirmed(groupName, organizationUuid);
        setOrganizationUuid('');
    };

    const isGroupNameValid = (text: string): boolean => {
        return !text || text.trim().length < 1;
    };

    const handleOrganizationSelect = (organizationUuid: string) => {
        setOrganizationUuid(organizationUuid);
    };

    return (
        <Dialog onClose={onCloseCanceled} open={open}>
            <DialogTitle sx={{ m: 2, textAlign: 'center' }}>{t('groups.summary.addGroup.name')}</DialogTitle>
            <DialogContent>

                <OrganizationSelect
                    organization={organization}
                    onOrganizationSelect={handleOrganizationSelect} />

                <TextField
                    fullWidth
                    autoFocus
                    sx={{ mb: 3, mt: 1 }}
                    id='outlined-error-helper-text'
                    label={t('groups.summary.addGroup.label')}
                    onChange={handleGroupNameChange}
                    placeholder={t('groups.summary.addGroup.placeholder')}
                />
            </DialogContent>

            <DialogActions sx={{ m: 2, mx: 'auto' }}>
                <CommonButton btnType={ButtonTypeEnum.CANCEL} onClick={onCloseCanceled}>
                    {t('groups.settings.removeUser.cancelButton')}
                </CommonButton>
                <CommonButton btnType={ButtonTypeEnum.CONFIRM} disabled={isGroupNameValid(groupName)} onClick={() => handleCloseConfirmed(groupName)}>
                    {t('groups.summary.addGroup.confirmButton')}
                </CommonButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddGroupDialog;
