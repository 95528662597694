import {
    AmountCurrencyCurrencyEnum,
    ApiWsPayResultRequest,
    Bundle,
    BundleOfferGroupEnum,
    ListResponsePayableItem,
    PayableGroup,
    PayableItem,
    PayableItemPayableEnum
} from 'payment-service-api';
import { IFeature } from '../components/profile/plans/PricingPlan';
import { ApiGetDocumentsResponse } from 'document-scanner-api';
import { IProduct } from '../pages/payments/CheckoutPage';
import numeral from 'numeral';
import { ISignatures } from '../model/common/payments/ISignatures';

export enum PlanType {
    ADVANCED_PLAN = 'advanced_plan',
    DEDICATED_PLAN = 'dedicated_plan',
    BASIC_PLAN = 'basic_plan',
}

export enum SignatureType {
    SIGNATURE_3 = 'signatures_3',
    SIGNATURE_10 = 'signatures_10',
    SIGNATURE_50 = 'signatures_50',
}

export enum WsPayResultType {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    CANCELED = 'CANCELED',
    TIMEOUT = 'TIMEOUT',
}

const planFeatures: { [key: string]: string } = {
    'AGE_VERIFICATION': 'dashboard.profile.plans.feature.AGE_VERIFICATION',
    'COINS': 'dashboard.profile.plans.feature.COINS',
    'FINA_LCP_CERTIFICATE': 'dashboard.profile.plans.feature.FINA_LCP_CERTIFICATE',
    'FREE_SIGNATURE': 'dashboard.profile.plans.feature.FREE_SIGNATURES',
    'IDENTYUM_CERTIFICATE': 'dashboard.profile.plans.feature.IDENTYUM_CERTIFICATE',
    'PAID_SIGNATURE': 'dashboard.profile.plans.feature.PAID_SIGNATURES',
    'REPORT': 'dashboard.profile.plans.feature.REPORT',
    'SMS': 'dashboard.profile.plans.feature.SMS'
};

const signatureBundleFeatures: { [key: string]: string } = {
    'PAID_SIGNATURE': 'shop.signatures.features.paidSignatures'
};

const paymentErrorMessage: { [key: string]: string } = {
    'CANCELED': 'WSPay.message.payment.CANCELED',
    'ERROR': 'WSPay.message.payment.ERROR',
    'SUCCESS': 'WSPay.message.payment.SUCCESS',
    'TIMEOUT': 'WSPay.message.payment.TIMEOUT'
};

const currencySymbols: { [key: string]: string } = {
    'HRK': 'kn',
    'EUR': '€',
    'USD': '$'
};

export class PaymentsHelper {
    public static EUR_HRK_RATE: number = 7.5345;

    public static getPlanDescription(placeholderName: string): string {
        return placeholderName.toLowerCase() === PlanType.BASIC_PLAN
            ? 'dashboard.profile.plans.basic.description'
            : placeholderName === PlanType.ADVANCED_PLAN
                ? 'dashboard.profile.plans.advanced.description'
                : 'dashboard.profile.plans.dedicated.description';
    }

    public static getPlanName(placeholderName: string): string {
        return placeholderName.toLowerCase() === PlanType.BASIC_PLAN
            ? 'dashboard.profile.plans.basic'
            : placeholderName === PlanType.ADVANCED_PLAN
                ? 'dashboard.profile.plans.advanced'
                : 'dashboard.profile.plans.dedicated';
    }

    public static getPlanType(placeholderName: string): string {
        return placeholderName.toLowerCase() === PlanType.BASIC_PLAN
            ? PlanType.BASIC_PLAN
            : placeholderName === PlanType.ADVANCED_PLAN
                ? PlanType.ADVANCED_PLAN
                : PlanType.DEDICATED_PLAN;
    }

    public static getPlanFeatures(payableGroups: Set<PayableGroup>): IFeature[] {
        const features: IFeature[] = [
            {
                name: planFeatures['IDENTYUM_CERTIFICATE'],
                quantity: 1
            } as IFeature
        ];

        Array.from(payableGroups).map((pg) => {
            const feature = planFeatures[pg.payable] || '';
            if (feature) {
                features.push({ name: feature, quantity: pg.amount } as IFeature);
            }
        });
        return features.sort((a, b) => a.name.localeCompare(b.name));
    }

    public static getSignatureDescription(placeholderName: string): string {
        return placeholderName.toLowerCase() === SignatureType.SIGNATURE_3
            ? 'shop.signatures.bundle.3.description'
            : placeholderName === SignatureType.SIGNATURE_10
                ? 'shop.signatures.bundle.10.description'
                : 'shop.signatures.bundle.50.description';
    }

    public static getSignatureBundleName(placeholderName: string): string {
        return placeholderName.toLowerCase() === SignatureType.SIGNATURE_3
            ? 'shop.signatures.bundle.3.name'
            : placeholderName === SignatureType.SIGNATURE_10
                ? 'shop.signatures.bundle.10.name'
                : 'shop.signatures.bundle.50.name';
    }

    public static getSignatureFeatures(payableGroups: Set<PayableGroup>): IFeature[] {
        const features: IFeature[] = [];

        Array.from(payableGroups).map((pg) => {
            const feature = signatureBundleFeatures[pg.payable] || '';
            if (feature) {
                features.push({ name: feature, quantity: pg.amount } as IFeature);
            }
        });
        return features.sort((a, b) => 0 - ( +a.quantity > +b.quantity ? 1 : -1 ));
    }

    public static getCurrencySymbol(currency: AmountCurrencyCurrencyEnum | undefined): string {
        if (!currency) {
            return '';
        }
        return currencySymbols[currency] || '';
    }

    public static filterBundlesForPlans(allBundles: Bundle[]): Bundle[] | undefined {
        // izbaci iz liste sve bundleove koji nisu tipa 'plan'
        return allBundles
            ?.filter((bundle: Bundle) => bundle?.offerGroup === BundleOfferGroupEnum.Plans) // daj samo planove
            ?.filter((plan) => plan?.placeholderName?.toLowerCase() !== PlanType.DEDICATED_PLAN.toLowerCase()) // izbaci dedicated plan
            ?.sort((a, b) => a.ordinal - b.ordinal); // sortiraj po ordinalu

    }

    public static filterBundlesForSignatures(allBundles: Bundle[]): Bundle[] | undefined {
        // izbaci iz liste sve bundleove koji nisu tipa 'signatures' + sort by ordinal
        return allBundles
            ?.filter((bundle: Bundle) => bundle?.offerGroup === BundleOfferGroupEnum.Signatures)
            ?.sort((a, b) => a.ordinal - b.ordinal);
    }

    public static getTotalCheckoutAmount(product?: IProduct): number {
        const shippingTax = 0;
        return product
            ? ( product.price ?? 0 ) * ( product?.quantity ?? 0 ) + shippingTax
            : 0;
    }

    public static paramsToApiWsPayResultRequest(searchParams: URLSearchParams): ApiWsPayResultRequest {
        const apiWsPayResultRequest: ApiWsPayResultRequest = {};

        searchParams.forEach((value, key) => {
            apiWsPayResultRequest[key] = value;
        });

        return apiWsPayResultRequest;
    }

    public static getResultMessageFromWsPayResult(apiWsPayResultRequest: ApiWsPayResultRequest): string {
        const responseCode = apiWsPayResultRequest.ResponseCode;

        if (responseCode != null && responseCode == '15') {
            return paymentErrorMessage[WsPayResultType.CANCELED] || '';
        }

        if (responseCode != null && responseCode == '16') {
            return paymentErrorMessage[WsPayResultType.TIMEOUT] || '';
        }

        return paymentErrorMessage[WsPayResultType.ERROR] || '';
    }

    public static getHrkPrice(price: number): string {
        const convertedAmount = numeral(price * PaymentsHelper.EUR_HRK_RATE).format('0.00');
        return `(~${ convertedAmount } HRK)*`;
    }

    public static hasFinaCertPayable(userPayables: ListResponsePayableItem | undefined): boolean {
        return !userPayables || !userPayables?.hits || userPayables?.hits.length <= 0 ?
            false :
            Array
                .from(userPayables?.hits)
                ?.filter(
                    (pi: PayableItem) =>
                        pi.payable === PayableItemPayableEnum.FinaLcpCertificate
                        && pi.amount > 0
                )?.length > 0;
    }

    public static getSignaturesForType(typeOfSignatures: PayableItemPayableEnum, userPayables?: ListResponsePayableItem): number {
        if (!userPayables?.hits || userPayables?.hits?.length <= 0) {
            return 0;
        }

        const signaturePayable = Array
            .from(userPayables.hits)
            .filter((pi: PayableItem) => pi.payable === typeOfSignatures);

        return signaturePayable
            .map(item => item.amount)
            .reduce(( (prev, curr) => prev + curr ), 0);
    }

    public static checkDocumentNationalityCode(nationalityCode: string, data?: ApiGetDocumentsResponse): boolean {
        return data?.documents?.some((doc) => {
            return doc.data?.nationalityCode?.visual === nationalityCode
                || doc.data?.nationalityCode?.mrz === nationalityCode
                || doc.data?.nationalityCode?.barcode === nationalityCode
                || doc.data?.nationalityCode?.edited === nationalityCode;
        }) ?? false;

    }

    public static getSignaturesFromPayables(userPayables: ListResponsePayableItem | undefined): ISignatures | undefined {
        return !userPayables
            ? undefined
            : {
                freeSignatures: PaymentsHelper.getSignaturesForType(PayableItemPayableEnum.FreeSignature, userPayables),
                paidSignatures: PaymentsHelper.getSignaturesForType(PayableItemPayableEnum.PaidSignature, userPayables)
            } as ISignatures;
    }
}
