import type { FC } from 'react';
import { Box, Dialog, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from './shared/CommonButton';

export enum ConfirmActionModalActionType {
    UNSAVED_DATA,
    DELETE_CONTACT,
    DELETE_CONTACT_DATA_EMAIL,
    DELETE_CONTACT_DATA_PHONE_NUMBER
}

interface ConfirmActionModalProps {
    actionType?: ConfirmActionModalActionType;
    onConfirm: () => void;
    onClose: () => void;
    open: boolean;
    title?: string;
    subTitle?: string;
}

const ConfirmActionModal: FC<ConfirmActionModalProps> = (props) => {
    const { onConfirm, onClose, open, title, subTitle } = props;
    const { t } = useTranslation();

    const resolvedTitle =
        props.actionType === ConfirmActionModalActionType.UNSAVED_DATA
            ? t('dashboard.sidebar.documents.confirmModal.unsavedData.title')
            : props.actionType === ConfirmActionModalActionType.DELETE_CONTACT
            ? t('dashboard.sidebar.documents.confirmModal.removeContact.title')
            : props.actionType === ConfirmActionModalActionType.DELETE_CONTACT_DATA_EMAIL
            ? t('dashboard.sidebar.documents.confirmModal.removeContactEmail.title')
            : props.actionType === ConfirmActionModalActionType.DELETE_CONTACT_DATA_PHONE_NUMBER
            ? t('dashboard.sidebar.documents.confirmModal.removeContactPhoneNumber.title')
            : t('');
    const resolvedSubtitle = props.actionType === ConfirmActionModalActionType.UNSAVED_DATA ? t('dashboard.sidebar.documents.confirmModal.subTitle') : '';

    const primaryText = title || resolvedTitle;
    const secondaryText = subTitle || resolvedSubtitle;

    return (
        <Dialog maxWidth='lg' onClose={onClose} open={open}>
            <Box sx={{ ml: 7, mr: 7, mt: 3, p: 3 }}>
                <Typography align='center' color='textPrimary' gutterBottom variant='h5'>
                    {primaryText}
                </Typography>
                <Typography align='center' color='textSecondary' variant='body1'>
                    {secondaryText}
                </Typography>
                <Grid container direction='row' alignItems='center' justifyContent='center' sx={{ mt: 5 }}>
                    <Grid item sx={{ mr: 1 }}>
                        <Box>
                            <CommonButton btnType={ButtonTypeEnum.CANCEL} onClick={() => onClose()}>
                                {t('dashboard.sidebar.documents.confirmModal.buttonCancel')}
                            </CommonButton>
                        </Box>
                    </Grid>
                    <Grid item sx={{ ml: 1 }}>
                        <Box>
                            <CommonButton btnType={ButtonTypeEnum.CONFIRM} onClick={() => onConfirm()}>
                                {t('dashboard.sidebar.documents.confirmModal.buttonConfirm')}
                            </CommonButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default ConfirmActionModal;
