import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Dialog, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ErrorType, IError } from '../../model/common/error';
import { DocumentsHelper } from '../../utils/documents-helper';
import { PhoneResponse } from 'contacts-service-api';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { UserContactsHelper } from '../../utils/user-contacts-helper';
import CountryCodeChooser from '../CountryCodeChooser';

interface ContactPhoneModalProps {
    phoneNumbers: PhoneResponse[] | undefined;
    editedPhoneNumber?: PhoneResponse;
    onConfirm: (phoneNumber: PhoneResponse) => void;
    onClose: () => void;
    open: boolean;
}

const ContactPhoneModal: FC<ContactPhoneModalProps> = (props) => {
    const { onConfirm, onClose, open, phoneNumbers, editedPhoneNumber, ...other } = props;
    const [ phoneNumber, setPhoneNumber ] = useState<string>('');
    const [ dialCode, setDialCode ] = useState<string>('');
    const [ validationError, setValidationError ] = useState<IError>({
        error: false,
        errorMessage: '',
        errorType: ErrorType.VALIDATION_ERROR
    });
    const [ isEditingPhone, setIsEditingPhone ] = useState<boolean>(false);
    const { t } = useTranslation();

    const modalTitle = isEditingPhone
        ? t('dashboard.sidebar.userContacts.newContact.updatePhoneNumber.modal.title')
        : t('dashboard.sidebar.userContacts.newContact.newPhoneNumber.modal.title');

    const buttonTitle = isEditingPhone
        ? t('dashboard.sidebar.userContacts.newContact.phoneNumber.modal.updatePhoneNumber')
        : t('dashboard.sidebar.userContacts.newContact.phoneNumber.modal.addPhoneNumber');


    useEffect(() => {
        setIsEditingPhone(!!editedPhoneNumber?.value?.number);
        setPhoneNumber(editedPhoneNumber?.value?.number ?? '');
    }, [ editedPhoneNumber ]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setValidationError({ error: false, errorMessage: '', errorType: ErrorType.VALIDATION_ERROR });
        const onlyNumbers = event.target.value.replace(/[^0-9]/g, '');
        setPhoneNumber(onlyNumbers);
    };

    const isPhoneNumberAlreadyAdded = (): boolean => {
        if (editedPhoneNumber?.value?.number) {
            const filteredPhoneNumbers = phoneNumbers?.filter(phone => phone?.value.number !== editedPhoneNumber?.value.number);
            const existingPhoneNumber = filteredPhoneNumbers?.some(p => p.value.number === phoneNumber);
            return existingPhoneNumber ?? false;
        }
        const isExistingPhoneNUmber = phoneNumbers?.some(p => p.value.number === phoneNumber);
        return isExistingPhoneNUmber ?? false;
    };

    const handleConfirm = (): void => {
        const phoneNr = phoneNumber.startsWith('0') ? DocumentsHelper.stripLeadingZeros(phoneNumber) : phoneNumber;
        const isValidPhoneNumber = DocumentsHelper.isValidInternationalPhoneNumber(`+${ dialCode }${ phoneNr }`);
        const isAlreadyAdded = isPhoneNumberAlreadyAdded();

        if (!isValidPhoneNumber) {
            setValidationError({
                error: true,
                errorMessage: t('dashboard.sidebar.userContacts.newContact.phoneNumber.modal.phoneNumber.validationError') as string,
                errorType: ErrorType.VALIDATION_ERROR
            });
        }

        if (isAlreadyAdded) {
            setValidationError({
                error: true,
                errorMessage: t('dashboard.sidebar.userContacts.newContact.phoneNumber.modal.phoneNumber.existingPhoneNumber') as string,
                errorType: ErrorType.VALIDATION_ERROR
            });
        }

        if (isValidPhoneNumber && !isAlreadyAdded) {
            onConfirm({ value: { dialCode, number: phoneNr }, isDefault: false } as PhoneResponse);
            setPhoneNumber('');
            setValidationError({ error: false, errorMessage: '', errorType: ErrorType.VALIDATION_ERROR });
        }
    };

    const isButtonDisabled = () => {
        return Boolean(validationError.errorMessage) || phoneNumber.trim().length === 0;
    };

    const onCloseHandler = () => {
        setValidationError({ error: false, errorMessage: '', errorType: ErrorType.VALIDATION_ERROR });
        onClose();
    };

    const handleDialCodeSelect = (dialCode: string) => {
        setDialCode(dialCode);
    };

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            handleConfirm();
        }
    };

    return (
        <Dialog maxWidth="lg" onClose={ onCloseHandler } open={ open } { ...other }>
            <Box sx={ { ml: 3, mr: 3, p: 3 } }>
                <Grid container direction="column">
                    <Box sx={ { pl: 7, pr: 7, mb: 3 } }>
                        <Typography align="center" color="textPrimary" gutterBottom variant="h5">
                            { modalTitle }
                        </Typography>
                    </Box>
                    <Grid item container direction="row" spacing={ 2 }>
                        <Grid item xs={ 12 } sm={ 4 } md={ 4 } lg={ 4 }>
                            <CountryCodeChooser onSelect={ handleDialCodeSelect }
                                                fallbackDialCode={ UserContactsHelper.FALLBACK_DIAL_CODE }
                                                flagsEnabled={ true }
                                                validationErrorMessage={ validationError.errorMessage }
                                                selectedDialCode={ editedPhoneNumber?.value?.dialCode } />
                        </Grid>
                        <Grid item xs={ 12 } sm={ 8 } md={ 8 } lg={ 8 } sx={ { width: '100%' } }>
                            <TextField error={ Boolean(validationError.errorMessage) }
                                       helperText={ Boolean(validationError.errorMessage) && validationError.errorMessage }
                                       autoFocus
                                       fullWidth
                                       label={ t('dashboard.sidebar.userContacts.newContact.phoneNumber.modal.phoneNumber') }
                                       onChange={ handleChange }
                                       onKeyDown={ keyDownHandler }
                                       placeholder={ t('dashboard.sidebar.userContacts.newContact.phoneNumber.modal.phoneNumber.placeholder') }
                                       rows={ 1 }
                                       value={ phoneNumber }
                                       variant="outlined"
                            />
                        </Grid>
                    </Grid>

                    <Box sx={ { mt: 3, p: 3 } }>
                        <CommonButton btnType={ ButtonTypeEnum.CONFIRM }
                                      fullWidth
                                      onClick={ handleConfirm }
                                      disabled={ isButtonDisabled() }>
                            { buttonTitle }
                        </CommonButton>
                    </Box>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default ContactPhoneModal;
