import type { FC } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { createGroup, getGroup, getOrganizationGroups } from '../../slices/groups';
import {
    CreateGroupRequest,
    GroupSimple,
    Membership,
    MembershipRoleEnum,
    MembershipSimpleStatusEnum,
    MembershipStatusEnum
} from 'group-service-api';
import { Box, Grid, Typography } from '@mui/material';
import GroupCard from '../groups/GroupCard';
import EmptyState from '../empty-states/EmptyState';
import { Groups } from '@mui/icons-material';
import { resetDocuments } from '../../slices/documents';
import { GroupRoutes } from '../../routes';
import { useNavigate } from 'react-router-dom';
import GroupSwitchOverlay from '../groups/GroupSwitchOverlay';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import AddGroupDialog from '../groups/AddGroupDialog';
import NewGroupCard from '../groups/NewGroupCard';
import OrganizationGroupMembers from './OrganizationGroupMembers';
import { MemberOutRoleEnum, OrganizationOutStatusEnum } from 'organizations-service-api';
import SkeletonBlock from '../skeletons/SkeletonBlock';
import auth from '../../auth/auth';

const OrganizationGroups: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [ showSwitchOverlay, setShowSwitchOverlay ] = useState<boolean>(false);
    const [ selectedGroupName, setSelectedGroupName ] = useState<string | undefined>(undefined);
    const [ isAddMemberOverlayOpen, setIsAddMemberOverlayOpen ] = useState<boolean>(false);
    const [ addNewGroupDialogOpened, setAddNewGroupDialogOpened ] = useState<boolean>(false);
    const [ groupName, setGroupName ] = useState<string>('');

    const { selectedOrganization } = useAppSelector(state => state.organizations);
    const {
        creatingGroupOrganizationUuid,
        organizationGroups,
        isLoadingAction,
        selectedGroup,
        isLoadingMemberships
    } = useAppSelector((state) => state.groups);

    const userUuid = auth.getUserUuid();
    const isOrganizationAdmin = selectedOrganization?.currentUser?.role === MemberOutRoleEnum.Admin || selectedOrganization?.currentUser?.role === MemberOutRoleEnum.Owner;

    const isCreatingGroupInProgress = creatingGroupOrganizationUuid === selectedOrganization?.uuid;

    useEffect(() => {
        if (!organizationGroups && selectedOrganization?.uuid) {
            dispatch(getOrganizationGroups(selectedOrganization?.uuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectGroupClick = (groupUuid: string, groupName: string) => {
        dispatch(resetDocuments());
        setShowSwitchOverlay(true);
        setSelectedGroupName(groupName);
        setTimeout(() => {
            setShowSwitchOverlay(false);
            navigate(GroupRoutes.groups_sent_documents_route.path.replace(':groupUuid', groupUuid));
        }, 3000);
    };

    const onAddMember = (group: GroupSimple) => {
        // fetch group details only if you are the group member, otherwise you will get 404
        group?.currentMembership?.userUuid === userUuid && dispatch(getGroup(group.uuid));
        setGroupName(group.name);
        setIsAddMemberOverlayOpen(true);
    };

    const getAdditionalButton = (group: GroupSimple): JSX.Element => {
        return ( isOrganizationAdmin ) ? (
            <CommonButton
                btnType={ ButtonTypeEnum.ADD_PERSON }
                variant="text"
                onClick={ () => onAddMember(group) }
                startIcon={ <Groups fontSize="small" /> }>
                { t('groups.summary.addMember.label') }
            </CommonButton>
        ) : (
            <></>
        );
    };

    const handleAddButtonClick = () => {
        setAddNewGroupDialogOpened(true);
    };

    const handleAddNewGroupDialogConfirmed = (groupName: string, organizationUuid: string) => {
        setAddNewGroupDialogOpened(false);
        if (groupName) {
            dispatch(createGroup({ name: groupName, organizationUuid: organizationUuid } as CreateGroupRequest));
        }
    };

    const handleCloseAddNewGroupDialog = () => {
        setAddNewGroupDialogOpened(false);
    };

    const convertMembership = (group: GroupSimple): Membership => {
        const membershipSimple = group.currentMembership;
        return {
            email: membershipSimple?.email ?? '',
            fullName: membershipSimple?.fullName ?? '',
            group: group,
            phone: membershipSimple?.phone,
            role: membershipSimple ?
                MembershipRoleEnum[membershipSimple.role] :
                isOrganizationAdmin ?
                    MembershipRoleEnum.Admin :
                    undefined,
            status: membershipSimple ? MembershipStatusEnum[membershipSimple.status] : undefined,
            userUuid: membershipSimple?.userUuid,
            uuid: membershipSimple?.uuid
        } as Membership;
    };

    const renderMemberships = (): JSX.Element => {
        return organizationGroups && organizationGroups.groups && organizationGroups.groups.length > 0 ? (
            <Grid container direction="row" spacing={ 2 }>
                { organizationGroups?.groups?.map((group: GroupSimple, index: number) => (
                    <Fragment key={ group.uuid }>
                        <Grid item md={ 4 } xs={ 12 }>
                            <GroupCard isSwitchToGroupDisabled={
                                            group?.currentMembership?.userUuid !== userUuid ||
                                            group?.currentMembership?.status !== MembershipSimpleStatusEnum.Confirmed
                                        }
                                       membership={ convertMembership(group) }
                                       onGroupSelect={ (groupUuid: string) => handleSelectGroupClick(groupUuid, group.name) }
                                       additionalButton={ getAdditionalButton(group) }
                                       onAdditionalButtonMobileClick={ onAddMember }
                            />
                        </Grid>
                        { organizationGroups?.groups?.length &&
                            index === organizationGroups?.groups?.length - 1 &&
                            isOrganizationAdmin &&
                            (
                                <Grid item md={ 4 } xs={ 12 }>
                                    <NewGroupCard isLoading={ isCreatingGroupInProgress }
                                                  onNewGroupClick={ handleAddButtonClick } />
                                </Grid>
                            ) }
                    </Fragment>
                )) }
            </Grid>
        ) : isOrganizationAdmin ? (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <EmptyState icon={ <Groups /> } message={ t('groups.summary.empty') } />
                <CommonButton btnType={ ButtonTypeEnum.ADD } onClick={ handleAddButtonClick }
                              loading={ isCreatingGroupInProgress }>
                    { isCreatingGroupInProgress ? t('groups.pending.summary.create.loading') : t('groups.pending.summary.create') }
                </CommonButton>
            </Box>
        ) : (
            <Box sx={ { p: 3 } }>
                <Typography color="textSecondary" variant="caption" sx={ { mb: 3 } }>
                    { t('businessProfile.details.label.group.notAdmin') }
                </Typography>
            </Box>
        );
    };

    const getOrganizationGroupsView = () => {
        return (
            selectedOrganization?.status === OrganizationOutStatusEnum.Approved ? (
                <>
                    { !isAddMemberOverlayOpen ? (
                        renderMemberships()
                    ) : (
                        <OrganizationGroupMembers group={ selectedGroup }
                                                  groupName={ groupName }
                                                  isLoading={ isLoadingAction }
                                                  onBackToGroups={ () => setIsAddMemberOverlayOpen(false) } />
                    ) }

                    <GroupSwitchOverlay groupName={ selectedGroupName }
                                        open={ showSwitchOverlay } />

                    <AddGroupDialog open={ addNewGroupDialogOpened }
                                    onCloseConfirmed={ handleAddNewGroupDialogConfirmed }
                                    onCloseCanceled={ handleCloseAddNewGroupDialog }
                                    organization={ selectedOrganization } />
                </>
            ) : (
                <Box sx={ { p: 3 } }>
                    <Typography sx={ { mb: 3 } }
                                color="textSecondary"
                                variant="caption">
                        {
                            !isOrganizationAdmin ?
                                t('businessProfile.details.label.group.notAdmin') :
                                t('businessProfile.details.label.group.notVerifiedOrganization')
                        }
                    </Typography>
                </Box>
            )
        );
    };

    return isLoadingMemberships ? <SkeletonBlock height={ 250 } /> : getOrganizationGroupsView();

};

export default OrganizationGroups;
