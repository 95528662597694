import type { FC } from 'react';
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import ShopLegalDocs from '../profile/plans/ShopLegalDocs';

const TermsSaleStatement: FC = () => {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8,
                }}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid alignItems="center" container justifyContent="space-between" spacing={3} item xs={12}>
                            <Grid item>
                                <Typography color="textPrimary" variant="h5">
                                    Uvjeti prodaje
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>
                                        UVJETI PRODAJE
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Ovi uvjeti prodaje definiraju postupak naručivanja, plaćanja, isporuke i  reklamacije usluge ponuđene na našim internet stranicama. Isporučitelj (Prodavatelj) proizvoda ponuđenih u web dućanu Identyum SIGN (u daljnjem tekstu «usluge») je Identity consortium d.o.o. a Kupac robe je posjetitelj internet kataloga koji odabere barem jednu uslugu, popuni elektronski formular narudžbe i pošalje ga Prodavatelju.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>
                                        NARUČIVANJE
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Roba se naručuje elektronskim putem. Kupac mora ispuniti Profil s osnovnim podacima za naplatu i dostavu željenih proizvoda te će elektronskom poštom biti obaviješten o izvršenoj narudžbi.
                                        Ako želite kupiti uslugu, samo kliknete na košaricu i on će se odmah naći u njoj. U košarici možete odustati od kupnje kao i završiti narudžbu. Obavijest o uspješno izvršenoj kupnji stiže vam na e-mail adresu.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>
                                        PLAĆANJE
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Plaćanje je moguće izvršiti na sljedeće načine:
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        1. Plaćanje kreditnim karticama
                                        American Express®, MasterCard® i Maestro®, Visa, Discover i Diners jednokratno, Paycek, KeksPay i aircash.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Pri plaćanju na našoj web trgovini koristite WSPay – napredni sustav za siguran prihvat platnih kartica putem interneta.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <ShopLegalDocs />
        </>
    );
};

export default TermsSaleStatement;
