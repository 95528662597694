import { CircularProgress } from '@material-ui/core';
import { Box } from '@mui/material';
import { FC } from 'react';

interface LoaderProps {
    size?: number;
    m?: number;
}

export const LoaderElement: FC<LoaderProps> = (props) => {
    return (
        <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', m: props.m }}>
            <CircularProgress size={props.size} />
        </Box>
    );
};

export default LoaderElement;