import { ContactRequest, ContactResponse, EmailResponse, PhoneResponse } from 'contacts-service-api';
import { DialCode, DialCodeCountryCodeEnum } from 'phone-verifier-api';
import deepCopy from './deepCopy';

export interface CountryDialCode {
    country: string;
    value: string;
}

export class UserContactsHelper {
    public static FALLBACK_DIAL_CODE = '385';

    public static generateDialCodes = () => {
        const dialCodes: DialCode[] = [];
        dialCodes.push({ countryCode: DialCodeCountryCodeEnum.Hr, code: '385' } as DialCode);
        dialCodes.push({ countryCode: DialCodeCountryCodeEnum.Si, code: '386' } as DialCode);
        dialCodes.push({ countryCode: DialCodeCountryCodeEnum.Sk, code: '421' } as DialCode);
        dialCodes.push({ countryCode: DialCodeCountryCodeEnum.De, code: '49' } as DialCode);
        dialCodes.push({ countryCode: DialCodeCountryCodeEnum.Es, code: '34' } as DialCode);
        dialCodes.push({ countryCode: DialCodeCountryCodeEnum.Hu, code: '36' } as DialCode);
        dialCodes.push({ countryCode: DialCodeCountryCodeEnum.Se, code: '46' } as DialCode);
        return dialCodes;
    };

    public static convertToContactData = (username: string, emails: EmailResponse[], phoneNumbers: PhoneResponse[]): ContactRequest => {
        return {
            name: username,
            emails: emails?.map(email => {
                return {
                    email: email.email,
                    isDefault: email.isDefault
                };
            }),
            phones: phoneNumbers?.map(phone => {
                return {
                    isDefault: phone.isDefault,
                    value: {
                        number: phone?.value?.number,
                        dialCode: phone?.value?.dialCode
                    }
                };
            })
        } as ContactRequest;
    };

    public static addEmailToExistingUserContact = (userContacts: ContactResponse[], contactUuid: string, email: EmailResponse) => {
        userContacts.forEach(contact => {
            if (contact.uuid === contactUuid) {
                contact.emails?.push(email);
            }
        });
        return userContacts;
    };

    public static addPhoneNumberToExistingUserContact = (userContacts: ContactResponse[], contactUuid: string, phone: PhoneResponse) => {
        userContacts.forEach(contact => {
            if (contact.uuid === contactUuid) {
                contact.phones?.push(phone);
            }
        });
        return userContacts;
    };

    public static sortByName = (contacts?: ContactResponse[]): ContactResponse[] => {
        if (!contacts) {
            return [];
        }
        const c = [...contacts];
        return c.sort((a: ContactResponse, b: ContactResponse) => a.name.localeCompare(b.name));
    };

    public static sortEmails = (emails?: EmailResponse[]): EmailResponse[] => {
        if (!emails) {
            return [];
        }
        const e = [...emails];
        return e.sort((a: EmailResponse, b: EmailResponse) => a.email.localeCompare(b.email));
    };

    public static sortPhones = (phones?: PhoneResponse[]): PhoneResponse[] => {
        if (!phones) {
            return [];
        }
        const p = [...phones];
        return p.sort((a: PhoneResponse, b: PhoneResponse) => (a.value.dialCode + a.value.number).localeCompare(b.value.dialCode + b.value.number));
    };

    public static applyFilters = (contacts: ContactResponse[], query: string): ContactResponse[] => {
        const filteredContacts: ContactResponse[] = contacts.filter((contact: ContactResponse) => {
            let matches = true;

            if (query) {
                let containsQuery = false;

                if (contact.name.toLowerCase()?.includes(query?.toLowerCase())) {
                    containsQuery = true;
                }

                if (contact.emails?.some(
                    (email: EmailResponse) =>
                        email.email.toLowerCase().includes(query.toLowerCase()))
                ) {
                    containsQuery = true;
                }

                if (contact.phones?.some(
                    (phone: PhoneResponse) =>
                        phone.value.dialCode.concat(phone.value.number)
                            .toLowerCase()
                            .includes(query.toLowerCase())
                )) {
                    containsQuery = true;
                }

                if (!containsQuery) {
                    matches = false;
                }
            }

            return matches;
        });

        const newContacts: ContactResponse[] = [];

        filteredContacts.forEach((c: ContactResponse) => {
            const contact = deepCopy(c);

            contact.emails = c.emails?.filter(
                (email: EmailResponse) =>
                    email.email
                        .toLowerCase()
                        .includes(query.toLowerCase())
            );

            contact.phones = c.phones?.filter(
                (phone: PhoneResponse) =>
                    phone.value.dialCode
                        .concat(phone.value.number)
                        .toLowerCase()
                        .includes(query.toLowerCase())
            );

            newContacts.push(contact);
        });

        return newContacts;
    };
}
