import { FC } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserConsent } from 'consent-manager-api';
import ProfileConsentRow from './ProfileConsentRow';
import { ConsentType } from './ProfileConsents';
import Scrollbar from '../scrollbar/Scrollbar';

interface ProfileConsentsTableProps {
    consents: UserConsent[];
    consentType: ConsentType;
}

const ProfileConsentsTable: FC<ProfileConsentsTableProps> = (props) => {
    const { consents, consentType } = props;
    const { t } = useTranslation();

    return (
        <>
            <Scrollbar>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography align='center' color='textSecondary' variant='subtitle2'>
                                    {t('dashboard.profile.consents.table.identyum.latestChangedDate')}
                                </Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography color='textPrimary' variant='subtitle2'>
                                    {t('dashboard.profile.consents.table.identyum.type')}
                                </Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography variant='subtitle2'>{t('dashboard.profile.consents.table.identyum.status')}</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography variant='subtitle2'>{t('dashboard.profile.consents.table.identyum.actions')}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {consents.map((consent: UserConsent) => {
                            return <ProfileConsentRow key={consent.uuid} consent={consent} consentType={consentType} />;
                        })}
                    </TableBody>
                </Table>
            </Scrollbar>
        </>
    );
};

export default ProfileConsentsTable;
