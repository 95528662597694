import { FC, useState } from 'react';
import { Collapse, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import identyumLogo from '../../assets/img/branding-logo.png';
import finaLogo from '../../assets/img/illustrations/FINA_logo.svg';
import CertificateCard from './CertificateCard';
import { useAppSelector } from '../../store/hooks';
import CertificateDetails from './CertificateDetails';
import { CloseRounded } from '@mui/icons-material';
import { PaymentsHelper } from '../../utils/payments-helper';

interface ICertificateSelectModal {
    onClose: () => void;
    onIdentyumCertificateSelect: () => void;
    onFinaCertificateSelect: () => void;
}

const CertificateSelectModal: FC<ICertificateSelectModal> = (props) => {

    const { onClose, onFinaCertificateSelect, onIdentyumCertificateSelect } = props;

    const [ isIdyDetails, setIsIdyDetails ] = useState<boolean>(false);
    const [ isFinaDetails, setIsFinaDetails ] = useState<boolean>(false);

    const { t } = useTranslation();

    const { isCertificateSelectModalOpen } = useAppSelector(state => state.certificates);
    const { userPayables } = useAppSelector((state) => state.payments);
    const hasFinaCertPayable: boolean = PaymentsHelper.hasFinaCertPayable(userPayables);

    const finaButtonTitle = hasFinaCertPayable
        ? t('certificateSelectModal.button.choose') as string
        : t('certificateSelectModal.button.chooseFina') as string;

    const handleIdyCertDetails = () => {
        setIsIdyDetails(true);
    };

    const handleFinaCertDetails = () => {
        setIsFinaDetails(true);
    };

    const handleBackButton = () => {
        setIsFinaDetails(false);
        setIsIdyDetails(false);
    };

    const handleOnCloseButton = () => {
        setIsFinaDetails(false);
        setIsIdyDetails(false);
        onClose();
    };

    return (
        <Dialog open={ isCertificateSelectModalOpen } maxWidth={ 'md' } onClose={ handleOnCloseButton }>
            <DialogTitle sx={ { display: 'flex', justifyContent: 'space-between' } }>
                { t('certificateSelectModal.title') }
                <CloseRounded onClick={ handleOnCloseButton } sx={ { cursor: 'pointer' } } />
            </DialogTitle>
            <DialogContent>
                { !isIdyDetails && !isFinaDetails &&
                  <Grid container spacing={ 2 } direction='row' justifyContent='center' alignItems='stretch'>
                    <Grid item xs={ 12 } sm={ 12 } md={ 5 } lg={ 5 }>
                      <CertificateCard image={ identyumLogo }
                                       title={ t('certificateSelectModal.identyum.title') }
                                       description={ t('certificateSelectModal.identyum.description') }
                                       onConfirm={ onIdentyumCertificateSelect }
                                       onDetails={ handleIdyCertDetails }
                                       buttonTitle={ t('certificateSelectModal.button.chooseIdy') }
                      />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 12 } md={ 5 } lg={ 5 }>
                      <CertificateCard image={ finaLogo }
                                       title={ t('certificateSelectModal.fina.title') }
                                       description={ t('certificateSelectModal.fina.description') }
                                       onConfirm={ onFinaCertificateSelect }
                                       onDetails={ handleFinaCertDetails }
                                       buttonTitle={ finaButtonTitle }
                      />
                    </Grid>

                  </Grid> }
                { isIdyDetails &&
                    ( <Collapse in={ true } orientation='horizontal'>
                        <CertificateDetails
                            onBack={ handleBackButton }
                            certificateDetails={ t('certificateSelectModal.identyum.certificateDetails') } />
                    </Collapse> )
                }
                { isFinaDetails &&
                  <Collapse in={ true } orientation='horizontal'>
                    <CertificateDetails
                      onBack={ handleBackButton }
                      certificateDetails={ t('certificateSelectModal.fina.certificateDetails') } />
                  </Collapse>
                }

            </DialogContent>
        </Dialog>
    );
};

export default CertificateSelectModal;
