import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Card, CardActions, CardContent, Container, Theme, Typography, useMediaQuery } from '@mui/material';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import { useParams } from 'react-router-dom';
import apis from '../../auth/apis';
import SkeletonBlock from '../../components/skeletons/SkeletonBlock';
import PDFViewer from '../../components/pdf-viewer/PdfViewer';
import EmptyState from '../../components/empty-states/EmptyState';
import { CheckCircleOutline, DocumentScanner } from '@mui/icons-material';
import idyLogo from '../../assets/img/illustrations/Identyum-SIGN-logo.svg';
import useSettings from '../../hooks/useSettings';
import { DocumentResponse, DocumentSimpleResponse } from 'user-document-signer-api';
import { toast } from 'react-hot-toast';
import { RequestHelper } from '../../utils/request-helper';
import { useTranslation } from 'react-i18next';
import LanguagePopover from '../../components/dashboard/LanguagePopover';
import ConfirmationDialog from '../../components/shared/ConfirmationDialog';
import PdfSignatureModal from '../../components/pdf-signature-modal/PdfSignatureModal';
import { IVisualSignatureCoordinates } from '../../model/common/documents/document-user';
import { DocumentSignSimpleRequest, SignatureCoordinates } from 'user-document-signer-api';

const DocumentSignSimplePage: FC = () => {
    const { settings } = useSettings();
    const { shareCode } = useParams();
    const { t } = useTranslation();

    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const [ simpleDocument, setSimpleDocument ] = useState<DocumentResponse>();
    const [ userHasSignedDocument, setUserHasSignedDocument ] = useState<boolean>(false);
    const [ downloadable, setDownloadable ] = useState<boolean>(false);
    const [ loadingContent, setLoadingContent ] = useState<boolean>(false);
    const [ loadingSign, setLoadingSign ] = useState<boolean>(false);
    const [ emptyStateMessage, setEmptyStateMessage ] = useState<string>(t('documentSignSimplePage.emptyState.message1'));
    const [ isSignConfirmModalOpen, setIsSignConfirmModalOpen ] = useState<boolean>(false);
    const [ isSignatureModalOpen, setIsSignatureModalOpen ] = useState<boolean>(false);

    useEffect(() => {
        if (shareCode) {
            setLoadingContent(true);
            apis.userDocumentSimpleSignerApi()
                .getDocumentContentSimple(shareCode)
                .then((result) => {
                    if (result.data) {
                        setSimpleDocument(result.data.document);
                        setUserHasSignedDocument(result.data.signed);
                        setDownloadable(determineIsDownloadable(result.data));
                    }
                    setLoadingContent(false);
                })
                .catch(() => setLoadingContent(false));
        }
    }, [ shareCode ]);

    const handleSignClick = (coordinates?: IVisualSignatureCoordinates) => {
        if (shareCode) {
            setLoadingSign(true);
            const request: DocumentSignSimpleRequest = {
                shareCode: shareCode,
                signatureCoordinates: coordinates ?
                    {
                        height: coordinates.height,
                        page: coordinates.pageNumber,
                        width: coordinates.width,
                        x: coordinates.x,
                        y: coordinates.y
                    } as SignatureCoordinates
                    : undefined
            };
            apis.userDocumentSimpleSignerApi()
                .signDocumentSimple(request)
                .then((result) => {
                    if (result.data) {
                        setSimpleDocument(result.data.document);
                        setUserHasSignedDocument(result.data.signed);
                        setDownloadable(determineIsDownloadable(result.data));
                        setLoadingSign(false);
                        toast.success(t('documentSignSimplePage.toast.success.signed'));
                    }
                })
                .catch((error) => {
                    if (RequestHelper.isGoneException(error)) {
                        setEmptyStateMessage(t('documentSignSimplePage.emptyState.message2'));
                    }

                    setLoadingSign(false);
                });
        }
    };

    const handleDownloadClick = () => {
        if (simpleDocument) {
            const link = document.createElement('a');
            link.download = `${ simpleDocument ? simpleDocument.documentInfo?.title?.defaultText?.split('.')[0].trim() : 'identyum-now-document-details' }.pdf`;
            link.href = 'data:application/pdf;base64,' + simpleDocument?.documentContent;
            link.click();
        }
    };

    const determineIsDownloadable = (document: DocumentSimpleResponse): boolean => {
        return document.downloadableBeforeSignature || ( !document.downloadableBeforeSignature && document.signed );
    };

    const handleSignModalOpen = (isOpen: boolean, openSignatureModal?: boolean) => {
        setIsSignConfirmModalOpen(isOpen);
        openSignatureModal && setIsSignatureModalOpen(openSignatureModal);
    };

    const handleSignatureModalOpen = (isOpen: boolean) => {
        setIsSignatureModalOpen(isOpen);
    };

    const handleOnFinish = (visualCoordinates: IVisualSignatureCoordinates) => {
        setIsSignatureModalOpen(false);
        handleSignClick(visualCoordinates);
    };

    const handleSignAutomatically = () => {
        setIsSignConfirmModalOpen(false);
        handleSignClick();
    };

    return (
        <Box m={4} mt={mdUp ? 4 : 1} minHeight='100vh' pb={16}>

            <Box sx={ { textAlign: 'right' } }>
                { mdUp ?? <Typography>Language</Typography> }
                <LanguagePopover />
            </Box>
            <Box textAlign='center' margin='auto' mb={ 4 }>
                <img alt={ 'Idy_logo' } src={ idyLogo } style={ { height: mdUp ? 100 : 50, width: 'auto' } } />
                { !loadingContent && <Typography>{ t('documentSignSimplePage.header.welcomeInfo') }</Typography> }
            </Box>
            <Container maxWidth={ settings.compact ? 'md' : false }>
                { loadingContent ? (
                    <Box textAlign='center'>
                        <Typography variant='subtitle2' margin='auto'>
                            { t('documentSignSimplePage.header.loadingMessage') }
                        </Typography>
                        <SkeletonBlock height={ 50 } />
                    </Box>
                ) : shareCode && simpleDocument ? (
                    <Card sx={ { pb: 2 } }>
                        <CardContent>
                            <Box>
                                <PDFViewer document={ simpleDocument.documentContent } />
                            </Box>
                        </CardContent>

                        <CardActions sx={ { justifyContent: 'center', mt: 2 } }>
                            { downloadable && <CommonButton btnType={ ButtonTypeEnum.DOWNLOAD } onClick={ handleDownloadClick }>
                                { t('documentSignSimplePage.cardActions.button.download.label') }
                            </CommonButton> }
                            { userHasSignedDocument ? (
                                <Box display='flex'
                                     alignItems='center'
                                     justifyContent='center'
                                     ml={ 2 }

                                     borderRadius='20px'
                                     maxWidth={ 130 }
                                >
                                    <CheckCircleOutline color='success' fontSize='large' sx={ { pr: 1 } } />
                                    <Typography color='green'>{ t('documentSignSimplePage.cardActions.box.signed.label') }</Typography>
                                </Box>
                            ) : (
                                <CommonButton btnType={ ButtonTypeEnum.SIGN } onClick={ () => handleSignModalOpen(true) } loading={ loadingSign }>
                                    { t('documentSignSimplePage.cardActions.button.sign.label') }
                                </CommonButton>
                            ) }
                        </CardActions>

                        <Box mt={ 2 } textAlign='center'>
                            <Typography variant='caption' color='textSecondary'>
                                { t('documentSignSimplePage.footer.info.label') }
                            </Typography>
                        </Box>
                    </Card>
                ) : (
                    <EmptyState message={ emptyStateMessage } icon={ <DocumentScanner /> } />
                ) }
            </Container>

            <ConfirmationDialog open={ isSignConfirmModalOpen }
                                preventSmUpBreakpoint={ true }
                                showCloseButton={ true }
                                closeButtonText={ t('signatureModal.button.cancel') as string }
                                confirmButtonText={ t('signatureModal.button.confirm') as string }
                                title={ t('signatureModal.title') }
                                message={ t('signatureModal.subtitle') }
                                onConfirm={ () => handleSignModalOpen(false, true) }
                                onNo={ handleSignAutomatically }
                                onCancel={ () => handleSignModalOpen(false, false) } />

            <PdfSignatureModal documentContent={ simpleDocument?.documentContent || '' }
                               onClose={ () => handleSignatureModalOpen(false) }
                               onFinish={ handleOnFinish }
                               open={ isSignatureModalOpen } />
        </Box>
    );
};

export default DocumentSignSimplePage;
