import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Box, InputAdornment, TextField, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import { ErrorType } from '../../model/common/error';
import { clearErrorMessage } from '../../slices/tan-modal';
import auth from '../../auth/auth';
import { useNavigate } from 'react-router-dom';

interface IFinaCertCreateProps {
    onConfirm: (tan: string) => void;
    onSendTan?: () => void;
}

const TanInsert: FC<IFinaCertCreateProps> = ({ onConfirm, onSendTan }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [userTan, setUserTan] = useState<string>('');

    const { error, remainingAttempts } = useAppSelector((state) => state.tanModal);
    const { isLoading, tanCompareValue } = useAppSelector((state) => state.finaLcp);

    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const TAN_LENGTH = 6;
    const tanCompareValueText = tanCompareValue
        ? t('tan.compareValue') as string + ': ' + tanCompareValue
        : '';

    useEffect(() => {
        if (remainingAttempts == 0) {
            auth.clearLoginData();
            navigate('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remainingAttempts]);

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            userTan.length === TAN_LENGTH && onConfirm(userTan);
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value as string;
        const onlyNumbersAndLetters = value.replace(/[^A-Za-z0-9]/g, '');
        setUserTan(onlyNumbersAndLetters.toUpperCase());
    };

    const handleConfirm = (): void => {
        onConfirm(userTan);
    };

    const isButtonDisabled = () => {
        return TAN_LENGTH - userTan.length !== 0;
    };

    const handleSendTan = () => {
        dispatch(clearErrorMessage());
        onSendTan && onSendTan();
    };

    return (
        <>
            <Box sx={{ p: 3 }}>
                <Typography align='center' color='textPrimary' gutterBottom variant='h5'>
                    {t('dashboard.sidebar.documents.pinModal.enterTan')}
                </Typography>
                <Typography align='center' color='textSecondary' variant='body1'>
                    {t('dashboard.sidebar.documents.enterTan.subtitle')}
                </Typography>
                <Typography align='center' color='error' variant='body1' fontWeight={500} sx={{ m: 2 }}>
                    {error?.errorMessage ? t(error?.errorMessage) : ''}
                </Typography>
                <Box sx={{ mt: 3, textAlign: 'center' }}>
                    <TextField
                        disabled={Boolean(isLoading)}
                        error={Boolean(error?.errorMessage)}
                        helperText={tanCompareValueText}
                        type='text'
                        autoFocus
                        inputProps={{ maxLength: 6 }}
                        fullWidth
                        label='TAN'
                        onChange={handleChange}
                        onKeyDown={keyDownHandler}
                        placeholder={t('dashboard.sidebar.documents.enterTan.textField.title')}
                        rows={1}
                        value={userTan}
                        variant='outlined'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    {userTan.length} / {TAN_LENGTH}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
            {error?.errorType === ErrorType.AUTHORIZATION_ERROR &&
              <Box sx={{ mt: 3, textAlign: 'center' }}>
                <CommonButton
                  btnType={ButtonTypeEnum.NO_ICON}
                  onClick={handleSendTan}
                  disabled={isLoading}>
                    {t('tan.send')}
                </CommonButton>
              </Box>
            }
            <Box sx={{ mt: 7, pl: !mdUp ? 3 : 0, pr: !mdUp ? 3 : 0, textAlign: 'center' }}>
                <CommonButton
                    fullWidth={!mdUp}
                    btnType={ButtonTypeEnum.NO_ICON_CONTAINED}
                    loading={isLoading}
                    onClick={handleConfirm}
                    disabled={isButtonDisabled()}>
                    {t('go.ahead')}
                </CommonButton>
            </Box>
        </>
    );
};

export default TanInsert;
