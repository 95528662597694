import type { FC, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

interface IEmptyState {
    message: string;
    icon?: ReactNode;
    subtitle?: string;
}

const EmptyState: FC<IEmptyState> = (props) => {
    const { message, icon, subtitle } = props;

    return (
        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
            {icon && <Box sx={ { mb: 10, mt: 15, opacity: '0.3', transform: 'scale(4)' } }>
                { icon }
            </Box> }
            <Typography sx={{ mb: 3 }} color='textSecondary' variant='subtitle2'>
                {message}
            </Typography>
            {subtitle && (
                <Typography sx={{ mb: 3 }} color='textSecondary' variant='caption'>
                    {subtitle}
                </Typography>
            )}
        </Box>
    );
};

export default EmptyState;
