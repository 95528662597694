import ReactDOM from 'react-dom';
import {CircularProgress} from '@material-ui/core';

type Props = {
    appear?: boolean;
};

export const Loader = ({appear = false}: Props) => {
    const parent = window?.document?.body;
    if (!appear || !parent) return null;

    const child = (
        <div className='loaderMainDiv'>
            <div className='loaderBgDiv'/>
            <div className='loaderInnerDiv'>
                <div className='imgSize' style={{position: 'relative'}}>
                    <CircularProgress color="secondary" />
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(child, parent);
};
