import { IMenuSection, IMenuSectionItem } from '../model/common/navigation/menu-section';
import {
    AutoAwesomeMotion,
    AutoFixHigh,
    BusinessRounded,
    Download,
    GroupsRounded,
    ListRounded,
    PermContactCalendarRounded,
    Restore,
    Settings,
    ShoppingBasketRounded,
    Upload,
    UploadFile
} from '@mui/icons-material';
import deepCopy from './deepCopy';

const private_received_documents_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.documents.received.title',
    path: '/dashboard/documents/received',
    icon: <Download fontSize='small' />,
};

const private_sent_documents_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.documents.sent.title',
    path: '/dashboard/documents/sent',
    icon: <Upload fontSize='small' />,
};

const private_new_document_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.newDocuments.title',
    path: '/dashboard/documents/upload',
    icon: <UploadFile fontSize='small' />,
};

const PRIVATE_DOCUMENTS_SECTION: IMenuSection = {
    title: 'dashboard.sidebar.documents.head',
    items: [
        private_received_documents_item,
        private_sent_documents_item,
        private_new_document_item
    ]
};

const private_contacts_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.userContacts.title',
    path: '/dashboard/userContacts/allContacts',
    icon: <ListRounded fontSize='small' />,
};

const private_new_contact_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.newUserContact.title',
    path: '/dashboard/userContacts/newContact',
    icon: <PermContactCalendarRounded fontSize='small' />,
};

const PRIVATE_CONTACTS_SECTION: IMenuSection = {
    title: 'dashboard.sidebar.userContacts.head',
    items: [
        private_contacts_item,
        private_new_contact_item
    ]
};

const private_shop_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.plans.title',
    path: '/dashboard/shop',
    icon: <ShoppingBasketRounded fontSize='small' />,
};

const PRIVATE_SHOP_SECTION: IMenuSection = {
    title: 'dashboard.sidebar.plans.head',
    items: [
        private_shop_item
    ]
};

const private_groups_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.groups.title',
    path: '/dashboard/groups',
    icon: <ListRounded fontSize='small' />,
};

// const private_groups_new_item: IMenuSectionItem = {
//     title: 'dashboard.sidebar.groups.new.title',
//     path: '/dashboard/groups/new',
//     icon: <GroupAddRounded fontSize='small' />,
// };

const private_groups_invitations_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.groups.pending',
    path: '/dashboard/groupsPending',
    icon: <Restore fontSize='small' />,
};

const PRIVATE_GROUPS_SECTION: IMenuSection = {
    title: 'dashboard.sidebar.groups.head',
    items: [
        private_groups_item,
        // private_groups_new_item,
        private_groups_invitations_item
    ]
};

const private_organizations_form_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.businessProfile.new',
    path: '/dashboard/organizations/new',
    icon: <BusinessRounded fontSize='small' />,
};

const private_organizations_list_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.businessProfile.list',
    path: '/dashboard/organizations/list',
    icon: <ListRounded fontSize='small' />,
};

const private_organizations_invitations_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.businessProfile.invitations',
    path: '/dashboard/organizations/invites',
    icon: <Restore fontSize='small' />,
};

const PRIVATE_ORGANIZATIONS_SECTION: IMenuSection = {
    title: 'dashboard.sidebar.businessProfile.head',
    items: [
        private_organizations_list_item,
        private_organizations_form_item,
        private_organizations_invitations_item
    ]
};

const private_created_surveys_list_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.surveys.surveyManagement',
    path: '/dashboard/survey-admin',
    icon: <AutoFixHigh fontSize='small' />,
};

const private_my_surveys_list_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.surveys.mySurveys',
    path: '/dashboard/my-surveys',
    icon: <AutoAwesomeMotion fontSize='small' />,
};

const PRIVATE_SURVEYS_SECTION: IMenuSection = {
    title: 'dashboard.sidebar.surveys.header',
    items: [
        private_created_surveys_list_item,
        private_my_surveys_list_item
    ]
};

const group_sent_documents_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.documents.sent.title',
    path: '/dashboard/groups/:groupUuid/sent-documents',
    icon: <Upload fontSize='small' />,
};

const group_received_documents_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.documents.received.title',
    path: '/dashboard/groups/:groupUuid/received-documents',
    icon: <Download fontSize='small' />,
};

const group_new_document_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.newDocuments.title',
    path: '/dashboard/groups/:groupUuid/newDocument',
    icon: <UploadFile fontSize='small' />,
};

const GROUP_DOCUMENTS_SECTION: IMenuSection = {
    title: 'dashboard.sidebar.documents.head',
    items: [
        group_sent_documents_item,
        group_received_documents_item,
        group_new_document_item
    ]
};

const group_settings_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.settings.title',
    path: '/dashboard/groups/:groupUuid/settings',
    icon: <Settings fontSize='small' />,
};

const GROUP_SETTINGS_SECTION: IMenuSection = {
    title: 'dashboard.sidebar.settings.head',
    items: [
        group_settings_item
    ]
};

const group_members_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.groups.members.title',
    path: '/dashboard/groups/:groupUuid/members',
    icon: <GroupsRounded fontSize='small' />,
};

const GROUP_MEMBERS_SECTION: IMenuSection = {
    title: 'dashboard.sidebar.groups.head',
    items: [
        group_members_item
    ]
};

const group_surveys_item: IMenuSectionItem = {
    title: 'dashboard.sidebar.surveys.surveyManagement',
    path: '/dashboard/groups/:groupUuid/surveys/survey-admin',
    icon: <AutoFixHigh fontSize='small' />,
};

const GROUP_SURVEYS_SECTION: IMenuSection = {
    title: 'dashboard.sidebar.surveys.header',
    items: [
        group_surveys_item
    ]
};

export const getGroupMenu = (groupUuid: string): IMenuSection[] => {
    const sections = deepCopy([GROUP_DOCUMENTS_SECTION, GROUP_SETTINGS_SECTION, GROUP_MEMBERS_SECTION, GROUP_SURVEYS_SECTION]) as IMenuSection[];
    if (groupUuid) {
        sections.map((section) => {
            section.items.map((item) => {
                item.path = item.path.replace(':groupUuid', groupUuid);
            });
        });
    }
    return sections;
};

export const getPrivateMenu = (): IMenuSection[] => {
    const menu: IMenuSection[] = [
        PRIVATE_DOCUMENTS_SECTION,
        PRIVATE_CONTACTS_SECTION,
        PRIVATE_SHOP_SECTION,
        PRIVATE_GROUPS_SECTION,
        PRIVATE_ORGANIZATIONS_SECTION
    ];

    if (process.env.REACT_APP_BETA_ENABLED === 'true') {
        menu.push(PRIVATE_SURVEYS_SECTION);
    }

    return menu;
};
