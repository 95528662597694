import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Invitation, MembershipSimple } from 'group-service-api';
import { Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DocumentsHelper } from '../../utils/documents-helper';
import { ErrorType, IError } from '../../model/common/error';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { useAppSelector } from '../../store/hooks';
import { UserContactsHelper } from '../../utils/user-contacts-helper';
import CountryCodeChooser from '../CountryCodeChooser';

interface IAddMemberPhoneCardProps {
    onClick: (invitation: Invitation) => void;
}

const AddMemberPhoneCard: FC<IAddMemberPhoneCardProps> = (props) => {
    const { onClick } = props;
    const { t } = useTranslation();
    const { selectedGroup, isLoadingAction } = useAppSelector((state) => state.groups);
    const [ phoneNumber, setPhoneNumber ] = useState<string>('');
    const [ dialCode, setDialCode ] = useState<string>('385');
    const [ validationError, setValidationError ] = useState<IError>({
        error: false,
        errorMessage: '',
        errorType: ErrorType.VALIDATION_ERROR
    });

    useEffect(() => {
        setPhoneNumber('');
    }, [ selectedGroup?.memberships ]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setValidationError({ error: false, errorMessage: '', errorType: ErrorType.VALIDATION_ERROR });
        const onlyNumbers = event.target.value.replace(/[^0-9]/g, '');
        setPhoneNumber(onlyNumbers);
    };

    const handleAddClick = () => {
        const phoneNr = phoneNumber.startsWith('0') ? DocumentsHelper.stripLeadingZeros(phoneNumber) : phoneNumber;
        const validPhoneNumber = DocumentsHelper.isCroatianMobileNumberFormat(`+${ dialCode }${ phoneNr }`);

        const alreadyExists = selectedGroup?.memberships.findIndex((m: MembershipSimple) => m.phone === ( dialCode + phoneNumber ));

        if (!validPhoneNumber) {
            setValidationError({
                error: true,
                errorMessage: t('dashboard.sidebar.userContacts.newContact.phoneNumber.modal.phoneNumber.validationError') as string,
                errorType: ErrorType.VALIDATION_ERROR
            });
        } else if (alreadyExists !== undefined && alreadyExists > -1) {
            setValidationError({
                error: true,
                errorMessage: t('dashboard.sidebar.userContacts.newContact.phoneNumber.modal.phoneNumber.existingPhoneNumber') as string,
                errorType: ErrorType.VALIDATION_ERROR
            });
        } else {
            onClick({ phone: { dialCode: dialCode, number: phoneNr } });
            setPhoneNumber('');
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            handleAddClick();
        }
    };

    const handleDialCodeSelect = (dialCode: string) => {
        setDialCode(dialCode);
    };

    return (
        <Grid item container direction='row' spacing={ 2 } sx={ { maxWidth: '400px' } }>
            <Grid item xs={ 4 } sm={ 4 } md={ 4 } lg={ 4 }>
                <CountryCodeChooser onSelect={ handleDialCodeSelect }
                                    fallbackDialCode={ UserContactsHelper.FALLBACK_DIAL_CODE }
                                    flagsEnabled={ true }
                                    validationErrorMessage={ validationError.errorMessage }
                                    disabled={ isLoadingAction } />
            </Grid>
            <Grid item xs={ 8 } sm={ 8 } md={ 8 } lg={ 8 } sx={ { width: '100%' } }>
                <TextField error={ Boolean(validationError.errorMessage) }
                           helperText={ Boolean(validationError.errorMessage) && validationError.errorMessage }
                           autoFocus
                           fullWidth
                           label={ t('dashboard.sidebar.userContacts.newContact.phoneNumber.modal.phoneNumber') }
                           onChange={ handleChange }
                           onKeyDown={ handleKeyDown }
                           placeholder={ t('dashboard.sidebar.userContacts.newContact.phoneNumber.modal.phoneNumber.placeholder') }
                           rows={ 1 }
                           value={ phoneNumber }
                           variant='outlined'
                />
            </Grid>
            <CommonButton sx={ { ml: 2, mt: 3 } }
                          btnType={ ButtonTypeEnum.ADD }
                          loading={ isLoadingAction }
                          onClick={ () => handleAddClick() }>
                { t('groups.memberships.add') }
            </CommonButton>
        </Grid>
    );
};

export default AddMemberPhoneCard;
