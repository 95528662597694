import { FC, useEffect } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Email } from 'email-verifier-api';
import CommonButton, { ButtonTypeEnum } from 'components/shared/CommonButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearErrorMessage, deleteMail, setDefaultEmail } from 'slices/emails';
import { useTranslation } from 'react-i18next';
import Label from 'components/Label';
import { DeleteOutline } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { ErrorType } from 'model/common/error';

interface IEmailRowProps {
    emailData: Email;
}

const EmailRow: FC<IEmailRowProps> = ({ emailData }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { error } = useAppSelector((state) => state.emails);

    useEffect(() => {
        if (error && error?.errorType === ErrorType.DELETE_EMAIL_ERROR) {
            toast.error(t(error.errorMessage));
            dispatch(clearErrorMessage());
            return;
        }
        if (error && error?.errorType === ErrorType.SET_DEFAULT_EMAIL_ERROR) {
            toast.error(t(error.errorMessage));
            dispatch(clearErrorMessage());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <TableRow>
            <TableCell sx={{ width: '10%' }}>
                {!emailData.defaultEmail && (
                    <DeleteOutline
                        sx={{ cursor: 'pointer', opacity: '0.7' }}
                        fontSize='small'
                        color='error'
                        onClick={() => dispatch(deleteMail(emailData.uuid))}
                    />
                )}
            </TableCell>
            <TableCell sx={{ width: '50%' }}>{emailData.email}</TableCell>
            <TableCell align='center' sx={{ width: '25%' }}>
                {emailData.defaultEmail && <Label color={'info'}>{t('dashboard.profile.contact.default')}</Label>}
                {!emailData.defaultEmail && (
                    <CommonButton btnType={ButtonTypeEnum.NO_ICON} onClick={() => dispatch(setDefaultEmail(emailData.uuid))}>
                        {t('dashboard.profile.contact.setDefault')}
                    </CommonButton>
                )}
            </TableCell>
        </TableRow>
    );
};

export default EmailRow;
