import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import { ArrowLeft } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PrivateRoutes } from '../../routes';

const CheckoutHeading: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <RouterLink to={ PrivateRoutes.private_shop_route.path }>
                <CommonButton btnType={ButtonTypeEnum.BACK} startIcon={<ArrowLeft fontSize='small' />}>
                    {t('dashboard.profile.plans.checkout.backButton')}
                </CommonButton>
            </RouterLink>
            <Typography variant='h3' sx={{ mt: 3 }}>
                {t('dashboard.profile.plans.checkout')}
            </Typography>
        </>
    );
};

export default CheckoutHeading;
