import axios, { AxiosInstance, AxiosResponse } from 'axios';
import auth, { Token } from './auth';
import apis from './apis';

let currentInstance: AxiosInstance;

export enum RejectReason {
    SESSION_TIMEOUT,
    INVALID_PIN,
}

export default {
    instance() {
        if (currentInstance == null) {
            this.update();
        }
        return currentInstance;
    },

    update() {
        currentInstance = axios.create({
            headers: {
                Authorization: 'Bearer ' + auth.getToken()?.access_token,
                'X-CLIENT-SESSION-UUID': auth.getClientSessionUuid(),
                'X-IDY-LANG': auth.getIdyLanguage(),
                'X-ORGANIZATION-UUID': auth.getOrganizationUuid(),
                'X-USER-PIN': auth.getUserPin(),
            },
        });
        currentInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                const status = error.response ? error.response.status : null;
                const type = error.response && error.response.data ? error.response.data.type : null;

                if (status === 401) {
                    return refreshToken().then(() => {
                        Promise.resolve();
                        error.config.headers['Authorization'] = 'Bearer ' + auth.getToken()?.access_token;
                        error.config.headers['X-USER-PIN'] = auth.getUserPin();
                        error.config.baseURL = undefined;
                        return axios.request(error.config);
                    });
                }

                if (status === 422 && type === 'PIN_NOT_CORRECT' && !(error.config.url as string).includes('pin-manager')) {
                    auth.clearLoginData();
                    window.location.assign(process.env.REACT_APP_REDIRECT_URL + '/login');
                    return Promise.reject(RejectReason.INVALID_PIN);
                }

                return Promise.reject(error);
            }
        );
        apis.update();
    },
};

export const baseAxiosInstance: AxiosInstance = axios.create();

let refreshPromise: Promise<void> | null = null;

export const refreshRequest = async (): Promise<void> => {
    auth.setIsRefreshInProgress(true);
    return baseAxiosInstance
        .post(
            `${process.env.REACT_APP_ACCESS_TOKEN_URL}`,
            new URLSearchParams({
                client_id: process.env.REACT_APP_CLIENT_ID ?? '',
                grant_type: 'refresh_token',
                refresh_token: auth.getToken()?.refresh_token || '',
            })
        )
        .then((response: AxiosResponse) => {
            auth.setToken(
                {
                    access_token: response.data.access_token,
                    expires_in: response.data.expires_in,
                    refresh_expires_in: response.data.refresh_expires_in,
                    refresh_token: response.data.refresh_token,
                } as Token
            );
            return Promise.resolve();
        })
        .catch((err) => {
            auth.clearLoginData();
            window.location.assign(process.env.REACT_APP_REDIRECT_URL + '/login');
            return Promise.reject(err);
        })
        .finally(() => {
            auth.setIsRefreshInProgress(false);
            refreshPromise = null;
        });
};

export function refreshToken(): Promise<void> {
    if (refreshPromise && auth.getIsRefreshInProgress()) {
        return refreshPromise;
    }

    refreshPromise = refreshRequest();
    return refreshPromise;
}
