import React, { ChangeEvent, FC, useState } from 'react';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    useMediaQuery
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'components/scrollbar/Scrollbar';
import { UserContactsHelper } from '../../utils/user-contacts-helper';
import LoadingModal from '../LoadingModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { DeleteOutline, VisibilityOutlined } from '@mui/icons-material';
import { deleteUserContact, setCurrentUserContactHandler } from '../../slices/user-contacts';
import { useNavigate } from 'react-router-dom';
import { ContactResponse } from 'contacts-service-api';
import ConfirmationDialog from '../shared/ConfirmationDialog';

interface IUserContactListProps {
    userContacts: ContactResponse[];
}

const UserContactsList: FC<IUserContactListProps> = (props) => {
    const {
        isDeleteContactLoading,
        isDeletePhoneLoading,
        isDeleteEmailLoading
    } = useAppSelector(state => state.userContacts);
    const { userContacts } = props;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    const [ page, setPage ] = useState<number>(0);
    const [ limit, setLimit ] = useState<number>(10);
    const [ isDeleteUserContactModalOpen, setIsDeleteUserContactModalOpen ] = useState<boolean>(false);
    const [ contactUuidToDelete, setContactUuidToDelete ] = useState<string>('');

    const sortedContacts = UserContactsHelper.sortByName(userContacts);

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage);
    };
    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value, 10));
    };

    const userContactDetailsClickHandler = (contact: ContactResponse) => {
        dispatch(setCurrentUserContactHandler(contact));
        navigate(`/dashboard/userContacts/${ contact.uuid }`);
    };

    const removeUserContactClickHandler = (contactUuid: string) => {
        setContactUuidToDelete(contactUuid);
        setIsDeleteUserContactModalOpen(true);
    };

    const removeUserContactModalConfirmHandler = () => {
        dispatch(deleteUserContact(contactUuidToDelete));
        removeUserContactModalCloseHandler();
    };

    const removeUserContactModalCloseHandler = () => {
        setContactUuidToDelete('');
        setIsDeleteUserContactModalOpen(false);
    };

    return (
        <>
            <Card>
                <Scrollbar>
                    <Box sx={ { minWidth: smUp ? 700 : 150 } }>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{ t('dashboard.sidebar.userContacts.list.table.column.name') }</TableCell>
                                    <TableCell sx={ { textAlign: 'right' } } />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { sortedContacts.map((contact: ContactResponse) => {
                                    return (
                                        <TableRow key={ contact.uuid } hover>
                                            <TableCell onClick={ () => userContactDetailsClickHandler(contact) }>
                                                { contact.name }
                                            </TableCell>

                                            <TableCell sx={ { textAlign: 'right' } }>
                                                { smUp && <VisibilityOutlined
                                                  sx={ { cursor: 'pointer', mr: 2, opacity: '0.7' } }
                                                  fontSize='medium'
                                                  onClick={ () => userContactDetailsClickHandler(contact) } />
                                                }
                                                <DeleteOutline sx={ { cursor: 'pointer', mr: 2, opacity: '0.7' } }
                                                               fontSize='small'
                                                               color='error'
                                                               onClick={ () => removeUserContactClickHandler(contact.uuid) } />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) }
                            </TableBody>
                        </Table>
                    </Box>
                </Scrollbar>

                <TablePagination component='div'
                                 count={ sortedContacts.length }
                                 labelRowsPerPage={ t('dashboard.sidebar.userContacts.list.rowsPerPage') }
                                 onPageChange={ handlePageChange }
                                 onRowsPerPageChange={ handleLimitChange }
                                 page={ page }
                                 rowsPerPage={ limit }
                                 rowsPerPageOptions={ [ 10, 20, 50 ] }
                />
            </Card>

            <LoadingModal open={ isDeleteEmailLoading }
                          modalTitle={ t('dashboard.sidebar.userContacts.allContacts.deleting.email.loading') } />

            <LoadingModal open={ isDeletePhoneLoading }
                          modalTitle={ t('dashboard.sidebar.userContacts.allContacts.deleting.phone.loading') } />

            <LoadingModal open={ isDeleteContactLoading }
                          modalTitle={ t('dashboard.sidebar.userContacts.allContacts.deleting.loading') } />

            <ConfirmationDialog title={ t('dashboard.sidebar.documents.confirmModal.removeContact.title') }
                                message={ t('dashboard.sidebar.documents.confirmModal.removeContact.message') }
                                onConfirm={ removeUserContactModalConfirmHandler }
                                onCancel={ removeUserContactModalCloseHandler }
                                open={ isDeleteUserContactModalOpen } />
        </>
    );
};

export default UserContactsList;
