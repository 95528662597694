import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { useAppSelector } from '../../store/hooks';

interface IPrivateProfileSwitch {
    onBackToPrivateProfile?: () => void;
}

const PrivateProfileSwitch: FC<IPrivateProfileSwitch> = ({ onBackToPrivateProfile }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { activeGroup } = useAppSelector(state => state.groups);

    const handleOnBackToPrivateProfile = () => {
        onBackToPrivateProfile && onBackToPrivateProfile();
    };

    return (
        <>
            <CommonButton
                btnType={ ButtonTypeEnum.SWITCH }
                sx={ { color: theme.palette.primary.contrastText } }
                onClick={ () => handleOnBackToPrivateProfile() }>
                {
                    activeGroup?.group.organizationUuid ?
                        t('dashboard.navbar.button.backToPrivateProfile') :
                        t('dashboard.navbar.button.backToHomepage')
                }
            </CommonButton>
        </>
    );
};

export default PrivateProfileSwitch;
