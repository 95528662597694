import type { FC } from 'react';
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ShopLegalDocs from '../profile/plans/ShopLegalDocs';

const DataUsageStatement: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8,
                }}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid alignItems="center" container justifyContent="space-between" spacing={3} item xs={12}>
                            <Grid item>
                                <Typography color="textPrimary" variant="h5">
                                    Izjava o prikupljanju i korištenju osobnih podataka
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Card>
                                <CardContent>
                                    <Box sx={{whiteSpace: 'pre-line'}}>
                                        <Typography color={'textSecondary'} variant='body2'>
                                            {t('dashboard.sidebar.terms.dataUsage.content')}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <ShopLegalDocs />
        </>
    );
};

export default DataUsageStatement;
