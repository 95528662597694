import type { FC } from 'react';
import { ContactType, IDocumentReceiver } from '../../model/common/documents/document-user';
import { Avatar, Grid, IconButton, Paper, Switch, Theme, Tooltip, Typography } from '@mui/material';
import { AlternateEmail, HighlightOff, PhoneIphone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface IReceiverCardProps {
    onRemoveReceiver: (receiver: IDocumentReceiver) => void;
    onSignToggle: (receiver: IDocumentReceiver) => void;
    receiver: IDocumentReceiver;
}

const ReceiverCard: FC<IReceiverCardProps> = ({ onRemoveReceiver, onSignToggle, receiver }) => {
    const { t } = useTranslation();

    return (
        <Grid item
              key={ receiver.contactType === ContactType.EMAIL ? receiver.email : receiver.phone?.dialCode?.concat(receiver.phone?.number) }>
            <Paper elevation={ 3 } sx={ { maxWidth: 350, my: 1, p: 2 } }>
                <Grid container wrap='nowrap' spacing={ 2 }>
                    <Grid item>
                        <Avatar sx={ { backgroundColor: (theme: Theme) => theme.palette.primary.main } }>
                            { receiver.contactType === ContactType.EMAIL ? <AlternateEmail /> : <PhoneIphone /> }
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography noWrap sx={ { pl: 1 } } fontWeight={ 600 }>
                            { receiver.contactType === ContactType.EMAIL ? receiver.email : '+' + receiver.phone?.dialCode?.concat(receiver.phone?.number) }
                        </Typography>
                        <Typography sx={ { mt: 1 } } color='textSecondary' variant='subtitle1'>
                            { receiver.name }
                        </Typography>
                        <Typography sx={ { mt: 3 } } color='textPrimary' variant='body2'>
                            { t('dashboard.sidebar.documents.newDocument.receiver.mandatorySignature') }
                        </Typography>
                        <Switch checked={ receiver.signatureRequested}
                                color='primary'
                                edge='start'
                                name='shouldSign'
                                onChange={ () => onSignToggle(receiver) }
                                value={ receiver.signatureRequested }
                        />
                    </Grid>
                    <Grid item>
                        <Tooltip
                            title={ t('dashboard.sidebar.documents.newDocument.receiver.button.delete') as string }>
                            <IconButton onClick={ () => onRemoveReceiver(receiver) }>
                                <HighlightOff sx={ { cursor: 'pointer' } } color='warning' fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default ReceiverCard;
