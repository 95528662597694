import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Certificate } from 'idy-certifier-api';
import { AppThunk } from '../store';
import { handleError } from './errors-handling';
import documentSlice, { signDocumentsWithIdyCert } from './documents';
import apis from '../auth/apis';
import { hasValidFinaPKICloudCertificate } from './fina-lcp';
import { IVisualSignatureCoordinates } from '../model/common/documents/document-user';

interface IDocumentToSign {
    documentUuidList: string[];
    isFinaCert: boolean;
}

interface ICertificatesState {
    isCertificateSelectModalOpen: boolean;
    isConsentModalOpen: boolean;
    idyCertificate: Certificate | undefined;
    isIssuingIdyCert: boolean;
    isLoadingIdyCertificate: boolean;
    documentToSign: IDocumentToSign | undefined;
}

const initialState: ICertificatesState = {
    documentToSign: undefined,
    idyCertificate: undefined,
    isCertificateSelectModalOpen: false,
    isConsentModalOpen: false,
    isIssuingIdyCert: false,
    isLoadingIdyCertificate: false
};

const certificatesSlice = createSlice({
    name: 'certificates',
    initialState,
    reducers: {
        clearIdyCertificate(state: ICertificatesState): void {
            state.idyCertificate = undefined;
        },
        closeCertificateSelectModal(state: ICertificatesState): void {
            state.isCertificateSelectModalOpen = false;
        },
        closeConsentsModal(state: ICertificatesState): void {
            state.isConsentModalOpen = false;
            state.documentToSign = undefined;
            state.idyCertificate = undefined;
        },
        openCertificateSelectModal(state: ICertificatesState): void {
            state.isCertificateSelectModalOpen = true;
        },
        setIdyCertificate(state: ICertificatesState, action: PayloadAction<Certificate>): void {
            state.idyCertificate = action.payload;
            state.isConsentModalOpen = false;
            state.isLoadingIdyCertificate = false;
            state.isIssuingIdyCert = false;
        },
        setIsIssuingIdyCert(state: ICertificatesState, action: PayloadAction<boolean>): void {
            state.isIssuingIdyCert = action.payload;
        },
        setIsLoading(state: ICertificatesState, action: PayloadAction<boolean>): void {
            state.isLoadingIdyCertificate = action.payload;
        },
        showCertificateSelectModal(state: ICertificatesState, action: PayloadAction<IDocumentToSign>): void {
            state.documentToSign = action.payload;
            state.isCertificateSelectModalOpen = true;
        }
    }
});

export const checkHasIdyCertAndSign = (
    documentUuidList: string[],
    isFinaCert: boolean,
    groupUuid: string | undefined,
    organizationUuid?: string,
    visualCoordinatesForSigner?: IVisualSignatureCoordinates
): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(documentSlice.actions.setSigning({ isSigning: true, documentUuidList: documentUuidList }));
        apis.idyCertifierApi()
            .getUserCertificate()
            .then((response) => {
                if (!response.data.revoked) {
                    dispatch(certificatesSlice.actions.setIdyCertificate(response.data));
                    dispatch(
                        signDocumentsWithIdyCert(
                            documentUuidList,
                            groupUuid,
                            organizationUuid,
                            visualCoordinatesForSigner
                        )
                    );

                } else {
                    dispatch(certificatesSlice.actions.showCertificateSelectModal({ documentUuidList: documentUuidList, isFinaCert: isFinaCert }));
                    dispatch(documentSlice.actions.setSigning({ isSigning: false, documentUuidList: [] }));
                }
            })
            .catch((err) => {
                dispatch(documentSlice.actions.setSigning({ isSigning: false, documentUuidList: [] }));
                if (err?.response?.status === 404) {
                    dispatch(certificatesSlice.actions.showCertificateSelectModal({ documentUuidList: documentUuidList, isFinaCert: isFinaCert }));
                } else {
                    dispatch(handleError(err));
                }
            });
    };

export const issueIdyCertificateAndSign = (
    documentUuidList: string[],
    groupUuid?: string,
    organizationUuid?: string,
    visualCoordinatesForSigner?: IVisualSignatureCoordinates
): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(documentSlice.actions.setSigning({ isSigning: true, documentUuidList: documentUuidList }));
        apis.idyCertifierApi()
            .issueUserCertificate()
            .then((response) => {
                dispatch(documentSlice.actions.setSigning({ isSigning: false, documentUuidList: [] }));
                dispatch(certificatesSlice.actions.setIdyCertificate(response.data));
                dispatch(
                    signDocumentsWithIdyCert(
                        documentUuidList,
                        groupUuid,
                        organizationUuid,
                        visualCoordinatesForSigner
                    )
                );
            })
            .catch((err) => {
                dispatch(documentSlice.actions.setSigning({ isSigning: false, documentUuidList: [] }));
                dispatch(handleError(err));
            });
    };

export const issueIdyCertificate = (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(certificatesSlice.actions.setIsIssuingIdyCert(true));
        apis.idyCertifierApi()
            .issueUserCertificate()
            .then((response) => {
                dispatch(certificatesSlice.actions.setIdyCertificate(response.data));
            })
            .catch((err) => {
                dispatch(certificatesSlice.actions.setIsIssuingIdyCert(false));
                dispatch(handleError(err));
            });
    };

export const getIdyCertificate = (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(certificatesSlice.actions.setIsLoading(true));
        apis.idyCertifierApi()
            .getUserCertificate()
            .then((response) => {
                dispatch(certificatesSlice.actions.setIdyCertificate(response.data));
            })
            .catch((err) => {
                dispatch(certificatesSlice.actions.setIsLoading(false));
                console.log(err);
            });
    };

export const loadCertificates = (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(getIdyCertificate());
        dispatch(hasValidFinaPKICloudCertificate());
    };

export const { reducer } = certificatesSlice;

export const { clearIdyCertificate, closeCertificateSelectModal, openCertificateSelectModal } = certificatesSlice.actions;

export default certificatesSlice;
