import { FC, useEffect, useState } from 'react';
import { Box, Card, Grid } from '@mui/material';
import SkeletonInput from '../skeletons/SkeletonInput';
import { useTranslation } from 'react-i18next';
import { ContactType, IDocumentReceiver } from '../../model/common/documents/document-user';
import DocumentReceiverList from './DocumentReceiverList';
import { DocumentInfo } from 'idy-certifier-api';
import { Membership } from 'group-service-api';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import AddReceiver from '../receivers/AddReceiver';
import { DocumentsHelper } from '../../utils/documents-helper';
import { getUserContacts } from '../../slices/user-contacts';
import { getDialCodes } from '../../slices/phone-verifier';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { EmailOutlined } from '@mui/icons-material';

interface IDocumentShareProps {
    activeGroup?: Membership;
    isLoading?: boolean;
    onDocumentShare: (shareWith: IDocumentReceiver[], documentUuid: string, signSeparateCopy: boolean, groupUuid: string | undefined, organizationUuid?: string) => void;
    onCancel: () => void;
    document?: DocumentInfo;
}

const DocumentShare: FC<IDocumentShareProps> = (props) => {
    const {
        activeGroup,
        isLoading,
        onDocumentShare,
        onCancel,
        document
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [ receivers, setReceivers ] = useState<IDocumentReceiver[]>([]);
    const [ isSignSeparateCopies, setIsSignSeparateCopies ] = useState<boolean>(false);

    const { userContacts } = useAppSelector((state) => state.userContacts);

    useEffect(() => {
        dispatch(getUserContacts());
        dispatch(getDialCodes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const areReceiversEqual = (receiverOne: IDocumentReceiver, receiverTwo: IDocumentReceiver) => {
        return receiverOne.contactType === ContactType.EMAIL
            ? receiverOne.email === receiverTwo.email
            : receiverOne.phone?.dialCode === receiverTwo.phone?.dialCode && receiverOne.phone?.number === receiverTwo.phone?.number;
    };

    const handleShouldSignToggle = (receiver: IDocumentReceiver, shouldSign: boolean): void => {
        const index = receivers.findIndex((r) => areReceiversEqual(r, receiver));
        const newReceivers = [ ...receivers ];
        newReceivers[index].signatureRequested = shouldSign;
        setReceivers(newReceivers);
    };

    const handleAddReceiver = (receiver: IDocumentReceiver[]) => {
        setReceivers((prevState) => [ ...prevState, ...receiver ]);
    };

    const handleRemoveReceiver = (receiverToRemove: IDocumentReceiver) => {
        const filteredReceivers = receivers.filter((r) => !areReceiversEqual(r, receiverToRemove));
        setReceivers(filteredReceivers);
    };

    const handleSignSeparateCopiesToggle = () => {
        setIsSignSeparateCopies((prevValue) => !prevValue);
    };

    const handleBackToDocumentListClick = () => {
        onCancel();
    };

    const sendDocumentsClickHandler = () => {
        onDocumentShare(
            receivers,
            document?.uuid ?? '',
            isSignSeparateCopies,
            activeGroup?.group.uuid === '' ? undefined : activeGroup?.group.uuid,
            activeGroup?.group.organizationUuid ? activeGroup.group.organizationUuid : undefined
        );
    };

    return (
        <>
            <Card sx={ { mb: 2, p: 2 } }>
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 } md={ 12 } lg={ 12 }>
                        { isLoading && <SkeletonInput /> }
                        { !isLoading && (
                            <>
                                <AddReceiver sharedWith={ receivers.concat(...DocumentsHelper.getDocumentReceivers(document)) }
                                             userContacts={ userContacts }
                                             onAddReceiver={ handleAddReceiver } />

                                <DocumentReceiverList receivers={ receivers }
                                                      onRemoveReceiver={ handleRemoveReceiver }
                                                      onShouldSignToggle={ handleShouldSignToggle }
                                                      onSignSeparateCopies={ handleSignSeparateCopiesToggle }
                                                      isSignSeparateCopies={ isSignSeparateCopies } />
                            </>
                        ) }
                        <Box sx={ { alignItems: 'flex-end', mt: 10, textAlign: 'center' } }>
                            { !isLoading &&
                              <CommonButton sx={ { mr: 2 } }
                                            btnType={ ButtonTypeEnum.CLOSE }
                                            loading={ isLoading }
                                            onClick={ handleBackToDocumentListClick }>
                                  { t('dashboard.sidebar.documents.newDocument.button.cancel') }
                              </CommonButton>
                            }

                            <CommonButton sx={ { ml: 2 } }
                                          disabled={ isLoading || !receivers.length }
                                          btnType={ ButtonTypeEnum.SEND }
                                          startIcon={ <EmailOutlined /> }
                                          loading={ isLoading }
                                          onClick={ sendDocumentsClickHandler }>
                                { t('dashboard.sidebar.documents.newDocument.button.share') }
                            </CommonButton>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
};

export default DocumentShare;
