import type { FC } from 'react';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import finaLogo from '../../assets/img/illustrations/FINA_logo.svg';
import { useTranslation } from 'react-i18next';
import useSettings from '../../hooks/useSettings';
import { THEMES } from '../../common/constants';
import { FinaUserData } from 'fina-lcp-certifier-api';
import FinaUserDataTable from './FinaUserData';
import FinaCertBasicInfo from './FinaCertBasicInfo';
import LoadingComponent from '../../components/LoadingComponent';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import OibInsert from './OibInsert';
import { RestartAltRounded } from '@mui/icons-material';

interface IFinaIntroProps {
    isUserDataDisplayed: boolean;
    isOibInsertionVisible: boolean;
    isTanBlocked: boolean;
    finaUserData?: FinaUserData;
    isCreatingFinaUser: boolean;
    isSendingTan: boolean;
    onCreateFinaUser: (pin?: string) => void;
    onNextStep: () => void;
    onSetFromBeginning: () => void;
}

const FinaIntro: FC<IFinaIntroProps> = (props: IFinaIntroProps) => {
    const {
        finaUserData,
        isCreatingFinaUser,
        isSendingTan,
        isOibInsertionVisible,
        isUserDataDisplayed,
        isTanBlocked,
        onCreateFinaUser,
        onNextStep,
        onSetFromBeginning
    } = props;

    const { t } = useTranslation();
    const { settings } = useSettings();

    const isDarkMode = settings.theme === THEMES.DARK;
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const isLoading = isCreatingFinaUser || isSendingTan;
    const loadingTitle = isCreatingFinaUser
        ? t('dashboard.profile.general.finaLcp.steps.first.label')
        : isSendingTan
            ? t('tan.sending')
            : '';

    const getComponent = () => {
        if (isTanBlocked) {
            return (
                <Box>
                    <Typography sx={ { mt: 2, pb: 2, pt: 2 } } color='warningMain' variant='body1'>
                        { t('dashboard.sidebar.documents.enterTan.tanBlocked.message') }
                    </Typography>
                    <Box sx={ { mt: 7, pl: !mdUp ? 3 : 0, pr: !mdUp ? 3 : 0 } }>
                        <CommonButton
                            fullWidth={ !mdUp }
                            btnType={ ButtonTypeEnum.NO_ICON_CONTAINED }
                            startIcon={ <RestartAltRounded fontSize='small' /> }
                            onClick={ onSetFromBeginning }>
                            { t('dashboard.profile.general.finaLcp.startFromBeginning') }
                        </CommonButton>
                    </Box>
                </Box>
            );
        }

        if (isOibInsertionVisible) {
            return (
                <Box>
                    <OibInsert onConfirm={ onCreateFinaUser } />
                </Box>
            );
        }

        if (isUserDataDisplayed) {
            return (
                <Box>
                    <FinaUserDataTable finaUserData={ finaUserData } />
                    <Box sx={ { mt: 7, pl: !mdUp ? 3 : 0, pr: !mdUp ? 3 : 0 } }>
                        <CommonButton
                            fullWidth={ !mdUp }
                            btnType={ ButtonTypeEnum.NO_ICON_CONTAINED }
                            onClick={ onNextStep }>
                            { t('dashboard.profile.general.finaLcp.issue') }
                        </CommonButton>
                    </Box>
                </Box>
            );
        }

        return (
            <Box>
                <FinaCertBasicInfo />
                <Box sx={ { mt: 7, pl: !mdUp ? 3 : 0, pr: !mdUp ? 3 : 0 } }>
                    <CommonButton
                        fullWidth={ !mdUp }
                        btnType={ ButtonTypeEnum.NO_ICON_CONTAINED }
                        onClick={ () => onCreateFinaUser() }>
                        { t('dashboard.profile.general.finaLcp.createUser') }
                    </CommonButton>
                </Box>
            </Box>
        );
    };

    return (
        <Box sx={{ ml: mdUp ? 7 : 0, mr: mdUp ? 7 : 0, mt: 3, textAlign: 'center' }}>
            <Box sx={{ backgroundColor: isDarkMode ? '#f4f5f7' : 'background.paper' }}>
                <img src={finaLogo} alt='finaLogo'/>
            </Box>
            { isLoading ?
                <LoadingComponent title={ loadingTitle } /> :
                getComponent()
            }
        </Box>
    );
};

export default FinaIntro;
