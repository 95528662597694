import idyAxios from './idyAxios';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
import { Misc } from '../utils/misc';

const ACCESS_TOKEN_COOKIE_KEY = 'idy-access-token';
const USER_UUID_COOKIE_KEY = 'idy-user-uuid';
export const CLIENT_SESSION_UUID = 'X-CLIENT-SESSION-UUID';
const X_IDY_LANGUAGE = 'X-IDY-LANG';
const X_ORGANIZATION_UUID = 'X-ORGANIZATION-UUID';
const USER_PIN = 'idy-user-pin';

export default {
    clearLoginData() {
        const tokenCookieKey = this.getUserUuid() + '_' + ACCESS_TOKEN_COOKIE_KEY + '_' + Misc.getEnvironment();
        const userUuidCookieKey = USER_UUID_COOKIE_KEY + '_' + Misc.getEnvironment();
        this.cookies.remove(tokenCookieKey, Misc.isLocalEnvironment() ? undefined : { path: '/', domain: 'identyum.com' });
        this.cookies.remove(userUuidCookieKey, Misc.isLocalEnvironment() ? undefined : { path: '/', domain: 'identyum.com' });
        this.removePin();
        idyAxios.update();
    },

    cookies: new Cookies(),

    getClientSessionUuid(): string | null {
        const clientSessionUuidCookieKey = CLIENT_SESSION_UUID + '_' + Misc.getEnvironment();
        return this.cookies.get(clientSessionUuidCookieKey) || null;
    },

    getIdyLanguage(): string | null {
        return localStorage.getItem(X_IDY_LANGUAGE) || i18n.language;
    },

    getIsRefreshInProgress() {
        return this.isRefreshTokenInProgress;
    },

    getOrganizationUuid(): string {
        const organizationUuid = localStorage.getItem(X_ORGANIZATION_UUID);
        return organizationUuid || '';
    },

    getToken(): Token | null {
        const tokenCookieKey = this.getUserUuid() + '_' + ACCESS_TOKEN_COOKIE_KEY + '_' + Misc.getEnvironment();
        return (this.cookies.get(tokenCookieKey) as Token) || null;
    },

    getUserPin(): string | null {
        const userPinKey = USER_PIN + '_' + Misc.getEnvironment();
        return sessionStorage.getItem(userPinKey);
    },

    getUserUuid(): string | null {
        const userUuidCookieKey = USER_UUID_COOKIE_KEY + '_' + Misc.getEnvironment();
        return this.cookies.get(userUuidCookieKey) || null;
    },

    async isLoggedIn() {
        return this.getToken() !== null;
    },

    isRefreshTokenInProgress: false,

    removePin() {
        const userPinKey = USER_PIN + '_' + Misc.getEnvironment();
        sessionStorage.removeItem(userPinKey);
        idyAxios.update();
    },

    setClientSessionUuid(clientSessionUuid: string) {
        const thisTimeNextYear = new Date();
        thisTimeNextYear.setFullYear(thisTimeNextYear.getFullYear() + 1);
        const clientSessionUuidKey = CLIENT_SESSION_UUID + '_' + Misc.getEnvironment();
        this.cookies.remove(
            clientSessionUuidKey,
            Misc.isLocalEnvironment() ? { path: '/', domain: 'localhost' } : { path: '/', domain: 'identyum.com' }
        );
        this.cookies.set(
            clientSessionUuidKey,
            clientSessionUuid,
            {
                path: '/',
                domain: Misc.isLocalEnvironment() ? 'localhost' : 'identyum.com',
                expires: thisTimeNextYear
            }
        );
        idyAxios.update();
    },

    setIdyLanguage(languageCode: string) {
        localStorage.removeItem(X_IDY_LANGUAGE);
        localStorage.setItem(X_IDY_LANGUAGE, languageCode);
        idyAxios.update();
    },

    setIsRefreshInProgress(isRefreshInProgress: boolean) {
        this.isRefreshTokenInProgress = isRefreshInProgress;
    },

    setOrganizationUuid(organizationUuid: string) {
        localStorage.setItem(X_ORGANIZATION_UUID, organizationUuid);
        idyAxios.update();
    },

    setToken(token: Token) {
        const thisTimeNextYear = new Date();
        thisTimeNextYear.setFullYear(thisTimeNextYear.getFullYear() + 1);
        const tokenCookieKey = this.getUserUuid() + '_' + ACCESS_TOKEN_COOKIE_KEY + '_' + Misc.getEnvironment();
        this.cookies.remove(
            tokenCookieKey,
            Misc.isLocalEnvironment() ? { path: '/', domain: 'localhost' } : { path: '/', domain: 'identyum.com' }
        );
        this.cookies.set(
            tokenCookieKey,
            token,
            {
                path: '/',
                domain: Misc.isLocalEnvironment() ? 'localhost' : 'identyum.com',
                expires: thisTimeNextYear
            }
        );
        idyAxios.update();
    },

    setUserPin(userPin: string) {
        const userPinKey = USER_PIN + '_' + Misc.getEnvironment();
        sessionStorage.setItem(userPinKey, userPin);
        idyAxios.update();
    },

    setUserUuid(userName: string) {
        const thisTimeNextYear = new Date();
        thisTimeNextYear.setFullYear(thisTimeNextYear.getFullYear() + 1);
        const userUuidCookieKey = USER_UUID_COOKIE_KEY + '_' + Misc.getEnvironment();
        this.cookies.remove(
            userUuidCookieKey,
            Misc.isLocalEnvironment() ? { path: '/', domain: 'localhost' } : { path: '/', domain: 'identyum.com' }
        );
        this.cookies.set(
            userUuidCookieKey,
            userName,
            {
                path: '/',
                domain: Misc.isLocalEnvironment() ? 'localhost' : 'identyum.com',
                expires: thisTimeNextYear
            }
        );
    },
};

export interface Token {
    access_token: string;
    refresh_token: string;
    expires_in: number;
    refresh_expires_in: number;
    session_state?: string;
    token_type?: string;
    id_token?: string;
}
