import type { FC } from 'react';
import { Box, Step, StepLabel, Stepper, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

const steps = [
    {
        label: 'dashboard.profile.general.finaLcp.steps.first.label',
    },
    {
        label: 'dashboard.profile.general.finaLcp.steps.second.label',
    },
    {
        label: 'dashboard.profile.general.finaLcp.steps.third.label',
    },
    {
        label: 'dashboard.profile.general.finaLcp.steps.fourth.label',
    },
];

interface IFinaStepperProps {
    activeStep: number;
}

const FinaStepper: FC<IFinaStepperProps> = ({ activeStep }) => {
    const { t } = useTranslation();
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Box sx={{ maxWidth: mdUp ? 400 : '100%' }}>
            <Stepper activeStep={activeStep} orientation={mdUp ? 'vertical' : 'horizontal'} alternativeLabel={!mdUp}>
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            optional={
                                index === activeStep && index === 3 ? (
                                    <Typography variant='caption'>
                                        {t('dashboard.profile.general.finaLcp.steps.finish')}
                                    </Typography>
                                ) : null
                            }>
                            {t(step.label)}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default FinaStepper;
