import {TokenHelper} from './token-helper';

export const identyumSurveyCreatorRole = 'identyum_survey:write';
export const standardUser = 'standard_user';
export const contactsServiceWrite = 'contacts_service:write';

export class RolesHelper {
    public static hasSurveyCreatorRole = () => {
        const userRoles: string[] = TokenHelper.getUserRoles();
        return userRoles.includes(identyumSurveyCreatorRole);
    };
}

