import type { FC } from 'react';
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import ShopLegalDocs from '../profile/plans/ShopLegalDocs';

const DataProtectionStatement: FC = () => {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8,
                }}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid alignItems="center" container justifyContent="space-between" spacing={3} item xs={12}>
                            <Grid item>
                                <Typography color="textPrimary" variant="h5">
                                    Način zaštite povjerljivih podataka
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>
                                        Izjava Identity consortium d.o.o. o zaštiti osobnih podataka
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Zaštita osobnih podataka naših korisnika poseban je interes naše tvrtke. Sve aktivnosti Identity consortium d.o.o. na Internetu zadovoljavaju europske propise (EU Direktiva 95/46/EC,2002,58/Ec i Međunarodni sporazum ETS 108, ETS 181, ETS 185, ETS 189) i državne zakone koji su na snazi u zemljama srednje i istočne Europe. U Republici Hrvatskoj pridržavamo se i Zakona o zaštiti osobnih podataka (N.N.103/03.). Identity consortium d.o.o pridržava se Zaštite privatnosti pri dobivanju podataka.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Niti u kojem slučaju nećemo zloupotrijebiti Vaše osobne podatke.
                                        Vaše kontakt podatke ili Vaše osobne podatke nećemo otkriti nekoj trećoj osobi.
                                        Nećemo Vam slati elektronske poruke ili obavijesti ukoliko niste dali privolu, osim u slučaju elektronskih transakcija.
                                        Kada god zaželite jednostavno se možete odjaviti od primanja naših elektronskih poruka ili obavijesti.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Unos i prijenos osobnih podataka i podataka o broju kreditne kartice zaštićen je SSL protokolom 256-bitne enkripcije koju osigurava WSpay™ sustav za on line autorizaciju kreditnih karica. Autorizacija i naplata kreditnih kartica radi se korištenjem sustava WSpay™ za autorizaciju i naplatu kartica u realnom vremenu.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>
                                        Prikupljanje i obrada osobnih podataka
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Osobni podatak je informacija kojom se utvrđuje Vaš identitet, npr. Vaše ime, prezime, e-mail adresa ili adresa stanovanja. Identity consortium d.o.one prikuplja i ne obrađuje Vaše osobne podatke, osim kada Vi date dopuštenje naručivanjem usluga.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>
                                        Korištenje i odavanje osobnih podataka
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Identity consortium d.o.o koristit će Vaše osobne podatke samo u svrhu tehničke administracije na Web lokaciji tako da biste imali pristup posebnim informacijama, ili u svrhu komunikacije s Vama. Identity consortium d.o.o neće dostavljati Vaše osobne podatke trećoj osobi.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>Narudžbe, ankete i natjecanja
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>Povremeno se na Internet stranicama korisnici pozivaju na sudjelovanje u raznim anketama ili natjecanjima ili se poziv na sudjelovanje šalje u elektronskim obavijestima (e-časopis i sl.). Sudjelovanje u ovim anketama ili natjecanjima je potpuno dobrovoljno i korisnik može izabrati želi li dati ove informacije ili ne. Zatražene informacije mogu uključiti informacije za kontakt (e-mail adresa, ime i adresa) ili demografske informacije (spol, dob i sl.). Informacije pribavljene anketom bit će korištene u svrhe praćenja ili unapređivanja korištenja i zadovoljstva korisnika. Kod natjecanja kontakt informacije koristit će se i za registriranje sudionika, objavu pobjednika, dodjeljivanje nagrada i sl.
                                        Povremeno se na Internet stranicama korisnici pozivaju na sudjelovanje u raznim anketama ili natjecanjima ili se poziv na sudjelovanje šalje u elektronskim obavijestima (e-časopis i sl.). Sudjelovanje u ovim anketama ili natjecanjima je potpuno dobrovoljno i korisnik može izabrati želi li dati ove informacije ili ne. Zatražene informacije mogu uključiti informacije za kontakt (e-mail adresa, ime i adresa) ili demografske informacije (spol, dob i sl.). Informacije pribavljene anketom bit će korištene u svrhe praćenja ili unapređivanja korištenja i zadovoljstva korisnika. Kod natjecanja kontakt informacije koristit će se i za registriranje sudionika, objavu pobjednika, dodjeljivanje nagrada i sl.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Zaposleni u Identity consortium d.o.o su obvezni poštovati povjerljivost Vaših podataka i podliježu sporazumu o razotkrivanju osobnih podataka.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>
                                        Sloboda izbora
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Osobne informacije koje dajete Identity consortium d.o.o kontrolirate sami. Korisnici koji žele povući suglasnost za kontaktiranje mogu to učiniti klikom na posebno istaknuti link odjave koji se nalazi u svakoj elektronskoj obavijesti koju primaju.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>Automatski zabilježeni podaci (koji nisu osobni)
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Kada dođete na internetske stranice Identity consortium d.o.o., automatski se zabilježe (a ne kao dio prijave) općeniti neosobni podaci (koji ste Internet pretraživač koristili, broj posjeta, prosječno vrijeme provedeno na našim stranicama, koje ste stranice gledali). Te informacije koristimo kako bismo ocijenili posjećenost naših internetskih stranica, te poboljšali njihov sadržaj i funkcionalnost. Vaši se podaci ne koriste u druge svrhe, niti se ustupaju trećoj osobi.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>
                                        Cookies („Kolačići“)
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        „Cookies“ („kolačići“) su male datoteke koje su privremeno pohranjene na Vaš tvrdi disk, tako da naša stranica prepozna Vaše računalo kada nas sljedeći put posjetite. Identity consortium d.o.o koristi ih isključivo zbog prikupljanja podataka koji su vezani za posjetu našim Internet stranicama, zbog dodatne funkcionalnosti ili u svrhu procjene djelotvornosti svoje prisutnosti na Internetu.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>
                                        Sigurnost
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Identity consortium d.o.o poduzima velike mjere sigurnosti osobnih podataka. Vaši podaci zaštićeni su od gubitka, uništenja, mijenjanja/krivotvorenja, manipulacije i nedozvoljenog pristupa, kao i od nedozvoljenog razotkrivanja.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>
                                        Veze prema ostalim Internet stranicama
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Ova izjava o zaštiti osobnih podataka odnosi se na sve internetske stranice i njihove poddomene Identity consortium d.o.o.  kao i na elektronske obavijesti i na www.now.identyum.com početnu stranicu koju održava Identity consortium d.o.o.. Internet stranice na ovoj početnoj stranici mogu sadržavati veze prema drugim dobavljačima unutar ili izvan Identity consortium d.o.o. na koje se ne odnosi ova izjava o zaštiti osobnih podataka. Kada napuštate neku od Internet stranica Identity consortium d.o.o., molimo vas da pročitate izjave o privatnosti na svakoj Internet stranici koja prikuplja osobne podatke.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>
                                        Pravo na informacije
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Ako imate pitanja u vezi naše politike zaštite osobnih podataka ili kako se obrađuju vaši osobni podaci, slobodno kontaktirajte našeg predstavnika za zaštitu privatnosti putem e-maila na: support@identyum.com
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>Na Vaš zahtjev brzo ćete dobiti informacije – pismeno i u skladu sa zakonom – o tome koji su Vaši osobni podaci, ako uopće jesu, prikupljeni za vrijeme Vašeg posjeta Internet stranicama Identity consortium d.o.o.
                                        Na Vaš zahtjev brzo ćete dobiti informacije – pismeno i u skladu sa zakonom – o tome koji su Vaši osobni podaci, ako uopće jesu, prikupljeni za vrijeme Vašeg posjeta Internet stranicama Identity consortium d.o.o.
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='h6' sx={{ mt: 2 }}>
                                        Obavijest o promjenama
                                    </Typography>
                                    <Typography color={'textSecondary'} variant='body2' sx={{ mt: 2 }}>
                                        Bilo kakve promjene u vezi naše politike zaštite osobnih podataka bit će objavljene u izjavi o zaštiti osobnih podataka, na našim Internet stranicama i na drugim mjestima na kojima smatramo da je to potrebno.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <ShopLegalDocs />
        </>
    );
};

export default DataProtectionStatement;
