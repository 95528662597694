import type { FC } from 'react';
import React from 'react';
import { Box } from '@mui/material';
import Scrollbar from '../../scrollbar/Scrollbar';
import { IMenuSection } from '../../../model/common/navigation/menu-section';
import { Membership } from 'group-service-api';
import SkeletonBlock from '../../skeletons/SkeletonBlock';
import GroupAvatarPanel from './GroupAvatarPanel';
import PersonalAvatarPanel from './PersonalAvatarPanel';
import SidebarMenu from './SidebarMenu';
import { IdentificationData } from 'profile-manager-api';
import { ApiGetDocumentsResponse } from 'document-scanner-api';

interface ISidebarContentProps {
    activeGroup?: Membership;
    isLoading?: boolean;
    isProfileVerified: boolean;
    menu: IMenuSection[];
    profileData?: ApiGetDocumentsResponse;
    userIdentificationData?: IdentificationData;
}

export const SidebarContent: FC<ISidebarContentProps> = (props) => {
    const {
        activeGroup,
        isLoading,
        isProfileVerified,
        menu,
        profileData,
        userIdentificationData
    } = props;

    return (
        <Box sx={ { display: 'flex', flexDirection: 'column', height: '100%' } }>
            <Scrollbar options={ { suppressScrollX: true } }>
                { isLoading ? (
                    <SkeletonBlock height={ 82 } />
                ) : (
                    <Box sx={ { p: 2 } }>
                        <Box sx={ {
                            alignItems: 'center',
                            backgroundColor: 'background.default',
                            borderRadius: 1,
                            display: 'flex',
                            overflow: 'hidden',
                            p: 2
                        } }>
                            { activeGroup
                                ? <GroupAvatarPanel activeGroup={ activeGroup }
                                                    userIdentificationData={ userIdentificationData } />
                                : <PersonalAvatarPanel isProfileVerified={ isProfileVerified }
                                                       profileData={ profileData }
                                                       userIdentificationData={ userIdentificationData } /> }
                        </Box>
                    </Box>
                ) }
                <SidebarMenu menu={ menu } />
            </Scrollbar>
        </Box>
    );
};

export default React.memo(SidebarContent);
