import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../model/common/error';

interface ITanModalState {
    error: IError | undefined;
    isTanModalVisible: boolean;
    remainingAttempts: number | undefined;
}

const initialState: ITanModalState = {
    error: undefined,
    isTanModalVisible: false,
    remainingAttempts: undefined,
};

const tanModalSlice = createSlice({
    name: 'tanModal',
    initialState,
    reducers: {
        clearErrorMessage(state: ITanModalState): void {
            state.error = undefined;
        },
        clearRemainingAttempts(state: ITanModalState): void {
            state.remainingAttempts = undefined;
        },
        hideTanModal(state: ITanModalState): void {
            state.isTanModalVisible = false;
            state.error = undefined;
        },
        setErrorMessage(state: ITanModalState, action: PayloadAction<IError>): void {
            state.error = action.payload;
        },
        setRemainingAttempts(state: ITanModalState, action: PayloadAction<number>): void {
            state.remainingAttempts = action.payload;
        },
        showTanModal(state: ITanModalState): void {
            state.isTanModalVisible = true;
        }
    }
});

export const { clearErrorMessage, clearRemainingAttempts, hideTanModal, showTanModal } = tanModalSlice.actions;

export const { reducer } = tanModalSlice;

export default tanModalSlice;

