import type { FC } from 'react';
import { useEffect, useState } from 'react';
import Page from '../../components/shared/Page';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    getDocuments,
    resetSendSuccessful,
    resetSignSuccessful,
    setFilesForUpload
} from '../../slices/documents';
import { hasValidFinaPKICloudCertificate, resetFinaFlags, sendTan, signDocumentFina } from '../../slices/fina-lcp';
import { toast } from 'react-hot-toast';
import {
    checkHasIdyCertAndSign,
    closeCertificateSelectModal,
    issueIdyCertificateAndSign
} from '../../slices/certificates';
import DocumentList from '../../components/documents/DocumentList';
import { showTanModal } from '../../slices/tan-modal';
import TanInsertionModal from '../../components/TanInsertionModal';
import { GroupRoutes, PrivateRoutes } from '../../routes';
import { DocumentsRetrieveType } from '../../model/common/documents/DocumentsRetrieveType';
import { DocumentVisibilityType } from '../../model/common/documents/DocumentVisibilityType';
import SkeletonBlock from '../../components/skeletons/SkeletonBlock';
import FileDropzone from '../../components/FileDropzone';
import { AppSettings } from '../../common/app-settings';
import { Box } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { clearErrorMessage } from '../../slices/errors-handling';
import { ISignatures } from '../../model/common/payments/ISignatures';
import { PaymentsHelper } from '../../utils/payments-helper';
import { getEntityPayables } from '../../slices/payments';

interface IProps {
    type: DocumentsRetrieveType;
}

const GroupDocumentsPage: FC<IProps> = ({ type }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { activeGroup } = useAppSelector((state) => state.groups);
    const {
        certInfo,
        hasValidFinaCertificate,
        isFinaSignSuccessful,
        isFinaSigning
    } = useAppSelector((state) => state.finaLcp);
    const {
        documents,
        isLoadingSentDocuments,
        isLoadingReceivedDocuments,
        isSigning,
        isSignSuccessful,
        isUploadingDocuments,
        uploadDocumentSuccessful,
        uploadedDocument
    } = useAppSelector((state) => state.documents);
    const { userPayables } = useAppSelector((state) => state.payments);

    const [ unsignedDocuments, setUnsignedDocuments ] = useState<string[]>([]);
    const signatures: ISignatures | undefined = PaymentsHelper.getSignaturesFromPayables(userPayables);

    useEffect(() => {
        !userPayables && dispatch(getEntityPayables());

        return () => {
            dispatch(clearErrorMessage());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        !certInfo && dispatch(hasValidFinaPKICloudCertificate());
        if (activeGroup?.group.uuid) {
            type === DocumentsRetrieveType.RECEIVED && dispatch(getDocuments(DocumentVisibilityType.VISIBLE, DocumentsRetrieveType.RECEIVED, activeGroup.group.uuid));
            type === DocumentsRetrieveType.SENT && dispatch(getDocuments(DocumentVisibilityType.VISIBLE, DocumentsRetrieveType.SENT, activeGroup.group.uuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ activeGroup?.group.uuid ]);

    useEffect(() => {
        if (activeGroup?.group.uuid) {
            type === DocumentsRetrieveType.RECEIVED && dispatch(getDocuments(DocumentVisibilityType.VISIBLE, DocumentsRetrieveType.RECEIVED, activeGroup.group.uuid));
            type === DocumentsRetrieveType.SENT && dispatch(getDocuments(DocumentVisibilityType.VISIBLE, DocumentsRetrieveType.SENT, activeGroup.group.uuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ type ]);

    useEffect(() => {
        if (uploadDocumentSuccessful) {
            toast.success(t('dashboard.sidebar.documents.summary.uploadSuccessful'));
            dispatch(resetSendSuccessful());
            navigate(GroupRoutes
                .group_document_details_route
                .path
                .replace(':documentUuid', uploadedDocument?.uuid ?? '')
                .replace(':groupUuid', activeGroup?.group.uuid ?? '')
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ uploadDocumentSuccessful ]);

    useEffect(() => {
        if (isFinaSignSuccessful || isSignSuccessful) {
            toast.success(t('dashboard.sidebar.documentDetails.sign.successful'));
            dispatch(resetSignSuccessful());
            dispatch(resetFinaFlags());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isFinaSignSuccessful, isSignSuccessful ]);

    const documentDetailsClickHandler = (documentUuid: string) => {
        navigate(GroupRoutes
            .group_document_details_route
            .path
            .replace(':groupUuid', activeGroup?.group.uuid ?? '')
            .replace(':documentUuid', documentUuid)
        );
    };

    const signAllDocumentsHandler = (unsignedDocuments: string[]) => {
        if (hasValidFinaCertificate) {
            setUnsignedDocuments(unsignedDocuments);
            dispatch(sendTan());
            dispatch(showTanModal());
            return;
        }
        dispatch(checkHasIdyCertAndSign(
            unsignedDocuments,
            false,
            activeGroup?.group.uuid === '' ? undefined : activeGroup?.group.uuid,
            activeGroup?.group.organizationUuid ? activeGroup?.group.organizationUuid : undefined
        ));
    };

    const signingDocumentsConfirmTanHandler = (tan: string) => {
        dispatch(signDocumentFina(
            unsignedDocuments,
            tan,
            activeGroup?.group.uuid === '' ? undefined : activeGroup?.group.uuid,
        ));
    };

    const handleFinaCertificateSelect = () => {
        dispatch(closeCertificateSelectModal());
        navigate(PrivateRoutes.private_shop_route.path);
    };

    const handleCertificateModalClose = () => {
        dispatch(closeCertificateSelectModal());
    };

    const handleIdentyumCertificateSelect = (unsignedDocuments: string[]) => {
        dispatch(closeCertificateSelectModal());
        dispatch(issueIdyCertificateAndSign(
            unsignedDocuments,
            activeGroup?.group.uuid === '' ? undefined : activeGroup?.group.uuid,
            activeGroup?.group.organizationUuid ? activeGroup?.group.organizationUuid : undefined
        ));
    };

    const handleFilterChange = (visibilityType: DocumentVisibilityType, retrieveType: DocumentsRetrieveType) => {
        dispatch(getDocuments(visibilityType, retrieveType, activeGroup?.group.uuid));
    };

    const uploadDocumentHandler = (acceptedFiles: File[]): void => {
        dispatch(setFilesForUpload(acceptedFiles));
        navigate(GroupRoutes
            .groups_new_document_route
            .path
            .replace(':groupUuid', activeGroup?.group.uuid ?? '')
        );
    };

    const documentsToShow = () => {
        if (type === DocumentsRetrieveType.SENT) {
            return documents?.sharedDocuments ?? [];
        }
        return documents?.receivedDocuments ?? [];
    };

    return (
        <>
            <Page
                pageTitle={ t('dashboard.sidebar.documents.groupDocuments.title') }
                pageDescription={ t('dashboard.sidebar.documents.groupDocuments.description') }>
                { type === DocumentsRetrieveType.SENT && (
                    <Box sx={ { mb: 5 } }>
                        { isUploadingDocuments ?
                            <SkeletonBlock height={ 150 } /> :
                            <FileDropzone
                                accept='application/pdf'
                                files={ [] }
                                isFilesPanelVisible={ false }
                                maxSize={ AppSettings.MAX_UPLOAD_SIZE }
                                setFiles={ uploadDocumentHandler }
                                title={ t('dashboard.sidebar.documents.newDocument.fileDropzone.uploadFile') } /> }
                    </Box>
                ) }

                <Accordion defaultExpanded>
                    <AccordionSummary>
                        <Typography variant="h6">
                            { type === DocumentsRetrieveType.SENT
                                ? t('dashboard.sidebar.documents.list.sent.title')
                                : t('dashboard.sidebar.documents.list.received.title') }
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <DocumentList
                            documents={ documentsToShow() }
                            received={ type === DocumentsRetrieveType.RECEIVED }
                            isLoadingDocuments={ type === DocumentsRetrieveType.RECEIVED ? isLoadingReceivedDocuments : isLoadingSentDocuments }
                            isSigning={ isSigning || isFinaSigning }
                            onDocumentClick={ documentDetailsClickHandler }
                            onSignAllDocuments={ signAllDocumentsHandler }
                            onCertificateModalClose={ handleCertificateModalClose }
                            onIdentyumCertificateSelect={ handleIdentyumCertificateSelect }
                            onFinaCertificateSelect={ handleFinaCertificateSelect }
                            signatures={ signatures }
                            onFilterChange={ (filter) => handleFilterChange(
                                filter,
                                type === DocumentsRetrieveType.RECEIVED ? DocumentsRetrieveType.RECEIVED : DocumentsRetrieveType.SENT
                            ) } />
                    </AccordionDetails>
                </Accordion>
            </Page>
            <TanInsertionModal onConfirm={ signingDocumentsConfirmTanHandler } />
        </>
    );
};

export default GroupDocumentsPage;
