import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Divider, Tab, Tabs, Typography } from '@mui/material';
import { AlternateEmail, FactCheck, PermIdentity, WorkspacePremiumRounded } from '@mui/icons-material';
import ProfileConsents from 'components/profile/ProfileConsents';
import ProfileGeneral from 'components/profile/ProfileGeneral';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store/hooks';
import { clearUserConfig } from '../../slices/consents';
import ProfileCertificates from 'components/profile/ProfileCertificates';
import { clearIdyCertificate } from '../../slices/certificates';
import { clearFinaCertInfo } from '../../slices/fina-lcp';
import ProfileContacts from 'components/profile/ProfileContacts';

const ProfilePage: FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { activeTab } = useParams();
    const [currentTab, setCurrentTab] = useState<string>(activeTab ? activeTab : 'general');

    const tabs = [
        { label: `${t('dashboard.profile.general')}`, value: 'general', icon: <PermIdentity /> },
        { label: `${t('dashboard.profile.consents')}`, value: 'consents', icon: <FactCheck /> },
        { label: `${t('dashboard.profile.certificates')}`, value: 'certificates', icon: <WorkspacePremiumRounded /> },
        { label: `${t('dashboard.profile.contact')}`, value: 'contact', icon: <AlternateEmail /> },
    ];

    useEffect(() => {
        return () => {
            dispatch(clearUserConfig());
            dispatch(clearIdyCertificate());
            dispatch(clearFinaCertInfo());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        activeTab !== currentTab && setCurrentTab(activeTab ?? 'general');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    // eslint-disable-next-line @typescript-eslint/ban-types
    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    return (
        <>
            <Box sx={{ minHeight: '100%', py: 8 }}>
                <Container maxWidth="xl" sx={{ maxWidth: '1000px' }}>
                    <Typography color="textPrimary" variant="h5">
                        {t('dashboard.sidebar.documents.account.profile')}
                    </Typography>
                    <Box sx={{ mt: 3 }}>
                        <Tabs
                            indicatorColor="primary"
                            onChange={handleTabsChange}
                            scrollButtons="auto"
                            textColor="primary"
                            value={currentTab}
                            variant="scrollable">
                            {tabs.map((tab) => (
                                <Tab key={tab.value} label={tab.label} value={tab.value} icon={tab.icon} />
                            ))}
                        </Tabs>
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 3 }}>
                        {currentTab === 'general' && <ProfileGeneral />}
                        {currentTab === 'consents' && <ProfileConsents />}
                        {currentTab === 'certificates' && <ProfileCertificates />}
                        {currentTab === 'contact' && <ProfileContacts />}
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default ProfilePage;
