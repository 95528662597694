import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const EurPriceDisclaimer: FC = () => {
    const { t } = useTranslation();

    return (
        <Box sx={{ mb: 1, mt: 3, textAlign: 'center' }}>
            <Typography variant='subtitle2' color='textSecondary'>
                {t('plans.disclaimer.eurPrices')}
            </Typography>
        </Box>
    );
};

export default EurPriceDisclaimer;
