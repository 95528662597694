enum Reason {
    TAN_BLOCKED = 'TAN_BLOCKED',
}
export class RequestHelper {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static isUnprocessableEntityError = (error: any): boolean => error?.response?.status === 422;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static getRemainingAttempts = (error: any): number => {
        const header = error?.response?.headers;
        const remainingAttempts = header['remaining-attempts'];
        return remainingAttempts ?? 9;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static isPreconditionFailed = (error: any): boolean => error?.response?.status === 412;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static isTanBlocked = (error: any): boolean =>
        error?.response?.status === 303 ||
        (error?.response?.status === 422 && error?.response?.data?.type === Reason.TAN_BLOCKED);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static isGoneException = (error: any): boolean => error?.response?.status === 410;
}
