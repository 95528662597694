import type { FC } from 'react';
import { Box, Grid, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ISignSeparateCopiesProps {
    isChecked: boolean;
    onChange: () => void;
}

const SignSeparateCopiesSwitch: FC<ISignSeparateCopiesProps> = ({ isChecked, onChange }) => {
    const { t } = useTranslation();

    return (
        <Grid item md={12} xs={12}>
            <Box sx={{ mt: 5 }}>
                <Typography color='textPrimary' gutterBottom variant='h6'>
                    {t('dashboard.sidebar.documents.newDocument.checkbox.separateCopies')}
                </Typography>
                <Switch
                    checked={isChecked}
                    color='primary'
                    edge='start'
                    name='isVerified'
                    onChange={() => onChange()}
                    value={isChecked}
                />
            </Box>
        </Grid>
    );
};

export default SignSeparateCopiesSwitch;
